import {ID, Store, StoreConfig} from '@datorama/akita'
import {SubtaskStatus, SubtaskType} from "../../../../entities/subtask/model";
import {HistoryActivityItemType} from "../../../../history/state/model";
import {parseLocalStorage} from "../../../utils";

export interface TaskPanelState {
  taskId: ID | null
  subtasksFilter: {
    types: SubtaskType[]
    statuses: SubtaskStatus[]
  }
  activitiesFilter: HistoryActivityItemType[]
}

const initialState = {
  taskId: null,
  subtasksFilter: {
    types: parseLocalStorage("subtasksFilter", "types") || [],
    statuses: parseLocalStorage("subtasksFilter", "statuses") || [],
  },
  activitiesFilter: parseLocalStorage("taskActivityFilter", "statuses") || [
    HistoryActivityItemType.ACTIVITY,
    HistoryActivityItemType.COMMENT,
    HistoryActivityItemType.WORKLOG,
  ]
}

@StoreConfig({ name: 'ui-task-panel' })
export class TaskPanelStore extends Store<TaskPanelState> {}

export const taskPanelStore = new TaskPanelStore(initialState)
