import React, {useState, useEffect, useRef, useCallback} from 'react'
import { Input, Popover } from 'antd'

import {Subtask, SubtaskEstimate} from '../../../entities/subtask/model'
import { ReadableTimeString, parseDuration } from '../../../tracking/TimeConverters'

interface SubtaskChangeEstimationProps {
  subtask: Subtask,
  changeSubtaskEstimate: (estimate: Partial<SubtaskEstimate>) => void
}

export function SubtaskChangeEstimation(props: SubtaskChangeEstimationProps) {
  const { subtask, changeSubtaskEstimate } = props
  const [ value, setValue ] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef(null) as any
  const readableCurrentEstimate = ReadableTimeString(subtask.current_estimate)

  useEffect(() => {
    const inputEl = inputRef.current
    if (isEditing && inputEl) inputEl.focus()
  }, [isEditing, inputRef])

  const handleActiveEditingState = () => setIsEditing(true)

  const changeInitialEstimate = useCallback(
    () => {
      const estimate = parseDuration(value)

      if (!estimate || estimate === subtask.initial_estimate) setIsEditing(false)

      changeSubtaskEstimate({ estimate: parseDuration(value) })
      setIsEditing(false)
    }, [changeSubtaskEstimate, subtask.initial_estimate, value]
  )

  return isEditing ? (
    <Input
      ref={inputRef}
      className="subtasks-subtask__estimation-input"
      onChange={(event) => setValue(event.target.value)}
      onBlur={changeInitialEstimate}
      onPressEnter={changeInitialEstimate}
    />
  ) : (
    <Popover
    placement="top"
    trigger="hover"
    content="Current estimate for the subtask"
  >
    <span
      onClick={handleActiveEditingState}
      className="subtasks__estimation-end subtasks__estimation-end_popover"
    >
      {readableCurrentEstimate}
    </span>
    </Popover>
  )
}
