import React from 'react'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import { UserProfileLocalTimeField } from './UserProfileLocalTimeField'

import '../../styles/user-field.scss'

interface UserProfileLocalTimeInfoProps {
  localTime: string
}

export function UserProfileLocalTimeInfo(props: UserProfileLocalTimeInfoProps) {
  if (!props.localTime) {
    return null
  }
  return (
    <div className="user-profile__info user-profile__info_local-time user-info">
      <UserProfileFieldTitle label={'local time'} />
      <UserProfileLocalTimeField time={props.localTime} />
    </div>
  )
}
