import React from 'react'
import './styles/task-groups-details-info-date.scss'
import {UserAvatar} from "../../../components/UserAvatar";
import {ShortUser, User} from "../../../entities/user/model";

interface TaskGroupsDetailsInfoDateProps {
  date: string
  user?: User | ShortUser
  title: string
}

export function TaskGroupsDetailsInfoDate(
  props: TaskGroupsDetailsInfoDateProps
) {
  return (
    <div className="task-groups-details-info-date">
      <div className="task-groups-details-info-date__title">{props.title}</div>
      <div className="task-groups-details-info-date__content">
        {!!props.user && (<UserAvatar size={22} shape="square" className={'avatar-img'} user={props.user} />)}
        {props.date}
      </div>
    </div>
  )
}
