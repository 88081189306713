import React, {useContext} from 'react'
import { SetTaskAssigneeAction } from '../../../entities/activities/model'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";
import {Subtask} from "../../../entities/subtask/model";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";

interface SetAssigneeActivityProps {
  action: SetTaskAssigneeAction
  date: string
  user: User | string
}

export function SetSubtaskAssigneeActivity(props: SetAssigneeActivityProps) {
  const { action, user } = props

  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> assigned {' '}the {action.target.type} <HighlightedActivity>{subtask?.name}</HighlightedActivity>{' '} to <ActivityBoldText>{action.data.changed_to.label}</ActivityBoldText>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
