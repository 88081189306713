import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Task } from '../../../entities/task/model'
import { Epic } from '../../../entities/epic/model'
import { Sprint } from '../../../entities/sprint/model'
import { TaskListItem } from '../TaskListItem'
import { ShortUser, User } from '../../../entities/user/model'
import { ModelUpdate } from '../../../utils/types'
import { ID } from '@datorama/akita'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'

export interface LinkedListProps {
  tasks: Task[]
  users: User[] | ShortUser[]
  epics?: Epic[]
  sprints?: Sprint[]
  taskTypes: TaskType[]
  onTaskUpdate: (update: ModelUpdate<Task>) => void
  onRemoveTask: (idToRemove: ID) => void
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function LinkedList(props: LinkedListProps) {
  const {
    tasks,
    epics,
    taskTypes,
    users,
    onTaskUpdate,
    onRemoveTask,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props
  const routeMatch = useRouteMatch('/:workspace')

  return (
    <div className="task-list">
      {tasks.map((task) => {
        const epic = epics?.find((epic: Epic) => epic.id === task.epic)
        return (
          <TaskListItem
            key={task.id}
            task={task}
            epic={epic || null}
            taskTypes={taskTypes}
            users={users}
            baseURL={routeMatch?.url}
            isLinkedTask={true}
            isHighlighted={false}
            onRemove={onRemoveTask}
            onTaskUpdate={onTaskUpdate}
            onStopTracking={() => {}}
            onStartTracking={() => {}}
            onAddTimeManually={() => {}}
            addTimeFormErrors={addTimeFormErrors}
            isAddTimeFormOpened={isAddTimeFormOpened}
            onOpenAddTimeModal={onOpenAddTimeModal}
            onCloseAddTimeModal={onCloseAddTimeModal}
          />
        )
      })}
    </div>
  )
}
