import '../styles/report-page.scss'

import React, { Ref, useCallback, useRef } from 'react'
import { ID } from '@datorama/akita'
import { Layout, message } from 'antd'
import { DateRange } from '@blueprintjs/datetime'
import { User } from '../../entities/user/model'
import { Project } from '../../entities/project/model'
import { ReportProject } from '../../entities/report-project/model'
import { useQuery } from '../../utils/queryhook'
import { reportProjectService } from './state/service'
import { reportProjectQuery } from './state/query'
import { ReportProjectFiltersList } from './ReportProjectFiltersList'
import { ReportProjectComponent } from './ReportProjectComponent'
import { projectQuery } from '../../entities/project/query'
import { ReportHeader } from '../ReportHeader'
import { ReportApplyFiltersText } from '../ReportApplyFiltersText'
import { copyElementContentsToClipboard } from '../utils'
import { Spinner } from '../../components/Spinner'
import { useInstantEffect } from '../../utils/instant-effect-hook'
import { Header } from '../../components/header/Header'
import { CustomScrollbar } from '../../components/custom-scrollbar/CustomScrollbar'

export function ReportProjectPageContainer() {
  const projects = useQuery(projectQuery.adminUserProjects$)
  const users = useQuery(reportProjectQuery.users$)
  const selectedProject = useQuery(reportProjectQuery.selectedProject$)
  const selectedUsersIDs = useQuery(reportProjectQuery.selectedUsersIDs$)
  const startDate = useQuery(reportProjectQuery.startDate$)
  const endDate = useQuery(reportProjectQuery.endDate$)
  const loading = useQuery(reportProjectQuery.loading$)
  const report = useQuery(reportProjectQuery.report$)

  const showFiltersPanel = useQuery(reportProjectQuery.showFiltersPanel$)

  useInstantEffect(() => {
    if (selectedProject && startDate && endDate) {
      reportProjectService.fetchReport(
        selectedProject,
        selectedUsersIDs,
        startDate,
        endDate
      )
    } else {
      reportProjectService.resetReport()
    }
  }, [selectedProject, selectedUsersIDs, startDate, endDate])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const onCopyReport = useCallback(() => {
    if (tableContainerRef.current) {
      const success = copyElementContentsToClipboard(tableContainerRef.current)
      if (success) {
        message.success('Report copied to clipboard.')
      } else {
        message.error('Error copying report to clipboard.')
      }
    }
  }, [tableContainerRef])

  return (
    <ReportProjectPage
      loading={loading}
      projects={projects}
      users={users}
      selectedProject={selectedProject}
      selectedUsersIDs={selectedUsersIDs}
      startDate={startDate}
      endDate={endDate}
      report={report}
      onChangeProject={reportProjectService.setProject}
      onChangeSelectedUsersIDs={reportProjectService.setUsers}
      onChangeDates={reportProjectService.setDates}
      onResetFilters={reportProjectService.clearFilters}
      showFiltersPanel={showFiltersPanel}
      onToggleFiltersPanel={reportProjectService.setShowFiltersPanel}
      tableContainerRef={tableContainerRef}
      onCopyReport={onCopyReport}
    />
  )
}

interface ReportProjectPageProps {
  loading: boolean
  projects: Project[]
  users: User[]
  selectedProject: Project | null
  selectedUsersIDs: ID[]
  startDate: Date | null
  endDate: Date | null
  report: ReportProject | null
  onChangeProject: (project: Project | null) => void
  onChangeSelectedUsersIDs: (usersIDs: ID[]) => void
  onChangeDates: (dates: DateRange) => void
  onResetFilters: () => void
  showFiltersPanel: boolean
  onToggleFiltersPanel: (value: boolean) => void
  tableContainerRef: Ref<HTMLDivElement>
  onCopyReport: () => void
}

export function ReportProjectPage(props: ReportProjectPageProps) {
  const { onToggleFiltersPanel } = props
  const onShowFiltersPanel = useCallback(
    () => onToggleFiltersPanel(true),
    [onToggleFiltersPanel]
  )

  return (
    <Layout className={'report-page'}>
      <Header title="Report: Project" />
      <Layout.Content style={{ display: 'flex', flex: '1 1 0' }}>
        <ReportProjectFiltersList
          show={props.showFiltersPanel}
          users={props.users}
          selectedUsersIDs={props.selectedUsersIDs}
          projects={props.projects}
          selectedProject={props.selectedProject}
          startDate={props.startDate}
          endDate={props.endDate}
          onChangeProject={props.onChangeProject}
          onChangeSelectedUsersIDs={props.onChangeSelectedUsersIDs}
          onChangeDates={props.onChangeDates}
          onResetFilters={props.onResetFilters}
        />
        <CustomScrollbar
          className="report-page__main"
          theme="light"
          rendererHeight="auto"
          contentStyle={{
            height: 'auto',
            display: 'flex',
            minHeight: '100%',
          }}
        >
          <Layout.Content style={{ flex: '1 1 0' }}>
            <Layout.Content
              className={'report-page__content'}
              style={{ backgroundColor: 'var(--color-background)' }}
            >
              <ReportHeader
                project={props.selectedProject}
                startDate={props.startDate}
                endDate={props.endDate}
                showFiltersPanel={props.showFiltersPanel}
                onToggleFiltersPanel={props.onToggleFiltersPanel}
                copyReportButtonActive={!!props.selectedProject}
                onCopyReport={props.onCopyReport}
              />
              {props.loading ? (
                <Spinner />
              ) : props.report ? (
                <ReportProjectComponent
                  report={props.report}
                  tableContainerRef={props.tableContainerRef}
                />
              ) : (
                <ReportApplyFiltersText onClick={onShowFiltersPanel} />
              )}
            </Layout.Content>
          </Layout.Content>
        </CustomScrollbar>
      </Layout.Content>
    </Layout>
  )
}
