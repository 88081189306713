import React, { useRef, useState } from 'react'
import { Input, Button } from 'antd'
import { ElementPosition } from '../../../ui/element-position'

import './dialog.css'

export interface EditorUrlDialogProps {
  targetElement: HTMLElement
  appendTo: HTMLElement
  offsetTop: number
  offsetLeft: number
  initialHref: string
  autoFocus?: boolean
  onHrefChange(href: string): void
}

export const Dialog = ({
  targetElement,
  offsetTop,
  offsetLeft,
  initialHref,
  autoFocus,
  appendTo,
  onHrefChange,
}: EditorUrlDialogProps) => {
  const appendToRef = useRef(appendTo)
  const targetRef = useRef(targetElement)
  targetRef.current = targetElement

  const [href, setHref] = useState(initialHref)

  const [isClosed, setIsClosed] = useState(false)

  const handleHrefChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHref(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave()
      event.stopPropagation()
      event.preventDefault()
    }
  }

  const handleSave = () => {
    onHrefChange(href)
    setIsClosed(true)
  }
  const handleGo = () => {
    window.open(href, '_blank')
  }

  return isClosed ? null : (
    <ElementPosition
      targetElement={targetRef}
      boundaryElement={appendToRef}
      appendTo={appendToRef}
      zIndex={10000}
      my="left bottom"
      at={`left+${offsetLeft} top+${offsetTop}`}
    >
      <div className="external-link-dialog-container">
        <Input
          autoFocus={autoFocus}
          placeholder="Enter or paste a link"
          value={href}
          maxLength={1000}
          onChange={handleHrefChange}
          onKeyDown={handleKeyDown}
        />
        {initialHref && <Button onClick={handleGo}>Go</Button>}
        <Button onClick={handleSave}>
          {initialHref ? (href ? 'Save' : 'Remove link') : 'Add Link'}
        </Button>
      </div>
    </ElementPosition>
  )
}
