import { WebSocketConnection, websocketConnection } from './connection'
import { WebSocketMessage, WebsocketMessageProcessor } from './types'
import { entityUpdateProcessor } from './entity-update-processor'
import { trackerUpdateProcessor } from './tracker-update-processor'
import { serverNotificationProcessor } from './server-notification-processor'

export class IncomingMessageProcessor {
  constructor(
    private connection: WebSocketConnection<WebSocketMessage>,
    private processors: WebsocketMessageProcessor[]
  ) {}

  init() {
    this.connection.messages.subscribe((message) => {
      for (const processor of this.processors) {
        if (processor.process(message)) {
          break
        }
      }
    })
  }
}

export const incommingMessageProcessor = new IncomingMessageProcessor(
  websocketConnection,
  [entityUpdateProcessor, trackerUpdateProcessor, serverNotificationProcessor]
)
