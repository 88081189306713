import './styles/inserted-text.scss'

import React from 'react'

interface InsertedTextProps {
  children: React.ReactNode
}

export function InsertedText(props: InsertedTextProps) {
  return <div className="inserted-text">{props.children}</div>
}
