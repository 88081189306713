import React, {useEffect} from "react"
import { EditorView } from "prosemirror-view"
import { PluginKey } from "prosemirror-state"
import { isEqual } from 'lodash'

import {useProseMirrorContext} from "../../../core/provider"
import { Dialog } from "./Dialog"


interface EditorDialogProps {
  view: EditorView,
  pluginKey?: PluginKey | null
}


export const EditorDialog = ({view, pluginKey}: EditorDialogProps) => {
  const {
    enable, targetElement, offset, query, command, items, activeIndex
  } = pluginKey?.getState(view.state)
  const { providers: { mentionProvider: provider } } = useProseMirrorContext()
  const users = provider.useSearch({query})

  useEffect(() => {
    if (!isEqual(users, items) && pluginKey) {
      const tr = view.state.tr
      tr.setMeta(pluginKey, {items: users})
      view.dispatch(tr)
    }
  })

  if (!enable || !users.length ) { return null }

  return <Dialog
    appendTo={view.dom.parentElement?.parentElement as HTMLElement}
    activeIndex={activeIndex}
    targetElement={targetElement}
    offset={offset}
    users={users}
    onClick={({id, title}: {id: number | string, title: string}) => command({id, title})}
  />
}
