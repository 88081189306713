import React from 'react'
import classNames from 'classnames'

interface HighlightedActivityProps {
  children: React.ReactNode
  isBold?: boolean
  isUpperCase?: boolean
}

export function HighlightedActivity(props: HighlightedActivityProps) {
  const { children, isBold, isUpperCase } = props
  const classes = classNames('task-activity__highlighted', {
    'task-activity__text-bold': isBold,
    'task-activity__text-uppercase': isUpperCase
  })
  return <span className={classes}>{children}</span>
}
