import { Icon, Popover, PopoverPosition } from '@blueprintjs/core'
import { ID } from '@datorama/akita'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { UsersSuggest } from '../../../../components/controls/UsersSuggest'
import { User, ShortUser } from '../../../../entities/user/model'

interface EditFollowersPopoverProps {
  users: (User | ShortUser)[]
  currentFollowers: ID[]
  isOpen: boolean
  handleInteraction: (nextOpenState: boolean) => void
  onAddFollowers: (idsToAdd: ID[]) => void
}

export function EditFollowersPopover(props: EditFollowersPopoverProps) {
  const targetClasses = classNames('edit-followers-popover__target', {
    'is-active': props.isOpen,
  })
  return (
    <Popover
      position={PopoverPosition.TOP}
      popoverClassName="edit-followers-popover"
      isOpen={props.isOpen}
      onInteraction={(state) => {
        props.handleInteraction(state)
      }}
      portalClassName="edit-followers-portal"
    >
      <Button className={targetClasses}>
        <Icon icon="plus" iconSize={12} />
      </Button>
      <div className="edit-followers-popover__content">
        <h3 className="edit-followers-popover__title">Edit Followers</h3>
        <div className="edit-followers-popover__users">
          <UsersSuggest
            users={props.users}
            popoverPosition="bottom"
            selectedUsersIDs={props.currentFollowers}
            onChangeSelectedUsersIDs={props.onAddFollowers}
            noUnassignedUser={true}
          />
        </div>
      </div>
    </Popover>
  )
}
