import React, { useState } from 'react'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import classNames from 'classnames'
import { FilterButton } from '../TaskPanel/FilterButton'
import { TaskPanelSectionTitle } from '../TaskPanel/TaskPanelSectionTitle'
import './styles/task-filter-popover.scss'

interface TaskPanelFilterPopoverProps {
  sectionName: string
  children: React.ReactNode
  noIconBackground?: boolean
  popoverClassName?: string
}

export function TaskPanelFilterPopover(props: TaskPanelFilterPopoverProps) {
  const { sectionName } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleInteraction = (nextOpenState: boolean) => {
    setIsOpen(nextOpenState)
  }

  const popoverClasses = classNames(
    'popover',
    'task-filter-popover',
    props.popoverClassName
  )

  return (
    <Popover
      minimal={true}
      position={Position.BOTTOM_LEFT}
      interactionKind={PopoverInteractionKind.CLICK}
      popoverClassName={popoverClasses}
      isOpen={isOpen}
      onInteraction={(state) => {
        handleInteraction(state)
      }}
    >
      <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <FilterButton noIconBackground={props.noIconBackground} isActive={isOpen} iconName="filter-outlined" />
        <TaskPanelSectionTitle>{sectionName}</TaskPanelSectionTitle>
      </div>
      <ul className="bp3-menu">{props.children}</ul>
    </Popover>
  )
}
