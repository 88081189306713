import React, { useEffect } from 'react'
import { workspaceService } from '../entities/workspace/service'
import { useQuery } from '../utils/queryhook'
import { workspaceQuery } from '../entities/workspace/query'
import { Loader } from './Loader'

export function MainInnerContainer(props: { children: React.ReactNode }) {
  useEffect(() => {
    workspaceService.loadWorkspaces()
  }, [])
  const loaded = useQuery(workspaceQuery.loaded$)

  if (!loaded) {
    return <Loader />
  }

  return <>{props.children}</>
}
