import './styles/add-time-manually-date.scss'
import './styles/add-time-manually-input.scss'

import React, { useCallback, useState } from 'react'
import { DateInput } from '@blueprintjs/datetime'
import classNames from 'classnames'

import { formatDate, parseDate } from '../../utils/date'

interface AddTimeManuallyDatePickerProps {
  className?: string
  date: Date | null
  onChange: (value: Date) => void
}

export function AddTimeManuallyDatePicker(
  props: AddTimeManuallyDatePickerProps
) {
  const { onChange } = props
  const [date, setDate] = useState(props.date)

  const classes = classNames('add-time-manually-date', props.className)

  const onChangeHandler = useCallback(
    (value) => {
      setDate(value)
      onChange(value)
    },
    [onChange]
  )

  return (
    <div className={classes}>
      <DateInput
        highlightCurrentDay={true}
        value={date}
        onChange={(newDate) => onChangeHandler(newDate)}
        canClearSelection={false}
        minDate={new Date(2009, 12, 31)}
        maxDate={new Date(2030, 11, 31)}
        placeholder="Select date"
        parseDate={(value: string) => parseDate(value)}
        formatDate={(date: Date) => formatDate(date)}
        popoverProps={{
          targetClassName:
            'add-time-manually-date-input add-time-manually-input',
          targetTagName: 'div',
          position: 'bottom',
          minimal: true,
          modifiers: {
            offset: {
              offset: '0 10px',
            },
          },
        }}
      />
    </div>
  )
}
