import React, { useCallback } from 'react'
import classNames from 'classnames'

import {
  Subtask,
  SubtaskEstimate,
  SubtaskType,
} from '../../../entities/subtask/model'
import { SubtaskChangeEstimation } from './SubtaskChangeEstimation'
import {
  SubtaskChangeEstimationTrackerControl,
  SubtaskChangeEstimationWithComment,
} from './SubtaskChangeEstimationWithComment'
import { TrackerControl } from '../../../tracking/TrackerControl'
import {
  Activity,
  SUBTASK_ACTIVITIES,
  SUBTASK_BUG_ACTIVITIES,
} from '../../../entities/choices/activity'
import { StartTrackingCallback } from '../../../tracking/types'
import { TrackingState } from '../../../tracking/state/store'
import { Popover } from 'antd'
import { IFormErrors } from '../../state/store'

interface SubtaskEstimationProps {
  subtask: Subtask
  onSubtaskEstimateChange: (estimate: Partial<SubtaskEstimate>) => void
  trackingState?: TrackingState
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function SubtaskEstimation(props: SubtaskEstimationProps) {
  const {
    subtask,
    onSubtaskEstimateChange,
    trackingState,
    onStopTracking,
    onStartTracking,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props
  const ACTIVITIES =
    subtask.type === SubtaskType.BUG
      ? SUBTASK_BUG_ACTIVITIES
      : SUBTASK_ACTIVITIES

  const isEstimationIncreased =
    subtask.initial_estimate < subtask.current_estimate

  const isInitialEstimateZero = subtask.initial_estimate === 0

  const isCurrentEstimateEqualsInitialEstimate =
    subtask.current_estimate === subtask.initial_estimate

  let exclamationMarkQuantity
  if (!isInitialEstimateZero && !isCurrentEstimateEqualsInitialEstimate) {
    exclamationMarkQuantity = Math.round(
      subtask.current_estimate / subtask.initial_estimate
    )
    exclamationMarkQuantity =
      exclamationMarkQuantity <= 5 ? exclamationMarkQuantity : 5
  }

  const estimationClasses = classNames('subtasks__estimation', {
    subtasks__estimation_increased: isEstimationIncreased,
  })

  const _onStartTracking = useCallback(
    (activity: Activity | null) => {
      onStartTracking && onStartTracking('subtask', +subtask.id, activity)
    },
    [onStartTracking, subtask.id]
  )

  const changeEstimateRequired =
    trackingState?.update_required &&
    trackingState?.target?.type === 'subtask' &&
    trackingState?.target?.id === subtask.id

  return (
    <div className={estimationClasses}>
      <span className="subtasks__estimation-current">
        <TrackerControl
          seconds={subtask.time_logged}
          trackingObject={subtask}
          trackingObjectType="subtask"
          activities={ACTIVITIES}
          onStop={onStopTracking}
          onStart={_onStartTracking}
          showHintTooltip={true}
          hintTooltipProps={{
            placement: 'top',
            content: 'Time spent on the subtask',
          }}
          addTimeFormErrors={addTimeFormErrors}
          isAddTimeFormOpened={isAddTimeFormOpened}
          onOpenAddTimeModal={onOpenAddTimeModal}
          onCloseAddTimeModal={onCloseAddTimeModal}
        />
      </span>
      <span className="subtasks__slash"> / </span>

      {changeEstimateRequired && (
        <Popover
          placement="top"
          trigger="hover"
          content="Current estimate for the subtask"
        >
          <SubtaskChangeEstimationTrackerControl
            subtask={subtask}
            changeSubtaskEstimate={onSubtaskEstimateChange}
            required={true}
            placement="left"
          />
        </Popover>
      )}

      {isInitialEstimateZero ? (
        <SubtaskChangeEstimation
          subtask={subtask}
          changeSubtaskEstimate={onSubtaskEstimateChange}
        />
      ) : (
        <SubtaskChangeEstimationWithComment
          subtask={subtask}
          changeSubtaskEstimate={onSubtaskEstimateChange}
          exclamationMarkQuantity={exclamationMarkQuantity}
        />
      )}
    </div>
  )
}
