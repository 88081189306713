import { Query } from '@datorama/akita'
import { AuthState, AuthStore, authStore } from './store'
import { UserQuery, userQuery } from '../../entities/user/query'
import { switchMap } from 'rxjs/operators'

export class AuthQuery extends Query<AuthState> {
  checked$ = this.select('checked')
  inviteChecked$ = this.select('inviteChecked')
  authPending$ = this.select('authPending')
  authenticated$ = this.select((state) =>
    state.checked ? !!state.currentUserId : null
  )
  authErrors$ = this.select((state) =>
    state.checked ? state.authErrors : null
  )
  inviteData$ = this.select((state) => state.inviteChecked ? state.inviteData : null)
  redirectTo$ = this.select('loginRedirectTo')

  currentUser$ = this.select('currentUserId').pipe(
    switchMap((id) => this.userQuery.selectEntity(id!))
  )

  constructor(store: AuthStore, private userQuery: UserQuery) {
    super(store)
  }
}

export const authQuery = new AuthQuery(authStore, userQuery)
