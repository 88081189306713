import '../../styles/user-profile.scss'

import React, { useState } from 'react'
import moment from 'moment-timezone'
import { ID } from '@datorama/akita'
import { ChangePasswordData, User } from '../../../entities/user/model'
import { Project } from '../../../entities/project/model'
import { UserProfileHeading } from './UserProfileHeading'
import { UserProfileStats } from './UserProfileStats'
import { UserProfileMainInfo } from './UserProfileMainInfo'
import { ModelUpdateCallback } from '../../../utils/types'
import { ChangeUserPasswordContainer } from '../change-password/ChangePasswordContainer'
import { Observable } from 'rxjs'

interface UserProfileMainProps {
  user: User
  allProjects: Project[]
  isCurrentUser: boolean
  adminView: boolean
  onUserUpdate: ModelUpdateCallback<User>
  onUploadAvatar: (userId: ID, file: File) => void
  onProjectsChange: (user: User, projects: Project[]) => void
  onChangePassword: (data: ChangePasswordData) => Observable<void>
}

export function UserProfileMain(props: UserProfileMainProps) {
  const [showPasswordDialog, setShowPasswordDialog] = useState<boolean>(false)
  const onClosePasswordDialog = () => {
    setShowPasswordDialog(false)
  }
  const onOpenPasswordDialog = () => {
    setShowPasswordDialog(true)
  }
  return (
    <div className="user-profile__main">
      <div className="user-profile__main-left">
        <UserProfileHeading
          user={props.user}
          editable={props.adminView}
          onUserUpdate={props.onUserUpdate}
        />
        <UserProfileMainInfo
          isCurrentUser={props.isCurrentUser}
          adminView={props.adminView}
          user={props.user}
          allProjects={props.allProjects}
          onUserUpdate={props.onUserUpdate}
          onUploadAvatar={props.onUploadAvatar}
          onProjectsChange={props.onProjectsChange}
        />
      </div>
      {!props.user.is_invited && (
        <div className="user-profile__main-right">
          <UserProfileStats
            startDate={
              props.user.start_date ? moment(props.user.start_date) : null
            }
            lastSeen={
              props.user.last_seen ? moment(props.user.last_seen) : null
            }
            hoursWorked={props.user.hours_worked}
          />
          {props.isCurrentUser && (
            <div className="user-profile__change-password">
              <ChangeUserPasswordContainer
                isDialogOpen={showPasswordDialog}
                onDialogOpen={onOpenPasswordDialog}
                onDialogClose={onClosePasswordDialog}
                onChangePassword={props.onChangePassword}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
