import {BoundingElementRect, CollisionRule, CollisionRuleData } from './ElementPosition.types';

export const collisionRule: CollisionRule = {
  fit: {
    left: (position: BoundingElementRect, data: CollisionRuleData): boolean => {
      const { boundaryOffset, collisionWidth } = data;
      const boundaryLeft = boundaryOffset.left;
      const outerWidth = boundaryOffset.width;
      const overLeft = boundaryLeft - position.left;
      const overRight = position.left + collisionWidth - outerWidth - boundaryLeft;

      // Element is wider than within
      if (collisionWidth > outerWidth) {
        // Element is initially over the left side of within
        if (overLeft > 0 && overRight <= 0) {
          const newOverRight =
            position.left + overLeft + collisionWidth - outerWidth - boundaryLeft;
          position.left += overLeft - newOverRight;

          // Element is initially over right side of within
        } else if (overRight > 0 && overLeft <= 0) {
          position.left = boundaryLeft;

          // Element is initially over both left and right sides of within
        } else {
          if (overLeft > overRight) {
            position.left = boundaryLeft + outerWidth - collisionWidth;
          } else {
            position.left = boundaryLeft;
          }
        }

        // Too far left -> align with left edge
      } else if (overLeft > 0) {
        position.left += overLeft;

        // Too far right -> align with right edge
      } else if (overRight > 0) {
        position.left -= overRight;

        // Adjust based on position and margin
      } else {
        position.left = Math.max(position.left - position.left, position.left);
      }

      return false;
    },
    top: (position: BoundingElementRect, data: CollisionRuleData): boolean => {
      const { boundaryOffset, collisionHeight } = data;
      const boundaryTop = boundaryOffset.top;
      const outerHeight = boundaryOffset.height;
      const overTop = boundaryTop - position.top;
      const overBottom = position.top + collisionHeight - outerHeight - boundaryTop;

      // Element is taller than within
      if (collisionHeight > outerHeight) {
        // Element is initially over the top of within
        if (overTop > 0 && overBottom <= 0) {
          const newOverBottom =
            position.top + overTop + collisionHeight - outerHeight - boundaryTop;
          position.top += overTop - newOverBottom;

          // Element is initially over bottom of within
        } else if (overBottom > 0 && overTop <= 0) {
          position.top = boundaryTop;

          // Element is initially over both top and bottom of within
        } else {
          if (overTop > overBottom) {
            position.top = boundaryTop + outerHeight - collisionHeight;
          } else {
            position.top = boundaryTop;
          }
        }

        // Too far up -> align with top
      } else if (overTop > 0) {
        position.top += overTop;

        // Too far down -> align with bottom edge
      } else if (overBottom > 0) {
        position.top -= overBottom;

        // Adjust based on position and margin
      } else {
        position.top = Math.max(position.top - position.top, position.top);
      }

      return false;
    },
  },
  flip: {
    left: (position: BoundingElementRect, data: CollisionRuleData): boolean => {
      let flip = false;
      const { boundaryOffset, collisionWidth, elemWidth, targetWidth, offset, my, at } = data;
      const boundaryLeft = boundaryOffset.left + boundaryOffset.scrollLeft;
      const outerWidth = boundaryOffset.width;
      const offsetLeft = boundaryOffset.left;
      const overLeft = position.left - offsetLeft;
      const overRight = position.left + collisionWidth - outerWidth - offsetLeft;
      const myOffset = my[0] === 'left' ? -elemWidth : my[0] === 'right' ? elemWidth : 0;
      const atOffset = at[0] === 'left' ? targetWidth : at[0] === 'right' ? -targetWidth : 0;
      const leftOffset = -2 * offset[0];

      if (overLeft < 0) {
        const newOverRight =
          position.left +
          myOffset +
          atOffset +
          leftOffset +
          collisionWidth -
          outerWidth -
          boundaryLeft;

        if (newOverRight < 0 || newOverRight < Math.abs(overLeft)) {
          position.left += myOffset + atOffset + leftOffset;
          flip = true;
        }
      }

      if (overRight > 0) {
        const newOverLeft = position.left - myOffset + atOffset + leftOffset - offsetLeft;

        if (newOverLeft > 0 || Math.abs(newOverLeft) < overRight) {
          flip = true
        }
      }

      return flip;
    },
    top: (position: BoundingElementRect, data: CollisionRuleData): boolean => {
      let flip = false;
      const { boundaryOffset, collisionHeight, elemHeight, targetHeight, offset, my, at } = data;

      const boundaryTop = boundaryOffset.top + boundaryOffset.scrollTop;
      const outerHeight = boundaryOffset.height;
      const offsetTop = boundaryOffset.top;
      const overTop = position.top - offsetTop;
      const overBottom = position.top + collisionHeight - outerHeight - offsetTop;
      const top = my[1] === 'top';
      const myOffset = top ? -elemHeight : my[1] === 'bottom' ? elemHeight : 0;
      const atOffset = at[1] === 'top' ? targetHeight : at[1] === 'bottom' ? -targetHeight : 0;
      const topOffset = -2 * offset[1];

      if (overTop < 0) {
        const newOverBottom =
          position.top +
          myOffset +
          atOffset +
          topOffset +
          collisionHeight -
          outerHeight -
          boundaryTop;

        if (newOverBottom < 0 || newOverBottom < Math.abs(overTop)) {
          position.top += myOffset + atOffset + topOffset;
          flip = true;
        }
      }

      if (overBottom > 0) {
        const newOverTop = position.top - myOffset + atOffset + topOffset - offsetTop;

        if (newOverTop > 0 || Math.abs(newOverTop) < overBottom) {
          position.top += myOffset + atOffset + topOffset;
          flip = true;
        }
      }

      return flip;
    },
  },
  flipfit: {
    left(position: BoundingElementRect, data: CollisionRuleData): boolean {
      const flip = collisionRule.flip.left.call(this, position, data) as boolean;
      collisionRule.fit.left.call(this, position, data);
      return flip;
    },
    top (position: BoundingElementRect, data: CollisionRuleData): boolean {
      const flip = collisionRule.flip.top.call(this, position, data) as boolean;
      collisionRule.fit.top.call(this, position, data);

      return flip;
    },
  },
};
