import { NodeSpec, Node as ProseMirrorNode } from 'prosemirror-model';

export const MentionNodeSpec: NodeSpec = {
  attrs: { id: {}, prefix: {}, title: {default: "Mention"} },
  inline: true,
  group: 'inline',
  draggable: true,
  toDOM: (node: ProseMirrorNode): any => [
    'a',
    {
      class: 'mention',
      'mention-id': node.attrs.id,
      title: node.attrs.title,
    },
    `${node.attrs.prefix}${node.attrs.title}`,
  ],
  parseDOM: [
    {
      tag: 'a.mention',
      getAttrs: (dom: any): { [key: string]: string } | false => {
        const title = dom.textContent.substr(1)
        const prefix = dom.textContent[0]
        const id = dom.getAttribute('mention-id')
        if (!id || !title) { return false }
        return { title, id, prefix }
      },
    },
  ],
}
