import React from 'react'

import '../../styles/titles.scss'

interface UserProfileFieldTitleProps {
  label: string
}

export function UserProfileFieldTitle(props: UserProfileFieldTitleProps) {
  return <h2 className="user-field-title">{props.label}</h2>
}
