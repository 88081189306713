import {
  CSSProperties,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { User } from '../entities/user/model'
import { Sprint } from '../entities/sprint/model'
import { Task } from '../entities/task/model'
import { Epic } from '../entities/epic/model'
import { ID } from '@datorama/akita'
import { ModelCreateCallback, ModelUpdateCallback } from '../utils/types'
import {
  AddTimeManuallyCallback,
  StartTrackingCallback,
} from '../tracking/types'
import { Project } from '../entities/project/model'
import { MoveItemCallback } from './drag-n-drop'
import { IFormErrors } from './state/store'
import { TaskType } from '../entities/task-type/model'

const HEADER_SIZE = 88
const HEADER_SIZE_WITH_DATES = 108
const ITEM_SIZE = 63
const COLLAPSED_ITEM_SIZE = 48
const COLLAPSED_ITEM_SIZE_WITH_DATES = 68
const SPINNER_HEIGHT = 64

export const VLIST_STYLES = { willChange: 'unset !important' }

export interface Dictionary<T> {
  [index: string]: T[]
}

interface DataProps {
  users: User[]
  tasks: Dictionary<Task>
  currentTask: Task
  baseUrl: string
  collapsed: ID[]
  onTaskUpdate: ModelUpdateCallback<Task>
  onToggle: (id: ID) => void
  onTaskCreate: ModelCreateCallback<Task>
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  onDestroyItem: (id: ID) => void
  sprints: Sprint[]
  epics: Epic[]
  taskTypes: TaskType[]
  onReorderItem: MoveItemCallback
  currentUser?: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

interface SprintDataProps extends DataProps {
  items: Sprint[]
  tasksLoading?: boolean
  onUpdateItem: ModelUpdateCallback<Sprint>
}

interface EpicDataProps extends DataProps {
  items: Epic[]
  sprints: Sprint[]
  taskTypes: TaskType[]
  filteredSprints: Sprint[]
  project: Project
  backlogCollapsed: boolean
  tasksWithoutEpic: Task[]
  tasksLoading?: boolean
  onToggleProjectBacklog: () => void
  onUpdateItem: ModelUpdateCallback<Epic>
}

export interface VScrollSprintRowProps {
  index: number
  style: CSSProperties
  data: SprintDataProps
}

export interface VScrollEpicRowProps {
  index: number
  style: CSSProperties
  data: EpicDataProps
}

export interface ViewPortSize {
  width?: number
  height?: number
}

export function calculateItemHeight(
  itemNum: number,
  collapsed: boolean = false,
  hasDates: boolean = false,
  isLoading: boolean = false,
  headerSize: number = HEADER_SIZE,
  itemSize: number = ITEM_SIZE,
  collapsedItemSize: number = COLLAPSED_ITEM_SIZE
): number {
  if (isLoading && !collapsed) {
    const headerHeight = hasDates ? HEADER_SIZE_WITH_DATES : headerSize
    return headerHeight + SPINNER_HEIGHT
  }
  if (collapsed && !hasDates) return collapsedItemSize
  if (collapsed && hasDates) return COLLAPSED_ITEM_SIZE_WITH_DATES
  if (hasDates) {
    headerSize = HEADER_SIZE_WITH_DATES
  }
  return itemNum * itemSize + headerSize
}

export function calculateOffsetTop(
  currentItem: Sprint | Epic,
  items: Sprint[] | Epic[],
  calculateRow: (index: number) => number
): number {
  // Current item isn't specified
  if (!currentItem) return 0

  const index = items.findIndex(
    (item: Sprint | Epic) => item.id === currentItem.id
  )

  // Current item is from another project
  if (index === -1) return 0

  let top = 0
  for (let i = 0; i < index; i++) top += calculateRow(i)
  return top
}

export function useViewportSize(): [
  ViewPortSize | null,
  RefObject<HTMLDivElement>
] {
  const viewPortRef = useRef<HTMLDivElement>(null)
  const [viewportSize, setviewPortSize] = useState<ViewPortSize>({
    width: 0,
    height: 0,
  })

  const handleResize = useCallback(() => {
    if (!viewPortRef.current) return

    const size = viewPortRef.current.getBoundingClientRect()

    if (
      !viewportSize ||
      size.height !== viewportSize.height ||
      size.width !== viewportSize.width
    ) {
      setviewPortSize({ width: size.width, height: size.height })
    }
  }, [viewPortRef, viewportSize])

  useEffect(() => {
    handleResize()
  }, [viewPortRef, viewportSize, handleResize])

  useEffect(() => {
    handleResize()
  })

  return [viewportSize, viewPortRef]
}
