import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { TaskType } from './model'

export interface TaskTypeState extends EntityState<TaskType, ID> {
  loaded: boolean
}

@StoreConfig({ name: 'entity-task-types' })
export class TaskTypeStore extends EntityStore<TaskTypeState> {}

export const taskTypeStore = new TaskTypeStore()
