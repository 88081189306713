import './styles/header.scss'
import React from 'react'

import { PageSearchContainer } from '../controls/page-search/PageSearchContainer'
import { TrackerStatusContainer } from '../../tracking/TrackerStatusContainer'

interface HeaderProps {
  title?: string
  searchItems?: []
  onItemSelect?: () => void
  children?: React.ReactNode
}

export function Header(props: HeaderProps) {
  return (
    <header className="header">
      <h1 className="header__title-wrap">
        {props.searchItems && (
          <PageSearchContainer
            targetClassName="header__search"
            items={props.searchItems}
          />
        )}
        {!!props.children && !props.title ? (
          props.children
        ) : (
          <>
            {props.children}
            <span className="header__title">{props.title}</span>
          </>
        )}
      </h1>

      <div className="header__right">
        <TrackerStatusContainer />
      </div>
    </header>
  )
}
