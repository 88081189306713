import { ID, Store, StoreConfig } from '@datorama/akita'
import {HistoryActivityItemType} from "../../../../../history/state/model";
import {parseLocalStorage} from "../../../../utils";
import {PerformancePerDay} from "./models";

export interface SprintPanelState {
  sprintId: ID | null
  activitiesFilter: HistoryActivityItemType[],
  performancePerDay: PerformancePerDay
}

const initialState = {
  sprintId: null,
  activitiesFilter: parseLocalStorage("sprintActivityFilter", "statuses") || [
    HistoryActivityItemType.ACTIVITY,
    HistoryActivityItemType.WORKLOG,
  ],
  performancePerDay: {}
}

@StoreConfig({ name: 'ui-sprint-panel' })
export class SprintPanelStore extends Store<SprintPanelState> {}

export const sprintPanelStore = new SprintPanelStore(initialState)
