import classNames from 'classnames'
import React, { CSSProperties, useState } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import './styles/custom-scrollbar.scss'

interface CustomScrollbarProps {
  children?: React.ReactNode
  translateContentSizeYToHolder?: boolean
  translateContentSizeXToHolder?: boolean
  translateContentSizesToHolder?: boolean
  rendererMaxHeight?: number | string
  contentHeight?: number | string
  rendererHeight?: number | string
  scrollTop?: number
  noScrollX?: boolean
  theme?: 'dark' | 'light'
  className?: string
  contentStyle?: CSSProperties
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
  forwardedRef?: any
}

export function CustomScrollbar(props: CustomScrollbarProps) {
  const {
    rendererMaxHeight,
    contentHeight,
    rendererHeight,
    forwardedRef,
    onScroll,
    contentStyle,
    theme = 'dark',
  } = props

  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const classes = classNames('custom-scrollbar', props.className, {
    'is-dark': theme === 'dark',
    'is-light': theme === 'light',
    'is-scrolling': isScrolling,
  })

  return (
    <Scrollbar
      noScrollX={props.noScrollX}
      scrollTop={props.scrollTop}
      removeTracksWhenNotUsed={true}
      permanentTrackY={false}
      scrollDetectionThreshold={500}
      translateContentSizeYToHolder={props.translateContentSizeYToHolder}
      translateContentSizeXToHolder={props.translateContentSizeXToHolder}
      translateContentSizesToHolder={props.translateContentSizesToHolder}
      disableTracksWidthCompensation={true}
      scrollerProps={{
        renderer: (props) => {
          const { elementRef, onScroll: rscOnScroll, ...restProps } = props

          return (
            <div
              {...restProps}
              onScroll={(e) => {
                onScroll && onScroll(e)
                rscOnScroll && rscOnScroll(e)
              }}
              style={{
                ...props.style,
                marginRight: 0,
                paddingRight: 0,
              }}
              ref={(ref) => {
                forwardedRef && forwardedRef(ref)
                elementRef && elementRef(ref)
              }}
              className="custom-scrollbar-scroller"
            />
          )
        },
      }}
      onScrollStart={() => {
        setIsScrolling(true)
      }}
      onScrollStop={() => {
        setIsScrolling(false)
      }}
      renderer={(props) => {
        const { elementRef, ...restProps } = props
        return (
          <div
            {...restProps}
            ref={elementRef}
            className={classes}
            style={{
              maxHeight: rendererMaxHeight || 'auto',
              height: rendererHeight || '100%',
            }}
          />
        )
      }}
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className="custom-scrollbar-wrapper"
            />
          )
        },
      }}
      contentProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                display: 'block',
                height: contentHeight,
                ...contentStyle,
              }}
              className="custom-scrollbar-content"
            />
          )
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                display: 'block',
                backgroundColor:
                  theme === 'dark'
                    ? 'rgba(0, 0, 0, 0.5)'
                    : 'rgba(255, 255, 255, 0.5)',
              }}
              className="custom-scrollbar-thumb custom-scrollbar-thumb_y"
            />
          )
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{ display: 'block' }}
              className="custom-scrollbar-track custom-scrollbar-track_y"
            />
          )
        },
      }}
      thumbXProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                display: 'block',
                backgroundColor:
                  theme === 'dark'
                    ? 'rgba(0, 0, 0, 0.5)'
                    : 'rgba(255, 255, 255, 0.5)',
              }}
              className="custom-scrollbar-thumb custom-scrollbar-thumb_x"
            />
          )
        },
      }}
      trackXProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{ display: 'block' }}
              className="custom-scrollbar-track custom-scrollbar-track_x"
            />
          )
        },
      }}
    >
      {props.children}
    </Scrollbar>
  )
}
