export interface RoleChoice {
  value: string
  label: string
}

export type Role = string

export const ROLES: RoleChoice[] = [
  {
    value: 'developer',
    label: 'Developer',
  },
  {
    value: 'qa',
    label: 'QA',
  },
  {
    value: 'manager',
    label: 'Manager',
  },
  {
    value: 'client',
    label: 'Client',
  },
]

export const getRoleChoice = (choiceValue: string) => {
  return ROLES.find(({ value }) => value === choiceValue)
}

export const getRoleLabel = (choiceValue: string) => {
  return getRoleChoice(choiceValue)?.label || ''
}
