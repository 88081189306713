import { ID } from '@datorama/akita'
import { http } from '../../globals/http'
import { Workspace } from './model'

export class WorkspaceDataService {
  get(id: ID) {
    return http.get<Workspace>(`/api/workspace/${id}/`)
  }

  list() {
    return http.get<Workspace[]>('/api/workspace/')
  }

  lastActivities(id: ID) {
    return http.get(`/api/workspace/${id}/my_last_activities/`)
  }
}

export const workspaceDataService = new WorkspaceDataService()
