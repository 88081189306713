import { preferencesQuery } from './state/query'
import { UserTheme } from '../entities/user/model'

class PreferencesManager {
  private matchMediaQuery = '(prefers-color-scheme: dark)'

  init() {
    this.applyThemeFromCache()
    preferencesQuery.theme$.subscribe((theme) => {
      this.applyThemeFromPreferences(theme)
      this.saveThemeToLocalStorage(theme)
    })
  }

  private applyThemeFromPreferences(theme: UserTheme) {
    if (theme === 'system') {
      this.setThemeFromSystemPreferences()
      this.listenToBrowserTheme()
      return
    }
    this.stopListeningToBrowserTheme()
    this.applyThemeToDocumentBody(theme)
  }

  private setThemeFromSystemPreferences() {
    const isDarkTheme = window.matchMedia?.(this.matchMediaQuery).matches
    const systemTheme = isDarkTheme ? 'dark' : 'light'
    this.applyThemeToDocumentBody(systemTheme)
  }

  private listenToBrowserTheme() {
    window
      .matchMedia(this.matchMediaQuery)
      .addEventListener('change', this.browserThemeListener.bind(this))
  }

  private stopListeningToBrowserTheme() {
    window
      .matchMedia(this.matchMediaQuery)
      .removeEventListener('change', this.browserThemeListener.bind(this))
  }

  private browserThemeListener(event: MediaQueryListEvent) {
    console.log(event.matches)
    const newColorScheme = event.matches ? 'dark' : 'light'
    this.applyThemeToDocumentBody(newColorScheme)
  }

  private applyThemeFromCache() {
    const theme = this.getThemeFromLocalStorage()
    this.applyThemeFromPreferences(theme)
  }

  private getThemeFromLocalStorage() {
    const cachedTheme = localStorage.getItem('theme')
    switch (cachedTheme) {
      case 'dark':
        return 'dark'
      case 'light':
        return 'light'
      case 'system':
        return 'system'
      default:
        return 'light'
    }
  }

  private saveThemeToLocalStorage(theme: string) {
    localStorage.setItem('theme', theme)
  }

  private applyThemeToDocumentBody(theme: UserTheme) {
    document.body.setAttribute('data-theme', theme)
  }
}

export const preferencesManager = new PreferencesManager()
