import classNames from 'classnames'
import React from 'react'
import './styles/error-number.scss'

interface ErrorNumberProps {
  number: string | number
  className?: string
}

export function ErrorNumber(props: ErrorNumberProps) {
  const classes = classNames('error-number', props.className)
  return <div className={classes}>{props.number}</div>
}
