import './styles/filters-list-layout.scss'

import React from 'react'
import classNames from 'classnames'
import { FiltersListResetButton } from '../../filters-list/FiltersListResetButton'

interface FilterListLayoutProps {
  show: boolean
  children: React.ReactNode
  onResetFilters?: () => void
}

export function FiltersListLayout(props: FilterListLayoutProps) {
  const className = classNames(['filters-list-wrap'], {
    'is-expanded': props.show,
  })

  return (
    <div className={className}>
      <div className="filters-list">{props.children}</div>
      {props.onResetFilters && (
        <FiltersListResetButton onClick={props.onResetFilters} />
      )}
    </div>
  )
}
