import { EditorState } from "prosemirror-state"
import {Plugin, PluginKey, Transaction} from "prosemirror-state"

const reactPropsKey = new PluginKey("react-props")

export const reactProps = (data: {[key: string]: any}) => new Plugin({
  key: reactPropsKey,
  state: {
    init: () => data,
    apply: (tr: Transaction, value: any): any => {
      const meta = tr.getMeta(reactPropsKey)
      if (meta) return {...value, ...meta}
      return value
    }
  }
})

export const getReactProps = (state: EditorState) => reactPropsKey.getState(state)

export const updateReactProps = (state: EditorState, props: {[key: string]: any}) =>
  state.tr.setMeta(reactPropsKey, props)
