import {Sprint} from "../entities/sprint/model";
import {Epic} from "../entities/epic/model";
import {
  generateEpicDetailUrl,
  generateSprintDetailUrl,
  generateTaskDetailUrl,
  generateUserDetailUrl
} from "../routes";
import {Project} from "../entities/project/model";
import {ID} from "@datorama/akita";
import {Task} from "../entities/task/model";
import {ShortUser, User} from "../entities/user/model";

export interface SearchItem {
  id: ID
  label: string
  type: 'sprints' | 'epics' | 'tasks' | 'users'
  url: string
}

export function combineProjectPageFilterItems(
  project: Project,
  sprints: Sprint[],
  epics: Epic[],
  tasks: Task[],
  users: User[] | ShortUser[]
): SearchItem[] {
  let searchItems: SearchItem[] = []

  sprints.forEach(sprint => searchItems.push({
    id: sprint.id,
    label: `${sprint.name} (${sprint.status})`,
    type: 'sprints',
    url: generateSprintDetailUrl(project.workspace, project.id, sprint.id)
  }))

  epics.forEach(epic => searchItems.push({
    id: epic.id,
    label: `${epic.name} (${epic.status})`,
    type: 'epics',
    url: generateEpicDetailUrl(project.workspace, project.id, epic.id)
  }))

  tasks.forEach(task => searchItems.push({
    id: task.id,
    label: `${task.code} ${task.name}`,
    type: 'tasks',
    url: generateTaskDetailUrl(project.workspace, project.id, task.code)
  }))

  users.forEach(user => searchItems.push({
    id: user.id,
    label: `${user.full_name}`,
    type: 'users',
    url: generateUserDetailUrl(project.workspace, user.id)
  }))

  return searchItems
}
