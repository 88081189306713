import '../../../users-page/styles/avatar-uploader.scss'

import React, { useState } from 'react'
import classNames from 'classnames'
import { Upload, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {
  RcCustomRequestOptions,
  UploadChangeParam,
} from 'antd/lib/upload/interface'
import { ProjectLogo } from '../../../components/ProjectLogo'
import { Project } from '../../../entities/project/model'

const checkURL = (url: string) => {
  return url.match(/\.(jpeg|jpg|png)$/) != null
}

const MAX_AVATAR_FILE_SIZE = 1024 * 1024 * 10

const validateAvatarFile = (file: File) => {
  const isOfProperType = file.type === 'image/jpeg' || file.type === 'image/png'
  const fitsMaxFileSize = file.size <= MAX_AVATAR_FILE_SIZE

  if (!isOfProperType) {
    message.error('You can only upload JPG/PNG file')
  }
  if (!fitsMaxFileSize) {
    message.error('Avatar file size should be less than 10MB')
  }
  return isOfProperType && fitsMaxFileSize
}

interface ProjectDetailsUploadLogoProps {
  project: Project
  uploadClassName?: string
  avatarClassName?: string
  editable: boolean
  onUploadAvatar?: (file: File) => void
}

export function ProjectDetailsUploadLogo(props: ProjectDetailsUploadLogoProps) {
  const [loading, setLoading] = useState(false)
  const imageURL = props.project.logo

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="ant-upload-text">upload</div>
      )}
    </div>
  )
  const avatarClassNames = classNames(
    'avatar-uploader',
    { 'avatar-uploader_no-border': imageURL && checkURL(imageURL) },
    props.uploadClassName,
    props.avatarClassName
  )

  if (!props.editable) {
    return (
      <ProjectLogo
        size={40}
        project={props.project}
        className={avatarClassNames}
      />
    )
  }

  const customRequest = ({file, onProgress}: RcCustomRequestOptions) => {
    onProgress({ percent: 0 }, file)
    props.onUploadAvatar && props.onUploadAvatar(file)
  }

  const onChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'error') {
      setLoading(false)
      message.error('Failed to load resource')
      return
    }
    if (info.file.status === 'done') {
      setLoading(false)
      message.success('Uploaded successfully')
      return
    }
  }

  return (
    <Upload
      name="avatar"
      style={{
        display: 'none',
      }}
      listType="picture-card"
      className={avatarClassNames}
      showUploadList={false}
      customRequest={customRequest}
      beforeUpload={validateAvatarFile}
      onChange={onChange}
    >
      {imageURL && checkURL(imageURL) ? (
        <ProjectLogo size={40} project={props.project} />
      ) : (
        uploadButton
      )}
    </Upload>
  )
}
