import React, { useState, SyntheticEvent, useCallback } from 'react'
import { Icon } from '@blueprintjs/core'
import { Input } from 'antd'
import classNames from 'classnames'
import { IconNames } from '@blueprintjs/icons'
import { Popover, PopoverPosition } from '@blueprintjs/core'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { Task } from '../../../entities/task/model'
import { TaskPanelSectionButton } from './TaskPanelSectionButton'
import { ID } from '@datorama/akita'
import { useQuery } from '../../../utils/queryhook'
import { taskQuery } from '../../../entities/task/query'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'

export interface LinkedTasksHeaderProps {
  tasks: Task[]
  task: Task
  linkedTasks: Task[]
  onRemoveTask: (idToRemove: ID) => void
  onAddLinkedTask: (idToAdd: ID) => void
  loadTasks: () => void
}

export function LinkedTasksPopoverSearch(props: LinkedTasksHeaderProps) {
  const { tasks, task, linkedTasks, onRemoveTask, onAddLinkedTask, loadTasks } =
    props
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const changeInputValueHandler = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    setSearchValue(target.value)
  }

  const handleInteraction = (nextOpenState: boolean) => {
    setIsOpen(nextOpenState)
    if (!isOpen) {
      setSearchValue('')
    }
  }

  return (
    <Popover
      position={PopoverPosition.BOTTOM_LEFT}
      popoverClassName="linked-tasks-popover"
      isOpen={isOpen}
      onInteraction={(state) => {
        handleInteraction(state)
      }}
    >
      <TaskPanelSectionButton isActive={isOpen} iconName="link-outlined">
        Linked Tasks
      </TaskPanelSectionButton>
      <div className="linked-tasks-popover__content">
        <h3 className="linked-tasks-popover__title">Edit Linked Tasks</h3>
        <Input
          defaultValue={searchValue}
          onChange={changeInputValueHandler}
          className="linked-tasks-popover__search-input"
          placeholder="Enter Task Title, Number or URL"
          suffix={
            <SearchOutlined
              style={{ fontSize: '13px', color: 'var(--color-text-inactive)' }}
            />
          }
        />
        <LinkedTasksPopoverSearchResults
          query={searchValue}
          currentTask={task}
          tasks={tasks}
          onRemoveTask={onRemoveTask}
          onAddLinkedTask={onAddLinkedTask}
          loadTasks={loadTasks}
          linkedTasks={linkedTasks}
        />
        <div className="linked-tasks-popover__tasks-wrap">
          <CustomScrollbar
            translateContentSizeYToHolder={true}
            rendererMaxHeight={150}
          >
            <LinkedTasksPopoverTasksTags
              linkedTasks={linkedTasks}
              onRemoveTask={onRemoveTask}
              onAddLinkedTask={onAddLinkedTask}
            />
          </CustomScrollbar>
        </div>
      </div>
    </Popover>
  )
}

type LinkedTasksPopoverTaskType = 'tag' | 'result'

interface LinkedTasksPopoverTaskProps {
  task: Task
  type: LinkedTasksPopoverTaskType
  onRemoveTask: (idToRemove: ID) => void
  onAddLinkedTask: (idToAdd: ID) => void
}

function LinkedTasksPopoverTask(props: LinkedTasksPopoverTaskProps) {
  const { type, task, onRemoveTask, onAddLinkedTask } = props

  const parentClasses = classNames(
    'linked-tasks-popover__task',
    `linked-tasks-popover__task_${type}`
  )

  const TAG_ICON_SIZE = 10
  const isTypeTag = type === 'tag'
  const widthHeightStyle = isTypeTag ? TAG_ICON_SIZE : 'auto'

  const buttonStyle = {
    width: widthHeightStyle,
    height: widthHeightStyle,
  }

  const iconToRender = isTypeTag ? (
    <Icon icon={IconNames.CROSS} iconSize={TAG_ICON_SIZE} />
  ) : (
    <PlusCircleOutlined />
  )

  const onClick = useCallback(() => {
    if (isTypeTag) {
      onRemoveTask(task.id!)
    } else {
      onAddLinkedTask(task.id!)
    }
  }, [isTypeTag, onAddLinkedTask, onRemoveTask, task.id])

  return (
    <li className={parentClasses} onClick={onClick}>
      <div className="linked-tasks-popover__task-info">
        <div className="linked-tasks-popover__task-code">{task.code}</div>
        <div className="linked-tasks-popover__task-name">{task.name}</div>
      </div>
      <button
        style={buttonStyle}
        className="linked-tasks-popover__result-button"
        onClick={onClick}
      >
        {iconToRender}
      </button>
    </li>
  )
}

interface LinkedTasksPopoverSearchResultsProps {
  tasks: Task[]
  currentTask: Task
  linkedTasks: Task[]
  query: string
  onRemoveTask: (idToRemove: ID) => void
  onAddLinkedTask: (idToAdd: ID) => void
  loadTasks: (...args: unknown[]) => void
}

function LinkedTasksPopoverSearchResults(
  props: LinkedTasksPopoverSearchResultsProps
) {
  const {
    tasks,
    currentTask,
    linkedTasks,
    query,
    onRemoveTask,
    onAddLinkedTask,
    loadTasks,
  } = props
  const filterTasks = (taskIdentifire: string) => {
    return (
      taskIdentifire.toLowerCase().trim().indexOf(query.toLowerCase().trim()) >=
      0
    )
  }
  const tasksWithoutLinkedTasks = tasks.filter(
    (item) => !linkedTasks.includes(item) && item !== currentTask
  )

  let taskCode = ''
  let filteredTasks: Task[] = []
  if (query.trim().length !== 0) {
    if (query.trim().includes('http')) {
      taskCode = query.trim().split('/').slice(-1)[0]
      loadTasks({ task_code: taskCode })
    } else {
      filteredTasks = tasksWithoutLinkedTasks.filter((task: Task) => {
        return filterTasks(task.code) || filterTasks(task.name)
      })
    }
  }
  // get task from another project
  const task = useQuery(taskQuery.selectByCode(taskCode!))
  if (task) {
    filteredTasks.push(task)
  }
  return !!query.length ? (
    <div className="linked-tasks-popover__results-wrap">
      <CustomScrollbar
        translateContentSizeYToHolder={true}
        rendererMaxHeight={200}
      >
        {!!filteredTasks.length ? (
          <ul className="linked-tasks-popover__results">
            {filteredTasks.slice(0, 10).map((task: Task) => {
              return (
                <LinkedTasksPopoverTask
                  key={task.id}
                  task={task}
                  type="result"
                  onRemoveTask={onRemoveTask}
                  onAddLinkedTask={onAddLinkedTask}
                />
              )
            })}
          </ul>
        ) : (
          <span className="linked-tasks-popover__no-results">
            Results not found...
          </span>
        )}
      </CustomScrollbar>
    </div>
  ) : null
}

interface LinkedTasksPopoverTasksTagsProps {
  linkedTasks: Task[]
  onRemoveTask: (idToRemove: ID) => void
  onAddLinkedTask: (idToAdd: ID) => void
}

function LinkedTasksPopoverTasksTags(props: LinkedTasksPopoverTasksTagsProps) {
  const { linkedTasks, onRemoveTask, onAddLinkedTask } = props
  return (
    <ul className="linked-tasks-popover__tasks">
      {linkedTasks.map((linkedTask: Task) => {
        return (
          <LinkedTasksPopoverTask
            key={linkedTask.id}
            task={linkedTask}
            type="tag"
            onRemoveTask={onRemoveTask}
            onAddLinkedTask={onAddLinkedTask}
          />
        )
      })}
    </ul>
  )
}
