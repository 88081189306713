import {SprintPanelState, sprintPanelStore, SprintPanelStore} from "./store";
import {combineQueries, Query} from "@datorama/akita";
import {map, switchMap} from "rxjs/operators";
import {sprintQuery, SprintQuery} from "../../../../../entities/sprint/query";
import {taskQuery, TaskQuery} from "../../../../../entities/task/query";
import {of, EMPTY, combineLatest} from "rxjs";
import {userQuery, UserQuery} from "../../../../../entities/user/query";
import {activityLogQuery, ActivityLogQuery} from "../../../../../entities/activities/query";
import {PerformancePerDay, SprintStatistics, SprintStatisticsItem} from "./models";
import {Task} from "../../../../../entities/task/model";
import {timeEntryQuery, TimeEntryQuery} from "../../../../../entities/time-entry/query";
import {SubtaskQuery, subtaskQuery} from "../../../../../entities/subtask/query";
import {Subtask} from "../../../../../entities/subtask/model";
import {Sprint} from "../../../../../entities/sprint/model";
import moment from "moment/moment";

export class SprintPanelQuery extends Query<SprintPanelState> {
  sprintId$ = this.select('sprintId')

  activityFilter$ = this.select('activitiesFilter')

  sprint$ = this.sprintId$.pipe(
    switchMap(sprintId => this.sprintQuery.selectEntity(sprintId!))
  )

  performancePerDay$ = this.select("performancePerDay")

  sprintWithUser$ = this.sprint$.pipe(
    switchMap((sprint) => {
      if (!sprint) return EMPTY

      return combineQueries([
        this.userQuery.getOrLoadUser$(sprint.created_by),
        this.userQuery.getOrLoadUser$(sprint.updated_by),
      ]).pipe(
        map(([created_by, updated_by]) => {
          return {
            ...sprint,
            created_by_user: created_by,
            updated_by_user: updated_by
          }
        })
      )
    })
  )

  sprintTasks$ = this.sprint$.pipe(
    switchMap(sprint => {
      if (!sprint) return of([])
      return this.taskQuery.forSprint(sprint.id)
    })
  )

  sprintSubTasks$ = this.sprintTasks$.pipe(
    switchMap(tasks => {
      if (!tasks) return of([])
      return this.subtaskQuery.forTasks(tasks.map(task => task.id))
    })
  )

  sprintActivities$ = this.sprint$.pipe(
    switchMap(sprint => {
      if (!sprint) return of([])
        return this.activityLogQuery.selectBySprintID(sprint.id).pipe(
          map(sprintActivities => {
              return sprintActivities.map(sprintActivity => {
                return this.userQuery.getOrLoadUser$(sprintActivity.actor).pipe(
                  map(user => ({ ...sprintActivity, user: user }))
                )
              })
            }
          ),
          map(
          sprintActivities => sprintActivities.length ? combineLatest([...sprintActivities]) : of([])
          ),
          switchMap(sprintActivityObservables => sprintActivityObservables)
        )
      }
    )
  )

  projectUsers$ = this.sprint$.pipe(
    switchMap(sprint => {
      if (!sprint) return of([])
      return this.userQuery.forProject(sprint.project)
    })
  )

  sprintWorklog$ = this.sprint$.pipe(
    switchMap((sprint) => {
      if (!sprint) return of([])
      return this.timeEntryQuery.forSprint(sprint.id)
    })
  )

  sprintStatistics$ = combineLatest([
    this.sprint$,
    this.sprintTasks$,
    this.performancePerDay$,
    this.sprintSubTasks$
  ]).pipe(
    map(([sprint, tasks, performance, subtasks]) =>
      this.calculateUsersStatistics(sprint, tasks, performance, subtasks)
    )
  )

  private calculateUsersStatistics(
    sprint: Sprint,
    tasks: Task[],
    performancePerDay: PerformancePerDay,
    subtasks: Subtask[]
  ) {
    const initialStatisticsData = {
      userId: 0,
      taskCount: 0,
      maxEstimation: 0,
      spentTime: 0,
      leftToSpent: 0,
      performancePerDay: 0,
      performancePerSprint: 0,
      initialEstimate: 0,
      currentEstimate: 0,
      sprint: sprint,
      workingDays: 0
    }

    let statistic: SprintStatistics = {}
    let summary: SprintStatisticsItem = initialStatisticsData
    let unassigned: SprintStatisticsItem = initialStatisticsData

    tasks.forEach(task => {
      let key = task.assignee
      let maxEstimation = task?.max_estimated_time || 0
      let spentTime = task?.time_logged || 0

      let taskSubtasks = subtasks.filter(subtask => subtask.task === task.id)
      let initialEstimate = taskSubtasks.reduce(
        (acc, curr) => acc + curr.initial_estimate,
        0
      )
      let currentEstimate = taskSubtasks.reduce(
        (acc, curr) => acc + curr.current_estimate,
        0
      )

      if (key) {
        // Fill default values
        let inialUserStatisticData = { ...initialStatisticsData, userId: key }
        if (!(key in statistic)) statistic[key] = inialUserStatisticData

        statistic[key] = this.reCalculateStatistics(
          statistic[key], maxEstimation, spentTime, initialEstimate, currentEstimate, sprint
        )
      } else {
        unassigned = this.reCalculateStatistics(unassigned, maxEstimation, spentTime, initialEstimate, currentEstimate, sprint)
      }

      // Update summary
      summary = this.reCalculateStatistics(summary, maxEstimation, spentTime, initialEstimate, currentEstimate, sprint)
    })

    let summeryPerformance = 0
    let summerySprintPerformance = 0

    Object.entries(performancePerDay).forEach(
      ([key, value]) => {
        if (key in statistic) {statistic[key]["performancePerDay"] = value}
        summeryPerformance += value
      }
    )

    Object.entries(statistic).forEach(
      ([key, value]) => {
        const sprintBusinessDays = !!sprint.start_date && !!sprint.release_date ? this.getBusinessDatesCount(sprint.start_date, sprint.release_date) : 1
        // @ts-ignore
        const sprintDays = !!sprint.user_working_days && !!sprint.user_working_days[key] ? sprint.user_working_days[key] : sprintBusinessDays
        value['performancePerSprint'] = value['performancePerDay'] as number * sprintDays
        summerySprintPerformance += value['performancePerSprint']
        value['sprint'] = sprint
        value['workingDays'] = sprintDays
      }
    )

    const statisticValues = Object.values(statistic)
    if (unassigned.taskCount) statisticValues.push(unassigned)

    summary.performancePerDay = summeryPerformance
    summary.performancePerSprint = summerySprintPerformance

    return { statistic: statisticValues, summary }
  }

  private reCalculateStatistics(
    statistic: SprintStatisticsItem,
    additionlaMaxEstimate: number,
    additionalSpentTime: number,
    initialEstimate: number,
    currentEstimate: number,
    sprint: Sprint
  ) {
    return {
      userId: statistic.userId,
      taskCount: statistic.taskCount + 1,
      maxEstimation: statistic.maxEstimation + additionlaMaxEstimate,
      spentTime: statistic.spentTime + additionalSpentTime,
      leftToSpent: (statistic.maxEstimation + additionlaMaxEstimate) - (statistic.spentTime + additionalSpentTime),
      initialEstimate: statistic.initialEstimate + initialEstimate,
      currentEstimate: statistic.currentEstimate + currentEstimate,
      performancePerDay: 0,
      performancePerSprint: 0,
      sprint: sprint,
      workingDays: 0,
    }
  }

  private getBusinessDatesCount(firstDate: string, secondDate: string) {
    const startDate = new Date(firstDate);
    const endDate = new Date(secondDate);
    const lastDay = moment(endDate);
    const firstDay = moment(startDate);
    let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 -
      (firstDay.day() - lastDay.day()) * 2) / 7;

    if (lastDay.day() === 6) calcBusinessDays--;//SAT
    if (firstDay.day() === 0) calcBusinessDays--;//SUN

    return calcBusinessDays;
}


  constructor(
    store: SprintPanelStore,
    private sprintQuery: SprintQuery,
    private taskQuery: TaskQuery,
    private userQuery: UserQuery,
    private activityLogQuery: ActivityLogQuery,
    private timeEntryQuery: TimeEntryQuery,
    private subtaskQuery: SubtaskQuery,
  ) {
    super(store)
  }
}

export const sprintPanelQuery = new SprintPanelQuery(
  sprintPanelStore,
  sprintQuery,
  taskQuery,
  userQuery,
  activityLogQuery,
  timeEntryQuery,
  subtaskQuery
)
