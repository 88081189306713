import { combineLatest, Observable, of } from 'rxjs'
import {
  map,
  pairwise,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { projectPageQuery, ProjectPageQuery } from '../project-page/state/query'
import { WorkspaceQuery, workspaceQuery } from '../entities/workspace/query'
import { WebSocketConnection, websocketConnection } from './connection'
import { difference } from 'lodash'
import { WebSocketMessage } from './types'
import {taskPanelQuery, TaskPanelQuery} from "../project-page/tasks/TaskPanel/state/query";
import {sprintPanelQuery, SprintPanelQuery} from "../project-page/task-groups/details/SprintPanel/state/query";
import {epicPanelQuery, EpicPanelQuery} from "../project-page/task-groups/details/EpicPanel/state/query";

type SubscriptionGroups = Observable<string[]>

export class SubscriptionManager {
  default: SubscriptionGroups = of(['workspaces', 'user'])

  workspace: SubscriptionGroups = this.workspaceQuery
    .selectActiveId()
    .pipe(
      switchMap((id) =>
        id ? of([`workspace-${id}-user`, `workspace-${id}-project`]) : of([])
      )
    )

  project: SubscriptionGroups = this.projectPageQuery.projectId$.pipe(
    switchMap((id) =>
      id
        ? of([
            `project-${id}-sprint`,
            `project-${id}-epic`,
            `project-${id}-task`,
          ])
        : of([])
    )
  )

  task: SubscriptionGroups = this.taskPanelQuery
    .taskId$
    .pipe(
      switchMap((id) =>
        id ? of([
          `task-${id}-subtask`,
          `task-${id}-linkedtask`,
          `task-${id}-activity-log`,
          `task-${id}-time-entry`,
      ]) : of([])
      )
    )

  sprint: SubscriptionGroups = this.sprintPanelQuery
    .sprintId$
    .pipe(
      switchMap((id) =>
        id ? of([
          `sprint-${id}-activity-log`,
          `sprint-${id}-time-entry`,
      ]) : of([])
      )
    )

  epic: SubscriptionGroups = this.epicPanelQuery
    .epicId$
    .pipe(
      switchMap((id) =>
        id ? of([
          `epic-${id}-activity-log`,
          `epic-${id}-time-entry`,
      ]) : of([])
      )
    )

  allGroups = combineLatest([
    this.default,
    this.workspace,
    this.project,
    this.task,
    this.sprint,
    this.epic,
  ]).pipe(map((groups: string[][]) => groups.flat()))

  subscriptionChanges: Observable<{
    add: string[]
    remove: string[]
  }> = this.allGroups.pipe(
    startWith([]),
    pairwise(),
    map(([last, current]) => ({
      add: difference(current, last),
      remove: difference(last, current),
    }))
  )

  init() {
    // update subscriptions by little every time list changes
    this.subscriptionChanges.subscribe((changes) => {
      this.connection.send(['subscribe', changes])
    })

    // subscribe to everything altogether on reconnection
    this.connection.connected
      .pipe(
        withLatestFrom(this.allGroups),
        map(([none, groups]) => groups)
      )
      .subscribe((groups) => {
        this.connection.send(['subscribe', { add: groups }])
      })
  }

  constructor(
    private workspaceQuery: WorkspaceQuery,
    private projectPageQuery: ProjectPageQuery,
    private taskPanelQuery: TaskPanelQuery,
    private sprintPanelQuery: SprintPanelQuery,
    private epicPanelQuery: EpicPanelQuery,
    private connection: WebSocketConnection<WebSocketMessage>
  ) {}
}

export const subscriptionManager = new SubscriptionManager(
  workspaceQuery,
  projectPageQuery,
  taskPanelQuery,
  sprintPanelQuery,
  epicPanelQuery,
  websocketConnection
)
