import React from 'react'
import { FilterButton } from './FilterButton'
import { TaskPanelSectionTitle } from './TaskPanelSectionTitle'

import '../styles/task-panel-section-button.scss'

interface TaskPanelSectionButtonProps {
  children: React.ReactNode
  isActive?: boolean
  iconName: 'link-outlined' | 'filter-outlined'
}

export function TaskPanelSectionButton(props: TaskPanelSectionButtonProps) {
  const { children, iconName, isActive } = props

  return (
    <div className="task-panel-section-button">
      <FilterButton isActive={isActive} iconName={iconName} />
      <TaskPanelSectionTitle>{children}</TaskPanelSectionTitle>
    </div>
  )
}
