import { ID } from '@datorama/akita'
import { ActionType, ActivityLog } from '../../entities/activities/model'
import { Comment } from '../../entities/comment/model'
import {
  getSubtype,
  TimeEntry,
  WorklogType,
} from '../../entities/time-entry/model'
import moment from 'moment/moment'
import { sortBy } from 'lodash'
import { groupByCompare } from '../../utils/group-by-compare'

export enum HistoryActivityItemType {
  ACTIVITY = 'activity',
  COMMENT = 'comments',
  WORKLOG = 'worklog',
}

export interface HistoryActivityItem {
  id: ID
  type: HistoryActivityItemType.ACTIVITY
  date: string
  data: ActionType[]
}

export interface HistoryCommentItem {
  id: ID
  type: HistoryActivityItemType.COMMENT
  date: string
  data: Comment
}

export interface WorkLogItem {
  id: ID
  type: HistoryActivityItemType.WORKLOG
  date: string
  data: WorklogType
}

export type HistoryItem = HistoryActivityItem | HistoryCommentItem | WorkLogItem

export const groupWorklog = (worklogs: TimeEntry[]): WorklogType[] => {
  let groupedWorklog: WorklogType[] = []

  sortBy(worklogs, 'start').forEach((worklog) => {
    const worklogDate = moment(worklog.start).startOf('day').format()

    const data = {
      date: worklogDate,
      userId: worklog.user,
      type: getSubtype(worklog),
      typeId: worklog.target.id,
      entries: [worklog],
    }
    // add data if it not exists in a list
    if (!groupedWorklog.slice(-1)[0]) {
      groupedWorklog.push(data)
    }
    // group items, by several params: data, userId, type and subtype
    // Similar time-entries are been glued in one entry and total duration is displayed within 1 day (24 hours),
    // if no other actions performed in a gap between them.
    const lastItem = groupedWorklog.slice(-1)[0]
    if (
      lastItem.date === worklogDate &&
      lastItem.userId === worklog.user &&
      lastItem.type === getSubtype(worklog) &&
      lastItem.typeId === worklog.target.id
    ) {
      !lastItem['entries'].includes(worklog) &&
        lastItem['entries'].push(worklog)
    } else {
      // if no match, we add new WorklogType to a list
      groupedWorklog.push(data)
    }
  })

  return groupedWorklog
}

export const groupActivities = (activities: ActionType[]): ActionType[][] => {
  const startOfDay = (value: string) => moment(value).startOf('day').format()
  return groupByCompare(
    sortBy(activities, 'date', 'user'),
    (prevActivity, activity) => {
      return (
        activity.actor === prevActivity.actor &&
        activity.event === prevActivity.event &&
        startOfDay(activity.date) === startOfDay(prevActivity.date)
      )
    }
  )
}

export const combineHistory = (
  activities: ActivityLog[],
  sprintWorklogs: TimeEntry[]
): HistoryItem[] => {
  let histories: HistoryItem[] = []

  groupActivities(activities).forEach((activity, index) => {
    const lastItem = activity[activity.length - 1]
    histories.push({
      id: `activity-${index}`,
      type: HistoryActivityItemType.ACTIVITY,
      date: lastItem.date,
      data: activity,
    })
  })

  groupWorklog(sprintWorklogs).forEach((item, index) => {
    const lastItem = item.entries[item.entries.length - 1]
    histories.push({
      id: `${item.userId}-worklog-${index}`,
      type: HistoryActivityItemType.WORKLOG,
      date: lastItem.end,
      data: item,
    })
  })

  return histories
}
