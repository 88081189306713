import { Collapse, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { UserAvatar } from '../../../../components/UserAvatar'
import { ShortUser, User } from '../../../../entities/user/model'
import { CommentsFollowersFormContent } from './CommentsFollowersFormContent'
import { EditFollowersPopover } from './EditFollowersPopover'
import './styles/edit-followers-popover.scss'
import './styles/task-panel-comment-form-editor.scss'
import './styles/task-panel-comment-form.scss'
import { Comment } from '../../../../entities/comment/model'
import { ModelCreateCallback } from '../../../../utils/types'
import { Tooltip, Position } from '@blueprintjs/core'
import { Task } from '../../../../entities/task/model'
import { ID } from '@datorama/akita'
import { Button } from 'antd'

const DEFAULT_TOOLTIP_POSITION = Position.TOP

interface CommentsFollowersFormProps {
  users: (User | ShortUser)[]
  followedUsers: (User | ShortUser)[]
  taskAuthor: User | ShortUser | undefined
  currentUser: User | ShortUser
  currentFollowers: ID[]
  onAddFollowers: (idsToAdd: ID[]) => void
  task: Task
  onCommentCreate: ModelCreateCallback<Comment>
}

export function CommentsFollowersForm(props: CommentsFollowersFormProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isFollowMenuOpened, setIsFollowMenuOpened] = useState(false)

  const handleFollowMenuInteraction = (nextOpenState: boolean) => {
    setIsFollowMenuOpened(nextOpenState)
  }
  const {
    currentUser,
    currentFollowers,
    onAddFollowers,
    task,
    users,
    followedUsers,
    taskAuthor,
  } = props

  const clickHandler = useCallback(() => {
    setIsVisible((prevState) => !prevState)
  }, [])

  const IDsToFollow = currentFollowers.includes(currentUser.id)
    ? currentFollowers.filter((x) => x !== currentUser.id)
    : [...currentFollowers, currentUser.id]

  const onFollowingClickHandler = useCallback(() => {
    onAddFollowers(IDsToFollow)
  }, [IDsToFollow, onAddFollowers])

  const onSubmit = (comment: object) => {
    setIsVisible(false)
    props.onCommentCreate(comment)
  }

  const classes = classNames('task-panel-comment-form', {
    'task-panel-comment-form_visible': isVisible,
  })

  return (
    <div className={classes}>
      <div className="task-panel-comment-form__users-follow">
        <div className="task-panel-comment-form__following-users">
          <div className="task-panel-comment-form__following-user task-panel-comment-form__following-user_author">
            {!!taskAuthor && (
              <Tooltip
                disabled={isFollowMenuOpened}
                content={taskAuthor.full_name}
                position={DEFAULT_TOOLTIP_POSITION}
              >
                <UserAvatar size={22} shape="square" user={taskAuthor} />
              </Tooltip>
            )}
          </div>
          {followedUsers
            .filter((user) => {
              return taskAuthor ? user.id !== taskAuthor.id : user
            })
            .map((user) => {
              return (
                <div
                  key={user.id}
                  className="task-panel-comment-form__following-user"
                >
                  <Tooltip
                    disabled={isFollowMenuOpened}
                    content={user.full_name}
                    position={DEFAULT_TOOLTIP_POSITION}
                  >
                    <UserAvatar size={22} shape="square" user={user} />
                  </Tooltip>
                </div>
              )
            })}
        </div>
        <Tooltip
          className="task-panel-comment-form__edit-action"
          content="Edit Followers"
          position={DEFAULT_TOOLTIP_POSITION}
          disabled={isFollowMenuOpened}
        >
          <EditFollowersPopover
            isOpen={isFollowMenuOpened}
            handleInteraction={handleFollowMenuInteraction}
            users={users}
            currentFollowers={currentFollowers}
            onAddFollowers={onAddFollowers}
          />
        </Tooltip>
        <Tooltip
          disabled={isFollowMenuOpened}
          className="task-panel-comment-form__edit-action"
          content={
            currentFollowers.includes(currentUser.id)
              ? 'Stop Following'
              : 'Follow'
          }
          position={DEFAULT_TOOLTIP_POSITION}
        >
          <Button
            className="task-panel-comment-form__edit-action_follow"
            onClick={onFollowingClickHandler}
          >
            <span>
              <Icon
                icon={
                  currentFollowers.includes(currentUser.id)
                    ? IconNames.DISABLE
                    : IconNames.NOTIFICATIONS
                }
                iconSize={10}
              />
            </span>
          </Button>
        </Tooltip>
      </div>
      <button
        className="task-panel-comment-form__button"
        onClick={clickHandler}
        type="button"
      >
        <Icon
          className="task-panel-comment-form__button-icon"
          icon={IconNames.CHEVRON_UP}
          iconSize={15}
          color="var(--color-text-link)"
        />
      </button>

      <Collapse isOpen={isVisible} transitionDuration={200}>
        <CommentsFollowersFormContent
          users={users}
          task={task}
          onCommentCreate={onSubmit}
        />
      </Collapse>
    </div>
  )
}
