import React, {useCallback, useState} from 'react'
import { EditableTextComponent } from '../../../components/ValidatedEditableText'
import { ProjectDetailsInfoItem } from './ProjectDetailsInfoItem'

interface ProjectDetailsInfoSlackProps {
  slackChannel: string
  disabled?: boolean
  onChange: (value: string) => void
}

export function ProjectDetailsInfoSlack(props: ProjectDetailsInfoSlackProps) {
  const { slackChannel, onChange } = props
  const [value, setValue] = useState(slackChannel)

  const onConfirmHandler = useCallback(
    (value) => {
      if (slackChannel === value) return

      onChange(value)
    },
    [onChange, slackChannel]
  )

  return (
    <ProjectDetailsInfoItem title="slack channel">
      <div className="user-field project-details-info-item__editable-text">
        <EditableTextComponent
          placeholder="#channelname"
          disabled={props.disabled}
          defaultValue={value}
          value={value}
          onChange={setValue}
          onConfirm={onConfirmHandler}
        />
      </div>
    </ProjectDetailsInfoItem>
  )
}
