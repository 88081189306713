import { ID } from '@datorama/akita'
import React from 'react'
import { UsersSuggest } from '../../components/controls/UsersSuggest'
import { STATUSES } from '../../entities/choices/status'
import { ShortUser, User } from '../../entities/user/model'
import { ProjectPageFilterSection } from './ProjectPageFilterSection'
import './styles/project-page-filter-content.scss'
import { TaskStatus } from '../../entities/task/model'
import { ProjectPageFilterOption } from './ProjectPageFilterOption'
import { SprintStatus } from '../../entities/sprint/model'
import { EpicStatus } from '../../entities/epic/model'
import { TaskType } from '../../entities/task-type/model'
import { FiltersListResetButton } from '../../filters-list/FiltersListResetButton'
import { TaskTypesSuggest } from '../../components/controls/TaskTypeSuggest'

export interface SprintStatusChoice {
  value: SprintStatus
  label: string
}

export interface EpicStatusChoice {
  value: EpicStatus
  label: string
}

const DEFAULT_SPRINT_STATUSES: SprintStatusChoice[] = [
  {
    value: SprintStatus.DRAFT,
    label: 'Draft',
  },
  {
    value: SprintStatus.STARTED,
    label: 'Active',
  },
  {
    value: SprintStatus.COMPLETE,
    label: 'Closed',
  },
]

const DEFAULT_EPIC_STATUSES: EpicStatusChoice[] = [
  {
    value: EpicStatus.DRAFT,
    label: 'Draft',
  },
  {
    value: EpicStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: EpicStatus.ONHOLD,
    label: 'On Hold',
  },
  {
    value: EpicStatus.COMPLETE,
    label: 'Closed',
  },
]

interface ProjectPageFilterContentProps {
  users: User[] | ShortUser[]
  taskTypes: TaskType[]
  onFilterTasksByStatus: (value: TaskStatus) => void
  onFilterTasksByAssignee: (assignee: ID[]) => void
  onFilterSprintsByStatus: (status: SprintStatus) => void
  onFilterEpicsByStatus: (status: EpicStatus) => void
  onFilterTasksByType: (taskType: ID[]) => void
  tasksFilter?: { statuses: TaskStatus[]; assignee: ID[]; task_type: ID[] }
  onResetFilters: () => void
  sprintsFilter?: { statuses: SprintStatus[] }
  epicsFilter?: { statuses: EpicStatus[] }
}

export function ProjectPageFilterContent(props: ProjectPageFilterContentProps) {
  return (
    <div className="project-page-filter-content">
      <ProjectPageFilterSection title="Assignee">
        <UsersSuggest
          popoverPosition="bottom"
          selectedUsersIDs={props.tasksFilter?.assignee || []}
          onChangeSelectedUsersIDs={props.onFilterTasksByAssignee}
          users={props.users}
          allowSelectAll={true}
        />
      </ProjectPageFilterSection>
      <ProjectPageFilterSection title="Task status">
        {STATUSES.map((status) => {
          return (
            <div
              key={status.value}
              className="project-page-filter-content__checkbox"
            >
              <ProjectPageFilterOption
                option={status}
                onChange={(value) =>
                  props.onFilterTasksByStatus(value as TaskStatus)
                }
                checked={props.tasksFilter?.statuses.includes(status.value)}
              />
            </div>
          )
        })}
      </ProjectPageFilterSection>

      <ProjectPageFilterSection title="Task Types">
        <TaskTypesSuggest
          selectedIDs={props.tasksFilter?.task_type || []}
          onChangeSelectedIDs={props.onFilterTasksByType}
          taskTypes={props.taskTypes}
          allowSelectAll={true}
        />
      </ProjectPageFilterSection>

      <ProjectPageFilterSection
        title="Sprint Status"
        className="project-page-filter-content__checkboxes"
      >
        {DEFAULT_SPRINT_STATUSES.map((status) => {
          return (
            <div
              key={status.value}
              className="project-page-filter-content__checkbox"
            >
              <ProjectPageFilterOption
                option={status}
                onChange={(value) =>
                  props.onFilterSprintsByStatus(value as SprintStatus)
                }
                checked={props.sprintsFilter?.statuses.includes(status.value)}
              />
            </div>
          )
        })}
      </ProjectPageFilterSection>
      <ProjectPageFilterSection
        title="Epic Status"
        className="project-page-filter-content__checkboxes"
      >
        {DEFAULT_EPIC_STATUSES.map((status) => {
          return (
            <div
              key={status.value}
              className="project-page-filter-content__checkbox"
            >
              <ProjectPageFilterOption
                option={status}
                onChange={(value) =>
                  props.onFilterEpicsByStatus(value as EpicStatus)
                }
                checked={props.epicsFilter?.statuses.includes(status.value)}
              />
            </div>
          )
        })}
      </ProjectPageFilterSection>

      <FiltersListResetButton onClick={props.onResetFilters} />
    </div>
  )
}
