import { applyTransaction, ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { keys, pick } from 'lodash'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { SubtaskDataService, subtaskDataService } from './data-service'
import { Subtask, SubtaskIntoTask, SubtaskEstimate } from './model'
import { SubtaskQuery, subtaskQuery } from './query'
import { SubtaskStore, subtaskStore } from './store'

import { optimisticSaveEnabled } from '../../globals/constants'
import { smartListReOrder } from '../../utils/lists'
import { messageDispatcher } from '../../utils/message-dispatcher'
import { ModelUpdate } from '../../utils/types'

export class SubtaskService {
  constructor(
    private store: SubtaskStore,
    private query: SubtaskQuery,
    private dataService: SubtaskDataService
  ) {}

  setActive(id: ID) {
    this.store.setActive(id)
  }

  /**
   * Create new subtask
   * @param subtask
   */
  createSubtask(subtask: Partial<Subtask>) {
    const result = new Subject<Subtask>()

    this.dataService.create(subtask).subscribe(
      (subtask) => {
        this.store.add(subtask)
        result.next(subtask)
        result.complete()
      },
      (error) => {
        result.error(error)
      }
    )

    return result.asObservable()
  }

  @boundMethod
  updateSubtask(update: ModelUpdate<Subtask>) {
    this.updateOptimistic(update)
  }

  @boundMethod
  destroy(id: ID) {
    const subtask: Subtask = { ...this.query.getEntity(id) }

    this.dataService.destroy(id).subscribe(
      () => {
        this.store.remove(id)
      },
      () => {
        this.store.add(subtask)
      }
    )
  }

  @boundMethod
  moveSubtask(id: ID, index: number) {
    const subtask = { ...this.query.getEntity(id) }
    const subtasks = this.query.getForTask(subtask.task)
    const updates = smartListReOrder(subtask, subtasks, 'task_order', index)

    applyTransaction(() => {
      updates.forEach((update) => this.updateOptimistic(update))
    })
  }

  loadSubtasks(query?: object) {
    this.store.setLoading(true)
    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
        })
      )
      .subscribe((subtasks) => {
        applyTransaction(() => {
          this.store.add(subtasks)
        })
      })
  }

  loadSubtask(id: ID) {
    this.dataService.get(id).subscribe((subtaks) => {
      this.store.add(subtaks)
    })
  }

  @boundMethod
  subtaskIntoTask(id: ID, data: SubtaskIntoTask) {
    this.dataService.toTask(id, data).subscribe(() => {
      this.store.remove(id)
      messageDispatcher.putSuccessMessage(
        'Subtask has been converted to the task'
      )
    })
  }

  @boundMethod
  addSubtaskEstimate(subtaskID: ID, estimate: Partial<SubtaskEstimate>) {
    this.dataService.addEstimate(subtaskID, estimate).subscribe({
      next: (value: ModelUpdate<Subtask>) => {
        this.store.update(value.id, value)
        messageDispatcher.putSuccessMessage('Subtask estimate has been changed')
      },
    })
  }

  /**
   * We first update the value and then try to send it to the backend.
   *
   * If save fails - we rollback the change.
   *
   */
  private updateOptimistic(update: ModelUpdate<Subtask>) {
    const currentSubTask = this.query.getEntity(update.id)
    const currentValues = pick(currentSubTask, keys(update))

    if (optimisticSaveEnabled) {
      this.store.update(update.id, update)
    }

    this.dataService.update(update).subscribe({
      next: (value: ModelUpdate<Subtask>) => {
        this.store.update(value.id, value)
      },
      error: () => {
        this.store.update(update.id, currentValues)
      },
    })
  }
}

export const subtaskService = new SubtaskService(
  subtaskStore,
  subtaskQuery,
  subtaskDataService
)
