import React, {useState} from "react";
import {Extension, ProseMirrorEditor} from "../../../editor";
import {
  AttachmentExtension,
  ExternalLinkExtension, FooterExtension, HorizontalRuleExtension,
  InternalLinkExtension, KeyMapExtension,
  MentionExtension,
  PlaceHolderExtension, StrikethroughExtension, TableExtension, ToolbarExtension, UnderlineExtension
} from "../../../editor/extentions";
import {AttachmentProvider, FooterProvider, InternalLinkProvider, MentionProvider} from "../../../editor/providers";
import {Subtask} from "../../../entities/subtask/model";
import {Transaction} from "prosemirror-state";
import {EditorView} from "prosemirror-view";
import {initialDoc} from "../../../editor/core/hooks";


interface SubtaskEstimationEditorProps {
  value: object | null
  subtask: Subtask
  onChangeEditor: (transaction: Transaction, view: EditorView) => void
}


export function SubtaskEstimationEditor(props: SubtaskEstimationEditorProps) {
  const {subtask, onChangeEditor} = props

  const [textAreaValue] = useState(initialDoc)

  const extensions: Extension[] = [
    new PlaceHolderExtension("Enter the reason why you want to change subtask's estimation"),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new AttachmentProvider(subtask.task)),
    new ToolbarExtension(),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new FooterExtension(new FooterProvider(subtask.task)),
    new KeyMapExtension()
  ]


  return <ProseMirrorEditor
    className="subtasks-change-estimation__field subtasks-change-estimation__field_textarea"
    value={textAreaValue}
    extensions={extensions}
    onChange={onChangeEditor}
  />
}
