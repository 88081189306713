import React, { useEffect } from 'react'
import { Redirect, useParams, Link } from 'react-router-dom'

import { Alert, Button, Form, Input, Typography, Card } from 'antd'

import { useQuery } from '../utils/queryhook'
import { authQuery } from './state/query'
import { authService } from './state/service'
import { Loader } from '../main-page/Loader'

import './styles/registration-form.scss'

export function RegistrationPageContainer() {
  // @ts-ignore
  const { invitationToken } = useParams()

  const registrationFinish = (values: {
    password: string
    confirmPassword: string
  }) => {
    authService.inviteRegistration(
      values.password,
      values.confirmPassword,
      invitationToken || ''
    )
  }

  const checked = useQuery(authQuery.checked$)
  const inviteChecked = useQuery(authQuery.inviteChecked$)
  const inviteData = useQuery(authQuery.inviteData$)
  const authenticated = useQuery(authQuery.authenticated$)
  const authPending = useQuery(authQuery.authPending$)
  const authErrors = useQuery(authQuery.authErrors$)
  const currentUser = useQuery(authQuery.currentUser$)

  useEffect(() => {
    authService.checkAuthentication(true)
  }, [])

  useEffect(() => {
    if (invitationToken) {
      authService.checkInviteToken(invitationToken)
    }
  }, [invitationToken])

  if (!checked || !inviteChecked) {
    return <Loader />
  }

  if (authenticated && currentUser) {
    const profileURL = `/${currentUser.workspaces[0]}/users/${currentUser.id}`
    return <Redirect to={profileURL} />
  }

  return (
    <RegistrationPage
      authPending={authPending}
      authErrors={authErrors}
      inviteData={inviteData}
      onFinish={registrationFinish}
    />
  )
}

export interface RegistrationPage {
  authPending: boolean
  authErrors: { [name: string]: string } | null
  inviteData: { invite: number; email: string } | null
  onFinish: (values: any) => void
}

export function RegistrationPage(props: RegistrationPage) {
  const { onFinish, authErrors, authPending, inviteData } = props

  if (authErrors?.invite_token) {
    return (
      <div>
        <Alert
          message="Invalid invitation."
          description="This invitation is no longer available."
          type="error"
          showIcon
        />
        <Card>
          <Link to="/login">
            <Button type="primary">Login</Button>
          </Link>
        </Card>
      </div>
    )
  }

  return (
    <Form
      className="registration-form text-center"
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item>
        <Typography.Title level={4}>
          Set up your password to continue registration
        </Typography.Title>
      </Form.Item>

      <Form.Item>
        <Input disabled={true} value={(inviteData && inviteData.email) || ''} />
      </Form.Item>

      <Form.Item
        label="Enter new password"
        name="password"
        validateStatus={!!authErrors?.password ? 'error' : ''}
        help={authErrors?.password}
        rules={[
          { required: true, message: 'Field is required' },
          { whitespace: true, message: 'This field may not be blank' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm password"
        name="confirmPassword"
        validateStatus={!!authErrors?.confirm_password ? 'error' : ''}
        help={authErrors?.confirm_password}
        rules={[
          { required: true, message: 'Field is required' },
          { whitespace: true, message: 'This field may not be blank' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item className="text-center">
        <Button type="primary" htmlType="submit" disabled={authPending}>
          Sign up
        </Button>
      </Form.Item>

      <Form.Item className="text-center">
        <Typography.Text type="secondary">
          Or continue registration with Google
        </Typography.Text>
      </Form.Item>

      <Form.Item className="text-center">
        <Button type="primary" htmlType="submit" disabled={authPending}>
          Google account
        </Button>
      </Form.Item>
    </Form>
  )
}
