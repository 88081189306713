import { Node as ProseMirrorNode } from "prosemirror-model"
import { Decoration, EditorView, NodeView } from "prosemirror-view"
import React, { useContext, useRef } from "react"
import ReactDOM from "react-dom"
import { kebabCase } from 'lodash'
import shortid from "shortid"

import { createPortal } from "./callbacks"

interface IReactNodeViewContext {
  node: ProseMirrorNode
  view: EditorView
  getPos: boolean | (() => number)
  decorations: Decoration[]
}

const ReactNodeViewContext = React.createContext<Partial<IReactNodeViewContext>>({
  node: undefined,
  view: undefined,
  getPos: undefined,
  decorations: undefined
});

class ReactNodeView implements NodeView {
  componentRef: React.RefObject<HTMLDivElement>;
  dom?: HTMLElement;
  contentDOM?: HTMLElement;

  constructor(
    public node: ProseMirrorNode,
    public view: EditorView,
    public getPos: boolean | (() => number),
    public decorations: Decoration[],
    public component: React.FC<any>
  ) {
    this.componentRef = React.createRef()
  }

  init() {
    this.dom = document.createElement(this.node.isInline ? "span" : "div")
    this.dom.classList.add(`${kebabCase(this.node.type.name)}-node-view-wrapper`)

    if (!this.node.isLeaf) {
      this.contentDOM = document.createElement("span")
      this.contentDOM.classList.add(`${kebabCase(this.node.type.name)}-node-view-content-wrapper`)
      this.dom.appendChild(this.contentDOM)
    }

    return { nodeView: this, portal: this.renderPortal(this.dom) }
  }

  renderPortal(container: HTMLElement) {
    const Component: React.FC = props => {
      const componentRef = useRef<HTMLDivElement>(null)

      // Update it if need contentDOM
      // useEffect(() => {
      //   const componentDOM = componentRef.current
      //   if (componentDOM != null && this.contentDOM != null) {
      //     if (!this.node.isLeaf) {
      //       componentDOM.firstChild?.appendChild(this.contentDOM)
      //     }
      //   }
      // }, [componentRef])

      return (
        <span ref={componentRef} className={`${kebabCase(this.node.type.name)}-node-view-react-component`}>
          <ReactNodeViewContext.Provider value={{
            node: this.node,
            view: this.view,
            getPos: this.getPos,
            decorations: this.decorations
          }}>
            <this.component {...props} />
          </ReactNodeViewContext.Provider>
        </span>
      )
    }

    return ReactDOM.createPortal(<Component />, container, shortid.generate())
  }

  update = (node: ProseMirrorNode) => {
    return node.type === this.node.type
  }

  destroy() {
    this.dom = undefined
    this.contentDOM = undefined
  }
}

interface TCreateReactNodeView extends IReactNodeViewContext { component: React.FC<any> }
export const createReactNodeView = ({node, view, getPos, decorations, component}: TCreateReactNodeView) => {
  const reactNodeView = new ReactNodeView(node, view, getPos, decorations, component)
  const { nodeView, portal } = reactNodeView.init()
  createPortal(view.state, portal)
  return nodeView
}

export const useReactNodeView = () => useContext(ReactNodeViewContext)
