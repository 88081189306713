import {MarkSpec, Node as ProseMirrorNode} from 'prosemirror-model';


export const strikethroughMark: MarkSpec = {
  strikethrough: {
    parseDOM: [
      {tag: 's'},
      {tag: 'del'},
      {tag: 'strike'},
      {
        style: 'text-decoration',
        getAttrs: (value: ProseMirrorNode | string) => (value === 'line-through' ? {} : false),
      },
    ],
    toDOM: () => ['s', {}, 0],
  },
}
