import React, {useContext} from 'react'
import { AddLinkedTaskAction } from '../../entities/activities/model'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {Link, useRouteMatch} from "react-router-dom";
import {User} from "../../entities/user/model";
import {activityLogTaskContext} from "../../project-page/tasks/TaskPanel/TaskPanel";
import {Task} from "../../entities/task/model";

interface AddLinkedTaskActivityProps {
  action: AddLinkedTaskAction
  date: string
  user: User | string
}

export function AddLinkedTaskActivity(props: AddLinkedTaskActivityProps) {
  const { action, user } = props
  const getTasks = useContext(activityLogTaskContext)
  const task = getTasks.find((x: Task) => x.id === action.data.changed_to.value)
  const routeMatch = useRouteMatch('/:workspace/:project/task/')
  const url = routeMatch?.url + '/' + task?.code

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> linked the{' '}
      <ActivityBoldText><Link to={url}>{action.data.changed_to.label}</Link></ActivityBoldText>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
