import React from 'react'
import { EditorView } from 'prosemirror-view'

import { isMac } from "../../../core/utils"
import { getPositionOffset } from "../../external-link/utils"
import { getMenuState } from "../plugins"
import { MenuBar } from './MenuBar'
import { MenuItem, DropDown } from "../utils"

const getDropDownItemData = (view: EditorView, {options, ...props}: DropDown) => {
  const showMenu = options.map(({run}: MenuItem) => run(view.state)).some((x: boolean) => x)
  if (!showMenu) return null

  return {
    ...props,
    onSelect: (value: number) => {
      options[value].run(view.state, view.dispatch, view)
      view.focus()
    },
    options: options.map(({label, active}: MenuItem, index: number) => ({
      value: index, label, isActive: active && active(view.state),
    }))
  }
}

const getItemData = (view: EditorView, {run, active, label, macLabel, ...props}: MenuItem) => {
  if (!run(view.state)) { return null }
  return {
    ...props,
    label: isMac && macLabel ? macLabel : label,
    isActive: active && active(view.state),
    onClick: (event: React.MouseEvent) => {
      event.preventDefault()
      run(view.state, view.dispatch, view)
      view.focus()
    }
  }
}

export const EditorMenuBar = ({view, items}: {view: EditorView, items?: (MenuItem | DropDown | false)[]}) => {
  const { enable } = getMenuState(view.state)
  if (!enable || !items) { return null }
  if (!view.hasFocus()) { return null }
  const menuItems: any = items.map(
    (item: MenuItem | DropDown | false) => {
      if (!item) return null
      if ((item as DropDown).options) return getDropDownItemData(view, item as DropDown)
      return getItemData(view, item as MenuItem)
    }
  ).filter(x => x)

  const { targetElement, offset } = getPositionOffset(view, view.state.selection.from)
  return (
    <MenuBar
      appendTo={view.dom.parentElement?.parentElement as HTMLElement}
      targetElement={targetElement}
      offset={offset}
      items={menuItems}
    />
  )
}
