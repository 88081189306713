import '../../styles/user-profile.scss'

import React, { useCallback, useMemo } from 'react'
import { User } from '../../../entities/user/model'
import { UserProfileTitleWithDivider } from './UserProfileTitleWithDivider'
import { UserProfileSocialInfo } from './UserProfileSocialInfo'
import { ModelUpdateCallback } from '../../../utils/types'
import { UserProfileTextInfo } from './UserProfileTextInfo'

interface UserProfileIntegrationsProps {
  user: User
  editable: boolean
  onUserUpdate: ModelUpdateCallback<User>
}

export function UserProfileIntegrations(props: UserProfileIntegrationsProps) {
  const { user, onUserUpdate } = props

  const onSlackChange = useCallback(
    (slack_id) => onUserUpdate({ id: user.id, slack_id }),
    [user, onUserUpdate]
  )
  const onSmartSuiteChange = useCallback(
    (smartsuite_key) => onUserUpdate({ id: user.id, smartsuite_key }),
    [user, onUserUpdate]
  )

  const allFieldsAreEmpty = useMemo(
    () => ['slack_id', 'smartsuite_key'].every((value) => !user[value]),
    [user]
  )

  if (!props.editable && allFieldsAreEmpty) {
    return null
  }

  return (
    <div className="user-profile__section">
      <UserProfileTitleWithDivider
        dividerClass={'user-title-divider'}
        orientation={'left'}
        label={'Integrations'}
      />
      <div className="user-profile__infos user-profile__infos_details">
        <UserProfileSocialInfo
          value={props.user.slack_id || ''}
          label={'slack id'}
          editable={props.editable}
          onChange={onSlackChange}
        />
        <UserProfileTextInfo
          modifier={'smartsuite'}
          value={props.user.smartsuite_key || ''}
          label={'SmartSuite'}
          placeholder={'Enter SmartSuite API Key...'}
          editable={props.editable}
          onChange={onSmartSuiteChange}
        />
      </div>
    </div>
  )
}
