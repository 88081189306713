import React from "react"
import ReactDOM from "react-dom"

export const createReactDecorationWidget = (Component: React.FC) => {
  const root = document.createElement("span")
  root.className = "ProseMirror-react-decoration-widget"

  ReactDOM.render(<Component />, root);
  return root
}
