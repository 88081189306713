import {
  Plugin,
  PluginKey,
  EditorState,
  NodeSelection,
  Transaction,
} from "prosemirror-state"

import { ReactPluginView } from '../../core/react-plugin-view'
import { EditorDialog } from './components'

const internalLinkDialogPluginKey = new PluginKey('internal-link-dialog')

export const internalLinkDialogPlugin = (): Plugin => new Plugin({
  key: internalLinkDialogPluginKey,
  state: {
    init: () => ({enable: false}),
    apply: (tr, value, oldState, state) => {
      const meta = tr.getMeta(internalLinkDialogPluginKey)
      const target = getNodeData(state)

      if (meta) { return {enable: meta.enable, target: target} }
      if (target) { return { enable: true, target } }
      return {enable: false}
    }
  },
  view: view => new ReactPluginView(view, EditorDialog)
})

const getNodeData = (state: EditorState): {from: number, to: number, attrs: any} | null => {
  const selection = state.selection as NodeSelection
  const node = selection?.node
  if (!node) { return null }
  if (node.type !== state.schema.nodes.internal_link) { return null }

  return {
    from: selection?.$from.pos,
    to: selection?.$from.pos + node.nodeSize,
    attrs: node.attrs
  }
}

export const getInternalLinkDialogPluginState = (state: EditorState) =>
  internalLinkDialogPluginKey.getState(state)

export const enableUrlDialog = (tr: Transaction) =>
  tr.setMeta(internalLinkDialogPluginKey, { enable: true })

export const disableUrlDialog = (tr: Transaction) =>
  tr.setMeta(internalLinkDialogPluginKey, { enable: false })
