import { Query } from '@datorama/akita'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import _ from 'lodash'
import { UserQuery, userQuery } from '../../entities/user/query'
import { getUserStatuses, User } from '../../entities/user/model'
import { authQuery, AuthQuery } from '../../auth/state/query'
import { UsersPageState, UsersPageStore, usersPageStore } from './store'

export class UsersPageQuery extends Query<UsersPageState> {
  showFiltersPanel$ = this.select('showFiltersPanel')
  projectFilter$ = this.select('projectFilter')
  statusFilters$ = this.select('statusFilters')

  searchQuery$ = this.select('searchQuery')
  users$ = combineLatest([
    this.userQuery.users$,
    this.authQuery.currentUser$,
    this.searchQuery$,
    this.projectFilter$,
    this.statusFilters$,
  ]).pipe(
    map(([users, currentUser, searchQuery, projectFilter, statusFilters]) => {
      return _.orderBy(
        users,
        [(user: User) => user.id === currentUser.id],
        ['desc']
      ).filter((user) => {
        const matchedStatuses = _.intersection(
          statusFilters,
          getUserStatuses(user)
        )
        if (matchedStatuses.length === 0) {
          return false
        }

        if (projectFilter) {
          const projectMatch = user.projects.indexOf(+projectFilter.id) !== -1
          if (!projectMatch) return false
        }

        const query = searchQuery.trim().toLowerCase()
        if (!query) return true

        return (
          user.full_name.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query)
        )
      })
    })
  )

  constructor(
    store: UsersPageStore,
    private authQuery: AuthQuery,
    private userQuery: UserQuery
  ) {
    super(store)
  }
}

export const usersPageQuery = new UsersPageQuery(
  usersPageStore,
  authQuery,
  userQuery
)
