import React, {useContext, useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import { ChangeDescriptionAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {Subtask} from "../../../entities/subtask/model";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";
import {DescriptionCompare} from "../DescriptionCompare";

interface ChangeSubtaskDescriptionActivityProps {
  action: ChangeDescriptionAction
  date: string
  user: User | string
}

export function ChangeSubtaskDescriptionActivity(
  props: ChangeSubtaskDescriptionActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText>{' '}
      updated the description of the {action.target.type} <HighlightedActivity>{subtask?.name}</HighlightedActivity>{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <DescriptionCompare
            oldValue={action.data.changed_from ? action.data.changed_from.value : null}
            newValue={action.data.changed_to.value}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
