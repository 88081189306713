import '../styles/sprint-details-date-picker.scss'

import React, { useCallback, useState } from 'react'
import { Icon } from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import classNames from 'classnames'
import { IconNames } from '@blueprintjs/icons'

import {
  DATE_STORE_FORMAT,
  formatDate,
  parseDate,
} from '../../../../utils/date'

interface SprintDetailsDatePickerProps {
  title: string
  date: string | null
  className?: string
  disabled?: boolean
  onChange: (value: string | null) => void
}

export function SprintDetailsDatePicker(props: SprintDetailsDatePickerProps) {
  const { onChange, disabled } = props
  const currentDate = props.date ? parseDate(props.date) : null

  const [date, setDate] = useState(currentDate)
  const classes = classNames('sprint-details-date-picker', props.className)

  const onChangeHandler = useCallback(
    (value) => {
      if (disabled) return

      const newDate = value ? parseDate(value) : null
      const dateToStore = value
        ? formatDate(value, DATE_STORE_FORMAT).toString()
        : null

      if (currentDate === newDate) return

      onChange(dateToStore)
      setDate(newDate)
    },
    [onChange, currentDate, disabled]
  )

  return (
    <div className={classes}>
      <div className="sprint-details-date-picker__title">{props.title}: </div>
      <div className="sprint-details-date-picker__calendar">
        <Icon
          className="sprint-details-date-picker__icon"
          icon={IconNames.CHEVRON_DOWN}
        />
        <DateInput
          dayPickerProps={{
            firstDayOfWeek: 1,
          }}
          onChange={(newDate) => onChangeHandler(newDate)}
          value={date}
          placeholder="Select date"
          minDate={new Date(2009, 12, 31)}
          maxDate={new Date(2030, 11, 31)}
          highlightCurrentDay={true}
          parseDate={(value: string) => parseDate(value)}
          formatDate={(date: Date) => formatDate(date)}
          disabled={disabled}
          popoverProps={{
            targetTagName: 'div',
            position: 'bottom',
            minimal: true,
            modifiers: {
              offset: {
                offset: '0 10px',
              },
            },
          }}
          inputProps={{
            className: 'sprint-details-date-picker__button',
          }}
        />
      </div>
    </div>
  )
}
