import { ID, QueryEntity } from '@datorama/akita'
import { of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import _ from 'lodash'
import { ProjectState, ProjectStore, projectStore } from './store'
import { workspaceQuery, WorkspaceQuery } from '../workspace/query'
import { authQuery, AuthQuery } from '../../auth/state/query'
import { UserRole } from '../user/model'
import { ProjectStatus } from './model'

export class ProjectQuery extends QueryEntity<ProjectState> {
  loaded$ = this.select('loaded')
  workspaceProjects$ = this.workspaceQuery.selectActiveId().pipe(
    switchMap((workspaceId) => {
      return this.selectAll({
        filterBy: (project) => {
          return +project.workspace === +(workspaceId || 0)
        },
      })
    }),
    map((projects) => {
      return _.sortBy(projects, 'name')
    })
  )
  userProjects$ = this.authQuery.currentUser$.pipe(
    switchMap((currentUser) => {
      return this.workspaceProjects$.pipe(
        map((projects) =>
          projects.filter((project) =>
            currentUser.projects.includes(+project.id)
          )
        )
      )
    })
  )
  adminUserProjects$ = this.authQuery.currentUser$.pipe(
    switchMap((currentUser) => {
      return this.workspaceProjects$.pipe(
        map((projects) => {
          if (currentUser.user_role === UserRole.ADMIN) {
            return projects
          }
          return projects.filter((project) =>
            currentUser.projects.includes(+project.id)
          )
        })
      )
    })
  )
  userActiveProjects$ = this.userProjects$.pipe(
    map((projects) =>
      projects.filter((project) => project.status === ProjectStatus.ACTIVE)
    )
  )

  selectByCode(projectCode: string | null) {
    if (!projectCode) {
      return of(null)
    }
    return this.selectEntity(({ code }) => code === projectCode)
  }

  /**
   * Same as selectEntity, but returns nulls on empty id
   */
  selectEntitySafe(id: ID | null) {
    if (!id) {
      return of(null)
    }
    return this.selectEntity(id)
  }

  constructor(
    store: ProjectStore,
    private workspaceQuery: WorkspaceQuery,
    private authQuery: AuthQuery
  ) {
    super(store)
  }
}

export const projectQuery = new ProjectQuery(
  projectStore,
  workspaceQuery,
  authQuery
)
