import {
  MarkSpec,
  Node as ProseMirrorNode,
  NodeSpec,
  Schema,
} from 'prosemirror-model'
import { isEqual } from 'lodash'
import { DependencyList, EffectCallback, useCallback, useEffect } from 'react'
import OrderedMap from 'orderedmap'
import { addListNodes } from 'prosemirror-schema-list'
import { schema as baseSchema } from 'prosemirror-schema-basic'

import { Extension } from './extension'
import { Doc } from '../types'

function isOfType<Type>(type: string, predicate?: (value: Type) => boolean) {
  return (value: unknown): value is Type => {
    if (typeof value !== type) {
      return false
    }

    return predicate ? predicate(value as Type) : true
  }
}

export const isUndefined = isOfType<undefined>('undefined')

export const isNullOrUndefined = (
  value: unknown
): value is null | undefined => {
  return value === null || isUndefined(value)
}

export const isDocNodeEmpty = (node: ProseMirrorNode): boolean => {
  const nodeChild = node.content.firstChild

  if (node.childCount !== 1 || !nodeChild) {
    return false
  }

  return (
    nodeChild.type.isBlock &&
    !nodeChild.childCount &&
    nodeChild.nodeSize === 2 &&
    (isNullOrUndefined(nodeChild.marks) || nodeChild.marks.length === 0)
  )
}

export const isMac =
  typeof navigator != 'undefined' ? /Mac/.test(navigator.platform) : false

export const EMPTY_P: Partial<Doc> = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
}

export const EMPTY_P_TEXT: Partial<Doc> = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [{ type: 'text', text: '' }],
    },
  ],
}

export const isDocEmpty = (doc: Partial<Doc> | null): boolean =>
  !doc ||
  !doc.content ||
  !doc.content.length ||
  isEqual(doc, EMPTY_P) ||
  isEqual(doc, EMPTY_P_TEXT)

export const compareDocs = (
  oldValue: object | null,
  newValue: object | null,
  extensions: Extension[],
  initialDoc: object
) => {
  const nodes = extensions.reduce<OrderedMap<NodeSpec>>(
    (acc, ext) => ext.addNodes(acc),
    addListNodes(
      baseSchema.spec.nodes as OrderedMap<NodeSpec>,
      'paragraph block*',
      'block'
    )
  )

  const marks = extensions.reduce<OrderedMap<MarkSpec>>(
    (acc, ext) => ext.addMarks(acc),
    baseSchema.spec.marks as OrderedMap<MarkSpec>
  )

  const schema = new Schema({ nodes, marks })

  const oldDoc = ProseMirrorNode.fromJSON(schema, oldValue || initialDoc)
  const newDoc = ProseMirrorNode.fromJSON(schema, newValue || initialDoc)

  let changedContent = ProseMirrorNode.fromJSON(schema, initialDoc)
  let content = ProseMirrorNode.fromJSON(schema, initialDoc)
  const differenceStart = oldDoc.content.findDiffStart(newDoc.content)
  const differenceEnd = oldDoc.content.findDiffEnd(newDoc.content as any)
  if (differenceStart || differenceStart === 0) {
    content = newDoc.cut(differenceStart, differenceEnd?.b)
    changedContent = oldDoc.cut(differenceStart, differenceEnd?.a)
  }

  return { content, changedContent }
}

export const useDebouncedEffect = (
  effect: EffectCallback,
  delay: number,
  deps: DependencyList
) => {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [callback, delay])
}
