import { without } from 'lodash'

/**
 * If item is present in array - removes it.
 * If item is not in the array - adds it.
 *
 * Returns new array. Created for use with store.
 *
 */
export function toggleArrayPresence<T>(array: T[], item: T): T[] {
  if (array.includes(item)) {
    return without(array, item)
  }
  return [...array, item]
}
