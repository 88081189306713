import '../../styles/user-field.scss'

import React from 'react'
import {
  EditableTextComponent,
  EditableTextValidator,
} from '../../../components/ValidatedEditableText'

interface UserProfileSocialInputProps {
  value: string
  placeholder: string
  editable: boolean
  onChange: (value: string) => void
  validator?: EditableTextValidator
}

export function UserProfileSocialInput(props: UserProfileSocialInputProps) {
  return (
    <div className="user-field">
      <EditableTextComponent
        defaultValue={props.value || ''}
        placeholder={props.editable ? props.placeholder : 'Not set'}
        disabled={!props.editable}
        onConfirm={props.onChange}
        validated={true}
        validator={props.validator}
      />
    </div>
  )
}
