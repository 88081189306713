import React from 'react'

import './styles/panel-section-title.scss'

interface PanelSectionTitleProps {
  title: React.ReactNode
}

export const PanelSectionTitle: React.FunctionComponent<PanelSectionTitleProps> = (
  props
) => {
  return (
    <div className="panel-section-title">
      <div className="panel-section-title__text">{props.title}</div>
      <div className="panel-section-title__line" />
    </div>
  )
}
