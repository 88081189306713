import React from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import { FiltersListTitle } from './FiltersListTitle'
import { UserStatus } from '../entities/user/model'

interface FiltersListCheckboxesProps {
  availableStatusFilters: UserStatus[]
  statusFilters: UserStatus[]
  onStatusFilterChange: (name: UserStatus, apply: boolean) => void
}

export function FiltersListCheckboxes(props: FiltersListCheckboxesProps) {
  return (
    <div className="filters-list__item filters-list__item_status">
      <FiltersListTitle>Status</FiltersListTitle>
      {props.availableStatusFilters.map((statusFilter) => (
        <div key={statusFilter} className="filters-list__checkbox">
          <Checkbox
            checked={props.statusFilters.includes(statusFilter)}
            onChange={(e) =>
              props.onStatusFilterChange(statusFilter, e.target.checked)
            }
          >
            {_.upperFirst(statusFilter)}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
