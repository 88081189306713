import { http } from '../../globals/http'
import { TrackingState } from './store'
import {AddTimeManuallyObject, TrackableType} from '../types'
import { ID } from '@datorama/akita'

export class TrackingDataService {

  getUserID(id: ID) {
    return http.get<ID>(`/api/time-entries/${id}/get_user/`)
  }

  getState() {
    return http.get<TrackingState>(`/api/time-entries/tracking_state/`)
  }

  startTracking(type: TrackableType, id: ID, activity: string | null) {
    return http.post<TrackingState>(`/api/time-entries/start_tracking/`, {
      activity,
      target: { type, id },
    })
  }

  stopTracking() {
    return http.post<TrackingState>(`/api/time-entries/stop_tracking/`, {})
  }

  addComment(entry: number, comment: string) {
    return http.patch<TrackingState>(`/api/time-entries/${entry}/`, {
      comment,
    })
  }

  destroy(entryID:ID) {
    return http.delete(`/api/time-entries/${entryID}/`)
  }

  addTimeManually(type: TrackableType, id: ID, data: AddTimeManuallyObject) {
    return http.post<TrackingState>('/api/time-entries/add-time-manually/', {
      target: { type, id },
      start: data.start,
      end: data.end,
      activity: data.activity,
      comment: data.comment
    })
  }

}

export const trackingDataService = new TrackingDataService()
