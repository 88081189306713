import React, { Ref, useCallback, useMemo } from 'react'
import { Typography } from 'antd'
import { ReportProject } from '../../entities/report-project/model'
import { ReportTableComponent } from '../ReportTableComponent'
import { ParseReportProjectData } from './parser'

const reportProjectTableColumns = [
  {
    title: 'ID',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Logged Object',
    dataIndex: 'activity_name',
    key: 'activity_name',
  },
  {
    title: 'Time spent in period',
    dataIndex: 'time_spent',
    key: 'time_spent',
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    key: 'assignee',
  }
]

interface ReportProjectContainerProps {
  report: ReportProject | null
  tableContainerRef: Ref<HTMLDivElement>
}

export function ReportProjectComponent(props: ReportProjectContainerProps) {
  const { report } = props
  const projectName = report?.project_name

  const reportData = useMemo(() => {
    if (report == null) {
      return []
    }
    return ParseReportProjectData(report)
  }, [report])

  const titleCallback = useCallback(() => {
    if (!projectName) {
      return null
    }
    return (
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        Time report: {projectName}
      </Typography.Title>
    )
  }, [projectName])

  const rowClassNameCallback = useCallback((record: any, index: number) => {
    switch (record.key) {
      case 'total':
        return 'table-row-bold'
      case 'project':
        return 'table-row-title'
    }
    if (record.key?.startsWith && record.key.startsWith('task')) {
      return 'table-row-title'
    }
    return ''
  }, [])

  return (
    <ReportTableComponent
      tableColumns={reportProjectTableColumns}
      reportData={reportData}
      titleCallback={titleCallback}
      rowClassNameCallback={rowClassNameCallback}
      tableContainerRef={props.tableContainerRef}
    />
  )
}
