import React from 'react'
import { ID } from '@datorama/akita'

import { FiltersListTitle } from './FiltersListTitle'
import { User } from '../entities/user/model'
import { UsersSuggest } from '../components/controls/UsersSuggest'

interface FilterListUsersProps {
  users: User[]
  selectedUsersIDs: ID[]
  onChangeSelectedUsersIDs: (usersIDs: ID[]) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  noUsersSelectedPlaceholder?: string
  noUnassignedUser?: boolean
}

export function FilterListUsers(props: FilterListUsersProps) {
  return (
    <>
      <FiltersListTitle>Users</FiltersListTitle>
      <div className="filters-list__item filters-list__item_users">
        <UsersSuggest
          closeOnSelect={false}
          users={props.users}
          selectedUsersIDs={props.selectedUsersIDs}
          onChangeSelectedUsersIDs={props.onChangeSelectedUsersIDs}
          allowSelectAll={props.allowSelectAll}
          emptyListLabel={props.emptyListLabel}
          noUsersSelectedPlaceholder={props.noUsersSelectedPlaceholder}
          noUnassignedUser={props.noUnassignedUser}
        />
      </div>
    </>
  )
}
