import {http} from "../../../../../globals/http";
import {PerformancePerDay} from "./models";

export class SprintStatisticDataService {
  performance_per_day(query?: object) {
    return http.get<PerformancePerDay>('/api/time-entries/performance_per_day/', query)
  }
}

export const sprintStatisticDataService = new SprintStatisticDataService()
