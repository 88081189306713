import './styles/data-range-input.scss'

import React, { useCallback, useMemo } from 'react'
import { DateRange, DateRangeInput } from '@blueprintjs/datetime'
import { CalendarOutlined } from '@ant-design/icons'

import { getDateRangeShortcuts } from './date-range-shortcuts'
import { formatDate, parseDate } from '../../../utils/date'

interface DatesRangeSelectProps {
  startDate: Date | null
  endDate: Date | null
  onChange: (dates: DateRange) => void
}

export function DatesRangeSelect(props: DatesRangeSelectProps) {
  const inputProps = useMemo(
    () => ({
      leftElement: (
        <CalendarOutlined
          className={'data-range-input__icon'}
          style={{ fontSize: '12', color: 'var(--color-text-inactive)' }}
        />
      ),
    }),
    []
  )

  const { startDate, endDate, onChange } = props

  const _onChange = useCallback(
    (dates: DateRange) => {
      onChange(dates)
    },
    [onChange]
  )

  const dateRange = useMemo(() => {
    return [startDate, endDate] as DateRange
  }, [startDate, endDate])

  const shortcuts = useMemo(() => getDateRangeShortcuts(), [])

  return (
    <DateRangeInput
      allowSingleDayRange={true}
      value={dateRange}
      formatDate={formatDate}
      parseDate={parseDate}
      className="data-range-input"
      onChange={_onChange}
      singleMonthOnly={false}
      popoverProps={{
        position: 'bottom',
        boundary: 'window',
        portalClassName: 'date-range-portal',
      }}
      startInputProps={inputProps}
      endInputProps={inputProps}
      shortcuts={shortcuts}
      highlightCurrentDay={true}
      dayPickerProps={{
        firstDayOfWeek: 1,
      }}
    />
  )
}
