import React, { useCallback, useContext, useMemo } from 'react'
import { ID } from '@datorama/akita'
import {
  HTMLInputProps,
  IInputGroupProps,
  ITagProps,
  Menu,
  PopoverPosition,
  Tag,
} from '@blueprintjs/core'
import {
  IItemRendererProps,
  ItemListRenderer,
  Suggest,
} from '@blueprintjs/select'
import { compact } from 'lodash'
import { Empty } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import {
  ALL_USERS,
  ALL_USERS_ID,
  booleanKeyReverseSortFn,
  booleanKeySortFn,
  nameSortFn,
  ShortUser,
  UNASSIGNED,
  User,
} from '../../entities/user/model'
import { UserAvatar } from '../UserAvatar'
import { CurrentUserContext } from '../../auth/currentUserContext'

import './styles/suggest.scss'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'

const TypedUsersSuggest = Suggest.ofType<User | ShortUser>()

interface UsersSuggestProps {
  users: (User | ShortUser)[]
  selectedUsersIDs: ID[]
  onChangeSelectedUsersIDs: (usersIDs: ID[]) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  noUsersSelectedPlaceholder?: string
  popoverPosition?: PopoverPosition
  tilesClassName?: string
  wrapperClassName?: string
  targetClassName?: string
  popoverFill?: boolean
  noTilesWrapper?: boolean
  inputProps?: IInputGroupProps & HTMLInputProps
  escapeWithReference?: boolean
  noUnassignedUser?: boolean
  inputIcon?: React.ReactNode
  closeOnSelect?: boolean
  portalClassName?: string
}

export function UsersSuggest(props: UsersSuggestProps) {
  const currentUser = useContext(CurrentUserContext)!
  const {
    selectedUsersIDs,
    onChangeSelectedUsersIDs,
    escapeWithReference = true,
    closeOnSelect = true,
  } = props

  const users = useMemo(() => {
    const _users = [...props.users].sort(
      (a, b) =>
        booleanKeySortFn(a, b, 'is_active') ||
        booleanKeyReverseSortFn(a, b, 'is_external') ||
        booleanKeyReverseSortFn(a, b, 'is_invited') ||
        nameSortFn(a, b)
    )
    if (_users.length && !props.noUnassignedUser) {
      _users.unshift(UNASSIGNED)
    }
    if (props.allowSelectAll) {
      _users.unshift(ALL_USERS)
    }
    const currentUserIndex = _users.findIndex(
      (user) => currentUser && currentUser.id === user.id
    )

    if (currentUserIndex !== ALL_USERS_ID) {
      _users.unshift(_users.splice(currentUserIndex, 1)[0])
    }
    return _users
  }, [props.users, props.allowSelectAll, currentUser, props.noUnassignedUser])

  const onChangeUser = useCallback(
    (user: User | ShortUser) => {
      const exists = selectedUsersIDs.includes(user.id)
      if (exists) {
        onChangeSelectedUsersIDs(
          selectedUsersIDs.filter((id) => id !== user.id)
        )
      } else if (user.id === ALL_USERS_ID) {
        onChangeSelectedUsersIDs([ALL_USERS_ID])
      } else if (
        selectedUsersIDs.length === 1 &&
        selectedUsersIDs[0] === ALL_USERS_ID
      ) {
        onChangeSelectedUsersIDs([user.id])
      } else {
        onChangeSelectedUsersIDs([user.id, ...selectedUsersIDs])
      }
    },
    [selectedUsersIDs, onChangeSelectedUsersIDs]
  )

  const handleTagRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, tagProps: ITagProps | any) => {
      const id = tagProps['data-id']
      const user = users.find((user) => user.id === id)!
      onChangeUser(user)
    },
    [users, onChangeUser]
  )

  const filterUser = useCallback(
    (query: string, user: User | ShortUser) => {
      const matchString = (user.full_name || user.email).toLowerCase()

      if (
        currentUser &&
        user.id === currentUser.id &&
        'me'.includes(query.trim().toLowerCase())
      ) {
        return true
      }

      return matchString.indexOf(query.trim().toLowerCase()) >= 0
    },
    [currentUser]
  )

  const renderMenu: ItemListRenderer<User | ShortUser> = ({
    items,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null)
    if (renderedItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          {(!users.length && props.emptyListLabel && (
            <li className="suggest__empty-list-label">
              {props.emptyListLabel}
            </li>
          )) || (
            <Empty imageStyle={{ height: 60 }} description={'No Results'} />
          )}
        </Menu>
      )
    }
    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={250}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  const renderUser = useCallback(
    (user: User | ShortUser, renderProps: IItemRendererProps) => {
      const { modifiers, handleClick } = renderProps

      if (!modifiers.matchesPredicate) {
        return null
      }

      const isCurrentUser = currentUser && user.id === currentUser.id

      return (
        <li
          className={classNames('bp3-menu-item', {
            'bp3-multi': modifiers.active,
            'bp3-menu-item_selected': selectedUsersIDs.includes(user.id),
          })}
          onClick={handleClick}
          key={user.id}
        >
          <div className="user-select__list-item">
            <div className="user-select__list-avatar">
              <UserAvatar user={user} shape={'square'} size={22} />
            </div>
            <div
              className="user-select__list-full-name bp3-text-overflow-ellipsis"
              title={user.full_name || user.email}
            >
              {user.full_name || user.email}{' '}
              {isCurrentUser && <span className="me-label">me</span>}
            </div>
          </div>
        </li>
      )
    },
    [selectedUsersIDs, currentUser]
  )

  const placeholder =
    (!selectedUsersIDs.length && props.noUsersSelectedPlaceholder) ||
    // eslint-disable-next-line no-useless-concat
    'Enter User N' + '\u0430' + 'me'

  const selectedUsers = useMemo(() => {
    return users.filter((user) => selectedUsersIDs.includes(user.id))
  }, [selectedUsersIDs, users])

  const DEFAULT_INPUT_PROPS: IInputGroupProps & HTMLInputProps = {
    name: 'searchTerm',
    autoComplete: 'off',
    placeholder: placeholder,
    rightElement: (
      <SearchOutlined
        style={{
          color: 'var(--color-text-inactive)',
          fontSize: 13,
        }}
        className={'suggest__search-icon'}
      />
    ),
  }

  const tilesClasses = classNames('suggest__tiles', props.tilesClassName)
  const wrapperClasses = classNames('suggest', props.wrapperClassName)

  const tiles = selectedUsers.map((user: User | ShortUser) => (
    <Tag
      className="project-tile suggest__tile"
      data-id={user.id}
      onRemove={handleTagRemove}
      key={user.id}
    >
      <div className="suggest__content">
        <UserAvatar
          className={'suggest__avatar'}
          user={user}
          size={28}
          shape={'square'}
        />
        <div className="suggest__name text-trim-ellipsis">
          {user.full_name || user.email}
        </div>
      </div>
    </Tag>
  ))

  return (
    <div className={wrapperClasses}>
      <div className="suggest__input-wrapper">
        {props.inputIcon}
        <TypedUsersSuggest
          items={users.filter((x) => !selectedUsers.includes(x))}
          resetOnClose={true}
          closeOnSelect={closeOnSelect}
          itemRenderer={renderUser}
          onItemSelect={onChangeUser}
          itemPredicate={filterUser}
          itemListRenderer={renderMenu}
          inputValueRenderer={useCallback(() => '', [])}
          noResults={
            (!users.length && props.emptyListLabel && (
              <div className="suggest__empty-list-label">
                {props.emptyListLabel}
              </div>
            )) || (
              <Empty imageStyle={{ height: 60 }} description={'No Results'} />
            )
          }
          inputProps={props.inputProps || DEFAULT_INPUT_PROPS}
          popoverProps={{
            className: props.targetClassName,
            fill: props.popoverFill || true,
            minimal: true,
            position: props.popoverPosition || 'top',
            popoverClassName: 'popover suggest-popover',
            portalClassName: classNames(
              'suggest-portal',
              props.portalClassName
            ),
            modifiers: {
              preventOverflow: {
                escapeWithReference: escapeWithReference,
              },
            },
          }}
        />
      </div>
      {selectedUsers.length > 0 && (
        <>
          {props.noTilesWrapper ? (
            tiles
          ) : (
            <CustomScrollbar translateContentSizeYToHolder={true}>
              <div className={tilesClasses}>{tiles}</div>
            </CustomScrollbar>
          )}
        </>
      )}
    </div>
  )
}

interface SingleUserSuggestProps {
  users: (User | ShortUser)[]
  selectedUserID: ID | null
  onChangeSelectedUserID: (userID: ID) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  noUsersSelectedPlaceholder?: string
}

export function SingleUserSuggest(props: SingleUserSuggestProps) {
  const { selectedUserID, onChangeSelectedUserID } = props

  const selectedUsersIDs = useMemo(() => {
    return compact([selectedUserID])
  }, [selectedUserID])

  const onChangeSelectedUsersIDs = useCallback(
    (usersIDs: ID[]) => {
      if (usersIDs.length) {
        onChangeSelectedUserID(usersIDs[0])
      }
    },
    [onChangeSelectedUserID]
  )

  return (
    <UsersSuggest
      users={props.users}
      selectedUsersIDs={selectedUsersIDs}
      onChangeSelectedUsersIDs={onChangeSelectedUsersIDs}
    />
  )
}
