import React from "react"
import {TextSelection} from "prosemirror-state"
import {EditorView} from "prosemirror-view"

import { generateTaskDetailUrl } from "../../../../routes"
import { useProseMirrorContext } from "../../../core/provider";
import { getPositionOffset } from "../../external-link/utils";
import { getInternalLinkDialogPluginState, disableUrlDialog } from "../plugin"

import { Dialog } from "./Dialog"

export const EditorDialog = ({view}: {view: EditorView}) => {
  const {enable, target} = getInternalLinkDialogPluginState(view.state)
  const { providers: { internalLinkProvider: provider } } = useProseMirrorContext()
  const { name, status } = provider.useProvider({
    taskId: target?.attrs.taskId,
    taskCode: target?.attrs.taskCode
  })

  if (!enable || !target) { return null }

  const { targetElement, offset } = getPositionOffset(view, target.from)
  const href = generateTaskDetailUrl(
    target.attrs.workspaceId,
    target.attrs.projectId,
    `${target.attrs.taskCode}-${target.attrs.taskId}`
  )

  const handleRemoveLink = () => {
    const text = view.state.schema.text(
      `${target.attrs.taskCode}-${target.attrs.taskId} | ${name} | ${status}`
    )

    const tr = view.state.tr
    tr.replaceRangeWith(target.from, target.to, text)
    tr.setSelection(TextSelection.near(tr.doc.resolve(target.from + text.nodeSize)))
    disableUrlDialog(tr)
    view.dispatch(tr)
    view.focus()
  }

  return (
    <Dialog
      appendTo={view.dom.parentElement?.parentElement as HTMLElement}
      targetElement={targetElement}
      offsetTop={offset?.top}
      offsetLeft={offset?.left}
      href={href}
      removeLink={handleRemoveLink}
    />
  )
}
