import React, {useRef, useEffect} from "react"
import { EditorView } from "prosemirror-view"
import shortid from "shortid"

import { Footer } from "./Footer"
import {useProseMirrorContext} from "../../../core/provider"
import { addWidget, removeWidget, findWidget, createAttachmentNode } from "../../attacment/plugins"

export const EditorFooter = ({view, onSubmit}: {view: EditorView, onSubmit?: (value: any) => void}) => {
  useEffect(() => {
    const element = document.getElementsByClassName("prose-mirror-editor")[0]
    element.classList.add("has-footer")
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const fileInputRef = useRef<HTMLInputElement>(null)
  const { providers: { footerProvider: provider } } = useProseMirrorContext()

  const handleOnSubmit = onSubmit && (() => onSubmit(view.state.doc.toJSON()))

  const items = [
    {
      icon: "attachment",
      onClick: () => {
        if (fileInputRef.current) { fileInputRef.current.click() }
      }
    },
    {
      icon: "horizontal-line",
      onClick: () => {
        const node = view.state.schema.nodes.horizontal_rule.create()
        const tr = view.state.tr.replaceSelectionWith(node).scrollIntoView()
        view.dispatch(tr)
        view.focus()
      }
    }
  ]

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = shortid.generate()

    const files = event.target.files
    if (!files?.length) { return false }

    // Show placeholder
    event.preventDefault()
    const { $from } = view.state.selection
    view.dispatch(addWidget(view.state, id, $from.pos))
    view.focus()

    const file = files[0]
    provider.uploadFile(
      file,
      (url: string, preview_url: string | undefined) => {
        // Get current element position
        const pos = findWidget(view.state, id)
        if (pos == null) return

        const node = createAttachmentNode(url, preview_url, file, view)
        view.dispatch(removeWidget(view.state, id).insert(pos, node).scrollIntoView())
      },
      () => {
        view.dispatch(removeWidget(view.state, id))
      }
    )
  }

  return (
    <>
      <Footer items={items} onSubmit={handleOnSubmit} />
      <input
        type="file"
        ref={fileInputRef}
        onChange={uploadFile}
        style={{display: 'none'}}
      />
    </>
  )
}
