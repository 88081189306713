import React, { useState } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { Layout, Menu, Typography } from 'antd'
import {
  BarChartOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Project } from '../entities/project/model'
import { UserRole } from '../entities/user/model'
import { useQuery } from '../utils/queryhook'
import { authService } from '../auth/state/service'
import { authQuery } from '../auth/state/query'
import { getCurrentLocationPathname } from '../utils/urls'
import { projectQuery } from '../entities/project/query'
import './styles/workspace-page.scss'
import classNames from 'classnames'
import { CustomScrollbar } from '../components/custom-scrollbar/CustomScrollbar'
import Logo from '../assets/white-logo.svg'
import './styles/sidebar.scss'

export function BaseWorkspaceLayoutContainer({
  children,
}: {
  children: React.ReactNode
}) {
  const currentUser = useQuery(authQuery.currentUser$)
  const isAdminUser = currentUser.user_role === UserRole.ADMIN
  //FIXME: the following useQuery results in double re-render on app loading
  const projects = useQuery(projectQuery.userActiveProjects$)

  const onLogout = () => authService.logout()

  const [collapsed, setCollapsed] = useState(true)

  const sortedProjects = projects.sort((firstProject, secondProject) => {
    const firstProjectName = firstProject.name.toLowerCase()
    const secondProjectName = secondProject.name.toLowerCase()
    if (firstProjectName < secondProjectName) {
      return -1
    }
    if (firstProjectName > secondProjectName) {
      return 1
    }
    return 0
  })

  return (
    <>
      <div className="sidebar-container">
        <Layout.Sider
          collapsible
          trigger={null}
          collapsed={collapsed}
          onMouseMove={() => setCollapsed(false)}
          onMouseLeave={() => setCollapsed(true)}
          style={{
            position: 'absolute',
            zIndex: 10,
            height: '100vh',
            left: 0,
          }}
        >
          <CustomScrollbar theme="light">
            <SidebarLogo collapsed={collapsed} />
            <MainMenu isAdminUser={isAdminUser} collapsed={collapsed} />
            <ProjectsMenu projects={sortedProjects} collapsed={collapsed} />
            <ProfileMenu onLogout={onLogout} collapsed={collapsed} />
          </CustomScrollbar>
        </Layout.Sider>
      </div>
      {children}
    </>
  )
}

function SidebarLogo({ collapsed }: { collapsed: boolean }) {
  const linkClasses = classNames('sidebar-logo', {
    'is-expanded': !collapsed,
  })
  const wrapperClasses = classNames('sidebar-logo-wrapper', {
    'is-expanded': !collapsed
  })
  return (
    <div className={wrapperClasses}>
      <Link to={'/'} className={linkClasses}>
        <img className="sidebar-logo-img" src={Logo} alt="Logo" />
        {!collapsed && (
          <Typography.Title
            level={1}
            style={{
              color: 'rgba(255, 255, 255, 0.8)',
              fontSize: 20,
              margin: '0 0 0 12px',
              verticalAlign: 'middle',
            }}
          >
            Timer
          </Typography.Title>
        )}
      </Link>
    </div>
  )
}

interface ProjectsMenuProps {
  projects: Project[]
  collapsed: boolean
}

function ProjectsMenu(props: ProjectsMenuProps) {
  const match = useRouteMatch()
  const { pathname: currentLocationPathname } = useLocation()

  const selectedKeys: string[] = []

  return (
    <Menu theme="dark" selectedKeys={selectedKeys}>
      {props.projects.map((project) => {
        const projectUrl = `${match.url}/${project.id}`
        if (currentLocationPathname.includes(projectUrl)) {
          selectedKeys.push(project.code)
        }
        return (
          <Menu.Item key={project.code} title={project.name}>
            <Link to={projectUrl}>
              {props.collapsed ? project.code : project.name}
            </Link>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

interface MainMenuProps {
  isAdminUser: boolean
  collapsed: boolean
}

function MainMenu(props: MainMenuProps) {
  const match = useRouteMatch()
  const currentLocationPathname = getCurrentLocationPathname()
  const urls: { [key: string]: string[] } = {
    dashboard: [match.url, `${match.url}$`],
    users: [`${match.url}/users`],
    calendar: [`${match.url}/calendar`, `${match.url}/calendar$`],
    stats: [
      `${match.url}/stats`,
      `${match.url}/stats$`
    ],
    report_project: [
      `${match.url}/report/project`,
      `${match.url}/report/project$`,
    ],
    report_dev: [
      `${match.url}/report/dev`,
      `${match.url}/report/dev$`
    ],
  }
  const selectedKeys: string[] = Object.keys(urls).filter((key) =>
    currentLocationPathname.match(urls[key].slice(-1)[0])
  )

  return (
    <Menu theme="dark" selectedKeys={selectedKeys}>
      {!!urls.dashboard && !!urls.dashboard[0] && (
        <Menu.Item key="dashboard" title="Dashboard">
          <Link to={urls.dashboard[0]}>
            <DashboardOutlined className="sidebar-container__icon" />
            {!props.collapsed && (
              <span className="sidebar-container__item-label">Dashboard</span>
            )}
          </Link>
        </Menu.Item>
      )}

      {!!urls.users && !!urls.users[0] && (
        <Menu.Item key="users" title="Users">
          <Link to={urls.users[0]}>
            <UsergroupAddOutlined className="sidebar-container__icon" />
            {!props.collapsed && (
              <span className="sidebar-container__item-label">Users</span>
            )}
          </Link>
        </Menu.Item>
      )}

      {!!urls.calendar && !!urls.calendar[0] && (
        <Menu.Item key="calendar" title="Calendar">
          <Link to={urls.calendar[0]}>
            <CalendarOutlined className="sidebar-container__icon" />
            {!props.collapsed && (
              <span className="sidebar-container__item-label">Calendar</span>
            )}
          </Link>
        </Menu.Item>
      )}

      {!!urls.stats && !!urls.stats[0] && (
        <Menu.Item key="stats" title="Statistics">
          <Link to={urls.stats[0]}>
            <BarChartOutlined className="sidebar-container__icon" />
            {!props.collapsed && (
              <span className="sidebar-container__item-label">
                Statistics
              </span>
            )}
          </Link>
        </Menu.Item>
      )}

      {!!urls.report_project && !!urls.report_project[0] && (
        <Menu.Item key="report_project" title="Report: Project">
          <Link to={urls.report_project[0]}>
            <ClockCircleOutlined className="sidebar-container__icon" />
            {!props.collapsed && (
              <span className="sidebar-container__item-label">
                Report: Project
              </span>
            )}
          </Link>
        </Menu.Item>
      )}

      {!!urls.report_dev && !!urls.report_dev[0] && (
        <Menu.Item key="report_dev" title="Report: Dev">
          <Link to={urls.report_dev[0]}>
            <ClockCircleOutlined className="sidebar-container__icon" />
            {!props.collapsed && (
              <span className="sidebar-container__item-label">Report: Dev</span>
            )}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  )
}

interface ProfileMenuProps {
  onLogout: () => void
  collapsed: boolean
}

function ProfileMenu(props: ProfileMenuProps) {
  return (
    <Menu theme="dark">
      <Menu.Item onClick={props.onLogout} key="logout" title="Logout">
        <LogoutOutlined /> {!props.collapsed && 'Logout'}
      </Menu.Item>
    </Menu>
  )
}
