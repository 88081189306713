import '../../styles/user-profile.scss'

import React, { useCallback } from 'react'
import { UserProfileTheme } from './UserProfileTheme'
import { User, UserTheme } from '../../../entities/user/model'
import { UserProfileTitleWithDivider } from './UserProfileTitleWithDivider'
import { ModelUpdateCallback } from '../../../utils/types'

interface UserProfilePreferencesProps {
  user: User
  editable: boolean
  onUserUpdate: ModelUpdateCallback<User>
}

export function UserProfilePreferences(props: UserProfilePreferencesProps) {
  const { user, onUserUpdate } = props
  const { theme } = user

  const handleThemeChange = useCallback(
    (value: UserTheme) => {
      onUserUpdate({ id: user.id, theme: value })
    },
    [onUserUpdate, user.id]
  )

  return (
    <div className="user-profile__section">
      <UserProfileTitleWithDivider
        dividerClass={'user-title-divider'}
        orientation={'left'}
        label={'Preferences'}
      />
      <div className="user-profile__infos user-profile__infos_details">
        <UserProfileTheme
          label={'Theme'}
          value={theme}
          editable={true}
          onChange={handleThemeChange}
        />
      </div>
    </div>
  )
}
