import { ID } from '@datorama/akita'
import { Observable } from 'rxjs'
import { boundMethod } from 'autobind-decorator'
import { http } from '../../globals/http'
import { ChangePasswordData, User, UserInvite } from './model'
import { ModelUpdate } from '../../utils/types'

export class UserDataService {
  getSelf(onlyServerErrMessages?: boolean) {
    return http.get<User>(`/api/users/me/`, {}, { onlyServerErrMessages })
  }

  get(id: ID) {
    return http.get<User>(`/api/users/${id}`)
  }

  list(query?: object) {
    return http.get<User[]>('/api/users/', query)
  }

  patch(update: ModelUpdate<User>) {
    return http.patch<User>(`/api/users/${update.id}/`, update)
  }

  checkInviteToken(inviteToken: string) {
    return http.get<{ invite: number; email: string }>(
      '/api/users/invite/registration/',
      { invite_token: inviteToken }
    )
  }

  inviteRegistration(
    password: string,
    confirmPassword: string,
    inviteToken: string
  ) {
    return http.post<User>('/api/users/invite/registration/', {
      password: password,
      confirm_password: confirmPassword,
      invite_token: inviteToken,
    })
  }

  login(email: string, password: string) {
    return http.post<User>('/api/users/login/', { email, password })
  }

  logout() {
    return http.post('/api/users/logout/', {})
  }

  inviteNewUser(invite: UserInvite): Observable<User> {
    return http.post('/api/users/', invite)
  }

  resendInvite(user: User): Observable<User> {
    return http.post(`/api/users/${user.id}/resend_invite/`, {})
  }

  cancelInvite(user: User): Observable<User> {
    return http.post(`/api/users/${user.id}/cancel_invite/`, {})
  }

  changePassword(data: ChangePasswordData): Observable<void> {
    return http.post(`/api/users/change_password/`, data)
  }

  @boundMethod
  uploadAvatar(userId: ID, file: File): Observable<User> {
    return http.postFile<User>(`/api/users/${userId}/avatar/`, 'avatar', file)
  }
}

export const userDataService = new UserDataService()
