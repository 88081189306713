import { applyTransaction, ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { isEqual, keys, pick, differenceWith } from 'lodash'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { TaskDataService, taskDataService } from './data-service'
import { TaskIntoSubtask, Task } from './model'
import { TaskQuery, taskQuery } from './query'
import { TaskStore, taskStore } from './store'

import { epicService } from '../epic/service'
import { userQuery } from '../user/query'

import { optimisticSaveEnabled } from '../../globals/constants'
import { hashObj } from '../../utils/hash'
import { smartListReOrder } from '../../utils/lists'
import { messageDispatcher } from '../../utils/message-dispatcher'
import { ModelUpdate } from '../../utils/types'

export class TaskService {
  constructor(
    private store: TaskStore,
    private query: TaskQuery,
    private dataService: TaskDataService
  ) {}

  setActive(id?: ID) {
    this.store.setActive(id || null)
  }

  @boundMethod
  ensureTasksLoaded(query?: object) {
    const loadedHashes = this.query.getLoadedHashes()
    if (!query || !loadedHashes.includes(hashObj(query))) {
      this.loadTasks(query)
    }
  }

  @boundMethod
  loadTasks(query?: object) {
    this.store.setLoading(true)

    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
        })
      )
      .subscribe((tasks) => {
        this.store.add(tasks)
        if (query) {
          this.store.update((state) => ({
            ...state,
            loaded: [...state.loaded, hashObj(query)],
          }))
        }
      })
  }

  loadTask(id: ID) {
    this.dataService.get(id).subscribe((task) => {
      this.store.add(task)
    })
  }

  loadTaskDetails(id: ID) {
    this.dataService.get(id).subscribe((task) => {
      this.store.update(id, task)
      this.store.setActive(id)
    })
  }

  unloadTaskDetails(id: ID) {
    this.store.update(id, {
      estimated_time: undefined,
      json_description: undefined,
    })
  }

  @boundMethod
  unloadActiveTaskDetails() {
    this.store.updateActive({
      estimated_time: undefined,
      json_description: undefined,
    })
  }

  loadTaskByCode(code: string) {
    this.dataService.list({ code }).subscribe((tasks) => {
      if (tasks.length) {
        this.store.add(tasks[0])
      }
    })
  }

  loadAllInfoTaskByCode(taskCode: string) {
    this.loadTasks({ linked_tasks_by_code: taskCode! })
    epicService.loadEpics({ task_code: taskCode! })
    taskQuery.selectByCode(taskCode!).subscribe((task) => {
      if (task) {
        this.store.add(task)
      }
    })
  }

  createTask(task: Partial<Task>) {
    const result = new Subject<Task>()

    this.dataService.create(task).subscribe(
      (task) => {
        this.store.add(task)
        result.next(task)
        result.complete()
        messageDispatcher.putSuccessMessage(
          !!task.name
            ? `Task ${task.code} | ${task.name} has been created`
            : `Task ${task.code} has been created`
        )
      },
      (error) => {
        result.error(error)
      }
    )

    return result.asObservable()
  }

  @boundMethod
  updateTask(update: ModelUpdate<Task>) {
    this.updateTaskOptimistic(update)
  }

  @boundMethod
  addFollowers(taskID: ID, idsToAdd: ID[]) {
    const task = this.query.getEntity(taskID!)
    if (!idsToAdd.includes(task.created_by)) {
      messageDispatcher.putErrorMessage(
        `The task's creator cannot be removed from followers list`
      )
      return
    }
    this.updateTaskOptimistic({ id: taskID!, followers: idsToAdd })

    if (task.followers.length < idsToAdd.length) {
      const follower = userQuery.getEntity(
        differenceWith(idsToAdd, task.followers)[0]
      )
      if (!!follower) {
        messageDispatcher.putSuccessMessage(
          `The follower ${follower.full_name} has been added to the task`
        )
      }
    } else {
      const follower = userQuery.getEntity(
        differenceWith(task.followers, idsToAdd)[0]
      )
      if (!!follower) {
        messageDispatcher.putSuccessMessage(
          `The follower ${follower.full_name} has been removed from the task`
        )
      }
    }
  }

  @boundMethod
  addLinkedTask(taskID: ID, idToAdd: ID) {
    const task = this.query.getEntity(taskID!)
    if (!task) {
      return
    }
    if (task.linked_tasks.includes(Number(idToAdd))) {
      return
    }
    const linkedTaskList = [...task.linked_tasks, idToAdd]
    this.updateTaskOptimistic({ id: taskID!, linked_tasks: linkedTaskList })
    messageDispatcher.putSuccessMessage('Tasks have been linked')
  }

  @boundMethod
  removeLinkedTask(taskID: ID, idToRemove: ID) {
    const task = this.query.getEntity(taskID!)
    const linkedTaskList = task.linked_tasks.filter(
      (value) => value !== idToRemove
    )
    this.updateTaskOptimistic({ id: taskID!, linked_tasks: linkedTaskList })
  }

  @boundMethod
  sortSprintTasks(sprintID: ID) {
    this.dataService.sortTasks({ sprint_id: sprintID }).subscribe((tasks) => {
      let checkedTasks: Task[] = []
      tasks.forEach((task) => {
        const realTask = this.query.getEntity(task.id as ID)
        if (realTask) {
          checkedTasks.push(realTask)
        }
      })
      this.store.upsertMany(checkedTasks)
    })
  }

  @boundMethod
  sortSprintTasksByAssignee(sprintID: ID) {
    this.dataService
      .sortTasksByAssigneeOnly({ sprint_id: sprintID })
      .subscribe((tasks) => {
        let checkedTasks: Task[] = []
        tasks.forEach((task) => {
          const realTask = this.query.getEntity(task.id as ID)
          if (realTask) {
            checkedTasks.push(realTask)
          }
        })
        this.store.upsertMany(checkedTasks)
      })
  }

  /**
   * Moves task to the specified location in the sprint or epic.
   *
   */
  @boundMethod
  moveTask(id: ID, targetType: 'sprint' | 'epic', targetId: ID, index: number) {
    const orderField = `${targetType}_order` as 'sprint_order' | 'epic_order'
    const task = { ...this.query.getEntity(id) }
    const tasks = this.query.getAll({
      filterBy: (task) => task.id !== id && task[targetType] === targetId,
    })
    const updates = smartListReOrder(task, tasks, orderField, index)

    applyTransaction(() => {
      updates.forEach((update) =>
        this.updateTaskOptimistic({
          [targetType]: targetId,
          ...update,
        })
      )
    })
  }

  @boundMethod
  moveLinkedTask(id: ID, targetType: 'link', targetId: ID, index: number) {
    if (id === targetId) {
      return
    }
    const task = this.query.getEntity(targetId)

    if (task.linked_tasks.length && task.linked_tasks.includes(id)) {
      return
    }
    const newlinkedTaskList = [...task.linked_tasks, id]
    this.updateTask({ id: targetId, linked_tasks: newlinkedTaskList })
    messageDispatcher.putSuccessMessage('Tasks have been linked')
  }

  @boundMethod
  taskIntoSubtask(id: ID, data: TaskIntoSubtask) {
    this.dataService.toSubtask(id, data).subscribe(() => {
      this.store.remove(id)
      messageDispatcher.putSuccessMessage(
        'The task has been converted to the subtask'
      )
    })
  }

  /**
   * We first update the value and then try to send it to the backend.
   *
   * If save fails - we rollback the change.
   *
   */
  private updateTaskOptimistic(update: ModelUpdate<Task>) {
    const currentTask = this.query.getEntity(update.id)
    const currentValues = pick(currentTask, keys(update))

    // Let's update store immediately
    if (optimisticSaveEnabled) {
      this.store.update(update.id, update)
    }

    this.dataService.update(update).subscribe({
      next: (value: Task) => {
        // max_estimated_time is generally updated on every size change.
        // If we don't include it in the list of keys to update, then
        // its value will be ignored and T-shirt size colors will break
        const keysToUpdate = keys(update).concat(['max_estimated_time'])
        const updatedValues = pick(value, keysToUpdate)

        // Update store only in case BE returns different values
        if (!isEqual(updatedValues, update)) {
          this.store.update(value.id, updatedValues)
        }
      },
      error: () => {
        this.store.update(update.id, currentValues)
      },
    })
  }
}

export const taskService = new TaskService(
  taskStore,
  taskQuery,
  taskDataService
)
