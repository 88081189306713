import React from 'react'
import { Divider } from 'antd'
import { UserProfileTitle } from './UserProfileTitle'

import '../../styles/titles.scss'

interface UserProfileTitleWithDividerProps {
  dividerClass: string
  label: string
  orientation?: 'left' | 'right' | 'center'
}

export function UserProfileTitleWithDivider(
  props: UserProfileTitleWithDividerProps
) {
  return (
    <Divider className={props.dividerClass} orientation={props.orientation}>
      <UserProfileTitle label={props.label} />
    </Divider>
  )
}
