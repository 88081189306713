import { http } from '../../globals/http'
import { ReportDev } from './model'

export class ReportDevDataService {
  get(query: object) {
    return http.get<ReportDev>('/api/report/dev/', query)
  }
}

export const reportDevPageDataService = new ReportDevDataService()
