import { http } from '../../globals/http'
import { ID } from '@datorama/akita'
import { TaskType } from './model'

export class TaskTypeDataService {
  get(id: ID) {
    return http.get<TaskType>(`/api/tasktype/${id}/`)
  }

  list(query?: object) {
    return http.get<TaskType[]>('/api/tasktype/', query)
  }

  create(taskType: Partial<TaskType>) {
    return http.post<TaskType>('/api/tasktype/', taskType)
  }
}

export const taskTypeDataService = new TaskTypeDataService()
