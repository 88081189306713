import {Node as ProseMirrorNode, NodeSpec} from 'prosemirror-model'

export const InternalLinkNodeSpec: NodeSpec = {
  attrs: {
    taskId: {},
    taskCode: {},
    workspaceId: {},
    projectId: {}
  },
  inline: true,
  group: 'inline',
  draggable: true,
  toDOM: (node: ProseMirrorNode): any => [
    'a',
    {
      class: 'internal_link',
      'task-id': node.attrs.taskId,
      'task-code': node.attrs.taskCode,
      'workspace-id': node.attrs.workspaceId,
      'project-id': node.attrs.projectId,
    },
    `${node.attrs.taskCode}-${node.attrs.taskId}`
  ],
  parseDOM: [
    {
      tag: 'a.internal_link',
      getAttrs: (dom: any): { [key: string]: string } | false => {
        const taskId = dom.getAttribute('task-id')
        const taskCode = dom.getAttribute('task-code')
        const workspaceId = dom.getAttribute('workspace-id')
        const projectId = dom.getAttribute('project-id')
        if (!taskId || !taskCode || !workspaceId || !projectId) { return false }
        return { taskId, taskCode, workspaceId, projectId }
      },
    },
  ],
}
