import {generateProjectDetailUrl} from "../routes";
import {Redirect, Route} from "react-router-dom";
import React from "react";
import {useInstantEffect} from "../utils/instant-effect-hook";
import {projectService} from "../entities/project/service";
import {useQuery} from "../utils/queryhook";
import {projectQuery} from "../entities/project/query";
import {Loader} from "../main-page/Loader";
import {NotFound} from "../components/error-pages/ErrorPages.stories";

interface LegacyProjectUrlRedirectProps {
  projectCode?: string
}


export function LegacyProjectUrlRedirect({ projectCode }: LegacyProjectUrlRedirectProps) {
  useInstantEffect(
    () => {
      projectService.loadProjects({ code: projectCode })
    }, [projectCode]
  )

  const projectLoaded = useQuery(projectQuery.loaded$)
  const project = useQuery(projectQuery.selectFirst())

  if (!projectLoaded) return <Loader />

  if (!project) return <Route component={NotFound}/>

  return <Redirect to={generateProjectDetailUrl(project.workspace, project.id)}/>
}
