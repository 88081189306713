import React from 'react'
import {
  getPriorityMapping,
  TaskPriorityType,
} from '../../../entities/choices/priority'

interface HighlightTaskPriorityProps {
  label: string
  value: TaskPriorityType
}

export function HighlightTaskPriority(props: HighlightTaskPriorityProps) {
  return (
    <span style={{ color: `${getPriorityMapping[props.value]}` }}>
      {props.label}
    </span>
  )
}
