import React, { createContext } from 'react'
import { useQuery } from '../utils/queryhook'
import { trackingQuery } from './state/query'
import { TrackingObject } from './types'

export const TrackingObjectContext = createContext<TrackingObject | undefined>(
  undefined
)

export function TrackingObjectContextGuard(props: {
  children: React.ReactNode
}) {
  const targetTrackingObject = useQuery(trackingQuery.targetObject$)

  return (
    <TrackingObjectContext.Provider value={targetTrackingObject}>
      {props.children}
    </TrackingObjectContext.Provider>
  )
}
