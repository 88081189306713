import classNames from 'classnames'
import React from 'react'

import './styles/project-details-info-item.scss'

interface ProjectDetailsInfoItemProps {
  title: string
  children: React.ReactNode
  className?: string
}

export function ProjectDetailsInfoItem(props: ProjectDetailsInfoItemProps) {
  const classes = classNames('project-details-info-item', props.className)
  return (
    <div className={classes}>
      <div className="project-details-info-item__title">{props.title}</div>
      {props.children}
    </div>
  )
}
