import React, {useContext} from 'react'
import { SetEstimateAction } from '../../../entities/activities/model'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";
import {ReadableTimeString} from "../../../tracking/TimeConverters";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {Subtask} from "../../../entities/subtask/model";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";

interface SetSubtaskEstimateActivityProps {
  action: SetEstimateAction
  date: string
  user: User | string
}

export function SetSubtaskEstimateActivity(props: SetSubtaskEstimateActivityProps) {
  const { action, user } = props

  const estimate = ReadableTimeString(Number(action.data.changed_to))
  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> estimated the <HighlightedActivity>{subtask?.name}</HighlightedActivity> subtask in <ActivityBoldText>{estimate}</ActivityBoldText>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
