import React, { useState } from 'react'
import { Collapse } from '@blueprintjs/core'
import classNames from 'classnames'

import { User } from '../../entities/user/model'
import { TimeEntry } from '../../entities/time-entry/model'
import { ActivitiesDate } from '../Activities/ActivitiesDate'
import { ActivityBoldText } from '../Activities/text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../Activities/ActivitiyDetailsContent'
import { ActivitiesDetailsButton } from '../Activities/ActivitiesDetailsButton'
import { ID } from '@datorama/akita'
import { HighlightedActivity } from '../Activities/text-formatters/HighlightedActivity'
import { TaskCommentDeleteConfirmationPopover } from '../CommentListItem'
import { ACTIVITIES } from '../../entities/choices/activity'
import {
  durationWithoutSeconds,
  getLocalTimeWithoutSeconds,
  humanizeDate,
} from '../../utils/date'
import { ActivityGrayItalicText } from '../Activities/text-formatters/HighlighGreyItalicText'

interface ManualWorkLogProps {
  worklog: TimeEntry[]
  date: string
  user: ID
  currentUser: User
  onDelete: (id: ID) => void
  dateStart: string
  dateEnd: string
  loggedTimeSum: number
  isAdmin: boolean
}

export function ManualWorkLog(props: ManualWorkLogProps) {
  const { worklog, user } = props

  const [isConfirmationPopoverOpen, setIsConfirmationPopoverOpen] = useState<
    boolean
  >(false)

  const [isExpanded, setIsExpanded] = useState(false)
  const setExpandedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }
  const [detailOpened, setDetailOpened] = useState<ID[]>([])
  const setDetailOpenHandler = (id: ID) => {
    const isOpened = detailOpened.includes(id)
    if (!isOpened) {
      setDetailOpened((detailOpened) => [...detailOpened, id])
    } else {
      setDetailOpened((detailOpened) => detailOpened.filter((x) => x !== id))
    }
  }

  const lastLog = worklog[worklog.length - 1]
  const logsWithoutLast = worklog.filter((item) => item.id !== lastLog.id)

  const closeConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(false)
  }

  const openConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(true)
  }

  const handleDeleteWorkLog = (id: ID) => {
    props.onDelete(id)
    closeConfirmationPopover()
  }

  const classes = classNames('worklog-container', {
    'worklog-container_has-popover': isConfirmationPopoverOpen,
  })

  const renderAction = (timeEntry: TimeEntry) => {
    return (
      <div key={timeEntry.id}>
        <ActivityBoldText>{user}</ActivityBoldText> manually added{' '}
        <ActivityBoldText>
          {durationWithoutSeconds(timeEntry.duration)}
        </ActivityBoldText>{' '}
        of{' '}
        <HighlightedActivity>
          {
            ACTIVITIES.find((activity) => activity.value === timeEntry.activity)
              ?.label
          }
        </HighlightedActivity>{' '}
        in {worklog[0].target.type}.
        <ActivitiesDetailsButton
          type="details"
          isActive={detailOpened.includes(timeEntry.id)}
          onClickHandler={() => setDetailOpenHandler(timeEntry.id)}
        />{' '}
        <ActivitiesDate date={humanizeDate(timeEntry.end)} isActivityDate />{' '}
        {detailOpened.includes(timeEntry.id) && (
          <>
            <ActivityDetailsContent>
              <ActivityBoldText>
                {`${getLocalTimeWithoutSeconds(timeEntry.start)}`}-
                {`${getLocalTimeWithoutSeconds(timeEntry.end)}`}
              </ActivityBoldText>
            </ActivityDetailsContent>
            <div className={classes}>
              <ActivityDetailsContent>
                {timeEntry.comment ? `comment: ${timeEntry.comment}.` : ''}{' '}
                <ActivityGrayItalicText>
                  (
                  {`${getLocalTimeWithoutSeconds(
                    timeEntry.start
                  )} - ${getLocalTimeWithoutSeconds(timeEntry.end)}`}
                  )
                </ActivityGrayItalicText>
              </ActivityDetailsContent>
              {(props.currentUser.id === timeEntry.user || props.isAdmin) && (
                <button
                  type="button"
                  className="task-panel-comment__action-button task-activity__delete-button"
                >
                  <TaskCommentDeleteConfirmationPopover
                    isOpen={isConfirmationPopoverOpen}
                    closeHandler={closeConfirmationPopover}
                    openHandler={openConfirmationPopover}
                    submitHandler={() => handleDeleteWorkLog(timeEntry.id)}
                  />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      {!!logsWithoutLast.length && (
        <>
          <ActivitiesDetailsButton
            isActive={isExpanded}
            onClickHandler={setExpandedStateHandler}
            noMargin={true}
            type={'show-previous'}
            itemsCount={logsWithoutLast.length}
          />{' '}
          <Collapse isOpen={isExpanded}>
            {logsWithoutLast.map((timeEntry) => renderAction(timeEntry))}
          </Collapse>
        </>
      )}
      {renderAction(lastLog)}
    </>
  )
}
