import './styles/project-details.scss'

import React, { useCallback, useEffect } from 'react'
import { ID } from '@datorama/akita'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import isEqual from 'react-fast-compare'
import { sortBy } from 'lodash'

import { ActionType } from '../../../entities/activities/model'
import {
  Project,
  ProjectStatus,
  PROJECT_STATUSES,
} from '../../../entities/project/model'
import { ProjectDetailsActivities } from './ProjectDetailsActivities'
import { ProjectDetailsInfo } from './ProjectDetailsInfo'
import { ProjectPanelDescription } from './ProjectDetailsDescription'
import { ProjectPanelMembers } from './ProjectDetailsMembers'
import { ProjectStatusSelect } from '../../../components/controls/ProjectStatusSelect'
import { ProjectDetailsUploadLogo } from './ProjectDetailsUploadLogo'
import { User } from '../../../entities/user/model'
import { projectPageService } from '../../state/service'
import { useQuery } from '../../../utils/queryhook'
import { projectPageQuery } from '../../state/query'
import { TaskGroupsDetailsInfoDate } from '../../task-groups/details/TaskGroupsDetailsInfoDate'
import { projectService } from '../../../entities/project/service'
import { ModelUpdateCallback } from '../../../utils/types'
import { activityLogService } from '../../../entities/activities/service'
import { authQuery } from '../../../auth/state/query'
import { TaskGroupDetailsTitle } from '../../task-groups/details/TaskGroupDetailsTitle'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'
import { timeEntryService } from '../../../entities/time-entry/service'
import {
  combineHistory,
  HistoryActivityItemType,
  HistoryItem,
} from '../../../history/state/model'
import { smartSuiteAccountQuery } from '../../../entities/smartsuite/accounts/account.query'
import { SmartSuiteAccount } from '../../../entities/smartsuite/accounts/account.model'
import { formatDate, parseDate } from '../../../utils/date'

export function ProjectDetailsContainer() {
  const { projectId } = useParams()

  useEffect(() => {
    projectPageService.setProject(+projectId!)
    activityLogService.loadActivities({ projects__id: projectId! })
    timeEntryService.loadTimeEntries({
      project__id: projectId!,
      limit: 'last_two_months',
    })
  }, [projectId])

  const project = useQuery(projectPageQuery.projectWithUser$)
  const users = useQuery(projectPageQuery.projectUsers$)
  const workspaseUsers = useQuery(projectPageQuery.workspaceUsers$)
  const projectActivities = useQuery(projectPageQuery.projectActivities$)
  const currentUser = useQuery(authQuery.currentUser$)
  const projectWorklog = useQuery(projectPageQuery.projectWorklog$)
  const selectedActivityFilter = useQuery(projectPageQuery.activityFilter$)
  const smartSuiteAccounts = useQuery(
    smartSuiteAccountQuery.currentUserAccounts$
  )

  if (!project) {
    return <></>
  }

  // 1) as any, 2) sortby, 3) move to project panel, 4) combine if filter is set

  const history = sortBy(
    combineHistory(projectActivities as any, projectWorklog),
    'date'
  ).reverse()

  return (
    <ProjectDetails
      project={project}
      users={users}
      currentUser={currentUser}
      workspaceUsers={workspaseUsers}
      activities={projectActivities}
      history={history}
      smartSuiteAccounts={smartSuiteAccounts}
      onUpdateProject={projectService.updateProject}
      onWorkLogDelete={timeEntryService.destroy}
      onUploadLogo={projectPageService.uploadLogo}
      onFilterActivities={projectPageService.updateActivityFilter}
      selectedActivityFilter={selectedActivityFilter}
    />
  )
}

interface ProjectDetailsProps {
  project: Project
  users: User[]
  currentUser: User
  workspaceUsers: User[]
  activities: ActionType[]
  history: HistoryItem[]
  selectedActivityFilter?: HistoryActivityItemType[]

  smartSuiteAccounts: SmartSuiteAccount[]

  onUpdateProject: ModelUpdateCallback<Project>
  onUploadLogo: (file: File) => void
  onWorkLogDelete: (id: ID) => void
  onFilterActivities: (value: HistoryActivityItemType) => void
}

function _ProjectDetails(props: ProjectDetailsProps) {
  const { project, onUpdateProject } = props
  const statusClasses = classNames('project-details__status', {
    'project-details__status_active': project.status === ProjectStatus.ACTIVE,
    'project-details__status_closed': project.status === ProjectStatus.CLOSED,
  })

  const createdAt = formatDate(project.created_at).toString()
  const updatedAt = formatDate(project.updated_at).toString()
  const deadline = project.deadline ? parseDate(project.deadline) : null

  const updateProject = useCallback(
    (update: Partial<Project>) => {
      onUpdateProject({ id: project.id, ...update })
    },
    [onUpdateProject, project.id]
  )

  return (
    <div className="project-details">
      <CustomScrollbar>
        <div className="project-details__header">
          <div className="project-details__header-left">
            <div className="project-details__header-left-top">
              <div className="project-details__title">Project</div>
              <div className={statusClasses}>
                <ProjectStatusSelect
                  currentItem={project.status}
                  items={PROJECT_STATUSES}
                  onItemSelect={(project) =>
                    updateProject({ status: project.status })
                  }
                />
              </div>
            </div>
          </div>
          <div className="project-details__header-right">
            <div className="task-groups-details-info">
              <div className="task-groups-details-info__item">
                <TaskGroupsDetailsInfoDate
                  date={createdAt}
                  user={project.created_by_user}
                  title="Created by"
                />
              </div>
              <div className="task-groups-details-info__item">
                <TaskGroupsDetailsInfoDate
                  date={updatedAt}
                  user={project.updated_by_user}
                  title="Updated by"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="project-details__main-info">
          <ProjectDetailsUploadLogo
            editable={true}
            project={project}
            avatarClassName="project-details__project-logo"
            onUploadAvatar={props.onUploadLogo}
          />
          <div className="project-details__project-name">
            {project.code}{' '}
            <span className="project-details__project-name-line">|</span>{' '}
            <TaskGroupDetailsTitle
              key={`project-name-${project.id}`}
              placeholder="Project name"
              className="project-details__name-textarea"
              onChange={(value) => updateProject({ name: value })}
            >
              {project.name}
            </TaskGroupDetailsTitle>
          </div>
        </div>

        <ProjectDetailsInfo
          key={`project-info-${project.id}`}
          slackChannel={project.slack_channel}
          dateDeadline={deadline}
          onUpdate={updateProject}
        />
        <ProjectPanelMembers
          onChangeSelectedUsersIDs={(selected) =>
            updateProject({ users: selected })
          }
          users={props.workspaceUsers}
          selectedUsersIDs={project.users || []}
        />

        <ProjectPanelDescription
          key={`project-description-${project.id}`}
          onChange={updateProject}
          project={project}
        />

        <ProjectDetailsActivities
          history={props.history}
          className="project-details__section"
          activities={props.activities}
          currentUser={props.currentUser}
          onWorkLogDelete={props.onWorkLogDelete}
          onFilterActivities={props.onFilterActivities}
          selectedActivityFilter={props.selectedActivityFilter}
        />
      </CustomScrollbar>
    </div>
  )
}

export const ProjectDetails = React.memo(_ProjectDetails, isEqual)
