import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ActionEvent, ActionType } from '../entities/activities/model'
import './styles/task-activity.scss'
import { AddDescriptionActivity } from './Activities/AddDescriptionActivity'
import { AddLinkedTaskActivity } from './Activities/AddLinkedTaskActivity'
import { AddFollowerActivity } from './Activities/AddFollowerActivity'
import { ChangeAssigneeActivity } from './Activities/ChangeAssigneeActivity'
import { ChangeEpicActivity } from './Activities/ChangeEpicActivity'
import { ChangePriorityActivity } from './Activities/ChangePriorityActivity'
import { ChangeTaskSizeActivity } from './Activities/ChangeTaskSizeActivity'
import { ChangeStatusActivity } from './Activities/ChangeStatusActivity'
import { CreateActivity } from './Activities/CreateActivity'
import { RenameActivity } from './Activities/RenameActivity'
import { RemoveLinkedTaskActivity } from './Activities/RemoveLinkedTaskActivity'
import { RemoveFollowerActivity } from './Activities/RemoveFollowerActivity'
import { SetAssigneeActivity } from './Activities/SetAssigneeActivity'
import { SetTaskPriorityActivity } from './Activities/SetTaskPriorityActivity'
import { SetTaskScoreActivity } from './Activities/SetTaskScoreActivity'
import { SetStatusActivity } from './Activities/SetStatusActivity'
import { User } from '../entities/user/model'
import { ChangeSprintActivity } from './Activities/ChangeSprintActivity'
import { ChangeProjectActivity } from './Activities/ChangeProjectActivity'
import { ChangeDescriptionActivity } from './Activities/ChangeDescriptionActivity'
import { ChangeTypeActivity } from './Activities/ChangeTypeActivity'
import { ChangeSubtaskAssigneeActivity } from './Activities/SubtaskActivities/ChangeSubtaskAssigneeActivity'
import { RemoveSubtaskActivity } from './Activities/SubtaskActivities/RemoveSubtaskActivity'
import { ChangeSubtaskDescriptionActivity } from './Activities/SubtaskActivities/ChangeSubtaskDescriptionActivity'
import { CreateSubtaskActivity } from './Activities/SubtaskActivities/CreateSubtaskActivity'
import { SetSubtaskAssigneeActivity } from './Activities/SubtaskActivities/SetSubtaskAssigneeActivity'
import { SetSubtaskEstimateActivity } from './Activities/SubtaskActivities/SetSubtaskEstimateActivity'
import { ChangeSubtaskEstimateActivity } from './Activities/SubtaskActivities/ChangeSubtaskEstimateActivity'
import { ChangeSubtaskStatusActivity } from './Activities/SubtaskActivities/ChangeSubtaskStatusActivity'
import { ChangeSubtaskTypeActivity } from './Activities/SubtaskActivities/ChangeSubtaskTypeActivity'
import { ChangeEpicDescriptionActivity } from './Activities/EpicActivities/ChangeEpicDescriptionActivity'
import { CreateEpicActivity } from './Activities/EpicActivities/CreateEpicActivity'
import { ChangeEpicStatusActivity } from './Activities/EpicActivities/ChangeEpicStatusActivity'
import { ChangeSprintDescriptionActivity } from './Activities/SprintActivities/ChangeSprintDescriptionActivity'
import { ChangeSprintStatusActivity } from './Activities/SprintActivities/ChangeSprintStatusActivity'
import { CreateSprintActivity } from './Activities/SprintActivities/CreateSprintActivity'
import { ChangeSprintDatesActivity } from './Activities/SprintActivities/ChangeSprintDatesActivity'
import { ChangeProjectDescriptionActivity } from './Activities/ProjectActivities/ChangeProjectDescriptionActivity'
import { ChangeProjectStatusActivity } from './Activities/ProjectActivities/ChangeProjectStatusActivity'
import { ChangeProjectLogoActivity } from './Activities/ProjectActivities/ChangeProjectLogo'
import { ChangeProjectDeadlineActivity } from './Activities/ProjectActivities/ChangeProjectDeadlineActivity'
import { SetProjectDeadlineActivity } from './Activities/ProjectActivities/SetProjectDeadlineActivity'
import { ChangeProjectSlackChannelActivity } from './Activities/ProjectActivities/ChangeProjectSlackChannelActivity'
import { AddProjectMemberActivity } from './Activities/ProjectActivities/AddProjectMemberActivity'
import { RemoveProjectMemberActivity } from './Activities/ProjectActivities/RemoveProjectMemberActivity'
import { UsersChoicesContext } from '../auth/usersContext'
import { ActivitiesDetailsButton } from './Activities/ActivitiesDetailsButton'
import { Collapse } from '@blueprintjs/core'
import { humanizeDate } from '../utils/date'

interface TaskActivityProps {
  action: ActionType[]
  date: string
  currentUser: User
}

const RENDERERS: { [key in ActionEvent]?: any } = {
  create_task: CreateActivity,
  create_subtask: CreateSubtaskActivity,
  set_task_size: SetTaskScoreActivity,
  change_task_size: ChangeTaskSizeActivity,
  change_task_assignee: ChangeAssigneeActivity,
  change_subtask_assignee: ChangeSubtaskAssigneeActivity,
  set_task_assignee: SetAssigneeActivity,
  set_subtask_assignee: SetSubtaskAssigneeActivity,
  change_task_name: RenameActivity,
  change_subtask_name: RenameActivity,
  add_task_description: AddDescriptionActivity,
  change_task_json_description: ChangeDescriptionActivity,
  change_subtask_json_description: ChangeSubtaskDescriptionActivity,
  set_task_status: SetStatusActivity,
  set_subtask_status: SetStatusActivity,
  change_task_status: ChangeStatusActivity,
  change_subtask_status: ChangeSubtaskStatusActivity,
  set_task_priority: SetTaskPriorityActivity,
  change_task_priority: ChangePriorityActivity,
  change_subtask_priority: ChangePriorityActivity,
  add_task_linked_tasks: AddLinkedTaskActivity,
  set_task_linked_tasks: AddLinkedTaskActivity,
  remove_task_linked_tasks: RemoveLinkedTaskActivity,
  add_task_followers: AddFollowerActivity,
  remove_task_followers: RemoveFollowerActivity,
  change_task_epic: ChangeEpicActivity,
  change_task_sprint: ChangeSprintActivity,
  change_task_project: ChangeProjectActivity,
  set_task_sprint: ChangeSprintActivity,
  set_task_epic: ChangeEpicActivity,
  set_subtask_current_estimate: SetSubtaskEstimateActivity,
  change_subtask_current_estimate: ChangeSubtaskEstimateActivity,
  change_subtask_type: ChangeSubtaskTypeActivity,
  set_subtask_type: ChangeTypeActivity,
  change_epic_json_description: ChangeEpicDescriptionActivity,
  change_sprint_json_description: ChangeSprintDescriptionActivity,
  create_epic: CreateEpicActivity,
  change_epic_status: ChangeEpicStatusActivity,
  change_epic_name: RenameActivity,
  change_sprint_name: RenameActivity,
  change_sprint_status: ChangeSprintStatusActivity,
  create_sprint: CreateSprintActivity,
  change_sprint_start_date: ChangeSprintDatesActivity,
  change_sprint_staging_date: ChangeSprintDatesActivity,
  change_sprint_release_date: ChangeSprintDatesActivity,
  change_project_json_description: ChangeProjectDescriptionActivity,
  create_project: CreateActivity,
  change_project_name: RenameActivity,
  change_project_status: ChangeProjectStatusActivity,
  change_project_logo: ChangeProjectLogoActivity,
  set_project_deadline: SetProjectDeadlineActivity,
  change_project_deadline: ChangeProjectDeadlineActivity,
  change_project_slack_channel: ChangeProjectSlackChannelActivity,
  add_project_users: AddProjectMemberActivity,
  remove_project_users: RemoveProjectMemberActivity,
  delete_subtask: RemoveSubtaskActivity,
}

export function ActivityLog({ action, currentUser }: TaskActivityProps) {
  const users = useContext(UsersChoicesContext)
  const user = useMemo(() => {
    const actor = users.find((x: User) => x.id === action[0].actor)
    return actor ? (actor.id === currentUser.id ? 'You' : actor.full_name) : ''
  }, [action, currentUser.id, users])

  const renderAction = useCallback(
    (action: ActionType) => {
      const ActionComponent = RENDERERS[action.event]
      if (ActionComponent) {
        return (
          <ActionComponent
            action={action}
            date={humanizeDate(action.date)}
            user={user}
          />
        )
      }
      return
    },
    [user]
  )

  const firstActivity = action[0]
  const otherActivities = action.slice(1)

  return (
    <div className="task-activity-wrap">
      <div className="task-activities-section__activity task-activity">
        {renderAction(firstActivity)}
      </div>
      {!!otherActivities.length && (
        <ActivityLogDetails count={otherActivities.length}>
          {otherActivities.map((item) => (
            <div
              key={item.id}
              className="task-activities-section__activity task-activity"
            >
              {renderAction(item)}
            </div>
          ))}
        </ActivityLogDetails>
      )}
    </div>
  )
}

export const ActivityLogDetails: React.FC<{ count: number }> = ({
  count,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={useCallback(
          () => setIsExpanded(!isExpanded),
          [isExpanded]
        )}
        noMargin={true}
        type={'show-previous'}
        itemsCount={count}
      />{' '}
      <Collapse isOpen={isExpanded}>{children}</Collapse>
    </>
  )
}
