import '../../styles/user-profile.scss'

import React, { useCallback, useMemo } from 'react'
import { TimezoneDisplayFormat } from '@blueprintjs/timezone'
import { getLocalTime, User } from '../../../entities/user/model'
import { UserProfileTitleWithDivider } from './UserProfileTitleWithDivider'
import { UserProfileTextInfo } from './UserProfileTextInfo'
import { UserProfileTimezoneInfo } from './UserProfileTimezoneInfo'
import { UserProfileBirthdayInfo } from './UserProfileBirthdayInfo'
import { UserProfileLocalTimeInfo } from './UserProfileLocalTimeInfo'
import { ModelUpdateCallback } from '../../../utils/types'

interface UserProfilePersonalDetailsProps {
  user: User
  editable: boolean
  onUserUpdate: ModelUpdateCallback<User>
}

export function UserProfilePersonalDetails(
  props: UserProfilePersonalDetailsProps
) {
  const { user, onUserUpdate } = props

  const onLocationChange = useCallback(
    (location) => onUserUpdate({ id: user.id, location }),
    [user, onUserUpdate]
  )
  const onTimezoneChange = useCallback(
    (timezone) => onUserUpdate({ id: user.id, timezone }),
    [user, onUserUpdate]
  )
  const onBirthdayChange = useCallback(
    (birthday) => onUserUpdate({ id: user.id, birthday }),
    [user, onUserUpdate]
  )

  const allFieldsAreEmpty = useMemo(
    () =>
      ['slack_name', 'location', 'timezone', 'birthday'].every(
        (value) => !user[value]
      ),
    [user]
  )

  if (!props.editable && allFieldsAreEmpty) {
    return null
  }

  const localTime = getLocalTime(user).format('HH:mm')

  return (
    <div className="user-profile__section">
      <UserProfileTitleWithDivider
        dividerClass={'user-title-divider'}
        orientation={'left'}
        label={'Personal details'}
      />
      <div className="user-profile__infos user-profile__infos_details">
        <UserProfileTextInfo
          modifier={'location'}
          value={props.user.location || ''}
          label={'location'}
          placeholder={'Enter location...'}
          editable={props.editable}
          onChange={onLocationChange}
        />
        <UserProfileTimezoneInfo
          timezone={props.user.timezone}
          format={TimezoneDisplayFormat.ABBREVIATION}
          editable={props.editable}
          onChange={onTimezoneChange}
        />
        <UserProfileLocalTimeInfo localTime={localTime} />
        <UserProfileBirthdayInfo
          birthday={props.user.birthday}
          editable={props.editable}
          onChange={onBirthdayChange}
        />
      </div>
    </div>
  )
}
