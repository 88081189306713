import { ID } from '@datorama/akita'
import { EventSourceFunc } from '@fullcalendar/core'
import moment from 'moment/moment'

import { calendarEventService } from '../service'
import { CalendarEvent } from '../model'
import { Project } from '../../project/model'

// returns callback that can access query arguments
// this approach allows reusing eventSource across multiple components
export function createEventSource(
  selectedUsersIDs: ID[],
  selectedProject: Project | null,
  workspaceId: string | undefined
): EventSourceFunc {
  return (fetchInfo, successCallback, failureCallback) => {
    if (!!selectedUsersIDs.length || !!selectedProject) {
      const query: {
        start: string
        end: string
        user_ids?: ID[]
        project_id?: ID | null
      } = {
        start: fetchInfo.start.toISOString(),
        end: fetchInfo.end.toISOString(),
        project_id: selectedProject?.id ?? null,
      }
      if (!isFetchAll(selectedUsersIDs)) {
        query.user_ids = selectedUsersIDs
      }

      calendarEventService.fetchEvents(
        query,
        successCallback,
        failureCallback,
        workspaceId
      )
    } else {
      successCallback([])
    }
  }
}

export const getTimeRange = (event: CalendarEvent): string => {
  return `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`
}

const isFetchAll = (selectedUsersIDs: ID[]): boolean => {
  return selectedUsersIDs.length === 1 && selectedUsersIDs[0] === -1
}