import { timeInSeconds } from './TimeConverters'

export const minWorkingTimeSec = timeInSeconds(6)
export const badWorkingTimeSec = minWorkingTimeSec - timeInSeconds(0, 30)
export const normalWorkingTimeSec = minWorkingTimeSec + timeInSeconds(0, 30)

export const calendarTimeClasses = {
  belowNormal: 'fc-state-below-normal',
  normal: 'fc-state-normal',
  aboveNormal: 'fc-state-above-normal',
  unknown: 'fc-state-unknown',  // use if you need some neutral color for non-allDay entries
  grouped: 'fc-state-grouped',  // used when grouping multiple time entries together
}
