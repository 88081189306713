import { isEqual } from 'lodash'
import { EditorView } from 'prosemirror-view'
import { Node as ProseMirrorNode, Schema } from 'prosemirror-model'
import { SuggestStateMatch, Suggester } from 'prosemirror-suggest'
import { TextSelection } from 'prosemirror-state'

import { getPositionOffset } from "../external-link/utils"
import { MentionPluginKey } from "./plugins"

export const mentionSuggester = (key: MentionPluginKey): Suggester => ({
  noDecorations: true,
  char: key.prefix,
  name: `mention-suggestion-${key.prefix}`,
  supportedCharacters: /[ \w\d_]+/,
  createCommand: ({ match, view }) => {
    return (target: any) => {
      createMention(match, view, target, key.prefix)
      view.focus()
    }
  },
  keyBindings: {
    Esc: ({view}) => {
      const { enable } = key.getState(view.state)
      if (enable) { key.updateState(view, {enable: false, query: null}) }
      return true
    },
    ArrowUp: ({view}) => {
      const { enable, activeIndex } = key.getState(view.state)
      if (enable) {
        key.updateState(view, {activeIndex: Math.max(activeIndex - 1, 0)})
      }
      return enable
    },
    ArrowDown: ({view}) => {
      const { enable, activeIndex, items } = key.getState(view.state)
      if (enable) {
        key.updateState(view, {activeIndex: Math.min(activeIndex + 1, items.length - 1)})
      }
      return enable
    },
    Enter: ({view, command}) => {
      const { enable, activeIndex, items } = key.getState(view.state)
      if (enable) { command(items[activeIndex]) }
      return enable
    }
  },
  onChange: ({view, range, queryText, command, ...props}) => {
    const prevState = key.getState(view.state)
    const { targetElement, offset } = getPositionOffset(view, range.from)
    const query = queryText.full

    if (prevState.query !== query || !isEqual(prevState.offset, offset)) {
      key.updateState(view, {
        enable: true, activeIndex: 0, targetElement, offset, query, command
      })
      view.focus()
    }
  },
  onExit: ({view, ...props}) => {
    const { enable } = key.getState(view.state)
    if (enable) { key.updateState(view, { enable: false, query: null }) }
  },
})

function createMention(
  match: SuggestStateMatch,
  view: EditorView,
  target: any,
  prefix: string
): void {
  const { from, to } = match.range
  const schema = view.state.schema as Schema
  const mention = schema.nodes.mention.create({
    id: target.id, title: target.title, prefix
  }) as ProseMirrorNode
  const tr = view.state.tr
  tr.replaceRangeWith(from, to, mention)
  tr.setSelection(new TextSelection(tr.doc.resolve(from + 1)))
  view.dispatch(tr)
}
