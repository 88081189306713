import React from 'react'
import { Divider, Dropdown, Typography } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { DownOutlined } from '@ant-design/icons'
import { TargetLink } from './TrackerStatusContainer'
import { Workspace } from '../entities/workspace/model'
import { EmptyTrackedWithUserObject } from './types'
import { SUBTASK_TYPES } from '../entities/choices/subtaskType'
import { ACTIVITIES } from '../entities/choices/activity'
import { CustomScrollbar } from '../components/custom-scrollbar/CustomScrollbar'

const { Text, Paragraph } = Typography

interface LastActivityDropdownProps {
  myLastActivities: any
  currentTeamActivities: any
  workspace: Workspace
  onShowActivities: (flag: any) => void
}

export function LastActivityDropdown(props: LastActivityDropdownProps) {
  const { myLastActivities, currentTeamActivities, workspace } = props

  const activityContact = (
    <div className="last-activity-list">
      <CustomScrollbar
        rendererMaxHeight={400}
        translateContentSizeYToHolder={true}
      >
        <div className="last-activity-list__inner">
          <LastActivityList
            title="My last activities"
            items={myLastActivities}
            workspace={workspace}
          />
          <LastActivityList
            title="Current team activities"
            items={currentTeamActivities}
            workspace={workspace}
          />
        </div>
      </CustomScrollbar>
    </div>
  )

  return (
    <div>
      <Dropdown
        overlay={activityContact}
        trigger={['click']}
        onVisibleChange={props.onShowActivities}
      >
        <span
          className="ant-dropdown-link header-link"
          style={{ cursor: 'pointer' }}
        >
          Last Activities <DownOutlined />
        </span>
      </Dropdown>
      <Divider type="vertical" />
    </div>
  )
}

interface LastActivityListProps {
  title: String
  workspace: Workspace
  items: EmptyTrackedWithUserObject[]
}

function LastActivityList(props: LastActivityListProps) {
  const { title, items, workspace } = props

  return (
    <Paragraph>
      <Text strong>{title}</Text>
      {items &&
        items.map((item, index) => {
          if (!item.object) {
            return (
              <div key={index}>
                <SyncOutlined spin />
              </div>
            )
          }
          return (
            <LastActivityListItem
              key={index}
              item={item}
              workspace={workspace}
            />
          )
        })}
    </Paragraph>
  )
}

interface LastActivityListItemProps {
  workspace: Workspace
  item: EmptyTrackedWithUserObject
}

function LastActivityListItem(props: LastActivityListItemProps) {
  const { item, workspace } = props
  const activityLabel =
    item.type === 'subtask'
      ? SUBTASK_TYPES.find((type) => item.object.type === type.value)?.label
      : ACTIVITIES.find((activity) => activity.value === item.activity)?.label

  return (
    <div className="last-activity-list__wrapper">
      {item.user ? `${item.user.full_name}: ` : ''}
      <TargetLink
        target={item}
        workspace={workspace}
        activity={activityLabel}
      />
    </div>
  )
}
