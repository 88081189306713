import React from 'react'
import classNames from 'classnames'
import { Position, Tooltip } from '@blueprintjs/core'

import { SpriteIcon } from '../../../../../components/ui/sprite-icon/SpriteIcon'
import Icons from '../../../../../assets/icons/editor/sprite.svg'

export type ToolBarItemProps = {
  iconName: string
  isActive?: boolean
  label?: string | JSX.Element | undefined
  onClick: (event: React.MouseEvent) => void
}

export const ToolBarItem: React.FC<ToolBarItemProps> = ({iconName, isActive = false, label, onClick}) => (
  <Tooltip
    popoverClassName="editor-toolbar__tooltip"
    content={label}
    position={Position.BOTTOM}
    minimal={true}
  >
    <span onClick={onClick} className={classNames(
      'editor-toolbar__item', {'is-active': isActive}
    )}>
      <SpriteIcon spritePath={Icons} name={iconName}/>
    </span>
  </Tooltip>
)
