import {
  ActiveState,
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita'
import { ActionType } from './model'

export interface ActivityLogState extends EntityState<ActionType, ID>, ActiveState {}

@StoreConfig({ name: 'entity-activity-log' })
export class ActivityLogStore extends EntityStore<ActivityLogState> {}

export const activityLogStore = new ActivityLogStore()
