import React from 'react'
import { SetTaskAssigneeAction } from '../../entities/activities/model'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {User} from "../../entities/user/model";

interface SetAssigneeActivityProps {
  action: SetTaskAssigneeAction
  date: string
  user: User | string
}

export function SetAssigneeActivity(props: SetAssigneeActivityProps) {
  const { action, user } = props

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> assigned {' '}the {action.target.type}{' '} to <ActivityBoldText>{action.data.changed_to.label}</ActivityBoldText>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
