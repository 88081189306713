import React from 'react'
import { TimezoneDisplayFormat } from '@blueprintjs/timezone'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import { UserProfileTimeZoneInput } from './UserProfileTimeZoneInput'

import '../../styles/user-field.scss'

interface UserProfileTimezoneInfoProps {
  timezone: string
  format: TimezoneDisplayFormat
  editable: boolean
  onChange: (value: string) => void
}

export function UserProfileTimezoneInfo(props: UserProfileTimezoneInfoProps) {
  if (!props.editable && !props.timezone) {
    return null
  }
  return (
    <div className="user-profile__info user-profile__info_timezone user-info">
      <UserProfileFieldTitle label={'time zone'} />
      <UserProfileTimeZoneInput
        timezone={props.timezone}
        format={props.format}
        editable={props.editable}
        onChange={props.onChange}
      />
    </div>
  )
}
