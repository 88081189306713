import { map } from 'rxjs/operators'

import { ID, QueryEntity } from '@datorama/akita'
import { Dictionary } from 'lodash'
import { boundMethod } from 'autobind-decorator'
import { TrackingDataService, trackingDataService } from './data-service'
import { TrackingStore, trackingStore } from './store'
import { AddTimeManuallyObject, TrackableType } from '../types'
import { TrackingQuery, trackingQuery, entityQuery } from './query'
import { TaskService, taskService } from '../../entities/task/service'
import { SubtaskService, subtaskService } from '../../entities/subtask/service'
import { EpicService, epicService } from '../../entities/epic/service'
import { SprintService, sprintService } from '../../entities/sprint/service'
import { ProjectService, projectService } from '../../entities/project/service'
import { Activity } from '../../entities/choices/activity'
import { NeverError } from '../../utils/errors'
import { messageDispatcher } from '../../utils/message-dispatcher'

export class TrackingService {
  loadState() {
    this.dataService.getState().subscribe((state) => {
      this.store.update(state)
    })
  }

  @boundMethod
  stopTracking() {
    this.dataService.stopTracking().subscribe(
      (state) => {
        this.store.update(state)
      },
      (error) => {
        messageDispatcher.putErrorMessage(
          `Oops, time tracking cannot stop. Try once again. Error ${error.response.status}`
        )
      },
    )
  }

  @boundMethod
  startTracking(type: TrackableType, id: ID, activity: Activity | null) {
    this.dataService.startTracking(type, id, activity).subscribe(
      (state) => {
        this.store.update(state)
      },
      (error) => {
        messageDispatcher.putErrorMessage(
          `Oops, time tracking cannot start. Try once again. Error ${error.response.status}`
        )
      },
    )
  }

  @boundMethod
  addTimeManually(type: TrackableType, id: ID, data: AddTimeManuallyObject) {
    this.dataService.addTimeManually(type, id, data).subscribe(
      (state) => {
        this.store.update(state)
        messageDispatcher.putSuccessMessage('Time has been added manually')
        this.cleanupFormErrors()
        this.closeAddTimeForm()
      },
      (error) => {
        this.store.update(() => ({
          formErrors: error.response.data,
        }))
      }
    )
  }

  loadTargetByParams(id: ID, type: String) {
    this._loadEntityByType(id, type)
  }

  getTargetByParams(id: ID, type: TrackableType, activity: Activity | null) {
    if (!this.entityQuery[type].hasEntity(id)) {
      this._loadEntityByType(id, type)
    }

    return this.entityQuery[type].selectEntity(id).pipe(
      map((entity) => {
        return { type: type, object: entity, activity: activity }
      })
    )
  }

  loadTarget() {
    const { target } = this.query.getValue()

    if (!target) {
      return
    }

    this._loadEntityByType(target.id, target.type)
  }

  addComment(entry: number, comment: string) {
    this.dataService.addComment(entry, comment).subscribe((state) => {
      this.store.update(state)
    })
  }

  _loadEntityByType(id: ID, type: String) {
    switch (type) {
      case 'task':
        return this.taskService.loadTask(id)

      case 'project':
        return this.projectService.loadProject(id)

      case 'subtask':
        return this.subtaskService.loadSubtask(id)

      case 'epic':
        return this.epicService.loadEpic(id)

      case 'sprint':
        return this.sprintService.loadSprint(id)

      default:
        throw new NeverError(type)
    }
  }

  @boundMethod
  cleanupFormErrors() {
    this.store.update(() => ({
      formErrors: null,
    }))
  }

  @boundMethod
  openAddTimeForm() {
    this.store.update(() => ({
      isFormOpened: true,
      formErrors: null,
    }))
  }

  @boundMethod
  closeAddTimeForm() {
    this.store.update(() => ({
      isFormOpened: false,
      formErrors: null,
    }))
  }

  constructor(
    private dataService: TrackingDataService,
    private store: TrackingStore,
    private query: TrackingQuery,
    private projectService: ProjectService,
    private epicService: EpicService,
    private sprintService: SprintService,
    private taskService: TaskService,
    private subtaskService: SubtaskService,
    private entityQuery: Dictionary<QueryEntity<any>>
  ) {}
}

export const trackingService = new TrackingService(
  trackingDataService,
  trackingStore,
  trackingQuery,
  projectService,
  epicService,
  sprintService,
  taskService,
  subtaskService,
  entityQuery
)
