import '../../styles/user-profile.scss'

import React, { useCallback } from 'react'
import { User } from '../../../entities/user/model'
import { UserProfileTitleWithDivider } from './UserProfileTitleWithDivider'
import { UserProfileSocialInfo } from './UserProfileSocialInfo'
import { UserProfileTextInfo } from './UserProfileTextInfo'
import { UserProfileSocialLink } from './UserProfileSocialLink'
import { ModelUpdateCallback } from '../../../utils/types'

interface UserProfilePrivateContactsProps {
  user: User
  editable: boolean
  onUserUpdate: ModelUpdateCallback<User>
}

export function UserProfilePrivateContacts(
  props: UserProfilePrivateContactsProps
) {
  const { user, onUserUpdate } = props

  const onUpdateContact = useCallback(
    (data: Partial<User['private_contacts']>) => {
      onUserUpdate({
        id: user.id,
        private_contacts: data,
      })
    },
    [user, onUserUpdate]
  )
  const onUpdatePhone = useCallback((phone) => onUpdateContact({ phone }), [
    onUpdateContact,
  ])
  const onUpdateTelegram = useCallback(
    (telegram) => onUpdateContact({ telegram }),
    [onUpdateContact]
  )
  const onUpdateSkype = useCallback((skype) => onUpdateContact({ skype }), [
    onUpdateContact,
  ])
  const onUpdateEmergency = useCallback(
    (emergency) => onUpdateContact({ emergency }),
    [onUpdateContact]
  )
  const onUpdateViber = useCallback((viber) => onUpdateContact({ viber }), [
    onUpdateContact,
  ])
  const onUpdateFacebook = useCallback(
    (facebook) => onUpdateContact({ facebook }),
    [onUpdateContact]
  )
  const onUpdateLinkedin = useCallback(
    (linkedin) => onUpdateContact({ linkedin }),
    [onUpdateContact]
  )

  const allFieldAreEmpty = Object.values(props.user.private_contacts).every(
    (value) => !value
  )

  if (!props.editable && allFieldAreEmpty) {
    return null
  }

  return (
    <div>
      <UserProfileTitleWithDivider
        dividerClass={'user-title-divider'}
        orientation={'left'}
        label={'Private contacts'}
      />
      <div className="user-profile__infos user-profile__infos_private">
        <UserProfileTextInfo
          label={'mobile'}
          value={props.user.private_contacts.phone || ''}
          placeholder={'Enter phone number...'}
          editable={props.editable}
          onChange={onUpdatePhone}
        />
        <UserProfileSocialInfo
          label={'telegram'}
          value={props.user.private_contacts.telegram || ''}
          editable={props.editable}
          onChange={onUpdateTelegram}
        />
        <UserProfileSocialInfo
          label={'skype'}
          value={props.user.private_contacts.skype || ''}
          editable={props.editable}
          onChange={onUpdateSkype}
        />
        <UserProfileTextInfo
          label={'emergency contact'}
          value={props.user.private_contacts.emergency || ''}
          placeholder={'Enter contact...'}
          editable={props.editable}
          onChange={onUpdateEmergency}
        />
        <UserProfileSocialInfo
          label={'viber'}
          value={props.user.private_contacts.viber || ''}
          editable={props.editable}
          onChange={onUpdateViber}
        />
        <UserProfileSocialLink
          label={'facebook'}
          value={props.user.private_contacts.facebook || ''}
          editable={props.editable}
          onChange={onUpdateFacebook}
        />
        <UserProfileSocialLink
          label={'linkedin'}
          value={props.user.private_contacts.linkedin || ''}
          editable={props.editable}
          onChange={onUpdateLinkedin}
        />
      </div>
    </div>
  )
}
