import './styles/report-header.scss'

import React from 'react'
import { Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import { FilterButton } from '../components/FilterButton'
import { Project } from '../entities/project/model'
import { User } from '../entities/user/model'
import { formatDate } from '../utils/date'

interface ReportHeaderProps {
  project?: Project | null
  user?: User | null
  startDate: Date | null
  endDate: Date | null
  showFiltersPanel: boolean
  onToggleFiltersPanel: (value: boolean) => void
  copyReportButtonActive: boolean
  customTitle?: string
  onCopyReport: () => void
}

export function ReportHeader(props: ReportHeaderProps) {
  return (
    <div className="report-page__header report-header">
      <div className="report-header__left">
        <FilterButton
          className="report-header__button-filter"
          showFilters={props.showFiltersPanel}
          onToggleFilters={props.onToggleFiltersPanel}
        />
        {!!props.project && (
          <>
            <div className="report-header__entity-name">
              <span className="report-header__project-abbr">
                {props.project.code}
              </span>
              {' | '}
              <span className="report-header__entity-fullname">
                {props.project.name}
              </span>
            </div>
            <div className="report-header__date-range">
              from{' '}
              <span className="report-header__date">
                {formatDate(props.startDate!)}
              </span>{' '}
              to{' '}
              <span className="report-header__date">
                {formatDate(props.endDate!)}
              </span>
            </div>
          </>
        )}
        {!!props.user && (
          <>
            <div className="report-header__entity-name">
              <span className="report-header__entity-fullname">
                {props.user.full_name || props.user.email}
              </span>
            </div>
            <div className="report-header__date-range">
              from{' '}
              <span className="report-header__date">
                {formatDate(props.startDate!)}
              </span>{' '}
              to{' '}
              <span className="report-header__date">
                {formatDate(props.endDate!)}
              </span>
            </div>
          </>
        )}
      </div>
      {props.customTitle && (
        <div className="report-header__left">
          <div className="report-header__entity-name">
            <span className="report-header__project-abbr">
              {props.customTitle}
            </span>
          </div>
        </div>
      )}
      <div className="report-header__right">
        <Button
          disabled={!props.copyReportButtonActive}
          className="report-header__button-copy"
          onClick={props.onCopyReport}
        >
          Copy Report
          <CopyOutlined />
        </Button>
      </div>
    </div>
  )
}
