import { applyTransaction } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { UserStatus } from '../../entities/user/model'
import { Project } from '../../entities/project/model'
import {
  usersPageStore,
  UsersPageStore,
  usersPageStoreInitialState,
} from './store'

export class UsersPageService {
  constructor(private store: UsersPageStore) {}

  @boundMethod
  setSearchQuery(query: string) {
    this.store.update({ searchQuery: query })
  }

  @boundMethod
  setShowFiltersPanel(show: boolean) {
    this.store.update({ showFiltersPanel: show })
  }

  setProjectFilter(project: Project | null) {
    this.store.update({ projectFilter: project })
  }

  setStatusFilters(statusFilters: UserStatus[]) {
    this.store.update({ statusFilters: statusFilters })
  }

  toggleStatusFilter(statusFilter: UserStatus) {
    this.store.update((state) => {
      const index = state.statusFilters.indexOf(statusFilter)
      if (index !== -1) {
        state.statusFilters = state.statusFilters.filter(
          (name) => name !== statusFilter
        )
      } else {
        state.statusFilters = [...state.statusFilters, statusFilter]
      }
      return state
    })
  }

  @boundMethod
  resetFilters() {
    applyTransaction(() => {
      this.setProjectFilter(usersPageStoreInitialState.projectFilter)
      this.setStatusFilters(usersPageStoreInitialState.statusFilters)
      this.setSearchQuery('')
    })
  }
}

export const usersPageService = new UsersPageService(usersPageStore)
