import { useQuery } from '../../utils/queryhook'
import { BaseTaskTypeProvider } from '../extentions/tasktype/provider'
import { taskTypeQuery } from '../../entities/task-type/query'

export class TaskTypeProvider extends BaseTaskTypeProvider {
  useTaskType = ({
    id,
  }: {
    id: string | number
  }): { id: number; name: string } => {
    return useQuery(taskTypeQuery.selectEntity(id)) || {}
  }

  useSearch = ({
    query,
  }: {
    query: string
  }): { id: number; name: string }[] => {
    return useQuery(taskTypeQuery.all$).filter((taskType) => {
      return taskType.name?.toLowerCase().includes(query?.toLowerCase())
    })
  }
}
