import React from 'react'
import { ErrorPageLayout } from '../components/error-page-layout/ErrorPageLayout'
import { ErrorPageLink } from '../components/error-page-link/ErrorPageLink'

interface ErrorPageNotFoundProps {}

export function ErrorPageNotFound(props: ErrorPageNotFoundProps) {
  return (
    <ErrorPageLayout
      errorNumber={404}
      errorMessage="Oops! We cannot seem to find the page you're looking for."
    >
      <ErrorPageLink text="Home" to="/" />
    </ErrorPageLayout>
  )
}
