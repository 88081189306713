import React, {useContext, useState} from 'react'
import { Collapse } from '@blueprintjs/core'

import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'

import '../../styles/task-activity.scss'
import {ChangeEstimateAction} from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {ReadableTimeString} from "../../../tracking/TimeConverters";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {Subtask} from "../../../entities/subtask/model";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";
import {Extension, ProseMirrorEditor} from "../../../editor";
import {InternalLinkProvider, MentionProvider} from "../../../editor/providers";
import {
  AttachmentExtension,
  ExternalLinkExtension,
  InternalLinkExtension,
  MentionExtension
} from "../../../editor/extentions";
import {BaseAttachmentProvider} from "../../../editor/extentions/attacment/provider";

interface ChangeSubtaskEstimateActivityProps {
  action: ChangeEstimateAction
  date: string
  user: User | string
}

export function ChangeSubtaskEstimateActivity(props: ChangeSubtaskEstimateActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  const currentEstimate = ReadableTimeString(Number(action.data.changed_to))
  const previousEstimate = ReadableTimeString(Number(action.data.changed_from))
  const initialEstimate = ReadableTimeString(Number(action.data.initial_estimate))
  const differenceOfEstimate = (Number(action.data.changed_to) / Number(action.data.initial_estimate)).toFixed(1)
  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  const extensions: Extension[] = [
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new BaseAttachmentProvider())
  ]

  const onChangeEditor = () => {}

  const onBlur = () => {}


  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      changed the estimate of <HighlightedActivity>{subtask?.name}</HighlightedActivity> subtask to{' '}
      <ActivityBoldText>{currentEstimate}</ActivityBoldText>.{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          {user} changed the estimate of <HighlightedActivity>{subtask?.name}</HighlightedActivity> subtask from <HighlightedActivity>{previousEstimate}</HighlightedActivity>{' '}
          to <HighlightedActivity>{currentEstimate}</HighlightedActivity>. The initial estimate was <HighlightedActivity>{initialEstimate}</HighlightedActivity>. The current estimate is {differenceOfEstimate}x increasing of Initial score.{' '} <br/>
          Comment: <HighlightedActivity><ProseMirrorEditor
            value={action.data.reason ? action.data.reason : null}
            extensions={extensions}
            editable={false}
            onChange={onChangeEditor}
            onBlur={onBlur}
            className="activity-page-editor"
          /></HighlightedActivity>.
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
