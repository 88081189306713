import { TaskPanelStore, taskPanelStore } from './store'
import { ID } from '@datorama/akita'
import { taskDataService } from '../../../../entities/task/data-service'
import { taskService, TaskService } from '../../../../entities/task/service'
import { taskStore } from '../../../../entities/task/store'
import { boundMethod } from 'autobind-decorator'
import {
  Subtask,
  SubtaskStatus,
  SubtaskType,
} from '../../../../entities/subtask/model'
import { taskPanelQuery, TaskPanelQuery } from './query'
import {
  subtaskService,
  SubtaskService,
} from '../../../../entities/subtask/service'
import { taskQuery, TaskQuery } from '../../../../entities/task/query'
import { Comment } from '../../../../entities/comment/model'
import {
  commentsService,
  CommentService,
} from '../../../../entities/comment/service'
import { arrayToggle } from '../../../../utils/arrayToggle'
import { HistoryActivityItemType } from '../../../../history/state/model'

export class TaskPanelService {
  constructor(
    private store: TaskPanelStore,
    private query: TaskPanelQuery,
    private taskQuery: TaskQuery,
    private subtaskService: SubtaskService,
    private taskService: TaskService,
    private commentService: CommentService
  ) {}

  @boundMethod
  setActive(id: ID | null) {
    this.store.update({ taskId: id })
  }

  @boundMethod
  updateSubtasksFilterTypes(type: SubtaskType) {
    this.store.update(({ subtasksFilter: { types, statuses } }) => ({
      subtasksFilter: { types: arrayToggle(types, type), statuses },
    }))
  }

  @boundMethod
  updateSubtasksFilterStatuses(status: SubtaskStatus) {
    this.store.update(({ subtasksFilter: { types, statuses } }) => ({
      subtasksFilter: { statuses: arrayToggle(statuses, status), types },
    }))
  }

  @boundMethod
  updateActivityFilter(choice: HistoryActivityItemType) {
    this.store.update(({ activitiesFilter }) => ({
      activitiesFilter: arrayToggle(activitiesFilter, choice),
    }))
  }

  @boundMethod
  removeLinkedTask(idToRemove: ID) {
    const taskID = this.query.getValue().taskId
    this.taskService.removeLinkedTask(taskID!, idToRemove)
  }

  @boundMethod
  addLinkedTask(idToAdd: ID) {
    const taskID = this.query.getValue().taskId
    if (taskID === Number(idToAdd)) {
      return
    }
    this.taskService.addLinkedTask(taskID!, idToAdd)
  }

  @boundMethod
  addFollowers(idsToAdd: ID[]) {
    const taskID = this.query.getValue().taskId
    this.taskService.addFollowers(taskID!, idsToAdd)
  }

  @boundMethod
  subtaskCreate(subtask: Partial<Subtask>) {
    const taskID = this.query.getValue().taskId

    this.subtaskService.createSubtask({
      ...subtask,
      task: taskID!,
    })
  }

  @boundMethod
  commentCreate(comment: Partial<Comment>) {
    const taskID = this.query.getValue().taskId

    this.commentService.createComment({
      ...comment,
      target: {
        type: 'task',
        id: taskID!,
      },
    })
  }

  // loadTaskDetails(id: ID) {
  loadTaskDetails(taskCode: string) {
    this.store.setLoading(true)
    taskDataService.getExtraFields(taskCode).subscribe((task) => {
      this.setActive(task.id)
      taskStore.setActive(task.id)
      taskStore.update(task.id, task)
      this.store.setLoading(false)
    })
  }

  unloadTaskDetails(id: ID) {
    this.taskService.unloadTaskDetails(id)
  }

  @boundMethod
  unloadActiveTaskDetails() {
    this.taskService.unloadActiveTaskDetails()
  }
}

export const taskPanelService = new TaskPanelService(
  taskPanelStore,
  taskPanelQuery,
  taskQuery,
  subtaskService,
  taskService,
  commentsService
)
