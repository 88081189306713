import React, { useContext, useState } from 'react'

import { UserAvatar } from '../components/UserAvatar'
import { IconNames } from '@blueprintjs/icons'
import './styles/task-panel-comment.scss'
import { Icon, Popover } from '@blueprintjs/core'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'
import { ActivitiesDate } from './Activities/ActivitiesDate'
import { Comment } from '../entities/comment/model'
import { Extension, ProseMirrorEditor } from '../editor'
import {
  AttachmentExtension,
  ExternalLinkExtension,
  FooterExtension,
  HorizontalRuleExtension,
  InternalLinkExtension,
  KeyMapExtension,
  MentionExtension,
  PlaceHolderExtension,
  StrikethroughExtension,
  TableExtension,
  ToolbarExtension,
  UnderlineExtension,
} from '../editor/extentions'
import { ID } from '@datorama/akita'
import { ShortUser, User } from '../entities/user/model'
import {
  AttachmentProvider,
  FooterProvider,
  InternalLinkProvider,
  MentionProvider,
} from '../editor/providers'
import { ModelUpdateCallback } from '../utils/types'
import { UsersChoicesContext } from '../auth/usersContext'
import moment from 'moment'
import { Button } from 'antd'

interface CommentProps {
  date: string
  comment: Comment
  currentUser: ShortUser
  onDelete: (id: ID) => void
  onUpdate: ModelUpdateCallback<Comment>
}

export function CommentListItem(props: CommentProps) {
  const { comment, currentUser, date, onDelete, onUpdate } = props
  const [isConfirmationPopoverOpen, setIsConfirmationPopoverOpen] =
    useState<boolean>(false)
  const [value, setValue] = useState(() => comment.json_text)
  const [isEditable, setIsEditable] = useState(false)

  const extensions: Extension[] = [
    new PlaceHolderExtension('Write a comment...'),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider(isEditable)),
    new AttachmentExtension(new AttachmentProvider(comment.target.id)),
    new ToolbarExtension(),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new FooterExtension(new FooterProvider(comment.target.id), (value) => {
      setIsEditable(false)
      onUpdate({ id: comment.id, json_text: value })
    }),
    new KeyMapExtension(),
  ]

  const onChangeEditor = (transaction: any, view: any) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
    }
  }
  const getUsers = useContext(UsersChoicesContext)!
  const commentUser = getUsers.find((x: User) => x.id === comment.created_by)!

  // refactor when version of storybook will be upgraded to use .storybook/preview.js
  let isAuthor = false

  if (commentUser) {
    isAuthor = currentUser.id === commentUser.id
  }

  const closeConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(false)
  }

  const openConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(true)
  }

  const handleDeleteComment = () => {
    onDelete(comment.id)
    closeConfirmationPopover()
  }

  const handleEditMode = () => {
    setIsEditable(!isEditable)
  }

  const classes = classNames('task-panel-comment', {
    'task-panel-comment_has-popover': isConfirmationPopoverOpen,
    'not-editable': !isEditable,
  })

  return (
    <div className={classes}>
      <UserAvatar
        size={28}
        user={commentUser ? commentUser : undefined}
        shape="square"
        className="task-panel-comment__avatar"
      />
      <div className="task-panel-comment__content">
        <div className="task-panel-comment__header">
          <div className="task-panel-comment__info">
            <div className="task-panel-comment__name">
              {commentUser?.full_name}
            </div>
            <div style={{ display: 'block' }}>
              <ActivitiesDate date={date} isCommentDate />
              {comment.edited && (
                <span className="task-panel-comment__date">(edited)</span>
              )}
            </div>
          </div>
          <div className="task-panel-comment__action-buttons">
            {isAuthor &&
              moment(comment.created_at) > moment().subtract(10, 'minutes') && (
                <button
                  type="button"
                  onClick={handleEditMode}
                  className="task-panel-comment__action-button"
                >
                  <Icon iconSize={13} color="#4a5566" icon={IconNames.EDIT} />
                </button>
              )}
            {isAuthor && (
              <button
                type="button"
                className="task-panel-comment__action-button"
              >
                <TaskCommentDeleteConfirmationPopover
                  isOpen={isConfirmationPopoverOpen}
                  closeHandler={closeConfirmationPopover}
                  openHandler={openConfirmationPopover}
                  submitHandler={handleDeleteComment}
                />
              </button>
            )}
          </div>
        </div>
        <ProseMirrorEditor
          hasFooter={true}
          value={value}
          onChange={onChangeEditor}
          editable={isEditable}
          extensions={extensions}
        />
      </div>
    </div>
  )
}

interface TaskCommentDeleteConfirmationPopover {
  isOpen: boolean
  openHandler: () => void
  closeHandler: () => void
  submitHandler: () => void
}

export function TaskCommentDeleteConfirmationPopover(
  props: TaskCommentDeleteConfirmationPopover
) {
  const content = (
    <div className="task-comment-delete-confirmation-popover">
      <h3>Are you sure?</h3>
      <div className="task-comment-delete-confirmation-popover__buttons">
        <Button
          className={`task-comment-delete-confirmation-popover__button task-comment-delete-confirmation-popover__button_yes ${Classes.POPOVER_DISMISS}`}
          onClick={props.submitHandler}
        >
          Yes
        </Button>
        <Button
          className={`task-comment-delete-confirmation-popover__button task-comment-delete-confirmation-popover__button_no ${Classes.POPOVER_DISMISS}`}
          onClick={props.closeHandler}
        >
          No
        </Button>
      </div>
    </div>
  )
  return (
    <Popover
      popoverClassName={`comment-list-item-popover ${Classes.POPOVER_CONTENT_SIZING}`}
      enforceFocus={false}
      onClosed={() => props.closeHandler()}
      content={content}
      captureDismiss={true}
      usePortal={false}
      position="left"
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
          escapeWithReference: true,
        },
        hide: {
          enabled: true,
        },
      }}
      target={
        <Icon
          onClick={props.openHandler}
          iconSize={13}
          color="#4a5566"
          icon={IconNames.TRASH}
        />
      }
    />
  )
}
