import '../../styles/user-profile.scss'

import React from 'react'
import classNames from 'classnames'
import {
  convertUserStatusToFlags,
  getUserStatus,
  User,
  UserRole,
  UserStatus,
} from '../../../entities/user/model'
import { UserProfileRoleSelect } from '../../UserProfileRoleSelect'
import { UserProfileStatusSelect } from '../../UserProfileStatusSelect'
import { UserProfileTitle } from './UserProfileTitle'
import { ModelUpdateCallback } from '../../../utils/types'
import { extractDaysTillNow } from '../../../utils/common'

interface UserProfileHeadingProps {
  user: User
  editable: boolean
  onUserUpdate: ModelUpdateCallback<User>
}

export function UserProfileHeading(props: UserProfileHeadingProps) {
  const headingClassNames = classNames(
    'user-profile__heading',
    'user-profile__heading_active',
    {
      'user-profile__heading_inactive': !props.user.is_active,
      'user-profile__heading_invited': props.user.is_invited,
    }
  )
  const userStatus = getUserStatus(props.user)

  return (
    <div className={headingClassNames}>
      <UserProfileTitle label={'User profile'} />
      <UserProfileRoleSelect
        role={props.user.user_role}
        editable={props.editable}
        onChange={(user_role: UserRole) =>
          props.onUserUpdate({ id: props.user.id, user_role })
        }
      />
      {props.editable && userStatus !== UserStatus.INVITED ? (
        <UserProfileStatusSelect
          status={userStatus}
          editable={props.editable}
          onChange={(status) => {
            props.onUserUpdate({
              id: props.user.id,
              ...convertUserStatusToFlags(status),
            })
          }}
        />
      ) : (
        <UserProfileStatus user={props.user} />
      )}
    </div>
  )
}

interface UserProfileStatusProps {
  user: User
}

export function UserProfileStatus(props: UserProfileStatusProps) {
  const userStatus = getUserStatus(props.user)

  let statusText

  switch (userStatus) {
    case UserStatus.ACTIVE:
      statusText = 'Active'
      break
    case UserStatus.INACTIVE:
      statusText = 'Inactive'
      break
    case UserStatus.INVITED:
      statusText = `Invited (${extractDaysTillNow(props.user.invited_at!)} ago)`
      break
  }

  return (
    <div className="user-profile__heading-item user-profile__heading-item_small user-profile__heading-item_status">
      {statusText}
    </div>
  )
}
