import React, { useCallback, useMemo } from 'react'
import { ActivityChoice } from '../entities/choices/activity'
import {
  IItemRendererProps,
  ItemListRenderer,
  Select,
} from '@blueprintjs/select'
import { Menu, MenuItem } from '@blueprintjs/core'
import { useSelect } from '../utils/select'
import { AddTimeManuallyModal } from '../components/modals/AddTimeManuallyModal'
import { AddTimeManuallyObject, TrackableType } from './types'
import { User } from '../entities/user/model'
import { Divider } from 'antd'
import './styles/activity-select.scss'
import { IFormErrors } from '../project-page/state/store'

interface ActivitySelectProps {
  activities: ActivityChoice[]
  activityTargetType: TrackableType
  onSelect: (value: string) => void
  children: React.ReactNode
  onOpened?: () => void
  onClosed?: () => void
  onAddTimeManually?: (data: AddTimeManuallyObject) => void
  currentUser?: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

const TypedSelect = Select.ofType<ActivityChoice>()

export function ActivitySelect(props: ActivitySelectProps) {
  const {
    onSelect,
    onAddTimeManually,
    onCloseAddTimeModal,
    onOpenAddTimeModal,
  } = props
  const [selectRef, showSelect, showSelectHandler] = useSelect<ActivityChoice>()

  const manualActivities = useMemo(() => {
    const result = [...props.activities]
    if (props.activityTargetType === 'task') {
      const last = result.pop() as ActivityChoice
      const development = {
        value: 'development',
        label: 'Development',
        task_type: ['task'],
      }
      result.push(development, last)
    }
    return result
  }, [props.activities, props.activityTargetType])

  const onItemSelect = useCallback(
    (choice: ActivityChoice) => {
      onSelect(choice.value)
    },
    [onSelect]
  )

  const openAddTimeModalHandler = useCallback(() => {
    if (!!onOpenAddTimeModal) onOpenAddTimeModal()
  }, [onOpenAddTimeModal])

  const closeAddTimeModalHandler = useCallback(() => {
    if (!!onCloseAddTimeModal) onCloseAddTimeModal()
  }, [onCloseAddTimeModal])

  const onTimeAddHandler = useCallback(
    (data: AddTimeManuallyObject) => {
      !!onAddTimeManually && onAddTimeManually(data)
    },
    [onAddTimeManually]
  )

  const renderMenu: ItemListRenderer<ActivityChoice> = ({
    items,
    itemsParentRef,
    renderItem,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null)
    return (
      <Menu ulRef={itemsParentRef}>
        {renderedItems}

        {!!onAddTimeManually && (
          <>
            <Divider className="activity-select-divider" />
            <MenuItem
              key="add-time-manually"
              onClick={openAddTimeModalHandler}
              text="Add Time Manually"
            />
          </>
        )}
      </Menu>
    )
  }

  if (!showSelect)
    return <span onClick={showSelectHandler}>{props.children}</span>

  return (
    <>
      <TypedSelect
        ref={selectRef}
        popoverProps={{
          minimal: true,
          onOpened: props.onOpened,
          onClosed: props.onClosed,
          popoverClassName: 'popover',
        }}
        itemListRenderer={renderMenu}
        items={props.activities}
        itemRenderer={renderActivity}
        itemPredicate={filterActivity}
        onItemSelect={onItemSelect}
        filterable={false}
      >
        {props.children}
      </TypedSelect>

      <AddTimeManuallyModal
        onClose={closeAddTimeModalHandler}
        onSave={onTimeAddHandler}
        isOpen={!!props.isAddTimeFormOpened || false}
        currentUser={props.currentUser}
        activities={manualActivities}
        addTimeFormErrors={props.addTimeFormErrors}
        isAddTimeFormOpened={props.isAddTimeFormOpened}
        onOpenAddTimeModal={props.onOpenAddTimeModal}
        onCloseAddTimeModal={props.onCloseAddTimeModal}
      />
    </>
  )
}

function renderActivity(
  activity: ActivityChoice,
  { modifiers, handleClick }: IItemRendererProps
) {
  if (!modifiers.matchesPredicate) {
    return null
  }

  return (
    <MenuItem
      key={activity.value}
      onClick={handleClick}
      text={activity.label}
    />
  )
}

function filterActivity(query: string, activity: ActivityChoice) {
  return activity.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
}
