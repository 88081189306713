import { getRoleLabel } from '../../../entities/choices/role'
import { ReportStatisticsTshirt } from '../../../entities/report-statistics/model'
import {
  safeParseFloat,
  ReadableTimeStringNoZero,
} from '../../../tracking/TimeConverters'

interface ReportStatisticsTshirtRow {
  assignee?: number
  assignee_name?: string
  role?: string
  sum_time?: string
  avg_time_u?: string
  avg_time_xs?: string
  avg_time_s?: string
  avg_time_m?: string
  avg_time_l?: string
  avg_time_xl?: string
  key?: string
}

export function ParseReportStatisticsTshirtData(
  report: ReportStatisticsTshirt
) {
  const rows: ReportStatisticsTshirtRow[] = []

  report.stats.forEach((origRow) => {
    rows.push({
      key: `assignee-${origRow.assignee}`,
      // ...row
      assignee: origRow.assignee,
      assignee_name: origRow.assignee_name,
      role: getRoleLabel(origRow.role),
      sum_time: ReadableTimeStringNoZero(
        safeParseFloat(origRow.sum_time),
        true
      ),
      avg_time_u: ReadableTimeStringNoZero(
        safeParseFloat(origRow.avg_time_u),
        true
      ),
      avg_time_xs: ReadableTimeStringNoZero(
        safeParseFloat(origRow.avg_time_xs),
        true
      ),
      avg_time_s: ReadableTimeStringNoZero(
        safeParseFloat(origRow.avg_time_s),
        true
      ),
      avg_time_m: ReadableTimeStringNoZero(
        safeParseFloat(origRow.avg_time_m),
        true
      ),
      avg_time_l: ReadableTimeStringNoZero(
        safeParseFloat(origRow.avg_time_l),
        true
      ),
      avg_time_xl: ReadableTimeStringNoZero(
        safeParseFloat(origRow.avg_time_xl),
        true
      ),
    })
  })

  return rows
}
