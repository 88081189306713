import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita'
import { Workspace } from './model'

export interface WorkspaceState
  extends EntityState<Workspace, number>,
    ActiveState {
  loaded: boolean
}

@StoreConfig({ name: 'entity-workspaces' })
export class WorkspaceStore extends EntityStore<WorkspaceState> {}

export const workspaceStore = new WorkspaceStore()
