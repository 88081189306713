import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ID } from '@datorama/akita'
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { sortBy, isEqual } from 'lodash'
import { taskService } from '../../../entities/task/service'
import { subtaskService } from '../../../entities/subtask/service'
import { activityLogService } from '../../../entities/activities/service'
import { useQuery } from '../../../utils/queryhook'
import { Task } from '../../../entities/task/model'
import {
  Subtask,
  SubtaskEstimate,
  SubtaskStatus,
  SubtaskType,
} from '../../../entities/subtask/model'
import {
  ModelUpdateCallback,
  ModelUpdate,
  ModelCreateSubtaskCallback,
  ModelCreateCallback,
} from '../../../utils/types'
import { TaskBreadcrumbs } from '../TaskBreadcrumbs'
import { Project, ProjectStatus } from '../../../entities/project/model'
import { Sprint } from '../../../entities/sprint/model'
import { Epic } from '../../../entities/epic/model'
import { taskPanelQuery } from './state/query'
import { taskPanelService } from './state/service'
import { projectQuery } from '../../../entities/project/query'
import { projectPageQuery } from '../../state/query'
import { TaskPanelBar } from './TaskPanelBar'
import { TaskPanelEditor } from './TaskPanelEditor'
import { Subtasks } from './Subtasks'
import { ShortUser, User } from '../../../entities/user/model'
import { trackingService } from '../../../tracking/state/service'
import {
  AddTimeManuallyCallback,
  StartTrackingCallback,
} from '../../../tracking/types'
import { LinkedTasksContainer } from './LinkedTasksContainer'
import { generateTaskDetailUrl } from '../../../routes'
import { taskQuery } from '../../../entities/task/query'
import '../styles/task-panel.scss'
import '../styles/task-panel-drop-zone.scss'
import { CommentsFollowersForm } from './comments-followers-form/CommentsFollowersForm'
import { dragObjectContext } from '../../ProjectPage'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'
import { Spinner } from '../../../components/Spinner'
import { SmartSuiteLink } from '../../../components/SmartSuiteLink'
import {
  DragObject,
  subtasksDropZoneId,
  TASKS_IN_OUT_DROP_TYPE,
} from '../../drag-n-drop'
import { Comment } from '../../../entities/comment/model'
import { authQuery } from '../../../auth/state/query'
import { commentsService } from '../../../entities/comment/service'
import { historyQuery } from '../../../history/state/query'
import {
  HistoryActivityItemType,
  HistoryItem,
} from '../../../history/state/model'
import { TaskHistorySection } from './TaskHistorySection'
import { trackingQuery } from '../../../tracking/state/query'
import { TrackingState } from '../../../tracking/state/store'
import { timeEntryService } from '../../../entities/time-entry/service'
import { filterLocalStorageService } from '../../state/filterLocalStorage'
import { formatDate } from '../../../utils/date'
import { IFormErrors } from '../../state/store'
import { TaskNameEditor } from './TaskNameEditor'
import { TaskType } from '../../../entities/task-type/model'
import { SORT_STEP } from '../../../utils/lists'
import { DescriptionTemplatesMenu } from './DescriptionTemplatesMenu'
import { isDocEmpty } from '../../../editor'

export const activityLogContext = createContext<Subtask[]>([])

export const activityLogTaskContext = createContext<Task[]>([])

export function TaskPanelContainer() {
  const { taskCode, workspaceId } = useParams()
  const history = useHistory()
  const task = useQuery(taskQuery.selectByCode(taskCode!))
  const tasksLoaded = useQuery(taskQuery.loaded$)
  const tasks = useQuery(projectPageQuery.tasks$)
  const taskId = task?.id
  const loading = useQuery(taskPanelQuery.loading$)

  // guarantees that you won't see previous task's subtasks, activities, etc.
  useEffect(() => {
    if (taskId) {
      taskPanelService.setActive(taskId)
    }
  }, [taskId, tasks])

  useEffect(() => {
    if (taskId && tasksLoaded) {
      taskPanelService.loadTaskDetails(taskCode!)
    }

    return function cleanup() {
      // removing heavy fields
      taskPanelService.unloadActiveTaskDetails()
    }
  }, [taskCode, taskId, tasksLoaded]) // taskId guarantees that store is ready

  useEffect(() => {
    subtaskService.loadSubtasks({ task_code: taskCode! })
    taskService.loadAllInfoTaskByCode(taskCode!)
    activityLogService.loadActivities({ activities_by_task_code: taskCode! })
    commentsService.loadComments({ task_code: taskCode! })
    timeEntryService.loadTimeEntries({ task__code: taskCode! })
    filterLocalStorageService.subscribeOnTaskPanelFilters()
  }, [taskCode])

  const linkedTasks = useQuery(taskPanelQuery.linkedtasks$)
  const activeSubtasks = sortBy(
    useQuery(taskPanelQuery.subtasks$),
    'task_order'
  )
  const subtasks = useQuery(taskPanelQuery.allSubtasks$)
  const sprints = useQuery(taskPanelQuery.sprints$)
  const filteredSprints = useQuery(projectPageQuery.filteredSprints$)
  const filteredEpics = useQuery(projectPageQuery.filteredEpics$)
  const linkedTasksEpics = useQuery(taskPanelQuery.linkedTasksEpics$)
  const projects = useQuery(projectQuery.adminUserProjects$)
  const epicsPaneCollapsed = useQuery(projectPageQuery.epicsPaneCollapsed$)
  const sprintsPaneCollapsed = useQuery(projectPageQuery.sprintsPaneCollapsed$)
  const users = useQuery(projectPageQuery.projectUsers$)
  const taskTypes = useQuery(taskPanelQuery.taskTypes$)
  const selectedSubtaskFilter = useQuery(taskPanelQuery.subtasksFilter$)
  const selectedActivityFilter = useQuery(taskPanelQuery.activityFilter$)
  const historyItems = useQuery(historyQuery.taskHistory$)
  const currentUser = useQuery(authQuery.currentUser$)
  const trackingState = useQuery(trackingQuery.state$)
  const addTimeFormErrors = useQuery(trackingQuery.fromErrors$)
  const isFormOpened = useQuery(trackingQuery.isFormOpened$)

  const onProjectChange = useCallback(
    (taskUpdate: ModelUpdate<Task>) => {
      taskService.updateTask(taskUpdate)
      // try to get updated project and code, otherwise use old values from panel
      return history.push(
        generateTaskDetailUrl(
          workspaceId as ID,
          taskUpdate.project || task.project,
          taskUpdate.code || task.code
        )
      )
    },
    [workspaceId, task, history]
  )
  const dragObject = useContext(dragObjectContext)!

  const followedUsers =
    !!task && users.filter((x) => task.followers.includes(x.id))
  const taskAuthor = !!task && users.find((x) => x.id === task.created_by)

  if (!task) {
    return <></>
  }

  return (
    <TaskPanel
      loading={loading}
      task={task}
      linkedTasks={linkedTasks}
      tasks={tasks}
      currentUser={currentUser}
      subtasks={activeSubtasks}
      withDeletedSubtasks={subtasks}
      sprints={sprints}
      taskTypes={taskTypes}
      filteredSprints={filteredSprints}
      filteredEpics={filteredEpics}
      linkedTasksEpics={linkedTasksEpics}
      historyItems={historyItems}
      projects={projects}
      users={users}
      followedUsers={followedUsers}
      taskAuthor={taskAuthor}
      dragObject={dragObject}
      isEpicsOrSprintsCollapsed={epicsPaneCollapsed || sprintsPaneCollapsed}
      onStartTracking={trackingService.startTracking}
      onStopTracking={trackingService.stopTracking}
      onAddTimeManually={trackingService.addTimeManually}
      onTaskUpdate={taskService.updateTask}
      onRemoveTask={taskPanelService.removeLinkedTask}
      onAddLinkedTask={taskPanelService.addLinkedTask}
      loadTasks={taskService.loadTasks}
      trackingState={trackingState}
      onProjectChange={onProjectChange}
      onSubtaskUpdate={subtaskService.updateSubtask}
      onSubtaskDelete={subtaskService.destroy}
      onSubtaskAdd={taskPanelService.subtaskCreate}
      onSubtaskAddEstimate={subtaskService.addSubtaskEstimate}
      onFilterSubtasksByType={taskPanelService.updateSubtasksFilterTypes}
      onFilterSubtasksByStatus={taskPanelService.updateSubtasksFilterStatuses}
      selectedSubtaskFilter={selectedSubtaskFilter}
      onFilterTaskActivities={taskPanelService.updateActivityFilter}
      selectedActivityFilter={selectedActivityFilter}
      onCommentCreate={taskPanelService.commentCreate}
      onCommentDelete={commentsService.destroy}
      onCommentUpdate={commentsService.updateComment}
      onWorkLogDelete={timeEntryService.destroy}
      addTimeFormErrors={addTimeFormErrors}
      isAddTimeFormOpened={isFormOpened}
      onOpenAddTimeModal={trackingService.openAddTimeForm}
      onCloseAddTimeModal={trackingService.closeAddTimeForm}
    />
  )
}

export interface TaskPanelProps {
  loading: boolean
  task: Task
  users: ShortUser[]
  currentUser: User
  tasks: Task[]
  linkedTasks: Task[]
  followedUsers: (User | ShortUser)[]
  taskAuthor: User | ShortUser | undefined
  historyItems: HistoryItem[]
  projects: Project[]
  sprints: Sprint[]
  filteredSprints: Sprint[]
  taskTypes: TaskType[]
  filteredEpics: Epic[]
  linkedTasksEpics: Epic[]
  subtasks: Subtask[]
  withDeletedSubtasks: Subtask[]
  dragObject?: DragObject
  isEpicsOrSprintsCollapsed: boolean
  onTaskUpdate: ModelUpdateCallback<Task>
  onSubtaskUpdate: ModelUpdateCallback<Subtask>
  onSubtaskDelete: (id: ID) => void
  onProjectChange: ModelUpdateCallback<Task>
  onSubtaskAdd: ModelCreateSubtaskCallback<Subtask>
  onCommentCreate: ModelCreateCallback<Comment>
  onCommentDelete: (id: ID) => void
  onWorkLogDelete: (id: ID) => void
  onCommentUpdate: ModelUpdateCallback<Comment>
  onSubtaskAddEstimate: (
    subtaskID: ID,
    estimate: Partial<SubtaskEstimate>
  ) => void
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  onRemoveTask: (idToRemove: ID) => void
  onAddLinkedTask: (idToAdd: ID) => void
  loadTasks: () => void
  onFilterSubtasksByType: (value: SubtaskType) => void
  onFilterSubtasksByStatus: (value: SubtaskStatus) => void
  selectedSubtaskFilter?: { types: SubtaskType[]; statuses: SubtaskStatus[] }
  onFilterTaskActivities: (value: HistoryActivityItemType) => void
  selectedActivityFilter?: HistoryActivityItemType[]
  trackingState?: TrackingState
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

function _TaskPanel(props: TaskPanelProps) {
  const {
    loading,
    task,
    tasks,
    taskTypes,
    filteredEpics,
    filteredSprints,
    linkedTasksEpics,
    users,
    followedUsers,
    taskAuthor,
    currentUser,
    sprints,
    projects,
    subtasks,
    withDeletedSubtasks,
    historyItems,
    dragObject,
    onSubtaskAdd,
    onCommentCreate,
    onCommentDelete,
    onCommentUpdate,
    onWorkLogDelete,
    onRemoveTask,
    onAddLinkedTask,
    loadTasks,
    linkedTasks,
    isEpicsOrSprintsCollapsed,
    onTaskUpdate,
    onSubtaskUpdate,
    onSubtaskDelete,
    onSubtaskAddEstimate,
    onProjectChange,
    onFilterSubtasksByType,
    onFilterSubtasksByStatus,
    onStartTracking,
    onStopTracking,
    onAddTimeManually,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props

  const [scrollTop, setScrollTop] = useState()

  const updateTask = useCallback(
    (update: Partial<Task>) => {
      onTaskUpdate({ id: task.id, ...update })
    },
    [onTaskUpdate, task.id]
  )

  useEffect(() => {
    setTimeout(() => {
      setScrollTop(0)
      setScrollTop(undefined)
    })
  }, [task.id])

  const sprintOrder = (sprintID: ID) => {
    const sprintTasks = sortBy(
      tasks.filter((item) => item.sprint === sprintID),
      'sprint_order'
    )
    return sprintTasks.length
      ? sprintTasks.slice(-1)[0].sprint_order + SORT_STEP
      : 0
  }

  const epicOrder = (epicID: ID) => {
    const epicTasks = sortBy(
      tasks.filter((item) => item.epic === epicID),
      'epic_order'
    )
    return epicTasks.length ? epicTasks.slice(-1)[0].epic_order + SORT_STEP : 0
  }

  const dropzoneEnabled = dragObject?.type === 'task' && !task.is_readonly

  return (
    <div className="task-panel-wrap">
      <div className="task-panel">
        <TaskPanelBar
          sprints={sprints}
          isEpicsOrSprintsCollapsed={isEpicsOrSprintsCollapsed}
          updateTask={updateTask}
          users={users}
          subtasks={subtasks}
          task={task}
          onStartTracking={onStartTracking}
          onStopTracking={onStopTracking}
          onAddTimeManually={onAddTimeManually}
          currentUser={currentUser}
          addTimeFormErrors={addTimeFormErrors}
          isAddTimeFormOpened={isAddTimeFormOpened}
          onOpenAddTimeModal={onOpenAddTimeModal}
          onCloseAddTimeModal={onCloseAddTimeModal}
        />
        <TaskBreadcrumbs
          isEpicsOrSprintsCollapsed={isEpicsOrSprintsCollapsed}
          filteredEpics={filteredEpics}
          task={task}
          sprints={sprints}
          filteredSprints={filteredSprints}
          projects={projects.filter(
            (project) => project.status === ProjectStatus.ACTIVE
          )}
          onSprintSelect={(id: ID) =>
            onTaskUpdate({
              id: task.id,
              sprint: id,
              sprint_order: sprintOrder(id),
            })
          }
          onProjectSelect={(id: ID) =>
            onProjectChange({ id: task.id, project: id })
          }
          onEpicSelect={(id: ID) =>
            onTaskUpdate({ id: task.id, epic: id, epic_order: epicOrder(id) })
          }
        />
        {task.is_blocked && (
          <div className="task-panel__created">
            <span className="task-panel__created-title">Created:</span>{' '}
            <span className="task-panel__created-value">
              {formatDate(task.created_at)}
            </span>
          </div>
        )}
        <div className="task-panel__title-wrap">
          <TaskNameEditor
            className="task-name-editor"
            key={task.id}
            task={task}
            onChange={(taskId, changes) =>
              onTaskUpdate({ id: taskId as ID, ...changes })
            }
          />
        </div>
        {dropzoneEnabled && <TaskPanelDropZone task={task} />}
        <CustomScrollbar scrollTop={scrollTop}>
          <div className="task-panel__content task-panel-content">
            {loading ? (
              <Spinner />
            ) : (
              <TaskPanelEditor
                key={task.id}
                task={task}
                onChange={(taskId, newDescription) =>
                  onTaskUpdate({
                    id: taskId,
                    json_description: newDescription,
                  })
                }
              />
            )}
            <div className="task-panel-content__section task-panel__title-wrap">
              <DescriptionTemplatesMenu
                disabled={
                  task.is_readonly || !isDocEmpty(task.json_description)
                }
                onSelectTemplate={(descriptionTemplate) =>
                  onTaskUpdate({
                    id: task.id,
                    json_description: descriptionTemplate,
                  })
                }
              />
            </div>
            {task.ss_link && (
              <div className="task-panel-content__section task-panel__title-wrap">
                <SmartSuiteLink ss_link={task.ss_link} />
              </div>
            )}
            {!!linkedTasks.length && (
              <div className="task-panel-content__section">
                <LinkedTasksContainer
                  task={task}
                  users={users}
                  tasks={tasks}
                  taskTypes={taskTypes}
                  linkedTasks={linkedTasks}
                  epics={linkedTasksEpics}
                  sprints={sprints}
                  onRemoveTask={onRemoveTask}
                  onAddLinkedTask={onAddLinkedTask}
                  loadTasks={loadTasks}
                  addTimeFormErrors={addTimeFormErrors}
                  isAddTimeFormOpened={isAddTimeFormOpened}
                  onOpenAddTimeModal={onOpenAddTimeModal}
                  onCloseAddTimeModal={onCloseAddTimeModal}
                />
              </div>
            )}
            <div className="task-panel-content__section">
              <Subtasks
                users={users}
                task={task}
                subtasks={subtasks}
                onSubtaskAdd={onSubtaskAdd}
                onSubtaskUpdate={onSubtaskUpdate}
                onSubtaskDelete={onSubtaskDelete}
                onSubtaskAddEstimate={onSubtaskAddEstimate}
                onFilterSubtasksByType={onFilterSubtasksByType}
                onFilterSubtasksByStatus={onFilterSubtasksByStatus}
                onStartTracking={onStartTracking}
                onStopTracking={onStopTracking}
                dragObject={dragObject}
                selectedSubtaskFilter={props.selectedSubtaskFilter}
                trackingState={props.trackingState}
                addTimeFormErrors={addTimeFormErrors}
                isAddTimeFormOpened={isAddTimeFormOpened}
                onOpenAddTimeModal={onOpenAddTimeModal}
                onCloseAddTimeModal={onCloseAddTimeModal}
              />
            </div>
            <activityLogContext.Provider value={withDeletedSubtasks}>
              <activityLogTaskContext.Provider
                value={[...linkedTasks, ...tasks]}
              >
                <div className="task-panel-content__section task-panel-content__section_activities">
                  <TaskHistorySection
                    items={historyItems}
                    currentUser={currentUser}
                    onCommentDelete={onCommentDelete}
                    onWorkLogDelete={onWorkLogDelete}
                    onCommentUpdate={onCommentUpdate}
                    onFilterTaskActivities={props.onFilterTaskActivities}
                    selectedActivityFilter={props.selectedActivityFilter}
                  />
                </div>
              </activityLogTaskContext.Provider>
            </activityLogContext.Provider>
          </div>
        </CustomScrollbar>
        <CommentsFollowersForm
          users={users}
          followedUsers={followedUsers}
          taskAuthor={taskAuthor}
          currentFollowers={task.followers}
          onAddFollowers={taskPanelService.addFollowers}
          task={task}
          onCommentCreate={onCommentCreate}
          currentUser={currentUser}
        />
      </div>
    </div>
  )
}

// using here lodash and not react-fast-compare because of error causing by
// adding external link in the description of a task
// https://timer.gearheart.io/1/95/task/TM3-34697
export const TaskPanel = React.memo(_TaskPanel, isEqual)

function TaskPanelDropZone({ task }: { task: Task }) {
  return (
    <div className="task-panel-drop-zone">
      <div className="task-panel-drop-zone__inner">
        <div className="task-panel-drop-zone__group">
          <h4 className="task-panel-drop-zone__title">Drop here to link:</h4>
          <Droppable
            ignoreContainerClipping={true}
            droppableId={`link-to-${task.id}`}
            type={TASKS_IN_OUT_DROP_TYPE}
          >
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot
            ) => {
              return (
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                  className="task-dropzone-area task-panel-drop-zone__area"
                  style={{
                    backgroundColor: dropSnapshot.isDraggingOver
                      ? '#54a0ff'
                      : 'transparent',
                  }}
                >
                  {dropProvided.placeholder}
                  <span className="task-panel-drop-zone__hint">do it!</span>
                </div>
              )
            }}
          </Droppable>
        </div>

        <div className="task-panel-drop-zone__group">
          <h4 className="task-panel-drop-zone__title">
            Drop here to convert to subtask:
          </h4>
          <Droppable
            ignoreContainerClipping={true}
            droppableId={subtasksDropZoneId(task)}
            type={TASKS_IN_OUT_DROP_TYPE}
          >
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot
            ) => (
              <div
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
                className="task-dropzone-area task-panel-drop-zone__area"
                style={{
                  backgroundColor: dropSnapshot.isDraggingOver
                    ? '#54a0ff'
                    : 'transparent',
                }}
              >
                {dropProvided.placeholder}
                <span className="task-panel-drop-zone__hint">do it!</span>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  )
}
