import '../../styles/users-list.scss'

import React from 'react'
import { ID } from '@datorama/akita'
import { Observable } from 'rxjs'
import classNames from 'classnames'
import { Project } from '../../../entities/project/model'
import { User, UserInvite, UserRole } from '../../../entities/user/model'
import UsersListItem from './UsersListItem'
import { UsersListHeader } from './UsersListHeader'
import { UsersListAddUser } from './UsersListAddUser'
import { Empty } from 'antd'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'

interface UsersListProps {
  currentUser: User
  projects: Project[]
  users: User[]
  selectedUserId: ID
  searchQuery: string
  onUserSearch: (query: string) => void
  showFiltersPanel: boolean
  setShowFiltersPanel: (show: boolean) => void
  onUserInvite: (invite: UserInvite) => Observable<User>
  onSuccessInviteUser: (user: User) => void
}

export function UsersList(props: UsersListProps) {
  const className = classNames(['users-list'], {
    'is-active': props.showFiltersPanel,
  })

  return (
    <div className={className}>
      <UsersListHeader
        placeholder="Search All Users"
        searchQuery={props.searchQuery}
        onSearch={props.onUserSearch}
        showFiltersPanel={props.showFiltersPanel}
        setShowFiltersPanel={props.setShowFiltersPanel}
      />
      <CustomScrollbar>
        <div className="users-list__items">
          {!!props.users.length ? (
            props.users.map((user) => (
              <UsersListItem
                key={user.id}
                user={user}
                isSelected={user.id === props.selectedUserId}
              />
            ))
          ) : (
            <Empty
              style={{ paddingTop: '20px' }}
              imageStyle={{ height: 60 }}
              description="No Results"
            />
          )}
        </div>
      </CustomScrollbar>
      {props.currentUser.user_role === UserRole.ADMIN ? (
        <UsersListAddUser
          projects={props.projects}
          onUserInvite={props.onUserInvite}
          onSuccessInviteUser={props.onSuccessInviteUser}
        />
      ) : null}
    </div>
  )
}
