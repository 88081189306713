import { ID } from '@datorama/akita'
import React, { useCallback, useMemo } from 'react'
import {
  Epic,
  EpicStatus,
  EpicStatusType,
  EPIC_STATUSES,
  getEpicStatusColorMapping,
} from '../../../entities/epic/model'
import { TaskGroupDropdownMenu } from './TaskGroupDropdownMenu'
import { TaskGroupDropdownTarget } from './TaskGroupDropdownTarget'
import { MoveItemCallback } from '../../drag-n-drop'

interface EpicDropdownContainerProps {
  epicID: ID
  epicStatus: EpicStatusType
  epicsLength: number
  isReadOnlyEpic: boolean
  showCurrent?: boolean
  targetClassName?: string
  hasReorderMenu?: boolean
  onStatusSelect: (status: string) => void
  onDelete: (id: ID) => void
  epics?: Epic[]
  onReorder?: MoveItemCallback
}

export function EpicDropdownContainer(props: EpicDropdownContainerProps) {
  const { onStatusSelect, onDelete, isReadOnlyEpic } = props

  const selectStatus = useCallback(
    (item) => {
      onStatusSelect(item.value)
    },
    [onStatusSelect]
  )

  const deleteEpic = useCallback(
    (item) => {
      onDelete(item.id)
    },
    [onDelete]
  )

  const [activateMenuItem, holdMenuItem, closeMenuItem] = useMemo(
    () =>
      [
        ['Active', EpicStatus.ACTIVE],
        ['On Hold', EpicStatus.ONHOLD],
        ['Close', EpicStatus.COMPLETE],
      ].map(([label, value]) => ({
        label,
        value,
        onSelectItem: selectStatus,
      })),
    [selectStatus]
  )

  const deleteMenuItem = useMemo(
    () => ({
      label: 'Delete',
      onSelectItem: deleteEpic,
    }),
    [deleteEpic]
  )

  const activeMenuItems = useMemo(
    () => [holdMenuItem, closeMenuItem],
    [holdMenuItem, closeMenuItem]
  )

  const draftOrClosedMenuItems = useMemo(
    () => [activateMenuItem, holdMenuItem],
    [activateMenuItem, holdMenuItem]
  )

  const noTasksActiveMenuItems = useMemo(
    () => [holdMenuItem, closeMenuItem, deleteMenuItem],
    [holdMenuItem, closeMenuItem, deleteMenuItem]
  )

  const noTasksDraftOrClosedMenuItems = useMemo(
    () => [activateMenuItem, holdMenuItem, deleteMenuItem],
    [activateMenuItem, holdMenuItem, deleteMenuItem]
  )

  const onHoldMenuItemsWithoutTasks = useMemo(
    () => [activateMenuItem, closeMenuItem, deleteMenuItem],
    [activateMenuItem, closeMenuItem, deleteMenuItem]
  )

  const onHoldMenuItemsWithTasks = useMemo(
    () => [activateMenuItem, closeMenuItem],
    [activateMenuItem, closeMenuItem]
  )

  const getMenuItems = (epicStatus: EpicStatusType) => {
    if (isReadOnlyEpic) return []

    switch (epicStatus) {
      case EpicStatus.DRAFT:
        if (!props.epicsLength) {
          return noTasksDraftOrClosedMenuItems
        }
        return draftOrClosedMenuItems

      case EpicStatus.ACTIVE:
        if (!props.epicsLength) {
          return noTasksActiveMenuItems
        }
        return activeMenuItems

      case EpicStatus.ONHOLD:
        if (!props.epicsLength) {
          return onHoldMenuItemsWithoutTasks
        }
        return onHoldMenuItemsWithTasks

      case EpicStatus.COMPLETE:
        if (!props.epicsLength) {
          return noTasksDraftOrClosedMenuItems
        }
        return draftOrClosedMenuItems

      case EpicStatus.CANCELLED:
        if (!props.epicsLength) {
          return noTasksDraftOrClosedMenuItems
        }
        return draftOrClosedMenuItems

      default:
        return activeMenuItems
    }
  }

  const dropdownTargetTitle =
    EPIC_STATUSES.find((status) => status.value === props.epicStatus)?.label ||
    ''

  return (
    <TaskGroupDropdownMenu
      key={props.epicID}
      objectType={'epic'}
      objectID={props.epicID}
      items={getMenuItems(props.epicStatus)}
      hasReorderMenu={props.hasReorderMenu}
      reorderMenuItems={props.epics}
      onReorder={props.onReorder}
      onSort={() => {}}
      onSortTasksByAssignee={() => {}}
    >
      <TaskGroupDropdownTarget
        titleColor={getEpicStatusColorMapping[props.epicStatus]}
        title={props.showCurrent ? dropdownTargetTitle : ''}
        className={props.targetClassName}
      />
    </TaskGroupDropdownMenu>
  )
}
