import {boundMethod} from "autobind-decorator";
import {timeEntryStore, TimeEntryStore} from "./store";
import {timeEntryQuery, TimeEntryQuery} from "./query";
import {timeEntryDataService, TimeEntryDataService} from "./data-service";
import {ID} from "@datorama/akita";
import {TimeEntry} from "./model";
import {TrackingState, trackingStore, TrackingStore} from "../../tracking/state/store";

export class TimeEntryService {
  constructor(
    private store: TimeEntryStore,
    private query: TimeEntryQuery,
    private dataService: TimeEntryDataService,
    private trackingStore: TrackingStore,
  ) {}

  @boundMethod
  loadTimeEntries(query?: object) {
    this.dataService.list(query).subscribe((timeEntries) => {
      this.store.add(timeEntries)
    })
  }

  @boundMethod
  destroy(id: ID) {
    const entry: TimeEntry = { ...this.query.getEntity(id) }

    // is this optimistic remove?
    this.store.remove(id)

    this.dataService.destroy(id).subscribe({
      next: (state) => {
        this.trackingStore.update(state as TrackingState)
      },
      error: () => {
        this.store.add(entry)
      }
    })
  }
}

export const timeEntryService = new TimeEntryService(
  timeEntryStore,
  timeEntryQuery,
  timeEntryDataService,
  trackingStore
)
