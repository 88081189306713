import { Button, Icon, Menu, Tooltip } from '@blueprintjs/core'
import {
  IItemRendererProps,
  ItemListRenderer,
  Select,
} from '@blueprintjs/select'
import { ID } from '@datorama/akita'
import { Empty } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { CurrentUserContext } from '../../../auth/currentUserContext'
import '../../../components/controls/styles/popover.scss'
import { UserAvatar } from '../../../components/UserAvatar'
import {
  booleanKeySortFn,
  booleanKeyReverseSortFn,
  nameSortFn,
  ShortUser,
  UNASSIGNED,
  User,
} from '../../../entities/user/model'
import '../../../assets/scss/select.scss'
import './styles/user-select.scss'
import { useSelect } from '../../../utils/select'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'

const TypedUserSelect = Select.ofType<User | ShortUser>()

export interface UserSelectProps {
  userId: ID | null
  users: User[] | ShortUser[]
  disabled?: boolean | false
  onlyAvatar?: boolean | false
  isEpicsOrSprintsCollapsed?: boolean
  inProjectPage?: boolean
  onSelect: (userId: ID) => void
  filterPredicate?: (query: string, user: User | ShortUser) => boolean
}

export function UserSelect(props: UserSelectProps) {
  const {
    userId,
    onlyAvatar,
    disabled,
    onSelect,
    filterPredicate = () => true,
  } = props
  const currentUser = useContext(CurrentUserContext)!

  const [selectRef, showSelect, showSelectHandler] = useSelect<
    User | ShortUser
  >()

  const users = useMemo(() => {
    const _users = [...props.users].sort(
      (a, b) =>
        booleanKeySortFn(a, b, 'is_active') ||
        booleanKeyReverseSortFn(a, b, 'is_external') ||
        nameSortFn(a, b)
    )
    if (_users.length) {
      _users.unshift(UNASSIGNED)
    }

    const currentUserIndex =
      currentUser && _users.findIndex((user) => currentUser.id === user.id)

    if (currentUserIndex !== -1) {
      _users.unshift(_users.splice(currentUserIndex, 1)[0])
    }
    return _users
  }, [props.users, currentUser])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const changeWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    changeWindowWidth()
    window.addEventListener('resize', changeWindowWidth)
    return () => window.removeEventListener('resize', changeWindowWidth)
  }, [])

  const user = useMemo(() => {
    return users.find((user) => user.id === userId)
  }, [userId, users])

  const onItemSelect = useCallback(
    (user: User | ShortUser) => {
      onSelect(user.id)
    },
    [onSelect]
  )

  const filterUser = useCallback(
    (query: string, user: User | ShortUser) => {
      const matchString = (user.full_name || user.email).toLowerCase()
      if (
        user.id === (currentUser && currentUser.id) &&
        'me'.includes(query.trim().toLowerCase())
      ) {
        return true
      }

      return matchString.indexOf(query.toLowerCase()) >= 0
    },
    [currentUser]
  )

  const selectClasses = classNames('user-select', 'select', {
    'user-select_disabled': disabled,
    'user-select_full': props.inProjectPage && props.isEpicsOrSprintsCollapsed,
  })

  const buttonClasses = classNames('user-select__button', {
      'user-select__button_disabled': disabled
  })

  const _filterPredicate = useCallback(
    (query: string, user: User | ShortUser) =>
      filterUser(query, user) && filterPredicate(query, user),
    [filterPredicate, filterUser]
  )

  const renderUser = (
    user: User | ShortUser,
    renderProps: IItemRendererProps
  ) => {
    const { modifiers, handleClick } = renderProps

    if (!modifiers.matchesPredicate) {
      return null
    }

    const isCurrentUser = currentUser && user.id === currentUser.id

    return (
      <li
        className={classNames('bp3-menu-item', {
          'bp3-active': user.id === userId,
          'bp3-multi': modifiers.active,
        })}
        onClick={handleClick}
        key={user.id}
      >
        <div className="user-select__list-item">
          <div className="user-select__list-avatar">
            <UserAvatar user={user} shape={'square'} size={22} />
          </div>
          <div
            className="user-select__list-full-name bp3-text-overflow-ellipsis"
            title={user.full_name || user.email}
          >
            {user.full_name || user.email}{' '}
            {isCurrentUser && <span className="me-label">me</span>}
          </div>
        </div>
      </li>
    )
  }

  const CurrentSelectItem = (
    <div className="user-select__content">
      {!!user ? (
        <>
          {!onlyAvatar && (
            <div className="user-select__full-name bp3-text-overflow-ellipsis">
              {' '}
              {user.full_name}
            </div>
          )}{' '}
          <div className="user-select__avatar">
            {windowWidth < 1500 &&
            !(props.inProjectPage && props.isEpicsOrSprintsCollapsed) ? (
              <Tooltip content={user.full_name} position={'bottom'}>
                <UserAvatar user={user} shape={'square'} size={22} />
              </Tooltip>
            ) : (
              <UserAvatar user={user} shape={'square'} size={22} />
            )}
          </div>
        </>
      ) : (
        <>
          {!onlyAvatar && (
            <div className="user-select__full-name">Unassigned</div>
          )}
          <div className="user-select__avatar user-select__avatar_unassigned">
            U
          </div>
        </>
      )}
    </div>
  )

  if (!showSelect) {
    return (
      <div className={selectClasses}>
        <Button onClick={showSelectHandler} className={buttonClasses}>
          {CurrentSelectItem}
        </Button>
      </div>
    )
  }

  const itemListRenderer: ItemListRenderer<User | ShortUser> = ({
    items,
    renderItem,
    itemsParentRef,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item !== null)

    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={400}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  return (
    <TypedUserSelect
      itemListRenderer={itemListRenderer}
      ref={selectRef}
      className={selectClasses}
      items={users}
      disabled={disabled}
      itemRenderer={renderUser}
      itemPredicate={_filterPredicate}
      onItemSelect={onItemSelect}
      resetOnClose={true}
      noResults={
        <Empty imageStyle={{ height: 60 }} description={'No Results'} />
      }
      popoverProps={{
        portalClassName: 'task-user-portal',
        minimal: true,
        position: 'bottom-right',
        popoverClassName: 'popover user-popover',
      }}
      inputProps={{
        // eslint-disable-next-line no-useless-concat
        placeholder: 'Enter User N' + '\u0430' + 'me...',
        leftIcon: null,
        name: 'searchTerm',
        autoComplete: 'off',
        rightElement: (
          <Icon iconSize={13} color="rgb(191, 191, 191)" icon="search" />
        ),
      }}
    >
      <Button className={buttonClasses}>{CurrentSelectItem}</Button>
    </TypedUserSelect>
  )
}
