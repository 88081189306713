import React, { useCallback, useEffect, useState } from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Popover, Input, Button } from 'antd'

import { Subtask, SubtaskEstimate } from '../../../entities/subtask/model'
import {
  parseDuration,
  ReadableTimeString,
} from '../../../tracking/TimeConverters'

import '../styles/subtasks-change-estimation.scss'
import { CommentOutlined } from '@ant-design/icons'
import { TooltipPlacement } from 'antd/es/tooltip'
import { isDocEmpty } from '../../../editor/core/utils'
import { SubtaskEstimationEditor } from './SubtaskEstimationEditor'
import { Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Doc } from '../../../editor/types'

interface SubtaskChangeEstimationProps {
  placement?: TooltipPlacement
  subtask: Subtask
  changeSubtaskEstimate: (estimate: Partial<SubtaskEstimate>) => void
}

interface SubtaskChangeEstimationControlProps
  extends SubtaskChangeEstimationProps {
  isOpen: boolean
  children: React.ReactNode
}

export function SubtaskChangeEstimationControl(
  props: SubtaskChangeEstimationControlProps
) {
  const { subtask, changeSubtaskEstimate, isOpen } = props
  const readableCurrentEstimate = ReadableTimeString(subtask.current_estimate)
  const initialDoc: Doc = {
    type: 'doc',
    content: [{ type: 'paragraph' }],
  }
  const [textAreaValue, setTextAreaValue] = useState(initialDoc)
  const [value, setInputValue] = useState(readableCurrentEstimate)
  const [canSend, setCanSend] = useState(false)
  const [isOpenState, setIsOpenState] = useState(isOpen)

  const changeInputValueHandler = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleVisibleChange = () => {
    setIsOpenState(!isOpenState)
  }

  const onClosePopoverHandler = () => {
    setIsOpenState(false)
  }

  useEffect(() => {
    setIsOpenState(isOpen)
  }, [isOpen])

  useEffect(() => {
    const estimate = parseDuration(value)
    const can_send =
      !!estimate &&
      !isDocEmpty(textAreaValue) &&
      estimate !== subtask.current_estimate

    setCanSend(can_send)
  }, [value, textAreaValue, subtask.current_estimate])

  const changeInitialEstimate = useCallback(() => {
    changeSubtaskEstimate({
      estimate: parseDuration(value),
      reason: textAreaValue,
    })
    onClosePopoverHandler()
  }, [changeSubtaskEstimate, value, textAreaValue])

  const onChangeEditor = (transaction: Transaction, view: EditorView) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setTextAreaValue(view.state.doc.toJSON() as any)
    }
  }

  const popoverContent = (
    <div className="subtasks-change-estimation__content">
      <div className="subtasks-change-estimation__header">
        <h3 className="subtasks-change-estimation__header-title">
          Change Current estimate
        </h3>
        <Icon
          icon={IconNames.CROSS}
          onClick={onClosePopoverHandler}
          iconSize={16}
          className="subtasks-change-estimation__header-close"
        />
      </div>
      <div className="subtasks-change-estimation__body">
        <div className="subtasks-change-estimation__section">
          <label className="subtasks-change-estimation__title">
            New estimation (required)
          </label>
          <Input
            value={value}
            className="subtasks-change-estimation__field"
            onChange={changeInputValueHandler}
          />
        </div>
        <div className="subtasks-change-estimation__section ">
          <label className="subtasks-change-estimation__title">
            Reason (required)
          </label>
          <SubtaskEstimationEditor
            value={textAreaValue}
            subtask={subtask}
            onChangeEditor={onChangeEditor}
          />
        </div>
        <Button
          onClick={changeInitialEstimate}
          size="large"
          type="primary"
          disabled={!canSend}
        >
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Popover
      trigger="click"
      visible={isOpenState}
      destroyTooltipOnHide={true}
      content={popoverContent}
      placement={props.placement || 'left'}
      overlayClassName="subtasks-change-estimation"
      onVisibleChange={handleVisibleChange}
    >
      {props.children}
    </Popover>
  )
}

interface SubtaskChangeEstimationTrackerControlProps
  extends SubtaskChangeEstimationProps {
  required: boolean
  showIcon?: boolean
}

export function SubtaskChangeEstimationTrackerControl({
  placement = 'bottom',
  required,
  showIcon,
  subtask,
  changeSubtaskEstimate,
}: SubtaskChangeEstimationTrackerControlProps) {
  return (
    <SubtaskChangeEstimationControl
      subtask={subtask}
      changeSubtaskEstimate={changeSubtaskEstimate}
      placement={placement}
      isOpen={required}
    >
      {showIcon &&
        (required ? (
          <CommentOutlined style={{ color: 'red' }} />
        ) : (
          <CommentOutlined className="comment-outlined" />
        ))}
    </SubtaskChangeEstimationControl>
  )
}

interface SubtaskChangeEstimationWithCommentProps
  extends SubtaskChangeEstimationProps {
  exclamationMarkQuantity: number | undefined
}

export function SubtaskChangeEstimationWithComment(
  props: SubtaskChangeEstimationWithCommentProps
) {
  const readableCurrentEstimate = ReadableTimeString(
    props.subtask.current_estimate
  )
  const exclamationMarks = Array.from(
    '!'.repeat(props.exclamationMarkQuantity || 0)
  )

  return (
    <SubtaskChangeEstimationControl
      subtask={props.subtask}
      changeSubtaskEstimate={props.changeSubtaskEstimate}
      isOpen={false}
    >
      <Popover
        placement="top"
        trigger="hover"
        content="Current estimate for the subtask"
      >
        <span className="subtasks__estimation-end subtasks__estimation-end_popover">
          {readableCurrentEstimate}

          {exclamationMarks.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </span>
      </Popover>
    </SubtaskChangeEstimationControl>
  )
}
