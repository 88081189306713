export class BaseTaskTypeProvider {
  useTaskType = ({
    id,
  }: {
    id: string | number
  }): { id: number; name: string } | null => {
    return null
  }

  useSearch = ({
    query,
  }: {
    query: string
  }): { id: number; name: string }[] => {
    return []
  }
}
