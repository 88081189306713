import React from 'react'
import { SetTaskSizeAction } from '../../entities/activities/model'
import { HighlightedActivity } from './text-formatters/HighlightedActivity'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {User} from "../../entities/user/model";

interface SetTaskScoreActivityProps {
  action: SetTaskSizeAction
  date: string
  user: User | string
}

export function SetTaskScoreActivity(props: SetTaskScoreActivityProps) {
  const { action, user } = props
  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> estimated
      this task as{' '}
      <HighlightedActivity isUpperCase>
        {action.shirt_size}
      </HighlightedActivity>
      . <ActivitiesDate date={props.date} isActivityDate />
    </>
  )
}
