import { ID } from '@datorama/akita'
import { Doc } from '../../editor/types'

export enum SubtaskType {
  GENERAL = 'general',
  BUG = 'bug',
  BLOCKER = 'blocker',
}

export enum SubtaskStatus {
  NEW = 'new',
  DONE = 'done',
  DRAFT = 'draft',
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  ON_REVIEW = 'on_rewiew',
  QA = 'qa',
  ACCEPTED = 'accepted',
  CANT_REPRODUCE = 'cant_reproduce',
  TO_CLARIFY = 'to_clarify',
  NOT_A_BUG = 'not_a_bug',
}

export interface Subtask {
  id: ID
  task: ID
  name: string
  type: SubtaskType
  is_done: boolean
  time_logged: number
  initial_estimate: number
  current_estimate: number
  assignee: ID | null
  json_description: Doc | null
  task_order: number
  status: SubtaskStatus
  code: string | null
  deleted_at: string | null
}

export interface SubtaskEstimate {
  estimate: number
  reason: object | null
}

export interface SubtaskIntoTask {
  target_id: ID
  target_type: 'sprint' | 'epic' | 'backlog'
  order_index: number
}
