import { ID } from '@datorama/akita'
import { ShortUser, User } from '../user/model'

export type SprintStatusType = 'draft' | 'active' | 'closed'

export enum SprintStatus {
  DRAFT = 'draft',
  STARTED = 'active',
  COMPLETE = 'closed',
}

export const DefaultSprintStatuses: SprintStatus[] = [
  SprintStatus.DRAFT,
  SprintStatus.STARTED,
]

export enum SprintStatusColor {
  DRAFT = 'rgb(7, 71, 166)',
  STARTED = 'rgb(54, 179, 126)',
  COMPLETE = 'rgb(151, 160, 175)',
}

export interface ISprintStatus {
  value: SprintStatus
  label: string
  color: string
}

export const SPRINT_STATUSES: ISprintStatus[] = [
  {
    value: SprintStatus.DRAFT,
    label: 'Draft',
    color: SprintStatusColor.DRAFT,
  },
  {
    value: SprintStatus.STARTED,
    label: 'Active',
    color: SprintStatusColor.STARTED,
  },
  {
    value: SprintStatus.COMPLETE,
    label: 'Closed',
    color: SprintStatusColor.COMPLETE,
  },
]

type SprintStatusMapping = {
  [key in SprintStatusType]: string
}

export const getSprintStatusColorMapping: SprintStatusMapping = {
  draft: SprintStatusColor.DRAFT,
  active: SprintStatusColor.STARTED,
  closed: SprintStatusColor.COMPLETE,
}

export interface Sprint {
  id: ID
  project: ID
  name: string
  order: number
  created_by: ID
  updated_by: ID
  created_at: string
  updated_at: string
  start_date: string | null
  staging_date: string | null
  release_date: string | null
  time_logged: number
  status: SprintStatus
  created_by_user?: User | ShortUser
  updated_by_user?: User | ShortUser
  user_working_days: any
  ss_link?: string
  is_readonly: boolean
}

export function filterSprints(sprints: Sprint[], statuses: SprintStatus[]) {
  if (!statuses.length) return sprints
  return sprints.filter((sprint) => statuses.includes(sprint.status))
}

export const ALL_SPRINTS_ID = -1

export const ALL_SPRINTS = {
  id: ALL_SPRINTS_ID,
  name: 'Show All',
  // is_active: true,
} as Sprint

export const sortAlphabetically = (sprints: Sprint[]) => {
  return sprints.sort(function (a, b) {
    const first_value = a.name
    const second_value = b.name
    if (first_value.toLowerCase() < second_value.toLowerCase()) {
      return -1
    }
    if (first_value.toLowerCase() > second_value.toLowerCase()) {
      return 1
    }
    return 0
  })
}

export const isCurrentSprint = (sprint: Sprint): boolean => {
  const hasStarted = sprint.status === SprintStatus.STARTED
  const today = new Date()
  const startDate = sprint.start_date ? new Date(sprint.start_date) : null
  const releaseDate = sprint.release_date ? new Date(sprint.release_date) : null
  const stagingDate = sprint.staging_date ? new Date(sprint.staging_date) : null
  const endDate =
    !!releaseDate && !!stagingDate
      ? Math.min(releaseDate.getTime(), stagingDate.getTime())
      : releaseDate || stagingDate
  return (
    hasStarted &&
    !!startDate &&
    today >= startDate &&
    !!endDate &&
    today <= endDate
  )
}
