import LinkifyIt from 'linkify-it'
import normalizeUrl from 'ss-normalize-url'
import { InputRule } from 'prosemirror-inputrules'
import { Transaction, EditorState } from 'prosemirror-state'

const linkify = LinkifyIt()

interface Match {
  //  Default linkify fields
  schema: string
  index: number
  lastIndex: number
  raw: string
  text: string
  url: string

  // manually added field to match regex result
  length?: number
}

class LinkifyRegexMock {
  exec(str: string) {
    // Check that there is a space at the end of the line
    if (!(/(\s+)$/.test(str))) { return null }

    // We break the sentence into words and take the last one
    const chunks = str.trim().split(/(\s+)/)
    const lastChunk = chunks[chunks.length - 1]

    // Check that fragment is link
    if (!linkify.test(lastChunk)) { return null }

    // Create linkify object
    const links = linkify.match(lastChunk) as []
    const lastLink = links[links.length - 1] as Match
    lastLink.length = lastLink.lastIndex - lastLink.index + 1
    return [lastLink];
  }
}

const UrlInputHandler = (state: EditorState, match: Match[], start: number, end: number): Transaction | null => {
  // Check that the string has not yet been converted into a link
  if (state.doc.rangeHasMark(start, end, state.schema.marks.link)) { return null }

  // Add mark and update PM state
  const mark = state.schema.mark('link', { href: normalizeUrl(match[0].url) })
  return state.tr.addMark(start, end, mark).insertText('\u00A0')
}

export const UrlInputRule = new InputRule(
  (new LinkifyRegexMock() as any) as RegExp,
  UrlInputHandler as any
);
