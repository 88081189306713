import React, {useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'
import { ChangeStatusAction } from '../../entities/activities/model'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { HighlightTaskStatus } from './text-formatters/HighlightTaskStatus'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import {User} from "../../entities/user/model";
import {TaskStatus} from "../../entities/task/model";

interface ChangeStatusActivityProps {
  action: ChangeStatusAction
  date: string
  user: User | string
}

export function ChangeStatusActivity(props: ChangeStatusActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText>{' '}changed the status of the {action.target.type} to{' '}
      <HighlightTaskStatus
        value={action.data.changed_to.value as TaskStatus}
        label={action.data.changed_to.label}
      />
      .{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <HighlightTaskStatus
            value={action.data.changed_from.value as TaskStatus}
            label={action.data.changed_from.label}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
