import React from 'react'

import '../../styles/user-field.scss'

interface UserProfileLocalTimeFieldProps {
  time: string
}

export function UserProfileLocalTimeField(
  props: UserProfileLocalTimeFieldProps
) {
  return (
    <div className="user-field">
      <div className="user-field__text">{props.time}</div>
    </div>
  )
}
