import React, { useCallback, useState } from 'react'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'

const menuItemClasses = classNames(
  Classes.MENU_ITEM,
  Classes.TEXT_OVERFLOW_ELLIPSIS,
  Classes.FILL,
  Classes.POPOVER_DISMISS
)

interface SprintListActionsDropdownProps {
  collapseAllSprints: () => void
  collapseInactiveSprints: () => void
}

export function SprintListActionsDropdown(props: SprintListActionsDropdownProps) {
  const { collapseAllSprints, collapseInactiveSprints } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleInteraction = useCallback((nextOpenState: boolean) => {
    setIsOpen(() => nextOpenState)
  }, [])

  return (
    <Popover
      minimal={true}
      position={Position.BOTTOM_RIGHT}
      interactionKind={PopoverInteractionKind.CLICK}
      popoverClassName="popover"
      isOpen={isOpen}
      onInteraction={(state) => {
        handleInteraction(state)
      }}
    >
      <Icon
        icon={IconNames.MORE}
        style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
      />

      <div className={Classes.MENU}>
        <div className={menuItemClasses} onClick={collapseAllSprints}>
          Collapse all
        </div>
        <div className={menuItemClasses} onClick={collapseInactiveSprints}>
          Collapse inactive
        </div>
      </div>
    </Popover>
  )
}
