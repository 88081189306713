import './styles/timer-tracker-control.scss'

import React, { useCallback, useContext, useMemo, useState } from 'react'
import { PauseCircleOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import _ from 'lodash'
import classNames from 'classnames'

import { ActivityChoice } from '../entities/choices/activity'
import { ActivitySelect } from './ActivitySelect'
import { ReadableTime } from './TimeConverters'
import { TrackingObjectContext } from './trackingContext'
import {
  AddTimeManuallyObject,
  TrackableType,
  TrackingObjectType,
} from './types'
import { User } from '../entities/user/model'
import { IFormErrors } from '../project-page/state/store'

export interface TrackerControlProps {
  trackingObject: TrackingObjectType
  trackingObjectType: TrackableType
  activities?: ActivityChoice[]
  seconds?: number
  showControlOnly?: boolean
  onStop: () => void
  onStart: (activity: string) => void
  onAddTimeManually?: (data: AddTimeManuallyObject) => void
  currentUser?: User
  hideIfPossible?: boolean
  showHintTooltip?: boolean
  hintTooltipProps?: {
    content: string
    placement: TooltipPlacement
  }
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function TrackerControl(props: TrackerControlProps) {
  const { seconds = 0 } = props

  const { activities, onStart } = props

  const targetTrackingObject = useContext(TrackingObjectContext)

  const isTracking = _.isEqual(
    targetTrackingObject?.object,
    props.trackingObject
  )

  const isSingleActivity = useMemo(() => activities?.length === 1, [activities])

  const onSingleActivityStartClick = useCallback(() => {
    if (isSingleActivity) {
      onStart(activities![0].value)
    }
  }, [isSingleActivity, activities, onStart])

  const className = classNames(['time-tracker-control'], {
    'time-tracker-control_tracking': isTracking,
    'time-tracker-control_hidden':
      props.hideIfPossible && !isTracking && isSingleActivity,
  })

  if (isTracking) {
    return (
      <span className={className}>
        <PauseCircleOutlined
          className={'time-tracker-control__icon'}
          style={{ color: 'var(--color-text-link)' }}
          onClick={props.onStop}
        />

        {!props.showControlOnly && (
          <span className="time-tracker-control__time">
            {props.showHintTooltip && !!props.hintTooltipProps ? (
              <Popover
                placement={props.hintTooltipProps.placement}
                trigger="hover"
                content={props.hintTooltipProps.content}
              >
                <span>
                  <ReadableTime seconds={seconds} />
                </span>
              </Popover>
            ) : (
              <ReadableTime seconds={seconds} />
            )}

            {targetTrackingObject?.type === 'subtask' && (
              <>
                {' '}
                <span className="time-tracker-control__current">
                  /{' '}
                  <ReadableTime
                    seconds={targetTrackingObject.object.current_estimate}
                  />
                </span>
              </>
            )}
          </span>
        )}
      </span>
    )
  }

  if (!props.activities) {
    throw new Error('Activities are required in case tracking is not active')
  }

  if (isSingleActivity) {
    return (
      <span className={className}>
        <SingleActivityTracker
          showHintTooltip={props.showHintTooltip}
          hintTooltipProps={props.hintTooltipProps}
          seconds={seconds}
          onClick={onSingleActivityStartClick}
          hideIfPossible={props.hideIfPossible}
          showControlOnly={props.showControlOnly}
        />
      </span>
    )
  }

  return (
    <span className={className}>
      <MultipleActivityTracker
        showHintTooltip={props.showHintTooltip}
        hintTooltipProps={props.hintTooltipProps}
        activities={props.activities}
        activityTargetType={props.trackingObjectType}
        seconds={seconds}
        onStart={props.onStart}
        onAddTimeManually={props.onAddTimeManually}
        currentUser={props.currentUser}
        hideIfPossible={props.hideIfPossible}
        showControlOnly={props.showControlOnly}
        addTimeFormErrors={props.addTimeFormErrors}
        isAddTimeFormOpened={props.isAddTimeFormOpened}
        onOpenAddTimeModal={props.onOpenAddTimeModal}
        onCloseAddTimeModal={props.onCloseAddTimeModal}
      />
    </span>
  )
}

function SingleActivityTracker(props: {
  onClick?: () => void
  seconds?: number
  showControlOnly?: boolean
  hideIfPossible?: boolean
  showHintTooltip?: boolean
  hintTooltipProps?: {
    content: string
    placement: TooltipPlacement
  }
}) {
  const { seconds = 0 } = props

  return (
    <span>
      {!props.hideIfPossible && (
        <CaretRightOutlined
          className="time-tracker-control__icon"
          onClick={props.onClick}
        />
      )}

      {!props.showControlOnly && (
        <span className="time-tracker-control__time">
          {props.showHintTooltip && !!props.hintTooltipProps ? (
            <Popover
              placement={props.hintTooltipProps.placement}
              trigger="hover"
              content={props.hintTooltipProps.content}
            >
              <span>
                <ReadableTime seconds={seconds} />
              </span>
            </Popover>
          ) : (
            <span>
              <ReadableTime seconds={seconds} />
            </span>
          )}
        </span>
      )}
    </span>
  )
}

function MultipleActivityTracker(props: {
  activities: ActivityChoice[]
  activityTargetType: TrackableType
  seconds?: number
  hideIfPossible?: boolean
  showControlOnly?: boolean
  onStart: (activity: string) => void
  onAddTimeManually?: (data: AddTimeManuallyObject) => void
  currentUser?: User
  showHintTooltip?: boolean
  hintTooltipProps?: {
    content: string
    placement: TooltipPlacement
  }
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}) {
  const [opened, setOpened] = useState(false)

  const onOpened = useCallback(() => setOpened(true), [])
  const onClosed = useCallback(() => setOpened(false), [])

  return (
    <ActivitySelect
      activities={props.activities}
      activityTargetType={props.activityTargetType}
      onSelect={props.onStart}
      onOpened={onOpened}
      onClosed={onClosed}
      onAddTimeManually={props.onAddTimeManually}
      currentUser={props.currentUser}
      addTimeFormErrors={props.addTimeFormErrors}
      isAddTimeFormOpened={props.isAddTimeFormOpened}
      onOpenAddTimeModal={props.onOpenAddTimeModal}
      onCloseAddTimeModal={props.onCloseAddTimeModal}
    >
      <SingleActivityTracker
        showHintTooltip={props.showHintTooltip}
        hintTooltipProps={props.hintTooltipProps}
        seconds={props.seconds}
        hideIfPossible={!opened && props.hideIfPossible}
        showControlOnly={props.showControlOnly}
      />
    </ActivitySelect>
  )
}
