import { Node as ProseMirrorNode, NodeSpec } from 'prosemirror-model'

export const AttachmentNodeSpec: NodeSpec = {
  group: 'inline',
  inline: true,
  draggable: true,
  attrs: {
    href: {}, name: {}, size: {}
  },
  toDOM: (node: ProseMirrorNode) => [
    'a',
    {
      class: "attachment",
      path: node.attrs.href,
      "file-size": node.attrs.size
    },
    node.attrs.name
  ],
  parseDOM: [
    {
      tag: 'a.attachment',
      getAttrs: (dom: any): { [key: string]: string } | false => {
        const href = dom.getAttribute("path")
        const name = dom.textContent
        const size = dom.getAttribute("file-size")
        if (!href || !name || !size) { return false }
        return { href, name, size }
      },
    },
  ],
}

export const AttachmentImageNodeSpec: NodeSpec = {
  group: 'block',
  marks: "",
  draggable: true,
  attrs: {
    src: { default: '' },
    preview_src: { default: '' },
  },
  caption: { default: '' },
  toDOM: (node: ProseMirrorNode) => ['img', { src: node.attrs.src }],
  parseDOM: [{
    tag: "img[src]",
    getAttrs: (dom: any) => ({
      src: dom.getAttribute("src")
    })
  }],
}
