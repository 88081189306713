import React from "react"
import { useReactNodeView } from "../../../core/react-node-view"
import { useProseMirrorContext } from "../../../core/provider"

import "./Link.css"

export const InternalLinkComponent: React.FC = () => {

  const { node } = useReactNodeView()
  const { providers: { internalLinkProvider: provider } } = useProseMirrorContext()
  const { name, status } = provider.useProvider({
    taskId: node?.attrs.taskId,
    taskCode: node?.attrs.taskCode,
  })

  const href = `/${node?.attrs.workspaceId}/${node?.attrs.projectId}/task/${node?.attrs.taskCode}-${node?.attrs.taskId}`
  const content = name ? `${node?.attrs.taskCode}-${node?.attrs.taskId} | ${name} | ${status}` : href
  const onClick = (e: React.MouseEvent) => e.preventDefault()

  return <a href={href} onClick={onClick}>{content}</a>
}
