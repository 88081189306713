import React from 'react'
import { CreateAction } from '../../../entities/activities/model'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";


interface CreateSprintActivityProps {
  action: CreateAction
  date: string
  user: User | string
}

export function CreateSprintActivity(props: CreateSprintActivityProps) {
  const { user, action } = props

  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      created this {action.target.type}.{' '}
      <ActivitiesDate date={props.date} isActivityDate />
    </>
  )
}
