import { sprintPanelStore, SprintPanelStore } from './store'
import { ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { HistoryActivityItemType } from '../../../../../history/state/model'
import { arrayToggle } from '../../../../../utils/arrayToggle'
import {
  SprintStatisticDataService,
  sprintStatisticDataService,
} from './data-service'

export class SprintPanelService {
  constructor(
    private store: SprintPanelStore,
    private dataService: SprintStatisticDataService
  ) {}

  setActive(id: ID | null) {
    this.store.update({ sprintId: id })
  }

  @boundMethod
  updateActivityFilter(choice: HistoryActivityItemType) {
    this.store.update(({ activitiesFilter }) => ({
      activitiesFilter: arrayToggle(activitiesFilter, choice),
    }))
  }

  @boundMethod
  getPerformancePerDay(query: object) {
    this.dataService
      .performance_per_day(query)
      .subscribe((performancePerDay) => {
        this.store.update({ performancePerDay: performancePerDay })
      })
  }
}

export const sprintPanelService = new SprintPanelService(
  sprintPanelStore,
  sprintStatisticDataService
)
