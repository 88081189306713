import React from 'react'
import { ID } from '@datorama/akita'
import { DateRange } from '@blueprintjs/datetime'
import classNames from 'classnames'
import { User } from '../../entities/user/model'
import { FiltersListDateRange } from '../../filters-list/FiltersListDateRange'
import { FilterListUser } from '../../filters-list/FilterListUser'
import { FiltersListResetButton } from '../../filters-list/FiltersListResetButton'

interface ReportDevFiltersListProps {
  show: boolean
  users: User[]
  selectedUserID: ID | null
  startDate: Date | null
  endDate: Date | null
  onChangeSelectedUserID: (userID: ID | null) => void
  onChangeDates: (dates: DateRange) => void
  onClearFilters: () => void
}

export function ReportDevFiltersList(props: ReportDevFiltersListProps) {
  const className = classNames(['filters-list-wrap'], {
    'is-expanded': props.show,
  })
  return (
    <div className={className}>
      <div className="filters-list">
        <FilterListUser
          noUnassignedUser={true}
          users={props.users}
          selectedUserID={props.selectedUserID}
          onChangeSelectedUserID={props.onChangeSelectedUserID}
        />
        <FiltersListDateRange
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChangeDates}
        />
      </div>

      <FiltersListResetButton onClick={props.onClearFilters} />
    </div>
  )
}
