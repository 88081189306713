import './styles/users-page.scss'

import React, { useCallback } from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { ID } from '@datorama/akita'
import { Observable } from 'rxjs'
import { Layout } from 'antd'
import { useQuery } from '../utils/queryhook'
import {
  ChangePasswordData,
  User,
  UserInvite,
  UserRole,
  UserStatus,
} from '../entities/user/model'
import { Project } from '../entities/project/model'
import { workspaceQuery } from '../entities/workspace/query'
import { authQuery } from '../auth/state/query'
import { usersPageQuery } from './state/query'
import { usersPageService } from './state/service'
import { UsersList } from './components/users-list/UsersList'
import { FiltersList } from '../filters-list/FiltersList'
import { userQuery } from '../entities/user/query'
import { projectQuery } from '../entities/project/query'
import { userDataService } from '../entities/user/data-service'
import { userService } from '../entities/user/service'
import { UserProfile } from './components/user-profile/UserProfile'
import { ModelUpdateCallback } from '../utils/types'
import { Header } from '../components/header/Header'
import { CustomScrollbar } from '../components/custom-scrollbar/CustomScrollbar'

export function UsersPageContainer() {
  const history = useHistory()
  const { userId } = useParams()
  const selectedUser = useQuery(userQuery.selectEntity(userId!))

  const workspaceId = useQuery(workspaceQuery.active$).id
  const currentUser = useQuery(authQuery.currentUser$)

  const projects = useQuery(projectQuery.adminUserProjects$)
  const workspaceProjects = useQuery(projectQuery.workspaceProjects$)

  const users = useQuery(usersPageQuery.users$)
  const searchQuery = useQuery(usersPageQuery.searchQuery$)

  const showFiltersPanel = useQuery(usersPageQuery.showFiltersPanel$)
  const projectFilter = useQuery(usersPageQuery.projectFilter$)
  const statusFilters = useQuery(usersPageQuery.statusFilters$)

  const onSelectProject = useCallback(
    (project: Project | null) => {
      if (project?.id !== projectFilter?.id) {
        usersPageService.setProjectFilter(project)
      }
    },
    [projectFilter]
  )

  const onStatusFilterChange = useCallback(
    (name: UserStatus, apply: boolean) =>
      usersPageService.toggleStatusFilter(name),
    []
  )

  const onUserInvite = useCallback(
    (invite: UserInvite): Observable<User> => {
      invite.workspaces = [workspaceId]
      return userDataService.inviteNewUser(invite)
    },
    [workspaceId]
  )

  const successInviteUser = useCallback(
    (user: User) => {
      userService.appendUser(user)
      history.push(`./${user.id}`)
    },
    [history]
  )

  const successCancelInviteUser = useCallback(() => {
    history.push(`./${currentUser.id}`)
  }, [history, currentUser.id])

  const onProjectsChange = useCallback((user: User, projects: Project[]) => {
    userService.updateUser({
      id: user.id,
      projects: projects.map((project) => +project.id),
    })
  }, [])

  const onChangePassword = useCallback((data: ChangePasswordData) => {
    return userService.changePassword(data)
  }, [])

  if (!selectedUser) {
    return <Redirect to={`./${currentUser.id}`} />
  }

  return (
    <UsersPage
      currentUser={currentUser}
      projects={projects}
      workspaceProjects={workspaceProjects}
      users={users}
      selectedUser={selectedUser}
      searchQuery={searchQuery}
      onUserSearch={usersPageService.setSearchQuery}
      showFiltersPanel={showFiltersPanel}
      setShowFiltersPanel={usersPageService.setShowFiltersPanel}
      projectFilter={projectFilter}
      onSelectProject={onSelectProject}
      availableStatusFilters={Object.values(UserStatus)}
      statusFilters={statusFilters}
      onStatusFilterChange={onStatusFilterChange}
      onResetFilters={usersPageService.resetFilters}
      onUserInvite={onUserInvite}
      onInviteResend={userService.resendInvite}
      onInviteCancel={userService.cancelInvite}
      onSuccessCancelInvite={successCancelInviteUser}
      onSuccessInviteUser={successInviteUser}
      onUserUpdate={userService.updateUser}
      onUploadAvatar={userService.uploadAvatar}
      onProjectsChange={onProjectsChange}
      onChangePassword={onChangePassword}
    />
  )
}

interface UsersPageProps {
  currentUser: User
  projects: Project[]
  workspaceProjects: Project[]
  users: User[]
  selectedUser: User
  searchQuery: string
  onUserSearch: (query: string) => void
  showFiltersPanel: boolean
  setShowFiltersPanel: (show: boolean) => void
  projectFilter: Project | null
  availableStatusFilters: UserStatus[]
  statusFilters: UserStatus[]
  onSelectProject: (project: Project | null) => void
  onStatusFilterChange: (name: UserStatus, apply: boolean) => void
  onResetFilters: () => void
  onUserInvite: (invite: UserInvite) => Observable<User>
  onSuccessInviteUser: (user: User) => void
  onUserUpdate: ModelUpdateCallback<User>
  onInviteResend: (user: User) => Observable<object>
  onInviteCancel: (user: User) => Observable<object>
  onSuccessCancelInvite: () => void
  onUploadAvatar: (userId: ID, file: File) => void
  onProjectsChange: (user: User, projects: Project[]) => void
  onChangePassword: (data: ChangePasswordData) => Observable<void>
}

function UsersPage(props: UsersPageProps) {
  return (
    <Layout>
      <Header title="Users" />
      <Layout.Content style={{ flex: '1 1 0' }}>
        <div className="users-page">
          <FiltersList
            show={props.showFiltersPanel}
            projects={props.projects}
            projectFilter={props.projectFilter}
            onSelectProject={props.onSelectProject}
            availableStatusFilters={props.availableStatusFilters}
            statusFilters={props.statusFilters}
            onStatusFilterChange={props.onStatusFilterChange}
            onResetFilters={props.onResetFilters}
          />
          <div className="users-page__users-list">
            <div className="users-list-container">
              <UsersList
                currentUser={props.currentUser}
                users={props.users}
                searchQuery={props.searchQuery}
                selectedUserId={props.selectedUser.id}
                projects={props.projects}
                onUserSearch={props.onUserSearch}
                showFiltersPanel={props.showFiltersPanel}
                setShowFiltersPanel={props.setShowFiltersPanel}
                onUserInvite={props.onUserInvite}
                onSuccessInviteUser={props.onSuccessInviteUser}
              />
            </div>
          </div>
          <div className="users-page__user-details">
            <CustomScrollbar theme="light">
              <div className="users-page__user-card">
                <UserProfile
                  key={props.selectedUser.id}
                  user={props.selectedUser}
                  allProjects={props.workspaceProjects}
                  isCurrentUser={
                    props.selectedUser?.id === props.currentUser?.id
                  }
                  adminView={props.currentUser.user_role === UserRole.ADMIN}
                  onInviteResend={props.onInviteResend}
                  onInviteCancel={props.onInviteCancel}
                  onSuccessCancelInvite={props.onSuccessCancelInvite}
                  onUserUpdate={props.onUserUpdate}
                  onUploadAvatar={props.onUploadAvatar}
                  onProjectsChange={props.onProjectsChange}
                  onChangePassword={props.onChangePassword}
                />
              </div>
            </CustomScrollbar>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
