export const ALL_TASK_TYPES_ID = -1

export interface TaskType {
  id: number
  name: string
}

export const ALL_TASK_TYPES = {
  id: ALL_TASK_TYPES_ID,
  name: 'Show All',
} as TaskType

export const sortAlphabetically = (taskTypes: TaskType[]) => {
  return taskTypes.sort(function (a, b) {
    const first_value = a.name
    const second_value = b.name
    if (first_value.toLowerCase() < second_value.toLowerCase()) {
      return -1
    }
    if (first_value.toLowerCase() > second_value.toLowerCase()) {
      return 1
    }
    return 0
  })
}
