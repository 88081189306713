import React from 'react'
import { UserProfileTitleWithDivider } from './UserProfileTitleWithDivider'
import { UserProfileTextarea } from './UserProfileTextarea'

import '../../styles/titles.scss'
import '../../styles/user-field.scss'

interface UserProfileAboutMeProps {
  about_me: string
  editable: boolean
  onChange: (value: string) => void
}

export function UserProfileAboutMe(props: UserProfileAboutMeProps) {
  if (!props.editable && !props.about_me) {
    return null
  }

  return (
    <div>
      <UserProfileTitleWithDivider
        dividerClass={'user-title-divider'}
        orientation={'left'}
        label={'About me'}
      />
      <UserProfileTextarea
        value={props.about_me}
        placeholder={'Enter your hobbies...'}
        editable={props.editable}
        maxLength={1000}
        onUpdate={props.onChange}
      />
    </div>
  )
}
