import React, { useCallback } from 'react'
import { Empty } from 'antd'
import classNames from 'classnames'
import { Button } from '@blueprintjs/core'
import { IItemRendererProps, Select } from '@blueprintjs/select'
import {
  BugOutlined,
  LockOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'

import { SubtaskTypeChoice } from '../../../entities/choices/subtaskType'

import '../../../assets/scss/select.scss'
import './styles/subtask-type-select.scss'
import { SubtaskType } from '../../../entities/subtask/model'

const TypeSelect = Select.ofType<SubtaskTypeChoice>()

interface SubtaskTypeSelectProps {
  type: string
  types: SubtaskTypeChoice[]
  onSelect: (type: SubtaskType) => void
}

interface TypeIconsInterface {
  icon: React.ReactNode
  value: SubtaskType
}

export function SubtaskTypeSelect(props: SubtaskTypeSelectProps) {
  const { type, types, onSelect } = props

  const typeIcons: TypeIconsInterface[] = [
    {
      value: SubtaskType.GENERAL,
      icon: (
        <PlusCircleOutlined style={{ color: 'var(--color-text-inactive)' }} />
      ),
    },
    {
      value: SubtaskType.BUG,
      icon: <BugOutlined style={{ color: '#cf1322' }} />,
    },
    {
      value: SubtaskType.BLOCKER,
      icon: <LockOutlined style={{ color: '#cf1322' }} />,
    },
  ]

  const onItemSelect = useCallback(
    (type: SubtaskTypeChoice) => onSelect(type.value),
    [onSelect]
  )

  let type_obj = types.find((s) => s.value === type)

  const findMatchingIcon = (typeObj: SubtaskTypeChoice) => {
    return typeIcons.find(
      (typeIcon: TypeIconsInterface) => typeIcon.value === typeObj.value
    )?.icon
  }

  const renderType = useCallback(
    (typeObject: SubtaskTypeChoice, renderProps: IItemRendererProps) => {
      const { handleClick } = renderProps

      const typeIcon = typeIcons.find(
        (typeIcon: TypeIconsInterface) => typeIcon.value === typeObject.value
      )

      const menuItemClasses = classNames(
        'bp3-menu-item',
        'subtask-type-popover__item',
        {
          'bp3-active': typeObject.value === props.type,
        }
      )

      return (
        <li
          onClick={handleClick}
          key={typeObject.value}
          className={menuItemClasses}
        >
          <div className="subtask-type-popover__item-icon">
            {typeIcon?.icon}
          </div>
          <div className="subtask-type-popover__item-label">
            {typeObject.label}
          </div>
        </li>
      )
    },
    [typeIcons, props.type]
  )

  if (!type_obj) {
    return null
  }

  const buttonClasses = classNames(
    'subtask-type-select__button',
    `subtask-type-select__button_${type_obj.value}`
  )
  const selectClasses = classNames('subtask-type-select', 'select')

  return (
    <TypeSelect
      items={types}
      className={selectClasses}
      noResults={<Empty imageStyle={{ height: 60 }} description="No Results" />}
      onItemSelect={onItemSelect}
      itemRenderer={renderType}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover popover_center-text subtask-type-popover',
      }}
    >
      <Button className={buttonClasses}>{findMatchingIcon(type_obj)}</Button>
    </TypeSelect>
  )
}
