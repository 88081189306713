import { ID, QueryEntity } from '@datorama/akita'
import { ActivityLogState, ActivityLogStore, activityLogStore } from './store'
import { subtaskQuery, SubtaskQuery } from '../subtask/query'
import { switchMap } from 'rxjs/operators'

export class ActivityLogQuery extends QueryEntity<ActivityLogState> {
  constructor(
    protected store: ActivityLogStore,
    private subtaskQuery: SubtaskQuery
  ) {
    super(store)
  }

  selectByTaskID(taskId: ID) {
    return this.selectAll({
      filterBy: (activity) =>
        activity.target.type === 'task' && activity.target.id === taskId,
    })
  }

  selectBySubTaskID(taskId: ID) {
    return this.subtaskQuery.forTaskWithAllSubtasks(taskId).pipe(
      switchMap((subtasks) => {
        const subtaskIds = subtasks.map((subtask) => subtask.id)
        return this.selectAll({
          filterBy: (activity) =>
            activity.target.type === 'subtask' &&
            subtaskIds.includes(activity.target.id),
        })
      })
    )
  }

  selectByEpicID(epicID: ID) {
    return this.selectAll({
      filterBy: (activity) =>
        activity.target.type === 'epic' && activity.target.id === epicID,
    })
  }

  selectBySprintID(sprintID: ID) {
    return this.selectAll({
      filterBy: (activity) =>
        activity.target.type === 'sprint' && activity.target.id === sprintID,
    })
  }

  selectByProjectID(projectID: ID) {
    return this.selectAll({
      filterBy: (activity) =>
        activity.target.type === 'project' && activity.target.id === projectID,
    })
  }
}

export const activityLogQuery = new ActivityLogQuery(
  activityLogStore,
  subtaskQuery
)
