import { WorkspacePageStore, workspacePageStore } from './store'
import {ProjectStatus} from "../../entities/project/model";
import {boundMethod} from "autobind-decorator";
import {arrayToggle} from "../../utils/arrayToggle";

export class WorkspacePageService {
  constructor(
    private store: WorkspacePageStore,
  ) {}

  @boundMethod
  setShowFiltersPanel(show: boolean) {
    this.store.update({ showFiltersPanel: show })
  }

  @boundMethod
  updateStatusFilter(status: ProjectStatus) {
    this.store.update(({statusFilters}) => ({
      statusFilters: arrayToggle(statusFilters, status)
    }))
  }

  @boundMethod
  clearFilters() {
    this.store.reset()
  }
}

export const workspacePageService = new WorkspacePageService(
  workspacePageStore
)
