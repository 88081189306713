import { ID, Query, QueryEntity } from '@datorama/akita'
import { TrackingState, trackingStore } from './store'
import { Observable, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { Dictionary, isEqual } from 'lodash'
import { TrackableType, TrackingObject } from '../types'
import { taskQuery } from '../../entities/task/query'
import { subtaskQuery } from '../../entities/subtask/query'
import { sprintQuery } from '../../entities/sprint/query'
import { epicQuery } from '../../entities/epic/query'
import { projectQuery } from '../../entities/project/query'

export class TrackingQuery extends Query<TrackingState> {
  state$ = this.select()

  target$ = this.select('target').pipe(distinctUntilChanged(isEqual))

  targetObject$ = this.target$.pipe(
    switchMap((target: { type: TrackableType; id: ID }) => {
      if (!target) {
        return of(null)
      }

      const { type, id } = target

      const observable = entityQuery[type].selectEntity(id)

      return observable.pipe(map((object) => ({ type, object })))
    }),
    map((targetObject) => {
      if (!!targetObject && !targetObject.object) {
        return null
      }
      return targetObject
    })
  ) as Observable<TrackingObject | null>

  fromErrors$ = this.select('formErrors')
  isFormOpened$ = this.select('isFormOpened')
}

export const trackingQuery = new TrackingQuery(trackingStore)

export const entityQuery: Dictionary<QueryEntity<any>> = {
  project: projectQuery,
  epic: epicQuery,
  sprint: sprintQuery,
  task: taskQuery,
  subtask: subtaskQuery,
}
