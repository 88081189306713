import { http } from '../../globals/http'
import { TimeEntry } from './model'
import { ID } from "@datorama/akita";

export class TimeEntryDataService {
  list(query?: object) {
    return http.get<TimeEntry[]>('/api/time-entries/', query)
  }

  destroy(id: ID) {
    return http.delete(`/api/time-entries/${id}/`)
  }
}

export const timeEntryDataService = new TimeEntryDataService()
