import { AuthStore, authStore } from './store'
import { UserService, userService } from '../../entities/user/service'
import {
  UserDataService,
  userDataService,
} from '../../entities/user/data-service'
import { UserStore, userStore } from '../../entities/user/store'
import { applyTransaction } from '@datorama/akita'

export class AuthService {
  /** Tries to get profile info from server
   * and updates authentication status.
   *
   * @param failSilently: disables error messages
   *  in case not authenticad user made the request.
   */
  checkAuthentication(failSilently?: boolean) {
    this.userDataService.getSelf(failSilently).subscribe(
      (user) => {
        applyTransaction(() => {
          this.userStore.add(user)
          this.store.update({
            checked: true,
            currentUserId: user.id,
          })
        })
      },
      (error) => {
        this.store.update({ checked: true })
      }
    )
  }

  checkInviteToken(inviteToken: string) {
    this.store.update({ authPending: true })

    this.userDataService.checkInviteToken(inviteToken).subscribe(
      (inviteData) => {
        this.store.update({
          inviteData: inviteData,
          authErrors: null,
          authPending: false,
          inviteChecked: true,
        })
      },
      (error) => {
        this.store.update({
          authPending: false,
          authErrors: error.response?.data,
          inviteChecked: true,
        })
      }
    )
  }

  inviteRegistration(
    password: string,
    confirmPassword: string,
    inviteToken: string
  ) {
    this.store.update({ authPending: true })

    this.userDataService
      .inviteRegistration(password, confirmPassword, inviteToken)
      .subscribe(
        (user) => {
          applyTransaction(() => {
            this.userStore.add(user)
            this.store.update({
              checked: true,
              currentUserId: user.id,
              authPending: false,
              authErrors: null,
            })
          })
        },
        (error) => {
          this.store.update({
            checked: true,
            authPending: false,
            authErrors: error.response?.data,
          })
        }
      )
  }

  authenticate(email: string, password: string) {
    this.store.update({ authPending: true })
    this.userDataService.login(email, password).subscribe(
      (user) => {
        applyTransaction(() => {
          this.userStore.add(user)
          this.store.update({
            checked: true,
            currentUserId: user.id,
            authPending: false,
            authErrors: null,
          })
        })
      },
      (error) => {
        this.store.update({
          checked: true,
          authPending: false,
          authErrors: error.response?.data,
        })
      }
    )
  }

  logout() {
    this.userDataService.logout().subscribe(
      () => {
        //In case some parts of the store will be persistent
        // in future, don't forget to reset it on logout.
        window.location.href = '/'
      },
      (error) => {
        console.warn('Server logout error:', error)
      }
    )
  }

  setLoginRedirectTo(loginRedirectTo: string) {
    this.store.update({ loginRedirectTo: loginRedirectTo })
  }

  constructor(
    private store: AuthStore,
    private userService: UserService,
    private userDataService: UserDataService,
    private userStore: UserStore
  ) {}
}

export const authService = new AuthService(
  authStore,
  userService,
  userDataService,
  userStore
)
