import { ShortUser } from "../../../entities/user/model"

export class BaseMentionProvider {
  useMentionUrl = ({id}: {id: string | number}): string => ""

  useMention = ({id}: {id: string | number}): ShortUser | null => {
    return null
  }

  useSearch = ({query}: {query: string}): ShortUser[] => {
    return []
  }
}
