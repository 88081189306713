import React from 'react'
import { SetTaskPriorityAction } from '../../entities/activities/model'
import { TaskPriorityType } from '../../entities/choices/priority'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { HighlightTaskPriority } from './text-formatters/HighlightTaskPriority'
import {User} from "../../entities/user/model";


interface SetTaskPriorityActivityProps {
  action: SetTaskPriorityAction
  date: string
  user: User | string
}

export function SetTaskPriorityActivity(props: SetTaskPriorityActivityProps) {
  const { action, user } = props
  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> set this
      task priority to{' '}
      <HighlightTaskPriority
        label={action.priority.label}
        value={action.priority.value as TaskPriorityType}
      />
      . <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
