import { QueryEntity } from '@datorama/akita'

import {
  SmartSuiteAccountState,
  SmartSuiteAccountStore,
  smartSuiteAccountStore,
} from './account.store'
import { authQuery, AuthQuery } from '../../../auth/state/query'
import { switchMap } from 'rxjs/operators'

export class SmartSuiteAccountQuery extends QueryEntity<
  SmartSuiteAccountState
> {
  loaded$ = this.select('loaded')

  currentUserAccounts$ = this.authQuery.currentUser$.pipe(
    switchMap(() => this.selectAll())
  )

  constructor(store: SmartSuiteAccountStore, private authQuery: AuthQuery) {
    super(store)
  }
}

export const smartSuiteAccountQuery = new SmartSuiteAccountQuery(
  smartSuiteAccountStore,
  authQuery
)
