import React from 'react'
import moment from 'moment-timezone'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import { UserProfileDateInput } from './UserProfileDateInput'

import '../../styles/user-field.scss'

interface UserProfileBirthdayInfoProps {
  birthday: string | null
  editable: boolean
  onChange: (value: string) => void
}

export function UserProfileBirthdayInfo(props: UserProfileBirthdayInfoProps) {
  if (!props.editable && !props.birthday) {
    return null
  }
  return (
    <div className="user-profile__info user-profile__info_birthday user-info">
      <UserProfileFieldTitle label={'birthday'} />
      <UserProfileDateInput
        value={props.birthday ? moment(props.birthday) : null}
        editable={props.editable}
        onChange={props.onChange}
      />
    </div>
  )
}
