import classNames from 'classnames'
import React from 'react'
import { ActionType } from '../../../entities/activities/model'
import { PROJECT_ACTIVITIES_FILTERS } from '../../../entities/choices/subtasksFilter'
import { TaskPanelFilterPopoverDefault } from '../../tasks/controls/TaskPanelFilterPopoverDefault'
import { PanelSectionTitle } from '../panel-section-title/PanelSectionTitle'
import {User} from "../../../entities/user/model";
import {ID} from "@datorama/akita";
import {HistoryActivityItemType, HistoryItem} from "../../../history/state/model";
import {HistoryList} from "../../../history/HistoryList";

interface ProjectDetailsActivitiesProps {
  activities: ActionType[]
  history: HistoryItem[]
  currentUser: User
  className?: string
  onWorkLogDelete: (id: ID) => void
  onFilterActivities: (value: HistoryActivityItemType) => void
  selectedActivityFilter?: HistoryActivityItemType[]
}

export function ProjectDetailsActivities(props: ProjectDetailsActivitiesProps) {
  const classes = classNames('project-details-activities', props.className)
    return (
      <div>
        <div className={classes}>
          <PanelSectionTitle
            title={
              <TaskPanelFilterPopoverDefault
                filters={PROJECT_ACTIVITIES_FILTERS}
                sectionName="Activities"
                noIconBackground={true}
                onFilter={props.onFilterActivities}
                selectedActivityFilter={props.selectedActivityFilter}
              />
            }
          />
        </div>
        <HistoryList
          items={props.history}
          onCommentDelete={() => {}}
          onWorkLogDelete={props.onWorkLogDelete}
          onCommentUpdate={() => {}}
          currentUser={props.currentUser}
          selectedActivityFilter={props.selectedActivityFilter}
        />
      </div>
  )
}
