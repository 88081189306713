import { Plugin, PluginKey, EditorState, Transaction } from "prosemirror-state"
import {Decoration, DecorationSet, EditorView} from "prosemirror-view"
import shortid from "shortid"

import { createReactDecorationWidget } from "../../core/react-decoration-widget"
import { Loading } from "./components"
import { BaseAttachmentProvider } from "./provider"

const key = new PluginKey("attachment")
export const attachmentPlugin = (provider: BaseAttachmentProvider) => new Plugin({
  key: key,
  state: {
    init() { return DecorationSet.empty },
    apply(tr, value, oldState, state) {
      const set = value.map(tr.mapping, tr.doc)
      const meta = tr.getMeta(this)

      if (meta && meta.add) {
        const dom = createReactDecorationWidget(Loading)
        const widget = Decoration.widget(meta.add.pos, dom, {id: meta.add.id})

        return set.add(tr.doc, [widget])
      } else if (meta && meta.remove) {
        return set.remove(set.find(null, null, (spec: any) => spec.id === meta.remove.id))
      }

      return set
    }
  },
  props: {
    decorations(state) { return this.getState(state) },
    handleDOMEvents: {
      drop: (view, event) => {
        const id = shortid.generate()

        // Get dropped files
        const files = event.dataTransfer?.files
        if (!files?.length) { return false }

        // Get the position where the file was inserted
        const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
        if (!coordinates) { return false }

        // Stop browser effect
        event.preventDefault()

        // Show placeholder
        view.dispatch(addWidget(view.state, id, coordinates.pos))
        view.dom.classList.add('file-load')
        view.focus()


        const file = files[0]
        provider.uploadFile(
          file,
          (url, preview_url) => {
            // Get current element position
            const pos = findWidget(view.state, id)
            if (pos == null) return

            const node = createAttachmentNode(url, preview_url, file, view)
            view.dispatch(removeWidget(view.state, id).insert(pos, node).scrollIntoView())
          },
          () => { view.dispatch(removeWidget(view.state, id)) }
        )

        return true
      }
    }
  }
})

export const createAttachmentNode = (url: string, preview_url: string | undefined, file: File, view: EditorView) => {
  if (/image/i.test(file.type)) {
    return view.state.schema.nodes.image.create({
      src: url, preview_src: preview_url
    })
  }

  return view.state.schema.nodes.attachment.create({
    href: url, name: file.name, size: file.size
  })
}

export const addWidget = (state: EditorState, id: string, pos: number): Transaction => {
  return state.tr.setMeta(key, {add: {id, pos}})
}

export const removeWidget = (state: EditorState, id: string): Transaction => {
  return state.tr.setMeta(key, {remove: {id}})
}

export const findWidget = (state: EditorState, id: string): number | null => {
  const found = key.getState(state).find(null, null, (spec: any) => spec.id === id)
  return found.length ? found[0].from : null
}
