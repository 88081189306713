import { orderBy } from 'lodash'
import {
  CalendarEventDataService,
  calendarEventDataService,
} from './data-service'
import { convertEvent, groupEvents } from './helpers'

export class CalendarEventService {
  constructor(private dataService: CalendarEventDataService) {}

  fetchEvents(
    query: object,
    successCallback: Function,
    failureCallback: Function,
    workspaceId: string | undefined
  ) {
    this.dataService.list(query).subscribe(
      (calendarEvents) => {
        // sorting is required for slot grouping
        const sortedCalendarEvents = orderBy(
          calendarEvents,
          ['full_day', 'user_id', 'start'],
          ['desc', 'asc', 'asc']
        )
        const groupedCalendarEvents = groupEvents(sortedCalendarEvents)
        const dateToStateMap: { [key: string]: string[] } = {}

        successCallback(
          groupedCalendarEvents.map((event) =>
            convertEvent(event, workspaceId, dateToStateMap)
          )
        )
      },
      (error) => {
        failureCallback(error)
      }
    )
  }
}

export const calendarEventService = new CalendarEventService(
  calendarEventDataService
)
