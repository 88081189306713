import { ID } from '@datorama/akita'
import React, { useCallback, useMemo } from 'react'
import {
  getSprintStatusColorMapping,
  Sprint,
  SprintStatus,
  SprintStatusType,
} from '../../../entities/sprint/model'
import { TaskGroupDropdownMenu } from './TaskGroupDropdownMenu'
import { TaskGroupDropdownTarget } from './TaskGroupDropdownTarget'
import { MoveItemCallback } from '../../drag-n-drop'

interface SprintDropdownContainerProps {
  sprintStatus: SprintStatusType
  sprintsLength: number
  sprintID: ID
  sprints?: Sprint[]
  showCurrent?: boolean
  targetClassName?: string
  hasReorderMenu?: boolean
  reorderMenuItems?: []
  isReadOnlySprint: boolean
  onDelete: (id: ID) => void
  onDownload: (id: ID) => void
  onStatusSelect: (status: string) => void
  onReorder?: MoveItemCallback
  onSort: (sprintID: ID) => void
  onSortTasksByAssignee: (sprintID: ID) => void
}

export function SprintDropdownContainer(props: SprintDropdownContainerProps) {
  const {
    sprintID,
    sprintStatus,
    onStatusSelect,
    isReadOnlySprint,
    onDelete,
    onDownload,
  } = props

  const selectStatus = useCallback(
    (item) => {
      onStatusSelect(item.value)
    },
    [onStatusSelect]
  )

  const deleteSprint = useCallback(
    (item) => {
      onDelete(item.id)
    },
    [onDelete]
  )

  const downloadSprint = useCallback(
    (item) => {
      onDownload(item.id)
    },
    [onDownload]
  )

  const [startMenuItem, finishMenuItem] = useMemo(
    () => [
      {
        value: SprintStatus.STARTED,
        label: 'Start',
        onSelectItem: selectStatus,
      },
      {
        value: SprintStatus.COMPLETE,
        label: 'Finish',
        onSelectItem: selectStatus,
      },
    ],
    [selectStatus]
  )

  const downloadMenuItem = useMemo(
    () => ({
      label: 'Download',
      onSelectItem: downloadSprint,
    }),
    [downloadSprint]
  )

  const deleteMenuItem = useMemo(
    () => ({
      label: 'Delete',
      onSelectItem: deleteSprint,
    }),
    [deleteSprint]
  )

  const activeMenuItems = useMemo(
    () =>
      isReadOnlySprint
        ? [downloadMenuItem]
        : [finishMenuItem, downloadMenuItem],
    [finishMenuItem, downloadMenuItem, isReadOnlySprint]
  )

  const draftOrClosedMenuItems = useMemo(
    () =>
      isReadOnlySprint ? [downloadMenuItem] : [startMenuItem, downloadMenuItem],
    [startMenuItem, downloadMenuItem, isReadOnlySprint]
  )

  const noTasksActiveMenuItems = useMemo(
    () =>
      isReadOnlySprint
        ? [downloadMenuItem]
        : [finishMenuItem, deleteMenuItem, downloadMenuItem],
    [finishMenuItem, deleteMenuItem, downloadMenuItem, isReadOnlySprint]
  )

  const noTasksDraftOrClosedMenuItems = useMemo(
    () =>
      isReadOnlySprint
        ? [downloadMenuItem]
        : [startMenuItem, deleteMenuItem, downloadMenuItem],
    [startMenuItem, deleteMenuItem, downloadMenuItem, isReadOnlySprint]
  )

  const getMenuItems = (sprintStatus: SprintStatusType) => {
    switch (sprintStatus) {
      case SprintStatus.DRAFT:
        if (!props.sprintsLength) {
          return noTasksDraftOrClosedMenuItems
        }
        return draftOrClosedMenuItems

      case SprintStatus.STARTED:
        if (!props.sprintsLength) {
          return noTasksActiveMenuItems
        }
        return activeMenuItems

      case SprintStatus.COMPLETE:
        if (!props.sprintsLength) {
          return noTasksDraftOrClosedMenuItems
        }
        return draftOrClosedMenuItems

      default:
        return activeMenuItems
    }
  }

  return (
    <TaskGroupDropdownMenu
      key={sprintID}
      objectType={'sprint'}
      items={getMenuItems(sprintStatus)}
      objectID={sprintID}
      hasReorderMenu={props.hasReorderMenu}
      reorderMenuItems={props.sprints}
      onReorder={props.onReorder}
      onSort={props.onSort}
      onSortTasksByAssignee={props.onSortTasksByAssignee}
    >
      <TaskGroupDropdownTarget
        titleColor={getSprintStatusColorMapping[sprintStatus]}
        title={props.showCurrent ? sprintStatus : ''}
        className={props.targetClassName}
      />
    </TaskGroupDropdownMenu>
  )
}
