import React from 'react'
import { Checkbox } from 'antd'
import { StatusChoice } from '../../entities/choices/status'
import { TaskStatus } from '../../entities/task/model'
import {
  EpicStatusChoice,
  SprintStatusChoice,
} from './ProjectPageFilterContent'
import { SprintStatus } from '../../entities/sprint/model'
import { ID } from '@datorama/akita'

interface TaskPanelFilterOptionProps {
  option:
    | StatusChoice
    | SprintStatusChoice
    | EpicStatusChoice
    | { value: number; label: string }
  onChange?: (value: TaskStatus | SprintStatus | ID) => void
  checked?: boolean
}

export function ProjectPageFilterOption(props: TaskPanelFilterOptionProps) {
  const { option, onChange } = props

  const changeHandler = (e: any) => {
    if (onChange) onChange(e.target['data-value'])
  }

  return (
    <Checkbox
      data-value={option.value}
      onChange={changeHandler}
      className="app-checkbox"
      checked={props.checked}
    >
      {option.label}
    </Checkbox>
  )
}
