import {MarkSpec, Node as ProseMirrorNode} from 'prosemirror-model'


export const underlineMark: MarkSpec = {
  underline: {
    parseDOM: [
      {tag: 'u'},
      {
        style: 'text-decoration',
        getAttrs: (value: ProseMirrorNode | string) => (value === 'underline' ? {} : false)
      },
    ],
    toDOM: () => ['u', {}, 0],
  },
}
