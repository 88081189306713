import React, { useState } from 'react'
import { Collapse } from '@blueprintjs/core'
import { Extension, ProseMirrorEditor } from '../../../editor'
import {
  AttachmentExtension,
  ExternalLinkExtension, FooterExtension, HorizontalRuleExtension,
  InternalLinkExtension,
  KeyMapExtension,
  MentionExtension, StrikethroughExtension, TableExtension, ToolbarExtension, UnderlineExtension
} from '../../../editor/extentions'
import { PlaceHolderExtension } from '../../../editor/extentions'
import { isEqual } from 'lodash'
import {
  AttachmentProvider,
  FooterProvider,
  InternalLinkProvider,
  MentionProvider
} from "../../../editor/providers";
import {Subtask} from "../../../entities/subtask/model";

interface SubtaskChangeEstimationProps {
  subtask: Subtask
  isOpen: boolean
  placeholder: string
  transitionDuration: number
  blurHandler: (value: object | null) => void
  description: object | null
}

export function SubtaskDescription(props: SubtaskChangeEstimationProps) {
  const {
    subtask,
    isOpen,
    placeholder,
    transitionDuration,
    blurHandler,
    description,
  } = props

  const extensions: Extension[] = [
    new PlaceHolderExtension(placeholder),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new AttachmentProvider(subtask.task)),
    new ToolbarExtension(),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new FooterExtension(new FooterProvider(subtask.task)),
    new KeyMapExtension()
  ]

  const [value, setValue] = useState(() => description)

  const onChangeEditor = (transaction: any, view: any) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
    }
  }

  const onBlur = () => {
    if (!isEqual(value, description)) blurHandler(value)
  }

  return (
    <Collapse isOpen={isOpen} transitionDuration={transitionDuration}>
      <ProseMirrorEditor
        className="subtasks-subtask__description"
        value={value}
        extensions={extensions}
        onBlur={onBlur}
        onChange={onChangeEditor}
      />
    </Collapse>
  )
}
