import cx from 'classnames';
import React, {
  forwardRef,
  LegacyRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as ReactDOM from 'react-dom';
import {
  animationFrameScheduler,
  combineLatest,
  fromEvent,
  interval,
  of,
  Subscription,
} from 'rxjs';
import { auditTime, filter, startWith, switchMap, take } from 'rxjs/operators'
import { ElementPositionProps, NormalizedData, PositionData } from './ElementPosition.types'
import { getPosition, getScrollbarWidth, normalizePositionOptions } from './ElementPosition.helpers'

export interface ElementPositionRef {
  readonly position: PositionData;
  updatePosition?: (position: PositionData) => void;
  refresh?: () => void;
}

export const ElementPosition = forwardRef(
  // @ts-ignore
  (props: ElementPositionProps, ref: MutableRefObject<ElementPositionRef>) => {
    const {
      display = 'block',
      my = 'left top',
      at = 'left bottom',
      collision = 'flipfit',
      isCalculatePositionEnabled = true,
      isEnabled = true,
      children,
      targetElement,
      boundaryElement,
      boundaryOffsets,
      appendTo,
      position = 'absolute',
      zIndex = 'auto',
      excludeScrollBarWidth = false,
      contentCreated = () => {},
      calculatePosition = () => {},
      flipPositionChanged = () => {},
      onMouseEnter = () => {},
      onMouseLeave = () => {},
    } = props;

    const positionContentEl = useRef<HTMLElement>(null);
    const parentEl = useRef<HTMLElement>(null);

    const [positionData, setPositionData] = useState<PositionData>({
      width: 0,
      height: 0,
      top: 1,
      left: 0,
      scrollTop: 0,
      scrollLeft: 0,
      marginTop: 0,
      marginLeft: 0,
      flipState: { horizontal: false, vertical: false },
    });
    const [opacity, setOpacity] = useState<number>(0);

    const scrollbarWidth = useMemo<number>(() => getScrollbarWidth(), []);
    const normalizedData = useMemo<NormalizedData>(
      () => normalizePositionOptions(my, at, collision),
      [my, at, collision]
    );
    const contentStyles = useMemo<React.CSSProperties>(
      () => ({
        position,
        zIndex,
        left: positionData.left,
        top: positionData.top,
        marginLeft: positionData.marginLeft,
        marginTop: positionData.marginTop,
        display,
        opacity,
      }),
      [position, zIndex, positionData, opacity, display]
    );

    const contentClasses = cx('position-content', {
      'flip-horizontal': positionData.flipState.horizontal,
      'flip-vertical': positionData.flipState.vertical,
    });

    const refresh = () => {
      let isDataChanged = false;

      // get new position data
      const data = getPosition(
        positionContentEl.current as any,
        targetElement?.current || parentEl.current as any,
        boundaryElement?.current || window,
        boundaryOffsets as any,
        position,
        excludeScrollBarWidth ? 0 : scrollbarWidth,
        normalizedData.my,
        normalizedData.at,
        normalizedData.offsets,
        normalizedData.collision
      );

      // check flip
      if (
        data.flipState.horizontal !== positionData.flipState.horizontal ||
        data.flipState.vertical !== positionData.flipState.vertical
      ) {
        flipPositionChanged(data.flipState);
      }

      if (
        data.left !== positionData.left ||
        data.top !== positionData.top ||
        data.marginLeft !== positionData.marginLeft ||
        data.marginTop !== positionData.marginTop //||
        // at !== atData
      ) {
        setPositionData(data);
        setOpacity(1);
        // setAtData(at);
        isDataChanged = true;
      }

      if (isDataChanged) {
        calculatePosition();
      }
    };

    // update element position
    useEffect(() => {
        let subscription: Subscription;

        if (isEnabled) {
          subscription = combineLatest([
            fromEvent(document, 'onwheel' in document ? 'wheel' : 'mousewheel').pipe(
              // @ts-ignore
              startWith(of(null)),
              switchMap((e: WheelEvent) => {
                const isTouchPad = e.deltaY ? e.deltaY === -3 * e.deltaY : e.deltaMode === 0;
                return isTouchPad ? of(e) : interval(16).pipe(take(20));
              })
            ),
            fromEvent(document, 'mousemove').pipe(startWith(of(null))),
            fromEvent(window, 'resize').pipe(startWith(of(null))),
            fromEvent(document, 'keydown').pipe(startWith(of(null))),
          ])
            .pipe(
              filter(() => isCalculatePositionEnabled),
              auditTime(0, animationFrameScheduler)
            )
            .subscribe(() => refresh());
        }

        return () => {
          if (subscription) {
            subscription.unsubscribe()
          }
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isCalculatePositionEnabled, isEnabled, positionData, setPositionData, at]
    );

    useEffect(() => {
      if (isEnabled) {
        contentCreated();
      }
    }, [contentCreated, isEnabled]);

    const parent = targetElement ? null : (
      <div className="element-position" ref={parentEl as LegacyRef<HTMLDivElement>} />
    );

    const positionContent = (
      <div
        ref={positionContentEl as LegacyRef<HTMLDivElement>}
        className={contentClasses}
        style={contentStyles}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    );

    useImperativeHandle<ElementPositionRef, ElementPositionRef>(
      ref,
      () =>
        ({
          refresh,
          position: positionData,
          updatePosition: (newPosition: PositionData) => {
            setPositionData(newPosition);
            refresh();
          },
        } as ElementPositionRef)
    );

    return isEnabled ? (
      <>
        {parent}
        {appendTo?.current
          ? ReactDOM.createPortal(positionContent, appendTo.current)
          : positionContent}
      </>
    ) : null;
  }
);
