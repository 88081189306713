import {
  Classes,
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import classNames from 'classnames'
import React, { useContext } from 'react'
import './styles/reorder-menu.scss'
import './styles/sort-menu.scss'
import { ID } from '@datorama/akita'
import { CurrentUserContext } from '../../../auth/currentUserContext'
import { UserRole } from '../../../entities/user/model'

const menuItemClasses = classNames(
  Classes.MENU_ITEM,
  Classes.TEXT_OVERFLOW_ELLIPSIS,
  Classes.FILL,
  'reorder-menu-item'
)

export type ReorderMenuItemType = {
  id: ID
  name: string
}

interface SortMenuProps {
  items?: ReorderMenuItemType[]
  objectId: ID
  onSort: (sprintID: ID) => void
  onSortByAssignee: (sprintID: ID) => void
}

export const SortMenu = (props: SortMenuProps) => {
  const { objectId, onSort, onSortByAssignee } = props
  const currentUser = useContext(CurrentUserContext)!

  const content = (
    <>
      <div className="sort-menu-content">
        <div
          className="sort-menu-content__item"
          onClick={() => {
            onSortByAssignee(objectId)
          }}
        >
          By assignee ONLY
        </div>
      </div>
      {currentUser?.user_role === UserRole.ADMIN && (
        <div className="sort-menu-content">
          <div
            className="sort-menu-content__item"
            onClick={() => {
              onSort(objectId)
            }}
          >
            By assignee, priority, creation date
          </div>
        </div>
      )}
    </>
  )

  return (
    <Popover
      content={content}
      position={Position.RIGHT_TOP}
      popoverClassName="popover sort-menu"
      minimal={true}
      targetTagName="div"
      wrapperTagName="div"
      interactionKind={PopoverInteractionKind.CLICK}
      boundary="viewport"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0 1',
        },
      }}
    >
      <div className={menuItemClasses}>
        <span className="reorder-menu-item__text">Group</span>{' '}
        <Icon color="#595959" icon="chevron-right" iconSize={12} />
      </div>
    </Popover>
  )
}
