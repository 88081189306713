import { InputRule, inputRules } from "prosemirror-inputrules"
import { Plugin } from "prosemirror-state"
import {EditorView} from "prosemirror-view"

const MAX_MATCH = 500

export function enterInputRules({rules}: { rules: InputRule[] }) {
  const spec = inputRules({rules}).spec

  const plugin: Plugin = new Plugin({
    ...spec,
    props: {
      ...spec.props,
      // Added keypress support for original plugin
      handleKeyDown(view, event) {
        if (event.key !== "Enter") return false
        let {$cursor} = view.state.selection as any
        if ($cursor) run(view, $cursor.pos, $cursor.pos, "\n", rules, plugin)
        return false
      }
    }
  })
  return plugin
}

const run = (view: EditorView, from: number, to: number, text: string, rules: any, plugin: Plugin) => {
  if (view.composing) return false
  const state = view.state, $from = state.doc.resolve(from)
  if ($from.parent.type.spec.code) return false
  const textBefore = $from.parent.textBetween(
    Math.max(0, $from.parentOffset - MAX_MATCH), $from.parentOffset, undefined, "\ufffc"
  ) + text

  for (let i = 0; i < rules.length; i++) {
    const match = rules[i].match.exec(textBefore)
    const tr = match && rules[i].handler(state, match, from - (match[0].length - text.length), to)
    if (!tr) continue
    view.dispatch(tr.setMeta(plugin, {transform: tr, from, to, text}))
    return true
  }
  return false
}
