import { DecorationSet, Decoration } from 'prosemirror-view'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Node as ProseMirrorNode } from "prosemirror-model"

import {isDocNodeEmpty} from "../../core/utils"
import "./placeholder.css"

const key = new PluginKey("placeHolderPlugin")

export const placeHolderPlugin = (text: string) => new Plugin({
  key: key,
  state: {
    init: (_, state) => ({
      empty: isDocNodeEmpty(state.doc),
    }),
    apply: (tr, value, _, state) => {
      if (!tr.docChanged) { return value }
      return {empty: isDocNodeEmpty(state.doc)}
    }
  },
  props: {
    decorations: state => {
      const decorations = [] as Decoration[];
      state.doc.descendants((node: ProseMirrorNode, pos: number) => {
        if (!key.getState(state).empty) { return }
        decorations.push(Decoration.node(
          pos, pos + node.nodeSize, {"class": "empty-node", "data-placeholder": text}
        ))
      })
      return DecorationSet.create(state.doc, decorations)
    },
  },
})
