import { SubtaskType } from '../subtask/model'

export interface SubtaskTypeChoice {
  value: SubtaskType
  color: string
  label: string
}

export const SUBTASK_TYPES: SubtaskTypeChoice[] = [
  {
    value: SubtaskType.GENERAL,
    label: 'General',
    color: 'var(--color-text-inactive)',
  },
  { value: SubtaskType.BUG, label: 'Bug', color: '#cf1322' },
  { value: SubtaskType.BLOCKER, label: 'Blocker', color: '#FFA500' },
]

export type SubtaskTypeType = 'general' | 'bug' | 'blocker'
