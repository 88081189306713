import React from 'react'
import { Button } from 'antd'
import {
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import { Doc } from '../../../editor/types'
import { useCallback, useState } from 'react'

import '../styles/task-description-template.scss'

interface DescriptionTemplatesMenuProps {
  disabled: boolean
  onSelectTemplate: (descriptionTemplate: Doc) => void
}

export function DescriptionTemplatesMenu({
  disabled,
  onSelectTemplate,
}: DescriptionTemplatesMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = useCallback((nextOpenState: boolean) => {
    setIsOpen(() => nextOpenState)
  }, [])

  const handleSelectTemplate = useCallback(
    (template: Doc) => {
      onSelectTemplate(template)
      toggleIsOpen(false)
    },
    [onSelectTemplate, toggleIsOpen]
  )

  return (
    <Popover
      minimal={true}
      position={Position.BOTTOM_LEFT}
      interactionKind={PopoverInteractionKind.CLICK}
      isOpen={isOpen}
      content={
        <Menu>
          {templates.map((template, index) => (
            <MenuItem
              key={index}
              text={template.label}
              onClick={() => handleSelectTemplate(template.value)}
            />
          ))}
        </Menu>
      }
      onInteraction={(state) => {
        toggleIsOpen(state)
      }}
    >
      <Button
        disabled={disabled}
        size="small"
        className="task-description-template__button"
      >
        Description templates
      </Button>
    </Popover>
  )
}

const templates: { label: string; value: Doc }[] = [
  {
    label: 'Feature',
    value: {
      type: 'doc',
      content: [
        getParagraphWithBoldText('In scope:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
        getParagraphWithBoldText('Designs:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
        getParagraphWithBoldText('Requirements:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
      ],
    },
  },
  {
    label: 'Bug',
    value: {
      type: 'doc',
      content: [
        getParagraphWithBoldText('Steps:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
        getParagraphWithBoldText('Expected:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
        getParagraphWithBoldText('Actual:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
        getParagraphWithBoldText('Affected:'),
        getParagraphWithText('…'),
        getEmptyParagraph(),
      ],
    },
  },
]

function getParagraphWithBoldText(text: string) {
  return {
    type: 'paragraph',
    content: [
      {
        type: 'text',
        marks: [{ type: 'strong' }],
        text,
      },
    ],
  }
}

function getParagraphWithText(text: string) {
  return {
    type: 'paragraph',
    content: [{ type: 'text', text }],
  }
}

function getEmptyParagraph() {
  return { type: 'paragraph' }
}
