import React from 'react'
import classNames from 'classnames'
import { UserProfileInput } from './UserProfileInput'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'

import '../../styles/user-field.scss'

interface UserProfileTextInfoProps {
  value: string
  label: string
  placeholder: string
  editable: boolean
  modifier?: string
  onChange: (value: string) => void
}

export function UserProfileTextInfo(props: UserProfileTextInfoProps) {
  const classes = classNames('user-profile__info', 'user-info', {
    [`user-profile__info_${props.modifier}`]: props.modifier,
  })

  if (!props.editable && !props.value) {
    return null
  }

  return (
    <div className={classes}>
      <UserProfileFieldTitle label={props.label} />
      <UserProfileInput
        value={props.value}
        placeholder={props.placeholder}
        maxLength={100}
        editable={props.editable}
        onUpdate={props.onChange}
      />
    </div>
  )
}
