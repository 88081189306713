import '../../styles/user-field.scss'

import React from 'react'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import { UserProfileInput } from './UserProfileInput'
import { FieldIsRequiredValidator } from '../../../components/ValidatedEditableText'

interface UserProfileFullNameProps {
  value: string
  maxLength: number
  editable: boolean
  onChange: (fullName: string) => void
}

export function UserProfileFullName(props: UserProfileFullNameProps) {
  return (
    <div className="user-profile__info user-info user-profile__info_full-name">
      <UserProfileFieldTitle label={'full name'} />
      <UserProfileInput
        value={props.value}
        placeholder={'Enter full name...'}
        maxLength={props.maxLength}
        editable={props.editable}
        onUpdate={props.onChange}
        validator={FieldIsRequiredValidator}
      />
    </div>
  )
}
