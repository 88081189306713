import {EditorState, Plugin, PluginKey, TextSelection, Transaction} from 'prosemirror-state'
import {ReactPluginView} from "../../core/react-plugin-view"
import { EditorMenuBar } from "./components"
import { DropDown, MenuItem } from "./utils"

const key = new PluginKey("floating-menu-state")
export const floatingMenuBar = (items: (MenuItem | DropDown | false)[]) => new Plugin({
  key: key,
  state: {
    init: () => ({enable: false}),
    apply: (tr, value, oldState, state) => {
      const meta = tr.getMeta(key)
      if (meta) return { ...value, ...meta }
      const isTextSelection = state.selection instanceof TextSelection
      if (!state.selection.empty && isTextSelection) return {enable: true}
      if (state.selection.empty && value.enable) return {enable: false}
      return value
    }
  },
  view: (view) => new ReactPluginView(
    view, EditorMenuBar, undefined, { items }
  )
})

export const getMenuState = (state: EditorState) => {
  return key.getState(state)
}

export const enableMenu = (tr: Transaction): Transaction => {
  return tr.setMeta(key, {enable: true})
}

export const disableMenu = (tr: Transaction): Transaction => {
  return tr.setMeta(key, {enable: false})
}
