import { applyTransaction, ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { keys, pick } from 'lodash'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { commentDataService, CommentDataService } from './data-service'
import { Comment } from './model'
import { commentQuery, CommentQuery } from './query'
import { commentStore, CommentStore } from './store'

import { optimisticSaveEnabled } from '../../globals/constants'
import { ModelUpdate } from '../../utils/types'
import { messageDispatcher } from '../../utils/message-dispatcher'

export class CommentService {
  constructor(
    private store: CommentStore,
    private query: CommentQuery,
    private dataService: CommentDataService
  ) {}

  loadComments(query?: object) {
    this.store.setLoading(true)
    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
        })
      )
      .subscribe((comments) => {
        applyTransaction(() => {
          this.store.add(comments)
        })
      })
  }

  loadComment(id: ID) {
    this.dataService.get(id).subscribe((comment) => {
      this.store.add(comment)
    })
  }

  /**
   * Create new comment
   * @param comment
   */
  createComment(comment: Partial<Comment>) {
    const result = new Subject<Comment>()

    this.dataService.create(comment).subscribe(
      (comment) => {
        this.store.add(comment)
        result.next(comment)
        result.complete()
        messageDispatcher.putSuccessMessage('Comment has been added')
      },
      (error) => {
        result.error(error)
      }
    )

    return result.asObservable()
  }

  @boundMethod
  updateComment(update: ModelUpdate<Comment>) {
    this.updateOptimistic(update)
    messageDispatcher.putSuccessMessage('Comment has been edited')
  }

  @boundMethod
  destroy(id: ID) {
    const comment: Comment = { ...this.query.getEntity(id) }
    this.store.remove(id)

    this.dataService.destroy(id).subscribe({
      error: () => {
        this.store.add(comment)
      },
    })
  }

  /**
   * We first update the value and then try to send it to the backend.
   *
   * If save fails - we rollback the change.
   *
   */
  private updateOptimistic(update: ModelUpdate<Comment>) {
    const currentComment = this.query.getEntity(update.id)
    const currentValues = pick(currentComment, keys(update))

    if (optimisticSaveEnabled) {
      this.store.update(update.id, update)
    }

    this.dataService.update(update).subscribe({
      next: (value: ModelUpdate<Comment>) => {
        this.store.update(value.id, value)
      },
      error: () => {
        this.store.update(update.id, currentValues)
      },
    })
  }
}

export const commentsService = new CommentService(
  commentStore,
  commentQuery,
  commentDataService
)
