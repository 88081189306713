import { boundMethod } from 'autobind-decorator'
import { finalize } from 'rxjs/operators'
import { DateRange } from '@blueprintjs/datetime'
import moment from 'moment-timezone'
import { ID } from '@datorama/akita'
import { Project } from '../../../entities/project/model'
import {
  ReportProjectDataService,
  reportProjectPageDataService,
} from '../../../entities/report-project/data-service'
import { ReportProjectPageStore, reportProjectPageStore } from './store'

export class ReportProjectPageService {
  constructor(
    private store: ReportProjectPageStore,
    private dataService: ReportProjectDataService
  ) {}

  @boundMethod
  setProject(project: Project | null) {
    this.store.update({ selectedProject: project, selectedUsersIDs: [] })
  }

  @boundMethod
  setUsers(usersIDs: ID[]) {
    this.store.update({ selectedUsersIDs: usersIDs })
  }

  @boundMethod
  setDates(dates: DateRange) {
    this.store.update({ startDate: dates[0], endDate: dates[1] })
  }

  @boundMethod
  clearFilters() {
    this.store.reset()
  }

  resetReport() {
    this.store.update({ report: null })
  }

  fetchReport(
    project: Project,
    usersIDs: ID[],
    startDate: Date,
    endDate: Date
  ) {
    this.store.setLoading(true)
    this.dataService
      .get({
        project: project.id,
        users: usersIDs,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      })
      .pipe(finalize(() => this.store.setLoading(false)))
      .subscribe((report) => {
        this.store.update({ report: report })
      })
  }

  @boundMethod
  setShowFiltersPanel(show: boolean) {
    this.store.update({ showFiltersPanel: show })
  }
}

export const reportProjectService = new ReportProjectPageService(
  reportProjectPageStore,
  reportProjectPageDataService
)
