import React from 'react'
import { Checkbox } from 'antd'
import {PROJECT_STATUSES, ProjectStatus} from "../../entities/project/model";
import {DashboardPageFilterTitle} from "./DashboardPageFilterTitle";


interface DashboardFiltersListCheckboxesProps {
  selectedFilters: ProjectStatus[]
  onStatusFilterChange: (name: ProjectStatus) => void
}

export function DashboardFiltersListCheckboxes(props: DashboardFiltersListCheckboxesProps) {
  return (
    <div className="filters-list__item filters-list__item_status">
      <DashboardPageFilterTitle>Status</DashboardPageFilterTitle>
      {PROJECT_STATUSES.map((statusFilter) => (
        <div key={statusFilter.value} className="filters-list__checkbox">
          <Checkbox
            checked={props.selectedFilters.includes(statusFilter.value)}
            onChange={(e) =>
              props.onStatusFilterChange(statusFilter.value)
            }
          >
            {statusFilter.label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
