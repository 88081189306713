import { EpicHeader } from './EpicListItem'
import { Epic } from '../../../entities/epic/model'
import { TaskGroupItemProps } from '../TaskGroupProps'
import React, { CSSProperties } from 'react'
import { sortBy } from 'lodash'
import { TaskList } from '../../tasks/TaskList'
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { backlogDroppableId, TASKS_IN_OUT_DROP_TYPE } from '../../drag-n-drop'
import { Project } from '../../../entities/project/model'
import { Sprint } from '../../../entities/sprint/model'
import classNames from 'classnames'
import isEqual from 'react-fast-compare'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'

export interface BacklogContainerProps extends TaskGroupItemProps {
  sprints: Sprint[]
  taskTypes: TaskType[]
  filteredSprints: Sprint[]
  project: Project
  style: CSSProperties
  tasksLoading?: boolean
  onToggle: () => void
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

function _BacklogContainer(props: BacklogContainerProps) {
  const tasks = sortBy(props.tasks, 'epic_order')

  let body = <></>

  if (!props.collapsed) {
    body = BacklogBody(props)
  }

  const classes = classNames(
    'task-groups-list__group',
    'task-groups-list__group_backlog',
    {
      'is-collapsed': props.collapsed,
    }
  )

  return (
    <div style={{ ...props.style, paddingTop: 8 }}>
      <div className={classes}>
        <EpicHeader
          epic={{ name: 'Backlog' } as Epic}
          isBacklog={true}
          collapsed={props.collapsed}
          onToggle={props.onToggle}
          onTaskCreate={props.onTaskCreate}
          tasks={tasks}
          notFilteredTasks={tasks}
          onDestroyEpic={() => {}}
          onStatusSelect={() => {}}
        />
        {body}
      </div>
    </div>
  )
}

function BacklogBody(props: BacklogContainerProps) {
  return (
    <div
      className={classNames('task-groups-list__group-body', {
        'task-groups-list__group-body_empty': props.tasksLoading,
      })}
    >
      <DroppableBlankEpicPlaceholder project={props.project}>
        {
          !!props.tasks.length && (
            <TaskList
              tasks={props.tasks}
              sprints={props.sprints}
              taskTypes={props.taskTypes}
              filteredSprints={props.filteredSprints}
              onStartTracking={props.onStartTracking}
              onStopTracking={props.onStopTracking}
              onAddTimeManually={props.onAddTimeManually}
              onTaskUpdate={props.onTaskUpdate}
              location={'epic'}
              currentTask={props.currentTask}
              currentUser={props.currentUser}
              users={props.users}
              addTimeFormErrors={props.addTimeFormErrors}
              isAddTimeFormOpened={props.isAddTimeFormOpened}
              onOpenAddTimeModal={props.onOpenAddTimeModal}
              onCloseAddTimeModal={props.onCloseAddTimeModal}
            />
          ) // : (
          //   <div
          //     className="task-groups-list__spinner-item"
          //     style={{ display: 'flex', justifyContent: 'center' }}
          //   >
          //     <Spin style={{ fontSize: 40, color: 'var(--color-text-link)' }} />
          //   </div>
          // )}
        }
      </DroppableBlankEpicPlaceholder>
    </div>
  )
}

function DroppableBlankEpicPlaceholder({
  project,
  children,
}: {
  project: Project
  children: React.ReactNode
}) {
  return (
    <Droppable
      droppableId={backlogDroppableId(project.id)}
      type={TASKS_IN_OUT_DROP_TYPE}
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot
      ) => (
        <div
          style={{ minHeight: '1px' }}
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          {children}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export const BacklogContainer = React.memo(_BacklogContainer, isEqual)
