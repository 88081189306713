import React from 'react'
import { ID } from '@datorama/akita'

import { FiltersListTitle } from './FiltersListTitle'
import { Sprint } from '../entities/sprint/model'
import { SprintsSuggest } from '../components/controls/SprintsSuggest'

interface FilterListSprintsProps {
  sprints: Sprint[]
  selectedSprintIDs: ID[]
  onChangeSelectedSprintIDs: (sprintsIDs: ID[]) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  noSprintsSelectedPlaceholder?: string
  noUnassignedSprint?: boolean
  maxSelectableItems?: number
}

export function FilterListSprints(props: FilterListSprintsProps) {
  return (
    <>
      <FiltersListTitle>Sprints</FiltersListTitle>
      <div className="filters-list__item filters-list__item_sprints">
        <SprintsSuggest
          closeOnSelect={false}
          sprints={props.sprints}
          selectedSprintIDs={props.selectedSprintIDs}
          onChangeSelectedSprintIDs={props.onChangeSelectedSprintIDs}
          allowSelectAll={props.allowSelectAll}
          emptyListLabel={props.emptyListLabel}
          noSprintsSelectedPlaceholder={props.noSprintsSelectedPlaceholder}
          noUnassignedSprint={props.noUnassignedSprint}
          maxSelectableItems={props.maxSelectableItems}
        />
      </div>
    </>
  )
}
