export interface StatusChoice {
  value: string
  label: string
  color: string
}

export enum StatusColor {
  DRAFT = '#2f54eb',
  ACTIVE = '#389e0d',
  CLOSED = 'var(--color-text-inactive)',
  ONHOLD = 'rgb(192,192,192)',
}

export const STATUSES: StatusChoice[] = [
  { value: 'active', label: 'Active', color: StatusColor.ACTIVE },
  { value: 'closed', label: 'Closed', color: StatusColor.CLOSED },
  { value: 'on-hold', label: 'On Hold', color: StatusColor.ONHOLD },
  { value: 'draft', label: 'Draft', color: StatusColor.DRAFT },
]

export type SprintEpicStatusType = 'active' | 'closed' | 'draft' | 'on-hold'

type StatusMapping = {
  [key in SprintEpicStatusType]: string
}

export const getStatusColorMapping: StatusMapping = {
  active: StatusColor.ACTIVE,
  closed: StatusColor.CLOSED,
  draft: StatusColor.DRAFT,
  'on-hold': StatusColor.ONHOLD,
}
