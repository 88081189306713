import { finalize } from 'rxjs/operators'
import moment from 'moment-timezone'
import { ID } from '@datorama/akita'
import { DateRange } from '@blueprintjs/datetime'
import { boundMethod } from 'autobind-decorator'
import { ReportDevPageStore, reportDevPageStore } from './store'
import {
  ReportDevDataService,
  reportDevPageDataService,
} from '../../../entities/report-dev/data-service'

export class ReportDevPageService {
  constructor(
    private store: ReportDevPageStore,
    private dataService: ReportDevDataService
  ) {}

  setSelectedUserID(userID: ID | null) {
    this.store.update({ selectedUserID: userID })
  }

  setDates(dates: DateRange) {
    this.store.update({ startDate: dates[0], endDate: dates[1] })
  }

  clearFilters() {
    this.store.reset()
  }

  resetReport() {
    this.store.update({ report: null })
  }

  fetchReport(userID: ID, startDate: Date, endDate: Date) {
    this.store.setLoading(true)
    this.dataService
      .get({
        user: userID,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      })
      .pipe(finalize(() => this.store.setLoading(false)))
      .subscribe((report) => {
        this.store.update({ report: report })
      })
  }

  @boundMethod
  setShowFiltersPanel(show: boolean) {
    this.store.update({ showFiltersPanel: show })
  }
}

export const reportDevService = new ReportDevPageService(
  reportDevPageStore,
  reportDevPageDataService
)
