import './styles/main.scss'
import './styles/calendar-page.scss'

import React, { useCallback, useMemo } from 'react'
import { Layout } from 'antd'
import { ID } from '@datorama/akita'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import { EventSourceFunc, EventSourceInput } from '@fullcalendar/core'

import { useQuery } from '../utils/queryhook'
import { authQuery } from '../auth/state/query'
import { calendarEventContent } from '../entities/calendar-event/template'
import { createEventSource } from '../entities/calendar-event/helpers'
import { CalendarFiltersList } from './CalendarFiltersList'
import { userQuery } from '../entities/user/query'
import { calendarPageQuery } from './state/query'
import { User } from '../entities/user/model'
import { FilterButton } from '../components/FilterButton'
import { calendarPageService } from './state/service'
import { Header } from '../components/header/Header'
import { Project } from '../entities/project/model'
import { projectQuery } from '../entities/project/query'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useParams } from 'react-router-dom'
import interactionPlugin from '@fullcalendar/interaction'

export function CalendarPageContainer() {
  const currentUser = useQuery(authQuery.currentUser$)
  const selectedCurrentUserID = useMemo(() => [currentUser.id], [currentUser])
  const users = useQuery(userQuery.users$)
  const selectedUsersIDs =
    useQuery(calendarPageQuery.selectedUsersIDs$) || selectedCurrentUserID
  const showFiltersPanel = useQuery(calendarPageQuery.showFiltersPanel$)
  const projects = useQuery(projectQuery.adminUserProjects$)
  const selectedProject = useQuery(calendarPageQuery.selectedProject$)
  const { workspaceId } = useParams()

  const { timezone } = currentUser

  const eventSource: EventSourceFunc = useCallback(
    createEventSource(selectedUsersIDs, selectedProject, workspaceId),
    [selectedUsersIDs, selectedProject, workspaceId]
  )

  return (
    <CalendarPage
      timezone={timezone}
      eventSources={[{ events: eventSource }]}
      users={users}
      selectedUsersIDs={selectedUsersIDs}
      showFiltersPanel={showFiltersPanel}
      onChangeSelectedUsersIDs={calendarPageService.setUsers}
      onToggleFiltersPanel={calendarPageService.setShowFiltersPanel}
      onResetFilters={calendarPageService.clearFilters}
      projects={projects}
      selectedProject={selectedProject}
      onChangeProject={calendarPageService.setProject}
    />
  )
}

export interface CalendarPageProps {
  timezone: string
  eventSources: EventSourceInput[]
  users: User[]
  selectedUsersIDs: ID[]
  showFiltersPanel: boolean
  projects: Project[]
  selectedProject: Project | null
  onChangeProject: (project: Project | null) => void
  onChangeSelectedUsersIDs: (usersIDs: ID[]) => void
  onToggleFiltersPanel: (show: boolean) => void
  onResetFilters: () => void
}

export function CalendarPage(props: CalendarPageProps) {
  const onDateClick = useCallback((info) => {
    if (info.view.type === 'dayGridMonth') {
      info.view.calendar.changeView('timeGridDay', info.dateStr)
    }
  }, [])

  return (
    <Layout className="calendar-page calendar-tracker">
      <Header title="Calendar" />
      <Layout.Content style={{ display: 'flex', flex: '1 1 0' }}>
        <CalendarFiltersList
          show={props.showFiltersPanel}
          users={props.users}
          selectedUsersIDs={props.selectedUsersIDs}
          onChangeSelectedUsersIDs={props.onChangeSelectedUsersIDs}
          onResetFilters={props.onResetFilters}
          projects={props.projects}
          selectedProject={props.selectedProject}
          onChangeProject={props.onChangeProject}
        />
        <Layout.Content
          style={{
            position: 'relative',
            padding: '16px',
            flex: '1 1 0',
            overflow: 'hidden',
          }}
        >
          <FilterButton
            className="calendar-page__button-filter"
            showFilters={props.showFiltersPanel}
            onToggleFilters={props.onToggleFiltersPanel}
          />
          <FullCalendar
            dateClick={onDateClick}
            defaultAllDay={true}
            eventDidMount={(arg) => {
              if (arg.view.type === 'dayGridMonth' && !arg.event.allDay) {
                arg.el.style.display = 'None'
              }
              if (arg.event.allDay) {
                arg.el.style.pointerEvents = 'none'
              }
            }}
            plugins={[
              dayGridPlugin,
              momentTimezonePlugin,
              timeGridPlugin,
              interactionPlugin,
            ]}
            timeZone={props.timezone}
            eventContent={calendarEventContent}
            showNonCurrentDates={false}
            initialView="dayGridMonth"
            height="100%"
            firstDay={1}
            viewClassNames={(info) => {
              // FIXME: find a better way
              // This is an ugly hack caused by this issue: https://github.com/fullcalendar/fullcalendar/issues/5543#issuecomment-749165437
              // When we switch view from month or week to day, FullCalendar does not refetch events, assuming we already have relevant data
              // However, for optimization reasons, we fetch only { all_day: true } events while in month view, yet we need to load invidual time entries
              // with { all_day: false } when switching to day view
              // The logical place to do this is in viewDidMount, but it doesn't fire when switching to Day view
              // Re-fetching events here seems ugly, but it works
              if (info.view.type === 'timeGridDay') {
                info.view.calendar.refetchEvents()
              }
              return []
            }}
            eventSources={props.eventSources}
            eventOrder={'user_full_name'}
            headerToolbar={{
              left: 'dayGridMonth,timeGridWeek,timeGridDay',
              center: 'prev,title,next',
              right: 'today',
            }}
            views={{
              day: {
                type: 'dayGrid',
                buttonText: 'day',
              },
            }}
            slotLabelFormat={{
              hour: `2-digit`,
              minute: `2-digit`,
              hour12: false,
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
          />
        </Layout.Content>
      </Layout.Content>
    </Layout>
  )
}
