import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { Sprint } from './model'

export interface SprintState extends EntityState<Sprint, ID> {
  loaded: string[]
}

@StoreConfig({ name: 'entity-sprints' })
export class SprintStore extends EntityStore<SprintState> {}

export const sprintStore = new SprintStore({ loading: false, loaded: [] })
