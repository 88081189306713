import React from 'react'

import '../../styles/project-tile.scss'

interface UserProfileProjectProps {
  children: React.ReactNode
}

export function UserProfileProject(props: UserProfileProjectProps) {
  return (
    <div className="user-profile__project project-tile">{props.children}</div>
  )
}
