import { MenuItem } from '@blueprintjs/core'
import { ItemRenderer, Select } from '@blueprintjs/select'
import classNames from 'classnames'
import React from 'react'

interface SwitchItems {
  id: string
  value: string
}

const ITEMS: SwitchItems[] = [
  {
    id: 'show',
    value: 'Show',
  },
  {
    id: 'hide',
    value: 'Hide',
  },
]

export type EpicDetailsSwitchItem = 'hide' | 'show'

const TypedEpicTasksSelect = Select.ofType<SwitchItems>()

interface EpicDetailsSwitcherSelectProps {
  selectedItem: string
  onChange: () => void
}

export function EpicDetailsSwitcherSelect(
  props: EpicDetailsSwitcherSelectProps
) {
  const renderItem: ItemRenderer<SwitchItems> = (
    item: SwitchItems,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        className={classNames({ 'bp3-active': item.id === props.selectedItem })}
        key={item.id}
        onClick={handleClick}
        text={item.value}
      />
    )
  }

  const selected_obj = ITEMS.find((item) => item.id === props.selectedItem)

  return (
    <TypedEpicTasksSelect
      onItemSelect={props.onChange}
      itemRenderer={renderItem}
      items={ITEMS}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover popover_center-text',
      }}
    >
      <span className="task-groups-switcher-select__target">
        {selected_obj?.value}
      </span>
    </TypedEpicTasksSelect>
  )
}
