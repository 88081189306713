import {Redirect, Route} from "react-router-dom";
import {NotFound} from "../components/error-pages/ErrorPages.stories";
import React from "react";
import {useQuery} from "../utils/queryhook";
import {workspaceQuery} from "../entities/workspace/query";

interface LegacyReportUrlRedirectProps {
  reportType?: 'devtime' | 'devtime2' | 'sprint'
}


export function LegacyReportUrlRedirect({ reportType }: LegacyReportUrlRedirectProps) {
  const workspaces = useQuery(workspaceQuery.workspaces$)

  switch (reportType) {
    case 'sprint':
      return <Redirect to={`./${workspaces[0].id}/report/project`}/>
    case "devtime":
    case "devtime2":
      return <Redirect to={`./${workspaces[0].id}/report/dev`}/>
    default:
      return <Route component={NotFound}/>
  }
}
