import React from 'react'
import { ErrorNumber } from '../error-number/ErrorNumber'
import { ErrorPageMessage } from '../error-page-message/ErrorPageMessage'
import './styles/error-page-layout.scss'

interface ErrorPageLayoutProps {
  errorNumber?: string | number
  children?: React.ReactNode
  errorMessage: string
}

export function ErrorPageLayout(props: ErrorPageLayoutProps) {
  return (
    <div className="error-page-layout">
      {props.errorNumber && <ErrorNumber className="error-page-layout__number" number={props.errorNumber} />}
      <ErrorPageMessage className="error-page-layout__message" message={props.errorMessage} />
      <div className="error-page-layout__content">{props.children}</div>
    </div>
  )
}
