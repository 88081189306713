import {SubtaskStatus, SubtaskType} from "../subtask/model";
import {HistoryActivityItemType} from "../../history/state/model";

export interface TaskPanelFilterChoice {
  value: string
  label: string
}

export interface ActivityFilterChoice {
  value: HistoryActivityItemType
  label: string
}

export interface SubtaskTypeChoice {
  value: SubtaskType
  label: string
}

export interface SubtaskStatusChoice {
  value: SubtaskStatus
  label: string
  subtak_type: SubtaskType
  color: string
}

export const SUBTASK_FILTER_TYPES: SubtaskTypeChoice[] = [
  { value: SubtaskType.GENERAL, label: 'General' },
  { value: SubtaskType.BLOCKER, label: 'Blockers' },
  { value: SubtaskType.BUG, label: 'Bugs' },
]

const SUBTASK_STATUSES: SubtaskStatusChoice[] = [
  { value: SubtaskStatus.NEW, label: 'New', subtak_type: SubtaskType.GENERAL, color: 'rgb(151, 160, 175)' },
  { value: SubtaskStatus.IN_PROGRESS, label: 'In Progress', subtak_type: SubtaskType.GENERAL, color: 'rgb(101, 84, 192)' },
  { value: SubtaskStatus.DONE, label: 'Done', subtak_type: SubtaskType.GENERAL, color: 'rgb(54, 179, 126)' },
  { value: SubtaskStatus.NEW, label: 'New', subtak_type: SubtaskType.BLOCKER, color: 'rgb(151, 160, 175)' },
  { value: SubtaskStatus.IN_PROGRESS, label: 'In Progress', subtak_type: SubtaskType.BLOCKER, color: 'rgb(101, 84, 192)' },
  { value: SubtaskStatus.DONE, label: 'Done', subtak_type: SubtaskType.BLOCKER, color: 'rgb(54, 179, 126)' },
  { value: SubtaskStatus.DRAFT, label: 'Draft', subtak_type: SubtaskType.BUG, color: 'rgb(7, 71, 166)' },
  { value: SubtaskStatus.TODO, label: 'To Do', subtak_type: SubtaskType.BUG, color: 'rgb(76, 154, 255)' },
  { value: SubtaskStatus.IN_PROGRESS, label: 'In Progress', subtak_type: SubtaskType.BUG, color: 'rgb(101, 84, 192)' },
  { value: SubtaskStatus.ON_REVIEW, label: 'On Review', subtak_type: SubtaskType.BUG, color: 'rgb(0, 184, 217)' },
  { value: SubtaskStatus.QA, label: 'QA', subtak_type: SubtaskType.BUG,  color: 'rgb(255, 153, 31)'},
  { value: SubtaskStatus.ACCEPTED, label: 'Accepted', subtak_type: SubtaskType.BUG, color: 'rgb(54, 179, 126)' },
  { value: SubtaskStatus.CANT_REPRODUCE, label: 'Cannot Reproduce', subtak_type: SubtaskType.BUG, color: 'rgb(255, 86, 48)' },
  { value: SubtaskStatus.TO_CLARIFY, label: 'To Clarify', subtak_type: SubtaskType.BUG, color: 'rgb(191, 38, 0)' },
  { value: SubtaskStatus.NOT_A_BUG, label: 'Not a Bug', subtak_type: SubtaskType.BUG, color: 'rgb(76, 154, 255)' },
]

const DEFAULT_SUBTASK_STATUSES: SubtaskStatusChoice[] = [
  { value: SubtaskStatus.NEW, label: 'New', subtak_type: SubtaskType.GENERAL, color: 'rgb(151, 160, 175)' },
  { value: SubtaskStatus.NEW, label: 'New', subtak_type: SubtaskType.BLOCKER, color: 'rgb(151, 160, 175)' },
  { value: SubtaskStatus.DRAFT, label: 'Draft', subtak_type: SubtaskType.BUG, color: 'rgb(7, 71, 166)' },
]

export const statusesForType = (type: SubtaskType) =>
  SUBTASK_STATUSES.filter(({ subtak_type }) => subtak_type.includes(type))

export const defaultStatusForType = (type: SubtaskType) =>
  DEFAULT_SUBTASK_STATUSES.filter(({ subtak_type }) => subtak_type.includes(type))[0]

export const SUBTASK_GENERAL_STATUSES = statusesForType(SubtaskType.GENERAL)
export const SUBTASK_BLOCKER_STATUSES = statusesForType(SubtaskType.BLOCKER)
export const SUBTASK_BUG_STATUSES = statusesForType(SubtaskType.BUG)

export const SUBTASK_FILTER_STATUSES: SubtaskStatusChoice[] = [
  ...SUBTASK_GENERAL_STATUSES,
  ...SUBTASK_BUG_STATUSES,
]

export const TASK_ACTIVITIES_FILTERS: ActivityFilterChoice[] = [
  { value: HistoryActivityItemType.COMMENT, label: 'Comments' },
  { value: HistoryActivityItemType.WORKLOG, label: 'Worklog' },
  { value: HistoryActivityItemType.ACTIVITY, label: 'Activities' },
]

export const PROJECT_ACTIVITIES_FILTERS: ActivityFilterChoice[] = [
  { value: HistoryActivityItemType.WORKLOG, label: 'Worklog' },
  { value: HistoryActivityItemType.ACTIVITY, label: 'Activities' },
]
