export enum ReportTypeEnum {
  ACTIVITY = 'activity',
  T_SHIRT = 'tshirt',
  VELOCITY = 'velocity',
  // FIXME: uncomment when more report types are implemented
  // TASK_TYPE = 'task_type',
}

export type ReportType =
  | ReportTypeEnum.ACTIVITY
  | ReportTypeEnum.T_SHIRT
  | ReportTypeEnum.VELOCITY
// export type ReportType = ReportTypeEnum.ACTIVITY | ReportTypeEnum.TASK_TYPE | ReportTypeEnum.T_SHIRT | ReportTypeEnum.VELOCITY

export interface ReportTypeOption {
  value: ReportType
  label: string
}

export const ReportTypeOptions: ReportTypeOption[] = [
  { value: ReportTypeEnum.ACTIVITY, label: 'Activity shares' },
  { value: ReportTypeEnum.T_SHIRT, label: 'T-shirt sizes' },
  { value: ReportTypeEnum.VELOCITY, label: 'Velocity report' },
  // FIXME: uncomment when more report types are implemented
  // { value: ReportTypeEnum.TASK_TYPE, label: 'Task type shares' },
]

export interface ReportStatisticsActivity {
  report_type: 'statistics_activity'
  stats: {
    [key: string]: string | null
  }
  total_time_spent: string | null
  project_name?: string | null
}

export interface ReportStatisticsTshirtEntry {
  assignee: number
  assignee_name: string
  role: string

  sum_time: string
  sum_time_u: string
  sum_time_xs: string
  sum_time_s: string
  sum_time_m: string
  sum_time_l: string
  sum_time_xl: string

  task_count_u: string
  task_count_xs: string
  task_count_s: string
  task_count_m: string
  task_count_l: string
  task_count_xl: string

  avg_time_u: string
  avg_time_xs: string
  avg_time_s: string
  avg_time_m: string
  avg_time_l: string
  avg_time_xl: string
}

export interface ReportStatisticsTshirt {
  report_type: 'statistics_tshirt'
  project_name?: string
  stats: ReportStatisticsTshirtEntry[]
}

export interface ReportStatisticsVelocityEntry {
  sprint_id: number
  sprint_name: string
  sprint_start: Date
  sprint_end: Date
  task_count: number
  velocity: number
  sprint_naive_duration: string
  sprint_naive_days: number
  completed_work: string
  performance_per_day: string
  performance_per_sprint: string
  workdays_last_month: number
  workdays_in_sprint: number
  [key: string]: any
}

export interface ReportStatisticsVelocity {
  report_type: 'statistics_tshirt'
  project_name?: string
  stats: ReportStatisticsVelocityEntry[]
}

export type ReportStatistics =
  | ReportStatisticsActivity
  | ReportStatisticsTshirt
  | ReportStatisticsVelocity
