import OrderedMap from "orderedmap";
import {NodeSpec, Schema} from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import {Suggester} from "prosemirror-suggest"

import { Extension } from "../.."
import {createReactNodeView} from "../../core/react-node-view"
import { NodeViewsSpec } from "../../core/types"

import { MentionNodeSpec } from "./nodes"
import { mentionSuggester } from "./suggesters"
import { MentionPlugin, MentionPluginKey } from "./plugins"
import { BaseMentionProvider } from "./provider"
import { EditorDialog, Mention } from "./components"

export class MentionExtension extends Extension {
  userMention: MentionPluginKey

  constructor(
    private provider: BaseMentionProvider
  ) {
    super()
    this.userMention = new MentionPluginKey("@")
  }

  addNodes = (nodes: OrderedMap<NodeSpec>): OrderedMap<NodeSpec> => {
    return nodes.append({ mention: MentionNodeSpec });
  }

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    MentionPlugin(this.userMention, EditorDialog)
  ]

  addNodeViews = (schema: Schema, nodeViews: NodeViewsSpec): NodeViewsSpec => ({
    ...nodeViews,
    mention: (node, view, getPos, decorations) => {
      return createReactNodeView({
        node, view, getPos, decorations, component: Mention
      })
    }
  })

  addProviders = (providers: {[key: string]: any}) => ({
    ...providers, mentionProvider: this.provider
  })

  addSuggesters = (suggesters: Suggester[]): Suggester[] => [
    ...suggesters,
    mentionSuggester(this.userMention)
  ]
}
