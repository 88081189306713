import './styles/filters.scss'

import React from 'react'
import { Project } from '../entities/project/model'
import { UserStatus } from '../entities/user/model'
import { FiltersListCheckboxes } from './FiltersListCheckboxes'
import { FiltersListProjects } from './FiltersListProjects'
import { FiltersListLayout } from '../components/filters-list-layout/FiltersListLayout'

interface FilterListProps {
  show: boolean
  projects: Project[]
  projectFilter: Project | null
  availableStatusFilters: UserStatus[]
  statusFilters: UserStatus[]
  onSelectProject: (project: Project | null) => void
  onStatusFilterChange: (name: UserStatus, apply: boolean) => void
  onResetFilters: () => void
}

export function FiltersList(props: FilterListProps) {
  return (
    <FiltersListLayout onResetFilters={props.onResetFilters} show={props.show}>
      <FiltersListProjects
        projects={props.projects}
        selectedProject={props.projectFilter}
        onSelectProject={props.onSelectProject}
      />
      <FiltersListCheckboxes
        availableStatusFilters={props.availableStatusFilters}
        statusFilters={props.statusFilters}
        onStatusFilterChange={props.onStatusFilterChange}
      />
    </FiltersListLayout>
  )
}
