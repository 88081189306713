import React from 'react'
import { UsersListSearch } from './UsersListSearch'
import { UsersListFilterButton } from './UsersListFilterButton'

interface UsersListHeaderProps {
  placeholder: string
  searchQuery: string
  onSearch: (query: string) => void
  showFiltersPanel: boolean
  setShowFiltersPanel: (show: boolean) => void
}

export function UsersListHeader(props: UsersListHeaderProps) {
  return (
    <div className="users-list__header">
      <UsersListFilterButton
        showFiltersPanel={props.showFiltersPanel}
        setShowFiltersPanel={props.setShowFiltersPanel}
      />
      <UsersListSearch
        showFiltersPanel={props.showFiltersPanel}
        placeholder={props.placeholder}
        searchQuery={props.searchQuery}
        onSearch={props.onSearch}
      />
    </div>
  )
}
