import { Button } from 'antd'
import React from 'react'
import './styles/change-user-password-button.scss'

interface ChangeUserPasswordButtonProps {
  className?: string
  onDialogOpen: () => void
}

export function ChangeUserPasswordButton(props: ChangeUserPasswordButtonProps) {
  return (
    <Button type="primary" onClick={props.onDialogOpen}>
      Change Password
    </Button>
  )
}
