import React from 'react'
import { RemoveProjectMemberAction } from '../../../entities/activities/model'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";

interface RemoveProjectMemberActivityProps {
  action: RemoveProjectMemberAction
  date: string
  user: User | string
}

export function RemoveProjectMemberActivity(props: RemoveProjectMemberActivityProps) {
  const { action, user } = props

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> removed{' '}
      <ActivityBoldText>{action.data.changed_from.label}</ActivityBoldText>{' '}from this project.
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
