import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { BehaviorSubject, Subject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import moment from 'moment-timezone'
import { WebSocketMessage } from './types'
import { pushMessageManager } from './push-message-manager'

export class WebSocketConnection<T> {
  /**
   * Actual socket connection.
   *
   * Deleted when connection is closed. Replaced on reconnection.
   */
  private connectionSubject: WebSocketSubject<T> | null = null

  /**
   * Observable of messages received from the socket.
   *
   * Survives through reconnections.
   */
  private wrapperSubject = new Subject<T>()
  public messages = this.wrapperSubject.asObservable()

  /**
   * Connection lost state observable.
   */
  private connectionLostSubject = new BehaviorSubject<boolean>(false)
  public connectionLost = this.connectionLostSubject
    .asObservable()
    .pipe(distinctUntilChanged())

  /**
   * Observable that emits every time connection is established.
   */
  private connectedSubject = new Subject<void>()
  public connected = this.connectedSubject.asObservable()

  reconnectionInterval = 5000

  init() {
    this.connect()
  }

  private previous_conn_error = moment()

  private connect() {
    let url = new URL('/ws/', window.location.href)
    url.protocol = url.protocol.replace('http', 'ws')

    this.connectionSubject = webSocket({
      url: url.href,
      openObserver: {
        next: () => {
          this.connectionLostSubject.next(false)
          this.connectedSubject.next()
        },
      },
      closeObserver: {
        next: () => {
          this.connectionLostSubject.next(true)
          this.connectionSubject = null
          setTimeout(() => this.connect(), this.reconnectionInterval)
        },
      },
    })

    this.connectionSubject.subscribe(
      (message) => {
        this.wrapperSubject.next(message)
      },
      () => {
        if (
          window !== undefined &&
          process.env.NODE_ENV !== 'development' &&
          moment().diff(this.previous_conn_error, 'seconds') > 60
        ) {
          pushMessageManager.createNotification({
            title: 'Timer connection issue',
            body: 'WebSocket connection failed',
          })
          this.previous_conn_error = moment()
        }
      }
    )
  }

  send(message: T) {
    if (!!this.connectionSubject) {
      this.connectionSubject.next(message)
    }
  }
}

export const websocketConnection = new WebSocketConnection<WebSocketMessage>()
