import React, { Ref, useCallback, useMemo } from 'react'
import { Typography } from 'antd'
import { ReportDev } from '../../entities/report-dev/model'
import { ReportTableComponent } from '../ReportTableComponent'
import { ParseReportDevData } from './parser'

export const reportDevTableColumns = [
  {
    title: 'Project',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'ID',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Object',
    dataIndex: 'activity_name',
    key: 'activity_name',
  },
  {
    title: 'Time',
    dataIndex: 'time_logged',
    key: 'time_logged',
  },
  {
    title: 'Totals',
    dataIndex: 'total',
    key: 'total',
  },
]

interface ReportDevComponentProps {
  report: ReportDev | null
  tableContainerRef: Ref<HTMLDivElement>
}

export function ReportDevComponent(props: ReportDevComponentProps) {
  const { report } = props
  const userFullName = report?.user_full_name

  const reportData = useMemo(() => {
    if (report == null) {
      return []
    }
    return ParseReportDevData(report)
  }, [report])

  const titleCallback = useCallback(() => {
    if (userFullName == null) {
      return <></>
    }
    return (
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        Time report: {userFullName}
      </Typography.Title>
    )
  }, [userFullName])

  const rowClassNameCallback = (record: any) => {
    switch (record.type) {
      case 'total':
      case 'project':
        return 'table-row-title'

      case 'summary':
        return 'table-row-bold'
    }

    return ''
  }

  return (
    <ReportTableComponent
      tableColumns={reportDevTableColumns}
      reportData={reportData}
      titleCallback={titleCallback}
      rowClassNameCallback={rowClassNameCallback}
      tableContainerRef={props.tableContainerRef}
    />
  )
}
