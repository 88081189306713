import { ID, QueryEntity } from '@datorama/akita'
import {
  TimeEntryState,
  timeEntryStore,
  TimeEntryStore
} from './store'
import {switchMap} from "rxjs/operators";
import {subtaskQuery, SubtaskQuery} from "../subtask/query";

export class TimeEntryQuery extends QueryEntity<TimeEntryState> {
  constructor(
    protected store: TimeEntryStore,
    private subtaskQuery: SubtaskQuery,
  ) {
    super(store)
  }

  forUser(userId: ID | null) {
    return this.selectAll({
      filterBy: (timeEntry) => timeEntry.user === userId,
    })
  }

  forTask(taskID: ID | null) {
    return this.selectAll({
      filterBy: (timeEntry) => timeEntry.target.type === "task" && timeEntry.target.id === taskID
    })
  }

  selectBySubTaskID(taskId: ID) {
    return this.subtaskQuery.forTaskWithAllSubtasks(taskId).pipe(
      switchMap(subtasks => {
        const subtaskIds = subtasks.map(subtask => subtask.id)
        return this.selectAll({
          filterBy: (timeEntry) =>
            timeEntry.target.type === 'subtask' &&  subtaskIds.includes(timeEntry.target.id)
        })
        }
      )
    )
  }

  forEpic(epicID: ID | null) {
    return this.selectAll({
      filterBy: (timeEntry) => timeEntry.target.type === "epic" && timeEntry.target.id === epicID,
    })
  }

  forProject(projectID: ID | null) {
    return this.selectAll({
      filterBy: (timeEntry) => timeEntry.target.type === "project" && timeEntry.target.id === projectID,
    })
  }

  forSprint(sprintID: ID | null) {
    return this.selectAll({
      filterBy: (timeEntry) => timeEntry.target.type === "sprint" && timeEntry.target.id === sprintID,
    })
  }

}

export const timeEntryQuery = new TimeEntryQuery(
  timeEntryStore,
  subtaskQuery
)
