import classNames from 'classnames'
import React from 'react'

interface DashboardPageFilterTitleProps {
  className?: string
  children: React.ReactNode
}

export function DashboardPageFilterTitle(props: DashboardPageFilterTitleProps) {
  const classes = classNames('project-page-filter-title', props.className)
  return <div className={classes}>{props.children}</div>
}
