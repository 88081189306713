import React, { useRef } from 'react'
import { SpriteIcon } from '../../../../components/ui/sprite-icon/SpriteIcon'
import Icons from '../../../../assets/icons/editor/sprite.svg'

import './styles/editor-footer.scss'
import { Button } from 'antd'

interface FooterItem {
  icon: string
  onClick: () => void
}

interface FooterProps {
  items: FooterItem[]
  onSubmit?: () => void
}

export const Footer = ({ items, onSubmit }: FooterProps) => {
  const ref = useRef<any>(null)
  return (
    <div
      ref={ref}
      tabIndex={0}
      className="editor-footer"
      onFocus={() => {
        ref.current.parentNode.parentNode.classList.add('is-focused')
      }}
      onBlur={() => {
        ref.current.parentNode.parentNode.classList.remove('is-focused')
      }}
    >
      <div className="editor-footer__left">
        {items.map(({ icon, onClick }, index) => (
          <button
            className="editor-footer__action-button"
            type="button"
            key={index}
            onClick={onClick}
          >
            <SpriteIcon name={icon} spritePath={Icons} />
          </button>
        ))}
      </div>
      {onSubmit ? (
        <Button type="primary" onClick={onSubmit}>
          Send
        </Button>
      ) : null}
    </div>
  )
}
