import { WorkspaceStore, workspaceStore } from './store'
import { WorkspacePageStore, workspacePageStore } from '../../dashboard-page/state/store'
import { WorkspaceDataService, workspaceDataService } from './data-service'
import { finalize } from 'rxjs/operators'
import { applyTransaction, ID } from '@datorama/akita'

export class WorkspaceService {
  constructor(
    private store: WorkspaceStore,
    private dataService: WorkspaceDataService,
    private workspacePageStore: WorkspacePageStore
  ) {}

  setActive(id: ID | null) {
    this.store.setActive(id)
  }

  loadWorkspace(id: ID) {
    this.dataService.get(id).subscribe((workspace) => {
      this.store.add(workspace)
    })
  }

  loadWorkspaces() {
    this.store.setLoading(true)
    this.dataService
      .list()
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
        })
      )
      .subscribe((workspaces) => {
        applyTransaction(() => {
          this.store.add(workspaces)
          this.store.update({ loaded: true })
        })
      })
  }
}

export const workspaceService = new WorkspaceService(
  workspaceStore,
  workspaceDataService,
  workspacePageStore
)
