import React from 'react'
import { TrackerControl } from '../../../tracking/TrackerControl'
import {
  getActivityChoice,
  TASK_ACTIVITIES,
} from '../../../entities/choices/activity'
import '../styles/task-panel-bar.scss'
import { EstimatedTimeCollection, Task } from '../../../entities/task/model'
import {
  ReadableTimeString,
  ReadableTime,
} from '../../../tracking/TimeConverters'
import { Popover } from 'antd'
import { AddTimeManuallyObject } from '../../../tracking/types'
import { User } from '../../../entities/user/model'
import { IFormErrors } from '../../state/store'

interface TaskPanelSizeEstimationProps {
  task: Task
  onStartTracking: (activity: string) => void
  onStopTracking: () => void
  onAddTimeManually: (data: AddTimeManuallyObject) => void
  currentUser: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function TaskPanelSizeEstimation({
  task,
  onStartTracking,
  onStopTracking,
  onAddTimeManually,
  currentUser,
  addTimeFormErrors,
  isAddTimeFormOpened,
  onOpenAddTimeModal,
  onCloseAddTimeModal,
}: TaskPanelSizeEstimationProps) {
  return (
    <div className="task-panel-bar__shirt-estimation">
      <span className={'task-panel-bar__estimation-current'}>
        <TrackerControl
          trackingObject={task}
          trackingObjectType="task"
          seconds={task.time_logged}
          onStop={onStopTracking}
          onStart={onStartTracking}
          onAddTimeManually={onAddTimeManually}
          currentUser={currentUser}
          activities={TASK_ACTIVITIES}
          showHintTooltip={true}
          hintTooltipProps={{
            placement: 'bottom',
            content: 'Total spent time',
          }}
          addTimeFormErrors={addTimeFormErrors}
          isAddTimeFormOpened={isAddTimeFormOpened}
          onOpenAddTimeModal={onOpenAddTimeModal}
          onCloseAddTimeModal={onCloseAddTimeModal}
        />{' '}
      </span>
      <span className="task-panel-bar__slash">/ </span>
      <EstimatesPopover estimated_time={task.estimated_time} />
    </div>
  )
}

function EstimatesPopover({
  estimated_time,
}: {
  estimated_time: EstimatedTimeCollection
}) {
  if (!estimated_time || !estimated_time.total) {
    return <ReadableTime seconds={0} />
  }

  const total = {
    minEstimatedTime: ReadableTimeString(
      estimated_time.total.min_estimated_time
    ),
    maxEstimatedTime: ReadableTimeString(
      estimated_time.total.max_estimated_time
    ),
  }
  return (
    <Popover
      title="Task T-Shirt Estimates"
      content={EstimatesList(estimated_time)}
      trigger="hover"
    >
      <span>{total.maxEstimatedTime}</span>
    </Popover>
  )
}

function EstimatesList(estimated_time: EstimatedTimeCollection) {
  const activities = estimated_time.activities.map((value) => {
    return {
      activity: getActivityChoice(value.activity)?.label,
      minEstimatedTime: ReadableTimeString(value.min_estimated_time),
      maxEstimatedTime: ReadableTimeString(value.max_estimated_time),
    }
  })
  const total = {
    minEstimatedTime: ReadableTimeString(
      estimated_time.total.min_estimated_time
    ),
    maxEstimatedTime: ReadableTimeString(
      estimated_time.total.max_estimated_time
    ),
  }
  const totalRepr = `Total: ${total.minEstimatedTime}-${total.maxEstimatedTime}`
  return (
    <div>
      <div>
        <strong>{totalRepr}</strong>
      </div>
      {activities.map((value, index) => {
        return (
          <div
            key={index}
          >{`${value.activity}: ${value.minEstimatedTime}-${value.maxEstimatedTime}`}</div>
        )
      })}
    </div>
  )
}
