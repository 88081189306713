import React, { useCallback, useContext, useMemo } from 'react'
import {
  IItemRendererProps,
  ItemListRenderer,
  Select,
} from '@blueprintjs/select'
import {
  sortAlphabetically,
  User,
  UserRole,
} from '../../../entities/user/model'
import { ID } from '@datorama/akita'
import { Icon, Menu } from '@blueprintjs/core'
import { Empty } from 'antd'
import classNames from 'classnames'
import { UserAvatar } from '../../../components/UserAvatar'
import { IconNames } from '@blueprintjs/icons'
import { CurrentUserContext } from '../../../auth/currentUserContext'

import './styles/members-popover.scss'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'

interface ProjectDetailsMembersSelectProps {
  members: User[]
  selectedMembersIDs: ID[]
  onSelect: (memberId: ID) => void
  filterPredicate?: (query: string, user: User) => boolean
}

const TypedMembersSelect = Select.ofType<User>()

export function ProjectDetailsMembersSelect(
  props: ProjectDetailsMembersSelectProps
) {
  const { filterPredicate = () => true, onSelect } = props
  const currentUser = useContext(CurrentUserContext)!
  const onItemSelect = useCallback(
    (member: User) => onSelect(member.id),
    [onSelect]
  )

  const members = useMemo(() => {
    const _members = props.members.filter((member) => {
      return !props.selectedMembersIDs.includes(member.id)
    })
    return sortAlphabetically([..._members]) as User[]
  }, [props.members, props.selectedMembersIDs])

  const filterUser = useCallback(
    (query: string, user: User) => {
      const matchString = (user.full_name || user.email).toLowerCase()
      if (
        user.id === (currentUser && currentUser.id) &&
        'me'.includes(query.trim().toLowerCase())
      ) {
        return true
      }
      if (user.is_active) {
        return matchString.indexOf(query.toLowerCase()) >= 0
      }
      return matchString === query.trim().toLowerCase()
    },
    [currentUser]
  )

  const _filterPredicate = useCallback(
    (query: string, user: User) =>
      filterUser(query, user) && filterPredicate(query, user),
    [filterPredicate, filterUser]
  )

  const renderMember = (member: User, renderProps: IItemRendererProps) => {
    const { modifiers, handleClick } = renderProps

    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <li
        className={classNames('bp3-menu-item', {
          'bp3-active': modifiers.active,
        })}
        onClick={handleClick}
        key={member.id}
      >
        <div className="user-select__list-item">
          <div className="user-select__list-avatar">
            <UserAvatar user={member} shape={'square'} size={22} />
          </div>
          <div
            className="user-select__list-full-name bp3-text-overflow-ellipsis"
            title={member.full_name || member.email}
          >
            {member.full_name || member.email}{' '}
          </div>
        </div>
      </li>
    )
  }

  const itemListRenderer: ItemListRenderer<User> = ({
    items,
    renderItem,
    itemsParentRef,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item !== null)

    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={400}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  return (
    <TypedMembersSelect
      itemListRenderer={itemListRenderer}
      items={members}
      onItemSelect={onItemSelect}
      itemRenderer={renderMember}
      itemPredicate={_filterPredicate}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover user-popover members-popover',
        targetTagName: 'div',
        wrapperTagName: 'div',
      }}
      inputProps={{
        placeholder: 'Enter Member Name...',
      }}
      noResults={
        <Empty imageStyle={{ height: 60 }} description={'No Results'} />
      }
    >
      {currentUser?.user_role === UserRole.ADMIN && (
        <div className="members-select-target">
          <Icon
            style={{ cursor: 'pointer' }}
            iconSize={16}
            color="var(--color-text-secondary)"
            icon={IconNames.PLUS}
          />
        </div>
      )}
    </TypedMembersSelect>
  )
}
