import React, { Ref } from 'react'
import {
  ReportStatistics,
  ReportStatisticsVelocity,
} from '../../entities/report-statistics/model'
import {
  safeParseFloat,
  ReadableTimeStringNoZero,
} from '../../tracking/TimeConverters'

interface ReportStatisticsVelocityProps {
  report: ReportStatistics
  tableContainerRef: Ref<HTMLDivElement>
}

interface ReportStatisticsVelocityRow {
  sprint_id: number
  sprint_name: string
  sprint_start: Date
  sprint_end: Date
  task_count: string
  velocity: string
  sprint_naive_duration: string
  sprint_naive_days: number
  completed_work: string
  performance_per_day: string
  performance_per_sprint: string
  workdays_last_month: number
  workdays_in_sprint: number
  [key: string]: any
}

export function ReportStatisticsVelocityComponent(
  props: ReportStatisticsVelocityProps
) {
  const report = props.report as ReportStatisticsVelocity

  const stats: ReportStatisticsVelocityRow[] = report.stats.map((item) => {
    return {
      sprint_name: item.sprint_name,
      sprint_start: item.sprint_start,
      sprint_end: item.sprint_end,
      sprint_id: item.sprint_id,
      task_count: item.task_count ? item.task_count.toString() : '',
      velocity: ReadableTimeStringNoZero(item.velocity, true),
      sprint_naive_duration: ReadableTimeStringNoZero(
        safeParseFloat(item.sprint_naive_duration),
        true
      ),
      sprint_naive_days: item.sprint_naive_days,
      completed_work: ReadableTimeStringNoZero(
        safeParseFloat(item.completed_work),
        true
      ),
      performance_per_day: ReadableTimeStringNoZero(
        safeParseFloat(item.performance_per_day),
        true
      ),
      performance_per_sprint: ReadableTimeStringNoZero(
        safeParseFloat(item.performance_per_sprint),
        true
      ),
      workdays_last_month: item.workdays_last_month,
      workdays_in_sprint: item.workdays_in_sprint,
    }
  })

  const rowHeadOrder = [['sprint_name', 'Name']]
  const rowOrder = [
    ['workdays_in_sprint', 'Days in sprint'],
    ['task_count', 'Task count'],
    ['velocity', 'Velocity'],
    ['completed_work', 'Completed work'],
    ['performance_per_day', 'Performance per day'],
    ['performance_per_sprint', 'Performance per sprint'],
  ]

  const headRows = rowHeadOrder.map((item) => {
    const [key, label] = item
    const sprintCells = stats.map((sprint) => {
      return (
        <th className="ant-table-cell" key={sprint.sprint_id}>
          <strong>{sprint[key]}</strong>
        </th>
      )
    })
    return (
      <tr key={key} className="ant-table-row">
        <th className="ant-table-cell">
          <strong>{label}</strong>
        </th>
        {sprintCells}
      </tr>
    )
  })

  const rows = rowOrder.map((item) => {
    const [key, label] = item
    const sprintCells = stats.map((sprint) => {
      return (
        <td className="ant-table-cell" key={sprint.sprint_id}>
          {sprint[key]}
        </td>
      )
    })
    return (
      <tr key={key} className="ant-table-row">
        <td className="ant-table-cell">
          <strong>{label}</strong>
        </td>
        {sprintCells}
      </tr>
    )
  })

  return (
    <div className="ant-table-wrapper">
      <div className="ant-table ant-table-bordered">
        <div className="ant-table-container">
          <div className="ant-table-content">
            <table className="sprint-details-statistics-table">
              <thead className="ant-table-thead sprint-details-statistics-table__head">
                {headRows}
              </thead>
              <tbody className="ant-table-tbody">{rows}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
