import React from 'react'
import { ID } from '@datorama/akita'
import { ShortUser, User } from '../../entities/user/model'
import { ProjectPageFilterContent } from './ProjectPageFilterContent'
import './styles/project-page-filter-portal.scss'
import { TaskStatus } from '../../entities/task/model'
import { SprintStatus } from '../../entities/sprint/model'
import { EpicStatus } from '../../entities/epic/model'
import classNames from 'classnames'
import { CustomScrollbar } from '../../components/custom-scrollbar/CustomScrollbar'
import { TaskType } from '../../entities/task-type/model'

interface ProjectPageFilterContainerProps {
  users: User[] | ShortUser[]
  taskTypes: TaskType[]
  isActive: boolean
  onFilterTasksByStatus: (value: TaskStatus) => void
  onFilterTasksByAssignee: (assignee: ID[]) => void
  onFilterSprintsByStatus: (status: SprintStatus) => void
  onFilterEpicsByStatus: (status: EpicStatus) => void
  onFilterTasksByType: (taskType: ID[]) => void
  tasksFilter?: { statuses: TaskStatus[]; assignee: ID[]; task_type: ID[] }
  onResetFilters: () => void
  sprintsFilter?: { statuses: SprintStatus[] }
  epicsFilter?: { statuses: EpicStatus[] }
}

export function ProjectPageFilterContainer(
  props: ProjectPageFilterContainerProps
) {
  const filtersListClasses = classNames('project-page__filters-list', {
    'is-active': props.isActive,
  })
  return (
    <div className={filtersListClasses}>
      <CustomScrollbar>
        <ProjectPageFilterContent
          onFilterTasksByStatus={props.onFilterTasksByStatus}
          onFilterTasksByAssignee={props.onFilterTasksByAssignee}
          onFilterSprintsByStatus={props.onFilterSprintsByStatus}
          onFilterEpicsByStatus={props.onFilterEpicsByStatus}
          onFilterTasksByType={props.onFilterTasksByType}
          onResetFilters={props.onResetFilters}
          tasksFilter={props.tasksFilter}
          sprintsFilter={props.sprintsFilter}
          epicsFilter={props.epicsFilter}
          users={props.users}
          taskTypes={props.taskTypes}
        />
      </CustomScrollbar>
    </div>
  )
}
