import React, {useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import { ChangeStatusAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {HighlightSprintStatus} from "../text-formatters/HighlightSprintStatus";
import {SprintEpicStatusType} from "../../../entities/choices/epicSprintChoices";


interface ChangeSubtaskStatusActivityProps {
  action: ChangeStatusAction
  date: string
  user: User | string
}

export function ChangeSprintStatusActivity(props: ChangeSubtaskStatusActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> changed the sprint status to{' '}
      <HighlightSprintStatus
        value={action.data.changed_to.value as SprintEpicStatusType}
        label={action.data.changed_to.label}
      />
      .{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <HighlightSprintStatus
            value={action.data.changed_from.value as SprintEpicStatusType}
            label={action.data.changed_from.label}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
