import './styles/dashboard.scss'

import React, { useCallback, useState } from 'react'
import { Layout, Typography } from 'antd'
import classNames from 'classnames'
import { Workspace } from '../entities/workspace/model'
import { Project, ProjectStatus } from '../entities/project/model'
import { useQuery } from '../utils/queryhook'
import { workspaceQuery } from '../entities/workspace/query'
import { DashboardHeader } from './DashboardHeader'
import { DashboardAddProject } from './DashboardAddProject'
import { DashboardProjectCards } from './DashboardProjectCards'
import { Observable } from 'rxjs'
import { userQuery } from '../entities/user/query'
import { User, UserRole } from '../entities/user/model'
import { projectService } from '../entities/project/service'
import { authQuery } from '../auth/state/query'
import { CustomScrollbar } from '../components/custom-scrollbar/CustomScrollbar'
import { ProjectPageFilterTarget } from '../project-page/filter/ProjectPageFilterTarget'
import { workspacePageService } from './state/service'
import { workspacePageQuery } from './state/query'
import { DashboardFiltersListCheckboxes } from './filter/DashboardFilterListCheckboxes'
import { FiltersListLayout } from '../components/filters-list-layout/FiltersListLayout'
import { Header } from '../components/header/Header'

const { Title } = Typography

export function DashboardContainer() {
  const currentUser = useQuery(authQuery.currentUser$)
  const workspace = useQuery(workspaceQuery.active$)
  const projects = useQuery(workspacePageQuery.filteredProjects$)
  const users = useQuery(userQuery.users$)
  const selectedFilters = useQuery(workspacePageQuery.statusFilter$)

  const onAddProject = useCallback(
    (data: Partial<Project>) => {
      data.workspace = workspace.id
      return projectService.addNewProject(data)
    },
    [workspace.id]
  )

  return (
    <Dashboard
      workspace={workspace}
      projects={projects}
      users={users}
      onAddProject={onAddProject}
      isAdminView={currentUser.user_role === UserRole.ADMIN}
      selectedFilters={selectedFilters}
      onStatusFilterChange={workspacePageService.updateStatusFilter}
      onResetFilters={workspacePageService.clearFilters}
    />
  )
}

interface DashboardProps {
  workspace: Workspace
  projects: Project[]
  users: User[]
  onAddProject: (data: Partial<Project>) => Observable<Project>
  isAdminView: boolean
  selectedFilters: ProjectStatus[]
  onStatusFilterChange: (name: ProjectStatus) => void
  onResetFilters: () => void
}

export function Dashboard(props: DashboardProps) {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const isDashboardEmpty = !props.projects.length

  const toggleFilter = useCallback(() => {
    setShowFilter((prevState) => !prevState)
  }, [])

  const dashboardClassNames = classNames('dashboard-page', {
    'dashboard-page_empty': isDashboardEmpty,
  })

  return (
    <Layout className={dashboardClassNames}>
      <Header title="Dashboard">
        <ProjectPageFilterTarget isActive={showFilter} onClick={toggleFilter} />
      </Header>
      <Layout.Content style={{ display: 'flex', flex: '1 1 0' }}>
        <FiltersListLayout
          onResetFilters={props.onResetFilters}
          show={showFilter}
        >
          <DashboardFiltersListCheckboxes
            selectedFilters={props.selectedFilters}
            onStatusFilterChange={props.onStatusFilterChange}
          />
        </FiltersListLayout>
        <Layout.Content
          style={{
            flex: '1 1 0',
            overflow: 'hidden auto',
            padding: '24px 16px',
          }}
        >
          <CustomScrollbar noScrollX={true}>
            {!isDashboardEmpty && (
              <DashboardHeader
                users={props.users}
                onAddProject={props.onAddProject}
                isAdminView={props.isAdminView}
              />
            )}
            {isDashboardEmpty && props.isAdminView ? (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Title
                  level={2}
                  className={
                    'dashboard-page__title dashboard-page__empty-title'
                  }
                >
                  {'Add New Project to Get Started'}
                </Title>
                <DashboardAddProject
                  classModifier={'dashboard-page__add-project-btn_big'}
                  users={props.users}
                  onAddProject={props.onAddProject}
                />
              </div>
            ) : (
              <DashboardProjectCards projects={props.projects} />
            )}
          </CustomScrollbar>
        </Layout.Content>
      </Layout.Content>
    </Layout>
  )
}
