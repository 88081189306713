import React from 'react'

interface ActivityBoldTextProps {
  children: React.ReactNode
}

export function ActivityBoldText(props: ActivityBoldTextProps) {
  const { children } = props
  return <span className="task-activity__bold-text">{children}</span>
}
