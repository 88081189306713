import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { SmartSuiteAccount } from './account.model'

export interface SmartSuiteAccountState
  extends EntityState<SmartSuiteAccount, ID> {
  loaded: boolean
}

@StoreConfig({ name: 'smartsuite-accounts', idKey: 'slug' })
export class SmartSuiteAccountStore extends EntityStore<
  SmartSuiteAccountState
> {}

export const smartSuiteAccountStore = new SmartSuiteAccountStore({
  loading: false,
})
