import React from 'react'
import { padStart } from 'lodash'

type Units = 'sec' | 'min' | 'hr'

export function safeParseFloat(value: any) {
  return parseFloat(value) || 0
}

export function ReadableTime(props: { seconds: number }) {
  return <>{ReadableTimeString(props.seconds)}</>
}

export function ReadableTimeString(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  return `${hours < 10 ? `0${hours}` : hours}:${padStart(minutes.toString(), 2, '0')}`
}

export function ReadableTimeStringWithSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  if (hours === 0 && minutes === 0){
    return `${seconds} seconds`
  }
  if (hours === 0) {
    return `${minutes} minutes`
  }
  return `${hours < 10 ? `0${hours}` : hours}:${padStart(minutes.toString(), 2, '0')}`
}

export function ReadableTimeStringNoZero(
  seconds: number, emptyStringWhenZero: boolean = false
) {
  if (!seconds && emptyStringWhenZero) {
    return ''
  }
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  return `${hours < 10 ? `${hours}` : hours}:${padStart(minutes.toString(), 2, '0')}`
}

export function SecondsToDecimalHours(seconds: number) {
  const hours = seconds / 3600
  return hours.toFixed(2)
}

export function timeInSeconds(
  hours?: number,
  minutes?: number,
  seconds?: number
): number {
  let totalSeconds = 0
  if (hours !== undefined) {
    totalSeconds += hours * 3600
  }
  if (minutes !== undefined) {
    totalSeconds += minutes * 60
  }
  if (seconds !== undefined) {
    totalSeconds += seconds
  }
  return totalSeconds
}

/**
 * Time duration parser
 * @param value
 * @param format
 */
export function parseDuration(value: string, format: Units='sec'): number {
  value = value.replace(/ /g, '').toLowerCase()

  let parsers = [
      singleDigitParser,
      decimalValueParser,
      timeFormatParser,
      humanTimeParser,
  ];

  for (let parser of parsers) {
    let parsedResult = parser(value);
    if (parsedResult) {
      switch (format) {
        case 'sec':
          return Math.floor(parsedResult * 60 * 60)

        case 'min':
          return Math.floor(parsedResult * 60)

        default:
          return Math.floor(parsedResult)
      }
    }
  }

  return 0
}

/**
 * Single value parser
 * Example: input: 4 - output 4:
 * @param value
 */
export function singleDigitParser(value: string): number {
  if (/^\d+$/g.test(value)) return parseInt(value)
  return 0
}

/**
 * Decimal value parser
 * Examples:
 * input: 1.15 - output: 1.15
 * input: 1,45 - output: 1.45
 * @param value
 */
export function decimalValueParser(value: string): number {
  if (/^\d{1,2}[,|.]\d{1,}$/g.test(value)) return parseFloat(value.replace(/,/g, '.'))
  return 0
}

/**
 * Time format parser
 * Examples:
 * input: 01:45 - output: 1.75
 * input: 1:45 - output 1.75
 * @param value
 */
export function timeFormatParser(value: string): number {
  if (/^\d{1,2}:\d{1,2}$/g.test(value)) {
    let bits = value.split(':')
    let hours = parseInt(bits[0]), minutes = parseInt(bits[1])
    return hours + minutes / 60
  }
  return 0
}

/**
 * Human format time parser
 * Examples:
 * input: 1h30m - output: 1.5
 * input: 1h - output: 1
 * input: 30m - output: 0.5
 * @param value
 */
export function humanTimeParser(value: string): number {
  let parsedValues = extractReResults(/(\d{1,3}[hm])/g, value)
  if (!parsedValues.length) return 0

  let result = 0
  for (let val of parsedValues) {
    let numVal = parseInt(val.slice(0, -1))
    let typeVal = val.slice(-1)

    if (typeVal === 'm') {
      result = result + numVal / 60;
    } else {
      result = result + numVal
    }
  }
  return result
}

function extractReResults(re: RegExp, value: string): string[] {
  let result = []
  let match
  while ((match = re.exec(value)) !== null) {
    result.push(match[0])
  }
  return result
}


export function getAddedTime(timeEntryDuration: string): string {
  return ReadableTimeString(
    timeInSeconds(
      parseInt(timeEntryDuration.split(':')[0]),
      parseInt(timeEntryDuration.split(':')[1]),
      parseInt(timeEntryDuration.split(':')[2])
    )
  )
}
