import React from 'react'

export type SubtaskTypeTypes = 'bug' | 'blocker' | 'general'

type SubtaskTypeMapping = {
  [key in SubtaskTypeTypes]: string
}

const getSubtaskTypeMapping: SubtaskTypeMapping = {
  bug: '#fa8c16',
  blocker: '#d4380d',
  general: 'var(--color-text-inactive)',
}

interface HighlightSubtaskTypeProps {
  label: string
  value: SubtaskTypeTypes
}

export function HighlightSubtaskType(props: HighlightSubtaskTypeProps) {
  return (
    <span style={{ color: `${getSubtaskTypeMapping[props.value]}` }}>
      {props.label}
    </span>
  )
}
