import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { Epic } from './model'

export interface EpicState extends EntityState<Epic, ID> {
  loaded: string[]
}

@StoreConfig({ name: 'entity-epics' })
export class EpicStore extends EntityStore<EpicState> {}

export const epicStore = new EpicStore({ loading: false, loaded: [] })
