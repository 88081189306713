import { isEqual } from 'lodash'
import React, { useState } from 'react'
import { Extension, ProseMirrorEditor } from '../../../editor'
import {
  PlaceHolderExtension,
  InternalLinkExtension,
  ExternalLinkExtension,
  MentionExtension,
  AttachmentExtension,
  KeyMapExtension,
  StrikethroughExtension,
  TableExtension,
  UnderlineExtension,
  HorizontalRuleExtension,
} from '../../../editor/extentions'
import {
  InternalLinkProvider,
  MentionProvider,
  AttachmentProvider,
} from '../../../editor/providers'
import { Project } from '../../../entities/project/model'
import { PanelSectionTitle } from '../panel-section-title/PanelSectionTitle'
import './styles/project-details-description.scss'

interface ProjectPanelDescriptionProps {
  project: Project
  onChange: (update: Partial<Project>) => void
}

export function ProjectPanelDescription(props: ProjectPanelDescriptionProps) {
  const extensions: Extension[] = [
    new PlaceHolderExtension(),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new AttachmentProvider(props.project.id)),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new KeyMapExtension(),
  ]

  const [value, setValue] = useState(() => props.project.json_description)

  const onChangeEditor = (transaction: any, view: any) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
    }
  }

  const onBlur = () => {
    if (!isEqual(value, props.project.json_description)) {
      props.onChange({json_description: value})
    }
  }
  return (
    <div className="project-details-description project-details__section">
      <PanelSectionTitle title="description" />
      <ProseMirrorEditor
        value={value}
        extensions={extensions}
        onChange={onChangeEditor}
        onBlur={onBlur}
        className="project-page-editor project-details-description__editor"
      />
    </div>
  )
}
