import React, { useState } from 'react'
import { Collapse } from '@blueprintjs/core'

import { ActivitiesDate } from './ActivitiesDate'
import { HighlightedActivity } from './text-formatters/HighlightedActivity'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'

import '../styles/task-activity.scss'
import { ChangeNameAction } from '../../entities/activities/model'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import {User} from "../../entities/user/model";

interface RenameActivityProps {
  action: ChangeNameAction
  date: string,
  user: User | string
}

export function RenameActivity(props: RenameActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      changed the{' '}{action.target.type}{' '}name to{' '}
      <HighlightedActivity>{action.data.changed_to}</HighlightedActivity>.{' '}
      <ActivitiesDetailsButton
        type="show-original"
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          {action.data.changed_from}
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
