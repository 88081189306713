import React, { useEffect } from 'react'
import { EditorView } from 'prosemirror-view'
import { PluginKey } from 'prosemirror-state'
import { isEqual } from 'lodash'

import { useProseMirrorContext } from '../../../core/provider'
import { Dialog } from './Dialog'
import { parseTaskNameDoc } from '../utils'
import { TaskType } from '../../../../entities/task-type/model'

interface EditorDialogProps {
  view: EditorView
  pluginKey?: PluginKey | null
}

export const TaskTypeEditorDialog = ({
  view,
  pluginKey,
}: EditorDialogProps) => {
  const {
    enable,
    targetElement,
    offset,
    query,
    command,
    items,
    selected,
    activeIndex,
  } = pluginKey?.getState(view.state)
  const {
    providers: { taskTypeProvider: provider },
  } = useProseMirrorContext()
  const taskTypes = provider.useSearch({ query })
  const selectedTypes = parseTaskNameDoc(view.state.doc.toJSON())[1]
  const unselectedItems = taskTypes.filter(
    (taskType: TaskType) => !selectedTypes.includes(taskType.id)
  )

  useEffect(() => {
    if (
      (!isEqual(unselectedItems, items) || !isEqual(selected, selectedTypes)) &&
      pluginKey
    ) {
      const tr = view.state.tr
      tr.setMeta(pluginKey, { items: unselectedItems, selected: selectedTypes })
      view.dispatch(tr)
    }
  }, [
    pluginKey,
    taskTypes,
    items,
    selected,
    selectedTypes,
    unselectedItems,
    view,
  ])

  const boundaryElement =
    view.dom.closest('.task-panel') || view.dom.closest('.task-groups-list')
  if (!enable || !taskTypes.length) {
    return null
  }
  return (
    <Dialog
      appendTo={document.body as HTMLElement}
      activeIndex={activeIndex}
      targetElement={targetElement}
      boundaryElement={boundaryElement as HTMLElement}
      offset={offset}
      taskTypes={unselectedItems}
      onClick={({ id, title }: { id: number | string; title: string }) =>
        command({ id, title })
      }
    />
  )
}
