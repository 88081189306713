import './styles/report-page.scss'

import React, { ReactNode, Ref } from 'react'
import { Table } from 'antd'
import { isEqual } from 'lodash'

interface ReportTableComponentProps {
  tableColumns: object[]
  reportData: object[]
  titleCallback: () => ReactNode
  rowClassNameCallback: (record: object, index: number) => string
  tableContainerRef: Ref<HTMLDivElement>
}

function _ReportTableComponent(props: ReportTableComponentProps) {
  return (
    <div ref={props.tableContainerRef}>
      <Table
        bordered
        columns={props.tableColumns}
        dataSource={props.reportData}
        title={props.titleCallback}
        rowClassName={props.rowClassNameCallback}
        pagination={false}
      />
    </div>
  )
}

export const ReportTableComponent = React.memo(_ReportTableComponent, isEqual)
