import { ID } from "@datorama/akita"

import { BaseFooterProvider } from "../extentions/footer/provider"
import { uploadFile } from "./utils/upload-file"

export class FooterProvider extends BaseFooterProvider {
  constructor(private taskId: ID) { super() }

  uploadFile = (file: File, onSuccess: (url: string, preview_url?: string) => void, onError: () => void) => {
    uploadFile(this.taskId, file, onSuccess, onError)
  }
}
