import React, {useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import { ChangeProjectChannelAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { InsertedText } from '../text-formatters/InsertedText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";


interface ChangeProjectSlackChannelActivityProps {
  action: ChangeProjectChannelAction
  date: string
  user: User | string
}

export function ChangeProjectSlackChannelActivity(
  props: ChangeProjectSlackChannelActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText>{' '}
      updated the project slack channel to <InsertedText>{action.data.changed_to}</InsertedText>{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          {action.data.changed_from ? action.data.changed_from : "No Channel"}
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
