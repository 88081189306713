import { applyTransaction, ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { keys, pick, isEqual } from 'lodash'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'
import * as XLSX from 'xlsx'

import { SprintDataService, sprintDataService } from './data-service'
import { Sprint } from './model'
import { sprintQuery, SprintQuery } from './query'
import { SprintStore, sprintStore } from './store'

import { optimisticSaveEnabled } from '../../globals/constants'
import { hashObj } from '../../utils/hash'
import { messageDispatcher } from '../../utils/message-dispatcher'
import { smartListReOrder } from '../../utils/lists'
import { ModelUpdate } from '../../utils/types'

export class SprintService {
  constructor(
    private store: SprintStore,
    private dataService: SprintDataService,
    private query: SprintQuery
  ) {}

  loadSprint(id: ID) {
    this.dataService.get(id).subscribe((sprint) => {
      this.store.add(sprint)
    })
  }

  @boundMethod
  downloadSprint(id: ID) {
    const sprint: Sprint = { ...this.query.getEntity(id) }
    const sprintName = `sprint-${sprint.name}.xlsx`
    this.dataService.download(id).subscribe((sprint) => {
      this.downLoadFile(
        sprint,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
        sprintName
      )
    })
  }

  downLoadFile(data: any, type: string, sprintName: string) {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: type })
    const url = window.URL.createObjectURL(blob)
    let aElement = document.createElement('a')
    aElement.href = url
    aElement.download = sprintName
    aElement.click()
  }

  ensureSprintsLoaded(query?: Record<string, unknown>) {
    const loadedHashes = this.query.getLoadedHashes()
    if (!query || !loadedHashes.includes(hashObj(query))) {
      this.loadSprints(query)
    }
  }

  loadSprints(query?: Record<string, unknown>) {
    this.store.setLoading(true)
    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
        })
      )
      .subscribe((sprints) => {
        this.store.add(sprints)
        if (query) {
          this.store.update((state) => ({
            ...state,
            loaded: [...state.loaded, hashObj(query)],
          }))
        }
      })
  }

  create(item: Partial<Sprint>) {
    const result = new Subject<Sprint>()
    this.dataService.create(item).subscribe(
      (sprint: Sprint) => {
        this.store.add(sprint)
        result.next(sprint)
        result.complete()
        messageDispatcher.putSuccessMessage(
          `Sprint ${sprint.name} has been created`
        )
      },
      (error) => {
        result.error(error)
      }
    )
    return result.asObservable()
  }

  @boundMethod
  destroy(id: ID) {
    const sprint: Sprint = { ...this.query.getEntity(id) }
    this.store.remove(id)

    this.dataService.destroy(id).subscribe({
      next: () => {
        messageDispatcher.putSuccessMessage(
          `Sprint ${sprint.name} has been deleted`
        )
      },
      error: () => {
        this.store.add(sprint)
      },
    })
  }

  @boundMethod
  moveSprint(id: ID, index: number) {
    const sprint: Sprint = { ...this.query.getEntity(id) }
    const sprints: Sprint[] = this.query.getForProject(sprint.project)
    const updates = smartListReOrder(sprint, sprints, 'order', index)

    applyTransaction(() => {
      updates.forEach((update) => this.updateOptimistic(update))
    })
  }

  @boundMethod
  updateSprint(update: ModelUpdate<Sprint>) {
    this.updateOptimistic(update)
  }

  /**
   * We first update the value and then try to send it to the backend.
   *
   * If save fails - we rollback the change.
   *
   */
  private updateOptimistic(update: ModelUpdate<Sprint>) {
    const currentSprint = this.query.getEntity(update.id)
    const currentValues = pick(currentSprint, keys(update))

    if (optimisticSaveEnabled) {
      this.store.update(update.id, update)
    }

    this.dataService.update(update).subscribe({
      next: (value: ModelUpdate<Sprint>) => {
        const updatedValues = pick(value, keys(update))
        // Update store only in case BE returns different values
        if (!isEqual(updatedValues, update)) {
          this.store.update(value.id, updatedValues)
        }

        if (update.hasOwnProperty('name')) {
          messageDispatcher.putSuccessMessage(
            `Sprint ${update.name} has been renamed`
          )
        } else if (update.hasOwnProperty('status')) {
          if (update.status === 'closed') {
            messageDispatcher.putSuccessMessage(
              `Sprint ${currentSprint.name} has been finished`
            )
          } else if (update.status === 'active') {
            messageDispatcher.putSuccessMessage(
              `Sprint ${currentSprint.name} has been started`
            )
          }
        } else {
          messageDispatcher.putSuccessMessage(
            `Sprint ${currentSprint.name} details have been updated`
          )
        }
      },
      error: () => {
        this.store.update(update.id, currentValues)
      },
    })
  }
}

export const sprintService = new SprintService(
  sprintStore,
  sprintDataService,
  sprintQuery
)
