import '../../styles/user-profile.scss'

import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { ID } from '@datorama/akita'
import { User } from '../../../entities/user/model'
import { Project } from '../../../entities/project/model'
import { UserProfileFullName } from './UserProfileFullName'
import { UserProfileEmailInfo } from './UserProfileEmailInfo'
import { UserProfileUploadPhoto } from './UserProfileUploadPhoto'
import { UserProfileProjectsInfo } from './UserProfileProjectsInfo'
import { ModelUpdateCallback } from '../../../utils/types'

interface UserProfileMainInfoProps {
  user: User
  allProjects: Project[]
  isCurrentUser: boolean
  adminView: boolean
  onUserUpdate: ModelUpdateCallback<User>
  onUploadAvatar: (userId: ID, file: File) => void
  onProjectsChange: (user: User, projects: Project[]) => void
}

export function UserProfileMainInfo(props: UserProfileMainInfoProps) {
  const uploadClassNames = classNames(
    'user-profile__main-avatar',
    'user-profile__main-avatar_upload'
  )
  const avatarClassNames = classNames(
    'user-profile__main-avatar',
    'user-profile__main-avatar_img'
  )

  const { user, allProjects, onUserUpdate } = props

  const userProjects = useMemo(
    () => allProjects.filter((project) => user.projects.includes(+project.id)),
    [user, allProjects]
  )

  const onFullNameChange = useCallback(
    (full_name) => onUserUpdate({ id: user.id, full_name }),
    [user, onUserUpdate]
  )

  const onEmailChange = useCallback(
    (email) => onUserUpdate({ id: user.id, email }),
    [user, onUserUpdate]
  )

  return (
    <div className="user-profile__main-info">
      <UserProfileUploadPhoto
        user={props.user}
        uploadClassName={uploadClassNames}
        avatarClassName={avatarClassNames}
        editable={props.isCurrentUser || props.adminView}
        onUploadAvatar={props.onUploadAvatar}
      />
      <div className="user-profile__main-about">
        <div className="user-profile__main-important-info">
          <UserProfileFullName
            value={props.user.full_name}
            maxLength={100}
            editable={props.isCurrentUser || props.adminView}
            onChange={onFullNameChange}
        />
        <UserProfileEmailInfo
          value={props.user.email}
          label={'work email'}
          editable={false}
          onChange={onEmailChange}
          />
        </div>
        <UserProfileProjectsInfo
          allProjects={props.allProjects}
          userProjects={userProjects}
          editable={props.adminView}
          onProjectsChange={(projects: Project[]) =>
            props.onProjectsChange(props.user, projects)
          }
        />
      </div>
    </div>
  )
}
