import { ID, Store, StoreConfig } from '@datorama/akita'

import { Project } from '../../entities/project/model'

export interface CalendarPageState {
  selectedUsersIDs: ID[] | null
  showFiltersPanel: boolean
  selectedProject: Project | null
}

const initialState = {
  selectedUsersIDs: null,
  showFiltersPanel: false,
  selectedProject: null,
}

@StoreConfig({ name: 'ui-calendar-page', resettable: true })
export class CalendarPageStore extends Store<CalendarPageState> {}

export const calendarPageStore = new CalendarPageStore(initialState)
