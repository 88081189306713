import React from 'react'
import {ID} from "@datorama/akita";
import {HistoryListItem} from "./HistoryListItem";
import {ModelUpdateCallback} from "../utils/types";
import {Comment} from "../entities/comment/model";
import {HistoryActivityItemType, HistoryItem} from "./state/model";
import {User} from "../entities/user/model";

export interface HistoryListProps {
  items: HistoryItem[]
  onCommentDelete: (id: ID) => void
  onWorkLogDelete: (id: ID) => void
  onCommentUpdate: ModelUpdateCallback<Comment>
  currentUser: User
  selectedActivityFilter?: HistoryActivityItemType[]
}

export function HistoryList(props: HistoryListProps) {
  const {
    items,
    onCommentDelete,
    onWorkLogDelete,
    onCommentUpdate,
    currentUser,
    selectedActivityFilter
  } = props

  return (
    <>
      {items && !!items.length && items.map(item => {
        return selectedActivityFilter?.includes(item.type) && (
          <HistoryListItem
            key={item.id}
            item={item}
            onCommentDelete={onCommentDelete}
            onWorkLogDelete={onWorkLogDelete}
            onCommentUpdate={onCommentUpdate}
            currentUser={currentUser}
          />
        )
      })}
    </>
  )
}
