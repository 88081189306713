import React, { useState } from 'react'
import { Collapse } from '@blueprintjs/core'
import { User } from '../../entities/user/model'
import { TimeEntry } from '../../entities/time-entry/model'
import { ActivitiesDate } from '../Activities/ActivitiesDate'
import { ActivityBoldText } from '../Activities/text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../Activities/ActivitiyDetailsContent'
import { ActivitiesDetailsButton } from '../Activities/ActivitiesDetailsButton'
import { ID } from '@datorama/akita'
import { ReadableTimeStringWithSeconds } from '../../tracking/TimeConverters'
import { HighlightedActivity } from '../Activities/text-formatters/HighlightedActivity'
import { TaskCommentDeleteConfirmationPopover } from '../CommentListItem'
import classNames from 'classnames'
import { ACTIVITIES } from '../../entities/choices/activity'
import { getLocalTimeWithoutSeconds } from '../../utils/date'
import { ActivityGrayItalicText } from '../Activities/text-formatters/HighlighGreyItalicText'

interface TaskWorkLogProps {
  // TODO: need to refactor, cleanup unneeded props
  worklog: TimeEntry[]
  date: string
  user: ID
  currentUser: User
  onDelete: (id: ID) => void
  dateStart: string
  dateEnd: string
  loggedTimeSum: number
  isAdmin: boolean
}

export function TaskWorkLog(props: TaskWorkLogProps) {
  const { worklog, user, dateStart, dateEnd, loggedTimeSum } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpandedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  const [isConfirmationPopoverOpen, setIsConfirmationPopoverOpen] = useState<
    boolean
  >(false)

  const closeConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(false)
  }

  const openConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(true)
  }

  const handleDeleteWorkLog = (id: ID) => {
    props.onDelete(id)
    closeConfirmationPopover()
  }

  const classes = classNames('worklog-container', {
    'worklog-container_has-popover': isConfirmationPopoverOpen,
  })

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> tracked{' '}
      <ActivityBoldText>
        {ReadableTimeStringWithSeconds(loggedTimeSum)}
      </ActivityBoldText>{' '}
      in this {worklog[0].target.type}.
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpandedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <ActivityBoldText>
            {`${dateStart}`}-{`${dateEnd}`}
          </ActivityBoldText>
        </ActivityDetailsContent>
        {worklog.map((timeEntry, index) => (
          <div className={classes} key={index}>
            <ActivityDetailsContent>
              activity{' '}
              <HighlightedActivity>
                {
                  ACTIVITIES.find(
                    (activity) => activity.value === timeEntry.activity
                  )?.label
                }
              </HighlightedActivity>{' '}
              {timeEntry.comment ? `; comment: ${timeEntry.comment}.` : ''}{' '}
              <ActivityGrayItalicText>
                (
                {`${getLocalTimeWithoutSeconds(
                  timeEntry.start
                )} - ${getLocalTimeWithoutSeconds(timeEntry.end)}`}
                )
              </ActivityGrayItalicText>
            </ActivityDetailsContent>
            {(props.currentUser.id === timeEntry.user || props.isAdmin) && (
              <button
                type="button"
                className="task-panel-comment__action-button task-activity__delete-button"
              >
                <TaskCommentDeleteConfirmationPopover
                  isOpen={isConfirmationPopoverOpen}
                  closeHandler={closeConfirmationPopover}
                  openHandler={openConfirmationPopover}
                  submitHandler={() => handleDeleteWorkLog(timeEntry.id)}
                />
              </button>
            )}
          </div>
        ))}
      </Collapse>
    </>
  )
}
