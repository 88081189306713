import React from 'react'
import { Avatar } from 'antd'
import { Project } from '../entities/project/model'

interface ProjectLogoProps {
  project: Project
  className?: string
  size?: number
}

export function ProjectLogo(props: ProjectLogoProps) {
  const { size = 56 } = props
  return (
    <Avatar
      src={props.project.logo}
      shape="square"
      size={size}
      style={{ backgroundColor: props.project.color }}
      className={props.className}
    >
      {props.project.code.toUpperCase()}
    </Avatar>
  )
}
