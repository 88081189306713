import { Plugin, PluginKey, Transaction, EditorState } from 'prosemirror-state'

import { ReactPluginView } from '../../core/react-plugin-view'
import { EditorDialog } from './components'
import { getCurrentLinkData, getMarkAt } from './utils'

const urlDialogPluginKey = new PluginKey('url-dialog')

export const UrlDialogPlugin = (): Plugin => new Plugin({
  key: urlDialogPluginKey,
  state: {
    init: () => ({enable: false, init: true}),
    apply: (tr, value, oldState, state) => {
      const meta = tr.getMeta(urlDialogPluginKey)
      const {from, to} = tr.selection

      // url dialog window is open by default, here we check if it first render or not
      if (value.init) return {...value, init: false}

      if (meta) { return {enable: meta.enable, autoFocus: true, target: {from, to}} }

      // enable dialog if cursor is over a link
      const link = getCurrentLinkData(state);
      if (link && from === to) {
        return {enable: true, target: {from: link.from, to: link.to, mark: link.mark}}
      }

      // preserve state if selection hasn't changed
      if (value.target?.from === from && value.target?.to === to) { return value }

      return {enable: false}
    },
  },
  props: {
    handleDOMEvents: {
      focusout: (view, event) => {
        if (!event.relatedTarget) {
          event.preventDefault()
          const tr = view.state.tr
          disableUrlDialog(tr)
          view.dispatch(tr)
        }
        return true
      }
    }
  },
  appendTransaction: (tr, oldState, state) => {
    const {enable: oldEnable, target} = getUrlDialogPluginState(oldState)
    const {enable} = getUrlDialogPluginState(state)

    // Do nothing if state hasn't changed
    if (enable === oldEnable) { return }

    // Remove link if href is empty
    if (!enable && oldEnable && target) {
      const mark = getMarkAt(state, target.from, state.schema.marks.link)
      if (mark && !mark.attrs.href) return state.tr.removeMark(target.from, target.to, mark)
    }
  },
  view: (view) => new ReactPluginView(view, EditorDialog)
})

export const enableUrlDialog = (tr: Transaction) =>
  tr.setMeta(urlDialogPluginKey, { enable: true })

export const disableUrlDialog = (tr: Transaction) =>
  tr.setMeta(urlDialogPluginKey, { enable: false })

export const getUrlDialogPluginState = (state: EditorState) =>
  urlDialogPluginKey.getState(state)
