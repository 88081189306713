import {ID} from "@datorama/akita";
import {Redirect, Route} from "react-router-dom";
import {useInstantEffect} from "../utils/instant-effect-hook";
import {userService} from "../entities/user/service";
import {useQuery} from "../utils/queryhook";
import {userQuery} from "../entities/user/query";
import {Loader} from "../main-page/Loader";
import {NotFound} from "../components/error-pages/ErrorPages.stories";
import React from "react";
import {generateUserDetailUrl} from "../routes";
import {workspaceQuery} from "../entities/workspace/query";

interface LegacyUserUrlRedirectProps {
  userId?: ID
}


export function LegacyUserUrlRedirect({ userId }: LegacyUserUrlRedirectProps) {
  useInstantEffect(
    () => {
      if (userId) userService.loadUsers({ timer_v2_id: userId })
    }, [userId]
  )

  const workspaces = useQuery(workspaceQuery.workspaces$)
  const userLoaded = useQuery(userQuery.loaded$)
  const user = useQuery(userQuery.selectFirst())

  if (!userId) return <Redirect to={`./${workspaces[0].id}/users`} />

  if (!userLoaded) return <Loader />

  if (!user) return <Route component={NotFound}/>

  return <Redirect to={generateUserDetailUrl(user.workspaces[0], user.id)} />
}
