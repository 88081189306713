import { finalize } from 'rxjs/operators'
import { applyTransaction } from '@datorama/akita'

import { smartSuiteAccountStore, SmartSuiteAccountStore } from './account.store'
import { smartSuiteAccountQuery, SmartSuiteAccountQuery } from './account.query'
import {
  smartSuiteAccountDataService,
  SmartSuiteAccountDataService,
} from './account.data-service'

export class SmartSuiteAccountService {
  constructor(
    private store: SmartSuiteAccountStore,
    private query: SmartSuiteAccountQuery,
    private dataService: SmartSuiteAccountDataService
  ) {}

  loadAccounts() {
    const { loaded, loading } = this.store.getValue()
    if (!loaded && !loading) {
      this.store.setLoading(true)
      this.dataService
        .list()
        .pipe(finalize(() => this.store.setLoading(false)))
        .subscribe(
          (accounts) => {
            applyTransaction(() => {
              this.store.update({ loaded: true })
              this.store.set(accounts)
            })
          },
          (error) => console.error(error)
        )
    }
  }
}

export const smartSuiteAccountService = new SmartSuiteAccountService(
  smartSuiteAccountStore,
  smartSuiteAccountQuery,
  smartSuiteAccountDataService
)
