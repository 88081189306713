import React from 'react'
import { DateRange } from '@blueprintjs/datetime'
import classNames from 'classnames'
import { ID } from '@datorama/akita'
import { User } from '../../entities/user/model'
import { Project } from '../../entities/project/model'
import { Sprint } from '../../entities/sprint/model'
import { ReportType, ReportTypeEnum } from '../../entities/report-statistics/model'
import { ReportStatisticsTypeSelect } from '../../components/ReportStatisticsTypeSelect'
import { FilterListUser } from '../../filters-list/FilterListUser'
import { FiltersListProjects } from '../../filters-list/FiltersListProjects'
import { FilterListSprints } from '../../filters-list/FilterListSprints'
import { FiltersListDateRange } from '../../filters-list/FiltersListDateRange'
import { FiltersListResetButton } from '../../filters-list/FiltersListResetButton'

interface ReportStatisticsFiltersListProps {
  show: boolean
  users: User[]
  projects: Project[]
  sprints: Sprint[]
  selectedUserID: ID | null
  selectedProject: Project | null
  selectedSprints: ID[]
  reportType: ReportType | null
  startDate: Date | null
  endDate: Date | null
  onChangeProject: (project: Project | null) => void
  onChangeReportType: (reportType: ReportType | null) => void
  onChangeSelectedUserID: (userID: ID | null) => void
  onChangeSelectedSprintIDs: (sprintIDs: ID[]) => void
  onChangeDates: (dates: DateRange) => void
  onResetFilters: () => void
}

export function ReportStatisticsFiltersList(props: ReportStatisticsFiltersListProps) {
  const className = classNames(['filters-list-wrap'], {
    'is-expanded': props.show,
  })
  const maxSelectableItems = 3

  return (
    <div className={className}>
      <div className="filters-list">
        <ReportStatisticsTypeSelect
          reportType={props.reportType ? props.reportType : ReportTypeEnum.ACTIVITY}
          editable={true}
          onChange={props.onChangeReportType}
        />
        <FiltersListProjects
          projects={props.projects}
          selectedProject={props.selectedProject}
          onSelectProject={props.onChangeProject}
        />
        <FilterListUser
          noUnassignedUser={true}
          users={props.users}
          // selectedUserIDs={props.selectedUserIDs}
          selectedUserID={props.selectedUserID}
          // onChangeSelectedUsersIDs={props.onChangeSelectedUsersIDs}
          onChangeSelectedUserID={props.onChangeSelectedUserID}
        />
        {props.reportType === ReportTypeEnum.VELOCITY &&
          <FilterListSprints
            noUnassignedSprint={true}
            sprints={props.sprints}
            selectedSprintIDs={props.selectedSprints}
            onChangeSelectedSprintIDs={props.onChangeSelectedSprintIDs}
            maxSelectableItems={maxSelectableItems}
          />
        }
        {props.reportType !== ReportTypeEnum.VELOCITY &&
          <FiltersListDateRange
            startDate={props.startDate}
            endDate={props.endDate}
            onChange={props.onChangeDates}
          />
        }
      </div>
      <FiltersListResetButton onClick={props.onResetFilters} />
    </div>
  )
}
