import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Icon } from '@blueprintjs/core'
import { groupBy, sortBy, isEqual } from 'lodash'
import {
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { Tooltip } from 'antd'
import { Tooltip as BPTooltip } from '@blueprintjs/core'
import { ID } from '@datorama/akita'

import { Epic } from '../../../entities/epic/model'
import { Task } from '../../../entities/task/model'
import { ModelCreateCallback, ModelUpdateCallback } from '../../../utils/types'
import { Sprint } from '../../../entities/sprint/model'
import { STATUSES } from '../../../entities/choices/status'
import { TaskList } from '../../tasks/TaskList'
import { AddTaskForm } from '../AddTaskForm'
import {
  DragObject,
  epicDroppableId,
  MoveItemCallback,
  TASKS_IN_OUT_DROP_TYPE,
} from '../../drag-n-drop'
import { TaskGroupItemProps } from '../TaskGroupProps'
import { EpicDropdownContainer } from '../controls/EpicDropdownContainer'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { TrackerControl } from '../../../tracking/TrackerControl'
import { Activity, EPIC_ACTIVITIES } from '../../../entities/choices/activity'
import { AddTimeManuallyObject } from '../../../tracking/types'
import { User } from '../../../entities/user/model'
import { SORT_STEP } from '../../../utils/lists'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'

export interface EpicListItemProps extends TaskGroupItemProps {
  baseUrl?: string
  epic: Epic
  sprints: Sprint[]
  currentEpic?: Epic | null
  taskTypes: TaskType[]
  filteredSprints: Sprint[]
  epics: Epic[]
  notFilteredTasks: Task[]
  isBacklog?: boolean
  dragProvided?: DraggableProvided
  dragObject?: DragObject
  onDestroyEpic: (id: ID) => void
  onUpdateEpic: ModelUpdateCallback<Epic>
  onReorderEpic: MoveItemCallback
  style?: CSSProperties
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
  tasksLoading?: boolean
}

function _EpicListItem(props: EpicListItemProps) {
  const tasks = sortBy(props.tasks, 'epic_order')

  const {
    epic,
    onToggle,
    onUpdateEpic,
    onStartTracking,
    onAddTimeManually,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props

  const url = `${props.baseUrl}/epic/${epic.id}`

  const epicRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (props.currentEpic?.id === epic.id && !!epicRef?.current) {
      epicRef.current?.scrollIntoView()
    }
  }, [epic, props.currentEpic])

  const _onToggle = useCallback(() => {
    onToggle(epic.id)
  }, [epic, onToggle])

  const updateEpic = useCallback(
    (update: Partial<Epic>) => {
      onUpdateEpic({ id: epic.id, ...update })
    },
    [onUpdateEpic, epic.id]
  )

  const onStatusSelect = useCallback(
    (status) => {
      updateEpic({ status: status })
    },
    [updateEpic]
  )

  const startTracking = useCallback(
    (activity: Activity | null) => {
      onStartTracking && onStartTracking('epic', +epic.id, activity)
    },
    [onStartTracking, epic.id]
  )

  const addEpicTimeManually = useCallback(
    (data: AddTimeManuallyObject) => {
      onAddTimeManually('epic', +epic.id, data)
    },
    [onAddTimeManually, epic.id]
  )

  let body: React.ReactNode

  if (props.collapsed) {
    body = <></>
  } else {
    body = (
      <div
        className={classNames('task-groups-list__group-body', {
          'task-groups-list__group-body_empty': props.tasksLoading,
        })}
      >
        <DroppableEpicPlaceholder epic={props.epic}>
          {!!props.tasks.length && (
            <TaskList
              tasks={tasks}
              onStartTracking={props.onStartTracking}
              onStopTracking={props.onStopTracking}
              onAddTimeManually={props.onAddTimeManually}
              onTaskUpdate={props.onTaskUpdate}
              location={'epic'}
              currentTask={props.currentTask}
              epic={props.epic}
              users={props.users}
              sprints={props.sprints}
              taskTypes={props.taskTypes}
              filteredSprints={props.filteredSprints}
              currentUser={props.currentUser}
              addTimeFormErrors={addTimeFormErrors}
              isAddTimeFormOpened={isAddTimeFormOpened}
              onOpenAddTimeModal={onOpenAddTimeModal}
              onCloseAddTimeModal={onCloseAddTimeModal}
            />
          )}
          {/* : (
            <div
              className="task-groups-list__spinner-item"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Spin style={{ fontSize: 40, color: 'var(--color-text-link)' }} />
            </div>
          ) */}
        </DroppableEpicPlaceholder>
      </div>
    )
  }

  const groupClasses = classNames('task-groups-list__group', {
    'is-collapsed': props.collapsed,
  })

  return (
    <div style={{ ...props.style, paddingTop: 8 }} ref={epicRef}>
      <div className={groupClasses}>
        <EpicHeader
          url={url}
          isBacklog={props.isBacklog}
          epic={props.epic}
          epics={props.epics}
          collapsed={props.collapsed}
          tasks={tasks}
          notFilteredTasks={props.notFilteredTasks}
          dragProvided={props.dragProvided}
          onToggle={_onToggle}
          onTaskCreate={props.onTaskCreate}
          onDestroyEpic={props.onDestroyEpic}
          onStatusSelect={onStatusSelect}
          onStartTracking={startTracking}
          onStopTracking={props.onStopTracking}
          onAddTimeManually={addEpicTimeManually}
          currentUser={props.currentUser}
          onReorderEpic={props.onReorderEpic}
          addTimeFormErrors={props.addTimeFormErrors}
          isAddTimeFormOpened={props.isAddTimeFormOpened}
          onOpenAddTimeModal={props.onOpenAddTimeModal}
          onCloseAddTimeModal={props.onCloseAddTimeModal}
        />
        {body}
      </div>
    </div>
  )
}

function DroppableEpicPlaceholder({
  epic,
  children,
}: {
  epic: Epic
  children: React.ReactNode
}) {
  return (
    <Droppable
      droppableId={epicDroppableId(epic)}
      type={TASKS_IN_OUT_DROP_TYPE}
    >
      {(
        dropProvided: DroppableProvided,
        _dropSnapshot: DroppableStateSnapshot
      ) => (
        <div
          style={{ minHeight: '1px' }}
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          {children}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export interface EpicHeaderProps {
  url?: string
  epic: Epic
  epics?: Epic[]
  tasks: Task[]
  notFilteredTasks: Task[]
  isBacklog?: boolean
  collapsed: boolean
  dragProvided?: DraggableProvided
  onToggle: () => void
  onTaskCreate: ModelCreateCallback<Task>
  onDestroyEpic: (id: ID) => void
  onStatusSelect: (status: string) => void
  onStartTracking?: (activity: string) => void
  onStopTracking?: () => void
  onAddTimeManually?: (data: AddTimeManuallyObject) => void
  currentUser?: User
  onReorderEpic?: MoveItemCallback
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function EpicHeader(props: EpicHeaderProps) {
  const { onTaskCreate, epic, tasks } = props

  const [hover, setHover] = useState(false)

  const createTask = (task: Partial<Task>) => {
    const taskOrder = tasks.length
      ? tasks.slice(-1)[0].epic_order + SORT_STEP
      : 0
    onTaskCreate({ ...task, epic: epic.id, epic_order: taskOrder })
  }

  const onMouseEnter = useCallback(() => setHover(true), [])
  const onMouseLeave = useCallback(() => setHover(false), [])

  return (
    <div
      className="task-groups-list__group-header task-groups-list__group-header_epic"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {props.dragProvided && (
        <div
          className="task-groups-list__drag-wrap"
          {...props.dragProvided.dragHandleProps}
        >
          <Icon icon="drag-handle-vertical" />
        </div>
      )}
      <div className="task-groups-list__group-header-top">
        <div className="task-groups-list__header-top-left">
          {props.collapsed && (
            <DownOutlined
              onClick={props.onToggle}
              className="task-groups-list__group-header-toggler"
            />
          )}
          {!props.collapsed && (
            <UpOutlined
              onClick={props.onToggle}
              className="task-groups-list__group-header-toggler"
            />
          )}

          <div className="task-groups-list__name">
            <BPTooltip
              content={props.epic.name}
              targetTagName="div"
              wrapperTagName="div"
              boundary="viewport"
              popoverClassName="task-groups-list__name-tooltip"
            >
              {props.url ? (
                <Link style={{ display: 'block' }} to={props.url}>
                  <h3>{props.epic.name}</h3>
                </Link>
              ) : (
                <h3>{props.epic.name}</h3>
              )}
            </BPTooltip>
          </div>
        </div>
        <div className="task-groups-list__header-epic-right">
          {props.onStartTracking && props.onStopTracking && (
            <span className="time-tracker-control_fixed">
              <TrackerControl
                trackingObject={props.epic}
                trackingObjectType="epic"
                activities={EPIC_ACTIVITIES}
                showControlOnly={true}
                hideIfPossible={!hover}
                onStart={props.onStartTracking}
                onStop={props.onStopTracking}
                onAddTimeManually={props.onAddTimeManually}
                currentUser={props.currentUser}
                addTimeFormErrors={props.addTimeFormErrors}
                isAddTimeFormOpened={props.isAddTimeFormOpened}
                onOpenAddTimeModal={props.onOpenAddTimeModal}
                onCloseAddTimeModal={props.onCloseAddTimeModal}
              />
            </span>
          )}

          <EpicStatsBar tasks={props.tasks} />

          {!props.isBacklog && (
            <div className="task-groups-list__action-buttons">
              <EpicDropdownContainer
                targetClassName="task-group-details__menu"
                key={epic.id}
                epicID={epic.id}
                epicsLength={props.notFilteredTasks.length}
                epicStatus={epic.status}
                isReadOnlyEpic={epic.is_readonly}
                onDelete={props.onDestroyEpic}
                onStatusSelect={props.onStatusSelect}
                hasReorderMenu={true}
                showCurrent={true}
                epics={props.epics}
                onReorder={props.onReorderEpic}
              />
            </div>
          )}
        </div>
      </div>

      {!props.collapsed && <AddTaskForm onTaskAdd={createTask} />}
    </div>
  )
}

function _EpicStatsBar(props: { tasks: Task[] }) {
  const statusGroups = groupBy(sortBy(props.tasks, 'status'), 'status')
  const activeStatuses = STATUSES.filter(
    (status) => !!statusGroups[status.value]
  )

  return !!activeStatuses.length ? (
    <div className="epic-stats-bar">
      {activeStatuses.map((status) => {
        return statusGroups[status.value].map((task) => {
          return (
            <Tooltip
              title={`${task.code} - ${task.name} - ${status.label}`}
              key={task.id}
            >
              <span
                className="epic-stats-bar__step"
                style={{ background: status.color }}
              />
            </Tooltip>
          )
        })
      })}
    </div>
  ) : null
}

export const EpicStatsBar = React.memo(_EpicStatsBar, isEqual)

export const EpicListItem = React.memo(_EpicListItem, isEqual)
