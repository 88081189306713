import { MenuFoldOutlined } from '@ant-design/icons'
import { ID } from '@datorama/akita'
import React, { useCallback, useEffect, useRef } from 'react'
import { Button, Spin } from 'antd'
import { Epic } from '../../../entities/epic/model'
import { Project } from '../../../entities/project/model'
import { Sprint } from '../../../entities/sprint/model'
import { Task } from '../../../entities/task/model'
import { ShortUser, User } from '../../../entities/user/model'
import {
  AddTimeManuallyCallback,
  StartTrackingCallback,
} from '../../../tracking/types'
import { ModelCreateCallback, ModelUpdateCallback } from '../../../utils/types'
import { useGroupBy } from '../../../utils/usegroupby'
import { BacklogContainer } from './BacklogContainer'
import { AddTaskGroupForm } from '../AddTaskGroupForm'
import { EpicListItem } from './EpicListItem'
import { VariableSizeList } from 'react-window'
import { VLIST_STYLES } from '../../virtual-scroll'
import { MoveItemCallback } from '../../drag-n-drop'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'

export interface EpicListProps {
  baseUrl?: string
  epics: Epic[]
  tasks: Task[]
  notFilteredTasks: Task[]
  sprints: Sprint[]
  taskTypes: TaskType[]
  filteredSprints: Sprint[]
  users: User[] | ShortUser[]
  collapsedEpics: ID[]
  backlogCollapsed: boolean
  currentTask?: Task | null
  currentEpic?: Epic | null
  project: Project
  onPaneToggle: () => void
  onToggleEpic: (id: ID) => void
  onToggleProjectBacklog: () => void
  onTaskUpdate: ModelUpdateCallback<Task>
  onTaskCreate: ModelCreateCallback<Task>
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  onCreateEpic: (epic: Partial<Epic>) => void
  onUpdateEpic: ModelUpdateCallback<Epic>
  onReorderEpic: MoveItemCallback
  onDestroyEpic: (id: ID) => void
  formErrors: IFormErrors | null
  cleanupFormErrors: () => void
  createEpicFormIsOpened: boolean
  openCreateEpicFrom: () => void
  closeCreateEpicFrom: () => void
  currentUser?: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
  sprintsLoading?: boolean
  epicsLoading?: boolean
  tasksLoading?: boolean
}

export function EpicList(props: EpicListProps) {
  const {
    epics,
    currentEpic,
    cleanupFormErrors,
    closeCreateEpicFrom,
    openCreateEpicFrom,
  } = props
  const epicTasks = useGroupBy(props.tasks, 'epic')
  const epicNotFilteredTasks = useGroupBy(props.notFilteredTasks, 'epic')

  const listRef = useRef<VariableSizeList>(null)

  useEffect(() => {
    listRef.current?.resetAfterIndex(0)
  }, [epics, props.tasks, props.collapsedEpics])

  useEffect(() => {
    if (currentEpic) {
      const index = epics.findIndex(
        (item: Sprint | Epic) => item.id === currentEpic.id
      )
      listRef.current?.scrollToItem(index, 'start')
    }
  }, [epics, currentEpic])

  const onCreateFormClose = useCallback(() => {
    closeCreateEpicFrom()
    cleanupFormErrors()
  }, [cleanupFormErrors, closeCreateEpicFrom])

  const onCreateFormOpen = useCallback(() => {
    openCreateEpicFrom()
    cleanupFormErrors()
  }, [openCreateEpicFrom, cleanupFormErrors])

  let lastEpicOrder = 0
  const lastSprint = epics.slice(-1)[0]

  if (lastSprint) {
    lastEpicOrder = lastSprint['order'] + 1
  }

  const { onCreateEpic } = props

  const createEpic = useCallback(
    (name: string, order: number) => {
      onCreateEpic({ name, order })
    },
    [onCreateEpic]
  )

  const tasksWithoutEpic = epicTasks['null'] || []

  const backlogCurrentTask =
    props.currentTask && tasksWithoutEpic.includes(props.currentTask)
      ? props.currentTask
      : null

  const backlogRow = (
    <BacklogContainer
      tasks={tasksWithoutEpic}
      sprints={props.sprints}
      taskTypes={props.taskTypes}
      filteredSprints={props.filteredSprints}
      project={props.project}
      collapsed={props.backlogCollapsed}
      currentTask={backlogCurrentTask}
      users={props.users}
      onToggle={props.onToggleProjectBacklog}
      onTaskUpdate={props.onTaskUpdate}
      onTaskCreate={props.onTaskCreate}
      onStartTracking={props.onStartTracking}
      onStopTracking={props.onStopTracking}
      onAddTimeManually={props.onAddTimeManually}
      currentUser={props.currentUser}
      style={VLIST_STYLES}
      addTimeFormErrors={props.addTimeFormErrors}
      isAddTimeFormOpened={props.isAddTimeFormOpened}
      onOpenAddTimeModal={props.onOpenAddTimeModal}
      onCloseAddTimeModal={props.onCloseAddTimeModal}
      tasksLoading={props.tasksLoading}
    />
  )

  if (props.epicsLoading) {
    return (
      <div className="task-groups-list task-groups-list_empty">
        <div className="task-groups-list__spinner">
          <Spin size="large" />
        </div>
      </div>
    )
  }

  return (
    <div className="task-groups-list">
      <div className="task-groups-list__header">
        <h2>
          <MenuFoldOutlined
            className="task-groups-list__header__toggle"
            onClick={props.onPaneToggle}
          />{' '}
          Epics
        </h2>

        <div className="task-groups-list__header-toolbar">
          <div className="task-groups-list__header-toolbar-button">
            <Button onClick={onCreateFormOpen}>New Epic</Button>
          </div>
        </div>
      </div>

      {props.createEpicFormIsOpened && (
        <AddTaskGroupForm
          type="epic"
          onCreate={createEpic}
          onClose={onCreateFormClose}
          order={lastEpicOrder}
          formErrors={props.formErrors}
        />
      )}

      <div className="task-groups-list__body">
        {epics.map((item, index) => {
          return (
            <EpicListItem
              key={item.id}
              baseUrl={props.baseUrl}
              epic={item}
              epics={epics}
              taskTypes={props.taskTypes}
              tasks={epicTasks[item.id] || []}
              notFilteredTasks={epicNotFilteredTasks[item.id] || []}
              currentEpic={currentEpic}
              sprints={props.sprints}
              filteredSprints={props.filteredSprints}
              collapsed={props.collapsedEpics.includes(item.id)}
              currentTask={props.currentTask}
              users={props.users}
              onToggle={props.onToggleEpic}
              onTaskUpdate={props.onTaskUpdate}
              onTaskCreate={props.onTaskCreate}
              onStartTracking={props.onStartTracking}
              onStopTracking={props.onStopTracking}
              onAddTimeManually={props.onAddTimeManually}
              onDestroyEpic={props.onDestroyEpic}
              onUpdateEpic={props.onUpdateEpic}
              currentUser={props.currentUser}
              onReorderEpic={props.onReorderEpic}
              style={VLIST_STYLES}
              addTimeFormErrors={props.addTimeFormErrors}
              isAddTimeFormOpened={props.isAddTimeFormOpened}
              onOpenAddTimeModal={props.onOpenAddTimeModal}
              onCloseAddTimeModal={props.onCloseAddTimeModal}
              tasksLoading={props.tasksLoading}
            />
          )
        })}
        {backlogRow}
      </div>
    </div>
  )
}
