import React from 'react'
import { Moment } from 'moment-timezone/moment-timezone'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'

import '../../styles/user-profile.scss'

interface UserProfileStatsProps {
  startDate: Moment | null
  lastSeen: Moment | null
  hoursWorked: number
}

export function UserProfileStats(props: UserProfileStatsProps) {
  const dateFormat = 'DD MMMM YYYY'
  const timeFormat = `${dateFormat}, HH:mm`
  return (
    <div className="user-profile__stats">
      <div className="user-profile__stat">
        <UserProfileFieldTitle label={'start date'} />
        <div className="user-profile__stat-field user-profile__stat-field_start-date">
          {props.startDate?.format(dateFormat)}
        </div>
      </div>
      <div className="user-profile__stat">
        <UserProfileFieldTitle label={'last seen'} />
        <div className="user-profile__stat-field user-profile__stat-field_last-seen">
          {props.lastSeen?.format(timeFormat)}
        </div>
      </div>
      <div className="user-profile__stat">
        <UserProfileFieldTitle label={'hours worked'} />
        <div className="user-profile__stat-field user-profile__stat-field_hours-worked">
          {props.hoursWorked}&nbsp;hr{props.hoursWorked % 10 === 1 ? '' : 's'}
        </div>
      </div>
    </div>
  )
}
