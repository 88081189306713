import React, { useContext } from 'react'
import { User } from '../../entities/user/model'
import { TimeEntry } from '../../entities/time-entry/model'
import { ActivityBoldText } from '../Activities/text-formatters/ActivityBoldText'
import { ID } from '@datorama/akita'
import moment from 'moment'
import { activityLogContext } from '../../project-page/tasks/TaskPanel/TaskPanel'
import { Subtask } from '../../entities/subtask/model'
import { HighlightedActivity } from '../Activities/text-formatters/HighlightedActivity'
import { ACTIVITIES } from '../../entities/choices/activity'

interface TaskCurrentTrackingProps {
  worklog: TimeEntry[]
  date?: string
  user: ID
  onDelete: (id: ID) => void
  currentUser: User
  dateStart: string
  dateEnd: string
  loggedTimeSum: number
  isAdmin: boolean
}

export function TaskCurrentTracking(props: TaskCurrentTrackingProps) {
  const { worklog, user } = props

  const lastItem = worklog[worklog.length - 1]

  const startTracking = new Date(worklog[0].start)
    .toLocaleTimeString('it-IT')
    .split(':')

  const duration = moment.duration(moment().diff(moment(lastItem.start)))

  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find(
    (x: Subtask) => x.id === worklog[0].target.id
  )

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> is currently performing{' '}
      <HighlightedActivity>
        {ACTIVITIES.find(
          (activity) => activity.value === worklog[0].activity
        )?.label.toLowerCase()}
      </HighlightedActivity>{' '}
      in{' '}
      {worklog[0].target.type === 'subtask' ? (
        <>
          <HighlightedActivity>{subtask?.name}</HighlightedActivity> subtask.
        </>
      ) : (
        `this ${worklog[0].target.type}`
      )}
      .{' '}
      <ActivityBoldText>
        {`${startTracking[0]}:${startTracking[1]}`} - now (
        {Math.round(duration.asMinutes())} minutes)
      </ActivityBoldText>
    </>
  )
}
