import { CalendarEvent } from './model'
import { http } from '../../globals/http'

export class CalendarEventDataService {
  list(query: object) {
    return http.get<CalendarEvent[]>('/api/time-slots/', query)
  }
}

export const calendarEventDataService = new CalendarEventDataService()
