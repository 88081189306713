import { QueryEntity } from '@datorama/akita'
import { TaskTypeState, TaskTypeStore, taskTypeStore } from './store'

export class TaskTypeQuery extends QueryEntity<TaskTypeState> {
  loaded$ = this.select('loaded')
  all$ = this.selectAll()

  constructor(protected store: TaskTypeStore) {
    super(store)
  }
}

export const taskTypeQuery = new TaskTypeQuery(taskTypeStore)
