import React from 'react'
import {
  getStatusColorMapping,
} from '../../../entities/choices/status'
import {TaskStatus} from "../../../entities/task/model";

interface HighlightTaskStatusProps {
  label: string
  value: TaskStatus
}

export function HighlightTaskStatus(props: HighlightTaskStatusProps) {
  return (
    <span style={{ color: `${getStatusColorMapping[props.value]}` }}>
      {props.label}
    </span>
  )
}
