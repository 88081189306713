import { Schema } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import {inputRules} from "prosemirror-inputrules"

import { Extension } from "../.."
import { HorizontalLineRule } from './inputrule'

export class HorizontalRuleExtension extends Extension {
  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    inputRules({ rules: [HorizontalLineRule] }),
  ]
}
