import './styles/change-user-password-dialog.scss'

import React, { useCallback, useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { Observable } from 'rxjs'

import { ChangePasswordData } from '../../../entities/user/model'

interface ChangeUserPasswordDialogProps {
  isOpen: boolean
  onClose: () => void
  onChangePassword: (data: ChangePasswordData) => Observable<void>
}

export function ChangeUserPasswordDialog(props: ChangeUserPasswordDialogProps) {
  const { isOpen, onClose, onChangePassword } = props
  const [backendErrors, setBackendErrors] = useState<{
    current_password?: string[]
    new_password?: string[]
  }>({})

  const getFieldBackendValidationStatus = useCallback(
    (fieldName: 'current_password' | 'new_password') =>
      (!!backendErrors[fieldName] && 'error') || undefined,
    [backendErrors]
  )

  const getFieldBackendError = useCallback(
    (fieldName: 'current_password' | 'new_password') =>
      !!backendErrors[fieldName] && backendErrors[fieldName]?.join(' '),
    [backendErrors]
  )

  const handleChangePassword = useCallback(
    (data) => {
      onChangePassword(data).subscribe(
        () => onClose(),
        ({ response: { data } }) => {
          setBackendErrors(data)
        }
      )
    },
    [onClose, onChangePassword, setBackendErrors]
  )

  const handleFormValuesChange = useCallback(
    (values) => {
      Object.keys(values).forEach((key) =>
        setBackendErrors((errors) => ({ ...errors, [key]: undefined }))
      )
    },
    [setBackendErrors]
  )

  return (
    <Modal
      visible={isOpen}
      title="Change password"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          form="change-password-form"
          key="submit"
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>,
      ]}
    >
      <Form
        id="change-password-form"
        labelCol={{ span: 9 }}
        labelAlign="right"
        layout="horizontal"
        onFinish={handleChangePassword}
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item
          validateStatus={getFieldBackendValidationStatus('current_password')}
          help={getFieldBackendError('current_password')}
          name="current_password"
          label="Current password"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <Input.Password placeholder="Enter current password" />
        </Form.Item>
        <Form.Item
          validateStatus={getFieldBackendValidationStatus('new_password')}
          help={getFieldBackendError('new_password')}
          name="new_password"
          label="New password"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <Input.Password placeholder="Enter new password" />
        </Form.Item>
        <Form.Item
          name="repeat_password"
          label="Repeat new password"
          dependencies={['new_password']}
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Passwords don’t match'))
              },
            }),
          ]}
        >
          <Input.Password placeholder="Repeat new password" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
