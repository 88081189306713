import React from 'react'
import { Select } from 'antd'
import { FiltersListTitle } from '../filters-list/FiltersListTitle'
import {
  ReportTypeEnum,
  ReportType,
  ReportTypeOption,
  ReportTypeOptions,
} from '../entities/report-statistics/model'

const { Option } = Select

interface ReportStatisticsTypeSelectProps {
  reportType?: ReportType
  editable: boolean
  onChange: (reportType: ReportType) => void
}

export function ReportStatisticsTypeSelect(
  props: ReportStatisticsTypeSelectProps
) {
  const defaultItem: ReportType = ReportTypeEnum.ACTIVITY

  return (
    <>
      <FiltersListTitle>Report Type</FiltersListTitle>
      <div className="report-statistics-type-select filters-list__item">
        {props.editable ? (
          <Select
            value={props.reportType || defaultItem}
            onChange={props.onChange}
            dropdownMatchSelectWidth={false}
          >
            {ReportTypeOptions.map((reportTypeOption: ReportTypeOption) => {
              return (
                <Option
                  key={reportTypeOption.value}
                  value={reportTypeOption.value}
                >
                  {reportTypeOption.label}
                </Option>
              )
            })}
          </Select>
        ) : (
          ReportTypeOptions.find((x) => x.value === props.reportType)?.label
        )}
      </div>
    </>
  )
}
