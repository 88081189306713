import React from 'react'
import { Classes } from '@blueprintjs/core'
import { Checkbox } from 'antd'
import classNames from 'classnames'
import { TaskPanelFilterChoice } from '../../../entities/choices/subtasksFilter'
import './styles/task-filter-popover.scss'
import {HistoryActivityItemType} from "../../../history/state/model";

const menuItemClasses = classNames(
  Classes.MENU_ITEM,
  Classes.TEXT_OVERFLOW_ELLIPSIS,
  Classes.FILL,
  'task-filter-popover__checkbox'
)

interface TaskPanelFilterOptionProps {
  filter: TaskPanelFilterChoice
  onFilter?: (value: HistoryActivityItemType) => void
  selected?: boolean
}

export function TaskPanelFilterOption(props: TaskPanelFilterOptionProps) {
  const { filter, onFilter } = props

  const onChange = (e: any) => {
    if (onFilter) onFilter(e.target['data-value'])
  }

  return (
    <li key={filter.value}>
      <Checkbox
        data-value={filter.value}
        onChange={onChange}
        className={menuItemClasses}
        checked={props.selected? props.selected : false}
      >
        {filter.label}
      </Checkbox>
    </li>
  )
}
