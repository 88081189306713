import { ID } from '@datorama/akita'
import _ from 'lodash'
import { ALL_USERS_ID, UNASSIGNED_ID } from '../user/model'
import { ALL_TASK_TYPES_ID } from '../task-type/model'
import { Doc } from '../../editor/types'

export type TaskStatus =
  | 'qa'
  | 'todo'
  | 'draft'
  | 'accepted'
  | 'cancelled'
  | 'fix-in-progress'
  | 'on-review'
  | 'bug-found'
  | 'in-progress'
  | 'verified'
  | 'ready-for-testing'
  | 'on-hold'

export interface ITaskType {
  value: string
}

export const DefaultTaskStatuses: TaskStatus[] = [
  'qa',
  'todo',
  'draft',
  'accepted',
  'fix-in-progress',
  'on-review',
  'bug-found',
  'in-progress',
  'verified',
  'ready-for-testing',
  'on-hold',
]

export interface Task {
  id: ID
  project: ID

  sprint?: ID
  sprint_order: number

  epic?: ID
  epic_order: number

  name: string
  code: string

  priority: string
  status: TaskStatus
  size: string

  assignee: ID | null

  is_blocked: boolean

  time_logged: number

  estimated_time: EstimatedTimeCollection
  max_estimated_time?: number

  linked_tasks: ID[]

  followers: ID[]

  json_description: Doc | null

  created_by: ID
  created_at: Date
  users?: [ID | null]
  ss_link?: string
  task_type: number[]
  types?: ITaskType[]
  current_estimate?: number
  is_readonly?: boolean
}

export interface EstimatedTimeCollection {
  total: EstimatedTimeEntry
  activities: EstimatedTimeEntry[]
}

export interface EstimatedTimeEntry {
  activity: string
  min_estimated_time: number
  max_estimated_time: number
}

export interface TaskIntoSubtask {
  target_id: ID
}

function prepareStatuses(
  statuses: TaskStatus[],
  task: Task,
  sprints: ID[],
  isShowTasksInFinishedSprints?: boolean
) {
  if (
    typeof isShowTasksInFinishedSprints !== 'undefined' &&
    !isShowTasksInFinishedSprints &&
    !!task.sprint
  ) {
    statuses = (sprints || []).includes(task.sprint)
      ? statuses.filter((s) => s !== 'accepted')
      : statuses
  }
  return statuses
}

export function filterTasks(
  tasks: Task[],
  statuses: TaskStatus[],
  assignee: ID[],
  task_type: ID[],
  isShowTasksInFinishedSprints?: boolean,
  closedSprintIDs?: ID[]
) {
  const statusesClone = [...statuses]
  return tasks.filter((task) => {
    statuses = prepareStatuses(
      statusesClone,
      task,
      closedSprintIDs || [],
      isShowTasksInFinishedSprints
    )
    return (
      statusesFilter(task, statuses) &&
      assigneeFilter(task, assignee) &&
      taskTypeFilter(task, task_type)
    )
  })
}

function statusesFilter(task: Task, statuses: TaskStatus[]) {
  return statuses.length ? statuses.includes(task.status) : true
}

function taskTypeFilter(task: Task, task_type: ID[]) {
  if (!task_type?.length) {
    return true
  }
  // Filter by all task types
  if (task_type[0] === ALL_TASK_TYPES_ID) return true

  let intersection = task_type.filter((x) =>
    task.task_type.includes(x as number)
  )
  return task_type.length ? !!intersection.length : true
}

function assigneeFilter(task: Task, assignee: ID[]) {
  // Filter isn't present
  if (!assignee.length) return true

  // Filter by all users
  if (assignee[0] === ALL_USERS_ID) return true

  const taskHaveUsers = _.intersection(assignee, task.users || []).length

  return assignee.includes(task.assignee || UNASSIGNED_ID) || taskHaveUsers
}
