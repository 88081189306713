import React from 'react'
import { MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { ToolBarItem } from './Item'

export interface SelectItemChoice {
  value: number
  label: string
  isActive: boolean
}

interface ToolBarSelectItemProps {
  iconName: string
  label?: string | JSX.Element | undefined
  options: SelectItemChoice[]
  onSelect: (value: number) => void
}

const TypedToolBarSelect = Select.ofType<SelectItemChoice>()

const itemRenderer = (item: any, renderProps: any) => {
  const {modifiers, handleClick} = renderProps
  if (!modifiers.matchesPredicate) { return null }

  return (
    <MenuItem
      key={`${item.value}-${item.label}`}
      active={item.isActive}
      disabled={modifiers.disabled}
      onClick={handleClick}
      text={item.label}
    />
  )
}

export const ToolBarSelectItem = ({options, iconName, label, onSelect}: ToolBarSelectItemProps) => {
  return (
    <TypedToolBarSelect
      items={options}
      itemRenderer={itemRenderer}
      onItemSelect={({value}: SelectItemChoice) => onSelect(value)}
      filterable={false}
      popoverProps={{minimal: true, position: 'bottom'}}
    >
      <ToolBarItem onClick={() => {}} label={label} iconName={iconName}/>
    </TypedToolBarSelect>
  )
}
