import React from 'react'
import { TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import normalizeUrl from 'ss-normalize-url'

import { disableUrlDialog, getUrlDialogPluginState } from '../plugins'
import { getPositionOffset } from '../utils'
import { Dialog } from './Dialog'

export const EditorDialog = ({ view }: { view: EditorView }) => {
  const { enable, target, autoFocus } = getUrlDialogPluginState(view.state)

  if (!enable || !target) {
    return null
  }

  const { mark, from, to } = target
  const attrs = mark?.attrs ?? { href: '' }
  const { targetElement, offset } = getPositionOffset(view, from)

  const handleHrefChange = (href: string) => {
    const schema = view.state.schema
    const tr = view.state.tr

    tr.removeMark(from, to, schema.marks.link)
    if (href) {
      tr.addMark(
        from,
        to,
        schema.marks.link.create({ href: normalizeUrl(href) })
      )
    }
    tr.setSelection(TextSelection.near(tr.doc.resolve(to)))

    disableUrlDialog(tr)
    view.dispatch(tr)
    view.focus()
  }

  return (
    <Dialog
      appendTo={view.dom.parentElement?.parentElement as HTMLElement}
      targetElement={targetElement}
      offsetTop={offset?.top}
      offsetLeft={offset?.left}
      initialHref={attrs.href}
      onHrefChange={handleHrefChange}
      autoFocus={autoFocus}
    />
  )
}
