import classNames from 'classnames'
import React, { useCallback, useState } from 'react'

import './styles/task-group-details-title.scss'
import {
  EditableTextComponent,
  FieldIsRequiredValidator,
} from '../../../components/ValidatedEditableText'

interface TaskGroupDetailsTitleProps {
  children: string
  placeholder: string
  required?: boolean
  onChange: (value: string) => void
  className?: string
  disabled?: boolean
}

export function TaskGroupDetailsTitle(props: TaskGroupDetailsTitleProps) {
  const { children, onChange, required, disabled } = props
  const [value, setValue] = useState<string>(children)
  const classes = classNames('task-group-details-title', props.className)

  const onChangeHandler = useCallback(
    (value) => {
      if (value === children || disabled) return
      onChange(value)
    },
    [onChange, children, disabled]
  )

  return (
    <div className={classes}>
      <EditableTextComponent
        className="task-group-details-title__textarea"
        defaultValue={props.children}
        maxLength={200}
        multiline={true}
        value={value}
        validated={required}
        validator={FieldIsRequiredValidator}
        placeholder={props.placeholder}
        onChange={setValue}
        onConfirm={onChangeHandler}
        disabled={disabled}
      />
    </div>
  )
}
