import { IDateRangeShortcut, DateRange } from '@blueprintjs/datetime'
import moment, { DurationInputArg2 } from 'moment'

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
})

function createShortcut(
  label: string,
  dateRange: DateRange
): IDateRangeShortcut {
  return { dateRange, label }
}

/**
 * @param durationUnit string literal accepred by momentJS as a duration unit like week or month
 * @param startRangeOffset offset in durationUnit, where the range starts
 * @param endRangeOffset offset in durationUnit, where the range ends
 * @returns list with range beginning as the first element and ending as the secont element
 */
function getPastRange(
  durationUnit: DurationInputArg2,
  startRangeOffset?: number,
  endRangeOffset?: number
): [Date, Date] {
  let startRange = moment()
  if (startRangeOffset) {
    startRange = startRange.subtract(startRangeOffset, durationUnit)
  }
  startRange = startRange.startOf(durationUnit)

  let endRange = moment()
  if (endRangeOffset) {
    endRange = endRange.subtract(endRangeOffset, durationUnit)
  }
  endRange = endRange.endOf(durationUnit)
  return [startRange.toDate(), endRange.toDate()]
}

/**
 *
 * Returns custom shortcuts for DateRangeInput. Handles financial quarters and half years.
 *
 */
export const getDateRangeShortcuts = (): IDateRangeShortcut[] => {
  const [previousWeekStart, previousWeekEnd] = getPastRange('week', 1, 1)

  const [previousMonthStart, previousMonthEnd] = getPastRange('month', 1, 1)

  const [previousYearStart, previousYearEnd] = getPastRange('year', 1, 1)

  const [twoYearsStart, twoYearsEnd] = getPastRange('year', 2, 1)

  const [currentMonthStart, currentMonthEnd] = [
    moment().startOf('month').toDate(),
    moment().toDate(),
  ]

  const [currentWeekStart, currentWeekEnd] = [
    moment().startOf('week').toDate(),
    moment().toDate(),
  ]

  return [
    createShortcut('Current month', [currentMonthStart, currentMonthEnd]),
    createShortcut('Current week', [currentWeekStart, currentWeekEnd]),
    createShortcut('Last 2 years', [twoYearsStart, twoYearsEnd]),
    createShortcut('Last year', [previousYearStart, previousYearEnd]),
    createShortcut('Last month', [previousMonthStart, previousMonthEnd]),
    createShortcut('Last week', [previousWeekStart, previousWeekEnd]),
  ]
}
