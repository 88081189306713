import React, { Ref, useCallback, useMemo } from 'react'
import { Typography } from 'antd'
import { ReportStatistics, ReportStatisticsActivity } from '../../entities/report-statistics/model'
import { ReportTableComponent } from '../ReportTableComponent'
import { ParseReportStatisticsActivityData } from './parsers/activity'

const reportProjectTableColumns = [
  { title: 'Activity', dataIndex: 'activity', key: 'activity' },
  { title: 'Duration', dataIndex: 'time_spent', key: 'time_spent' },
  { title: '% of Total', dataIndex: 'percentage', key: 'percentage' },
]

interface ReportStatisticsActivityProps {
  report: ReportStatistics | null
  tableContainerRef: Ref<HTMLDivElement>
}

export function ReportStatisticsActivityComponent(props: ReportStatisticsActivityProps) {
  const report = props.report as ReportStatisticsActivity
  const projectName = report?.project_name

  const reportData = useMemo(() => {
    if (report == null) {
      return []
    }
    return ParseReportStatisticsActivityData(report)
  }, [report])

  const titleCallback = useCallback(() => {
    if (!projectName) {
      return null
    }
    return (
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        Time report: {projectName}
      </Typography.Title>
    )
  }, [projectName])

  const rowClassNameCallback = useCallback((record: any, index: number) => {
    switch (record.key) {
      case 'total':
        return 'table-row-bold table-row-title'
    }
    return ''
  }, [])

  return (
    <ReportTableComponent
      tableColumns={reportProjectTableColumns}
      reportData={reportData}
      titleCallback={titleCallback}
      rowClassNameCallback={rowClassNameCallback}
      tableContainerRef={props.tableContainerRef}
    />
  )
}
