import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { ProjectMultiSelect } from '../../../components/controls/ProjectMultiSelect'
import { Project } from '../../../entities/project/model'
import { UserProfileProject } from './UserProfileProject'

import '../../styles/user-profile.scss'

interface UserProfileProjectsProps {
  allProjects: Project[]
  selectedProjects: Project[]
  editable: boolean
  onProjectsChange: (projects: Project[]) => void
}

export function UserProfileProjects(props: UserProfileProjectsProps) {
  const { selectedProjects } = props
  const noProjects = useMemo(() => selectedProjects.length === 0, [
    selectedProjects,
  ])

  if (props.editable) {
    return (
      <Tooltip
        placement={'top'}
        visible={noProjects}
        title={'User must be assigned to at least one Project'}
      >
        <ProjectMultiSelect
          projects={props.allProjects}
          selectedProjects={props.selectedProjects}
          onProjectsChange={props.onProjectsChange}
        />
      </Tooltip>
    )
  }
  return (
    <>
      {props.selectedProjects.map((project) => {
        return (
          <UserProfileProject key={project.name}>
            {project.code} | {project.name} | {project.status}
          </UserProfileProject>
        )
      })}
    </>
  )
}
