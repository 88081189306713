import '../styles/task-group-details.scss'

import React, { useCallback, useEffect, useMemo } from 'react'
import { ID } from '@datorama/akita'
import { ActionType } from '../../../../entities/activities/model'
import { useParams } from 'react-router-dom'
import { sortBy } from 'lodash'
import isEqual from 'react-fast-compare'

import { Epic } from '../../../../entities/epic/model'
import { ProjectDetailsActivities } from '../../../panels/project-details/ProjectDetailsActivities'
import { EpicDropdownContainer } from '../../controls/EpicDropdownContainer'
import { TaskGroupDetailsTitle } from '../TaskGroupDetailsTitle'
import { useQuery } from '../../../../utils/queryhook'
import { epicPanelService } from './state/service'
import { epicPanelQuery } from './state/query'
import { Task } from '../../../../entities/task/model'
import { TaskGroupsDetailsInfoDate } from '../TaskGroupsDetailsInfoDate'
import { epicService } from '../../../../entities/epic/service'
import { ModelUpdateCallback } from '../../../../utils/types'
import { EpicDetailsDescription } from './EpicDetailsDescription'
import { activityLogService } from '../../../../entities/activities/service'
import { authQuery } from '../../../../auth/state/query'
import { User } from '../../../../entities/user/model'
import { CustomScrollbar } from '../../../../components/custom-scrollbar/CustomScrollbar'
import { SmartSuiteLink } from '../../../../components/SmartSuiteLink'
import { timeEntryService } from '../../../../entities/time-entry/service'
import {
  combineHistory,
  HistoryActivityItemType,
  HistoryItem,
} from '../../../../history/state/model'
import { filterLocalStorageService } from '../../../state/filterLocalStorage'
import { formatDate } from '../../../../utils/date'
import { EpicDetailsSwitcher } from './EpicDetailsSwitcher'

export function EpicDetailsComponent() {
  const { epicId } = useParams()

  useEffect(() => {
    epicPanelService.setActive(epicId!)
    activityLogService.loadActivities({ epics__id: epicId! })
    timeEntryService.loadTimeEntries({ epic__id: epicId! })
    filterLocalStorageService.subscribeOnEpicPanelFilters()
  }, [epicId])

  const epic = useQuery(epicPanelQuery.epicWithUser$)
  const epicTasks = useQuery(epicPanelQuery.epicTasks$)
  const epicActivities = useQuery(epicPanelQuery.epicActivities$)
  const epicWorklog = useQuery(epicPanelQuery.epicWorklogs$)
  const currentUser = useQuery(authQuery.currentUser$)
  const selectedActivityFilter = useQuery(epicPanelQuery.activityFilter$)
  const showTasksInFinishedSprints = useQuery(
    epicPanelQuery.showTasksInFinishedSprints$
  )

  const history = sortBy(
    combineHistory(epicActivities as any, epicWorklog),
    'date'
  ).reverse()

  if (!epic) {
    return <></>
  }

  return (
    <EpicDetails
      epic={epic}
      epicTasks={epicTasks}
      currentUser={currentUser}
      activities={epicActivities}
      history={history}
      onUpdateEpic={epicService.updateEpic}
      onDeleteEpic={epicService.destroy}
      onWorkLogDelete={timeEntryService.destroy}
      onFilterActivities={epicPanelService.updateActivityFilter}
      selectedActivityFilter={selectedActivityFilter}
      showTasksInFinishedSprints={showTasksInFinishedSprints}
      onSetShowTasksInFinishedSprints={
        epicPanelService.setShowTasksInFinishedSprints
      }
    />
  )
}

interface EpicDetailsProps {
  epic: Epic
  epicTasks: Task[]
  currentUser: User
  activities: ActionType[]
  history: HistoryItem[]
  onUpdateEpic: ModelUpdateCallback<Epic>
  onDeleteEpic: (id: ID) => void
  onWorkLogDelete: (id: ID) => void
  onFilterActivities: (value: HistoryActivityItemType) => void
  showTasksInFinishedSprints: ID[]
  onSetShowTasksInFinishedSprints: (id: ID) => void
  selectedActivityFilter?: HistoryActivityItemType[]
}

function _EpicDetails(props: EpicDetailsProps) {
  const {
    epic,
    epicTasks,
    onUpdateEpic,
    onDeleteEpic,
    onSetShowTasksInFinishedSprints,
  } = props

  const createdAt = formatDate(epic.created_at).toString()
  const updatedAt = formatDate(epic.updated_at).toString()

  const updateEpic = useCallback(
    (update: Partial<Epic>) => {
      onUpdateEpic({ id: epic.id, ...update })
    },
    [onUpdateEpic, epic.id]
  )

  const onStatusSelect = useCallback(
    (status) => {
      updateEpic({ status: status })
    },
    [updateEpic]
  )

  const onUpdateDescription = useCallback(
    (value) => {
      updateEpic({ json_description: value })
    },
    [updateEpic]
  )

  const isShowTasksInFinishedSprints = useMemo(() => {
    return props.showTasksInFinishedSprints.includes(epic.id)
  }, [props.showTasksInFinishedSprints, epic])

  const onShowTasksInFinishedSprintsChange = useCallback(() => {
    onSetShowTasksInFinishedSprints(epic.id)
  }, [onSetShowTasksInFinishedSprints, epic])

  return (
    <div className="task-group-details">
      <div className="task-group-details__header">
        <div className="task-group-details__header-left">
          <div className="task-group-details__group-top">
            <div className="task-group-details__group-type">Epic</div>
            <div className="task-group-details__status">
              <EpicDropdownContainer
                targetClassName="task-group-details__menu"
                epicID={epic.id}
                epicStatus={epic.status}
                epicsLength={epicTasks.length}
                isReadOnlyEpic={epic.is_readonly}
                onDelete={onDeleteEpic}
                onStatusSelect={onStatusSelect}
                showCurrent={true}
              />
            </div>
          </div>
        </div>
        <div className="task-group-details__header-right">
          <div className="task-groups-details-info">
            <div className="task-groups-details-info__item">
              <TaskGroupsDetailsInfoDate
                date={createdAt}
                user={epic.created_by_user}
                title="Created by"
              />
            </div>
            <div className="task-groups-details-info__item">
              <TaskGroupsDetailsInfoDate
                date={updatedAt}
                user={epic.updated_by_user}
                title="Updated by"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="task-group-details__title-wrap">
        <TaskGroupDetailsTitle
          key={`epic-name-${epic.id}`}
          required={true}
          className="task-group-details__title"
          placeholder="Epic title"
          disabled={epic.is_readonly}
          onChange={(value) => updateEpic({ name: value })}
        >
          {epic.name}
        </TaskGroupDetailsTitle>{' '}
      </div>
      <EpicDetailsSwitcher
        isShow={isShowTasksInFinishedSprints}
        onChange={onShowTasksInFinishedSprintsChange}
      />
      <EpicDetailsDescription
        key={`epic-description-${epic.id}`}
        epic={epic}
        onChange={onUpdateDescription}
        className="task-group-details__section"
        disabled={epic.is_readonly}
      />
      {epic.ss_link && <SmartSuiteLink ss_link={epic.ss_link} />}
      <div className="task-group-details__content">
        <CustomScrollbar>
          <ProjectDetailsActivities
            activities={props.activities}
            history={props.history}
            className="task-group-details__section"
            currentUser={props.currentUser}
            onWorkLogDelete={props.onWorkLogDelete}
            onFilterActivities={props.onFilterActivities}
            selectedActivityFilter={props.selectedActivityFilter}
          />
        </CustomScrollbar>
      </div>
    </div>
  )
}

export const EpicDetails = React.memo(_EpicDetails, isEqual)
