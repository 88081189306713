import { QueryEntity } from '@datorama/akita'
import { WorkspaceState, WorkspaceStore, workspaceStore } from './store'

export class WorkspaceQuery extends QueryEntity<WorkspaceState> {
  workspaces$ = this.selectAll()
  loaded$ = this.select('loaded')
  active$ = this.selectActive()

  constructor(protected store: WorkspaceStore) {
    super(store)
  }
}

export const workspaceQuery = new WorkspaceQuery(workspaceStore)
