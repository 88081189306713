import '../../styles/user-field.scss'

import React, { useCallback } from 'react'
import { EditableTextComponent } from '../../../components/ValidatedEditableText'

interface UserProfileTextareaProps {
  value: string
  placeholder: string
  editable: boolean
  onUpdate: (value: string) => void
  maxLength?: number
}

export function UserProfileTextarea(props: UserProfileTextareaProps) {
  const { value, onUpdate } = props
  const onConfirm = useCallback(
    (newValue: string) => {
      if (newValue !== value) {
        onUpdate(newValue)
      }
    },
    [value, onUpdate]
  )
  return (
    <div className="user-field">
      <EditableTextComponent
        defaultValue={props.value}
        placeholder={props.editable ? props.placeholder : 'Not set'}
        disabled={!props.editable}
        multiline={true}
        maxLength={props.maxLength}
        onConfirm={onConfirm}
      />
    </div>
  )
}
