import React, { useState } from 'react'
// import { PageSearch } from './PageSearch'
import { PageSearchTarget } from './PageSearchTarget'
import { SearchItem } from '../../../project-page/search'

interface PageSearchContainerProps {
  targetClassName?: string
  items: SearchItem[]
}

export const PageSearchContainer: React.FunctionComponent<
  PageSearchContainerProps
> = (props) => {
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // eslint-disable-next-line
  const [query, setQuery] = useState<string>('')

  // const handleItemSelect = () => {
  //   setQuery('')
  //   setIsOpen(false)
  // }

  const handleOpen = () => {
    setQuery('')
    setIsOpen(true)
  }

  return (
    <>
      <PageSearchTarget
        className={props.targetClassName}
        onClick={handleOpen}
      />
      {/*<PageSearch*/}
      {/*  onClose={() => { setIsOpen(false) }}*/}
      {/*  isOpen={isOpen}*/}
      {/*  query={query}*/}
      {/*  onQueryChange={setQuery}*/}
      {/*  items={props.items}*/}
      {/*  onSelectItem={handleItemSelect}*/}
      {/*/>*/}
    </>
  )
}
