import { Collapse } from '@blueprintjs/core'
import React, { useState } from 'react'
import { ChangeTaskSizeAction } from '../../entities/activities/model'
import { HighlightedActivity } from './text-formatters/HighlightedActivity'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import {DeletedText} from "./text-formatters/DeletedText";
import {InsertedText} from "./text-formatters/InsertedText";
import {User} from "../../entities/user/model";

interface ChangeTaskSizeActivityProps {
  action: ChangeTaskSizeAction
  date: string,
  user: User | string
}

export function ChangeTaskSizeActivity(props: ChangeTaskSizeActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      changed estimate of this task to{' '}
      <HighlightedActivity>{action.data.changed_to.label}</HighlightedActivity>.{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <DeletedText>{action.data.changed_from.label}</DeletedText> ||{' '}
          <InsertedText>{action.data.changed_to.label}</InsertedText>
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
