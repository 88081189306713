import moment from 'moment'

export const DATE_STORE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'DD MMM YYYY'
export const CURRENT_YEAR_DATE_FORMAT = 'DD MMM'
export const TIME_FORMAT = 'HH:MM'
export const TIME_FORMAT_ROUNDED = 'HH:mm'

export const formatDate = (
  date: Date | string,
  format: string = DATE_DISPLAY_FORMAT
) => moment(date).format(format)

export const parseDate = (value: string) => new Date(value)

const checkIsLessThanHourAgo = (date: string) => {
  const oneHourAgo = moment().subtract(1, 'hours')
  const commentDate = moment(date)
  return oneHourAgo <= commentDate
}

const checkIsLessThanMinuteAgo = (date: string) => {
  const oneMinuteAgo = moment().subtract(1, 'minutes')
  return moment(date).isAfter(oneMinuteAgo)
}

const checkToday = (date: string) => {
  const today = moment().endOf('day')
  const commentDate = moment(date).endOf('day')
  return moment(commentDate).isSame(today, 'day')
}

const checkYesterday = (date: string) => {
  const yesterday = moment().add(-1, 'day').endOf('day')
  const commentDate = moment(date).endOf('day')
  return moment(commentDate).isSame(yesterday, 'day')
}

const checkIsCurrentYear = (date: string) => {
  return moment().year() === moment(date).year()
}

export function humanizeDate(date: string) {
  const isCurrentYear = checkIsCurrentYear(date)

  if (checkIsLessThanMinuteAgo(date)) return 'Just now'

  if (checkIsLessThanHourAgo(date)) {
    const duration = moment.duration(moment().diff(moment(date)))
    const minutes = Math.round(duration.asMinutes())
    return minutes + ' minutes ago'
  }

  if (checkToday(date)) {
    const duration = moment.duration(moment().diff(moment(date)))
    const hours = Math.round(duration.asHours())
    return hours + ' hours ago'
  }

  if (checkYesterday(date))
    return `Yesterday at ${formatDate(date, TIME_FORMAT)}`

  if (isCurrentYear) return formatDate(date, CURRENT_YEAR_DATE_FORMAT)

  if (!isCurrentYear) date = formatDate(date)

  return date
}

export const getMinutesWithLeadingZeros = (date: Date): string => {
  return (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
}

export const getHoursWithLeadingZeros = (date: Date): string => {
  return (date.getHours() < 10 ? '0' : '') + date.getHours()
}

export const getTimeWithLeadingZeros = (date: Date): string => {
  return `${getHoursWithLeadingZeros(date)}:${getMinutesWithLeadingZeros(date)}`
}

export const getLocalTimeWithoutSeconds = (date: string): string => {
  return new Date(date)
    .toLocaleTimeString('uk-UK', { hour: '2-digit', minute: '2-digit' })
    .replace(/[^0-9\.:]/g, '') // eslint-disable-line
}

export const durationWithoutSeconds = (duration: string): string => {
  return duration.substr(0, duration.lastIndexOf(':'))
}
