import { Divider } from '@blueprintjs/core'
import React from 'react'
import {
  SubtaskStatusChoice,
  SubtaskTypeChoice,
} from '../../../entities/choices/subtasksFilter'
import { TaskPanelFilterOption } from './TaskPanelFilterOption'
import { TaskPanelFilterPopover } from './TaskPanelFilterPopover'
import { SubtaskStatus, SubtaskType } from '../../../entities/subtask/model'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'

interface SubtasksFilterPopoverProps {
  typeFilters: SubtaskTypeChoice[]
  statusFilters: SubtaskStatusChoice[]
  onFilterSubtasksByType: (value: SubtaskType) => void
  onFilterSubtasksByStatus: (value: SubtaskStatus) => void
  selectedSubtaskFilter?: { types: SubtaskType[]; statuses: SubtaskStatus[] }
}

export function SubtasksFilterPopover(props: SubtasksFilterPopoverProps) {
  return (
    <TaskPanelFilterPopover popoverClassName='subtasks-filter-popover' sectionName="Subtasks">
      <CustomScrollbar
        rendererMaxHeight={300}
        translateContentSizeYToHolder={true}
      >
        <React.Fragment key={'filter-by-type'}>
          {props.typeFilters.map((filter) => {
            return (
              <TaskPanelFilterOption
                key={filter.value}
                filter={filter}
                onFilter={() => props.onFilterSubtasksByType(filter.value)}
                selected={props.selectedSubtaskFilter?.types.includes(
                  filter.value
                )}
              />
            )
          })}
          <Divider />
        </React.Fragment>
        <React.Fragment key={'filter-by-status'}>
          {props.statusFilters.map((filter) => {
            return (
              <TaskPanelFilterOption
                key={filter.value}
                filter={filter}
                onFilter={() => props.onFilterSubtasksByStatus(filter.value)}
                selected={props.selectedSubtaskFilter?.statuses.includes(
                  filter.value
                )}
              />
            )
          })}
        </React.Fragment>
      </CustomScrollbar>
    </TaskPanelFilterPopover>
  )
}
