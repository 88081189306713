import React from "react"
import { useReactNodeView } from "../../../core/react-node-view"

export const AttachmentComponent: React.FC = () => {
  const { node } = useReactNodeView()
  const name = node?.attrs.name.replace(/(.{30})..+/, "$1...")
  const size = (node?.attrs.size / 1024 / 1024).toFixed(2)

  return <a href={node?.attrs.href} target="_blank" rel="noopener noreferrer" download>{name}, {size} MB</a>
}
