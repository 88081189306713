import React from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { useQuery } from '../utils/queryhook'
import { authQuery } from '../auth/state/query'

export function UsersPageWrapper({ children }: { children: React.ReactNode }) {
  const currentUser = useQuery(authQuery.currentUser$)

  const routeMatch = useRouteMatch('/:workspace/users/:user')
  if (!routeMatch) {
    return <Redirect to={`./users/${currentUser.id}`} />
  }

  return <>{children}</>
}
