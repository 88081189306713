import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Input } from 'antd'
import { Tooltip } from '@blueprintjs/core'
import { Task } from '../../entities/task/model'

const MAX_LENGTH = 255

export interface AddTaskFormProps {
  onTaskAdd: (task: Partial<Task>) => void
}

export function AddTaskForm(props: AddTaskFormProps) {
  const [value, setValue] = useState('')
  const [, setIsMaxLength] = useState(false)
  const [isTooltipShowing, setIsTooltipShowing] = useState(false)
  const timeout = useRef<any>(null)

  const clearTooltipTimeout = () => {
    clearTimeout(timeout.current)
    timeout.current = null
  }

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTooltipTimeout()
      }
    },
    []
  )

  const { onTaskAdd } = props

  const addTask = useCallback(
    (changes?: Partial<Task>) => {
      if (!changes?.name?.length) {
        return
      }
      onTaskAdd(changes)
      setValue('')
    },
    [onTaskAdd, setValue]
  )

  return (
    <div className="quick-add-task">
      <Tooltip
        targetTagName="div"
        wrapperTagName="div"
        isOpen={isTooltipShowing}
        content="You reached max length!"
      >
        <Input
          value={value}
          onPressEnter={(event) => addTask({ name: value })}
          onChange={(event) => {
            if (event.target.value.length === MAX_LENGTH) {
              setIsMaxLength(true)
              setIsTooltipShowing(true)
              timeout.current = setTimeout(
                () => setIsTooltipShowing(false),
                2000
              )
            } else {
              setIsMaxLength(false)
              setIsTooltipShowing(false)
              clearTooltipTimeout()
            }
            setValue(event.target.value)
          }}
          placeholder={'Quick Add Task...'}
          className="quick-add-task-input"
          maxLength={MAX_LENGTH}
        />
      </Tooltip>
    </div>
  )
}
