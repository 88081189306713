import OrderedMap from "orderedmap"
import {Plugin} from "prosemirror-state"
import {MarkSpec, Schema} from "prosemirror-model"
import { toggleMark } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'

import { Extension } from "../.."
import { underlineMark } from "./marks"


export class UnderlineExtension extends Extension {
  addMarks = (marks: OrderedMap<MarkSpec>): OrderedMap<MarkSpec> => marks.append(underlineMark)

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    keymap({'Mod-u': toggleMark(schema.marks.underline)}),
  ]
}
