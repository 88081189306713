import React, {useContext} from 'react'
import { CreateAction } from '../../../entities/activities/model'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";
import {Subtask} from "../../../entities/subtask/model";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";

interface CreateSubtaskActivityProps {
  action: CreateAction
  date: string
  user: User | string
}

export function CreateSubtaskActivity(props: CreateSubtaskActivityProps) {
  const { user, action } = props

  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      created the {action.target.type} <HighlightedActivity>{subtask?.name}</HighlightedActivity>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />
    </>
  )
}
