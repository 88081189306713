import { Popover } from 'antd'
import React from 'react'
import { ReadableTimeStringNoZero } from '../../../tracking/TimeConverters'

export interface SubtasksInitialEstimateProps {
  initialEstimateSum: number
}
export function SubtasksInitialEstimate(props: SubtasksInitialEstimateProps) {
  const { initialEstimateSum } = props

  return (
    <div className="subtasks-header__initial-estimate">
      <Popover placement="left" trigger="hover" content="Total subtasks initial estimate">
        {ReadableTimeStringNoZero(initialEstimateSum)}
      </Popover>
    </div>
  )
}
