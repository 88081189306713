import classNames from 'classnames'
import React from 'react'
import { TaskType as TaskTypeModel } from '../../../entities/task-type/model'
import './task-type.scss'

interface TaskTypeProps {
  type: TaskTypeModel
  className?: string
}

export function TaskType(props: TaskTypeProps) {
  const { className } = props
  const classes = classNames('task-type', className)
  return <span className={classes}>#{props.type.name}</span>
}
