import { ID } from '@datorama/akita'

import { ForceAuthentication } from './auth/ForceAuthentication'
import { MainInnerContainer } from './main-page/MainInnerLoader'
import { WorkspaceListPageContainer } from './dashboard-page/WorkspaceList'
import { BaseWorkspaceLayoutContainer } from './dashboard-page/WorkspaceBaseLayout'
import { DashboardContainer } from './dashboard-page/Dashboard'
import { ProjectPageContainer } from './project-page/ProjectPage'
import { TaskPanelContainer } from './project-page/tasks/TaskPanel/TaskPanel'
import { WorkspacePreloader } from './dashboard-page/WorkspacesPreLoader'
import { UsersContextGuard } from './auth/usersContext'
import { RouteConfig } from './utils/ConfigRouter'
import { LoginPageContainer } from './auth/LoginPage'
import { RegistrationPageContainer } from './auth/RegistrationPage'
import { CalendarPageContainer } from './calendar-page/CalendarPage'
import { ReportProjectPageContainer } from './reports-pages/report-project-page/ReportProjectPage'
import { ReportDevPageContainer } from './reports-pages/report-dev-page/ReportDevPage'
import { ReportStatisticsPageContainer } from './reports-pages/report-statistics-page/ReportStatisticsPage'
import { UsersPageContainer } from './users-page/UsersPage'
import { UsersPageWrapper } from './users-page/UsersPageWrapper'
import { CurrentUserContextGuard } from './auth/currentUserContext'
import { TrackingObjectContextGuard } from './tracking/trackingContext'
import { SprintDetailsContainer } from './project-page/task-groups/details/SprintPanel/SprintDetails'
import { EpicDetailsComponent } from './project-page/task-groups/details/EpicPanel/EpicDetails'
import { ProjectDetailsContainer } from './project-page/panels/project-details/ProjectDetails'
import { LegacyTaskRedirect } from './legacy-urls-redirect/LegacyTaskRedirect'

export function generateTaskDetailUrl(
  workspaceId: ID,
  projectId: ID,
  taskCode: ID
): string {
  return `/${workspaceId}/${projectId}/task/${taskCode}`
}

export function generateUserDetailUrl(workspaceId: ID, userId: ID): string {
  return `/${workspaceId}/users/${userId}`
}

export function generateSprintDetailUrl(
  workspaceId: ID,
  projectId: ID,
  sprintId: ID
): string {
  return `/${workspaceId}/${projectId}/sprint/${sprintId}`
}

export function generateEpicDetailUrl(
  workspaceId: ID,
  projectId: ID,
  epicId: ID
): string {
  return `/${workspaceId}/${projectId}/epic/${epicId}`
}

export function generateProjectDetailUrl(workspaceId: ID, projectId: ID) {
  return `/${workspaceId}/${projectId}`
}

export const ROUTES: RouteConfig = [
  {
    path: '/login',
    component: LoginPageContainer,
  },
  {
    path: '/registration/:invitationToken',
    component: RegistrationPageContainer,
  },
  {
    path: '',
    component: MainInnerContainer,
    guards: [ForceAuthentication],
    children: [
      {
        path: '/:workspaceId',
        guards: [
          WorkspacePreloader,
          UsersContextGuard,
          CurrentUserContextGuard,
          TrackingObjectContextGuard,
        ],
        component: BaseWorkspaceLayoutContainer,
        children: [
          {
            path: '/users',
            component: UsersPageWrapper,
            children: [
              {
                path: '/:userId',
                component: UsersPageContainer,
              },
            ],
          },
          {
            path: '/stats',
            component: ReportStatisticsPageContainer,
          },
          {
            path: '/report/project',
            component: ReportProjectPageContainer,
          },
          {
            path: '/report/dev',
            component: ReportDevPageContainer,
          },
          {
            path: '/calendar',
            component: CalendarPageContainer,
          },
          {
            path: '/:projectId',
            component: ProjectPageContainer,
            children: [
              {
                path: '/sprint/:sprintId',
                component: SprintDetailsContainer,
              },
              {
                path: '/epic/:epicId',
                component: EpicDetailsComponent,
              },
              {
                path: '/task/:taskCode',
                component: TaskPanelContainer,
                guards: [UsersContextGuard],
              },
              {
                path: '/:taskId',
                component: LegacyTaskRedirect,
              },
              {
                path: '/',
                component: ProjectDetailsContainer,
              },
            ],
          },
          {
            path: '/',
            component: DashboardContainer,
          },
        ],
      },
      {
        path: '/',
        component: WorkspaceListPageContainer,
      },
    ],
  },
]
