import {Redirect, Route} from 'react-router-dom'
import React from 'react'
import {ID} from "@datorama/akita";
import {useInstantEffect} from "../utils/instant-effect-hook";
import {projectService} from "../entities/project/service";
import {useQuery} from "../utils/queryhook";
import {projectQuery} from "../entities/project/query";
import {Loader} from "../main-page/Loader";
import {NotFound} from "../components/error-pages/ErrorPages.stories";
import {generateTaskDetailUrl} from "../routes";
import {taskService} from "../entities/task/service";
import {taskQuery} from "../entities/task/query";

interface LegacyTaskUrlWithHashRedirectProps {
  projectCode?: string,
  taskId?: ID
}

export function LegacyTaskUrlWithHashRedirect({ projectCode = '', taskId }: LegacyTaskUrlWithHashRedirectProps) {
  useInstantEffect(
    () => {
      projectService.loadProjects({ code: projectCode })
      taskService.loadTasks({timer_v2_id: taskId })
    }, [projectCode]
  )

  const projectLoaded = useQuery(projectQuery.loaded$)
  const project = useQuery(projectQuery.selectFirst())
  const taskLoading = useQuery(taskQuery.loading$)
  const task = useQuery(taskQuery.selectFirst())

  if (!projectLoaded || taskLoading) return <Loader />

  if (!project || !task) return <Route component={NotFound}/>

  return <Redirect to={generateTaskDetailUrl(project.workspace, project.id, task.code)}/>
}
