import React, {useContext} from 'react'
import { RemoveLinkedTaskAction } from '../../entities/activities/model'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {Link, useRouteMatch} from "react-router-dom";
import {User} from "../../entities/user/model";
import {activityLogTaskContext} from "../../project-page/tasks/TaskPanel/TaskPanel";
import {Task} from "../../entities/task/model";

interface RemoveLinkedTaskActivityProps {
  action: RemoveLinkedTaskAction
  date: string
  user: User | string
}

export function RemoveLinkedTaskActivity(props: RemoveLinkedTaskActivityProps) {
  const { action, user } = props
    const getTasks = useContext(activityLogTaskContext)
  const task = getTasks.find((x: Task) => x.id === action.data.changed_from.value)
  const routeMatch = useRouteMatch('/:workspace/:project/task/')
  const url = routeMatch?.url + '/' + task?.code

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> unlinked the{' '}
      <ActivityBoldText><Link to={url}>{action.data.changed_from.label}</Link></ActivityBoldText>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
