import { tableNodes } from 'prosemirror-tables'


export const tableNodeSpecs = tableNodes({
  tableGroup: 'block',
  cellContent: 'block+',
  cellAttributes: {
    background: {
      default: null,
      getFromDOM: (dom: Element) => (dom as HTMLElement).style.backgroundColor || null,
      setDOMAttr: (value: string, attrs) => {
        if (value) { attrs.style = (attrs.style || '') + `background-color: ${value};` }
      },
    },
  },
}) as any
