import React, {useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import { ChangeProjectDeadlineAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {DeletedText} from "../text-formatters/DeletedText";
import {InsertedText} from "../text-formatters/InsertedText";


interface ChangeProjectDeadlineActivityProps {
  action: ChangeProjectDeadlineAction
  date: string
  user: User | string
}

export function ChangeProjectDeadlineActivity(props: ChangeProjectDeadlineActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText>{' '}
      updated the project deadline date.{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <DeletedText>{action.data.changed_from}</DeletedText>{' '}<InsertedText>{action.data.changed_to}</InsertedText>
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
