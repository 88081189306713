import React, { useState } from 'react'
import { Extension, ProseMirrorEditor } from '../../../../editor'
import {
  AttachmentExtension,
  FooterExtension,
  KeyMapExtension,
  PlaceHolderExtension,
  InternalLinkExtension,
  MentionExtension,
  ExternalLinkExtension,
  ToolbarExtension,
  StrikethroughExtension,
  TableExtension,
  UnderlineExtension,
  HorizontalRuleExtension,
} from '../../../../editor/extentions'
import { User, ShortUser } from '../../../../entities/user/model'
import { ModelCreateCallback } from '../../../../utils/types'
import { Comment } from '../../../../entities/comment/model'
import {
  AttachmentProvider,
  FooterProvider,
  InternalLinkProvider,
  MentionProvider,
} from '../../../../editor/providers'
import { Task } from '../../../../entities/task/model'
import { isDocEmpty } from '../../../../editor/core/utils'

interface CommentsFollowersFormContentProps {
  users: (User | ShortUser)[]
  task: Task
  onCommentCreate: ModelCreateCallback<Comment>
}

export function CommentsFollowersFormContent(
  props: CommentsFollowersFormContentProps
) {
  const { task, onCommentCreate } = props

  const extensions: Extension[] = [
    new PlaceHolderExtension('Write a comment...'),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new AttachmentProvider(task.id)),
    new ToolbarExtension(),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new FooterExtension(new FooterProvider(task.id), (value) => {
      if (isDocEmpty(value)) return

      onCommentCreate({ json_text: value })
      setValue(null)
    }),
    new KeyMapExtension(),
  ]
  const [value, setValue] = useState(() => null)

  const onChangeEditor = (transaction: any, view: any) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
    }
  }

  return (
    <div className="task-panel-comment-form__content">
      <ProseMirrorEditor
        hasFooter={true}
        value={value}
        onChange={onChangeEditor}
        extensions={extensions}
        className="task-panel-comment-form-editor task-panel-comment-form__editor"
      />
    </div>
  )
}
