import { ID } from '@datorama/akita'
import moment from 'moment/moment'
import { truncate, get,  } from 'lodash'

import { CalendarEvent } from '../model'
import {
  calendarTimeClasses,
  badWorkingTimeSec,
  normalWorkingTimeSec,
} from '../../../tracking/constants'
import { ReadableTimeString } from '../../../tracking/TimeConverters'
import {
  generateEpicDetailUrl,
  generateProjectDetailUrl,
  generateSprintDetailUrl,
  generateTaskDetailUrl,
} from '../../../routes'


export const convertEvent = (
  event: CalendarEvent,
  workspaceId: string | undefined,
  dateToStateMap: { [key: string]: string[] }
) => {

  return {
    title: getEventTitle(event),
    user_full_name: getFullName(event),
    classNames: getClassNames(event, dateToStateMap),
    start: event.start,
    end: event.end,
    allDay: event.full_day,
    url: getUrl(event, workspaceId as ID),
    extendedProps: {
      grouped: event.grouped,
      children: event.children,
      activity: event.activity_label,
    },
  }
}

const getFullName = (event: CalendarEvent): string => {
  return truncate(event.user_full_name, { length: 16 })
}

const getEventTitle = (event: CalendarEvent): string => {
  if (event.full_day) {
    const duration = ReadableTimeString(event.duration_sec)
    return `${getFullName(event)} ${duration}h`
  }
  if (event.grouped) {
    return `Working on ${event.project_name} project`
  }
  return event.code ? `${event.code} | ${event.name}` : `${event.name}`;
}

const getClassNames = (
  event: CalendarEvent,
  dateToStateMap: { [key: string]: string[] }
): string[] => {
  // this key is used to properly set color classes for non-cumulative entries
  // (full_day === false); user_id is necessary if you filter by multiple users:
  // this guarantees proper colors for each user's time slot
  const dateStringKey = `${moment(event.start).format('YYYY-MM-DD')}-${
    event.user_id
  }`

  let classNames

  if (event.full_day) {
    if (event.duration_sec < badWorkingTimeSec) {
      classNames = [calendarTimeClasses.belowNormal]
    } else if (event.duration_sec < normalWorkingTimeSec) {
      classNames = [calendarTimeClasses.normal]
    } else {
      classNames = [calendarTimeClasses.aboveNormal]
    }
    dateToStateMap[dateStringKey] = classNames
  } else {
    // events are sorted by full_day (desc), hence dateToStateMap is populated by the
    // time we get here
    classNames = get(dateToStateMap, dateStringKey, [
      calendarTimeClasses.unknown,
    ]).slice()
    if (event.grouped) {
      classNames.push(calendarTimeClasses.grouped)
    }
  }
  return classNames
}

const getUrl = (event: CalendarEvent, workspaceId: ID): string => {
  let url: string = ''

  switch (true) {
    case event.type === 'subtask':
    case event.type === 'task':
      url = generateTaskDetailUrl(workspaceId, event.project_id, event.code)
      break

    case event.type === 'epic':
      url = generateEpicDetailUrl(workspaceId, event.project_id, event.id)
      break

    case event.type === 'sprint':
      url = generateSprintDetailUrl(workspaceId, event.project_id, event.id)
      break

    case event.type === 'project':
      url = generateProjectDetailUrl(workspaceId, event.project_id)
      break

    default:
      break
  }
  return url
}