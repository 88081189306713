import React, { useMemo } from 'react'
import classNames from 'classnames'
import { UserProfileSocialInput } from './UserProfileSocialInput'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import '../../styles/user-field.scss'

interface UserProfileSocialLinkProps {
  label: string
  value: string
  editable: boolean
  onChange: (value: string) => void
}

export function UserProfileSocialLink(props: UserProfileSocialLinkProps) {
  const { label } = props
  const classes = useMemo(
    () =>
      classNames(
        'user-profile__info',
        'user-info',
        `user-profile__info_${label}`
      ),
    [label]
  )
  if (!props.editable && !props.value) {
    return null
  }
  return (
    <div className={classes}>
      <UserProfileFieldTitle label={props.label} />
      {props.editable ? (
        <UserProfileSocialInput
          value={props.value}
          placeholder={'Enter account...'}
          editable={props.editable}
          onChange={props.onChange}
        />
      ) : (
        <a
          className="user-profile__info-link"
          href={props.value}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.value}
        </a>
      )}
    </div>
  )
}
