import React from 'react'

import './TaskType.css'

import { useReactNodeView } from '../../../core/react-node-view'
import { useProseMirrorContext } from '../../../core/provider'

export const TaskType: React.FC = () => {
  const { node } = useReactNodeView()
  const {
    providers: { taskTypeProvider: provider },
  } = useProseMirrorContext()
  const { name } = provider.useTaskType({ id: node?.attrs.id })

  return (
    <span className={'task-type task-types__type'}>
      {node?.attrs.prefix}
      {name}
    </span>
  )
}
