import OrderedMap from "orderedmap"
import {NodeSpec} from "prosemirror-model"
import { Extension } from "../.."
import { tableNodeSpecs } from "./nodes"

import "./styles.scss"

export class TableExtension extends Extension {
  addNodes = (nodes: OrderedMap<NodeSpec>) => nodes.append(tableNodeSpecs)
}
