import React, { useCallback } from 'react'
import { Select } from '@blueprintjs/select'
import { Button, Tooltip } from '@blueprintjs/core'
import { SizeChoice, SizeValue } from '../../../entities/choices/size'
import { selectItemRenderer, useSelect } from '../../../utils/select'
import classNames from 'classnames'

import '../../../assets/scss/select.scss'
import './styles/size-select.scss'

const TypedSizeSelect = Select.ofType<SizeChoice>()

export interface SizeSelectProps {
  size: string
  sizes: SizeChoice[]
  isInTask?: boolean | false
  disabled?: boolean | false
  isExtended?: boolean | false
  isEstimateNeeded?: boolean
  isEstimatePresent?: boolean
  isSpentTimeExceeded?: boolean
  isEstimateTimeExceeded?: boolean
  isSpentTimeAlmostExceeded?: boolean
  hasTooltip?: boolean
  onSelect: (size: string) => void
}

export function SizeSelect(props: SizeSelectProps) {
  const {
    disabled,
    isInTask,
    onSelect,
    isEstimateNeeded,
    isEstimatePresent,
    isSpentTimeExceeded,
    isEstimateTimeExceeded,
    isSpentTimeAlmostExceeded,
  } = props
  const onItemSelect = useCallback((size: SizeChoice) => onSelect(size.value), [
    onSelect,
  ])
  const [selectRef, showSelect, showSelectHandler] = useSelect<SizeChoice>()

  let size_obj = props.sizes.find((s) => s.value === props.size)

  let tooltipText: string = ''
  switch (true) {
    case !isEstimatePresent:
      tooltipText = "Couldn't load estimates"
      break

    case isSpentTimeExceeded:
      tooltipText = 'Spent time exceeds max. estimate'
      break

    case isSpentTimeAlmostExceeded:
      tooltipText = 'Spent time is close to max. estimate'
      break

    case isEstimateTimeExceeded:
      tooltipText = 'Total subtasks estimate exceeds max. estimate'
      break

    case isEstimateNeeded:
      tooltipText = 'Subtasks need estimates'
      break

    default:
      break
  }

  if (!size_obj) {
    return null
  }

  const selectClasses = classNames('size-select', 'select', {
    'size-select_disabled': disabled,
    'size-select_in-task': isInTask,
    'size-select_no-estimate':
      !isEstimatePresent || (isEstimateNeeded && size_obj.value !== SizeValue.U),
    'size-select_exceeded-spent':
      isSpentTimeExceeded && size_obj.value !== SizeValue.U,
    'size-select_exceeded-estimate':
      isEstimateTimeExceeded &&
      size_obj.value !== SizeValue.U &&
      !isSpentTimeExceeded &&
      !isSpentTimeAlmostExceeded,
    'size-select_almost-exceeded':
      isSpentTimeAlmostExceeded && size_obj.value !== SizeValue.U,
  })

  const firstTarget = (
    <div className={selectClasses}>
      <Button className="size-select__button" onClick={showSelectHandler}>
        {size_obj.label}
      </Button>
    </div>
  )

  if (!showSelect)
    return props.hasTooltip ? (
      <Tooltip content={tooltipText}>{firstTarget}</Tooltip>
    ) : (
      firstTarget
    )

  const target = (
    <Button className="size-select__button">{size_obj.label}</Button>
  )

  return (
    <>
      <TypedSizeSelect
        ref={selectRef}
        className={selectClasses}
        items={props.sizes}
        activeItem={size_obj}
        itemRenderer={selectItemRenderer}
        onItemSelect={onItemSelect}
        filterable={false}
        disabled={disabled}
        popoverProps={{
          minimal: true,
          position: 'bottom',
          popoverClassName: 'popover popover_center-text',
        }}
      >
        {props.hasTooltip ? (
          <Tooltip position="right" content={tooltipText}>{target}</Tooltip>
        ) : (
          target
        )}
      </TypedSizeSelect>
    </>
  )
}
