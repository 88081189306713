import { http } from '../../../globals/http'
import { SmartSuiteAccount } from './account.model'

export class SmartSuiteAccountDataService {
  list() {
    return http.get<SmartSuiteAccount[]>(
      '/api/integrations/smartsuite/accounts/'
    )
  }
}

export const smartSuiteAccountDataService = new SmartSuiteAccountDataService()
