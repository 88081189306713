import { ID } from '@datorama/akita'
import { http } from '../../globals/http'
import { Sprint } from './model'
import {ModelUpdate} from "../../utils/types";

export class SprintDataService {
  get(id: ID) {
    return http.get<Sprint>(`/api/sprint/${id}/`)
  }

  destroy(id: ID) {
    return http.delete(`/api/sprint/${id}/`)
  }

  list(query?: object) {
    return http.get<Sprint[]>('/api/sprint/', query)
  }

  create(item: Partial<Sprint>) {
    return http.post<Sprint>('/api/sprint/', item)
  }

  update(update: ModelUpdate<Sprint>) {
    return http.patch<Sprint>(`/api/sprint/${update.id}/`, update)
  }

  download(id: ID) {
    return http.get<Sprint>(`/api/sprint/${id}/download/`, )
  }

}

export const sprintDataService = new SprintDataService()
