import { ID, QueryEntity } from '@datorama/akita'
import { SubtaskState, SubtaskStore, subtaskStore } from './store'
import {boundMethod} from "autobind-decorator";

export class SubtaskQuery extends QueryEntity<SubtaskState> {
  loaded$ = this.select('loaded')
  active$ = this.selectActive()

  forTask(taskId: ID) {
    return this.selectAll({
      filterBy: (subtask) => subtask.task === taskId && subtask.deleted_at === null
    })
  }

  forTasks(taskIds: ID[]) {
    return this.selectAll({
      filterBy: (subtask) => taskIds.includes(subtask.task) && subtask.deleted_at === null
    })
  }

  forTaskWithAllSubtasks(taskId: ID) {
    return this.selectAll({
      filterBy: (subtask) => subtask.task === taskId
    })
  }

  @boundMethod
  getBySubtaskID(subtaskId: ID) {
    return this.selectEntity(({ id }) => id === subtaskId)
  }

  @boundMethod
  getForTask(taskId: ID) {
    return this.getAll({
      filterBy: (subtask) => subtask.task === taskId && subtask.deleted_at === null
    })
  }

  getForTaskWithDeleted(taskId: ID) {
    return this.getAll({
      filterBy: (subtask) => subtask.task === taskId
    })
  }

  constructor(protected store: SubtaskStore) {
    super(store)
  }
}

export const subtaskQuery = new SubtaskQuery(subtaskStore)
