import OrderedMap from "orderedmap"
import {MarkSpec, Schema} from "prosemirror-model"
import { Extension } from "../.."
import { strikethroughMark } from "./marks"
import {Plugin} from "prosemirror-state";
import {keymap} from "prosemirror-keymap";
import {toggleMark} from "prosemirror-commands";


export class StrikethroughExtension extends Extension {
  addMarks = (marks: OrderedMap<MarkSpec>): OrderedMap<MarkSpec> => marks.append(strikethroughMark)

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    keymap({'Mod-Shift-s': toggleMark(schema.marks.strikethrough)}),
  ]
}
