import { ActivityLogStore, activityLogStore } from './store'
import { ActivityLogDataService, activityLogDataService } from './data-service'
import { ActivityLogQuery, activityLogQuery } from './query'
import { boundMethod } from 'autobind-decorator'


export class ActivityLogService {
  constructor(
    private store: ActivityLogStore,
    private query: ActivityLogQuery,
    private dataService: ActivityLogDataService
  ) {}

  @boundMethod
  loadActivities(query?: object) {
    this.dataService.list(query).subscribe((activities) => {
      this.store.add(activities)
    })
  }

  @boundMethod
  loadSubtaskActivities(query?: object) {
    this.dataService.list(query).subscribe((activities) => {
      this.store.add(activities)
    })
  }
}

export const activityLogService = new ActivityLogService(
  activityLogStore,
  activityLogQuery,
  activityLogDataService
)
