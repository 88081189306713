import { ID, Store, StoreConfig } from '@datorama/akita'

export interface AuthState {
  currentUserId: ID | null
  checked: boolean
  inviteChecked: boolean
  authPending: boolean
  authErrors: { [name: string]: string } | null
  loginRedirectTo: string
  inviteData: { invite: number, email: string } | null
}

const initialState = {
  currentUserId: null,
  checked: false,
  inviteChecked: false,
  authPending: false,
  authErrors: null,
  loginRedirectTo: '/',
  inviteData: null
}

@StoreConfig({ name: 'ui-auth' })
export class AuthStore extends Store<AuthState> {}

export const authStore = new AuthStore(initialState)
