import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Alert, Button, Form, Input, Typography } from 'antd'
import { authService } from './state/service'
import { useQuery } from '../utils/queryhook'
import { authQuery } from './state/query'
import { Loader } from '../main-page/Loader'

import './styles/login-form.scss'

export function LoginPageContainer() {
  const checked = useQuery(authQuery.checked$)
  const authenticated = useQuery(authQuery.authenticated$)
  const authPending = useQuery(authQuery.authPending$)
  const authErrors = useQuery(authQuery.authErrors$)
  const loginRedirectTo = useQuery(authQuery.redirectTo$)

  const onFinish = (values: { email: string; password: string }) => {
    authService.authenticate(values.email, values.password)
  }

  useEffect(() => {
    authService.checkAuthentication(true)
  }, [])

  if (!checked) {
    return <Loader />
  }

  if (authenticated) {
    return <Redirect to={loginRedirectTo} />
  }

  return (
    <LoginPage
      authPending={authPending}
      authErrors={authErrors}
      onFinish={onFinish}
    />
  )
}

export interface LoginPageProps {
  authPending: boolean
  authErrors: { [name: string]: string } | null
  onFinish: (values: any) => void
}

export function LoginPage(props: LoginPageProps) {
  const tailLayout = {
    wrapperCol: { offset: 9 },
  }

  return (
    <Form className="login-form" onFinish={props.onFinish} layout="vertical">
      <Form.Item {...tailLayout}>
        <Typography.Title level={3}>Login</Typography.Title>
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        validateStatus={!!props.authErrors?.email ? 'error' : ''}
        help={props.authErrors?.email}
        rules={[
          { required: true, message: 'Field is required' },
          { type: 'email', message: 'Enter valid email address' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        validateStatus={!!props.authErrors?.password ? 'error' : ''}
        help={props.authErrors?.password}
        rules={[
          { required: true, message: 'Field is required' },
          { whitespace: true, message: 'This field may not be blank' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      {!!props.authErrors?.detail && (
        <Alert
          style={{ marginBottom: 24 }}
          message={props.authErrors.detail}
          type="error"
          showIcon
        />
      )}

      <Form.Item className="login-form-btn">
        <Button type="primary" htmlType="submit" disabled={props.authPending}>
          Sign in
        </Button>
      </Form.Item>

      {/*<Form.Item className="login-form-btn">*/}
      {/*  <Button type="primary" htmlType="submit">*/}
      {/*    Sign In with Google*/}
      {/*  </Button>*/}
      {/*</Form.Item>*/}
    </Form>
  )
}
