import React, { Ref, useCallback, useMemo } from 'react'
import { Typography } from 'antd'
import { ReportStatistics, ReportStatisticsTshirt } from '../../entities/report-statistics/model'
import { ReportTableComponent } from '../ReportTableComponent'
import { ParseReportStatisticsTshirtData } from './parsers/tshirt'

const reportProjectTableColumns = [
  { title: 'User', dataIndex: 'assignee_name', key: 'assignee_name' },
  { title: 'Role', dataIndex: 'role', key: 'role', },
  { title: 'U', dataIndex: 'avg_time_u', key: 'avg_time_u' },
  { title: 'XS', dataIndex: 'avg_time_xs', key: 'avg_time_xs' },
  { title: 'S', dataIndex: 'avg_time_s', key: 'avg_time_s' },
  { title: 'M', dataIndex: 'avg_time_m', key: 'avg_time_m' },
  { title: 'L', dataIndex: 'avg_time_l', key: 'avg_time_l' },
  { title: 'XL', dataIndex: 'avg_time_xl', key: 'avg_time_xl' },
]

interface ReportStatisticsTshirtProps {
  report: ReportStatistics
  tableContainerRef: Ref<HTMLDivElement>
}

export function ReportStatisticsTshirtComponent(props: ReportStatisticsTshirtProps) {
  // const { report } = props
  const report = props.report as ReportStatisticsTshirt
  const projectName = report?.project_name

  const reportData = useMemo(() => {
    if (report == null) {
      return []
    }
    return ParseReportStatisticsTshirtData(report)
  }, [report])

  const titleCallback = useCallback(() => {
    if (!projectName) {
      return null
    }
    return (
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        Time report: {projectName}
      </Typography.Title>
    )
  }, [projectName])

  const rowClassNameCallback = useCallback((record: any, index: number) => {
    switch (record.key) {
      case 'total':
        return 'table-row-bold table-row-title'
    }
    return ''
  }, [])

  return (
    <ReportTableComponent
      tableColumns={reportProjectTableColumns}
      reportData={reportData}
      titleCallback={titleCallback}
      rowClassNameCallback={rowClassNameCallback}
      tableContainerRef={props.tableContainerRef}
    />
  )
}
