import React from 'react'
import { RemoveFollowerAction } from '../../entities/activities/model'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {User} from "../../entities/user/model";

interface RemoveFollowerActivityProps {
  action: RemoveFollowerAction
  date: string
  user: User | string
}

export function RemoveFollowerActivity(props: RemoveFollowerActivityProps) {
  const { action, user } = props

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> removed{' '}
      <ActivityBoldText>{action.data.changed_from.label}</ActivityBoldText>{' '} from this task followers.
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
