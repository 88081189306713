import { isEqual, map, sortBy, uniqBy } from 'lodash'
import { ID } from '@datorama/akita'
import { PickProperties } from './types'

export const SORT_STEP = 1000

export function safeFindBy<T, K extends keyof T, V extends T[K]>(
  list: T[] | undefined,
  key: K,
  value: V | undefined
) {
  return (list || []).find((item) => item[key] === value) || null
}

/**
 * Re-order `listItem` inside `originalList`
 * @param listItem
 * @param originalList
 * @param orderField
 * @param index
 */
export function simpleListReOrder<T extends { id: ID }, P extends keyof T>(
  listItem: T,
  originalList: T[],
  orderField: P,
  index: number
) {
  // Nothing needs to be reordered
  if (originalList.length === 1) return []

  originalList = sortBy(originalList, orderField)

  // Remove item from list if present
  const origIdx = originalList.findIndex((item) => item.id === listItem.id)
  if (origIdx !== -1) {
    originalList.splice(origIdx, 1)
  }

  // Move item to the new place
  originalList.splice(index, 0, listItem)

  const updateUntil = origIdx > index ? origIdx : index

  // Reorder all items in list
  return map(originalList.slice(0, updateUntil + 1), (item, idx) => ({
    id: item.id,
    [orderField]: idx + 1,
  }))
}

/**
 * Determine real index for the filtered list
 * @param originalList
 * @param filteredList
 * @param orderField
 * @param index
 */
export function determineRealIndexInFilteredList<
  T extends { id: ID },
  K extends keyof T
>(originalList: T[], filteredList: T[], orderField: K, index: number): number {
  originalList = sortBy(originalList, orderField)
  filteredList = sortBy(filteredList, orderField)

  // Last position check
  if (index === originalList.length) return index

  if (index === filteredList.length) return index

  // IN OTHER CASE IF WE DON"T NEED ACTUAL TASK IN LIST https://timer.gearheart.io/1/95/task/TM3-34742
  // if (index === filteredList.length) {
  //   const targetElement = filteredList[filteredList.length - 1]
  //   const originalIndex = originalList.findIndex(
  //   (item) => item.id === targetElement.id
  // )
  //   return originalIndex + 1
  // }

  const targetElement = filteredList[index]

  // Find index of target element in originalList
  const originalIndex = originalList.findIndex(
    (item) => item.id === targetElement.id
  )

  // Real position is not equal to the specified
  if (originalIndex !== index) return originalIndex

  return index
}

/**
 * Smart re-order `listItem` inside `originalList`
 * @param listItem
 * @param originalList
 * @param orderField
 * @param index
 * @param orderStep
 */
export function smartListReOrder<
  T extends { id: ID },
  K extends PickProperties<T, number>
>(
  listItem: any, //T,
  originalList: any[], //T[],
  orderField: string, //K,
  index: number,
  orderStep: number = SORT_STEP
) {
  originalList = sortBy(originalList, orderField)

  // Remove item from list if present
  const origIdx = originalList.findIndex((item) => item.id === listItem.id)
  if (origIdx !== -1) {
    originalList.splice(origIdx, 1)
  }

  // Move item to the new place
  originalList.splice(index, 0, listItem)

  // Now we need to calculate order number that will produce needed ordering
  if (originalList.length === 1) {
    listItem[orderField] = 0
  } else if (index === 0) {
    listItem[orderField] = originalList[1][orderField] - orderStep
  } else if (index === originalList.length - 1) {
    listItem[orderField] = originalList[index - 1][orderField] + orderStep
  } else {
    // when inserting between two iterms - we take middle of their order numbers
    listItem[orderField] = Math.round(
      (originalList[index - 1][orderField] +
        originalList[index + 1][orderField]) /
        2
    )
  }

  // now we need to check if order numbers are not duplicated and produce
  // desired ordering
  const ordersUnique =
    uniqBy(originalList, orderField).length === originalList.length
  const orderSame = isEqual(
    map(originalList, 'id'),
    map(sortBy(originalList, orderField), 'id')
  )

  // if everything is fine - we just update one task
  let updates = [{ id: listItem.id, [orderField]: listItem[orderField] }]

  if (ordersUnique && orderSame) return updates

  // ok, something is wrong
  // we just update orders for several tasks in the list
  return checkForDuplicates(originalList, orderField, orderStep, listItem)
}

/**
 * Determine real index for the filtered list
 * @param originalList
 * @param orderField
 * @param orderStep
 * @param listItem
 */
export function checkForDuplicates<T extends { id: ID }, K extends keyof T>(
  originalList: any[],
  orderField: string, //K,
  orderStep: number,
  listItem: any
) {
  let updates: any[] = []
  let itemsToUpdate = [listItem]
  let newIdx = originalList.findIndex((item) => item.id === listItem.id)
  let delta: number = 0

  while (true) {
    // get next item from list
    let nextItem = originalList.slice(newIdx + 1)[0]
    // if we have next item, weg are getting delta between them
    if (nextItem) {
      delta = nextItem[orderField] - listItem[orderField]
    } else {
      // if no, we get items that we need to update and multiply it by 3(to update extra items on future)
      delta = itemsToUpdate.length * 3
      break
    }
    if (delta <= itemsToUpdate.length) {
      itemsToUpdate.push(nextItem)
      newIdx = originalList.findIndex((item) => item.id === nextItem.id)
    } else {
      break
    }
  }

  newIdx = originalList.findIndex((item) => item.id === listItem.id)

  // we check if previous item has the same order number
  while (true) {
    // get prev item from list
    let prevItem = originalList[newIdx - 1]
    // if we have prev item, we check if they order number are the same
    if (prevItem) {
      if (listItem[orderField] === prevItem[orderField]) {
        itemsToUpdate.unshift(prevItem)
        // check new previous item
        newIdx = originalList.findIndex((item) => item.id === prevItem.id)
      } else {
        break
      }
    } else {
      break
    }
  }
  // we get step to add to order field
  const step = Math.round(delta / itemsToUpdate.length)
  updates = map(itemsToUpdate, (task, index) => ({
    id: task.id,
    [orderField]: task[orderField] + step * index,
  }))
  return updates
}
