import './styles/filter-button.scss'

import React, { useCallback } from 'react'
import classNames from 'classnames'
import { FilterOutlined } from '@ant-design/icons'

interface FilterButtonProps {
  className: string
  showFilters: boolean
  onToggleFilters: (value: boolean) => void
}

export function FilterButton(props: FilterButtonProps) {
  const { showFilters, onToggleFilters } = props

  const toggleButton = useCallback(() => onToggleFilters(!showFilters), [
    showFilters,
    onToggleFilters,
  ])

  return (
    <button
      className={classNames('filter-button', props.className, {
        'is-active': showFilters,
      })}
      onClick={toggleButton}
    >
      <FilterOutlined className="filter-button__filter-icon" />
    </button>
  )
}
