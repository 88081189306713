import React, {useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import {ChangeProjectLogoAction} from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {Avatar} from "antd";


interface ChangeProjectLogoActivityProps {
  action: ChangeProjectLogoAction
  date: string
  user: User | string
}

export function ChangeProjectLogoActivity(
  props: ChangeProjectLogoActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText>{' '}
      updated the project logo.{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <Avatar
            src={action.data.changed_to}
            shape="square"
            className={"project-details__project-logo"}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
