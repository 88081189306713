import React, { useCallback, useState, useEffect } from 'react'
import { CommentOutlined } from '@ant-design/icons'
import { Button, Form, Input, Popover } from 'antd'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import './styles/tracker-comment-control.scss'

export function TrackerCommentControl({
  initial,
  onComment,
  required,
}: {
  initial: string
  required: boolean
  onComment: (comment: string) => void
}) {
  const [comment, setComment] = useState<string>(initial)
  const [visible, setVisible] = useState<boolean>(required)

  useEffect(() => {
    setVisible(required)
  }, [required])

  const toggleVisibility = useCallback(() => {
    setVisible(!visible)
    if (visible) {
      setComment(initial)
    }
  }, [initial, visible])

  const submit = useCallback(() => {
    setVisible(false)
    onComment(comment)
  }, [onComment, comment])

  const content = (
    <div className="tracker-comment-control">
      <div className="tracker-comment-control__title">
        <span className="tracker-comment-control__title-text">
          Comment current activity
        </span>{' '}
        <Icon
          className="tracker-comment-control__close-icon"
          onClick={toggleVisibility}
          icon={IconNames.CROSS}
          iconSize={12}
        />
      </div>
      <Form className="tracker-comment-control__form" onFinish={submit}>
        <Input.TextArea
          placeholder="Describe your last activity"
          autoFocus={true}
          autoSize={true}
          className="tracker-comment-control__textarea"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
        <Button
          className="tracker-comment-control__submit"
          disabled={!comment}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  )

  return (
    <Popover
      visible={visible}
      content={content}
      trigger="click"
      onVisibleChange={toggleVisibility}
      overlayClassName="tracker-comment-popover"
    >
      {required ? (
        <CommentOutlined style={{ color: 'red' }} />
      ) : (
        <CommentOutlined className="comment-outlined" />
      )}
    </Popover>
  )
}
