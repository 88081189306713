import React, {useContext, useState} from 'react'
import { Collapse } from '@blueprintjs/core'

import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'

import '../../styles/task-activity.scss'
import { ChangeTaskAssigneeAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {Subtask} from "../../../entities/subtask/model";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";

interface ChangeSubtaskAssigneeActivityProps {
  action: ChangeTaskAssigneeAction
  date: string
  user: User | string
}

export function ChangeSubtaskAssigneeActivity(props: ChangeSubtaskAssigneeActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }
  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      reassigned the {action.target.type} <HighlightedActivity>{subtask?.name}</HighlightedActivity> to{' '}
      <ActivityBoldText>{action.data.changed_to ? action.data.changed_to.label: 'Unassigned'}</ActivityBoldText>.{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <ActivityBoldText>
            {action.data.changed_from ? action.data.changed_from.label: 'Unassigned'}
          </ActivityBoldText>
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
