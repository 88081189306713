import { Store, StoreConfig } from '@datorama/akita'
import { UserStatus } from '../../entities/user/model'
import { Project } from '../../entities/project/model'

export interface UsersPageState {
  searchQuery: string
  showFiltersPanel: boolean
  projectFilter: Project | null
  statusFilters: UserStatus[]
}

export const usersPageStoreInitialState = {
  searchQuery: '',
  showFiltersPanel: false,
  projectFilter: null,
  statusFilters: [UserStatus.ACTIVE, UserStatus.INVITED],
}

@StoreConfig({ name: 'ui-users-page' })
export class UsersPageStore extends Store<UsersPageState> {}

export const usersPageStore = new UsersPageStore(usersPageStoreInitialState)
