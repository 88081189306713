import React from 'react'
import { ErrorPageNoAccess } from './error-page-no-access/ErrorPageNoAccess'
import { ErrorPageNotFound } from './error-page-not-found/ErrorPageNotFound'

export default {
  title: 'Pages/Errors',
}

export const NoAccess: React.FunctionComponent = () => {
  return <ErrorPageNoAccess />
}

export const NotFound: React.FunctionComponent = () => {
  return <ErrorPageNotFound />
}
