import React from 'react'
import './styles/add-time-manually-comment.scss'
import './styles/add-time-manually-input.scss'

interface AddTimeManuallyCommentProps {
  value: string
  onChange: (value: string) => void
}

export function AddTimeManuallyComment(props: AddTimeManuallyCommentProps) {
  return (
    <div className="add-time-manually-comment add-time-manually-input">
      <textarea
        placeholder="Enter comment (required)"
        maxLength={200}
        onChange={(e) => {
          props.onChange(e.target.value)
        }}
        value={props.value}
      />
    </div>
  )
}
