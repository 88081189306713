import { applyTransaction, ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { keys, pick, isEqual } from 'lodash'
import { Subject } from 'rxjs'

import { EpicDataService, epicDataService } from './data-service'
import { Epic } from './model'
import { epicQuery, EpicQuery } from './query'
import { EpicStore, epicStore } from './store'

import { optimisticSaveEnabled } from '../../globals/constants'
import { hashObj } from '../../utils/hash'
import { smartListReOrder } from '../../utils/lists'
import { messageDispatcher } from '../../utils/message-dispatcher'
import { ModelUpdate } from '../../utils/types'

export class EpicService {
  constructor(
    private store: EpicStore,
    private dataService: EpicDataService,
    private query: EpicQuery
  ) {}

  create(item: Partial<Epic>) {
    const result = new Subject<Epic>()

    this.dataService.create(item).subscribe(
      (epic: Epic) => {
        this.store.add(epic)
        result.next(epic)
        result.complete()
        messageDispatcher.putSuccessMessage(
          `Epic ${epic.name} has been created`
        )
      },
      (error) => {
        result.error(error)
      }
    )
    return result.asObservable()
  }

  loadEpic(id: ID) {
    this.dataService.get(id).subscribe((epic) => {
      this.store.add(epic)
    })
  }

  ensureEpicsLoaded(query?: object) {
    const loadedHashes = this.query.getLoadedHashes()
    if (!query || !loadedHashes.includes(hashObj(query))) {
      this.loadEpics(query)
    }
  }

  loadEpics(query?: object) {
    this.dataService.list(query).subscribe((epics) => {
      this.store.add(epics)
      if (query) {
        this.store.update((state) => ({
          ...state,
          loaded: [...state.loaded, hashObj(query)],
        }))
      }
    })
  }

  @boundMethod
  destroy(id: ID) {
    const epic: Epic = { ...this.query.getEntity(id) }
    this.store.remove(id)

    this.dataService.destroy(id).subscribe({
      next: () => {
        messageDispatcher.putSuccessMessage(
          `Epic ${epic.name} has been deleted`
        )
      },
      error: () => {
        this.store.add(epic)
      },
    })
  }

  @boundMethod
  moveEpic(id: ID, index: number) {
    const epic: Epic = { ...this.query.getEntity(id) }
    const epics: Epic[] = this.query.getForProject(epic.project)
    const updates = smartListReOrder(epic, epics, 'order', index)

    applyTransaction(() => {
      updates.forEach((update) => this.updateOptimistic(update))
    })
  }

  @boundMethod
  updateEpic(update: ModelUpdate<Epic>) {
    this.updateOptimistic(update)
  }

  /**
   * We first update the value and then try to send it to the backend.
   *
   * If save fails - we rollback the change.
   *
   */
  private updateOptimistic(update: ModelUpdate<Epic>) {
    const currentEpic = this.query.getEntity(update.id)
    const currentValues = pick(currentEpic, keys(update))

    if (optimisticSaveEnabled) {
      this.store.update(update.id, update)
    }

    this.dataService.update(update).subscribe({
      next: (value: ModelUpdate<Epic>) => {
        const updatedValues = pick(value, keys(update))
        // Update store only in case BE returns different values
        if (!isEqual(updatedValues, update)) {
          this.store.update(value.id, updatedValues)
        }

        if (update.hasOwnProperty('name')) {
          messageDispatcher.putSuccessMessage(
            `Epic ${update.name} has been renamed`
          )
        } else if (update.hasOwnProperty('status')) {
          if (update.status === 'closed') {
            messageDispatcher.putSuccessMessage(
              `Epic ${currentEpic.name} has been closed`
            )
          } else if (update.status === 'active') {
            messageDispatcher.putSuccessMessage(
              `Epic ${currentEpic.name} has been started`
            )
          }
        } else {
          messageDispatcher.putSuccessMessage(
            `Epic ${currentEpic.name} details have been updated`
          )
        }
      },
      error: () => {
        this.store.update(update.id, currentValues)
      },
    })
  }
}

export const epicService = new EpicService(
  epicStore,
  epicDataService,
  epicQuery
)
