import React, { Ref, useCallback, useMemo, useRef } from 'react'
import { ID } from '@datorama/akita'
import { DateRange } from '@blueprintjs/datetime'
import { Layout, message } from 'antd'
import { User } from '../../entities/user/model'
import { ReportDev } from '../../entities/report-dev/model'
import { useQuery } from '../../utils/queryhook'
import { reportDevPageQuery } from './state/query'
import { reportDevService } from './state/service'
import { ReportDevComponent } from './ReportDevComponent'
import { ReportApplyFiltersText } from '../ReportApplyFiltersText'
import { ReportHeader } from '../ReportHeader'
import { ReportDevFiltersList } from './ReportDevFiltersList'
import { copyElementContentsToClipboard } from '../utils'
import { Spinner } from '../../components/Spinner'
import { useInstantEffect } from '../../utils/instant-effect-hook'
import { Header } from '../../components/header/Header'
import { CustomScrollbar } from '../../components/custom-scrollbar/CustomScrollbar'

export function ReportDevPageContainer() {
  const users = useQuery(reportDevPageQuery.users$)
  const selectedUserID = useQuery(reportDevPageQuery.selectedUserID$)
  const startDate = useQuery(reportDevPageQuery.startDate$)
  const endDate = useQuery(reportDevPageQuery.endDate$)
  const loading = useQuery(reportDevPageQuery.loading$)
  const report = useQuery(reportDevPageQuery.report$)

  const showFiltersPanel = useQuery(reportDevPageQuery.showFiltersPanel$)

  useInstantEffect(() => {
    if (selectedUserID && startDate && endDate) {
      reportDevService.fetchReport(selectedUserID, startDate, endDate)
    } else {
      reportDevService.resetReport()
    }
  }, [selectedUserID, startDate, endDate])

  const onChangeSelectedUserID = (userID: ID | null) => {
    reportDevService.setSelectedUserID(userID)
  }

  const onChangeDates = (dates: DateRange) => {
    reportDevService.setDates(dates)
  }

  const onClearFilters = () => {
    reportDevService.clearFilters()
  }

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const onCopyReport = useCallback(() => {
    if (tableContainerRef.current) {
      const success = copyElementContentsToClipboard(tableContainerRef.current)
      if (success) {
        message.success('Report copied to clipboard.')
      } else {
        message.error('Error copying report to clipboard.')
      }
    }
  }, [tableContainerRef])

  return (
    <ReportDevPage
      loading={loading}
      users={users}
      selectedUserID={selectedUserID}
      startDate={startDate}
      endDate={endDate}
      report={report}
      onChangeSelectedUserID={onChangeSelectedUserID}
      onChangeDates={onChangeDates}
      onClearFilters={onClearFilters}
      showFiltersPanel={showFiltersPanel}
      onToggleFiltersPanel={reportDevService.setShowFiltersPanel}
      tableContainerRef={tableContainerRef}
      onCopyReport={onCopyReport}
    />
  )
}

interface ReportDevPageProps {
  loading: boolean
  users: User[]
  selectedUserID: ID | null
  startDate: Date | null
  endDate: Date | null
  report: ReportDev | null
  onChangeSelectedUserID: (userID: ID | null) => void
  onChangeDates: (dates: DateRange) => void
  onClearFilters: () => void
  showFiltersPanel: boolean
  onToggleFiltersPanel: (value: boolean) => void
  tableContainerRef: Ref<HTMLDivElement>
  onCopyReport: () => void
}

function ReportDevPage(props: ReportDevPageProps) {
  const { onToggleFiltersPanel, selectedUserID, users } = props
  const onShowFiltersPanel = useCallback(
    () => onToggleFiltersPanel(true),
    [onToggleFiltersPanel]
  )

  const selectedUser = useMemo(
    () => users.find((u) => u.id === selectedUserID),
    [selectedUserID, users]
  )

  return (
    <Layout className={'report-page'} style={{}}>
      <Header title="Report: Dev" />
      <Layout.Content style={{ display: 'flex', flex: '1 1 0' }}>
        <ReportDevFiltersList
          show={props.showFiltersPanel}
          users={props.users}
          selectedUserID={props.selectedUserID}
          startDate={props.startDate}
          endDate={props.endDate}
          onChangeSelectedUserID={props.onChangeSelectedUserID}
          onChangeDates={props.onChangeDates}
          onClearFilters={props.onClearFilters}
        />
        <CustomScrollbar
          className="report-page__main"
          theme="light"
          rendererHeight="auto"
          contentHeight="100%"
          contentStyle={{
            height: 'auto',
            display: 'flex',
            minHeight: '100%',
          }}
        >
          <Layout.Content style={{ flex: '1 1 0' }}>
            <Layout.Content
              className={'report-page__content'}
              style={{ backgroundColor: 'var(--color-background)' }}
            >
              <ReportHeader
                user={selectedUser}
                startDate={props.startDate}
                endDate={props.endDate}
                showFiltersPanel={props.showFiltersPanel}
                onToggleFiltersPanel={onToggleFiltersPanel}
                copyReportButtonActive={!!props.selectedUserID}
                onCopyReport={props.onCopyReport}
              />
              {props.loading ? (
                <Spinner />
              ) : props.report ? (
                <ReportDevComponent
                  report={props.report}
                  tableContainerRef={props.tableContainerRef}
                />
              ) : (
                <ReportApplyFiltersText onClick={onShowFiltersPanel} />
              )}
            </Layout.Content>
          </Layout.Content>
        </CustomScrollbar>
      </Layout.Content>
    </Layout>
  )
}
