import React from 'react'
import { ID } from '@datorama/akita'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'

import { Subtask, SubtaskEstimate } from '../../../entities/subtask/model'
import { SubtaskListItem } from './SubtaskListItem'
import { ModelUpdateCallback } from '../../../utils/types'
import {
  DragObject,
  subtaskDraggableId,
  subtaskDroppableId,
  TASKS_IN_OUT_DROP_TYPE,
} from '../../drag-n-drop'
import { Task } from '../../../entities/task/model'
import { ShortUser, User } from '../../../entities/user/model'
import { TrackingState } from '../../../tracking/state/store'
import { StartTrackingCallback } from '../../../tracking/types'
import { IFormErrors } from '../../state/store'

export interface SubtasksListProps {
  users: User[] | ShortUser[]
  task: Task
  subtasks: Subtask[]
  onSubtaskUpdate: ModelUpdateCallback<Subtask>
  onSubtaskAddEstimate: (
    subtaskID: ID,
    estimate: Partial<SubtaskEstimate>
  ) => void
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onSubtaskDelete: (id: ID) => void
  dragObject: DragObject | undefined
  trackingState?: TrackingState
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function SubtasksList(props: SubtasksListProps) {
  return (
    <Droppable
      droppableId={subtaskDroppableId(props.task)}
      type={TASKS_IN_OUT_DROP_TYPE}
      isDropDisabled={props.dragObject?.type !== 'subtask'}
    >
      {(provided: DroppableProvided) => (
        <div ref={provided.innerRef} className={'subtasks__list'}>
          {props.subtasks.map((subtask, index) => (
            <Draggable
              draggableId={subtaskDraggableId(subtask)}
              index={index}
              key={subtask.id}
            >
              {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
              ) => (
                <SubtaskListItem
                  key={subtask.id}
                  users={props.users}
                  subtask={subtask}
                  onSubtaskUpdate={props.onSubtaskUpdate}
                  onSubtaskDelete={props.onSubtaskDelete}
                  onSubtaskAddEstimate={props.onSubtaskAddEstimate}
                  innerRef={provided.innerRef}
                  isDragging={snapshot.isDragging}
                  dragHandleProps={provided.dragHandleProps}
                  draggableProps={provided.draggableProps}
                  trackingState={props.trackingState}
                  onStartTracking={props.onStartTracking}
                  onStopTracking={props.onStopTracking}
                  addTimeFormErrors={props.addTimeFormErrors}
                  isAddTimeFormOpened={props.isAddTimeFormOpened}
                  onOpenAddTimeModal={props.onOpenAddTimeModal}
                  onCloseAddTimeModal={props.onCloseAddTimeModal}
                />
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
