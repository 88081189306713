import React from 'react'
import './styles/project-page-filter-target.scss'
import classNames from 'classnames'

interface ProjectPageFilterTargetProps {
  isActive?: boolean
  onClick: () => void
}

export function ProjectPageFilterTarget(props: ProjectPageFilterTargetProps) {
  const { isActive, onClick } = props
  const classes = classNames('project-page-filter-target', {
    'is-active': isActive,
  })

  return (
    <span className={classes} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="icon-filter"
        viewBox="0 0 512 512"
        preserveAspectRatio="none"
        fill="currentColor"
      >
        <path d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z" />
      </svg>
    </span>
  )
}
