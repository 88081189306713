import React from 'react'

export type EpicAndSprintType =
  | 'active'
  | 'closed'
  | 'draft'
  | 'started'
  | 'complete'
  | 'on-hold'
  | 'cancelled'

type EpicAndSprintTypeMapping = {
  [key in EpicAndSprintType]: string
}

const getEpicAndSprintTypeMapping: EpicAndSprintTypeMapping = {
  draft: '#2f54eb',
  active: '#389e0d',
  closed: 'var(--color-text-inactive)',
  started: 'var(--color-text-inactive)',
  complete: 'var(--color-text-inactive)',
  cancelled: 'var(--color-text-inactive)',
  'on-hold': 'var(--color-text-inactive)',
}

interface HighlightEpicAndSprintProps {
  label: string
  value: EpicAndSprintType
}

export function HighlightEpicAndSprint(props: HighlightEpicAndSprintProps) {
  return (
    <span style={{ color: `${getEpicAndSprintTypeMapping[props.value]}` }}>
      {props.label}
    </span>
  )
}
