import classNames from 'classnames'
import React from 'react'
import './styles/add-time-manually-title.scss'

interface AddTimeManuallyTitleProps {
  children: React.ReactNode
  className?: string
}

export function AddTimeManuallyTitle(props: AddTimeManuallyTitleProps) {
  const classes = classNames('add-time-manually-title', props.className)
  return <div className={classes}>{props.children}</div>
}
