import React from 'react'
import { ID } from '@datorama/akita'

import {
  Subtask,
  SubtaskEstimate,
  SubtaskStatus,
  SubtaskType,
} from '../../../entities/subtask/model'
import { SubtasksList } from './SubtasksList'
import { SubtasksHeader } from './SubtasksHeader'
import { AddSubtask } from './AddSubtask'
import {
  ModelCreateSubtaskCallback,
  ModelUpdateCallback,
} from '../../../utils/types'

import '../styles/subtasks.scss'
import { ShortUser } from '../../../entities/user/model'
import { Task } from '../../../entities/task/model'
import { StartTrackingCallback } from '../../../tracking/types'
import { DragObject } from '../../drag-n-drop'
import { TrackingState } from '../../../tracking/state/store'
import { IFormErrors } from '../../state/store'

export interface SubtasksProps {
  users: ShortUser[]
  task: Task
  subtasks: Subtask[]
  onSubtaskAdd: ModelCreateSubtaskCallback<Subtask>
  onSubtaskUpdate: ModelUpdateCallback<Subtask>
  onSubtaskAddEstimate: (
    subtaskID: ID,
    estimate: Partial<SubtaskEstimate>
  ) => void
  onFilterSubtasksByType: (value: SubtaskType) => void
  onFilterSubtasksByStatus: (value: SubtaskStatus) => void
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onSubtaskDelete: (id: ID) => void
  dragObject: DragObject | undefined
  selectedSubtaskFilter?: { types: SubtaskType[]; statuses: SubtaskStatus[] }
  trackingState?: TrackingState
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function Subtasks(props: SubtasksProps) {
  const {
    users,
    task,
    subtasks,
    onSubtaskAdd,
    onSubtaskDelete,
    onSubtaskUpdate,
    onSubtaskAddEstimate,
    onFilterSubtasksByType,
    onFilterSubtasksByStatus,
    onStartTracking,
    onStopTracking,
    dragObject,
    selectedSubtaskFilter,
    trackingState,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props

  return (
    <div className="subtasks">
      <div className="subtasks__header">
        <SubtasksHeader
          onFilterSubtasksByType={onFilterSubtasksByType}
          onFilterSubtasksByStatus={onFilterSubtasksByStatus}
          subtasks={subtasks}
          selectedSubtaskFilter={selectedSubtaskFilter}
        />
      </div>
      <AddSubtask
        placeholder="Quick Add Subtask"
        onSubtaskAdd={onSubtaskAdd}
        subtasks={subtasks}
      />

      <SubtasksList
        users={users}
        task={task}
        subtasks={subtasks}
        onSubtaskUpdate={onSubtaskUpdate}
        onSubtaskDelete={onSubtaskDelete}
        onSubtaskAddEstimate={onSubtaskAddEstimate}
        onStartTracking={onStartTracking}
        onStopTracking={onStopTracking}
        dragObject={dragObject}
        trackingState={trackingState}
        addTimeFormErrors={addTimeFormErrors}
        isAddTimeFormOpened={isAddTimeFormOpened}
        onOpenAddTimeModal={onOpenAddTimeModal}
        onCloseAddTimeModal={onCloseAddTimeModal}
      />
    </div>
  )
}
