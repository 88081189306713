import { ID } from '@datorama/akita'

export interface TimeEntry {
  id: ID,
  user: ID
  start: string
  end: string,
  title: string
  duration: string,
  target: {
    type: TimeEntryTargetType,
    id: ID
  },
  comment: string,
  activity: string,
  is_manual: boolean,
}


export type TargetType =
  | 'task-auto'
  | 'task-tracking'
  | 'task-manual'
  | 'subtask-auto'
  | 'subtask-tracking'
  | 'project-manual'
  | 'project-auto'
  | 'project-tracking'
  | 'sprint-manual'
  | 'sprint-auto'
  | 'sprint-tracking'
  | 'epic-manual'
  | 'epic-auto'
  | 'epic-tracking'


export type TimeEntryTargetType =
  | 'task'
  | 'subtask'
  | 'project'
  | 'sprint'
  | 'epic'


export function getSubtype(timeEntry: TimeEntry): TargetType {
  if (!timeEntry.end)
    return `${timeEntry.target.type}-tracking` as TargetType

  if (timeEntry.is_manual)
    return `${timeEntry.target.type}-manual` as TargetType

  return `${timeEntry.target.type}-auto` as TargetType
}


export type WorklogType = {date: string, userId: ID, type: TargetType, typeId: ID,  entries: TimeEntry[]}
