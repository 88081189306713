import React from "react";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { LegacyTaskUrlWithHashRedirect } from "./LegacyTaskUrlWithHashRedirect";
import { LegacyProjectUrlRedirect } from "./LegacyProjectUrlRedirect";
import { LegacySprintUrlRedirect } from "./LegacySprintUrlRedirect";
import { LegacyUserUrlRedirect } from "./LegacyUserUrlRedirect";
import { LegacyReportUrlRedirect } from "./LegacyReportUrlRedirect";
import { LegacyCalendarUrlRedirect } from "./LegacyCalendarUrlRedirect";
import { LegacyDashboardUrlRedirect } from "./LegacyDashboardUrlRedirect";

const legacyUrls = [
  // Dashboard
  {
    path: '#/dashboard',
    component: LegacyDashboardUrlRedirect
  },

  // Project urls
  {
    path: '#/(browse|dashboard)/:projectCode',
    component: LegacyProjectUrlRedirect
  },
  {
    path: '#/(browse|dashboard)/:projectCode/detail/(worklog|activities|attachments)?',
    component: LegacyProjectUrlRedirect
  },

  // Sprint urls
  {
    path: '#/(browse|dashboard)/:projectCode/sprint/:sprintId/(statistics|worklog)?',
    component: LegacySprintUrlRedirect
  },

  // Task urls
  {
    path: '#/(browse|dashboard)/:projectCode/:taskId/(comments|worklog|activities|attachments)?',
    component: LegacyTaskUrlWithHashRedirect
  },

  // User urls
  {
    path: '#/users/:userId?',
    component: LegacyUserUrlRedirect
  },

  // Report urls
  {
    path: '#/reports/:reportType',
    component: LegacyReportUrlRedirect
  },

  // Calendar urls
  {
    path: '#/calendar/(dailystats|tasks)/(month|week|day)?',
    component: LegacyCalendarUrlRedirect
  },
]

export function useLegacyUrlRedirect(): null | React.ReactElement {
  const location = useLocation()

  if (!location.hash) return null

  for (let url of legacyUrls) {
    const match = matchPath(location.hash, {
      path: url.path,
      exact: true,
      strict: false
    })

    const RedirectTo = url.component
    if (match && match.isExact) return <RedirectTo {...match.params} />
  }

  return null
}
