import classNames from 'classnames'
import React from 'react'
import { TaskType } from './TaskType'
import './task-types.scss'
import { TaskType as TaskTypeModel } from '../../../entities/task-type/model'

interface TaskTypesProps {
  types: TaskTypeModel[]
  className?: string
}

export function TaskTypes(props: TaskTypesProps) {
  const { types, className } = props
  const classes = classNames('task-types', className)
  return (
    <span className={classes}>
      {types.map((type) => {
        return (
          <TaskType key={type.id} className="task-types__type" type={type} />
        )
      })}
    </span>
  )
}
