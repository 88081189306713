import React, { useCallback, useMemo } from 'react'
import { ID } from '@datorama/akita'
import { FiltersListTitle } from './FiltersListTitle'
import { User } from '../entities/user/model'
import { UsersSuggest } from '../components/controls/UsersSuggest'

interface FilterListUserProps {
  users: User[]
  selectedUserID: ID | null
  noUnassignedUser?: boolean
  onChangeSelectedUserID: (userID: ID | null) => void
}

export function FilterListUser(props: FilterListUserProps) {
  const { selectedUserID, onChangeSelectedUserID } = props

  const selectedUsersIDs = useMemo(() => {
    if (!!selectedUserID) {
      return [selectedUserID]
    }
    return []
  }, [selectedUserID])

  const onChangeSelectedUsersIDs = useCallback(
    (usersIDs: ID[]) => {
      onChangeSelectedUserID(!!usersIDs.length ? usersIDs[0] : null)
    },
    [onChangeSelectedUserID]
  )

  return (
    <>
      <FiltersListTitle>Users</FiltersListTitle>
      <div className="filters-list__item filters-list__item_users">
        <UsersSuggest
          users={props.users}
          selectedUsersIDs={selectedUsersIDs}
          noUnassignedUser={props.noUnassignedUser}
          onChangeSelectedUsersIDs={onChangeSelectedUsersIDs}
        />
      </div>
    </>
  )
}
