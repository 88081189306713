import { http } from '../../globals/http'
import { ReportStatistics } from './model'

export class ReportStatisticsDataService {
  get(query: object) {
    return http.get<ReportStatistics>('/api/report/statistics', query)
  }
}

export const reportStatisticsPageDataService = new ReportStatisticsDataService()
