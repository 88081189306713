import './styles/add-time-manually-time-picker.scss'

import React from 'react'
import classNames from 'classnames'
import { TimePicker } from 'antd'
import moment from 'moment'

import { AddTimeManuallySection } from './AddTimeManuallySection'
import { TIME_FORMAT_ROUNDED } from '../../utils/date'

interface AddTimeManuallyTimePickerProps {
  className?: string
  title: string
  value: string
  onChange: (value: string) => void
}

export function AddTimeManuallyTimePicker(
  props: AddTimeManuallyTimePickerProps
) {
  const classes = classNames(
    'add-time-manually-time-picker',
    'add-time-manually-input',
    props.className
  )

  return (
    <AddTimeManuallySection title={props.title}>
      <div className={classes}>
        <TimePicker
          value={props.value ? moment(props.value, TIME_FORMAT_ROUNDED) : null}
          placeholder="00:00"
          format={TIME_FORMAT_ROUNDED}
          allowClear={false}
          onChange={(time, timeString) => props.onChange(timeString)}
        />
      </div>
    </AddTimeManuallySection>
  )
}
