import React from 'react'
import { ID } from '@datorama/akita'

import { User } from '../entities/user/model'
import { FilterListUsers } from '../filters-list/FilterListUsers'
import { FiltersListLayout } from '../components/filters-list-layout/FiltersListLayout'
import { Project } from '../entities/project/model'
import { FiltersListProjects } from '../filters-list/FiltersListProjects'

interface CalendarFiltersListProps {
  show: boolean
  users: User[]
  selectedUsersIDs: ID[]
  projects: Project[]
  selectedProject: Project | null
  onChangeProject: (project: Project | null) => void
  onChangeSelectedUsersIDs: (usersIDs: ID[]) => void
  onResetFilters: () => void
}

export function CalendarFiltersList(props: CalendarFiltersListProps) {
  return (
    <FiltersListLayout onResetFilters={props.onResetFilters} show={props.show}>
      <FilterListUsers
        users={props.users}
        allowSelectAll={true}
        noUnassignedUser={true}
        selectedUsersIDs={props.selectedUsersIDs}
        onChangeSelectedUsersIDs={props.onChangeSelectedUsersIDs}
      />
      <FiltersListProjects
        projects={props.projects}
        selectedProject={props.selectedProject}
        onSelectProject={props.onChangeProject}
      />
    </FiltersListLayout>
  )
}
