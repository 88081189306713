import React, { useCallback } from 'react'
import { Input } from 'antd'
import classNames from 'classnames'

const { Search } = Input

interface UsersListSearchProps {
  placeholder?: string
  searchQuery: string
  showFiltersPanel?: boolean
  onSearch: (query: string) => void
}

export function UsersListSearch(props: UsersListSearchProps) {
  const { onSearch, showFiltersPanel } = props

  const onChange = useCallback(
    (e) => {
      onSearch(e.target.value)
    },
    [onSearch]
  )

  const classes = classNames('users-list__search', {
    'is-panel-active': showFiltersPanel
  })

  return (
    <div className={classes}>
      <Search
        value={props.searchQuery}
        allowClear={true}
        placeholder={props.placeholder}
        onSearch={props.onSearch}
        onChange={onChange}
      />
    </div>
  )
}
