import React from 'react'

export type SubtaskStatusTypes = "new" | "done" | 'draft' | "todo" | "in_progress" | "on_rewiew" | "qa" | "accepted" | "cant_reproduce" | "to_clarify" | "not_a_bug"

type SubtaskStatusMapping = {
  [key in SubtaskStatusTypes]: string
}

const getSubtaskStatusMapping: SubtaskStatusMapping = {
  new: '#97A0AF',
  done: '#36B37E',
  draft: '#0747A6',
  todo: '#4C9AFF',
  in_progress: '#6554C0',
  on_rewiew: '#00B8D9',
  qa: '#FF991F',
  accepted: '#36B37E',
  cant_reproduce: '#FF5630',
  to_clarify: '#BF2600',
  not_a_bug: '#4C9AFF',
}

interface HighlightSubtaskStatusProps {
  label: string
  value: SubtaskStatusTypes
}

export function HighlightSubtaskStatus(props: HighlightSubtaskStatusProps) {
  return (
    <span style={{ color: `${getSubtaskStatusMapping[props.value]}` }}>
      {props.label}
    </span>
  )
}
