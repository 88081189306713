import { ReportProject } from '../../entities/report-project/model'
import { SecondsToDecimalHours } from '../../tracking/TimeConverters'

interface ReportProjectActivityRow {
  code?: string
  assignee?: string
  activity_name: string
  time_spent?: string
  key: string
}

export function ParseReportProjectData(report: ReportProject) {
  if (!report.total_time_spent) {
    return []
  }
  const rows: ReportProjectActivityRow[] = []

  // Project
  rows.push({
    code: report.project_code,
    activity_name: report.project_name,
    key: 'project',
  })

  // Project activities
  report.activities.forEach((item, index) => {
    rows.push({
      activity_name: item.activity_name,
      time_spent: item.time_spent
        ? SecondsToDecimalHours(item.time_spent)
        : undefined,
      key: `project-${report.project_code}-activity-${index}`,
    })
  })

  // Tasks activities
  report.tasks.forEach((activity, index) => {
    rows.push({
      code: activity.code,
      activity_name: activity.activity_name,
      assignee: activity.assignee || (activity.code ? 'Unassigned' : ''),
      time_spent: activity.time_spent
        ? SecondsToDecimalHours(activity.time_spent)
        : undefined,
      key: activity.code
        ? `task_${activity.code.toLowerCase()}`
        : index.toString(),
    })
  })

  rows.push({
    code: 'Total',
    activity_name: '',
    time_spent: SecondsToDecimalHours(report.total_time_spent),
    key: 'total',
  })
  return rows
}
