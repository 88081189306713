import React from 'react'
import { Select } from 'antd'
import { UserStatus } from '../entities/user/model'

const { Option } = Select

interface UserProfileStatusSelectProps {
  status: UserStatus
  editable: boolean
  onChange: (status: UserStatus) => void
}

export function UserProfileStatusSelect(props: UserProfileStatusSelectProps) {
  return (
    <div className="user-profile__heading-item user-profile__heading-item_small user-profile__heading-item_status">
      <Select
        value={props.status}
        onChange={props.onChange}
        dropdownMatchSelectWidth={false}
      >
        <Option value={UserStatus.ACTIVE}>Active</Option>
        <Option value={UserStatus.INACTIVE}>Inactive</Option>
      </Select>
    </div>
  )
}
