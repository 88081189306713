import './styles/spinner.scss'

import React from 'react'
import { Space, Spin } from 'antd'

export function Spinner(props: { style?: object }) {
  return (
    <Space className={'spinner-container'} {...props}>
      <Spin size={'large'} />
    </Space>
  )
}
