import { TaskStatus } from '../task/model'

export interface StatusChoice {
  value: TaskStatus
  label: string
  color: string
}

export enum StatusColor {
  DRAFT = '#2f54eb',
  TODO = 'var(--color-text-link)',
  IN_PROGRESS = '#722ed1',
  FIX_IN_PROGRESS = '#722ed1',
  ON_REVIEW = '#13c2c2',
  QA = '#fa8c16',
  BUG_FOUND = '#f5222d',
  ACCEPTED = '#52c41a',
  DEPLOY = '#3498db',
  CANCELLED = '#fa541c',
  READY_FOR_TESTING = '#4A9C69',
  VERIFIED = '#006400',
  ON_HOLD = 'rgb(192,192,192)',
}

export const STATUSES: StatusChoice[] = [
  { value: 'draft', label: 'Draft', color: StatusColor.DRAFT },
  { value: 'todo', label: 'To Do', color: StatusColor.TODO },
  {
    value: 'in-progress',
    label: 'In Progress',
    color: StatusColor.IN_PROGRESS,
  },
  { value: 'on-review', label: 'On Review', color: StatusColor.ON_REVIEW },
  {
    value: 'ready-for-testing',
    label: 'Ready for Testing',
    color: StatusColor.READY_FOR_TESTING,
  },
  { value: 'qa', label: 'QA', color: StatusColor.QA },
  {
    value: 'bug-found',
    label: 'Bug Found',
    color: StatusColor.BUG_FOUND,
  },
  {
    value: 'fix-in-progress',
    label: 'Fix in Progress',
    color: StatusColor.FIX_IN_PROGRESS,
  },
  { value: 'verified', label: 'Verified', color: StatusColor.VERIFIED },
  { value: 'accepted', label: 'Accepted', color: StatusColor.ACCEPTED },
  { value: 'on-hold', label: 'On Hold', color: StatusColor.ON_HOLD },
  { value: 'cancelled', label: 'Cancelled', color: StatusColor.CANCELLED },
]

type TaskStatusMapping = {
  [key in TaskStatus]: string
}

export const getStatusColorMapping: TaskStatusMapping = {
  qa: StatusColor.QA,
  todo: StatusColor.TODO,
  draft: StatusColor.DRAFT,
  accepted: StatusColor.ACCEPTED,
  cancelled: StatusColor.CANCELLED,
  'on-review': StatusColor.ON_REVIEW,
  'in-progress': StatusColor.IN_PROGRESS,
  'bug-found': StatusColor.BUG_FOUND,
  'fix-in-progress': StatusColor.FIX_IN_PROGRESS,
  verified: StatusColor.VERIFIED,
  'ready-for-testing': StatusColor.READY_FOR_TESTING,
  'on-hold': StatusColor.ON_HOLD,
}
