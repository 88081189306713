import classNames from 'classnames'
import React from 'react'
import { AddTimeManuallyTitle } from './AddTimeManuallyTitle'
import './styles/add-time-manually-section.scss'

interface AddTimeManuallySectionProps {
  title: string
  children: React.ReactNode
  className?: string
  titleClassName?: string
}

export function AddTimeManuallySection(props: AddTimeManuallySectionProps) {
  const classes = classNames('add-time-manually-section', props.className)
  const titleClasses = classNames(
    'add-time-manually-section__title',
    props.titleClassName
  )
  return (
    <div className={classes}>
      <AddTimeManuallyTitle className={titleClasses}>
        {props.title}
      </AddTimeManuallyTitle>
      {props.children}
    </div>
  )
}
