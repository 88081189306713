import { Query } from '@datorama/akita'
import { switchMap } from 'rxjs/operators'
import { userQuery } from '../../../entities/user/query'
import { ReportProjectPageState, reportProjectPageStore } from './store'

export class ReportProjectQuery extends Query<ReportProjectPageState> {
  selectedProject$ = this.select('selectedProject')
  selectedUsersIDs$ = this.select('selectedUsersIDs')
  startDate$ = this.select('startDate')
  endDate$ = this.select('endDate')
  report$ = this.select('report')
  loading$ = this.selectLoading()
  users$ = this.selectedProject$.pipe(
    switchMap((project) => {
      return userQuery.forProject(project?.id || null)
    })
  )

  showFiltersPanel$ = this.select('showFiltersPanel')
}

export const reportProjectQuery = new ReportProjectQuery(reportProjectPageStore)
