import React, { useState } from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'
import { AddTaskDescriptionAction } from '../../entities/activities/model'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import {User} from "../../entities/user/model";

interface AddTaskDescriptionActivityProps {
  action: AddTaskDescriptionAction
  date: string,
  user: User | string
}

export function AddDescriptionActivity(
  props: AddTaskDescriptionActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> added the
      task description.{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          {action.data.changed_to}
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
