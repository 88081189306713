export enum SizeValue {
  U = 'u',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export interface SizeChoice {
  value: string
  label: string
}

export const SIZES: SizeChoice[] = [
  { value: SizeValue.U, label: 'U' },
  { value: SizeValue.XS, label: 'XS' },
  { value: SizeValue.S, label: 'S' },
  { value: SizeValue.M, label: 'M' },
  { value: SizeValue.L, label: 'L' },
  { value: SizeValue.XL, label: 'XL' },
]
