import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { Subtask } from './model'

export interface SubtaskState extends EntityState<Subtask, ID> {
  loaded: boolean
}

@StoreConfig({ name: 'entity-subtasks' })
export class SubtaskStore extends EntityStore<SubtaskState> {}

export const subtaskStore = new SubtaskStore()
