import React, { useContext } from 'react'

import { ActivitiesDate } from '../ActivitiesDate'

import '../../styles/task-activity.scss'
import { DeleteAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { User } from '../../../entities/user/model'
import { Subtask } from '../../../entities/subtask/model'
import { activityLogContext } from '../../../project-page/tasks/TaskPanel/TaskPanel'
import { HighlightedActivity } from '../text-formatters/HighlightedActivity'

interface ChangeSubtaskAssigneeActivityProps {
  action: DeleteAction
  date: string
  user: User | string
}

export function RemoveSubtaskActivity(
  props: ChangeSubtaskAssigneeActivityProps
) {
  const { action, user } = props

  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> removed the{' '}
      {action.target.type}{' '}
      <HighlightedActivity>{subtask?.name}</HighlightedActivity>.{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
