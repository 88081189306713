import {ID} from "@datorama/akita";
import {useInstantEffect} from "../utils/instant-effect-hook";
import {projectService} from "../entities/project/service";
import {useQuery} from "../utils/queryhook";
import {projectQuery} from "../entities/project/query";
import {Loader} from "../main-page/Loader";
import {Redirect, Route} from "react-router-dom";
import {NotFound} from "../components/error-pages/ErrorPages.stories";
import {generateSprintDetailUrl} from "../routes";
import React from "react";
import {sprintService} from "../entities/sprint/service";
import {sprintQuery} from "../entities/sprint/query";

interface LegacySprintUrlRedirectProps {
  projectCode?: string,
  sprintId?: ID
}


export function LegacySprintUrlRedirect({ projectCode, sprintId }: LegacySprintUrlRedirectProps) {
  useInstantEffect(
    () => {
      projectService.loadProjects({ code: projectCode })
      sprintService.loadSprints({ timer_v2_id: sprintId })
    }, [projectCode]
  )

  const projectLoaded = useQuery(projectQuery.loaded$)
  const project = useQuery(projectQuery.selectFirst())
  const sprintLoading = useQuery(sprintQuery.loading$)
  const sprint = useQuery(sprintQuery.selectFirst())

  if (!projectLoaded || sprintLoading) return <Loader />

  if (!project || !sprint) return <Route component={NotFound}/>

  return <Redirect to={generateSprintDetailUrl(project.workspace, project.id, sprint.id)}/>
}
