export interface PriorityChoice {
  value: string
  label: string
  icon: string
  color: string
}

export enum PriorityColor {
  HOTFIX = '#fa8c16',
  BLOCKER = '#d4380d',
  MAJOR = 'var(--color-text-link)',
  MINOR = '#389e0d',
  TRIVIAL = 'var(--color-text-inactive)',
}

export const PRIORITIES: PriorityChoice[] = [
  {
    value: 'hotfix',
    label: 'Hotfix',
    icon: 'hotfix',
    color: PriorityColor.HOTFIX,
  },
  {
    value: 'blocker',
    label: 'Blocker',
    icon: 'blocker',
    color: PriorityColor.BLOCKER,
  },
  { value: 'major', label: 'Major', icon: 'major', color: PriorityColor.MAJOR },
  { value: 'minor', label: 'Minor', icon: 'minor', color: PriorityColor.MINOR },
  {
    value: 'trivial',
    label: 'Trivial',
    icon: 'trivial',
    color: PriorityColor.TRIVIAL,
  },
]

export type TaskPriorityType =
  | 'hotfix'
  | 'blocker'
  | 'major'
  | 'minor'
  | 'trivial'

type PriorityMapping = {
  [key in TaskPriorityType]: string
}

export const getPriorityMapping: PriorityMapping = {
  hotfix: PriorityColor.HOTFIX,
  blocker: PriorityColor.BLOCKER,
  major: PriorityColor.MAJOR,
  minor: PriorityColor.MINOR,
  trivial: PriorityColor.TRIVIAL,
}
