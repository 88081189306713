import { EditorView } from 'prosemirror-view'
import { Node as ProseMirrorNode, Schema } from 'prosemirror-model'
import { SuggestStateMatch, Suggester } from 'prosemirror-suggest'
import { TextSelection } from 'prosemirror-state'

import { getPositionOffset } from '../external-link/utils'
import { TaskTypePluginKey } from './plugins'
import { taskTypeService } from '../../../entities/task-type/service'
import { TaskType } from '../../../entities/task-type/model'

export const taskTypeSuggester = (key: TaskTypePluginKey): Suggester => ({
  noDecorations: true,
  char: key.prefix,
  name: `task-type-suggestion-${key.prefix}`,
  supportedCharacters: /[\w\d_/]+/,
  createCommand: ({ match, view }) => {
    return (target: any) => {
      createTaskType(match, view, target, key.prefix)
      view.focus()
    }
  },
  keyBindings: {
    Esc: ({ view }) => {
      const { enable } = key.getState(view.state)
      if (enable) {
        key.updateState(view, { enable: false, query: null })
      }
      return true
    },
    ArrowUp: ({ view }) => {
      const { enable, activeIndex } = key.getState(view.state)
      if (enable) {
        key.updateState(view, { activeIndex: Math.max(activeIndex - 1, 0) })
      }
      return enable
    },
    ArrowDown: ({ view }) => {
      const { enable, activeIndex, items } = key.getState(view.state)
      if (enable) {
        key.updateState(view, {
          activeIndex: Math.min(activeIndex + 1, items.length - 1),
        })
      }
      return enable
    },
    Enter: ({ view, command }) => {
      const { enable, query, items, activeIndex } = key.getState(view.state)
      if (enable) {
        command(items[activeIndex] || query)
      }
      return enable
    },
    Space: ({ view, command }) => {
      const { enable, query, items, activeIndex } = key.getState(view.state)
      if (enable) {
        command(items[activeIndex] || query)
      }
      return enable
    },
  },
  onChange: ({ view, range, queryText, command, ...props }) => {
    const prevState = key.getState(view.state)
    const { targetElement, offset } = getPositionOffset(view, range.from)
    const query = queryText.full

    if (prevState.query !== query) {
      key.updateState(view, {
        enable: true,
        activeIndex: 0,
        targetElement,
        offset,
        query,
        command,
      })
      view.focus()
    }
  },
  onExit: ({ view, ...props }) => {
    const { enable } = key.getState(view.state)
    if (enable) {
      key.updateState(view, { enable: false, query: null })
    }
  },
})

function createTaskType(
  match: SuggestStateMatch,
  view: EditorView,
  target: any,
  prefix: string
): void {
  const { from, to } = match.range
  const schema = view.state.schema as Schema

  const create = (taskType: TaskType) => {
    const node = schema.nodes.tasktype.create({
      id: taskType.id,
      title: taskType.name,
      prefix,
    }) as ProseMirrorNode
    const tr = view.state.tr
    tr.replaceRangeWith(from, to, node)
    tr.setSelection(new TextSelection(tr.doc.resolve(from + 1)))
    view.dispatch(tr)
  }

  if (target?.id) {
    create(target)
  } else {
    taskTypeService
      .createTaskType({ name: target })
      .subscribe((createdTaskType) => create(createdTaskType))
  }
}
