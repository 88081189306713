import { Schema } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'

import { Extension } from '../..'
import { footerMenuBar } from './plugins'
import { BaseFooterProvider } from './provider'

export class FooterExtension extends Extension {
  constructor(
    private provider: BaseFooterProvider,
    private onSubmit?: (value: any) => void
  ) {
    super()
  }

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => {
    return [...plugins, footerMenuBar(this.onSubmit)]
  }

  addProviders = (providers: { [key: string]: any }) => ({
    ...providers,
    footerProvider: this.provider,
  })
}
