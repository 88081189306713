import _ from 'lodash'
import React, { useState } from 'react'
import { isEqual } from 'lodash'
import { ID } from '@datorama/akita'
import { debounce } from 'lodash'
import classNames from 'classnames'
import { Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { Extension, ProseMirrorEditor } from '../../../editor'
import {
  AttachmentExtension,
  FooterExtension,
  KeyMapExtension,
  PlaceHolderExtension,
  InternalLinkExtension,
  ExternalLinkExtension,
  MentionExtension,
  ToolbarExtension,
  StrikethroughExtension,
  TableExtension,
  UnderlineExtension,
  HorizontalRuleExtension,
} from '../../../editor/extentions'
import {
  AttachmentProvider,
  FooterProvider,
  InternalLinkProvider,
  MentionProvider,
} from '../../../editor/providers'
import { Task } from '../../../entities/task/model'

import '../styles/project-page-editor.scss'
import '../styles/task-panel-editor.scss'
import { isDocEmpty } from '../../../editor/core/utils'
import { Doc } from '../../../editor/types'

interface TaskPanelEditorProps {
  task: Pick<Task, 'id' | 'json_description' | 'is_readonly'>
  onChange: (taskId: ID, newDescription: Doc | null) => void
}

export const TaskPanelEditor = ({ task, onChange }: TaskPanelEditorProps) => {
  const editable = !task.is_readonly
  const placeholder = editable
    ? 'Please enter description here...'
    : 'Editing disabled'

  const extensions: Extension[] = [
    new PlaceHolderExtension(placeholder),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new AttachmentProvider(task.id)),
    new ToolbarExtension(),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new KeyMapExtension(),
    ...(editable ? [new FooterExtension(new FooterProvider(task.id))] : []),
  ]

  // json_description might be initially undefined on task,
  // hence setting null default to make TS happy
  const json_description: Doc | null = _.get(task, 'json_description', null)
  let [value, setValue] = useState(json_description)

  React.useEffect(() => {
    setValue(json_description)
  }, [json_description])

  const debounceOnChange = debounce(onChange, 5000)

  const onChangeEditor = (transaction: Transaction, view: EditorView) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
      debounceOnChange(task.id, view.state.doc.toJSON())
      if (
        json_description === null &&
        (isDocEmpty(value) || isEqual(value, json_description))
      ) {
        debounceOnChange.cancel()
      }
    }
  }

  const onBlur = () => {
    if (!isEqual(value, json_description) && !isDocEmpty(value)) {
      onChange(task.id, value)
    }
  }

  const className = classNames('project-page-editor', 'task-panel-editor', {
    'task-panel-editor_disabled': !editable,
  })

  return (
    <ProseMirrorEditor
      editable={editable}
      hasFooter={editable}
      value={value}
      extensions={extensions}
      onChange={onChangeEditor}
      onBlur={onBlur}
      className={className}
    />
  )
}
