import React, { useEffect, useState } from 'react'
import '../styles/sprint-details-statistics-table.scss'
import '../styles/sprint-details-change.scss'
import { SprintStatisticsItem } from './state/models'
import { ShortUser } from '../../../../entities/user/model'
import { ReadableTimeString } from '../../../../tracking/TimeConverters'
import { UserAvatar } from '../../../../components/UserAvatar'
import classNames from 'classnames'
import { SprintChangeDaysPopover } from './SprintStatisticsPopover'
import { Classes } from '@blueprintjs/core'
import { ID } from '@datorama/akita'
import { sprintService } from '../../../../entities/sprint/service'
import { Sprint } from '../../../../entities/sprint/model'
import { ModelUpdate } from '../../../../utils/types'
import { Button, InputNumber } from 'antd'

const TABLE_HEAD: { value: string; label: string }[] = [
  {
    value: 'assignee',
    label: 'Assignee',
  },
  {
    value: 'taskCount',
    label: 'Tasks',
  },
  {
    value: 'performancePerDay',
    label: 'Performance  <br /> Per Day',
  },
  {
    value: 'performancePerSprint',
    label: 'Performance  <br /> Per Sprint',
  },
  {
    value: 'maxEstimation',
    label: 'Max. Est.',
  },
  {
    value: 'spentTime',
    label: `Spent in Task <br /> (Left to spend)`,
  },
  {
    value: 'initialEstimate',
    label: 'Initial Est.',
  },
  {
    value: 'currentEstimate',
    label: 'Final Est.',
  },
]

interface SprintDetailsStatisticsTableProps {
  users: ShortUser[]
  items: SprintStatisticsItem[]
  summary: SprintStatisticsItem
}

export function SprintDetailsStatisticsTable(
  props: SprintDetailsStatisticsTableProps
) {
  const { users, items, summary } = props

  return (
    <table className="sprint-details-statistics-table">
      <thead className="sprint-details-statistics-table__head">
        <tr>
          {TABLE_HEAD.map((headCol) => {
            return (
              <th
                className="sprint-details-statistics-table__col sprint-details-statistics-table__col_head"
                key={headCol.value}
                dangerouslySetInnerHTML={{ __html: headCol.label }}
              ></th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const user = item.userId
            ? users.find((user) => user.id === item.userId)
            : null
          const spentTimeColumnClasses = classNames(
            'sprint-details-statistics-table__col',
            {
              'is-red': item.leftToSpent < 0,
              'is-yellow':
                item.leftToSpent > 0 &&
                item.spentTime > item.maxEstimation * 0.7,
            }
          )

          return (
            <tr key={item.userId}>
              <td className="sprint-details-statistics-table__col">
                <div className="user-select__avatar">
                  {user ? (
                    <>
                      <UserAvatar user={user} shape={'square'} size={22} />
                      <span className="sprint-details-statistics-table__user-name">
                        {user.full_name.split(' ')[0]}
                      </span>
                    </>
                  ) : (
                    <>
                      <UserAvatar shape={'square'} size={22} />
                      <span className="sprint-details-statistics-table__user-name">
                        Unassigned
                      </span>
                    </>
                  )}
                </div>
              </td>
              <td className="sprint-details-statistics-table__col">
                {item.taskCount}
              </td>
              <td className="sprint-details-statistics-table__col">
                {ReadableTimeString(item.performancePerDay as number)}
              </td>
              <td className="sprint-details-statistics-table__col">
                <SprintChangeDaysPopover
                  content={
                    <ChangeContent
                      userId={item.userId}
                      sprint={item.sprint}
                      userTime={item.performancePerSprint}
                      daysNumber={item.performancePerDay as number}
                      workingDays={item.workingDays}
                      submitHandler={sprintService.updateSprint}
                    />
                  }
                >
                  <span className="sprint-details-statistics-table__col-inner">
                    {ReadableTimeString(item.performancePerSprint as number)}
                  </span>
                </SprintChangeDaysPopover>
              </td>
              <td className="sprint-details-statistics-table__col">
                {ReadableTimeString(item.maxEstimation)}
              </td>
              <td className={spentTimeColumnClasses}>
                {ReadableTimeString(item.spentTime)}{' '}
                {item.leftToSpent < 0
                  ? ''
                  : `(${ReadableTimeString(item.leftToSpent)} left)`}
              </td>
              <td className="sprint-details-statistics-table__col">
                {ReadableTimeString(item.initialEstimate)}
              </td>
              <td className="sprint-details-statistics-table__col">
                {ReadableTimeString(item.currentEstimate)}
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            Totals
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {summary.taskCount}
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {ReadableTimeString(summary.performancePerDay as number)}
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {ReadableTimeString(summary.performancePerSprint as number)}
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {ReadableTimeString(summary.maxEstimation)}
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {ReadableTimeString(summary.spentTime)}
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {ReadableTimeString(summary.initialEstimate)}
          </td>
          <td className="sprint-details-statistics-table__col sprint-details-statistics-table__col_foot">
            {ReadableTimeString(summary.currentEstimate)}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

interface SprintDetailsStatisticsTableContentProps {
  daysNumber: number
  submitHandler: (update: ModelUpdate<Sprint>) => void
  userId?: ID
  userTime: number | string
  sprint: Sprint
  workingDays: number
}

export const ChangeContent = (
  props: SprintDetailsStatisticsTableContentProps
) => {
  let { userId } = props

  const [inputValue, setInputValue] = useState<string | number>('')
  const onInputChange = (value: string | number | undefined) => {
    if (value === undefined) {
      setInputValue(props.workingDays)
      return
    }
    setInputValue(value)
  }

  useEffect(() => {
    setInputValue(props.workingDays)
  }, [props.workingDays])

  const workingDays =
    userId &&
    props.sprint.user_working_days &&
    Object.keys(props.sprint.user_working_days).includes(userId as string)
      ? (props.sprint.user_working_days[userId] = Number(inputValue))
      : {
          ...props.sprint.user_working_days,
          [userId as ID]: Number(inputValue),
        }

  const onSubmitHandler = () => {
    props.submitHandler({
      id: props.sprint?.id,
      user_working_days: workingDays,
    })
  }

  return (
    <div className="sprint-details-change">
      <h3>Edit Days in Sprint value</h3>
      {ReadableTimeString(props.daysNumber as number)} *{' '}
      <InputNumber defaultValue={props.workingDays} onChange={onInputChange} />
      <div className="sprint-details-change__buttons">
        <Button
          className={`sprint-details-change__button sprint-details-change__button--submit ${Classes.POPOVER_DISMISS}`}
          onClick={onSubmitHandler}
        >
          {' '}
          Yes
        </Button>
        <Button
          className={`sprint-details-change__button ${Classes.POPOVER_DISMISS}`}
        >
          {' '}
          Cancel
        </Button>
      </div>
    </div>
  )
}
