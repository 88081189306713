import React, {useState, SyntheticEvent, useCallback, useEffect} from 'react'
import { Input } from 'antd'
import { ModelCreateSubtaskCallback } from '../../../utils/types'
import { Subtask } from '../../../entities/subtask/model'
import {useParams} from "react-router-dom";
import {SORT_STEP} from "../../../utils/lists";

interface AddSubtaskProps {
  placeholder: string
  onSubtaskAdd: ModelCreateSubtaskCallback<Subtask>
  subtasks: Subtask[]
}

export function AddSubtask(props: AddSubtaskProps) {
  const { placeholder, onSubtaskAdd, subtasks } = props
  const [value, setValue] = useState('')
  const { TextArea } = Input

  const { taskCode } = useParams()

  useEffect(() => {
    setValue('')
  }, [taskCode])

  const createSubtask = useCallback(
    (event) => {
      event.preventDefault()
      if (!value.trim().length) return
      const subtaskLastItemOrder = subtasks.length ? subtasks.slice(-1)[0].task_order + SORT_STEP : 0
      onSubtaskAdd({ name: value, task_order: subtaskLastItemOrder })
      setValue('')
    },
    [onSubtaskAdd, value, subtasks]
  )

  const onChangeHandler = (event: SyntheticEvent) => {
    const target = event.target as HTMLTextAreaElement
    setValue(target.value)
  }

  return (
    <div className="subtasks__add-subtask-wrap">
      <TextArea
        value={value}
        autoSize={{ minRows: 1 }}
        className="subtasks__add-subtask"
        placeholder={placeholder}
        onInput={onChangeHandler}
        onPressEnter={createSubtask}
      />
    </div>
  )
}
