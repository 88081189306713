import _ from 'lodash'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

interface RouterConfigItemNoChildren {
  path: string
  guards?: React.ComponentType<{ children: React.ReactNode }>[]
  component: React.ComponentType<any>
  children?: undefined
}

interface RouterConfigItemWithChildren {
  path: string
  guards?: React.ComponentType<{ children: React.ReactNode }>[]
  component: React.ComponentType<{ children: React.ReactNode }>
  children: RouterConfigItem[]
}

export type RouterConfigItem =
  | RouterConfigItemNoChildren
  | RouterConfigItemWithChildren
export type RouteConfig = RouterConfigItem[]

function _ConfigRouter(props: { config: RouteConfig; urlPrefix?: string }) {
  return (
    <Switch>
      {props.config.map((item) => {
        return getItemRoute({ item, urlPrefix: props.urlPrefix })
      })}
    </Switch>
  )
}

export const ConfigRouter = React.memo(_ConfigRouter, _.isEqual)

function getItemRoute({
  item,
  urlPrefix,
}: {
  item: RouterConfigItem
  urlPrefix?: string
}) {
  const path = (urlPrefix || '') + item.path
  let content: React.ReactElement

  if (!!item.children) {
    content = (
      <item.component>
        <ConfigRouter config={item.children} urlPrefix={path} />
      </item.component>
    )
  } else {
    content = <item.component />
  }

  if (item.guards) {
    item.guards.reverse().forEach((guard) => {
      content = React.createElement(guard, { children: content })
    })
  }

  return (
    <Route path={path} key={path}>
      {content}
    </Route>
  )
}
