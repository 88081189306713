import React from 'react'
import { TimezoneDisplayFormat, TimezonePicker } from '@blueprintjs/timezone'

import '../../styles/user-field.scss'

interface UserProfileTimeZoneInputProps {
  timezone: string
  format: TimezoneDisplayFormat
  editable: boolean
  onChange: (value: string) => void
}

export function UserProfileTimeZoneInput(props: UserProfileTimeZoneInputProps) {
  return (
    <TimezonePicker
      value={props.timezone}
      placeholder={props.editable ? undefined : 'Not set'}
      className={'user-field user-field_timezone'}
      valueDisplayFormat={props.format}
      onChange={props.onChange}
      buttonProps={{
        minimal: true,
      }}
      disabled={!props.editable}
      popoverProps={{
        position: 'bottom',
        usePortal: false,
        className: 'user-field__popover',
        minimal: true,
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
          flip: {
            enabled: false,
          },
        },
      }}
    />
  )
}
