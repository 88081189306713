import React from 'react'
import classNames from 'classnames'

import './styles/editable-text.scss'

interface ContentEditableComponentProps {
  children?: React.ReactNode
  className: string
  placeholder: string
  suppressEditableWarning: boolean
  blurHandler?: (e: FocusEvent) => void
  changeHandler?: () => void
}

export function ContentEditableComponent({
  children,
  className,
  placeholder,
  suppressEditableWarning,
  blurHandler,
  changeHandler,
}: ContentEditableComponentProps) {
  const onChangeHandler = () => {
    if (changeHandler) changeHandler()
  }

  const onBlurHandler = (e: any) => {
    if (blurHandler) blurHandler(e.currentTarget.textContent)
  }

  const classes = classNames('editable-text', className)

  return (
    <span
      contentEditable
      className={classes}
      data-placeholder={placeholder}
      suppressContentEditableWarning={suppressEditableWarning}
      onBlur={onBlurHandler}
      onPaste={onChangeHandler}
      onInput={onChangeHandler}
    >
      {children}
    </span>
  )
}
