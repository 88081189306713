import '../../styles/user-field.scss'

import React, { useCallback } from 'react'
import { EditableTextComponent } from '../../../components/ValidatedEditableText'

interface UserProfileEmailInputProps {
  value: string
  maxLength: number
  editable: boolean
  onChange: (email: string) => void
}

export function UserProfileEmailInput(props: UserProfileEmailInputProps) {
  const { value, onChange } = props
  const onConfirm = useCallback(
    (newValue: string) => {
      if (newValue !== value) {
        onChange(newValue)
      }
    },
    [value, onChange]
  )
  return (
    <div className="user-field">
      <EditableTextComponent
        type={'email'}
        defaultValue={props.value}
        maxLength={props.maxLength}
        placeholder={props.editable ? undefined : 'Not set'}
        disabled={!props.editable}
        onConfirm={onConfirm}
      />
    </div>
  )
}
