import { ID } from '@datorama/akita'
import { Epic } from './model'
import { http } from '../../globals/http'
import { ModelUpdate } from '../../utils/types'

export class EpicDataService {
  get(id: ID) {
    return http.get<Epic>(`/api/epic/${id}`)
  }

  list(query?: object) {
    return http.get<Epic[]>('/api/epic/', query)
  }

  create(item: Partial<Epic>) {
    return http.post<Epic>('/api/epic/', item)
  }

  update(update: ModelUpdate<Epic>) {
    return http.patch<Epic>(`/api/epic/${update.id}/`, update)
  }

  destroy(id: ID) {
    return http.delete(`/api/epic/${id}/`)
  }
}

export const epicDataService = new EpicDataService()
