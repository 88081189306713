import { TrackingStore, trackingStore } from '../tracking/state/store'
import { WebSocketMessage, WebsocketMessageProcessor } from './types'

export class TrackerUpdateProcessor implements WebsocketMessageProcessor {
  constructor(private store: TrackingStore) {}

  process(message: WebSocketMessage) {
    if (message[0] === 'tracking_state') {
      this.store.update((state) => {
        return message[1]
      })
      return true
    }
    return false
  }
}

export const trackerUpdateProcessor = new TrackerUpdateProcessor(trackingStore)
