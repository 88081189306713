import { EpicPanelState, epicPanelStore, EpicPanelStore } from './store'
import { combineQueries, Query } from '@datorama/akita'
import { map, switchMap } from 'rxjs/operators'
import { taskQuery, TaskQuery } from '../../../../../entities/task/query'
import { of, EMPTY } from 'rxjs'
import { userQuery, UserQuery } from '../../../../../entities/user/query'
import { epicQuery, EpicQuery } from '../../../../../entities/epic/query'
import {
  activityLogQuery,
  ActivityLogQuery,
} from '../../../../../entities/activities/query'
import {
  timeEntryQuery,
  TimeEntryQuery,
} from '../../../../../entities/time-entry/query'
import { groupWorklog } from '../../../../../history/state/model'

export class EpicPanelQuery extends Query<EpicPanelState> {
  epicId$ = this.select('epicId')

  activityFilter$ = this.select('activitiesFilter')

  epic$ = this.epicId$.pipe(
    switchMap((epicId) => this.epicQuery.selectEntity(epicId!))
  )

  epicWithUser$ = this.epic$.pipe(
    switchMap((epic) => {
      if (!epic) return EMPTY

      return combineQueries([
        this.userQuery.getOrLoadUser$(epic.created_by),
        this.userQuery.getOrLoadUser$(epic.updated_by),
      ]).pipe(
        map(([created_by, updated_by]) => {
          return {
            ...epic,
            created_by_user: created_by,
            updated_by_user: updated_by,
          }
        })
      )
    })
  )

  epicTasks$ = this.epic$.pipe(
    switchMap((epic) => {
      if (!epic) return of([])
      return this.taskQuery.forEpic(epic.id)
    })
  )

  epicActivities$ = this.epic$.pipe(
    switchMap((epic) => {
      if (!epic) return of([])
      return this.activityLogQuery.selectByEpicID(epic.id)
    })
  )

  epicWorklogs$ = this.epic$.pipe(
    switchMap((epic) => {
      if (!epic) return of([])
      return this.timeEntryQuery.forEpic(epic.id)
    })
  )

  groupedWorklog$ = this.epicWorklogs$.pipe(
    switchMap((epicworklogs) => {
      if (!epicworklogs) return of([])
      return groupWorklog(epicworklogs)
    })
  )

  showTasksInFinishedSprints$ = this.select('showTasksInFinishedSprints')

  constructor(
    store: EpicPanelStore,
    private epicQuery: EpicQuery,
    private taskQuery: TaskQuery,
    private userQuery: UserQuery,
    private activityLogQuery: ActivityLogQuery,
    private timeEntryQuery: TimeEntryQuery
  ) {
    super(store)
  }
}

export const epicPanelQuery = new EpicPanelQuery(
  epicPanelStore,
  epicQuery,
  taskQuery,
  userQuery,
  activityLogQuery,
  timeEntryQuery
)
