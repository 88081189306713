import React from 'react'
import { Button } from 'antd'

interface InviteUserButtonsProps {
  disabled: boolean
  showCancelButton: boolean
  onInviteCancel: () => void
  onInviteResend: () => void
}

export function InviteUserButtons(props: InviteUserButtonsProps) {
  return (
    <div className="user-profile__invite-buttons">
      {props.showCancelButton && (
        <div className="user-profile__invite-button user-profile__invite-button_link">
          <Button
            type="link"
            onClick={props.onInviteCancel}
            disabled={props.disabled}
          >
            Cancel Invite
          </Button>
        </div>
      )}
      <div className="user-profile__invite-button">
        <Button
          type="primary"
          onClick={props.onInviteResend}
          disabled={props.disabled}
        >
          Resend Invite
        </Button>
      </div>
    </div>
  )
}
