import OrderedMap from 'orderedmap'
import {Schema, NodeSpec} from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'

import { enterInputRules } from "../../core/enter-input-rules"
import { Extension } from "../.."
import { createReactNodeView } from "../../core/react-node-view"
import { NodeViewsSpec } from "../../core/types"
import { UrlInputRule } from './inputrule'
import { InternalLinkComponent } from "./components"
import { BaseInternalLinkProvider } from "./provider"
import { InternalLinkNodeSpec } from "./nodes"
import { internalLinkDialogPlugin } from "./plugin"

export class InternalLinkExtension extends Extension {
  constructor(
    private provider: BaseInternalLinkProvider
  ) { super() }

  addNodes = (nodes: OrderedMap<NodeSpec>): OrderedMap<NodeSpec> => {
    return nodes.append({ internal_link: InternalLinkNodeSpec });
  }

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    enterInputRules({ rules: [UrlInputRule] }),
    internalLinkDialogPlugin()
  ]

  addNodeViews = (schema: Schema, nodeViews: NodeViewsSpec): NodeViewsSpec => ({
    ...nodeViews,
    internal_link: (node, view, getPos, decorations) => {
      return createReactNodeView({
        node, view, getPos, decorations, component: InternalLinkComponent
      })
    }
  })

  addProviders = (providers: {[key: string]: any}) => ({
    ...providers,
    internalLinkProvider: this.provider
  })
}
