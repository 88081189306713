import React, { useState } from 'react'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import classNames from "classnames";


interface SprintChangeDaysPopoverProps {
  // isOpen: boolean
  // openHandler: () => void
  // closeHandler: () => void
  // submitHandler: () => void
  children: React.ReactNode
  content: string | JSX.Element | undefined
}

export function SprintChangeDaysPopover(
  props: SprintChangeDaysPopoverProps
) {

 const popoverClasses = classNames(
    'popover',
  )

   const [
    isConfirmationPopoverOpen,
    setIsConfirmationPopoverOpen,
  ] = useState<boolean>(false)

    const closeConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(false)
  }

    const openConfirmationPopover = () => {
    setIsConfirmationPopoverOpen(true)
  }


return (
  <Popover
    content={props.content}
    minimal={true}
    position={Position.BOTTOM_LEFT}
    interactionKind={PopoverInteractionKind.CLICK}
    popoverClassName={popoverClasses}
    isOpen={isConfirmationPopoverOpen}
    onClose={closeConfirmationPopover}
  >
    <div onClick={openConfirmationPopover}>{props.children}</div>
    </Popover>
  )
}
