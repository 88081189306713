import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { TaskPanelSizeEstimation } from './TaskPanelSizeEstimation'
import { SizeSelect } from '../controls/SizeSelect'
import { PrioritySelect } from '../controls/PrioritySelect'
import { StatusSelect } from '../controls/StatusSelect'
import { UserSelect } from '../controls/UserSelect'
import { PRIORITIES } from '../../../entities/choices/priority'
import { STATUSES } from '../../../entities/choices/status'
import { SIZES } from '../../../entities/choices/size'
import { Task } from '../../../entities/task/model'
import { ShortUser, User } from '../../../entities/user/model'

import '../styles/task-panel-bar.scss'
import {
  AddTimeManuallyCallback,
  AddTimeManuallyObject,
  StartTrackingCallback,
} from '../../../tracking/types'
import { Activity } from '../../../entities/choices/activity'
import { IFormErrors } from '../../state/store'
import { checkIsTimeAlmostExceeded } from '../../../utils/checkIsTimeAlmostExceeded'
import { Subtask } from '../../../entities/subtask/model'
import { Sprint, SprintStatus } from '../../../entities/sprint/model'

interface TaskPanelBarProps {
  task: Task
  sprints: Sprint[]
  users: ShortUser[]
  isEpicsOrSprintsCollapsed: boolean
  updateTask: (arg: object) => void
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  currentUser: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
  subtasks: Subtask[]
}

export function TaskPanelBar(props: TaskPanelBarProps) {
  const {
    task,
    users,
    isEpicsOrSprintsCollapsed,
    updateTask,
    onStartTracking,
    onAddTimeManually,
    currentUser,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props

  const _onStartTracking = useCallback(
    (activity: Activity | null) => {
      onStartTracking('task', +task.id, activity)
    },
    [onStartTracking, task.id]
  )

  const _onAddTimeManually = useCallback(
    (data: AddTimeManuallyObject) => {
      onAddTimeManually('task', +task.id, data)
    },
    [onAddTimeManually, task.id]
  )

  const sprint = props.sprints.find((item) => item.id === task.sprint)
  const isSprintClosedOrActive = useMemo(() => {
    return (
      !!sprint &&
      [SprintStatus.STARTED, SprintStatus.COMPLETE].includes(sprint.status)
    )
  }, [sprint])
  const isEstimateNeeded = useMemo(() => {
    return isSprintClosedOrActive && !task.current_estimate
  }, [isSprintClosedOrActive, task.current_estimate])

  // estimated_time is more predictable, so take it when available
  const maxEstimatedTime =
    _.get(
      task,
      'estimated_time.total.max_estimated_time',
      task.max_estimated_time
    ) || null

  const isEstimatePresent = !!maxEstimatedTime

  const isEstimateTimeExceeded = maxEstimatedTime
    ? isSprintClosedOrActive && task.current_estimate! > maxEstimatedTime
    : false

  const isSpentTimeExceeded = maxEstimatedTime
    ? isSprintClosedOrActive && maxEstimatedTime < task.time_logged
    : false

  const isSpentTimeAlmostExceeded = maxEstimatedTime
    ? isSprintClosedOrActive &&
      checkIsTimeAlmostExceeded(maxEstimatedTime, task.time_logged)
    : false

  const disabled = task.is_readonly

  return (
    <div className="task-panel-bar task-panel__bar">
      <div className="task-panel-bar__left">
        <div className="task-panel-bar__priority">
          <PrioritySelect
            isExtended={isEpicsOrSprintsCollapsed}
            isBordered={true}
            onSelect={(priority) => updateTask({ priority })}
            priority={task.priority}
            priorities={PRIORITIES}
            disabled={disabled}
          />
        </div>
        <div className="task-panel-bar__shirt">
          <SizeSelect
            isExtended={isEpicsOrSprintsCollapsed}
            onSelect={(size) => updateTask({ size })}
            size={task.size}
            sizes={SIZES}
            hasTooltip={true}
            isEstimatePresent={isEstimatePresent}
            isEstimateTimeExceeded={isEstimateTimeExceeded}
            isEstimateNeeded={isEstimateNeeded}
            isSpentTimeExceeded={isSpentTimeExceeded}
            isSpentTimeAlmostExceeded={isSpentTimeAlmostExceeded}
            disabled={disabled}
          />
        </div>
        <div className="task-panel-bar__estimate">
          <TaskPanelSizeEstimation
            task={task}
            onStartTracking={_onStartTracking}
            onStopTracking={props.onStopTracking}
            onAddTimeManually={_onAddTimeManually}
            currentUser={currentUser}
            addTimeFormErrors={addTimeFormErrors}
            isAddTimeFormOpened={isAddTimeFormOpened}
            onOpenAddTimeModal={onOpenAddTimeModal}
            onCloseAddTimeModal={onCloseAddTimeModal}
          />
        </div>
      </div>
      <div className="task-panel-bar__right">
        <div className="task-panel-bar__assignee-select">
          <UserSelect
            inProjectPage={true}
            isEpicsOrSprintsCollapsed={isEpicsOrSprintsCollapsed}
            onSelect={(user) => updateTask({ assignee: user })}
            userId={task.assignee}
            users={users}
            disabled={disabled}
          />
        </div>
        <StatusSelect
          onSelect={(status) => updateTask({ status })}
          status={task.status}
          statuses={STATUSES}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
