import { Popover } from 'antd'
import React from 'react'

import { Subtask } from '../../../entities/subtask/model'
import { ReadableTimeString } from '../../../tracking/TimeConverters'

interface SubtasksHeaderEstimation {
  subtasks: Subtask[]
}

export function SubtasksHeaderEstimation(props: SubtasksHeaderEstimation) {
  const { subtasks } = props

  const endEstimatesSum = subtasks.reduce(
    (acc, curr) => acc + curr.current_estimate,
    0
  )

  const loggedTimeSum = subtasks.reduce(
    (acc, curr) => acc + curr.time_logged,
    0
  )

  return (
    <div className="subtasks-header__estimation">
      <span className="subtasks__estimation-current">
        <Popover
          placement="left"
          trigger="hover"
          content="Time spent on all subtasks"
        >
          {ReadableTimeString(loggedTimeSum)}
        </Popover>
      </span>
      <span className="subtasks__slash"> / </span>
      <span className="subtasks__estimation-end">
        <Popover
          trigger="hover"
          placement="left"
          content="Total subtasks current estimate"
        >
          {ReadableTimeString(endEstimatesSum)}
        </Popover>
      </span>
    </div>
  )
}
