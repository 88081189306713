import { ID } from '@datorama/akita'
import React, { useCallback, useState } from 'react'
import { BeforeCapture, DragDropContext, DropResult } from 'react-beautiful-dnd'
import { Sprint } from '../entities/sprint/model'
import { Epic } from '../entities/epic/model'
import { TaskIntoSubtask, Task } from '../entities/task/model'
import { Subtask, SubtaskIntoTask } from '../entities/subtask/model'
import { Project } from '../entities/project/model'
import { messageDispatcher } from '../utils/message-dispatcher'

export type MoveTaskCallback = (
  id: ID,
  targetType: DroppableTaskType,
  targetId: ID,
  index: number
) => void

export type MoveLinkedTaskCallback = (
  id: ID,
  targetType: 'link',
  targetId: ID,
  index: number
) => void

export type MoveItemCallback = (id: ID, index: number) => void

export type MoveSubtaskIntoTaskCallback = (
  id: ID,
  data: SubtaskIntoTask
) => void

export type MoveTaskIntoSubtaskCallback = (
  id: ID,
  data: TaskIntoSubtask
) => void

export type DragObject = {
  isDragging: boolean
  type: DraggableType | undefined
  id: ID | null
  is_readonly?: boolean
}

export function ProjectPageTaskDraggingContext({
  onTaskMove,
  onSubtaskMove,
  onSubtaskIntoTask,
  onLinkedTaskMove,
  onSprintMove,
  onEpicMove,
  onTaskIntoSubtask,
  children,
}: {
  onTaskMove: MoveTaskCallback
  onSubtaskMove: MoveItemCallback
  onSubtaskIntoTask: MoveSubtaskIntoTaskCallback
  onLinkedTaskMove: MoveLinkedTaskCallback
  onSprintMove: MoveItemCallback
  onEpicMove: MoveItemCallback
  onTaskIntoSubtask: MoveTaskIntoSubtaskCallback
  children: (dragObject: DragObject) => React.ReactNode
}) {
  const emptyDragObject: DragObject = {
    isDragging: false,
    type: undefined,
    id: null,
    is_readonly: false,
  }
  const [dragObject, setDragObject] = useState<DragObject>(emptyDragObject)

  const onBeforeCapture = useCallback((before: BeforeCapture) => {
    const draggable = parseDraggableId(before.draggableId)
    setDragObject({
      isDragging: true,
      type: draggable.type,
      id: draggable.id,
      is_readonly: draggable.is_readonly
    })
  }, [])

  /**
   * Task dragging handling
   */
  const handleTaskDrag = useCallback(
    (source, destination, index) => {
      switch (destination.type) {
        case 'sprint':
        case 'epic':
          onTaskMove(source.id, destination.type, destination.id, index)
          break

        case 'link':
          onLinkedTaskMove(source.id, destination.type, destination.id, index)
          break

        case 'subtasks':
          onTaskIntoSubtask(source.id, { target_id: destination.id })
          break

        case 'backlog':
          if (source.type === 'task') {
            messageDispatcher.putErrorMessage(
              `Drag 'n' drop tasks to the Backlog is not allowed`
            )
          }
      }
    },
    [onTaskMove, onLinkedTaskMove, onTaskIntoSubtask]
  )

  /**
   * Subtask dragging handling
   */
  const handleSubtaskDrag = useCallback(
    (source, destination, index) => {
      switch (destination.type) {
        case 'sprint':
        case 'epic':
        case 'backlog':
          onSubtaskIntoTask(source.id, {
            target_id: destination.id,
            target_type: destination.type,
            order_index: index,
          })
          break

        case 'subtasks':
          onSubtaskMove(source.id, index)
          break
      }
    },
    [onSubtaskIntoTask, onSubtaskMove]
  )

  /**
   * Sprint dragging handling
   */
  const handleSprintDrag = useCallback(
    (source, destination, index) => {
      if (destination.type !== 'sprints') return

      onSprintMove(source.id, index)
    },
    [onSprintMove]
  )

  /**
   * Epic dragging handling
   */
  const handleEpicDrag = useCallback(
    (source, destination, index) => {
      if (destination.type !== 'epics') return

      onEpicMove(source.id, index)
    },
    [onEpicMove]
  )

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setDragObject(emptyDragObject)

      if (!result.destination) {
        return
      }

      const draggable = parseDraggableId(result.draggableId) // draggable item, usually task

      const source = parseDroppableId(result.source.droppableId)
      const destination = parseDroppableId(result.destination.droppableId)
      const destinationIndex = result.destination.index


      if (draggable.type === 'task') {
        if (
          draggable.is_readonly &&
          ['sprint', 'epic'].includes(source.type) && // forbid movement from/to epics/sprints
          ['sprint', 'epic'].includes(destination.type) &&
          result.source.droppableId !== result.destination.droppableId
        ) {
          messageDispatcher.putErrorMessage(
            `Moving tasks to another ${destination.type} is forbidden for this project`
          )
          return
        }
        handleTaskDrag(draggable, destination, destinationIndex)
      } else if (draggable.type === 'subtask') {
        handleSubtaskDrag(draggable, destination, destinationIndex)
      } else if (draggable.type === 'sprint') {
        handleSprintDrag(draggable, destination, destinationIndex)
      } else if (draggable.type === 'epic') {
        handleEpicDrag(draggable, destination, destinationIndex)
      }
    },
    [
      emptyDragObject,
      handleTaskDrag,
      handleSubtaskDrag,
      handleSprintDrag,
      handleEpicDrag,
    ]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      {children(dragObject)}
    </DragDropContext>
  )
}

const droppableTypes = [
  'sprint',
  'epic',
  'subtasks',
  'backlog',
  'link',
  'sprints',
  'epics',
]
type DroppableType =
  | 'sprint'
  | 'epic'
  | 'subtasks'
  | 'link'
  | 'backlog'
  | 'sprints'
  | 'epics'
type DroppableTaskType = 'sprint' | 'epic'

const draggableTypes = ['task', 'subtask', 'sprint', 'epic']
type DraggableType = 'task' | 'subtask' | 'sprint' | 'epic'

// Droppable types to enable / disable dragging in different areas
export type DropType = 'SPRINT_LIST' | 'EPICS_LIST' | 'TASKS_IN_OUT'
// NOTE: such constants can be rewritten to a function - to define a type with some logic
export const SPRINTS_DROP_TYPE: DropType = 'SPRINT_LIST'
export const EPICS_DROP_TYPE: DropType = 'EPICS_LIST'
export const TASKS_IN_OUT_DROP_TYPE: DropType = 'TASKS_IN_OUT'

export function sprintDroppableId(sprint: Sprint): string {
  return `sprint-${sprint.id}`
}

export function backlogDroppableId(projectId: ID): string {
  return `backlog-${projectId}`
}

export function epicDroppableId(epic: Epic): string {
  return `epic-${epic.id}`
}

export function taskDraggableId(task: Task, location: string): string {
  if (task.is_readonly) {
    // readonly tasks cannot be moved to another Sprint or Epic
    return `task-${task.id}-${location}-readonly`
  }
  return `task-${task.id}-${location}`
}

export function subtaskDroppableId(task: Task): string {
  return `subtasks-${task.id}`
}

export function subtaskDraggableId(subtask: Subtask): string {
  return `subtask-${subtask.id}`
}

export function sprintListDroppableId(project: Project): string {
  return `sprints-${project.id}`
}

export function epicListDroppableId(project: Project): string {
  return `epics-${project.id}`
}

export function sprintDraggableId(sprint: Sprint): string {
  return `sprint-${sprint.id}`
}

export function epicDraggableId(epic: Epic): string {
  return `epic-${epic.id}`
}

export function subtasksDropZoneId(task: Task) {
  return `subtasks-dropzone-${task.id}`
}

export function parseDraggableId(
  id: string
): { type: DraggableType; id: number; is_readonly: boolean } {
  const is_readonly = /.*-readonly$/.test(id)

  return {
    ...parseDragDropId(id, draggableTypes),
    is_readonly
  }
}

export function parseDroppableId(
  id: string
): { type: DroppableType; id: number } {
  return parseDragDropId(id, droppableTypes)
}

function parseDragDropId<T extends string>(
  id: string,
  allowedTypes?: string[]
): { type: T; id: number } {
  if (!id.includes('-')) {
    throw new Error(`Invalid id ${id}`)
  }

  const split = id.split('-')

  const type = split[0]

  if (!!allowedTypes && !allowedTypes.includes(type)) {
    throw new Error(`Invalid id ${id}`)
  }
  const parsedId = parseInt(split[1], 10)

  return {
    id: parsedId ? parsedId : parseInt(split[2], 10),
    type: type as T,
  }
}
