export interface ActivityChoice {
  value: string
  label: string
  task_type: string[]
}

export type Activity = string

export const ACTIVITIES: ActivityChoice[] = [
  {
    value: 'development',
    label: 'Development',
    task_type: ['subtask'],
  },
  {
    value: 'bugfix',
    label: 'Bug Fixing',
    task_type: ['subtask_bug'],
  },
  {
    value: 'estimation',
    label: 'Estimation',
    task_type: ['task'],
  },
  {
    value: 'calls',
    label: 'Сommunication',
    task_type: ['project', 'epic', 'sprint', 'task'],
  },
  {
    value: 'testing',
    label: 'Testing',
    task_type: ['project', 'epic', 'sprint', 'task'],
  },
  {
    value: 'planning',
    label: 'Requirements',
    task_type: ['project', 'epic', 'sprint', 'task'],
  },
  {
    value: 'management',
    label: 'Management',
    task_type: ['project', 'epic', 'sprint', 'task'],
  },
  {
    value: 'deployment',
    label: 'Devops',
    task_type: ['project', 'sprint', 'epic'],
  },
  {
    value: 'support',
    label: 'Support',
    task_type: ['sprint', 'task'],
  },
  { value: 'review', label: 'Code review', task_type: ['task'] },
]

export const forType = (type: string) =>
  ACTIVITIES.filter(({ task_type }) => task_type.includes(type))

export const getActivityChoice = (choiceValue: string) => {
  return ACTIVITIES.find(({ value }) => value === choiceValue)
}

export const getActivityLabel = (choiceValue: string) => {
  return getActivityChoice(choiceValue)?.label || ''
}

export const TASK_ACTIVITIES = forType('task')
export const SPRINT_ACTIVITIES = forType('sprint')
export const SUBTASK_ACTIVITIES = forType('subtask')
export const SUBTASK_BUG_ACTIVITIES = forType('subtask_bug')
export const EPIC_ACTIVITIES = forType('epic')
export const PROJECT_ACTIVITIES = forType('project')
