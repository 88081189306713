import {Extension} from "../..";
import {Schema} from "prosemirror-model";
import {Plugin} from "prosemirror-state";

import {placeHolderPlugin} from "./plugin";

export class PlaceHolderExtension extends Extension {
  constructor(private text: string = "Please enter description here...") { super() }

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    placeHolderPlugin(this.text)
  ]
}
