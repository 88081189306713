import React from 'react'
import { Loader } from '../main-page/Loader'
import { useQuery } from '../utils/queryhook'
import { workspaceQuery } from '../entities/workspace/query'
import { useParams } from 'react-router-dom'
import { workspaceService } from '../entities/workspace/service'
import { projectService } from '../entities/project/service'
import { userService } from '../entities/user/service'
import { trackingService } from '../tracking/state/service'
import { userQuery } from '../entities/user/query'
import { projectQuery } from '../entities/project/query'
import { useInstantEffect } from '../utils/instant-effect-hook'

export function WorkspacePreloader(props: { children: React.ReactNode }) {
  const { workspaceId } = useParams()
  const workspace = useQuery(workspaceQuery.active$)
  const projectsLoaded = useQuery(projectQuery.loaded$)
  const usersLoaded = useQuery(userQuery.loaded$)

  useInstantEffect(() => {
    workspaceService.setActive(+workspaceId!)
    projectService.loadProjects({ workspace: workspaceId })
    userService.loadUsers()
    trackingService.loadState()
  }, [workspaceId])

  if (!workspace || !projectsLoaded || !usersLoaded) {
    return <Loader />
  }

  return <>{props.children}</>
}
