import React, { useMemo, useCallback } from 'react'
import { ItemListRenderer, Select } from '@blueprintjs/select'
import { Epic } from '../../entities/epic/model'
import {
  selectChoicePredicate,
  selectItemRenderer,
  useSelect,
} from '../../utils/select'
import { Button, Empty } from 'antd'
import classNames from 'classnames'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'
import { Menu } from '@blueprintjs/core'

const TypedEpicSelect = Select.ofType<Epic>()

export interface EpicSelectProps {
  items: Epic[]
  disabled?: boolean | false
  canClear?: boolean
  onItemSelect: (epic: Epic) => void
  currentItem?: Epic | null
  className?: string
  popoverClassName?: string
  portalClassName?: string
}

export function EpicSelect(props: EpicSelectProps) {
  const {
    disabled,
    canClear,
    className,
    onItemSelect,
    popoverClassName = '',
    portalClassName,
  } = props

  const [itemsWithDefault, currentItem] = useMemo(() => {
    const items = [...props.items]

    let currentItem = props.currentItem
    let defaultItem = getDefaultItem()

    if (!currentItem && canClear) {
      currentItem = defaultItem
    }

    if (canClear) {
      items.push(defaultItem)
    }

    return [items, currentItem]
  }, [props.items, props.currentItem, canClear])

  const _onItemSelect = useCallback(
    (epic) => {
      return onItemSelect({ id: epic.id || null } as Epic)
    },
    [onItemSelect]
  )

  const popoverClasses = classNames(
    'popover',
    'popover_with-search',
    popoverClassName
  )

  const buttonClasses = classNames('breadcrumb-select__button', {
    'breadcrumb-select__button_disabled': disabled,
  })

  const [selectRef, showSelect, showSelectHandler] = useSelect<Epic>()

  if (!showSelect) {
    return (
      <div className={className}>
        <Button className={buttonClasses} onClick={showSelectHandler}>
          {currentItem?.name || 'Epics'}
        </Button>
      </div>
    )
  }

  const itemListRenderer: ItemListRenderer<Epic> = ({
    items,
    renderItem,
    itemsParentRef,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item !== null)

    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={400}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  return (
    <TypedEpicSelect
      itemListRenderer={itemListRenderer}
      ref={selectRef}
      disabled={disabled}
      className={className}
      items={itemsWithDefault}
      itemRenderer={selectItemRenderer}
      itemPredicate={selectChoicePredicate('name')}
      onItemSelect={(epic) => _onItemSelect(epic)}
      noResults={
        <Empty imageStyle={{ height: 60 }} description={'No Results'} />
      }
      popoverProps={{
        portalClassName: portalClassName,
        minimal: true,
        position: 'bottom',
        popoverClassName: popoverClasses,
      }}
      activeItem={currentItem}
    >
      <Button className={buttonClasses}>{currentItem?.name || 'Epics'}</Button>
    </TypedEpicSelect>
  )
}

function getDefaultItem(): Epic {
  return {
    name: 'Backlog',
  } as Epic
}
