export const parseTaskNameDoc = (json: any) => {
  const items = json.content[0].content
  if (!items?.length) {
    return ['', []]
  }
  const text = items
    .filter((item: any) => item.type === 'text')
    .reduce((acc: string, item: any) => acc + item.text, '')
  const taskTypes = items
    .filter((item: any) => item.type === 'tasktype')
    .map((item: any) => item.attrs.id)
  return [text, taskTypes]
}
