import { combineQueries, QueryEntity } from '@datorama/akita'
import { combineLatest, EMPTY, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { ProjectPageState, projectPageStore, ProjectPageStore } from './store'
import { SprintQuery, sprintQuery } from '../../entities/sprint/query'
import { EpicQuery, epicQuery } from '../../entities/epic/query'
import { TaskQuery, taskQuery } from '../../entities/task/query'
import { ProjectQuery, projectQuery } from '../../entities/project/query'
import { userQuery, UserQuery } from '../../entities/user/query'
import { EmptyTrackedObject } from '../../tracking/types'
import { trackingService } from '../../tracking/state/service'
import {
  activityLogQuery,
  ActivityLogQuery,
} from '../../entities/activities/query'
import { subtaskQuery, SubtaskQuery } from '../../entities/subtask/query'
import { subtaskService, SubtaskService } from '../../entities/subtask/service'
import { filterTasks } from '../../entities/task/model'
import { filterSprints, SprintStatus } from '../../entities/sprint/model'
import { filterEpics } from '../../entities/epic/model'
import { timeEntryQuery, TimeEntryQuery } from '../../entities/time-entry/query'
import {
  epicPanelQuery,
  EpicPanelQuery,
} from '../task-groups/details/EpicPanel/state/query'
import { groupBy } from 'lodash'

export class ProjectPageQuery extends QueryEntity<ProjectPageState> {
  projectId$ = this.selectActiveId()

  tasksFilter$ = this.select('tasksFilter')
  sprintsFilter$ = this.select('sprintsFilter')
  epicsFilter$ = this.select('epicsFilter')
  activityFilter$ = this.select('activitiesFilter')
  epicsPaneCollapsedState$ = this.select('epicsPaneCollapsed')
  sprintsPaneCollapsedState$ = this.select('sprintsPaneCollapsed')

  project$ = this.projectId$.pipe(
    switchMap((id) => this.projectQuery.selectEntitySafe(id))
  )

  sprints$ = this.projectId$.pipe(
    switchMap((id) => this.sprintQuery.forProject(id))
  )

  sprintsLoading$ = this.sprintQuery.loading$

  epicsLoading$ = this.epicQuery.loading$

  tasksLoading$ = this.taskQuery.loading$

  epics$ = this.projectId$.pipe(
    switchMap((id) => this.epicQuery.forProject(id))
  )

  tasks$ = this.projectId$.pipe(
    switchMap((id) => this.taskQuery.forProject(id))
  )

  filteredTasks$ = this.projectId$.pipe(
    switchMap((id) => {
      return combineLatest([
        this.tasksFilter$,
        this.taskQuery.forProject(id),
        this.filteredSprints$,
      ]).pipe(
        map(([filter, tasks, sprints]) => {
          const sprintsIDs = sprints.map((sprint) => sprint.id)
          const sprintsTasks = tasks.filter(
            (task) => !task.sprint || sprintsIDs.includes(task.sprint)
          )
          return { filter, tasks: sprintsTasks }
        }),
        map(({ filter, tasks }) => {
          return filterTasks(
            tasks,
            filter.statuses,
            filter.assignee,
            filter.task_type
          )
        })
      )
    })
  )

  showTasksInFinishedSprints$ = this.epicPanelQuery.showTasksInFinishedSprints$

  epicFilteredTasks$ = this.projectId$.pipe(
    switchMap((id) => {
      return combineLatest([
        this.showTasksInFinishedSprints$,
        this.tasksFilter$,
        this.taskQuery.forProject(id),
        this.filteredEpics$,
        this.sprints$,
      ]).pipe(
        map(([showTasksInFinishedSprints, filter, tasks, epics, sprints]) => {
          const epicIDs = epics.map((epic) => epic.id)
          const closedSprintIDs = sprints
            .filter((sprint) => sprint.status === 'closed')
            .map((sprint) => sprint.id)
          const epicTasks = tasks.filter(
            (task) => !task.epic || epicIDs.includes(task.epic)
          )

          let returnTasks = []
          for (const [epicId, tasks] of Object.entries(
            groupBy(epicTasks, 'epic')
          )) {
            returnTasks.push(
              ...filterTasks(
                tasks,
                filter.statuses,
                filter.assignee,
                filter.task_type,
                showTasksInFinishedSprints.includes(parseInt(epicId)),
                closedSprintIDs
              )
            )
          }

          return returnTasks
        })
      )
    })
  )

  filteredSprints$ = this.projectId$.pipe(
    switchMap((id) => {
      return combineLatest([
        this.sprintsFilter$,
        this.sprintQuery.forProject(id),
      ]).pipe(
        map(([filter, sprints]) => {
          return filterSprints(sprints, filter.statuses)
        })
      )
    })
  )

  sprintIds$ = this.projectId$.pipe(
    // collapsing ALL sprints for this project
    // if we used filteredSprints$, it would collapse ALL VISIBLE items instead
    switchMap((id) => this.sprintQuery.forProject(id).pipe(
      map((sprints) => sprints.map(sprint => sprint.id))
    ))
  )

  inactiveSprintIds$ = this.projectId$.pipe(
    // collapsing ALL INACTIVE sprints for this project
    // if we used filteredSprints$, it would collapse ALL VISIBLE INACTIVE
    switchMap((id) => this.sprintQuery.forProject(id).pipe(
      map(
        (sprints) => sprints
          .filter(sprint => sprint.status !== SprintStatus.STARTED)
          .map(sprint => sprint.id)
      )
    ))
  )

  filteredSprintIds$ = this.filteredSprints$.pipe(
    map((sprints) => sprints.map(elem => elem.id))
  )

  filteredEpics$ = this.projectId$.pipe(
    switchMap((id) => {
      return combineLatest([
        this.epicsFilter$,
        this.epicQuery.forProject(id),
      ]).pipe(
        map(([filter, epics]) => {
          return filterEpics(epics, filter.statuses)
        })
      )
    })
  )

  projectUsers$ = this.projectId$.pipe(
    switchMap((id) => this.userQuery.forProject(id))
  )

  projectUsersShort$ = this.projectId$.pipe(
    switchMap((id) => this.userQuery.forProjectShort(id))
  )

  projectActiveUsersShort$ = this.projectId$.pipe(
    switchMap((id) =>
      this.userQuery.forProjectShort(id, this.userQuery.activeShortUsers$)
    )
  )

  backlogCollapsed$ = this.selectActive(({ backlogVisible }) => !backlogVisible)

  epicsPaneCollapsed$ = this.selectActive(
    ({ epicsPaneCollapsed }) => epicsPaneCollapsed
  )

  collapsedSprints$ = this.selectActive(
    ({ collapsedSprints }) => collapsedSprints
  )

  expandedEpics$ = this.selectActive(({ expandedEpics }) => expandedEpics)

  sprintsPaneCollapsed$ = this.selectActive(
    ({ sprintsPaneCollapsed }) => sprintsPaneCollapsed
  )

  /**
   * Epics are collapsed by default. So we store list of expanded epics in store,
   * but we still want components to work the same way and generate a list of
   * collapsed ids here in query.
   */
  collapsedEpics$ = combineLatest([
    this.selectActive(({ expandedEpics }) => expandedEpics),
    this.epics$,
  ]).pipe(
    map(([expanded, epics]) => {
      return epics.map((epic) => epic.id).filter((id) => !expanded.includes(id))
    })
  )

  myLastActivities$ = this.select(
    ({ myLastActivities }) => myLastActivities
  ).pipe(
    map((activities) => {
      return activities.map((activity: EmptyTrackedObject) => {
        return trackingService.getTargetByParams(
          activity.id,
          activity.type,
          activity.activity
        )
      })
    }),
    map((activities) => combineLatest([...activities])),
    switchMap((activityObservables) => activityObservables)
  )

  currentTeamActivities$ = this.select(
    ({ currentTeamActivities }) => currentTeamActivities
  ).pipe(
    map((activities) => {
      return activities.map((activity: EmptyTrackedObject) => {
        return combineQueries([
          trackingService.getTargetByParams(
            activity.id,
            activity.type,
            activity.activity
          ),
          this.userQuery.getOrLoadUser$(activity.user_id),
        ]).pipe(
          map(([activity, user]) => {
            return {
              ...activity,
              user: user,
            }
          })
        )
      })
    }),
    map((activities) => combineLatest([...activities])),
    switchMap((activityObservables) => activityObservables)
  )

  projectWithUser$ = this.project$.pipe(
    switchMap((project) => {
      if (!project) return EMPTY

      return combineQueries([
        this.userQuery.getOrLoadUser$(project.created_by),
        this.userQuery.getOrLoadUser$(project.updated_by),
      ]).pipe(
        map(([created_by, updated_by]) => {
          return {
            ...project,
            created_by_user: created_by,
            updated_by_user: updated_by,
          }
        })
      )
    })
  )

  workspaceUsers$ = this.project$.pipe(
    switchMap((project) => {
      if (!project) return of([])
      return this.userQuery.forWorkspace(project.workspace)
    })
  )

  projectActivities$ = this.project$.pipe(
    switchMap((project) => {
      if (!project) return of([])
      return this.activityLogQuery.selectByProjectID(project.id).pipe(
        map((projectActivities) => {
          return projectActivities.map((projectActivity) => {
            return this.userQuery
              .getOrLoadUser$(projectActivity.actor)
              .pipe(map((user) => ({ ...projectActivity, user: user })))
          })
        }),
        map((projectActivities) =>
          projectActivities.length
            ? combineLatest([...projectActivities])
            : of([])
        ),
        switchMap((projectActivityObservables) => projectActivityObservables)
      )
    })
  )

  projectWorklog$ = this.project$.pipe(
    switchMap((project) => {
      if (!project) return of([])
      return this.timeEntryQuery.forProject(project.id)
    })
  )

  createEpicFormErrors$ = this.select('createEpicFormErrors')
  createSprintFormErrors$ = this.select('createSprintFormErrors')
  createEpicFormIsOpened$ = this.select('createEpicFormIsOpened')
  createSprintFormIsOpened$ = this.select('createSprintFormIsOpened')

  constructor(
    store: ProjectPageStore,
    private projectQuery: ProjectQuery,
    private sprintQuery: SprintQuery,
    private epicQuery: EpicQuery,
    private taskQuery: TaskQuery,
    private userQuery: UserQuery,
    private subtaskQuery: SubtaskQuery,
    private subtaskService: SubtaskService,
    private activityLogQuery: ActivityLogQuery,
    private timeEntryQuery: TimeEntryQuery,
    private epicPanelQuery: EpicPanelQuery
  ) {
    super(store)
  }
}

export const projectPageQuery = new ProjectPageQuery(
  projectPageStore,
  projectQuery,
  sprintQuery,
  epicQuery,
  taskQuery,
  userQuery,
  subtaskQuery,
  subtaskService,
  activityLogQuery,
  timeEntryQuery,
  epicPanelQuery
)
