import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '../utils/queryhook'
import { authQuery } from './state/query'
import { authService } from './state/service'
import { Loader } from '../main-page/Loader'

export interface ForceAuthenticationProps {
  children: React.ReactNode
}

export function ForceAuthentication(props: ForceAuthenticationProps) {
  const checked = useQuery(authQuery.checked$)
  const authenticated = useQuery(authQuery.authenticated$)

  useEffect(() => {
    authService.checkAuthentication()
  }, [])

  if (!checked) {
    return <Loader />
  }

  if (authenticated) {
    return <>{props.children}</>
  }

  authService.setLoginRedirectTo(window.location.pathname)
  return <Redirect to={'/login'} />
}
