import { Store, StoreConfig } from '@datorama/akita'
import {ProjectStatus} from "../../entities/project/model";


export interface WorkspacePageState {
  showFiltersPanel: boolean
  statusFilters: ProjectStatus[]
}

export const workspacePageStoreInitialState = {
  showFiltersPanel: false,
  statusFilters: [ProjectStatus.ACTIVE],
}

@StoreConfig({ name: 'ui-dashboard-page' , resettable: true })
export class WorkspacePageStore extends Store<WorkspacePageState> {}

export const workspacePageStore = new WorkspacePageStore(workspacePageStoreInitialState)
