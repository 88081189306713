import React, { createContext } from 'react'
import { User } from '../entities/user/model'
import { useQuery } from '../utils/queryhook'
import { authQuery } from './state/query'

export const CurrentUserContext = createContext<User | undefined>(undefined)

export function CurrentUserContextGuard(props: { children: React.ReactNode }) {
  const currentUser = useQuery(authQuery.currentUser$)

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {props.children}
    </CurrentUserContext.Provider>
  )
}
