import './styles/project-page.scss'
import './task-groups/styles/task-groups.scss'

import React, { createContext, useCallback, useState } from 'react'
import { Layout } from 'antd'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { ID } from '@datorama/akita'
import classNames from 'classnames'
import { sprintService } from '../entities/sprint/service'
import { taskService } from '../entities/task/service'
import { Project, ProjectStatus } from '../entities/project/model'
import { ShortUser, User } from '../entities/user/model'
import { Task, TaskStatus } from '../entities/task/model'
import { Sprint, SprintStatus } from '../entities/sprint/model'
import { useQuery } from '../utils/queryhook'
import { epicService } from '../entities/epic/service'
import { Epic, EpicStatus } from '../entities/epic/model'
import { ModelCreateCallback, ModelUpdateCallback } from '../utils/types'
import { projectPageQuery } from './state/query'
import { projectPageService } from './state/service'
import { EpicList } from './task-groups/epics/EpicList'
import { SprintList } from './task-groups/sprints/SprintList'
import { taskPanelQuery } from './tasks/TaskPanel/state/query'
import {
  ProjectPageTaskDraggingContext,
  DragObject,
  MoveItemCallback,
} from './drag-n-drop'
import { CollapsedPane } from './CollapsedPane'
import {
  AddTimeManuallyCallback,
  AddTimeManuallyObject,
  StartTrackingCallback,
} from '../tracking/types'
import { Activity, PROJECT_ACTIVITIES } from '../entities/choices/activity'
import { trackingService } from '../tracking/state/service'
import { useInstantEffect } from '../utils/instant-effect-hook'
import { subtaskService } from '../entities/subtask/service'
import { ProjectPageFilterContainer } from './filter/ProjectPageFilterContainer'
import '../components/header/styles/header.scss'
import { sortBy } from 'lodash'
import { ProjectPageFilterTarget } from './filter/ProjectPageFilterTarget'
import { authQuery } from '../auth/state/query'
import { sprintPanelQuery } from './task-groups/details/SprintPanel/state/query'
import { epicPanelQuery } from './task-groups/details/EpicPanel/state/query'
import { smartSuiteAccountService } from '../entities/smartsuite/accounts/account.service'
import { TrackerControl } from '../tracking/TrackerControl'
import { ProjectPageSearchContainer } from './controls/ProjectPageSearchContainer'
import { filterLocalStorageService } from './state/filterLocalStorage'
import { IFormErrors } from './state/store'
import { trackingQuery } from '../tracking/state/query'
import { taskTypeService } from '../entities/task-type/service'
import { TaskType } from '../entities/task-type/model'
import { Header } from '../components/header/Header'

export const dragObjectContext = createContext<DragObject | undefined>(
  undefined
)

export const projectPageTaskContext = createContext<Task[]>([])

export function ProjectPageContainer(props: { children: React.ReactNode }) {
  const { projectId } = useParams()

  useInstantEffect(() => {
    projectPageService.setProject(+projectId!)
    sprintService.ensureSprintsLoaded({ project: +projectId! })
    epicService.ensureEpicsLoaded({ project: +projectId! })
    taskService.ensureTasksLoaded({ project: +projectId! })
    taskTypeService.loadTaskTypes()
    filterLocalStorageService.subscribeOnProjectFilters()
  }, [projectId])

  const project = useQuery(projectPageQuery.project$)
  const filteredSprints = sortBy(
    useQuery(projectPageQuery.filteredSprints$),
    'order'
  )
  const filteredEpics = sortBy(
    useQuery(projectPageQuery.filteredEpics$),
    'order'
  )
  const filteredTasks = useQuery(projectPageQuery.filteredTasks$)
  const tasks = useQuery(projectPageQuery.tasks$)
  const sprints = useQuery(projectPageQuery.sprints$)
  const sprintsLoading = useQuery(projectPageQuery.sprintsLoading$)
  const epicsLoading = useQuery(projectPageQuery.epicsLoading$)
  const tasksLoading = useQuery(projectPageQuery.tasksLoading$)
  const epics = useQuery(projectPageQuery.epics$)
  const taskTypes = useQuery(taskPanelQuery.taskTypes$)
  const currentTask = useQuery(taskPanelQuery.task$)
  const users = useQuery(projectPageQuery.projectUsers$)
  const collapsedSprints = useQuery(projectPageQuery.collapsedSprints$)
  const collapsedEpics = useQuery(projectPageQuery.collapsedEpics$)
  const epicsPaneCollapsed = useQuery(projectPageQuery.epicsPaneCollapsedState$)
  const sprintsPaneCollapsed = useQuery(
    projectPageQuery.sprintsPaneCollapsedState$
  )
  const backlogCollapsed = useQuery(projectPageQuery.backlogCollapsed$)
  const tasksFilter = useQuery(projectPageQuery.tasksFilter$)
  const sprintsFilter = useQuery(projectPageQuery.sprintsFilter$)
  const epicsFilter = useQuery(projectPageQuery.epicsFilter$)
  const currentUser = useQuery(authQuery.currentUser$)
  const currentSprint = useQuery(sprintPanelQuery.sprint$)
  const currentEpic = useQuery(epicPanelQuery.epic$)
  const createEpicFormErrors = useQuery(projectPageQuery.createEpicFormErrors$)
  const createSprintFormErrors = useQuery(
    projectPageQuery.createSprintFormErrors$
  )
  const createEpicFormIsOpened = useQuery(
    projectPageQuery.createEpicFormIsOpened$
  )
  const createSprintFormIsOpened = useQuery(
    projectPageQuery.createSprintFormIsOpened$
  )
  const addTimeFormErrors = useQuery(trackingQuery.fromErrors$)
  const isFormOpened = useQuery(trackingQuery.isFormOpened$)
  const epicFilteredTasks = useQuery(projectPageQuery.epicFilteredTasks$)

  const [applyFilter, setApplyFilters] = useState(false)
  const onApplyFilters = useCallback(
    () => setApplyFilters((prevApplyFilter) => !prevApplyFilter),
    []
  )

  useInstantEffect(() => {
    if (currentUser.smartsuite_key?.length) {
      smartSuiteAccountService.loadAccounts()
    }
  }, [currentUser.smartsuite_key, project?.smartsuite_account])

  if (!project) {
    return null
  }

  return (
    <ProjectPageTaskDraggingContext
      onTaskMove={projectPageService.moveTask}
      onSubtaskMove={subtaskService.moveSubtask}
      onSubtaskIntoTask={subtaskService.subtaskIntoTask}
      onLinkedTaskMove={taskService.moveLinkedTask}
      onSprintMove={sprintService.moveSprint}
      onEpicMove={epicService.moveEpic}
      onTaskIntoSubtask={taskService.taskIntoSubtask}
    >
      {(dragObject: DragObject) => (
        <projectPageTaskContext.Provider value={[...tasks]}>
        <ProjectPage
          project={project}
          filteredSprints={filteredSprints}
          filteredEpics={filteredEpics}
          filteredTasks={filteredTasks}
          sprints={sprints}
          epics={epics}
          taskTypes={taskTypes}
          tasks={tasks}
          sprintsLoading={sprintsLoading}
          epicsLoading={epicsLoading}
          tasksLoading={tasksLoading}
          onApplyFilter={onApplyFilters}
          applyFilter={applyFilter}
          backlogCollapsed={backlogCollapsed}
          collapsedSprints={collapsedSprints}
          collapsedEpics={collapsedEpics}
          epicsPaneCollapsed={epicsPaneCollapsed}
          sprintsPaneCollapsed={sprintsPaneCollapsed}
          onEpicsPaneToggle={projectPageService.toggleEpicsPane}
          onSprintsPaneToggle={projectPageService.toggleSprintsPane}
          currentTask={currentTask}
          currentSprint={currentSprint}
          currentEpic={currentEpic}
          users={users}
          onToggleSprint={projectPageService.toggleSprint}
          onToggleEpic={projectPageService.toggleEpic}
          onToggleProjectBacklog={projectPageService.toggleProjectBacklog}
          onCreateSprint={projectPageService.sprintCreate}
          onUpdateSprint={sprintService.updateSprint}
          onReorderSprint={projectPageService.moveSprint}
          onDestroySprint={sprintService.destroy}
          onCreateEpic={projectPageService.epicCreate}
          onUpdateEpic={epicService.updateEpic}
          onReorderEpic={projectPageService.moveEpic}
          onDestroyEpic={epicService.destroy}
          onTaskUpdate={taskService.updateTask}
          onTaskCreate={projectPageService.taskCreate}
          onStartTracking={trackingService.startTracking}
          onStopTracking={trackingService.stopTracking}
          onAddTimeManually={trackingService.addTimeManually}
          onFilterTasksByStatus={projectPageService.updateTasksFilterStatuses}
          onFilterTasksByAssignee={projectPageService.updateTasksFilterAssignee}
          onFilterTasksByType={projectPageService.updateTasksFilterType}
          onFilterSprintsByStatus={
            projectPageService.updateSprintsFilterStatuses
          }
          onFilterEpicsByStatus={projectPageService.updateEpicsFilterStatuses}
          onResetFilter={projectPageService.clearFilters}
          tasksFilter={tasksFilter}
          sprintsFilter={sprintsFilter}
          epicsFilter={epicsFilter}
          currentUser={currentUser}
          dragObject={dragObject}
          createEpicFormErrors={createEpicFormErrors}
          createSprintFormErrors={createSprintFormErrors}
          cleanupFormErrors={projectPageService.cleanupFormErrors}
          createEpicFormIsOpened={createEpicFormIsOpened}
          openCreateEpicFrom={projectPageService.openCreateEpicFrom}
          closeCreateEpicFrom={projectPageService.closeCreateEpicFrom}
          createSprintFormIsOpened={createSprintFormIsOpened}
          openCreateSprintFrom={projectPageService.openCreateSprintFrom}
          closeCreateSprintFrom={projectPageService.closeCreateSprintFrom}
          collapseAllSprints={projectPageService.collapseAllSprints}
          collapseInactiveSprints={projectPageService.collapseInactiveSprints}
          addTimeFormErrors={addTimeFormErrors}
          isAddTimeFormOpened={isFormOpened}
          onOpenAddTimeModal={trackingService.openAddTimeForm}
          onCloseAddTimeModal={trackingService.closeAddTimeForm}
          epicFilteredTasks={epicFilteredTasks}
        >
          {props.children}
        </ProjectPage>
        </projectPageTaskContext.Provider>
      )}
    </ProjectPageTaskDraggingContext>
  )
}

export interface ProjectPageProps {
  project: Project
  sprints: Sprint[]
  epics: Epic[]
  taskTypes: TaskType[]
  tasks: Task[]
  filteredSprints: Sprint[]
  filteredEpics: Epic[]
  filteredTasks: Task[]
  onApplyFilter: () => void
  applyFilter: boolean
  currentTask: Task | null
  currentSprint: Sprint | null
  currentEpic: Epic | null
  collapsedSprints: ID[]
  collapsedEpics: ID[]
  backlogCollapsed: boolean
  epicsPaneCollapsed?: boolean
  sprintsPaneCollapsed?: boolean
  users: User[] | ShortUser[]
  onEpicsPaneToggle: () => void
  onSprintsPaneToggle: () => void
  onTaskUpdate: ModelUpdateCallback<Task>
  onTaskCreate: ModelCreateCallback<Task>
  onToggleSprint: (id: ID) => void
  onToggleEpic: (id: ID) => void
  onToggleProjectBacklog: () => void
  onCreateSprint: (sprint: Partial<Sprint>) => void
  onUpdateSprint: ModelUpdateCallback<Sprint>
  onReorderSprint: MoveItemCallback
  onDestroySprint: (id: ID) => void
  onCreateEpic: (epic: Partial<Epic>) => void
  onUpdateEpic: ModelUpdateCallback<Epic>
  onReorderEpic: MoveItemCallback
  onDestroyEpic: (id: ID) => void
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  onFilterTasksByStatus: (value: TaskStatus) => void
  onFilterTasksByAssignee: (assignee: ID[]) => void
  onFilterTasksByType: (taskType: ID[]) => void
  onFilterSprintsByStatus: (status: SprintStatus) => void
  onFilterEpicsByStatus: (status: EpicStatus) => void
  createEpicFormErrors: IFormErrors | null
  createSprintFormErrors: IFormErrors | null
  cleanupFormErrors: () => void
  onResetFilter: () => void
  createEpicFormIsOpened: boolean
  openCreateEpicFrom: () => void
  closeCreateEpicFrom: () => void
  createSprintFormIsOpened: boolean
  openCreateSprintFrom: () => void
  closeCreateSprintFrom: () => void
  collapseAllSprints: () => void
  collapseInactiveSprints: () => void
  addTimeFormErrors: IFormErrors | null
  isAddTimeFormOpened: boolean
  onOpenAddTimeModal: () => void
  onCloseAddTimeModal: () => void
  epicFilteredTasks: Task[]
  tasksFilter?: { statuses: TaskStatus[]; assignee: ID[]; task_type: ID[] }
  sprintsFilter?: { statuses: SprintStatus[] }
  epicsFilter?: { statuses: EpicStatus[] }
  currentUser?: User
  dragObject: DragObject
  children: React.ReactNode
  sprintsLoading?: boolean
  epicsLoading?: boolean
  tasksLoading?: boolean
}

export function ProjectPage(props: ProjectPageProps) {
  const projectUrl = useRouteMatch().url
  const { onStartTracking, onAddTimeManually } = props

  const startProjectTracking = useCallback(
    (activity: Activity | null) => {
      onStartTracking && onStartTracking('project', +props.project.id, activity)
    },
    [onStartTracking, props.project.id]
  )

  const [showFilters, setShowFilters] = useState<boolean>(false)

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState)
  }

  const addProjectTimeManually = useCallback(
    (data: AddTimeManuallyObject) => {
      onAddTimeManually('project', +props.project.id, data)
    },
    [onAddTimeManually, props.project.id]
  )

  const epicsPanelClasses = classNames(
    'project-page__panel',
    'project-page__panel_epics',
    {
      'project-page__panel_collapsed': props.epicsPaneCollapsed,
    }
  )

  const sprintsPanelClasses = classNames(
    'project-page__panel',
    'project-page__panel_sprints',
    {
      'project-page__panel_collapsed': props.sprintsPaneCollapsed,
    }
  )

  const detailsPanelClasses = classNames(
    'project-page__panel',
    'project-page__panel_details'
  )

  return (
    <Layout>
      <Header>
        <>
          <ProjectPageFilterTarget
            isActive={showFilters}
            onClick={toggleFilters}
          />
          <ProjectPageSearchContainer
            targetClassName="header__search"
            project={props.project}
            sprints={props.sprints}
            epics={props.epics}
            tasks={props.tasks}
            filteredSprints={props.filteredSprints}
            filteredEpics={props.filteredEpics}
            filteredTasks={props.filteredTasks}
            users={props.users}
            onApplyFilter={props.onApplyFilter}
            applyFilter={props.applyFilter}
          />
          <Link to={projectUrl} className="header__title">
            {props.project.name}
            {props.project.status === ProjectStatus.CLOSED && (
              <span className="header__title-tip">(closed)</span>
            )}
          </Link>
          <span className="tracker-control">
            <TrackerControl
              trackingObject={props.project}
              trackingObjectType="project"
              activities={PROJECT_ACTIVITIES}
              showControlOnly={true}
              hideIfPossible={false}
              onStart={startProjectTracking}
              onStop={props.onStopTracking}
              onAddTimeManually={addProjectTimeManually}
              currentUser={props.currentUser}
              addTimeFormErrors={props.addTimeFormErrors}
              isAddTimeFormOpened={props.isAddTimeFormOpened}
              onOpenAddTimeModal={props.onOpenAddTimeModal}
              onCloseAddTimeModal={props.onCloseAddTimeModal}
            />
          </span>
        </>
      </Header>
      <Layout.Content style={{ flex: '1 1 0' }}>
        <div className="project-page">
          <ProjectPageFilterContainer
            isActive={showFilters}
            users={props.users}
            taskTypes={props.taskTypes}
            onFilterTasksByStatus={props.onFilterTasksByStatus}
            onFilterTasksByAssignee={props.onFilterTasksByAssignee}
            onFilterSprintsByStatus={props.onFilterSprintsByStatus}
            onFilterEpicsByStatus={props.onFilterEpicsByStatus}
            onFilterTasksByType={props.onFilterTasksByType}
            onResetFilters={props.onResetFilter}
            tasksFilter={props.tasksFilter}
            sprintsFilter={props.sprintsFilter}
            epicsFilter={props.epicsFilter}
          />
          <div className={epicsPanelClasses}>
            {props.epicsPaneCollapsed ? (
              <CollapsedPane title="Epics" onExpand={props.onEpicsPaneToggle} />
            ) : (
              <EpicList
                notFilteredTasks={props.tasks}
                sprintsLoading={props.sprintsLoading}
                epicsLoading={props.epicsLoading}
                tasksLoading={props.tasksLoading}
                baseUrl={projectUrl}
                sprints={props.sprints}
                taskTypes={props.taskTypes}
                filteredSprints={props.filteredSprints}
                epics={props.filteredEpics}
                tasks={props.epicFilteredTasks}
                currentTask={props.currentTask}
                currentEpic={props.currentEpic}
                collapsedEpics={props.collapsedEpics}
                backlogCollapsed={props.backlogCollapsed}
                onPaneToggle={props.onEpicsPaneToggle}
                onToggleEpic={props.onToggleEpic}
                onCreateEpic={props.onCreateEpic}
                onUpdateEpic={props.onUpdateEpic}
                onReorderEpic={props.onReorderEpic}
                onDestroyEpic={props.onDestroyEpic}
                onToggleProjectBacklog={props.onToggleProjectBacklog}
                onStartTracking={props.onStartTracking}
                onStopTracking={props.onStopTracking}
                onAddTimeManually={props.onAddTimeManually}
                onTaskUpdate={props.onTaskUpdate}
                onTaskCreate={props.onTaskCreate}
                users={props.users}
                project={props.project}
                currentUser={props.currentUser}
                formErrors={props.createEpicFormErrors}
                cleanupFormErrors={props.cleanupFormErrors}
                createEpicFormIsOpened={props.createEpicFormIsOpened}
                openCreateEpicFrom={props.openCreateEpicFrom}
                closeCreateEpicFrom={props.closeCreateEpicFrom}
                addTimeFormErrors={props.addTimeFormErrors}
                isAddTimeFormOpened={props.isAddTimeFormOpened}
                onOpenAddTimeModal={props.onOpenAddTimeModal}
                onCloseAddTimeModal={props.onCloseAddTimeModal}
              />
            )}
          </div>

          <div className={sprintsPanelClasses}>
            {props.sprintsPaneCollapsed ? (
              <CollapsedPane
                title="Sprints"
                onExpand={props.onSprintsPaneToggle}
              />
            ) : (
              <SprintList
                sprintsLoading={props.sprintsLoading}
                epicsLoading={props.epicsLoading}
                tasksLoading={props.tasksLoading}
                baseUrl={projectUrl}
                sprints={props.filteredSprints}
                epics={props.filteredEpics}
                taskTypes={props.taskTypes}
                tasks={props.filteredTasks}
                notFilteredTasks={props.tasks}
                currentTask={props.currentTask}
                collapsedSprints={props.collapsedSprints}
                onPaneToggle={props.onSprintsPaneToggle}
                onToggleSprint={props.onToggleSprint}
                onCreateSprint={props.onCreateSprint}
                onUpdateSprint={props.onUpdateSprint}
                onReorderSprint={props.onReorderSprint}
                onDestroySprint={props.onDestroySprint}
                onStartTracking={props.onStartTracking}
                onStopTracking={props.onStopTracking}
                onAddTimeManually={props.onAddTimeManually}
                onTaskUpdate={props.onTaskUpdate}
                onTaskCreate={props.onTaskCreate}
                users={props.users}
                currentUser={props.currentUser}
                currentSprint={props.currentSprint}
                formErrors={props.createSprintFormErrors}
                cleanupFormErrors={props.cleanupFormErrors}
                createSprintFormIsOpened={props.createSprintFormIsOpened}
                openCreateSprintFrom={props.openCreateSprintFrom}
                closeCreateSprintFrom={props.closeCreateSprintFrom}
                collapseAllSprints={props.collapseAllSprints}
                collapseInactiveSprints={props.collapseInactiveSprints}
                addTimeFormErrors={props.addTimeFormErrors}
                isAddTimeFormOpened={props.isAddTimeFormOpened}
                onOpenAddTimeModal={props.onOpenAddTimeModal}
                onCloseAddTimeModal={props.onCloseAddTimeModal}
                dragObject={props.dragObject}
              />
            )}
          </div>

          <div className={detailsPanelClasses}>
            <dragObjectContext.Provider value={props.dragObject}>
              {props.children}
            </dragObjectContext.Provider>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
