import { Query } from '@datorama/akita'
import { switchMap } from 'rxjs/operators'
import { ReportDevPageState, reportDevPageStore } from './store'
import { userQuery } from '../../../entities/user/query'
import { workspaceQuery } from '../../../entities/workspace/query'

export class ReportDevPageQuery extends Query<ReportDevPageState> {
  loading$ = this.selectLoading()
  showFiltersPanel$ = this.select('showFiltersPanel')
  selectedUserID$ = this.select('selectedUserID')
  startDate$ = this.select('startDate')
  endDate$ = this.select('endDate')
  report$ = this.select('report')
  users$ = workspaceQuery.selectActiveId().pipe(
    switchMap((id) => {
      return userQuery.forWorkspace(id)
    })
  )
}

export const reportDevPageQuery = new ReportDevPageQuery(reportDevPageStore)
