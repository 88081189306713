/**
 * Group items in array by callback compare.
 * Return list of grouped element.
 * */
export const groupByCompare = <T>(
  items: T[],
  callback: (prevItem: T, currentItem: T) => boolean
): T[][] => {
  return items.reduce<T[][]>((groupedItems, item) => {
    const prevItemGroup = groupedItems?.[groupedItems.length - 1] || []
    const prevItem = prevItemGroup?.[prevItemGroup.length - 1]

    if (prevItem && callback(prevItem, item)) {
      prevItemGroup.unshift(item)
      return groupedItems
    }

    groupedItems.push([item])
    return groupedItems
  }, [])
}
