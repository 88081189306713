import React, {useCallback} from 'react'
import {
  IItemModifiers,
  IItemRendererProps,
  ItemListRenderer,
  Omnibar,
} from '@blueprintjs/select'
import { Checkbox } from 'antd'

import './styles/page-search.scss'
import classNames from 'classnames'
import { Classes, Menu } from '@blueprintjs/core'
import {SearchItem} from '../../../project-page/search'
import { useHistory } from 'react-router-dom'
import { groupBy } from 'lodash'
import { PageSearchSectionTitle } from './PageSearchSectionTitle'
import Fuse from 'fuse.js'

interface PageSearchProps {
  items: SearchItem[]
  query: string
  isOpen: boolean
  onClose: () => void
  onQueryChange: (query: string) => void
  onSelectItem: () => void
  onApplyFilter: () => void
  applyFilter: boolean
}

const PageSearchOmnibar = Omnibar.ofType<SearchItem>()

interface ItemProps {
  item: any
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  modifiers: IItemModifiers
}

const Item: React.FunctionComponent<ItemProps> = (props) => {
  return (
    <li
      key={`${props.item.type}-${props.item.id}`}
      onClick={props.handleClick}
      className={classNames(
        Classes.MENU_ITEM,
        Classes.TEXT_OVERFLOW_ELLIPSIS,
        'page-search-list-item',
        { 'bp3-active': props.modifiers.active }
      )}
    >
      <span
        className={classNames(
          'page-search-list-item__label',
          Classes.TEXT_OVERFLOW_ELLIPSIS
        )}
      >
        {props.item.label}
      </span>
    </li>
  )
}

export const PageSearch: React.FunctionComponent<PageSearchProps> = (props) => {
  const { items, onSelectItem } = props
  const history = useHistory()

  const itemRenderer = (item: SearchItem, renderProps: IItemRendererProps) => {
    const { modifiers, handleClick } = renderProps
    return <Item modifiers={modifiers} item={item} handleClick={handleClick} />
  }

  const selectHandler = useCallback(
    (item) => {
      history.push(item.url)
      onSelectItem()
    },
    [history, onSelectItem]
  )

  const itemListPredicate = (query: string, items: SearchItem[]) => {
    const options = {
      includeScore: true,
      keys: ['label'],
    }
    const fuse = new Fuse(items, options)
    const searchResult = fuse.search(query)

    // We need to sort result by groups additionaly: the group with the highest score must be first
    const grouped = groupBy(searchResult, 'item.type')
    const sortedByGroupsResult = ([] as Fuse.FuseResult<SearchItem>[]).concat(
      ...Object.values(grouped)
    )
    return sortedByGroupsResult.map((item) => item.item)
  }

  const emptyContent = (
    <div className="page-search-omnibar__empty">
      Hmm... nothing matches your search. <br />
      Try being less specific or using different keywords
    </div>
  )

  const itemListRenderer: ItemListRenderer<SearchItem> = ({
    filteredItems,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const searchItems = !!query ? filteredItems : []
    const groupedItems = groupBy(filteredItems, 'type')

    if (query && !filteredItems.length) return <Menu>{emptyContent}</Menu>

    return (
      <Menu ulRef={itemsParentRef}>
        {searchItems.map((item, index) => {
          const isFirst =
            groupedItems[item.type].findIndex((i) => i.id === item.id) === 0
          return (
            <div key={`serch-item-${item.type}-${item.id}`}>
              {isFirst && <PageSearchSectionTitle title={item.type} />}
              {renderItem(item, index)}
            </div>
          )
        })}
      </Menu>
    )
  }

  return (
    <PageSearchOmnibar
      className="page-search-omnibar"
      itemRenderer={itemRenderer}
      itemListPredicate={itemListPredicate}
      itemListRenderer={itemListRenderer}
      items={items}
      isOpen={props.isOpen}
      onItemSelect={selectHandler}
      overlayProps={{ onClose: props.onClose }}
      noResults={emptyContent}
      onQueryChange={props.onQueryChange}
      query={props.query}
      resetOnSelect={true}
      inputProps={{
        rightElement: (
          <div className="page-search-omnibar__filter">
            <Checkbox checked={props.applyFilter} onChange={props.onApplyFilter}>
              Apply filter
            </Checkbox>
          </div>
        ),
      }}
    />
  )
}
