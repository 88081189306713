import { ID, QueryEntity } from '@datorama/akita'
import { TaskState, TaskStore, taskStore } from './store'

export class TaskQuery extends QueryEntity<TaskState> {
  constructor(protected store: TaskStore) {
    super(store)
  }

  loading$ = this.selectLoading()
  loaded$ = this.select('loaded')

  forProject(projectId: ID | null) {
    return this.selectAll({
      filterBy: (task) => task.project === projectId,
    })
  }

  selectByCode(taskCode: ID) {
    return this.selectEntity(({ code }) => code === taskCode)
  }

  forSprint(sprintId: ID) {
    return this.selectAll({
      filterBy: (task) => task.sprint === sprintId,
    })
  }

  forEpic(epicId: ID) {
    return this.selectAll({
      filterBy: (task) => task.epic === epicId,
    })
  }

  getLoadedHashes() {
    return this.getValue().loaded
  }
}

export const taskQuery = new TaskQuery(taskStore)
