import './styles/deleted-text.scss'

import React from 'react'

interface DeletedTextProps {
  children: React.ReactNode
}

export function DeletedText(props: DeletedTextProps) {
  return <div className="deleted-text">{props.children}</div>
}
