import React from 'react'
import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { ReactPluginView } from '../../core/react-plugin-view'

export class MentionPluginKey extends PluginKey {
  constructor(public prefix: string) {
    super(`${prefix}-mention`)
  }

  updateState(view: EditorView, data: { [key: string]: any }) {
    const tr = view.state.tr
    tr.setMeta(this, data)
    view.dispatch(tr)
  }
}

export const MentionPlugin = (
  key: MentionPluginKey, Component: React.FC<any>
): Plugin => new Plugin({
  key: key,
  state: {
    init: () => ({query: null, items: [], activeIndex: 0}),
    apply: (tr, value) => ({...value, ...(tr.getMeta(key) || {})})
  },
  view: (view) => new ReactPluginView(view, Component, key)
})
