import React, { useCallback } from 'react'
import '../../../components/controls/styles/popover.scss'
import {
  Select,
  IItemRendererProps,
  ItemListRenderer,
} from '@blueprintjs/select'
import { Button, Menu } from '@blueprintjs/core'
import classNames from 'classnames'
import { MenuItem } from '@blueprintjs/core'
import { SubtaskStatusChoice } from '../../../entities/choices/subtasksFilter'

import '../../../assets/scss/select.scss'
import './styles/status-select.scss'
import { Subtask, SubtaskStatus } from '../../../entities/subtask/model'
import { ID } from '@datorama/akita'

const TypedSubtaskStatusSelect = Select.ofType<SubtaskStatusChoice>()

export interface SubtaskStatusSelectProps {
  status?: string
  subtask: Subtask
  statuses: SubtaskStatusChoice[]
  default: SubtaskStatusChoice
  disabled?: boolean | false
  className?: string
  onSelect: (status: SubtaskStatus) => void
  onSubtaskDelete: (id: ID) => void
}

export function SubtaskStatusSelect(props: SubtaskStatusSelectProps) {
  const {
    disabled,
    onSelect,
    onSubtaskDelete,
    subtask,
    status = SubtaskStatus.NEW,
  } = props

  const onItemSelect = useCallback(
    (status: SubtaskStatusChoice) => onSelect(status.value),
    [onSelect]
  )

  const onItemDelete = useCallback(() => onSubtaskDelete(subtask.id), [
    onSubtaskDelete,
    subtask.id,
  ])

  const renderMenu: ItemListRenderer<SubtaskStatusChoice> = ({
    items,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null)
    return (
      <Menu ulRef={itemsParentRef}>
        {renderedItems}
        <li className="status-select__divider" aria-hidden={true} />
        <MenuItem onClick={onItemDelete} disabled={false} text="Delete" />
      </Menu>
    )
  }

  const renderStatus = useCallback(
    (statusChoice: SubtaskStatusChoice, renderProps: IItemRendererProps) => {
      const { modifiers, handleClick } = renderProps

      if (!modifiers.matchesPredicate) {
        return null
      }

      return (
        <MenuItem
          key={statusChoice.value}
          active={statusChoice.value === status}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={statusChoice.label}
        />
      )
    },
    [status]
  )

  let statusObj = props.statuses.find((s) => s.value === status)
  statusObj = statusObj ? statusObj : props.default

  const selectClasses = classNames('status-select', 'select', props.className, {
    select_disabled: disabled,
  })

  return (
    <TypedSubtaskStatusSelect
      itemListRenderer={renderMenu}
      items={props.statuses}
      disabled={disabled}
      className={selectClasses}
      itemRenderer={renderStatus}
      onItemSelect={onItemSelect}
      filterable={false}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover',
      }}
    >
      <Button
        style={{ color: statusObj.color, borderColor: statusObj.color }}
        className="status-select__button"
      >
        {statusObj.label}
      </Button>
    </TypedSubtaskStatusSelect>
  )
}
