import React, { useCallback, useMemo } from 'react'
import { ID } from '@datorama/akita'
import {
  HTMLInputProps,
  IInputGroupProps,
  ITagProps,
  Menu,
  PopoverPosition,
  Tag,
} from '@blueprintjs/core'
import {
  IItemRendererProps,
  ItemListRenderer,
  Suggest,
} from '@blueprintjs/select'

import { Empty } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import './styles/suggest.scss'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'
import {
  ALL_TASK_TYPES,
  TaskType,
  ALL_TASK_TYPES_ID,
} from '../../entities/task-type/model'

const TypedTaskTypesSuggest = Suggest.ofType<TaskType>()

interface TaskTypesSuggestProps {
  taskTypes: TaskType[]
  selectedIDs: ID[]
  onChangeSelectedIDs: (IDs: ID[]) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  popoverPosition?: PopoverPosition
  tilesClassName?: string
  wrapperClassName?: string
  targetClassName?: string
  popoverFill?: boolean
  noTilesWrapper?: boolean
  inputProps?: IInputGroupProps & HTMLInputProps
  escapeWithReference?: boolean
  inputIcon?: React.ReactNode
  closeOnSelect?: boolean
  portalClassName?: string
}

export function TaskTypesSuggest(props: TaskTypesSuggestProps) {
  const {
    selectedIDs,
    onChangeSelectedIDs,
    escapeWithReference = true,
    closeOnSelect = true,
  } = props

  const taskTypes = useMemo(() => {
    const _taskTypes = [...props.taskTypes]
    if (props.allowSelectAll) {
      _taskTypes.unshift(ALL_TASK_TYPES)
    }
    return _taskTypes
  }, [props.taskTypes, props.allowSelectAll])

  const onChangeTaskType = useCallback(
    (taskType: TaskType) => {
      const exists = selectedIDs.includes(taskType.id)
      if (exists) {
        onChangeSelectedIDs(selectedIDs.filter((id) => id !== taskType.id))
      } else if (taskType.id === ALL_TASK_TYPES_ID) {
        onChangeSelectedIDs([ALL_TASK_TYPES_ID])
      } else if (
        selectedIDs.length === 1 &&
        selectedIDs[0] === ALL_TASK_TYPES_ID
      ) {
        onChangeSelectedIDs([taskType.id])
      } else {
        onChangeSelectedIDs([taskType.id, ...selectedIDs])
      }
    },
    [selectedIDs, onChangeSelectedIDs]
  )

  const handleTagRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, tagProps: ITagProps | any) => {
      const id = tagProps['data-id']
      const taskType = taskTypes.find((taskType) => taskType.id === id)!
      onChangeTaskType(taskType)
    },
    [taskTypes, onChangeTaskType]
  )

  const filterTaskType = useCallback((query: string, taskType: TaskType) => {
    const matchString = taskType.name.toLowerCase()
    return matchString.indexOf(query.trim().toLowerCase()) >= 0
  }, [])

  const renderMenu: ItemListRenderer<TaskType> = ({
    items,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null)
    if (renderedItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          {(!taskTypes.length && props.emptyListLabel && (
            <li className="task_types-suggest__empty-list-label">
              {props.emptyListLabel}
            </li>
          )) || (
            <Empty imageStyle={{ height: 60 }} description={'No Results'} />
          )}
        </Menu>
      )
    }
    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={250}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  const renderTaskType = useCallback(
    (taskType: TaskType, renderProps: IItemRendererProps) => {
      const { modifiers, handleClick } = renderProps

      if (!modifiers.matchesPredicate) {
        return null
      }

      return (
        <li
          className={classNames('bp3-menu-item', {
            'bp3-multi': modifiers.active,
            'bp3-menu-item_selected': selectedIDs.includes(taskType.id),
          })}
          onClick={handleClick}
          key={taskType.id}
        >
          <div className="task-type-select__list-item">
            <div
              className="task-type-select__list-name bp3-text-overflow-ellipsis"
              title={taskType.name}
            >
              {taskType.name}{' '}
            </div>
          </div>
        </li>
      )
    },
    [selectedIDs]
  )

  const selectedTaskTypes = useMemo(() => {
    return taskTypes.filter((taskType) => selectedIDs.includes(taskType.id))
  }, [selectedIDs, taskTypes])

  const DEFAULT_INPUT_PROPS: IInputGroupProps & HTMLInputProps = {
    name: 'searchTerm',
    autoComplete: 'off',
    placeholder: 'Enter Task Type',
    rightElement: (
      <SearchOutlined
        style={{
          color: 'var(--color-text-inactive)',
          fontSize: 13,
        }}
        className={'suggest__search-icon'}
      />
    ),
  }

  const tilesClasses = classNames('suggest__tiles', props.tilesClassName)
  const wrapperClasses = classNames('suggest', props.wrapperClassName)

  const tiles = selectedTaskTypes.map((taskType: TaskType) => (
    <Tag
      className="project-tile suggest__tile task-types__tile"
      data-id={taskType.id}
      onRemove={handleTagRemove}
      key={taskType.id}
    >
      <div className="suggest__content">
        <div className="suggest__name text-trim-ellipsis">{taskType.name}</div>
      </div>
    </Tag>
  ))

  return (
    <div className={wrapperClasses}>
      <div className="suggest__input-wrapper">
        {props.inputIcon}
        <TypedTaskTypesSuggest
          items={taskTypes.filter((x) => !selectedTaskTypes.includes(x))}
          resetOnClose={true}
          closeOnSelect={closeOnSelect}
          itemRenderer={renderTaskType}
          onItemSelect={onChangeTaskType}
          itemPredicate={filterTaskType}
          itemListRenderer={renderMenu}
          inputValueRenderer={useCallback(() => '', [])}
          noResults={
            (!taskTypes.length && props.emptyListLabel && (
              <div className="suggest__empty-list-label">
                {props.emptyListLabel}
              </div>
            )) || (
              <Empty imageStyle={{ height: 60 }} description={'No Results'} />
            )
          }
          inputProps={props.inputProps || DEFAULT_INPUT_PROPS}
          popoverProps={{
            className: props.targetClassName,
            fill: props.popoverFill || true,
            minimal: true,
            position: props.popoverPosition || 'bottom',
            popoverClassName: 'popover suggest-popover',
            portalClassName: classNames(
              'suggest-portal',
              props.portalClassName
            ),
            modifiers: {
              preventOverflow: {
                escapeWithReference: escapeWithReference,
              },
            },
          }}
        />
      </div>
      {selectedTaskTypes.length > 0 && (
        <>
          {props.noTilesWrapper ? (
            tiles
          ) : (
            <CustomScrollbar translateContentSizeYToHolder={true}>
              <div className={tilesClasses}>{tiles}</div>
            </CustomScrollbar>
          )}
        </>
      )}
    </div>
  )
}
