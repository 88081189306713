import './index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { akitaDevtools } from '@datorama/akita'
// eslint-disable-next-line
import whyDidYouRender from '@welldone-software/why-did-you-render'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'

// if (process.env.NODE_ENV === 'development') {
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     onlyLogs: false,
//     logOnDifferentValues: true,
//     trackHooks: true,
//     exclude: [
//       /Blueprint3/,
//       /Connect\(Droppable\)/,
//       /Connect\(Draggable\)/,
//       /AnimateInOut/,
//       // /_DraggableTaskListItem/,
//       /Body/,
//     ],
//     titleColor: 'green',
//     diffNameColor: 'darkturquoise',
//   })
// }

Sentry.init({
  dsn: 'https://6d40e1e885174ecbb39a833e9552bfbf:1b11cccdfb944be0914d90df15418ed4@sentry.gearheart.io/19',
  environment: process.env.NODE_ENV,
})

akitaDevtools()

ReactDOM.render(
  // Strict mode is turned off because ant.design doesn't support it yet.
  // Should be turned on when following issue is resolved:
  // https://github.com/ant-design/ant-design/issues/22493
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
