import React from 'react'

interface ActivityGrayItalicTextProps {
  children: React.ReactNode
}

export function ActivityGrayItalicText(props: ActivityGrayItalicTextProps) {
  const { children } = props
  return <span className="task-activity__italic-text-log">{children}</span>
}
