import React, { useCallback, useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import {
  EditableText,
  IEditableTextProps,
  IEditableTextState,
} from '@blueprintjs/core'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { isEqual, omit } from 'lodash'

export type EditableTextValidator = (value: string) => string | void

export const FieldIsRequiredValidator: EditableTextValidator = (value) => {
  if (!value) {
    return 'Field is required'
  }
}

interface ValidatedEditableTextProps
  extends IEditableTextProps,
    IEditableTextState {
  validated?: boolean
  tooltipPlacement?: TooltipPlacement
  validator?: EditableTextValidator
}

function _EditableTextComponent(props: ValidatedEditableTextProps) {
  const { defaultValue, onChange } = props

  const [inputValue, setInputValue] = useState(defaultValue)
  useEffect(() => setInputValue(defaultValue), [defaultValue])

  const _onChange = useCallback(
    (value: string) => {
      setInputValue(value)
      if (onChange) {
        onChange(value)
      }
    },
    [onChange]
  )

  if (props.validated) {
    return (
      <ValidatedEditableText
        {...props}
        value={inputValue}
        onChange={_onChange}
      />
    )
  }

  return (
    <EditableText
      {...omit(props, ['validated', 'tooltipPlacement', 'validator'])}
      value={inputValue}
      onChange={_onChange}
    />
  )
}

export const EditableTextComponent = React.memo(_EditableTextComponent, isEqual)

function ValidatedEditableText(props: ValidatedEditableTextProps) {
  const { defaultValue, validator, onConfirm, onChange } = props
  const [validationError, setValidationError] = useState<string | void>()
  useEffect(() => setValidationError(), [defaultValue])

  const _onConfirm = useCallback(
    (newValue: string) => {
      if (newValue === defaultValue) {
        return
      }
      if (validator) {
        const error = validator(newValue)
        setValidationError(error)
        if (error) {
          return
        }
      }
      if (onConfirm) {
        onConfirm(newValue)
      }
    },
    [defaultValue, validator, onConfirm]
  )

  const _onChange = useCallback(
    (value: string) => {
      if (!!validationError) {
        setValidationError()
      }
      if (onChange) {
        onChange(value)
      }
    },
    [validationError, setValidationError, onChange]
  )

  return (
    <Tooltip
      placement={props.tooltipPlacement || 'bottomLeft'}
      visible={!!validationError}
      title={validationError || ''}
    >
      <EditableText {...props} onChange={_onChange} onConfirm={_onConfirm} />
    </Tooltip>
  )
}
