import {MarkType, NodeType} from "prosemirror-model"
import {EditorState, Transaction} from "prosemirror-state"
import {lift, setBlockType, toggleMark, wrapIn} from "prosemirror-commands"
import {wrapInList} from "prosemirror-schema-list"

import { disableMenu } from "./plugins"
import { enableUrlDialog } from "../external-link/plugins"
import {EditorView} from "prosemirror-view";

interface ItemProps { macLabel?: string, label: string, iconName?: string, attrs?: {[key: string]: any}}
interface MarkItemProps extends ItemProps { mark: MarkType }
interface NodeItemProps extends ItemProps { node: NodeType }
interface CmdNodeItemProps extends NodeItemProps {
  cmd: (node: NodeType, attrs: {[key: string]: any}) =>
    (state: EditorState, dispatch?: (tr: Transaction) => void) => boolean,
  attrs?: {[key: string]: any}
  active?: ((state: EditorState) => boolean) | any
}
export interface MenuItem extends ItemProps {
  run: (state: EditorState, dispatch?: (tr: Transaction) => void, view?: EditorView) => boolean
  active?: ((state: EditorState) => boolean) | any
}
export interface DropDown extends ItemProps {
  options: MenuItem[]
}

export const markItem = ({mark, attrs={}, ...props}: MarkItemProps): MenuItem => mark && {
  ...props,
  active: (state: EditorState) => markActive(state, mark),
  run: toggleMark(mark, attrs)
}
export const nodeItem = ({node, cmd, attrs={}, ...props}: CmdNodeItemProps): MenuItem => node && {
  ...props, run: cmd(node, attrs)
}
export const dropDown = ({options, ...props}: DropDown) => Boolean(options.length) && {options, ...props}

export const setBlockItem = ({node, attrs = {}, ...props}: NodeItemProps): MenuItem => nodeItem({
  node,
  attrs,
  cmd: setBlockType,
  active: (state: EditorState) => {
    const {$from, to, node: selectionNode} = state.selection as any
    if (selectionNode) return selectionNode.hasMarkup(node, attrs)
    return to <= $from.end() && $from.parent.hasMarkup(node, attrs)
  },
  ...props
})

export const wrapItem = ({node, ...props}: NodeItemProps): MenuItem =>
  nodeItem({node, cmd: wrapIn, ...props})
export const wrapListItem = ({node, ...props}: NodeItemProps): MenuItem =>
  nodeItem({node, cmd: wrapInList,  ...props})
export const liftItem = (props: ItemProps): MenuItem => ({...props, run: lift})

export const linkItem = ({mark, ...props}: MarkItemProps) => ({
  ...props,
  active: (state: EditorState) => markActive(state, mark),
  run: (state: EditorState, dispatch?: (tr: Transaction) => void, view: EditorView | null = null) => {
    const cmd = toggleMark(mark, {href: ""})
    if (!cmd(state)) return false
    if (dispatch && view) {
      cmd(state, dispatch)

      if (!markActive(state, mark)) {
        const tr = view.state.tr
        disableMenu(tr)
        enableUrlDialog(tr)
        dispatch(tr)
      }
    }
    return true
  }
})

const markActive = (state: EditorState, type: MarkType) => {
  const {from, $from, to, empty} = state.selection
  if (empty) return type.isInSet(state.storedMarks || $from.marks())
  return state.doc.rangeHasMark(from, to, type)
}


