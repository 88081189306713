import { Project } from './model'
import { ID } from '@datorama/akita'
import { http } from '../../globals/http'
import { Observable } from 'rxjs'
import { ModelUpdate } from '../../utils/types'

export class ProjectDataService {
  get(id: ID) {
    return http.get<Project>(`/api/project/${id}/`)
  }

  list(query?: object) {
    return http.get<Project[]>('/api/project/', query)
  }

  update(update: ModelUpdate<Project>) {
    return http.patch<Project>(`/api/project/${update.id}/`, update)
  }

  addNewProject(data: Partial<Project>): Observable<Project> {
    return http.post<Project>('/api/project/', data)
  }

  uploadLogo(projectId: ID, file: File): Observable<Project> {
    return http.postFile<Project>(
      `/api/project/${projectId}/logo/`,
      'logo',
      file
    )
  }

  currentTeamActivities(id: ID) {
    return http.get(`/api/project/${id}/current_team_activities/`)
  }
}

export const projectDataService = new ProjectDataService()
