import { ITagProps, Tag } from '@blueprintjs/core'
import { ID } from '@datorama/akita'
import classNames from 'classnames'
import React, {useContext} from 'react'
import { UserAvatar } from '../../../components/UserAvatar'
import {
  sortAlphabetically,
  User, UserRole,
} from '../../../entities/user/model'
import { PanelSectionTitle } from '../panel-section-title/PanelSectionTitle'
import { ProjectDetailsMembersSelect } from './ProjectDetailsMembersSelect'
import './styles/project-details-members.scss'
import {CurrentUserContext} from "../../../auth/currentUserContext";
interface ProjectPanelMembersProps {
  users: User[]
  selectedUsersIDs: ID[]
  onChangeSelectedUsersIDs: (userIds: ID[]) => void
}

export function ProjectPanelMembers(props: ProjectPanelMembersProps) {
  const onRemoveUser = (
    e: React.MouseEvent<HTMLButtonElement>,
    tagProps: ITagProps | any
  ) => {
    const selectedId = tagProps['data-id']
    const res = props.selectedUsersIDs.filter((id) => id !== selectedId)
    props.onChangeSelectedUsersIDs(res)
  }
  const currentUser = useContext(CurrentUserContext)!

  const onSelectUsers = (id: number | ID) => {
    const res = [...props.selectedUsersIDs, id]
    props.onChangeSelectedUsersIDs(res)
  }

  const users = !!props.selectedUsersIDs.length
    ? (props.selectedUsersIDs.map((id) => {
        return props.users.find((user) => user.id === id)
      }) as User[]).filter(Boolean)
    : []


  const sortedUsers = !!users.length ? sortAlphabetically([...users]) : []

  return (
    <div className="project-details-members project-details__section">
      <PanelSectionTitle title="members" />
      <div className="project-details-members__row">
        {!!sortedUsers.length &&
          sortedUsers.map((user) => {
            const classes = classNames(
              'project-tile project-details-members__col project-details-members-tag',
              {
                'project-details-members-tag_inactive': !user.is_active,
              }
            )
            const avatarClasses = classNames('project-details-members-tag__avatar', {
              'project-details-members-tag__avatar_has-img': !!user.avatar
            })
            return (
              <Tag
                className={classes}
                data-id={user?.id}
                key={user?.id}
                onRemove={currentUser?.user_role === UserRole.ADMIN ? onRemoveUser: undefined}
              >
                <div className="project-details-members-tag__content">
                  <UserAvatar
                    className={avatarClasses}
                    user={user}
                    size={28}
                    shape="square"
                  />
                  <div className="text-trim-ellipsis">
                    {user?.full_name || user?.email}
                  </div>
                </div>
              </Tag>
            )
          })}
        <ProjectDetailsMembersSelect
          members={props.users}
          selectedMembersIDs={props.selectedUsersIDs}
          onSelect={onSelectUsers}
        />
      </div>
    </div>
  )
}
