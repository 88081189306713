import CSS from 'csstype';

import {
  CollisionInput,
  NormalizedData,
  Position,
  BoundingElementRect,
  FlipPosition,
  PositionOffset,
  PositionBoundaryOffsets,
  PositionData,
  CollisionPositionRules,
  Collision,
} from './ElementPosition.types';
import { collisionRule } from './collisionRules';

export const normalizePositionOptions = (
  my: Position,
  at: Position,
  collision: CollisionInput
): NormalizedData => {
  const rHorizontal = /left|center|right/;
  const rVertical = /top|center|bottom/;
  const rOffset = /[+-]\d+(\.[\d]+)?%?/;
  const rposition = /^\w+/;

  const result: NormalizedData = {
    my: my.split(' '),
    at: at.split(' '),
    offsets: { my: [0, 0], at: [0, 0] },
    collision: (collision || 'flip').split(' ') as Collision[],
  };

  // @ts-ignore
  ['my', 'at'].forEach((positionParam: 'my' | 'at') => {
    let pos = result[positionParam];

    if (pos.length === 1) {
      pos = rHorizontal.test(pos[0])
        ? pos.concat(['center'])
        : rVertical.test(pos[0])
        ? ['center'].concat(pos)
        : ['center', 'center'];
    }

    pos[0] = rHorizontal.test(pos[0]) ? pos[0] : 'center';
    pos[1] = rVertical.test(pos[1]) ? pos[1] : 'center';

    // Calculate offsets
    const horizontalOffset: RegExpExecArray | null = rOffset.exec(pos[0]);
    const verticalOffset: RegExpExecArray | null = rOffset.exec(pos[1]);
    result.offsets[positionParam] = [
      horizontalOffset ? parseInt(horizontalOffset[0], 10) : 0,
      verticalOffset ? parseInt(verticalOffset[0], 10) : 0,
    ];

    // Reduce to just the positions without the offsets
    // @ts-ignore
    result[positionParam] = [rposition.exec(pos[0])[0], rposition.exec(pos[1])[0]];
  });

  // Normalize collision option
  if (result.collision.length === 1) {
    result.collision[1] = result.collision[0];
  }

  return result;
};

export function getScrollbarWidth(): number {
  const div = document.createElement('div');
  div.setAttribute(
    'style',
    'display:block;position:absolute;width:50px;height:50px;overflow:hidden;'
  );
  div.innerHTML = "<div style='height:100px;width:auto;'></div>";

  const innerDiv = div.firstChild as HTMLElement;

  document.body.appendChild(div);
  const w1 = innerDiv.offsetWidth;
  div.style.setProperty('overflow', 'scroll');
  let w2 = innerDiv.offsetWidth;

  if (w1 === w2) {
    w2 = div.clientWidth;
  }

  div.remove();

  return w1 - w2;
}

export function getOffsets(offsets: number[], width: number, height: number): number[] {
  const rpercent = /%$/;

  return [
    offsets[0] * (rpercent.test(String(offsets[0])) ? width / 100 : 1),
    offsets[1] * (rpercent.test(String(offsets[1])) ? height / 100 : 1),
  ];
}

export function getScrollInfo(
  boundaryElement: HTMLElement | Window,
  scrollbarWidth: number
): { width: number; height: number } {
  let overflowX = '';
  let overflowY = '';
  let hasOverflowX: boolean;
  let hasOverflowY: boolean;

  if (boundaryElement instanceof Window) {
    const { scrollWidth, scrollHeight } = document.scrollingElement as any;
    const styles: CSSStyleDeclaration = getComputedStyle(document.scrollingElement as any);
    overflowX = styles.overflowX;
    overflowY = styles.overflowY;

    hasOverflowX =
      overflowX === 'scroll' ||
      (['auto', 'visible'].includes(overflowY) && boundaryElement.innerWidth < scrollWidth);
    hasOverflowY =
      overflowY === 'scroll' ||
      (['auto', 'visible'].includes(overflowY) && boundaryElement.innerHeight < scrollHeight);
  } else {
    const { scrollWidth, scrollHeight } = boundaryElement;
    const styles: CSSStyleDeclaration = getComputedStyle(boundaryElement);
    overflowX = styles.overflowX;
    overflowY = styles.overflowY;

    hasOverflowX =
      overflowX === 'scroll' || (overflowX === 'auto' && boundaryElement.offsetWidth < scrollWidth);
    hasOverflowY =
      overflowY === 'scroll' ||
      (overflowY === 'auto' && boundaryElement.offsetHeight < scrollHeight);
  }

  return {
    width: hasOverflowY ? scrollbarWidth : 0,
    height: hasOverflowX ? scrollbarWidth : 0,
  };
}

export function getOffset(element: HTMLElement): BoundingElementRect {
  const docElement = document.documentElement;
  const { offsetWidth, offsetHeight } = element;
  const { top, width, height, left } = element.getBoundingClientRect();

  return {
    width: width || offsetWidth,
    height: height || offsetHeight,
    top: top + (window.pageYOffset || docElement.scrollTop),
    left: left + (window.pageXOffset || docElement.scrollLeft),
    scrollTop: element.scrollTop,
    scrollLeft: element.scrollLeft,
  };
}

export function getViewport(): BoundingElementRect {
  const docElement = document.documentElement;
  const body = document.getElementsByTagName('body')[0];
  const width = window.innerWidth || docElement.clientWidth || body.clientWidth;
  const height = window.innerHeight || docElement.clientHeight || body.clientHeight;

  return {
    width,
    height,
    top: 0,
    left: 0,
    scrollTop: docElement.scrollTop,
    scrollLeft: docElement.scrollLeft,
  };
}

export function getPosition(
  positionContentEl: HTMLElement,
  targetElement: HTMLElement,
  boundaryElement: HTMLElement | Window,
  boundaryOffsets: PositionBoundaryOffsets,
  elementPosition: CSS.PositionProperty,
  scrollbarWidth: number,
  my: string[],
  at: string[],
  offsets: PositionOffset,
  collision: Collision[]
): PositionData {
  if (!targetElement?.offsetParent || !positionContentEl?.offsetParent) {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      scrollTop: 0,
      scrollLeft: 0,
      marginTop: 0,
      marginLeft: 0,
      flipState: { horizontal: false, vertical: false },
    };
  }

  const targetOffset = getOffset(targetElement);
  const appendToOffset = getOffset(positionContentEl.offsetParent as HTMLElement);
  const scrollInfo = getScrollInfo(boundaryElement, scrollbarWidth);
  const targetWidth = targetOffset.width;
  const targetHeight = targetOffset.height;
  const boundaryOffset =
    boundaryElement instanceof Window ? getViewport() : getOffset(boundaryElement);

  // Calculate base element position
  const targetPosition: BoundingElementRect = { ...targetOffset };

  if (at[0] === 'right') {
    targetPosition.left += targetWidth;
  } else if (at[0] === 'center') {
    targetPosition.left += targetWidth / 2;
  }

  if (at[1] === 'bottom') {
    targetPosition.top += targetHeight;
  } else if (at[1] === 'center') {
    targetPosition.top += targetHeight / 2;
  }

  // Add target offset to the base element position
  const [atOffsetLeft, atOffsetTop] = getOffsets(offsets.at, targetWidth, targetHeight);
  targetPosition.left += atOffsetLeft;
  targetPosition.top += atOffsetTop;

  // Calculate real element position
  const { width: elemWidth, height: elemHeight } = getOffset(positionContentEl);

  const collisionWidth = elemWidth + scrollInfo.width;
  const collisionHeight = elemHeight + scrollInfo.height;

  const position = { ...targetPosition };

  if (my[0] === 'right') {
    position.left -= elemWidth;
  } else if (my[0] === 'center') {
    position.left -= elemWidth / 2;
  }

  if (my[1] === 'bottom') {
    position.top -= elemHeight;
  } else if (my[1] === 'center') {
    position.top -= elemHeight / 2;
  }

  // Add self offset to the base element position
  const [myOffsetLeft, myOffsetTop] = getOffsets(
    offsets.my,
    positionContentEl.offsetWidth,
    positionContentEl.offsetHeight
  );
  position.left += myOffsetLeft;
  position.top += myOffsetTop;

  // Detect element collision
  const flipState: FlipPosition = { horizontal: false, vertical: false };

  // @ts-ignore
  ['left', 'top'].forEach((dir: 'left' | 'top', index: number) => {
    const currCollision = collision[index];

    if (collisionRule[currCollision]) {
      const rules: CollisionPositionRules = collisionRule[currCollision];
      const checkCollision = rules[dir];

      flipState[dir === 'top' ? 'vertical' : 'horizontal'] = checkCollision(position, {
        targetWidth,
        targetHeight,
        elemWidth,
        elemHeight,
        collisionWidth,
        collisionHeight,
        boundaryOffset,
        offset: [atOffsetLeft + myOffsetLeft, atOffsetTop + myOffsetTop],
        my,
        at,
      });
    }
  });

  position.top -= appendToOffset.top;
  position.left -= appendToOffset.left;

  const result = {
    top: position.top,
    left: position.left,
    width: elemWidth,
    height: elemHeight,
    marginTop: 0,
    marginLeft: 0,
    scrollLeft: 0,
    scrollTop: 0,
    flipState,
  } as PositionData;

  // Set element position
  if (elementPosition === 'relative') {
    // relative position
    result.top = 0;
    result.left = 0;
    result.marginTop = position.top - targetOffset.height;
    result.marginLeft = position.left;
  }

  return result;
}
