import React, {useState} from "react"
import { useReactNodeView } from "../../../core/react-node-view"
import { Image, Button } from 'antd'
import { DownloadOutlined, CloseOutlined } from "@ant-design/icons"

import "./image.css"

export const ImageComponent: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const { node, getPos, view } = useReactNodeView()

  const handleOpenFull = () => {
    window.open(node?.attrs.src, '_blank')
  }

  const handleOnClose = () => {
    if (!getPos || !node || !(getPos instanceof Function)) { return }
    const from = getPos()
    view?.dom.classList.remove('file-load');
    view?.dispatch(view?.state.tr.delete(from, from + node.nodeSize))
    view?.focus()
  }

  const hasFocus = view?.hasFocus()

  return (
    <div className="prose-mirror-image-container">
      <Image
        src={node?.attrs.preview_src ? node?.attrs.preview_src : node?.attrs.src}
        preview={{
          visible: false,
          onVisibleChange: setVisible
        }}
      />
      {visible ? (
        <div style={{"display": "none"}}>
          <Image
            src={node?.attrs.src}
            preview={{
              visible,
              onVisibleChange: setVisible
            }}
          />
        </div>
      ) : null}
      <div className="prose-mirror-image-close-buttons">
        <Button
          onClick={handleOpenFull}
          icon={<DownloadOutlined />}
          size="small"
        />
        {hasFocus &&
          <Button
            onClick={handleOnClose}
            icon={<CloseOutlined />}
            size="small"
          />
        }

      </div>
    </div>
  )
}
