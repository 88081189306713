import React from 'react'
import { Avatar } from 'antd'
import { FileImageOutlined, UserOutlined } from '@ant-design/icons'
import { ALL_USERS, ShortUser, User } from '../entities/user/model'
import { TeamOutlined } from '@ant-design/icons/lib'

interface AvatarProps {
  user?: User | ShortUser
  className?: string
  shape?: 'circle' | 'square'
  size?: number | 'small' | 'large' | 'default'
}

const INVITED_COLOR = '#fa8c16'
const DEFAULT_COLOR = '#a0d911'
const INACTIVE_COLOR = 'var(--color-text-inactive)'

export function UserAvatar(props: AvatarProps) {
  if (!props.user || props.user.id === -1) {
    return (
      <Avatar
        className={props.className}
        icon={!!props.user ? <TeamOutlined /> : <UserOutlined />}
        size={props.size}
        shape={props.shape}
      />
    )
  }

  if (props.user === ALL_USERS) {
    return (
      <Avatar
        className={props.className}
        icon={<TeamOutlined />}
        size={props.size}
        shape={props.shape}
      />
    )
  }

  let color
  if (!props.user.is_active) {
    color = INACTIVE_COLOR
  } else if (props.user.is_invited) {
    color = INVITED_COLOR
  } else {
    color = props.user.color || DEFAULT_COLOR
  }

  if (!props.user.avatar || props.user.is_invited) {
    return (
      <Avatar
        className={props.className}
        shape={props.shape}
        size={props.size}
        style={{ backgroundColor: color }}
      >
        <span className="user-initials">{userInitials(props.user)}</span>
      </Avatar>
    )
  }

  return (
    <Avatar
      className={props.className}
      size={props.size}
      shape={props.shape}
      icon={<FileImageOutlined />}
      src={props.user.avatar}
      style={{ backgroundColor: color }}
    />
  )
}

function userInitials(user: User | ShortUser): string {
  if (!user.full_name) {
    return user.email[0].toUpperCase()
  }

  const initials = user.full_name
    .split(/[ -]/)
    .map((element) => element[0])
    .join('')

  return (initials || user.email[0]).toUpperCase()
}
