import React, { useRef } from 'react'
import { Button } from 'antd'
import { ElementPosition } from '../../../ui/element-position'

import './dialog.css'

export interface EditorUrlDialogProps {
  targetElement: HTMLElement
  appendTo: HTMLElement
  offsetTop: number
  offsetLeft: number
  href: string
  removeLink: () => void
}

export const Dialog = ({
  targetElement,
  offsetTop,
  offsetLeft,
  href,
  removeLink,
  appendTo,
}: EditorUrlDialogProps) => {
  const appendToRef = useRef(appendTo)
  const targetRef = useRef(targetElement)
  targetRef.current = targetElement

  const handleGo = () => {
    window.open(href, '_blank')
  }
  const handleRemove = () => {
    removeLink()
  }

  return (
    <ElementPosition
      boundaryElement={appendToRef}
      targetElement={targetRef}
      appendTo={appendToRef}
      zIndex={10000}
      my="left bottom"
      at={`left+${offsetLeft} top+${offsetTop}`}
    >
      <div className="external-link-dialog-container">
        <Button onClick={handleGo}>Go</Button>
        <Button onClick={handleRemove}>Remove link</Button>
      </div>
    </ElementPosition>
  )
}
