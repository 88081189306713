import { Store, StoreConfig } from '@datorama/akita'
import { TrackableType } from '../types'
import { IFormErrors } from '../../project-page/state/store'

export interface TrackingState {
  seconds_today: number
  seconds_week: number
  seconds_month: number

  tracking: boolean
  activity: string | null
  target: {
    type: TrackableType
    id: number
  } | null
  update_required: boolean

  entry: number | null
  comment: string
  formErrors: IFormErrors | null
  isFormOpened: boolean
}

const initialState = {
  seconds_today: 0,
  seconds_week: 0,
  seconds_month: 0,

  tracking: false,
  activity: null,
  target: null,
  update_required: false,

  entry: null,
  comment: '',
  formErrors: null,
  isFormOpened: false,
}

@StoreConfig({ name: 'ui-tracking-state' })
export class TrackingStore extends Store<TrackingState> {}

export const trackingStore = new TrackingStore(initialState)
