import { useParams, Redirect } from 'react-router-dom'
import React from 'react'
import {useInstantEffect} from "../utils/instant-effect-hook";
import {projectPageService} from "../project-page/state/service";
import {useQuery} from "../utils/queryhook";
import {projectPageQuery} from "../project-page/state/query";

export function LegacyTaskRedirect() {
  const { taskId, projectId } = useParams()

  useInstantEffect(() => {
    projectPageService.setProject(+projectId!)
  }, [projectId])

  const project = useQuery(projectPageQuery.project$)

  return <Redirect to={`task/${project?.code}-${taskId}`}/>
}
