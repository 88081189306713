import { ID } from '@datorama/akita'
import { ShortUser, User } from '../user/model'

export enum ProjectStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
}

export interface IProjectStatus {
  value: ProjectStatus
  label: string
}

export const PROJECT_STATUSES: IProjectStatus[] = [
  {
    value: ProjectStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: ProjectStatus.CLOSED,
    label: 'Closed',
  },
]

export interface Project {
  id: ID
  workspace: ID
  code: string
  name: string
  status: ProjectStatus
  created_by: ID
  updated_by: ID
  created_at: string
  updated_at: string
  slack_channel: string
  smartsuite_account?: string
  logo?: string
  color?: string
  users?: number[] | ID[]
  deadline: string | null
  time_logged?: number
  json_description: object | null
  created_by_user?: User | ShortUser
  updated_by_user?: User | ShortUser
}
