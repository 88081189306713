import { groupBy, sortBy } from 'lodash'
import { useMemo } from 'react'

/**
 * Groups list by key.
 *
 * It is written as a hook to allow caching in the future.
 *
 */
export function useGroupBy<T, K extends keyof T>(objects: T[], key: K) {
  // }: {[key: T[K]]: T[]} {
  return useMemo(() => groupBy(sortBy(objects, key), key), [objects, key])
}
