import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { User, UserRole } from '../../../entities/user/model'
import { UserAvatar } from '../../../components/UserAvatar'
import { extractDaysTillNow } from '../../../utils/common'

interface UsersListItemProps {
  user: User
  isSelected?: boolean
}

export default function UsersListItem(props: UsersListItemProps) {
  const { user, isSelected } = props
  const isInactive = !user.is_active
  const isInvited = user.is_invited
  const isAdmin = user.user_role === UserRole.ADMIN

  const [isHovered, setIsHovered] = useState(false)
  const isHighlighted = isSelected || isHovered

  const usersListItemClasses = useMemo(
    () =>
      classNames('users-list__item', {
        'users-list__item_inactive': isInactive,
        'users-list__item_inactive_selected': isInactive && isHighlighted,
        'users-list__item_invited': isInvited && !isInactive,
        'users-list__item_selected': isHighlighted,
      }),
    [isInactive, isInvited, isHighlighted]
  )

  const statusDiv = useMemo(() => {
    if (isInactive) {
      return <div className="users-list__item-status">inactive</div>
    } else if (isInvited) {
      return (
        <div className="users-list__item-status">
          Invited ({extractDaysTillNow(user.invited_at)} ago)
        </div>
      )
    }
  }, [isInactive, isInvited, user.invited_at])

  return (
    <Link to={`./${props.user.id}`}>
      <div
        className={usersListItemClasses}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <UserAvatar
          user={user}
          shape={'square'}
          size={40}
          className={'users-list__item-photo'}
        />
        <div className="users-list__item-about">
          <div className="users-list__item-info">
            <div className="users-list__item-name">{user.full_name}</div>
            {isAdmin && <div className="users-list__item-role">admin</div>}
          </div>
          <div className="users-list__item-email">{user.email}</div>
        </div>
        {statusDiv}
      </div>
    </Link>
  )
}
