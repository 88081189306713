import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Observable } from 'rxjs'
import { ID } from '@datorama/akita'
import { Button, Form, Input, Modal } from 'antd'
import { FormInstance } from 'antd/es/form'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Store } from 'rc-field-form/lib/interface'
import { User } from '../entities/user/model'
import { Project } from '../entities/project/model'
import { convertBackendErrors } from '../utils/backend-errors'
import { UsersSuggest } from '../components/controls/UsersSuggest'

interface DashboardNewProjectButtonProps {
  classModifier: string
  users: User[]
  onAddProject: (data: Partial<Project>) => Observable<Project>
}

export function DashboardAddProject(props: DashboardNewProjectButtonProps) {
  const history = useHistory()

  const initialValues = useMemo(
    () => ({
      name: '',
      code: '',
      users: [],
    }),
    []
  )

  const btnClassNames = classNames(
    'dashboard-page__add-project-btn',
    props.classModifier
  )

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [submitActive, setSubmitActive] = useState<boolean>(true)

  const onAddProjectClick = useCallback(() => setModalVisible(true), [])

  const formRef = useRef<FormInstance>(null)

  const onOk = useCallback(() => {
    formRef.current?.submit()
  }, [formRef])

  const onClose = useCallback(() => {
    setModalVisible(false)
    formRef.current?.resetFields()
  }, [formRef])

  const { onAddProject } = props

  const onFinish = useCallback(
    (values: Store) => {
      setSubmitActive(false)
      onAddProject(values as Partial<Project>).subscribe(
        (project: Project) =>
          history.push(`./${project.workspace}/${project.id}`),
        (error) => {
          if (error.response.status === 400) {
            formRef.current?.setFields(
              convertBackendErrors(error.response.data)
            )
          }
          setSubmitActive(true)
        }
      )
    },
    [history, onAddProject, formRef]
  )

  return (
    <>
      <Button
        type="dashed"
        className={btnClassNames}
        onClick={onAddProjectClick}
      >
        Add New Project
        <PlusCircleOutlined />
      </Button>
      <DashboardAddProjectModal
        visible={modalVisible}
        setModelVisible={setModalVisible}
        initialValues={initialValues}
        users={props.users}
        onOk={onOk}
        onCancel={onClose}
        onFinish={onFinish}
        formRef={formRef}
        submitActive={submitActive}
      />
    </>
  )
}

interface DashboardAddProjectModalProps {
  visible: boolean
  setModelVisible: Dispatch<SetStateAction<boolean>>
  initialValues: object
  users: User[]
  onOk: () => void
  onCancel: () => void
  onFinish: (values: Store) => void
  formRef: RefObject<FormInstance>
  submitActive: boolean
}

export function DashboardAddProjectModal(props: DashboardAddProjectModalProps) {
  const [selectedUsersIDs, setSelectedUsersIDs] = useState<ID[]>([])

  return (
    <Modal
      visible={props.visible}
      title="Add New Project"
      onOk={props.onOk}
      className="dashboard-modal-add-new-project"
      onCancel={props.onCancel}
      footer={[
        <Button
          key="back"
          className="dashboard-modal-add-new-project__cancel-button"
          onClick={props.onCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.onOk}
          disabled={!props.submitActive}
        >
          Add
        </Button>,
      ]}
    >
      <Form
        ref={props.formRef}
        labelCol={{ span: 5 }}
        layout="horizontal"
        initialValues={props.initialValues}
        onFinish={props.onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input placeholder={'Google Corp. '} maxLength={200} />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          rules={[{ required: true, message: 'Field is required' }, { max: 5 }]}
        >
          <Input placeholder={'Max. 5 characters'} maxLength={5} />
        </Form.Item>
        <Form.Item
          name="users"
          label="Users"
          rules={[{ required: true, message: 'Field is required' }]}
          trigger="onChangeSelectedUsersIDs"
          valuePropName="selectedUsersIDs"
        >
          <UsersSuggest
            portalClassName="dashboard-modal-users-suggest"
            popoverPosition="bottom"
            noUnassignedUser={true}
            users={props.users}
            selectedUsersIDs={selectedUsersIDs}
            onChangeSelectedUsersIDs={setSelectedUsersIDs}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
