import { applyTransaction, ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'
import { keys, pick, isEqual } from 'lodash'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { ProjectDataService, projectDataService } from './data-service'
import { Project } from './model'
import { projectQuery, ProjectQuery } from './query'
import { ProjectStore, projectStore } from './store'

import { optimisticSaveEnabled } from '../../globals/constants'
import { messageDispatcher } from '../../utils/message-dispatcher'
import { userService, UserService } from '../user/service'
import { ModelUpdate } from '../../utils/types'

export class ProjectService {
  constructor(
    private store: ProjectStore,
    private query: ProjectQuery,
    private dataService: ProjectDataService,
    private userService: UserService
  ) {}

  loadProject(id: ID) {
    this.dataService.get(id).subscribe((project) => {
      this.store.add(project)
    })
  }

  loadProjects(query?: object) {
    this.store.setLoading(true)
    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          applyTransaction(() => {
            this.store.setLoading(false)
            this.store.update({ loaded: true })
          })
        })
      )
      .subscribe((projects) => {
        applyTransaction(() => {
          this.store.add(projects)
        })
      })
  }

  addNewProject(data: Partial<Project>): Observable<Project> {
    const observable = this.dataService.addNewProject(data)
    observable.subscribe((project: Project) => {
      project.users?.forEach((userId) => {
        this.userService.updateProjects(userId, project.id)
      })
      this.store.add(project)
      messageDispatcher.putSuccessMessage(
        `Project ${project.name} has been created`
      )
    })
    return observable
  }

  @boundMethod
  updateProject(update: ModelUpdate<Project>) {
    this.updateOptimistic(update)
  }

  @boundMethod
  uploadLogo(id: ID, file: File) {
    this.dataService.uploadLogo(id, file).subscribe((value) => {
      this.store.update(value.id, value)
    })
  }

  /**
   * We first update the value and then try to send it to the backend.
   *
   * If save fails - we rollback the change.
   *
   */
  private updateOptimistic(update: ModelUpdate<Project>) {
    const currentProject = this.query.getEntity(update.id)
    const currentValues = pick(currentProject, keys(update))

    if (optimisticSaveEnabled) {
      this.store.update(update.id, update)
    }

    this.dataService.update(update).subscribe({
      next: (value: ModelUpdate<Project>) => {
        const updatedValues = pick(value, keys(update))
        // Update store only in case BE returns different values
        if (!isEqual(updatedValues, update)) {
          this.store.update(value.id, updatedValues)
        }

        if (update.hasOwnProperty('name')) {
          messageDispatcher.putSuccessMessage(
            `Project ${update.name} has been renamed`
          )
        } else if (update.hasOwnProperty('status')) {
          if (update.status === 'closed') {
            messageDispatcher.putSuccessMessage(
              `Project ${currentProject.name} has been closed`
            )
          } else if (update.status === 'active') {
            messageDispatcher.putSuccessMessage(
              `Project ${currentProject.name} has been switched to "active"`
            )
          }
        }
      },
      error: () => {
        this.store.update(update.id, currentValues)
      },
    })
  }
}

export const projectService = new ProjectService(
  projectStore,
  projectQuery,
  projectDataService,
  userService
)
