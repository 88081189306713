import { from, Observable, of } from 'rxjs'

export interface PushNotification {
  title: string
  body: string
  tag?: string
}

export class PushMessageManager {
  private notification = window['Notification']
  private notifySound = new Audio('/sound/notify.mp3')

  public init() {
    if (!this.hasPermission()) {
      this.requestPermission()
    }
  }

  public requestPermission(): Observable<string> {
    if (this.isSupported()) {
      return from(this.notification.requestPermission())
    }
    return of('denied')
  }

  public hasPermission(): boolean {
    if (this.isSupported()) {
      return this.notification.permission === 'granted'
    }
    return false
  }

  public createNotification(notificationObj: PushNotification): void {
    if (!this.isSupported()) {
      return
    }
    this.playNotificationSound()
    let notification = new this.notification(
      notificationObj.title,
      notificationObj
    )
    notification.onclick = function () {
      window.focus()
      this.close()
    }
  }

  private isSupported(): boolean {
    return !!this.notification
  }

  private playNotificationSound() {
    this.notifySound.play().catch((error) => {})
  }
}

export const pushMessageManager = new PushMessageManager()
