import classNames from 'classnames'
import React from 'react'
import { PanelSectionTitle } from '../../../panels/panel-section-title/PanelSectionTitle'
import { SprintDetailsStatisticsTable } from './SprintDetailsStatisticsTable'
import '../styles/sprint-details-statistics.scss'
import { SprintStatisticsItem } from './state/models'
import { ShortUser } from '../../../../entities/user/model'
import { CustomScrollbar } from '../../../../components/custom-scrollbar/CustomScrollbar'

interface SprintDetailsStatisticsProps {
  users: ShortUser[]
  statistic: SprintStatisticsItem[]
  statisticSummary: SprintStatisticsItem
  className?: string
}

export function SprintDetailsStatistics(props: SprintDetailsStatisticsProps) {
  const classes = classNames('sprint-details-statistics', props.className)
  return (
    <div className={classes}>
      <PanelSectionTitle title="statistics" />
      <div className="sprint-details-statistics__table-wrap">
        <CustomScrollbar translateContentSizeYToHolder={true}>
          <SprintDetailsStatisticsTable
            users={props.users}
            items={props.statistic}
            summary={props.statisticSummary}
          />
        </CustomScrollbar>
      </div>
    </div>
  )
}
