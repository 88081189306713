import React from 'react'
import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { ReactPluginView } from '../../core/react-plugin-view'

export class TaskTypePluginKey extends PluginKey {
  constructor(public prefix: string) {
    super(`${prefix}-task-type`)
  }

  updateState(view: EditorView, data: { [key: string]: any }) {
    const tr = view.state.tr
    tr.setMeta(this, data)
    view.dispatch(tr)
  }
}

export const TaskTypePlugin = (
  key: TaskTypePluginKey,
  Component: React.FC<any>
): Plugin => {
  return new Plugin({
    key: key,
    state: {
      init: () => ({ query: null, items: [], activeIndex: 0, selected: [] }),
      apply: (tr, value) => ({ ...value, ...(tr.getMeta(key) || {}) }),
    },
    props: {
      handleDOMEvents: {
        blur: (view, event: FocusEvent) => {
          if (!!event.relatedTarget) {
            view.dispatch(view.state.tr.setMeta(key, { enable: false }))
          }
          return false
        },
        focus: (view) => {
          view.dispatch(view.state.tr.setMeta(key, { enable: true }))
          return true
        },
      },
    },
    view: (view) => new ReactPluginView(view, Component, key),
  })
}
