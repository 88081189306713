import { Query } from '@datorama/akita'
import { CalendarPageState, calendarPageStore } from './store'

export class CalendarPageQuery extends Query<CalendarPageState> {
  selectedUsersIDs$ = this.select('selectedUsersIDs')
  showFiltersPanel$ = this.select('showFiltersPanel')
  selectedProject$ = this.select('selectedProject')
}

export const calendarPageQuery = new CalendarPageQuery(calendarPageStore)
