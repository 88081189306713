import classNames from 'classnames'
import React from 'react'

import './styles/icon.scss'

interface SpriteIconProps {
  name: string
  spritePath: string
  className?: string
}

export function SpriteIcon(props: SpriteIconProps) {
  const classes = classNames('icon', `icon-${props.name}`, {
    [`${props.className}`]: props.className,
  })
  return (
    <svg className={classes}>
      <use xlinkHref={`${props.spritePath}#icon-${props.name}`} />
    </svg>
  )
}
