import React from 'react'

import { Task } from '../../../entities/task/model'
import { Epic } from '../../../entities/epic/model'
import { Sprint } from '../../../entities/sprint/model'
import { taskService } from '../../../entities/task/service'
import { LinkedList } from './LinkedList'
import { ShortUser, User } from '../../../entities/user/model'
import { LinkedTasksPopoverSearch } from './LinkedTasksPopoverSearch'

import '../styles/task-list.scss'
import '../styles/linked-tasks.scss'
import { ID } from '@datorama/akita'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'

export interface LinkedTasksContainerProps {
  tasks: Task[]
  task: Task
  users: User[] | ShortUser[]
  epics?: Epic[]
  sprints?: Sprint[]
  taskTypes: TaskType[]
  linkedTasks: Task[]
  onRemoveTask: (idToRemove: ID) => void
  onAddLinkedTask: (idToAdd: ID) => void
  loadTasks: () => void
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function LinkedTasksContainer(props: LinkedTasksContainerProps) {
  const {
    tasks,
    task,
    epics,
    users,
    taskTypes,
    sprints,
    linkedTasks,
    onRemoveTask,
    onAddLinkedTask,
    loadTasks,
  } = props
  return (
    <div className="linked-tasks">
      <div className="linked-tasks__header">
        <LinkedTasksPopoverSearch
          linkedTasks={linkedTasks}
          tasks={tasks}
          task={task}
          onRemoveTask={onRemoveTask}
          onAddLinkedTask={onAddLinkedTask}
          loadTasks={loadTasks}
        />
      </div>
      <LinkedList
        users={users}
        epics={epics}
        tasks={linkedTasks}
        sprints={sprints}
        taskTypes={taskTypes}
        onTaskUpdate={taskService.updateTask}
        onRemoveTask={onRemoveTask}
        addTimeFormErrors={props.addTimeFormErrors}
        isAddTimeFormOpened={props.isAddTimeFormOpened}
        onOpenAddTimeModal={props.onOpenAddTimeModal}
        onCloseAddTimeModal={props.onCloseAddTimeModal}
      />
    </div>
  )
}
