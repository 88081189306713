import { http } from '../../globals/http'
import { ReportProject } from './model'

export class ReportProjectDataService {
  get(query?: object) {
    return http.get<ReportProject>('/api/report/project/', query)
  }
}

export const reportProjectPageDataService = new ReportProjectDataService()
