import moment from 'moment-timezone/moment-timezone'

export function getLastMonthStartAndEnd(): { start: Date; end: Date } {
  const momentLastMonth = moment().subtract(1, 'months')
  return {
    start: momentLastMonth.startOf('month').toDate(),
    end: momentLastMonth.endOf('month').toDate(),
  }
}

export function copyElementContentsToClipboard(element: HTMLElement): boolean {
  if (document.createRange && window.getSelection) {
    const range = document.createRange()
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      range.selectNodeContents(element)
      sel.addRange(range)
      document.execCommand('copy')
      sel.removeAllRanges()
      return true
    }
  }
  return false
}
