import React, { useRef } from 'react'
import { Menu } from '@blueprintjs/core'

import { ShortUser, User } from '../../../../entities/user/model'
import { ElementPosition } from '../../../ui/element-position'
import { UserAvatar } from '../../../../components/UserAvatar'

import './dialog.css'
import classNames from 'classnames'
import { CustomScrollbar } from '../../../../components/custom-scrollbar/CustomScrollbar'

export interface DialogProps {
  activeIndex: number
  appendTo: HTMLElement
  targetElement: HTMLElement
  offset: { top: number; left: number; right: number; bottom: number }
  users: any
  onClick: ({ id, title }: { id: number | string; title: string }) => void
}

export const Dialog = ({
  activeIndex,
  targetElement,
  offset,
  users,
  appendTo,
  onClick,
}: DialogProps) => {
  const appendToRef = useRef(appendTo)
  const targetRef = useRef(targetElement)
  targetRef.current = targetElement

  return (
    <ElementPosition
      targetElement={targetRef}
      boundaryElement={appendToRef}
      appendTo={appendToRef}
      zIndex={10000}
      my="left top"
      at={`left+${offset.left} bottom+${offset.bottom}`}
    >
      <Menu className="mention-user-menu">
        <CustomScrollbar
          translateContentSizesToHolder={true}
          rendererMaxHeight={200}
        >
          {users.map((user: User | ShortUser, index: number) => (
            <li
              className={classNames('bp3-menu-item', {
                'bp3-active': index === activeIndex,
              })}
              onClick={() => onClick({ id: user.id, title: user.full_name })}
              key={user.id}
            >
              <div className="user-select__list-item">
                <div className="user-select__list-avatar">
                  <UserAvatar user={user} shape={'square'} size={22} />
                </div>
                <div
                  className="user-select__list-full-name bp3-text-overflow-ellipsis"
                  title={user.full_name || user.email}
                >
                  {user.full_name || user.email}{' '}
                </div>
              </div>
            </li>
          ))}
        </CustomScrollbar>
      </Menu>
    </ElementPosition>
  )
}
