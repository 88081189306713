import { useParams } from "react-router-dom"

import { generateUserDetailUrl } from "../../routes"
import { BaseMentionProvider } from "../extentions/mention/provider"
import { ShortUser } from "../../entities/user/model"
import {useQuery} from "../../utils/queryhook"
import {projectPageQuery} from "../../project-page/state/query"
import { userQuery } from '../../entities/user/query'
import {taskPanelQuery} from "../../project-page/tasks/TaskPanel/state/query";
import {taskService} from "../../entities/task/service";

export class MentionProvider extends BaseMentionProvider {
  constructor(private sendRequest: boolean = true) { super() }

  useMentionUrl = ({id}: {id: string | number}): string => {
    const { workspaceId } = useParams()
    return generateUserDetailUrl(workspaceId as string, id)
  }

  useMention = ({id}: {id: string | number}): ShortUser | null => {
    const user = useQuery(userQuery.selectEntity(id))

    const currentTask = useQuery(taskPanelQuery.task$)
    if (currentTask.followers.includes(user.id)) {
      return user
    }

    if (user && currentTask && this.sendRequest){
      taskService.addFollowers(currentTask.id!, [...currentTask.followers, user.id])
    }

    return user
  }

  useSearch = ({query}: {query: string}): ShortUser[] => {
    let sortedUsers = useQuery(projectPageQuery.projectUsers$).filter(user => {
      const fullName = user.full_name.toLowerCase()
      const slack = user.slack_name ? user.slack_name.toLowerCase() : user.slack_name
      const _query = query ? query.toLowerCase() : query
      return fullName.includes(_query) || (slack && slack.includes(_query))
    })
    const currentTask = useQuery(taskPanelQuery.task$)
    if (currentTask) {
        sortedUsers = [
        ...sortedUsers.filter((user) => currentTask.followers.includes(user.id)),
        ...sortedUsers.filter((user) => !currentTask.followers.includes(user.id))
      ];
    }
    return sortedUsers
  }
}
