import React, { useCallback } from 'react'
import { Select, IItemRendererProps } from '@blueprintjs/select'
import { Button, Icon } from '@blueprintjs/core'
import { FireOutlined, CoffeeOutlined, StopOutlined } from '@ant-design/icons'
import { PriorityChoice } from '../../../entities/choices/priority'
import classNames from 'classnames'

import '../../../assets/scss/select.scss'
import './styles/priority-select.scss'
import { useSelect } from '../../../utils/select'

const TypedPrioritySelect = Select.ofType<PriorityChoice>()

export interface PrioritySelectProps {
  priority: string
  disabled?: boolean | false
  priorities: PriorityChoice[]
  isBordered?: boolean | false
  isExtended?: boolean | false
  onSelect: (priority: string) => void
}

export function PrioritySelect(props: PrioritySelectProps) {
  const { onSelect, disabled } = props

  const [selectRef, showSelect, showSelectHandler] = useSelect<PriorityChoice>()

  const prioritiesIcons = [
    {
      value: 'hotfix',
      icon: <FireOutlined style={{ fontSize: '14px', color: '#fa8c16' }} />,
    },
    {
      value: 'trivial',
      icon: (
        <CoffeeOutlined
          style={{ fontSize: '16px', color: 'var(--color-text-inactive)' }}
        />
      ),
    },
    {
      value: 'blocker',
      icon: <StopOutlined style={{ fontSize: '13px', color: '#d4380d' }} />,
    },
    {
      value: 'major',
      icon: (
        <Icon icon="arrow-up" iconSize={12} color="var(--color-text-link)" />
      ),
    },
    {
      value: 'minor',
      icon: <Icon icon="arrow-down" iconSize={12} color="#389e0d" />,
    },
  ]

  const onItemSelect = useCallback(
    (Priority: PriorityChoice) => onSelect(Priority.value),
    [onSelect]
  )

  let priority_obj = props.priorities.find((s) => s.value === props.priority)

  if (!priority_obj) {
    return null
  }

  const findMatchingIcon = (priority: PriorityChoice) => {
    return prioritiesIcons.find(
      (priorIcon) => priority?.value === priorIcon.value
    )?.icon
  }

  const selectIcon = findMatchingIcon(priority_obj)

  const buttonClasses = classNames(
    'priority-select__button',
    `priority-select__button_${priority_obj.value}`,
    {
      'priority-select__button_bordered': props.isBordered,
      'priority-select__button_extended': props.isExtended,
      'priority-select__button_disabled': disabled,
    }
  )

  function renderPriority(
    priority: PriorityChoice,
    renderProps: IItemRendererProps
  ) {
    const { modifiers, handleClick } = renderProps

    if (!modifiers.matchesPredicate) {
      return null
    }

    const menuItemClasses = classNames(
      'priority-popover__menu-item',
      'bp3-menu-item',
      `bp3-menu-item_${priority.value}`,
      { 'bp3-active': modifiers.active }
    )

    const menuItemIcon = findMatchingIcon(priority)

    return (
      <li
        className={menuItemClasses}
        onClick={handleClick}
        key={priority.value}
      >
        <div className="priority-popover__icon">{menuItemIcon}</div>
        <div className="bp3-text-overflow-ellipsis bp3-fill">
          {priority.label}
        </div>
      </li>
    )
  }

  const selectClasses = classNames('priority-select', 'select', {
    select_disabled: disabled,
  })

  const CurrentSelectItem = (
    <>
      {selectIcon}
      {props.isExtended && (
        <div className="priority-select__button-text">{priority_obj.label}</div>
      )}
    </>
  )

  if (!showSelect)
    return (
      <div className={selectClasses}>
        <Button onClick={showSelectHandler} className={buttonClasses}>
          {CurrentSelectItem}
        </Button>
      </div>
    )

  return (
    <TypedPrioritySelect
      ref={selectRef}
      items={props.priorities}
      disabled={disabled}
      activeItem={priority_obj}
      className={selectClasses}
      itemsEqual={(one, two) => one.value === two.value}
      onItemSelect={onItemSelect}
      filterable={false}
      itemRenderer={renderPriority}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover priority-popover',
      }}
    >
      <Button className={buttonClasses}>{CurrentSelectItem}</Button>
    </TypedPrioritySelect>
  )
}
