import { Mark, MarkType, Node as ProseMirrorNode } from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'
import { TextSelection } from 'prosemirror-state'
import {EditorView} from "prosemirror-view";

export interface LinkData {
  from: number;
  to: number;
  mark: Mark;
}

export const getCurrentLinkData = (state: EditorState): LinkData | null => {
  const { $cursor } = state.selection as TextSelection
  if (!$cursor) { return null }

  const link = state.doc.nodeAt($cursor.pos);
  if (!link) { return null }

  const mark = getMark(link, state.schema.marks.link);
  if (!mark) { return null }

  const from = $cursor.pos - $cursor.textOffset;
  const to = from + link.nodeSize;

  return { from, to, mark };
}

export const getMark = (node: ProseMirrorNode, markType: MarkType): Mark | undefined => {
  return node.marks.find((m) => m.type === markType);
}

export const getMarkAt = (state: EditorState, pos: number, markType: MarkType): Mark | undefined => {
  const node = state.doc.nodeAt(pos);
  if (!node) { return undefined }
  return getMark(node, markType)
}

export const getPositionOffset = (view: EditorView, from: number) => {
  const $from = view.state.doc.resolve(from)
  const parentElement = view.nodeDOM($from.before()) as HTMLElement
  const parentRect = parentElement.getBoundingClientRect()
  const coords = view.coordsAtPos(from)

  return {
    targetElement: parentElement,
    offset: {
      top: coords.top - parentRect.top,
      left: coords.left - parentRect.left,
      right: coords.right - parentRect.right,
      bottom: coords.bottom - parentRect.bottom
    }
  }
}
