import {
  ActiveState,
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita'
import { Task } from './model'

export interface TaskState extends EntityState<Task, ID>, ActiveState {
  loaded: string[]
}

@StoreConfig({ name: 'entity-tasks' })
export class TaskStore extends EntityStore<TaskState> {}

export const taskStore = new TaskStore({ loading: false, loaded: [] })
