import React from "react"
import ReactDOM from "react-dom"
import { EditorState, PluginKey } from "prosemirror-state"
import { EditorView } from "prosemirror-view"
import shortid from "shortid"

import { createPortal } from "./callbacks"

export interface ReactPluginViewComponentProps {
  view: EditorView
  prevState?: EditorState | null,
  pluginKey?: PluginKey | null
}

/**
 * A generic plugin view that mounts the given react component.
 * The react component must define a prop called `view`, which is the prosemirror's EditorView instance.
 */
export class ReactPluginView {
  private root = document.createElement('div')
  private readonly key: string

  constructor(
    view: EditorView,
    private Component: React.FunctionComponent<ReactPluginViewComponentProps>,
    private pluginKey: PluginKey | null = null,
    private props: {[Key: string]: any} = {}
  ) {
    this.key = shortid.generate()
    view.dom.parentNode?.appendChild(this.root)
    this.render(view)
  }

  update(view: EditorView, prevState: EditorState): void {
    this.render(view, prevState)
  }

  private render(view: EditorView, prevState?: EditorState): void {
    createPortal(view.state, ReactDOM.createPortal(
      <this.Component view={view} prevState={prevState} pluginKey={this.pluginKey} {...this.props} />, this.root, this.key
    ))
  }
}
