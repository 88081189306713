import { getActivityLabel } from '../../../entities/choices/activity'
import { ReportStatisticsActivity } from '../../../entities/report-statistics/model'
import {
  safeParseFloat,
  ReadableTimeStringNoZero,
} from '../../../tracking/TimeConverters'

interface ReportStatisticsActivityRow {
  activity?: string
  activity_label?: string | null
  duration: number
  time_spent?: string | null
  percentage?: string | null
  key: string
}

export function ParseReportStatisticsActivityData(report: ReportStatisticsActivity) {
  if (!report.total_time_spent) {
    return []
  }
  const total_time_spent: number = safeParseFloat(report.total_time_spent)
  const rows: ReportStatisticsActivityRow[] = []

  for (const [key, value] of Object.entries(report.stats)) {
    const duration: number = safeParseFloat(value || '')
    const percentage: string =
      duration
        ? `${(duration / total_time_spent * 100).toFixed(1)}%`
        : ''

    if (duration) {
      rows.push({
        activity: getActivityLabel(key),
        time_spent: ReadableTimeStringNoZero(duration, true),
        duration: duration,
        percentage: percentage,
        key: `activity-${key}`,
      })
    }
  }

  rows.sort((a, b) => b.duration - a.duration)
  rows.push({
    activity: 'Total',
    time_spent: ReadableTimeStringNoZero(total_time_spent, true),
    duration: total_time_spent,
    percentage: '100%',
    key: 'total',
  })
  return rows
}
