import './styles/task-activity.scss'

import React, { useContext } from 'react'
import { ID } from '@datorama/akita'

import { User, UserRole } from '../entities/user/model'
import { UsersChoicesContext } from '../auth/usersContext'
import { TargetType, WorklogType } from '../entities/time-entry/model'
import { TaskWorkLog } from './worklogs/AutoWorklog'
import { TaskCurrentTracking } from './worklogs/TaskCurrentTracking'
import { SubtaskTaskWorkLog } from './worklogs/SubtaskWorklog'
import { ManualWorkLog } from './worklogs/ManualAddedWorklog'
import { getLocalTimeWithoutSeconds, humanizeDate } from '../utils/date'
import { timeInSeconds } from '../tracking/TimeConverters'

interface WorkLogProps {
  worklog: WorklogType
  date: string
  currentUser: User
  onDelete: (id: ID) => void
}

export function WorkLog(props: WorkLogProps) {
  const { worklog } = props

  const getUsers = useContext(UsersChoicesContext)!
  const getUser = getUsers.find((x: User) => x.id === worklog.userId)!
  const user = getUser.id === props.currentUser.id ? 'You' : getUser.full_name

  const lastItem = worklog.entries[worklog.entries.length - 1]

  const humanDate = humanizeDate(lastItem.end)

  const dateStart = getLocalTimeWithoutSeconds(worklog.entries[0].start)

  const dateEnd = getLocalTimeWithoutSeconds(lastItem.end)

  const isAdmin = props.currentUser.user_role === UserRole.ADMIN

  let durations: number[] = []

  worklog.entries.forEach((value, index) => {
    const duration = value.duration.split(':')
    durations.push(
      timeInSeconds(
        parseInt(duration[0]),
        parseInt(duration[1]),
        parseInt(duration[2])
      )
    )
  })

  const loggedTimeSum = durations.reduce((acc, log) => acc + log, 0)

  const RENDERERS: { [key in TargetType]?: any } = {
    'task-auto': TaskWorkLog,
    'task-tracking': TaskCurrentTracking,
    'task-manual': ManualWorkLog,
    'sprint-manual': ManualWorkLog,
    'sprint-auto': TaskWorkLog,
    'sprint-tracking': TaskCurrentTracking,
    'epic-manual': ManualWorkLog,
    'epic-tracking': TaskCurrentTracking,
    'epic-auto': TaskWorkLog,
    'project-manual': ManualWorkLog,
    'project-auto': TaskWorkLog,
    'project-tracking': TaskCurrentTracking,
    'subtask-auto': SubtaskTaskWorkLog,
    'subtask-tracking': TaskCurrentTracking,
  }

  const renderAction = (worklog: WorklogType) => {
    if (!worklog) {
      return
    }

    let ActionComponent

    ActionComponent = RENDERERS[worklog.type as TargetType]

    if (ActionComponent) {
      return (
        <ActionComponent
          key={`${worklog.userId}-${worklog.date}-${worklog.type}`}
          user={user}
          date={humanDate}
          worklog={worklog.entries}
          currentUser={props.currentUser}
          onDelete={props.onDelete}
          dateStart={dateStart}
          dateEnd={dateEnd}
          loggedTimeSum={loggedTimeSum}
          isAdmin={isAdmin}
        />
      )
    }
    return
  }

  return (
    <div className="task-activities-section__activity task-activity">
      {renderAction(worklog)}
    </div>
  )
}
