import _ from 'lodash'

interface FieldData {
  name: string
  errors: Array<string>
}

export const convertBackendErrors = (errors: object): FieldData[] => {
  return Object.entries(errors).map((value) => {
    return {
      name: value[0],
      errors: value[1].map((message: string) => _.upperFirst(message)),
    }
  })
}
