import React, { useCallback } from 'react'
import '../../../components/controls/styles/popover.scss'
import { Select, IItemRendererProps } from '@blueprintjs/select'
import { Button } from '@blueprintjs/core'
import classNames from 'classnames'
import { StatusChoice } from '../../../entities/choices/status'
import { MenuItem } from '@blueprintjs/core'

import '../../../assets/scss/select.scss'
import './styles/status-select.scss'
import { TaskStatus } from '../../../entities/task/model'
import { useSelect } from '../../../utils/select'

const TypedStatusSelect = Select.ofType<StatusChoice>()

export interface StatusSelectProps {
  status: TaskStatus
  statuses: StatusChoice[]
  disabled?: boolean | false
  onSelect: (status: TaskStatus) => void
}

export function StatusSelect(props: StatusSelectProps) {
  const { disabled, onSelect } = props

  const [selectRef, showSelect, showSelectHandler] = useSelect<StatusChoice>()

  const onItemSelect = useCallback(
    (status: StatusChoice) => onSelect(status.value),
    [onSelect]
  )

  const renderStatus = useCallback(
    (statusObject: StatusChoice, renderProps: IItemRendererProps) => {
      const { modifiers, handleClick } = renderProps

      if (!modifiers.matchesPredicate) {
        return null
      }

      return (
        <MenuItem
          key={statusObject.value}
          active={statusObject.value === props.status}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={statusObject.label}
        />
      )
    },
    [props.status]
  )

  const status_obj = props.statuses.find((s) => s.value === props.status)

  if (!status_obj) {
    return null
  }

  const buttonClasses = classNames('status-select__button', `status-select__button_${status_obj.value}`, {
    'status-select__button_disabled': disabled
  })

  const selectClasses = classNames('status-select', 'select', {
    'status-select_disabled': disabled,
  })

  if (!showSelect)
    return (
      <div className={selectClasses}>
        <Button onClick={showSelectHandler} className={buttonClasses}>
          {status_obj.label}
        </Button>
      </div>
    )

  return (
    <TypedStatusSelect
      ref={selectRef}
      items={props.statuses}
      disabled={disabled}
      className={selectClasses}
      itemRenderer={renderStatus}
      onItemSelect={onItemSelect}
      filterable={false}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover',
      }}
    >
      <Button className={buttonClasses}>{status_obj.label}</Button>
    </TypedStatusSelect>
  )
}
