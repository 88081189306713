import classNames from 'classnames'
import React from 'react'
import './styles/error-page-message.scss'

interface ErrorPageMessageProps {
  message: string
  className?: string
}

export function ErrorPageMessage(props: ErrorPageMessageProps) {
  const classes = classNames('error-page-message', props.className)
  return <div className={classes}>{props.message}</div>
}
