import '../styles/task-group-details.scss'
import '../styles/sprint-details.scss'

import React, { useCallback, useEffect } from 'react'
import { ID } from '@datorama/akita'
import { useParams } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import { sortBy } from 'lodash'

import { ActionType } from '../../../../entities/activities/model'
import { Sprint } from '../../../../entities/sprint/model'
import { ProjectDetailsActivities } from '../../../panels/project-details/ProjectDetailsActivities'
import { SprintDropdownContainer } from '../../controls/SprintDropdownContainer'
import { TaskGroupDetailsTitle } from '../TaskGroupDetailsTitle'
import { SprintDetailsDatePicker } from './SprintDetailsDatePicker'
import { SprintDetailsStatistics } from './SprintDetailsStatistics'
import { useQuery } from '../../../../utils/queryhook'
import { sprintService } from '../../../../entities/sprint/service'
import { ModelUpdateCallback } from '../../../../utils/types'
import { sprintPanelService } from './state/service'
import { sprintPanelQuery } from './state/query'
import { Task } from '../../../../entities/task/model'
import { TaskGroupsDetailsInfoDate } from '../TaskGroupsDetailsInfoDate'
import { SprintStatisticsItem } from './state/models'
import { ShortUser } from '../../../../entities/user/model'
import { userQuery } from '../../../../entities/user/query'
import { activityLogService } from '../../../../entities/activities/service'
import { User } from '../../../../entities/user/model'
import { authQuery } from '../../../../auth/state/query'
import { CustomScrollbar } from '../../../../components/custom-scrollbar/CustomScrollbar'
import { SmartSuiteLink } from '../../../../components/SmartSuiteLink'
import { timeEntryService } from '../../../../entities/time-entry/service'
import {
  combineHistory,
  HistoryActivityItemType,
  HistoryItem,
} from '../../../../history/state/model'
import { filterLocalStorageService } from '../../../state/filterLocalStorage'
import { formatDate } from '../../../../utils/date'
import { subtaskService } from '../../../../entities/subtask/service'

export function SprintDetailsContainer() {
  const { sprintId } = useParams()

  useEffect(() => {
    sprintPanelService.setActive(sprintId!)
    activityLogService.loadActivities({ sprints__id: sprintId! })
    timeEntryService.loadTimeEntries({ sprint__id: sprintId! })
    filterLocalStorageService.subscribeOnSprintPanelFilters()
    sprintPanelService.getPerformancePerDay({ sprint_id: sprintId! })
    subtaskService.loadSubtasks({ sprint_id: sprintId! })
  }, [sprintId])

  const sprint = useQuery(sprintPanelQuery.sprintWithUser$)
  const sprintTasks = useQuery(sprintPanelQuery.sprintTasks$)
  const users = useQuery(userQuery.users$)
  const { statistic, summary } = useQuery(sprintPanelQuery.sprintStatistics$)
  const sprintActivities = useQuery(sprintPanelQuery.sprintActivities$)
  const currentUser = useQuery(authQuery.currentUser$)
  const sprintWorklogs = useQuery(sprintPanelQuery.sprintWorklog$)
  const selectedActivityFilter = useQuery(sprintPanelQuery.activityFilter$)

  const history = sortBy(
    combineHistory(sprintActivities as any, sprintWorklogs),
    'date'
  ).reverse()

  if (!sprint) {
    return <></>
  }

  return (
    <SprintDetails
      sprint={sprint}
      history={history}
      sprintTasks={sprintTasks}
      currentUser={currentUser}
      users={users}
      statistic={statistic}
      statisticSummary={summary}
      activities={sprintActivities}
      onUpdateSprint={sprintService.updateSprint}
      onDestroySprint={sprintService.destroy}
      onDownloadSprint={sprintService.downloadSprint}
      onStatusSelect={() => {}}
      onWorkLogDelete={timeEntryService.destroy}
      onFilterActivities={sprintPanelService.updateActivityFilter}
      selectedActivityFilter={selectedActivityFilter}
    />
  )
}

interface SprintDetailsProps {
  sprint: Sprint
  sprintTasks: Task[]
  users: ShortUser[]
  currentUser: User
  statistic: SprintStatisticsItem[]
  statisticSummary: SprintStatisticsItem
  activities: ActionType[]
  history: HistoryItem[]
  onUpdateSprint: ModelUpdateCallback<Sprint>
  onStatusSelect: (status: string) => void
  onDestroySprint: (id: ID) => void
  onWorkLogDelete: (id: ID) => void
  onDownloadSprint: (id: ID) => void
  onFilterActivities: (value: HistoryActivityItemType) => void
  selectedActivityFilter?: HistoryActivityItemType[]
}

function _SprintDetails(props: SprintDetailsProps) {
  const {
    sprint,
    sprintTasks,
    onUpdateSprint,
    onDestroySprint,
    onDownloadSprint,
  } = props

  const createdAt = formatDate(sprint.created_at).toString()
  const updatedAt = formatDate(sprint.updated_at).toString()

  const updateSprint = useCallback(
    (update: Partial<Sprint>) => {
      onUpdateSprint({ id: sprint.id, ...update })
    },
    [onUpdateSprint, sprint.id]
  )

  const onStatusSelect = useCallback(
    (status) => {
      updateSprint({ status: status })
    },
    [updateSprint]
  )

  return (
    <div className="task-group-details sprint-details">
      <div className="task-group-details__header">
        <div className="task-group-details__header-left">
          <div className="task-group-details__group-top">
            <div className="task-group-details__group-type">Sprint</div>
            <div className="task-group-details__status">
              <SprintDropdownContainer
                targetClassName="task-group-details__menu"
                sprintID={sprint.id}
                sprintStatus={sprint.status}
                sprintsLength={sprintTasks.length}
                isReadOnlySprint={sprint.is_readonly}
                onDelete={onDestroySprint}
                onDownload={onDownloadSprint}
                onStatusSelect={onStatusSelect}
                showCurrent={true}
                onSort={() => {}}
                onSortTasksByAssignee={() => {}}
              />
            </div>
          </div>
        </div>

        <div className="task-group-details__header-right">
          <div className="task-groups-details-info">
            <div className="task-groups-details-info__item">
              <TaskGroupsDetailsInfoDate
                date={createdAt}
                user={sprint.created_by_user}
                title="Created by"
              />
            </div>
            <div className="task-groups-details-info__item">
              <TaskGroupsDetailsInfoDate
                date={updatedAt}
                user={sprint.updated_by_user}
                title="Updated by"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="task-group-details__title-wrap">
        <TaskGroupDetailsTitle
          key={`sprint-name-${sprint.id}`}
          required={true}
          className="task-group-details__title"
          placeholder="Sprint title"
          onChange={(value) => updateSprint({ name: value })}
          disabled={sprint.is_readonly}
        >
          {sprint.name}
        </TaskGroupDetailsTitle>
      </div>
      <div className="sprint-details__dates">
        <SprintDetailsDatePicker
          key={`sprint-start-${sprint.id}`}
          className="sprint-details__dates-item"
          title="Started"
          date={sprint.start_date}
          disabled={sprint.is_readonly}
          onChange={(value) => updateSprint({ start_date: value })}
        />
        <SprintDetailsDatePicker
          key={`sprint-staging-${sprint.id}`}
          className="sprint-details__dates-item"
          title="Staging"
          date={sprint.staging_date}
          disabled={sprint.is_readonly}
          onChange={(value) => updateSprint({ staging_date: value })}
        />
        <SprintDetailsDatePicker
          key={`sprint-release-${sprint.id}`}
          className="sprint-details__dates-item"
          title="Release"
          date={sprint.release_date}
          disabled={sprint.is_readonly}
          onChange={(value) => updateSprint({ release_date: value })}
        />
      </div>
      <div className="task-group-details__content">
        <CustomScrollbar>
          <SprintDetailsStatistics
            className="task-group-details__section"
            users={props.users}
            statistic={props.statistic}
            statisticSummary={props.statisticSummary}
          />
          {sprint.ss_link && <SmartSuiteLink ss_link={sprint.ss_link} />}
          <ProjectDetailsActivities
            history={props.history}
            activities={props.activities}
            className="task-group-details__section"
            currentUser={props.currentUser}
            onWorkLogDelete={props.onWorkLogDelete}
            onFilterActivities={props.onFilterActivities}
            selectedActivityFilter={props.selectedActivityFilter}
          />
        </CustomScrollbar>
      </div>
    </div>
  )
}

export const SprintDetails = React.memo(_SprintDetails, isEqual)
