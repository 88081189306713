import OrderedMap from 'orderedmap'
import { NodeSpec, Schema } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { Suggester } from 'prosemirror-suggest'

import { Extension } from '../..'
import { createReactNodeView } from '../../core/react-node-view'
import { NodeViewsSpec } from '../../core/types'

import { TaskTypeNodeSpec } from './nodes'
import { taskTypeSuggester } from './suggesters'
import { TaskTypePlugin, TaskTypePluginKey } from './plugins'
import { BaseTaskTypeProvider } from './provider'
import { TaskTypeEditorDialog, TaskType } from './components'

export class TaskTypeExtension extends Extension {
  taskType: TaskTypePluginKey

  constructor(private provider: BaseTaskTypeProvider) {
    super()
    this.taskType = new TaskTypePluginKey('#')
  }

  addNodes = (nodes: OrderedMap<NodeSpec>): OrderedMap<NodeSpec> => {
    return nodes.append({ tasktype: TaskTypeNodeSpec })
  }

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    TaskTypePlugin(this.taskType, TaskTypeEditorDialog),
  ]

  addNodeViews = (schema: Schema, nodeViews: NodeViewsSpec): NodeViewsSpec => ({
    ...nodeViews,
    tasktype: (node, view, getPos, decorations) => {
      return createReactNodeView({
        node,
        view,
        getPos,
        decorations,
        component: TaskType,
      })
    },
  })

  addProviders = (providers: { [key: string]: any }) => ({
    ...providers,
    taskTypeProvider: this.provider,
  })

  addSuggesters = (suggesters: Suggester[]): Suggester[] => [
    ...suggesters,
    taskTypeSuggester(this.taskType),
  ]
}
