import {
  Classes,
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import './styles/reorder-menu.scss'
import { ID } from '@datorama/akita'
import { MoveItemCallback } from '../../drag-n-drop'
import { CustomScrollbar } from '../../../components/custom-scrollbar/CustomScrollbar'

const menuItemClasses = classNames(
  Classes.MENU_ITEM,
  Classes.TEXT_OVERFLOW_ELLIPSIS,
  Classes.FILL,
  'reorder-menu-item'
)

interface ReorderMenuItemProps {
  index: number
  label: string
  isActive: boolean
  onClick: (index: number) => void
  showPrevReorderElement: boolean
  showNextReorderElement: boolean
}

const RenderMenuItem: React.FunctionComponent<ReorderMenuItemProps> = (
  props
) => {
  const classes = classNames('reorder-menu-content__item', {
    'is-active': props.isActive,
  })

  return (
    <>
      {props.showPrevReorderElement && (
        <span
          onClick={() => props.onClick(props.index)}
          className="reorder-menu-content__trigger reorder-menu-content__trigger_top"
        />
      )}
      <div className={classes}>
        <span className="reorder-menu-content__item-label">{props.label}</span>
      </div>
      {props.showNextReorderElement && (
        <span
          onClick={() => props.onClick(props.index)}
          className="reorder-menu-content__trigger reorder-menu-content__trigger_bottom"
        />
      )}
    </>
  )
}

export type ReorderMenuItemType = {
  id: ID
  name: string
}

interface ReorderMenuProps {
  items: ReorderMenuItemType[]
  objectId: ID
  onReorder?: MoveItemCallback
}

export const ReorderMenu = (props: ReorderMenuProps) => {
  const { items, objectId, onReorder } = props

  const reorderHandler = useCallback(
    (index) => {
      !!onReorder && onReorder(objectId, index)
    },
    [onReorder, objectId]
  )

  const content = (
    <div className="reorder-menu-content">
      <CustomScrollbar translateContentSizeYToHolder={true}>
        {items.map((item, index) => {
          const isActive = item.id === objectId
          const isLast = index === items.length - 1
          const prevObjectId = index !== 0 ? items[index - 1].id : null
          const showPrevReorderElement = !isActive && prevObjectId !== objectId
          const showNextReorderElement = isLast && !isActive

          return (
            <RenderMenuItem
              key={index}
              index={index}
              label={item.name}
              isActive={isActive}
              showPrevReorderElement={showPrevReorderElement}
              showNextReorderElement={showNextReorderElement}
              onClick={(index) => reorderHandler(index)}
            />
          )
        })}
      </CustomScrollbar>
    </div>
  )

  return (
    <Popover
      content={content}
      position={Position.RIGHT_TOP}
      popoverClassName="popover reorder-menu"
      minimal={true}
      targetTagName="div"
      wrapperTagName="div"
      interactionKind={PopoverInteractionKind.CLICK}
      boundary="viewport"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0 1',
        },
      }}
    >
      <div className={menuItemClasses}>
        <span className="reorder-menu-item__text">Reorder</span>{' '}
        <Icon color="#595959" icon="chevron-right" iconSize={12} />
      </div>
    </Popover>
  )
}
