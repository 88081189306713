import {IItemRendererProps, Select} from '@blueprintjs/select'
import React, {RefObject, useEffect, useRef, useState} from 'react'
import { IconName, MenuItem } from '@blueprintjs/core'

export function selectChoicePredicate<T, K extends keyof T>(key: K) {

  return (query: string, choice: T) => {
    const stringChoice = choice[key] as any

    return (
      (stringChoice.toString())
        .toLowerCase()
        .indexOf(query.toLowerCase()) >= 0
    )
  }
}


export function selectItemRenderer<T extends { label?: string; name?: string; id?: string | number; value?: string; icon?: IconName }
>(item: T, { modifiers, handleClick }: IItemRendererProps) {
  if (!modifiers.matchesPredicate) {
    return null
  }

  return (
    <MenuItem
      key={item.id || item.name || item.value}
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      icon={item.icon || undefined}
      text={item.label || item.name}
    />
  )
}

export function useSelect<T>(): [RefObject<Select<T>>, boolean, () => void] {
  const ref = useRef<Select<T>>(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) ref.current?.setState({ isOpen: true })
  }, [show])

  const showSelectHandler = () => setShow(true)

  return [ref, show, showSelectHandler]
}
