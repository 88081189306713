import { EntityState, EntityStore, StoreConfig } from '@datorama/akita'

export interface HistoryState extends EntityState {
  loaded: boolean
}

@StoreConfig({ name: 'entity-subtasks' })
export class HistoryStore extends EntityStore<HistoryState> {}

export const historyStore = new HistoryStore()
