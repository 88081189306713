import React, { useState } from 'react'
import { Project } from '../../entities/project/model'
import { combineProjectPageFilterItems, SearchItem } from '../search'
import { Sprint } from '../../entities/sprint/model'
import { Epic } from '../../entities/epic/model'
import { Task } from '../../entities/task/model'
import { ShortUser, User } from '../../entities/user/model'
import { PageSearchTarget } from '../../components/controls/page-search/PageSearchTarget'
import { PageSearch } from '../../components/controls/page-search/PageSearch'

interface ProjectPageSearchContainerProps {
  targetClassName?: string
  project: Project
  sprints: Sprint[]
  epics: Epic[]
  tasks: Task[]
  filteredSprints: Sprint[]
  filteredEpics: Epic[]
  filteredTasks: Task[]
  users: User[] | ShortUser[]
  onApplyFilter: () => void
  applyFilter: boolean
}

export function ProjectPageSearchContainer({
  targetClassName,
  project,
  sprints,
  epics,
  tasks,
  users,
  onApplyFilter,
  applyFilter,
  filteredSprints,
  filteredEpics,
  filteredTasks,
}: ProjectPageSearchContainerProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [filterItems, setFilterItems] = useState<SearchItem[]>([])
  const [items, setItems] = useState<SearchItem[]>([])

  const handleItemSelect = () => {
    setQuery('')
    setIsOpen(false)
  }

  const handleOpen = () => {
    setQuery('')
    setIsOpen(true)
    setFilterItems(
      combineProjectPageFilterItems(
        project,
        filteredSprints,
        filteredEpics,
        filteredTasks,
        users
      )
    )
    setItems(
      combineProjectPageFilterItems(project, sprints, epics, tasks, users)
    )
  }

  return (
    <>
      <PageSearchTarget className={targetClassName} onClick={handleOpen} />
      <PageSearch
        onClose={() => {
          setIsOpen(false)
        }}
        isOpen={isOpen}
        query={query}
        onQueryChange={setQuery}
        items={applyFilter ? filterItems : items}
        onSelectItem={handleItemSelect}
        onApplyFilter={onApplyFilter}
        applyFilter={applyFilter}
      />
    </>
  )
}
