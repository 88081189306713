import React, { useState } from 'react'
import { Collapse } from '@blueprintjs/core'

import { ActivitiesDate } from './ActivitiesDate'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'

import '../styles/task-activity.scss'
import { ChangeEpicAction } from '../../entities/activities/model'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import {User} from "../../entities/user/model";

interface ChangeEpicActivityProps {
  action: ChangeEpicAction
  date: string
  user: User | string
}

export function ChangeEpicActivity(props: ChangeEpicActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>
        {user}
      </ActivityBoldText>{' '}
      moved this task to the{' '}
      <ActivityBoldText>{action.data.changed_to.label ? action.data.changed_to.label: "Backlog"}</ActivityBoldText> epic.{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <ActivityBoldText>
            {action.data.changed_from.label ? action.data.changed_from.label: "Backlog"}
          </ActivityBoldText>
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
