import React from 'react'
import { AddFollowerAction } from '../../entities/activities/model'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {User} from "../../entities/user/model";

interface AddFollowerActivityProps {
  action: AddFollowerAction
  date: string
  user: User | string
}

export function AddFollowerActivity(props: AddFollowerActivityProps) {
  const { action, user } = props

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> added{' '}
      <ActivityBoldText>{action.data.changed_to.label}</ActivityBoldText>{' '} as a follower to this task.
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
