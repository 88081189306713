import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { sortBy, isEqual } from 'lodash'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Icon, Tooltip } from '@blueprintjs/core'
import { ID } from '@datorama/akita'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { isCurrentSprint, Sprint } from '../../../entities/sprint/model'
import { Task } from '../../../entities/task/model'
import { ModelCreateCallback, ModelUpdateCallback } from '../../../utils/types'
import { TaskList } from '../../tasks/TaskList'
import { AddTaskForm } from '../AddTaskForm'
import { Epic } from '../../../entities/epic/model'
import {
  MoveItemCallback,
  sprintDroppableId,
  TASKS_IN_OUT_DROP_TYPE,
} from '../../drag-n-drop'
import { TaskGroupItemProps } from '../TaskGroupProps'
import { SprintDropdownContainer } from '../controls/SprintDropdownContainer'
import { TrackerControl } from '../../../tracking/TrackerControl'
import { Activity, SPRINT_ACTIVITIES } from '../../../entities/choices/activity'
import { AddTimeManuallyObject } from '../../../tracking/types'
import { User } from '../../../entities/user/model'
import { SORT_STEP } from '../../../utils/lists'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'
import { formatDate } from '../../../utils/date'

export interface SprintListItemProps extends TaskGroupItemProps {
  baseUrl?: string
  sprint: Sprint
  currentSprint?: Sprint | null
  sprints: Sprint[]
  epics: Epic[]
  taskTypes: TaskType[]
  notFilteredTasks: Task[]
  dragProvided?: DraggableProvided
  dragObject?: Task
  onDestroySprint: (id: ID) => void
  onDownloadSprint: (id: ID) => void
  onUpdateSprint: ModelUpdateCallback<Sprint>
  onReorderSprint: MoveItemCallback
  onSortTasks: (id: ID) => void
  onSortTasksByAssignee: (id: ID) => void
  style?: CSSProperties
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
  tasksLoading?: boolean
}

function _SprintListItem(props: SprintListItemProps) {
  const tasks = sortBy(props.tasks, 'sprint_order')

  const {
    sprint,
    currentSprint,
    onToggle,
    onUpdateSprint,
    onStartTracking,
    onAddTimeManually,
    onDownloadSprint,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props

  const url = `${props.baseUrl}/sprint/${sprint.id}`

  const sprintRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (currentSprint?.id === sprint.id && !!sprintRef?.current) {
      sprintRef.current?.scrollIntoView()
    }
  }, [sprint, currentSprint])

  const _onToggle = useCallback(() => {
    onToggle(sprint.id)
  }, [onToggle, sprint.id])

  const updateSprint = useCallback(
    (update: Partial<Sprint>) => {
      onUpdateSprint({ id: sprint.id, ...update })
    },
    [onUpdateSprint, sprint.id]
  )

  const onStatusSelect = useCallback(
    (status) => {
      updateSprint({ status: status })
    },
    [updateSprint]
  )

  const startTracking = useCallback(
    (activity: Activity | null) => {
      onStartTracking && onStartTracking('sprint', +sprint.id, activity)
    },
    [onStartTracking, sprint.id]
  )

  const addSprintTimeManually = useCallback(
    (data: AddTimeManuallyObject) => {
      onAddTimeManually('sprint', +sprint.id, data)
    },
    [onAddTimeManually, sprint.id]
  )

  let body: React.ReactNode

  if (props.collapsed) {
    body = <></>
  } else {
    body = (
      <div
        className={classNames('task-groups-list__group-body', {
          'task-groups-list__group-body_empty': props.tasksLoading,
        })}
      >
        <DroppableSprintPlaceholder sprint={props.sprint}>
          {!!props.tasks.length && (
            <TaskList
              tasks={tasks}
              onStartTracking={props.onStartTracking}
              onStopTracking={props.onStopTracking}
              onAddTimeManually={props.onAddTimeManually}
              currentUser={props.currentUser}
              onTaskUpdate={props.onTaskUpdate}
              location={'sprint'}
              currentTask={props.currentTask}
              sprint={props.sprint}
              users={props.users}
              epics={props.epics}
              taskTypes={props.taskTypes}
              addTimeFormErrors={addTimeFormErrors}
              isAddTimeFormOpened={isAddTimeFormOpened}
              onOpenAddTimeModal={onOpenAddTimeModal}
              onCloseAddTimeModal={onCloseAddTimeModal}
            />
          )}
        </DroppableSprintPlaceholder>
      </div>
    )
  }

  const groupClasses = classNames('task-groups-list__group', {
    'is-collapsed': props.collapsed,
    'has-dates':
      props.sprint.start_date ||
      props.sprint.staging_date ||
      props.sprint.release_date,
  })

  return (
    <div style={{ ...props.style, paddingTop: 8 }} ref={sprintRef}>
      <div className={groupClasses}>
        <SprintHeader
          url={url}
          sprint={props.sprint}
          collapsed={props.collapsed}
          orderedTasks={tasks}
          tasksLength={props.notFilteredTasks.length}
          dragProvided={props.dragProvided}
          onToggle={_onToggle}
          onTaskCreate={props.onTaskCreate}
          onDestroySprint={props.onDestroySprint}
          onDownloadSprint={onDownloadSprint}
          onStatusSelect={onStatusSelect}
          onStartTracking={startTracking}
          onStopTracking={props.onStopTracking}
          onAddTimeManually={addSprintTimeManually}
          currentUser={props.currentUser}
          onReorderSprint={props.onReorderSprint}
          onSortTasks={props.onSortTasks}
          onSortTasksByAssignee={props.onSortTasksByAssignee}
          sprints={props.sprints}
          addTimeFormErrors={addTimeFormErrors}
          isAddTimeFormOpened={isAddTimeFormOpened}
          onOpenAddTimeModal={onOpenAddTimeModal}
          onCloseAddTimeModal={onCloseAddTimeModal}
        />
        {body}
      </div>
    </div>
  )
}

function DroppableSprintPlaceholder({
  sprint,
  children,
}: {
  sprint: Sprint
  children: React.ReactNode
}) {
  return (
    <Droppable
      droppableId={sprintDroppableId(sprint)}
      type={TASKS_IN_OUT_DROP_TYPE}
    >
      {(
        dropProvided: DroppableProvided,
        _dropSnapshot: DroppableStateSnapshot
      ) => (
        <div
          style={{ minHeight: '1px' }}
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          {children}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export interface SprintHeaderProps {
  url: string
  sprint: Sprint
  sprints: Sprint[]
  collapsed: boolean
  dragProvided?: DraggableProvided
  tasksLength: number
  orderedTasks: Task[]
  onToggle: () => void
  onTaskCreate: ModelCreateCallback<Task>
  onDestroySprint: (id: ID) => void
  onDownloadSprint: (id: ID) => void
  onStatusSelect: (status: string) => void
  onStartTracking: (activity: string) => void
  onStopTracking: () => void
  onAddTimeManually: (data: AddTimeManuallyObject) => void
  currentUser?: User
  onReorderSprint: MoveItemCallback
  onSortTasks: (id: ID) => void
  onSortTasksByAssignee: (id: ID) => void
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function SprintHeader(props: SprintHeaderProps) {
  const { onTaskCreate, orderedTasks } = props

  const [hover, setHover] = useState(false)

  const createTask = (task: Partial<Task>) => {
    const lastItemOrder = orderedTasks.length
      ? orderedTasks.slice(-1)[0].sprint_order + SORT_STEP
      : 0
    onTaskCreate({
      ...task,
      sprint: props.sprint.id,
      sprint_order: lastItemOrder,
    })
  }

  const onMouseEnter = useCallback(() => setHover(true), [])
  const onMouseLeave = useCallback(() => setHover(false), [])

  const sprintDates = [
    {
      label: 'start',
      date: props.sprint.start_date,
    },
    {
      label: 'staging',
      date: props.sprint.staging_date,
    },
    {
      label: 'release',
      date: props.sprint.release_date,
    },
  ].filter((item) => !!item.date)

  const isCurrent = isCurrentSprint(props.sprint)

  return (
    <div
      className="task-groups-list__group-header"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {props.dragProvided && (
        <div
          className="task-groups-list__drag-wrap"
          {...props.dragProvided.dragHandleProps}
        >
          <Icon icon="drag-handle-vertical" />
        </div>
      )}
      <div className="task-groups-list__header-top">
        <div className="task-groups-list__header-top-left">
          {props.collapsed ? (
            <DownOutlined
              onClick={props.onToggle}
              className="task-groups-list__group-header-toggler"
            />
          ) : (
            <UpOutlined
              onClick={props.onToggle}
              className="task-groups-list__group-header-toggler"
            />
          )}
          <div className="task-groups-list__name">
            <Tooltip
              content={props.sprint.name}
              targetTagName="div"
              wrapperTagName="div"
              boundary="viewport"
              popoverClassName="task-groups-list__name-tooltip"
            >
              <Link style={{ display: 'block' }} to={props.url}>
                <h3>
                  {props.sprint.name}
                  {isCurrent ? (
                    <span className="task-groups-list__name-mark">
                      (current)
                    </span>
                  ) : (
                    ''
                  )}
                </h3>
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className="task-groups-list__header-sprint-right">
          <span className="tracker-control">
            <TrackerControl
              trackingObject={props.sprint}
              trackingObjectType="sprint"
              activities={SPRINT_ACTIVITIES}
              showControlOnly={true}
              hideIfPossible={!hover}
              onStart={props.onStartTracking}
              onStop={props.onStopTracking}
              onAddTimeManually={props.onAddTimeManually}
              currentUser={props.currentUser}
              addTimeFormErrors={props.addTimeFormErrors}
              isAddTimeFormOpened={props.isAddTimeFormOpened}
              onOpenAddTimeModal={props.onOpenAddTimeModal}
              onCloseAddTimeModal={props.onCloseAddTimeModal}
            />
          </span>

          <div className="task-groups-list__action-buttons">
            <SprintDropdownContainer
              targetClassName="task-group-details__menu"
              hasReorderMenu={true}
              key={props.sprint.id}
              sprints={props.sprints}
              sprintID={props.sprint.id}
              sprintsLength={props.tasksLength}
              sprintStatus={props.sprint.status}
              isReadOnlySprint={props.sprint.is_readonly}
              onDelete={props.onDestroySprint}
              onDownload={props.onDownloadSprint}
              onStatusSelect={props.onStatusSelect}
              showCurrent={true}
              onReorder={props.onReorderSprint}
              onSort={props.onSortTasks}
              onSortTasksByAssignee={props.onSortTasksByAssignee}
            />
          </div>
        </div>
      </div>
      {!!sprintDates.length && (
        <span className="task-groups-list__dates">
          (
          {sprintDates.map((item, index) => {
            const comma = index + 1 !== sprintDates.length ? ', ' : null
            return (
              <React.Fragment key={item.label}>
                {item.label}: {formatDate(item.date!, 'DD.MM').toString()}
                {comma}
              </React.Fragment>
            )
          })}
          )
        </span>
      )}
      {!props.collapsed && <AddTaskForm onTaskAdd={createTask} />}
    </div>
  )
}
// using here lodash and not react-fast-compare because of error causing by
// adding external link in the description of a task
// https://timer.gearheart.io/1/95/task/TM3-34697
export const SprintListItem = React.memo(_SprintListItem, isEqual)
