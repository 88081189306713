import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { Project } from './model'

export interface ProjectState extends EntityState<Project, ID> {
  loaded: boolean
}

@StoreConfig({ name: 'entity-projects' })
export class ProjectStore extends EntityStore<ProjectState> {}

export const projectStore = new ProjectStore()
