import { boundMethod } from 'autobind-decorator'
import { ID } from '@datorama/akita'

import { CalendarPageStore, calendarPageStore } from './store'
import { Project } from '../../entities/project/model'
import { authStore, AuthStore } from '../../auth/state/store'

export class CalendarPageService {
  constructor(private store: CalendarPageStore, private authStore: AuthStore) {}

  @boundMethod
  setUsers(usersIDs: ID[]) {
    this.store.update({ selectedUsersIDs: usersIDs })
  }

  @boundMethod
  setShowFiltersPanel(show: boolean) {
    this.store.update({ showFiltersPanel: show })
  }

  @boundMethod
  clearFilters() {
    const { currentUserId } = this.authStore.getValue()
    this.store.update({
      selectedProject: null,
      selectedUsersIDs: !!currentUserId ? [currentUserId] : [],
    })
  }

  @boundMethod
  setProject(project: Project | null) {
    this.store.update({ selectedProject: project })
  }
}

export const calendarPageService = new CalendarPageService(
  calendarPageStore,
  authStore
)
