import React, { useCallback, useContext, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { Divider } from 'antd'
import {
  CalendarOutlined,
  HourglassOutlined,
  FlagOutlined,
} from '@ant-design/icons'

import { useQuery } from '../utils/queryhook'
import { trackingQuery } from './state/query'
import { trackingService } from './state/service'
import { projectPageService } from '../project-page/state/service'
import { projectPageQuery } from '../project-page/state/query'
import { taskService } from '../entities/task/service'
import { taskQuery } from '../entities/task/query'
import { EmptyTrackedWithUserObject, TrackingObject } from './types'
import { TrackerControl } from './TrackerControl'
import { workspaceQuery } from '../entities/workspace/query'
import { Workspace } from '../entities/workspace/model'
import { ReadableTime } from './TimeConverters'
import { TrackerCommentControl } from './TrackerCommentControl'
import { LastActivityDropdown } from './LastActivityDropdown'
import { generateTaskDetailUrl } from '../routes'
import { TrackingObjectContext } from './trackingContext'
import './styles/tracker-status-container.scss'
import { SubtaskChangeEstimationTrackerControl } from '../project-page/tasks/TaskPanel/SubtaskChangeEstimationWithComment'
import { subtaskService } from '../entities/subtask/service'
import { ACTIVITIES } from '../entities/choices/activity'
import { SUBTASK_TYPES } from '../entities/choices/subtaskType'

interface TrackerStatusContainerProps {}

export function TrackerStatusContainer(props: TrackerStatusContainerProps) {
  const trackingState = useQuery(trackingQuery.state$)
  const targetObject = useContext(TrackingObjectContext)
  const myLastActivitiesList = useQuery(projectPageQuery.myLastActivities$)
  const currentTeamActivities = useQuery(
    projectPageQuery.currentTeamActivities$
  )
  const addTimeFormErrors = useQuery(trackingQuery.fromErrors$)
  const isAddTimeFormOpened = useQuery(trackingQuery.isFormOpened$)

  const targetId = trackingState.target?.id
  const targetType = trackingState.target?.type

  useEffect(() => {
    trackingService.loadTarget()
  }, [targetId, targetType])

  const workspace = useQuery(workspaceQuery.active$)

  const onComment = useCallback(
    (comment: string) =>
      trackingService.addComment(trackingState.entry!, comment),
    [trackingState.entry]
  )

  const onShowActivities = useCallback((flag) => {
    if (!flag) {
      return
    }
    projectPageService.loadMyLastActivities()
    projectPageService.loadCurrentTeamActivities()
  }, [])

  const { workspaceId, projectId } = useParams()
  const { pathname } = useLocation()
  const subtaskTask =
    targetObject?.type === 'subtask'
      ? taskQuery.getEntity(targetObject?.object.task)
      : null
  const subtaskTaskUrl = subtaskTask
    ? generateTaskDetailUrl(workspaceId!, projectId!, subtaskTask.code)
    : null

  const needToShowCommentControl = pathname !== subtaskTaskUrl

  const activityLabel =
    targetObject?.type === 'subtask'
      ? SUBTASK_TYPES.find((type) => targetObject.object.type === type.value)
          ?.label
      : ACTIVITIES.find((activity) => activity.value === trackingState.activity)
          ?.label

  return (
    <div className="tracker-status-container">
      {!!targetObject ? (
        <>
          {(targetObject.type !== 'project' ||
            targetObject.object.id !== Number(projectId)) && (
            <div className="tracker-status-container__col">
              <TrackerControl
                activities={[]}
                trackingObject={targetObject.object}
                trackingObjectType={targetObject.type}
                onStop={trackingService.stopTracking}
                seconds={targetObject.object.time_logged}
                onStart={() => null}
                showControlOnly={targetObject.type !== 'subtask'}
                addTimeFormErrors={addTimeFormErrors}
                isAddTimeFormOpened={isAddTimeFormOpened}
                onOpenAddTimeModal={trackingService.openAddTimeForm}
                onCloseAddTimeModal={trackingService.closeAddTimeForm}
              />
            </div>
          )}
          <div className="tracker-status-container__col">
            <FlagOutlined />
          </div>
          <div className="tracker-status-container__col tracker-status-container__col_task-name">
            <span className="header-link">
              <TargetLink
                activity={activityLabel}
                target={targetObject}
                workspace={workspace}
              />
            </span>
          </div>

          {needToShowCommentControl && (
            <div className="tracker-status-container__col">
              {targetObject.type === 'subtask' ? (
                <SubtaskChangeEstimationTrackerControl
                  subtask={targetObject.object}
                  required={trackingState.update_required}
                  changeSubtaskEstimate={(estimate) =>
                    subtaskService.addSubtaskEstimate(
                      targetObject.object.id,
                      estimate
                    )
                  }
                  showIcon={true}
                />
              ) : (
                <TrackerCommentControl
                  required={trackingState.update_required}
                  initial={trackingState.comment}
                  onComment={onComment}
                />
              )}
            </div>
          )}
          <Divider type="vertical" />
        </>
      ) : null}
      <div className="tracker-status-container__col">
        <LastActivityDropdown
          myLastActivities={myLastActivitiesList}
          currentTeamActivities={currentTeamActivities}
          workspace={workspace}
          onShowActivities={onShowActivities}
        />
      </div>
      <div className="tracker-status-container__col">
        <HourglassOutlined />
      </div>
      <div className="tracker-status-container__col">
        <ReadableTime seconds={trackingState.seconds_today} />
      </div>

      <div className="tracker-status-container__col">
        <CalendarOutlined />
      </div>
      <div className="tracker-status-container__col">
        <ReadableTime seconds={trackingState.seconds_month} />
      </div>
    </div>
  )
}

export function TargetLink({
  target,
  workspace,
  activity,
}: {
  target: TrackingObject | EmptyTrackedWithUserObject
  workspace: Workspace
  activity: string | undefined | null
}) {
  if (!target) {
    return null
  }

  let targetUrl = `/${workspace.id}/${target.object.id}`
  let prefix = ''
  let name = target.object.name

  if (target.type === 'task') {
    targetUrl = generateTaskDetailUrl(
      workspace.id,
      target.object.project,
      target.object.code
    )
    prefix = `${target.object.code}: `
  }

  // TODO: What we can do here more esthetic?
  if (target.type === 'subtask') {
    if (!taskQuery.hasEntity(target.object.task)) {
      taskService.loadTask(target.object.task)
    }

    taskQuery.selectEntity(target.object.task).subscribe((task) => {
      if (task) {
        targetUrl = generateTaskDetailUrl(workspace.id, task.project, task.code)
        prefix = `${task.code}: `
        name = task.name
      }
    })
  }

  name = target.object.name

  let _activity = activity

  if (activity === 'General') {
    _activity = 'development'
  }

  if (activity === 'Bug') {
    _activity = 'bug fixing'
  }

  return (
    <Link to={targetUrl} className="tracker-status-container__task">
      <span className="tracker-status-container__task-name">
        {target.type === 'task' ? prefix : null}
        {name}
      </span>
      <span className="tracker-status-container__task-activity">
        ({_activity})
      </span>
    </Link>
  )
}
