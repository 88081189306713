import { Query } from '@datorama/akita'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { userQuery } from '../../../entities/user/query'
import { sprintQuery } from '../../../entities/sprint/query'
import { ReportStatisticsPageState, reportStatisticsPageStore } from './store'

export class ReportStatisticsQuery extends Query<ReportStatisticsPageState> {
  selectedReportType$ = this.select('reportType')

  selectedProject$ = this.select('selectedProject')
  selectedUserID$ = this.select('selectedUserID')
  selectedSprints$ = this.select('selectedSprints')

  startDate$ = this.select('startDate')
  endDate$ = this.select('endDate')
  report$ = this.select('report')
  loading$ = this.selectLoading()

  users$ = this.selectedProject$.pipe(
    switchMap((project) => {
      const projectId = project?.id || null
      if (projectId) {
        return userQuery.forProject(projectId)
      }
      return userQuery.selectAll()
    })
  )

  sprints$ = this.selectedProject$.pipe(
    switchMap((project) => {
      const projectId = project?.id || null
      if (projectId) {
        return sprintQuery.forProject(projectId)
      }
      return of([])
    })
  )

  showFiltersPanel$ = this.select('showFiltersPanel')
}

export const reportStatisticsQuery = new ReportStatisticsQuery(reportStatisticsPageStore)
