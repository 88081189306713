import React from 'react'
import { Project } from '../entities/project/model'
import { DashboardProjectCard } from './DashboardProjectCard'

interface DashboardProjectCardsProps {
  projects: Project[]
}

export function DashboardProjectCards(props: DashboardProjectCardsProps) {
  return (
    <div className="dashboard-page__project-cards">
      {props.projects.map((project: Project) => {
        return <DashboardProjectCard key={project.id} project={project} />
      })}
    </div>
  )
}
