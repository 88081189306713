import React from 'react'
import { Select } from 'antd'
import _ from 'lodash'
import { UserRole, UserRoles } from '../entities/user/model'

const { Option } = Select

interface UserProfileRoleSelectProps {
  role: UserRole
  editable: boolean
  onChange: (role: UserRole) => void
}

export function UserProfileRoleSelect(props: UserProfileRoleSelectProps) {
  return (
    <div className="user-profile__heading-item user-profile__heading-item_small user-profile__heading-item_role">
      {props.editable ? (
        <Select
          value={props.role}
          onChange={props.onChange}
          dropdownMatchSelectWidth={false}
        >
          {UserRoles.map((userRole: UserRole) => {
            return (
              <Option key={userRole} value={userRole}>
                {_.upperFirst(userRole)}
              </Option>
            )
          })}
        </Select>
      ) : (
        props.role
      )}
    </div>
  )
}
