import React from 'react'
import {
  ActivityFilterChoice,
} from '../../../entities/choices/subtasksFilter'
import { TaskPanelFilterOption } from './TaskPanelFilterOption'
import { TaskPanelFilterPopover } from './TaskPanelFilterPopover'
import {HistoryActivityItemType} from "../../../history/state/model";

interface TaskPanelFilterPopoverDefaultProps {
  filters: ActivityFilterChoice[]
  sectionName: string
  noIconBackground?: boolean
  onFilter?: (value: HistoryActivityItemType) => void
  selectedActivityFilter?: HistoryActivityItemType[]
}

export function TaskPanelFilterPopoverDefault(
  props: TaskPanelFilterPopoverDefaultProps
) {
  return (
    <TaskPanelFilterPopover
      noIconBackground={props.noIconBackground}
      sectionName={props.sectionName}
    >
      {props.filters.map((filter: ActivityFilterChoice) => {
        return (
          <TaskPanelFilterOption
            key={filter.value}
            filter={filter}
            onFilter={props.onFilter}
            selected={props.selectedActivityFilter?.includes(filter.value)}
          />
        )
      })}
    </TaskPanelFilterPopover>
  )
}
