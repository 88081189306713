import React, { useMemo, useCallback } from 'react'
import { ItemListRenderer, Select } from '@blueprintjs/select'
import { isCurrentSprint, Sprint } from '../../entities/sprint/model'
import {
  selectChoicePredicate,
  selectItemRenderer,
  useSelect,
} from '../../utils/select'
import { Button, Empty } from 'antd'
import classNames from 'classnames'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'
import { Menu } from '@blueprintjs/core'

export interface SprintSelectProps {
  sprints: Sprint[]
  disabled?: boolean | false
  canClear?: boolean
  onSprintSelect: (sprint: Sprint) => void
  selectedSprint?: Sprint
  className?: string
  popoverClassName?: string
  portalClassName?: string
}

export function SprintSelect(props: SprintSelectProps) {
  const {
    disabled,
    canClear,
    className,
    onSprintSelect,
    popoverClassName = '',
    portalClassName,
  } = props
  const selectedSprint = useMemo(() => {
    return !props.selectedSprint && canClear
      ? getEmptySprint()
      : props.selectedSprint
  }, [canClear, props.selectedSprint])

  const sprints = useMemo(() => {
    const currentSprint = props.sprints.find((sprint) =>
      isCurrentSprint(sprint)
    )
    if (currentSprint && canClear) {
      return [
        getEmptySprint(),
        formatCurrentSprint(currentSprint),
        ...props.sprints,
      ]
    }
    if (currentSprint) {
      return [formatCurrentSprint(currentSprint), ...props.sprints]
    }
    if (canClear) {
      return [getEmptySprint(), ...props.sprints]
    }
    return props.sprints
  }, [props.sprints, canClear])

  const onItemSelect = useCallback(
    (sprint: Sprint) => {
      return onSprintSelect({ id: sprint.id || null } as Sprint)
    },
    [onSprintSelect]
  )

  const [selectRef, showSelect, showSelectHandler] = useSelect<Sprint>()

  const popoverClasses = classNames(
    'popover',
    'popover_with-search',
    popoverClassName
  )

  const buttonClasses = classNames('breadcrumb-select__button', {
    'breadcrumb-select__button_disabled': disabled,
  })

  if (!showSelect) {
    return (
      <div className={className}>
        <Button className={buttonClasses} onClick={showSelectHandler}>
          {selectedSprint?.name || 'Sprints'}
        </Button>
      </div>
    )
  }

  const itemListRenderer: ItemListRenderer<Sprint> = ({
    items,
    renderItem,
    itemsParentRef,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item !== null)

    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={400}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  return (
    <Select<Sprint>
      ref={selectRef}
      itemListRenderer={itemListRenderer}
      className={className}
      disabled={disabled}
      items={sprints}
      itemRenderer={selectItemRenderer}
      itemPredicate={selectChoicePredicate('name')}
      onItemSelect={onItemSelect}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        portalClassName: portalClassName,
        popoverClassName: popoverClasses,
      }}
      activeItem={selectedSprint}
      noResults={
        <Empty imageStyle={{ height: 60 }} description={'No Results'} />
      }
    >
      <Button className={buttonClasses}>
        {selectedSprint?.name || 'Sprints'}
      </Button>
    </Select>
  )
}

function getEmptySprint(): Sprint {
  return {
    name: '---',
  } as Sprint
}

function formatCurrentSprint(sprint: Sprint) {
  const name = `Current Sprint (${sprint.name})`
  return { ...sprint, name }
}
