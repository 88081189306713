import { ReportDev } from '../../entities/report-dev/model'
import { SecondsToDecimalHours } from '../../tracking/TimeConverters'

interface ReportDevActivityRow {
  title?: string
  code?: string
  activity_name?: string
  time_logged?: string
  total?: string
  key: string
  type:
    | 'project'
    | 'epic'
    | 'sprint'
    | 'task'
    | 'activity'
    | 'total'
    | 'summary'
}

export function ParseReportDevData(report: ReportDev) {
  if (!report.total_time_logged) return []

  const rows: ReportDevActivityRow[] = []

  report.projects.forEach((project) => {
    // Project
    rows.push({
      title: project.name,
      code: project.code,
      total: project.total ? SecondsToDecimalHours(project.total) : undefined,
      key: `project-${project.code}`,
      type: 'project',
    })

    // Project activities
    project.activities.forEach((activity, index) => {
      rows.push({
        activity_name: activity.activity_name,
        time_logged: activity.time_logged
          ? SecondsToDecimalHours(activity.time_logged)
          : undefined,
        key: `project-${project.code}-activity-${index}`,
        type: 'activity',
      })
    })

    // Tasks
    project.tasks.forEach((activity) => {
      rows.push({
        code: activity.code,
        activity_name: activity.activity_name,
        time_logged: activity.time_logged
          ? SecondsToDecimalHours(activity.time_logged)
          : undefined,
        key: `project-activity-${activity.code}`,
        type: 'task',
      })
    })
  })

  report.total.forEach((item) => {
    rows.push({
      title: item.name,
      total: item.total ? SecondsToDecimalHours(item.total) : undefined,
      key: `total-for-peoject-${item.code}`,
      type: 'summary',
    })
  })

  rows.push({
    title: 'Total',
    total: report.total_time_logged
      ? SecondsToDecimalHours(report.total_time_logged)
      : undefined,
    key: 'total',
    type: 'total',
  })
  return rows
}
