import '../styles/project-page-editor.scss'
import '../styles/task-name-editor.scss'

import React, { useCallback, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { ID } from '@datorama/akita'
import { debounce } from 'lodash'
import { cx } from 'emotion'
import { noop } from 'rxjs'
import { EditorState, Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Node as ProseMirrorNode } from 'prosemirror-model'

import { Extension, ProseMirrorEditor } from '../../../editor'
import {
  PlaceHolderExtension,
  TaskTypeExtension,
} from '../../../editor/extentions'
import { Task } from '../../../entities/task/model'
import { TaskTypeProvider } from '../../../editor/providers/task-type'
import { parseTaskNameDoc } from '../../../editor/extentions/tasktype/utils'

interface TaskNameEditorProps {
  task: Partial<Task>
  className?: string
  placeholder?: string
  debounceChanges?: boolean
  onChange?: (taskId?: ID, changes?: Partial<Task>) => void
  onEnterPress?: (taskId?: ID, changes?: Partial<Task>) => void
}

export const TaskNameEditor = ({
  task,
  className,
  placeholder,
  debounceChanges = true,
  onChange = noop,
  onEnterPress = noop,
}: TaskNameEditorProps) => {
  const extensions: Extension[] = [
    new PlaceHolderExtension(placeholder || 'Task Title'),
    new TaskTypeExtension(new TaskTypeProvider()),
  ]

  const getEditorDoc = (data: Partial<Task>) => {
    const content: any[] =
      data?.task_type?.map((id) => ({
        type: 'tasktype',
        attrs: { id, prefix: '#', title: '' },
      })) || []
    if (data?.name) {
      content.push({ text: data.name, type: 'text' })
    }
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content,
        },
      ],
    }
  }

  const doc = useMemo(() => getEditorDoc(task), [task])

  let [value, setValue] = useState(task.name)
  let [taskTypes, setTaskTypes] = useState(task.task_type)

  let [focused, setFocused] = useState(false)

  const debounceOnChange = debounceChanges ? debounce(onChange, 5000) : onChange

  const classNames = cx('project-page-editor', className, {
    focused,
  })

  const editable = !task.is_readonly

  const onChangeEditor = useCallback(
    (transaction: Transaction, view: EditorView) => {
      const newState = view.state.apply(transaction)
      view.updateState(newState)
      if (transaction.docChanged) {
        const [text, tTypes] = parseTaskNameDoc(view.state.doc.toJSON())
        setValue(text)
        setTaskTypes(tTypes)
        debounceOnChange(task?.id, { name: text, task_type: tTypes })
      }
    },
    [setValue, setTaskTypes, debounceOnChange, task]
  )

  const onBlur = () => {
    if (!isEqual(value, task.name) || !task.name) {
      onChange(task.id, { name: value, task_type: taskTypes })
    }
    setFocused(false)
  }

  const handleFocus = () => setFocused(true)

  const handleEnter = (view: EditorView) => {
    const [text, tTypes] = parseTaskNameDoc(view.state.doc.toJSON())
    onEnterPress(task.id, { name: text, task_type: tTypes })
    if (onEnterPress !== noop) {
      const doc = ProseMirrorNode.fromJSON(
        view.state.schema,
        getEditorDoc({ name: '' })
      )
      const newState = EditorState.create({
        schema: view.state.schema,
        doc,
        plugins: view.state.plugins,
      })
      view.updateState(newState)
    }
    return true
  }

  return (
    <ProseMirrorEditor
      hasFooter={false}
      editable={editable}
      value={doc}
      extensions={extensions}
      onEnterPress={handleEnter}
      multiLine={false}
      onChange={onChangeEditor}
      onBlur={onBlur}
      onFocus={handleFocus}
      className={classNames}
    />
  )
}
