import React from 'react'
import { ChangeUserPasswordButton } from './ChangeUserPasswordButton'
import { ChangeUserPasswordDialog } from './ChangeUserPasswordDialog'
import { ChangePasswordData } from '../../../entities/user/model'
import { Observable } from 'rxjs'

interface ChangeUserPasswordContainerProps {
  className?: string
  isDialogOpen: boolean
  onDialogOpen: () => void
  onDialogClose: () => void
  onChangePassword: (data: ChangePasswordData) => Observable<void>
}

export function ChangeUserPasswordContainer(
  props: ChangeUserPasswordContainerProps
) {
  return (
    <>
      <ChangeUserPasswordButton
        className={props.className}
        onDialogOpen={props.onDialogOpen}
      />
      <ChangeUserPasswordDialog
        isOpen={props.isDialogOpen}
        onClose={props.onDialogClose}
        onChangePassword={props.onChangePassword}
      />
    </>
  )
}
