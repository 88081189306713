import './editor.scss'

import React from 'react'
import { useProseMirror } from './core/hooks'
import { Extension } from './core/extension'
import { Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import classNames from 'classnames'
import { ProseMirrorProvider } from './core/provider'

interface ProseMirrorEditorProps {
  value: object | null
  extensions: Extension[]
  onChange: (transaction: Transaction, view: EditorView) => void
  onEnterPress?: (view: EditorView) => boolean
  hasFooter?: boolean
  multiLine?: boolean
  debug?: boolean
  [x: string]: any
}

export const Editor = ({
  value,
  editable,
  extensions,
  onChange,
  className,
  hasFooter,
  multiLine = true,
  debug,
  onEnterPress,
  ...otherProps
}: ProseMirrorEditorProps) => {
  const { ...editorProps } = useProseMirror({
    extensions,
    editable,
    value,
    multiLine,
    debug,
    onChange,
    onEnterPress,
  })
  const classes = classNames('prose-mirror-editor', className, {
    'has-footer': hasFooter,
  })

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes} {...editorProps} {...otherProps} />
    </div>
  )
}

export const ProseMirrorEditor: React.FC<ProseMirrorEditorProps> = ({
  extensions,
  ...props
}) => {
  const providers = extensions.reduce<{ [key: string]: any }>(
    (acc, ext) => ext.addProviders(acc),
    {}
  )
  return (
    <ProseMirrorProvider providers={providers}>
      <Editor extensions={extensions} {...props} />
    </ProseMirrorProvider>
  )
}
