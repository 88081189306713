import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import { Card, Layout } from 'antd'
import { workspaceQuery } from '../entities/workspace/query'
import { Workspace } from '../entities/workspace/model'
import { useQuery } from '../utils/queryhook'
import {useLegacyUrlRedirect} from "../legacy-urls-redirect/hooks";

export function WorkspaceListPageContainer() {
  const workspaces = useQuery(workspaceQuery.workspaces$)
  const redirect = useLegacyUrlRedirect()

  if (redirect) return redirect

  if (workspaces.length === 1) {
    return <Redirect to={`./${workspaces[0].id}`} />
  }

  return <WorkspaceListPage workspaces={workspaces} />
}

export function WorkspaceListPage({ workspaces }: { workspaces: Workspace[] }) {
  return (
    <Layout.Content
      style={{ margin: '24px 16px', overflow: 'initial', textAlign: 'center' }}
    >
      {workspaces?.map((workspace) => (
        <WorkspaceCard key={workspace.id} workspace={workspace} />
      ))}
    </Layout.Content>
  )
}

function WorkspaceCard({ workspace }: { workspace: Workspace }) {
  return (
    <Link to={`./${workspace.id}`}>
      <Card
        hoverable
        style={{ width: 240, display: 'inline-block', margin: '16px' }}
      >
        {workspace.name}
      </Card>
    </Link>
  )
}
