import { Query } from '@datorama/akita'
import { TaskPanelState, TaskPanelStore, taskPanelStore } from './store'
import { ProjectQuery, projectQuery } from '../../../../entities/project/query'
import { SprintQuery, sprintQuery } from '../../../../entities/sprint/query'
import { EpicQuery, epicQuery } from '../../../../entities/epic/query'
import {
  ActivityLogQuery,
  activityLogQuery,
} from '../../../../entities/activities/query'
import {
  WorkspaceQuery,
  workspaceQuery,
} from '../../../../entities/workspace/query'
import { TaskQuery, taskQuery } from '../../../../entities/task/query'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { subtaskQuery, SubtaskQuery } from '../../../../entities/subtask/query'
import { of } from 'rxjs'
import { EpicStatus } from '../../../../entities/epic/model'
import {
  Subtask,
  SubtaskStatus,
  SubtaskType,
} from '../../../../entities/subtask/model'
import {
  taskTypeQuery,
  TaskTypeQuery,
} from '../../../../entities/task-type/query'

export class TaskPanelQuery extends Query<TaskPanelState> {
  loading$ = this.selectLoading()

  taskId$ = this.select('taskId')
  subtasksFilter$ = this.select('subtasksFilter')
  activityFilter$ = this.select('activitiesFilter')

  task$ = this.taskId$.pipe(
    switchMap((taskId) => this.taskQuery.selectEntity(taskId!))
  )

  linkedtasks$ = this.task$.pipe(
    switchMap((task) => {
      if (!task) {
        return of([])
      }
      return this.taskQuery.selectMany(task.linked_tasks)
    })
  )

  taskActivities$ = this.task$.pipe(
    switchMap((task) => {
      if (!task) {
        return of([])
      }

      return this.activityLogQuery.selectByTaskID(task.id)
    })
  )

  subtasks$ = this.taskId$.pipe(
    switchMap((taskId) => {
      return this.subtasksFilter$.pipe(
        switchMap(({ types, statuses }) => {
          return this.subtaskQuery
            .forTask(taskId!)
            .pipe(
              map((subtasks) => this.filterSubtasks(subtasks, types, statuses))
            )
        })
      )
    })
  )

  allSubtasks$ = this.taskId$.pipe(
    switchMap((taskId) => this.subtaskQuery.forTaskWithAllSubtasks(taskId!))
  )

  filterSubtasks(
    subtasks: Subtask[],
    types: SubtaskType[],
    statuses: SubtaskStatus[]
  ) {
    return subtasks.filter((subtasks) => {
      const typesCond = types.length ? types.includes(subtasks.type) : true
      const statusesCond = statuses.length
        ? statuses.includes(subtasks.status)
        : true
      return typesCond && statusesCond
    })
  }

  sprints$ = this.task$.pipe(
    switchMap((task) => {
      if (!task) {
        return of([])
      }
      return this.sprintQuery.forProject(task.project)
    })
  )

  epic$ = this.task$.pipe(
    map((task) => task && task.epic),
    distinctUntilChanged(),
    switchMap((epicId) =>
      epicId ? this.epicQuery.selectEntity(epicId) : of(null)
    )
  )

  taskTypes$ = this.taskTypeQuery.selectAll()

  linkedTasksEpics$ = this.task$.pipe(
    switchMap((task) => {
      if (!task) {
        return of([])
      }
      return this.epicQuery.selectAll()
    })
  )

  isActiveOrDraft(status: EpicStatus): boolean {
    return [EpicStatus.ACTIVE, EpicStatus.DRAFT].includes(status)
  }

  constructor(
    store: TaskPanelStore,
    private workspaceQuery: WorkspaceQuery,
    private projectQuery: ProjectQuery,
    private sprintQuery: SprintQuery,
    private epicQuery: EpicQuery,
    private taskQuery: TaskQuery,
    private subtaskQuery: SubtaskQuery,
    private activityLogQuery: ActivityLogQuery,
    private taskTypeQuery: TaskTypeQuery
  ) {
    super(store)
  }
}

export const taskPanelQuery = new TaskPanelQuery(
  taskPanelStore,
  workspaceQuery,
  projectQuery,
  sprintQuery,
  epicQuery,
  taskQuery,
  subtaskQuery,
  activityLogQuery,
  taskTypeQuery
)
