import React, { useCallback } from 'react'
import { Select } from '@blueprintjs/select'
import { selectItemRenderer } from '../../utils/select'
import classNames from 'classnames'
import {
  IProjectStatus,
  Project,
  ProjectStatus,
} from '../../entities/project/model'

const TypedProjectSelect = Select.ofType<IProjectStatus>()

export interface ProjectStatusSelectProps {
  items: IProjectStatus[]
  currentItem: ProjectStatus
  onItemSelect: (project: Project) => void
  className?: string
  popoverClassName?: string
  portalClassName?: string
}

export function ProjectStatusSelect(props: ProjectStatusSelectProps) {
  const {
    className,
    onItemSelect,
    popoverClassName = '',
    portalClassName,
  } = props

  const _onItemSelect = useCallback(
    (status) => {
      return onItemSelect({ status: status.value || null } as Project)
    },
    [onItemSelect]
  )

  const currentStatus = props.items.find(
    (item) => item.value === props.currentItem
  )

  const popoverClasses = classNames(
    'popover',
    'popover_with-search',
    popoverClassName
  )

  return (
    <TypedProjectSelect
      className={className}
      items={props.items}
      itemRenderer={selectItemRenderer}
      onItemSelect={(project) => _onItemSelect(project)}
      filterable={false}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        portalClassName: portalClassName,
        popoverClassName: popoverClasses,
      }}
      activeItem={currentStatus}
    >
      {props.currentItem}
    </TypedProjectSelect>
  )
}
