import { ID, Store, StoreConfig } from '@datorama/akita'
import {
  ReportType,
  ReportTypeEnum,
} from '../../../entities/report-statistics/model'
import { Project } from '../../../entities/project/model'
import { ReportStatistics } from '../../../entities/report-statistics/model'
import { getLastMonthStartAndEnd } from '../../utils'

export interface ReportStatisticsPageState {
  reportType: ReportType | null
  selectedProject: Project | null
  selectedUserID: ID | null
  selectedSprints: ID[]
  startDate: Date | null
  endDate: Date | null
  report: ReportStatistics | null
  showFiltersPanel: boolean
}

const momentLastMonth = getLastMonthStartAndEnd()

const initialState = {
  reportType: ReportTypeEnum.ACTIVITY,
  selectedProject: null,
  selectedUserID: null,
  selectedSprints: [],
  startDate: momentLastMonth.start,
  endDate: momentLastMonth.end,
  report: null,
  showFiltersPanel: true,
}

@StoreConfig({
  name: 'ui-report-statistics-page',
  idKey: 'statistics',
  resettable: true,
})
export class ReportStatisticsPageStore extends Store<ReportStatisticsPageState> {}

export const reportStatisticsPageStore = new ReportStatisticsPageStore(
  initialState
)
