import React, { useRef } from 'react'
import { ElementPosition } from '../../../ui/element-position'
import { ToolBar } from './ToolBar'
import { ToolBarItem, ToolBarSelectItem } from './toolbar-items'

interface ToolBarItemI {
  label?: string | JSX.Element | undefined
  iconName: string
  isActive?: boolean
  onClick: (event: React.MouseEvent) => void
}
interface ToolBarSelectItemI {
  label?: string | JSX.Element | undefined
  iconName: string
  options: {
    value: number
    label: string
    isActive: boolean
  }[]
  onSelect: (value: number) => void
}

interface DialogProps {
  targetElement: HTMLElement
  appendTo: HTMLElement
  offset: { top: number; left: number; right: number; bottom: number }
  items: (ToolBarItemI | ToolBarSelectItemI)[]
}

export const MenuBar = ({
  targetElement,
  offset,
  items,
  appendTo,
}: DialogProps) => {
  const appendToRef = useRef(appendTo)
  const targetRef = useRef(targetElement)
  targetRef.current = targetElement

  return (
    <ElementPosition
      targetElement={targetRef}
      boundaryElement={appendToRef}
      appendTo={appendToRef}
      zIndex={10001}
      my="left bottom"
      at={`left+${offset.left} top+${offset.top}`}
    >
      <ToolBar>
        {items.map((props, index) => {
          if ((props as ToolBarSelectItemI).options) {
            return (
              <ToolBarSelectItem
                key={index}
                {...(props as ToolBarSelectItemI)}
              />
            )
          }
          return <ToolBarItem key={index} {...(props as ToolBarItemI)} />
        })}
      </ToolBar>
    </ElementPosition>
  )
}
