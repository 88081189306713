import classNames from 'classnames'
import React from 'react'
import { ProjectPageFilterTitle } from './ProjectPageFilterTitle'
import './styles/project-page-filter-section.scss'

interface ProjectPageFilterSectionProps {
  title: string
  children: React.ReactNode
  className?: string
}

export function ProjectPageFilterSection(props: ProjectPageFilterSectionProps) {
  const classes = classNames('project-page-filter-section', props.className)
  return (
    <div className={classes}>
      <ProjectPageFilterTitle>{props.title}</ProjectPageFilterTitle>
      {props.children}
    </div>
  )
}
