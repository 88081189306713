import React from 'react'
import { Tooltip, PopoverInteractionKind } from "@blueprintjs/core";
import { EventContentArg } from '@fullcalendar/react'

import { CalendarEvent } from './model'
import { getTimeRange } from './helpers'


export function renderGroupedEvents(eventContent: EventContentArg) {
  const events = eventContent.event.extendedProps.children
  return (
    <div className="tooltip-grouped-events">
      {events.map((event: CalendarEvent, i: number) =>
        <div key={i}>
          {event.name} | {getTimeRange(event)} | {event.activity_label}
        </div>
      )}
    </div>
  )
}

export function renderInnerContent(eventContent: EventContentArg, isTooltip: boolean = false) {
  const isGrouped = eventContent.event.extendedProps.grouped
  const activity = eventContent.event.extendedProps.activity

  return (
    <div className="fc-event-main-frame">
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">
          {eventContent.event.title}
        </div>
        <div className="fc-event-title">
          {activity && eventContent.timeText}
          {activity && !isGrouped && ` | ${activity}`}
          {isGrouped && ' (grouped)'}
          {isGrouped && isTooltip && (renderGroupedEvents(eventContent))}
        </div>
      </div>
    </div>
  )
}

export function calendarEventContent(eventContent: EventContentArg) {
  return (
    <Tooltip
      wrapperTagName="div"
      targetTagName="div"
      interactionKind={PopoverInteractionKind.HOVER}
      content={renderInnerContent(eventContent, true)}
    >
      <div>{renderInnerContent(eventContent, false)}</div>
    </Tooltip>
  )
}
