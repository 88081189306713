import { WebSocketMessage, WebsocketMessageProcessor } from './types'
import { pushMessageManager } from './push-message-manager'

export class ServerNotificationProcessor implements WebsocketMessageProcessor {
  process(message: WebSocketMessage) {
    if (message[0] !== 'event') {
      return false
    }

    if (message[1] === 'comment_needed') {
      pushMessageManager.createNotification({
        title: 'Comment needed',
        body: 'Please enter a comment to continue time tracking',
      })
    } else if (message[1] === 'tracking_stopped') {
      pushMessageManager.createNotification({
        title: 'Tracking stopped',
        body: 'Time tracking stopped. Please start tracking againg.',
      })
    } else if (message[1] === 'estimate_change_needed') {
      pushMessageManager.createNotification({
        title: 'Change estimation required’',
        body: 'Please change subtask estimation to continue time tracking',
      })
    }

    return true
  }
}

export const serverNotificationProcessor = new ServerNotificationProcessor()
