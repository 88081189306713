import '../../styles/user-profile.scss'

import React from 'react'
import { Project } from '../../../entities/project/model'
import { UserProfileProjects } from './UserProfileProjects'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'

interface UserProfileProjectsInfoProps {
  allProjects: Project[]
  userProjects: Project[]
  editable: boolean
  onProjectsChange: (projects: Project[]) => void
}

export function UserProfileProjectsInfo(props: UserProfileProjectsInfoProps) {
  return (
    <div className="user-profile__info user-profile__info_projects user-info">
      <UserProfileFieldTitle label={'projects'} />
      <div className="user-profile__projects">
        <UserProfileProjects
          allProjects={props.allProjects}
          selectedProjects={props.userProjects}
          editable={props.editable}
          onProjectsChange={props.onProjectsChange}
        />
      </div>
    </div>
  )
}
