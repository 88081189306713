import React from 'react'

import { PanelSectionTitle } from '../../../panels/panel-section-title/PanelSectionTitle'
import { EpicDetailsSwitcherSelect } from './EpicDetailsSwitcherSelect'

interface EpicDetailsSwitcherProps {
  isShow: boolean
  onChange: () => void
}

export function EpicDetailsSwitcher(props: EpicDetailsSwitcherProps) {
  const { onChange, isShow } = props

  return (
    <div className="task-group-details__switcher">
      <PanelSectionTitle title="settings" />
      <div className="task-group-details__switcher-label">
        Tasks in finished Sprints
      </div>
      <EpicDetailsSwitcherSelect
        selectedItem={isShow ? 'show' : 'hide'}
        onChange={onChange}
      />
    </div>
  )
}
