import { cloneDeep } from 'lodash'
import moment from 'moment/moment'

import { CalendarEvent } from '../model'
import { ACTIVITIES } from '../../choices/activity'

const MAX_GROUPING_TIME_GAP = 60 * 15 // 15 minutes
const TIME_SLOT_MAX_DURATION = 60 * 30 // 30 minutes


export const groupEvents = (events: CalendarEvent[]): CalendarEvent[] => {
  let groupedEvents: CalendarEvent[] = []
  events.forEach((event) => {
    event.grouped = false
    event.children = []

    if (event.full_day) {
      groupedEvents.push(event)
      return // do not group sum (full_day/allDay) entries
    }

    // Setting human-readable activity for every event
    event.activity_label =
      ACTIVITIES.find((activity) => activity.value === event.activity)?.label ||
      ''

    const prevEvent = groupedEvents.slice(-1)[0]
    if (!prevEvent) {
      groupedEvents.push(event)
      return
    }

    if (prevEvent.full_day) {
      groupedEvents.push(event)
      return
    }

    // do not group two consecutive time slots if they both aren't
    // within the same day
    if (!moment(event.start).isSame(prevEvent.end, 'day')) {
      groupedEvents.push(event)
      return
    }

    // grouping all consecutive events
    // all small time slots (under 60 minutes)
    // within the same project should be grouped together
    // if the gap between them is less than 15 minutes
    // and if there were no objects from other projects between them.
    if (
      prevEvent.project_id === event.project_id &&
      event.duration_sec < TIME_SLOT_MAX_DURATION &&
      prevEvent.duration_sec < TIME_SLOT_MAX_DURATION
    ) {
      const gap = moment(event.start).diff(moment(prevEvent.end), 'seconds')
      if (gap <= MAX_GROUPING_TIME_GAP) {
        // extending previous event (grouping)

        if (!prevEvent.grouped) {
          // prevEvent will become a grouped event and will ultimately contain
          // all grouped events. But we need to add it to the array of child
          // events as the first event of the group; this condition will
          // guarantee that it will be added only once
          prevEvent.grouped = true

          // prevEvent will be modified later, hence we clone it in its
          // current state prior to adding to children
          const firstEventInGroup = cloneDeep(prevEvent)
          prevEvent.children.push(firstEventInGroup)
        }

        prevEvent.end = event.end
        prevEvent.duration_sec = moment(event.end).diff(
          moment(prevEvent.start),
          'seconds'
        )
        prevEvent.children.push(event)
      } else {
        groupedEvents.push(event)
      }
    } else {
      groupedEvents.push(event)
    }
  })
  return groupedEvents
}
