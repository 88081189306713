import { NodeSpec, Node as ProseMirrorNode } from 'prosemirror-model'

export const TaskTypeNodeSpec: NodeSpec = {
  attrs: { id: {}, prefix: {}, title: { default: 'TaskType' } },
  inline: true,
  group: 'inline',
  draggable: true,
  toDOM: (node: ProseMirrorNode): any => [
    'a',
    {
      class: 'task_type',
      'task-type-id': node.attrs.id,
      title: node.attrs.title,
    },
    `${node.attrs.prefix}${node.attrs.title}`,
  ],
  parseDOM: [
    {
      tag: 'a.task_type',
      getAttrs: (dom: any): { [key: string]: string } | false => {
        const title = dom.textContent.substr(1)
        const prefix = dom.textContent[0]
        const id = dom.getAttribute('task-type-id')
        if (!id || !title) {
          return false
        }
        return { title, id, prefix }
      },
    },
  ],
}
