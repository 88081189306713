import { ID, Store, StoreConfig } from '@datorama/akita'
import { Project } from '../../../entities/project/model'
import { ReportProject } from '../../../entities/report-project/model'
import { getLastMonthStartAndEnd } from '../../utils'

export interface ReportProjectPageState {
  selectedProject: Project | null
  selectedUsersIDs: ID[]
  startDate: Date | null
  endDate: Date | null
  report: ReportProject | null
  showFiltersPanel: boolean
}

const momentLastMonth = getLastMonthStartAndEnd()

const initialState = {
  selectedProject: null,
  selectedUsersIDs: [],
  startDate: momentLastMonth.start,
  endDate: momentLastMonth.end,
  report: null,
  showFiltersPanel: true,
}

@StoreConfig({ name: 'ui-report-project-page', resettable: true })
export class ReportProjectPageStore extends Store<ReportProjectPageState> {}

export const reportProjectPageStore = new ReportProjectPageStore(initialState)
