import React from 'react'

export function ReportApplyFiltersText(props: { onClick: () => void }) {
  return (
    <button
      className="report-page__apply-filter-btn"
      type="button"
      onClick={props.onClick}
    >
      Аpply filters to see report results
    </button>
  )
}
