import React from 'react'
import './styles/error-page-link.scss'

interface ErrorPageLinkProps {
  to: string
  text: string
}

export function ErrorPageLink(props: ErrorPageLinkProps) {
  return (
    <a className="error-page-link" href={props.to}>
      {props.text}
    </a>
  )
}
