import React from 'react'
import { LinkOutlined } from '@ant-design/icons'

export interface SmartSuiteLinkProps {
  ss_link: string
}

export function SmartSuiteLink(props: SmartSuiteLinkProps) {
  const { ss_link } = props

  return (
    <div className="task-panel-content__section">
      <a target="_blank" rel="noopener noreferrer" href={ss_link}>
        <LinkOutlined style={{ color: 'var(--color-text-link)' }} /> SmartSuite
        Record Link
      </a>
    </div>
  )
}
