import React from 'react'
import { FiltersListTitle } from './FiltersListTitle'
import { ProjectSelect } from '../components/controls/ProjectSelect'
import { Project } from '../entities/project/model'

interface FiltersListProjectsProps {
  projects: Project[]
  selectedProject: Project | null
  onSelectProject: (project: Project | null) => void
}

export function FiltersListProjects(props: FiltersListProjectsProps) {

  return (
    <div className="filters-list__item">
      <FiltersListTitle>Project</FiltersListTitle>
      <ProjectSelect
        projects={props.projects}
        selectedProject={props.selectedProject}
        onChange={props.onSelectProject}
      />
    </div>
  )
}
