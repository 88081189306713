import './styles/add-time-manually-modal.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Dialog } from '@blueprintjs/core'
import FullCalendar from '@fullcalendar/react'
import { EventSourceFunc } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import { createEventSource } from '../../entities/calendar-event/helpers'
import { calendarEventContent } from '../../entities/calendar-event/template'
import { AddTimeManuallyDatePicker } from './AddTimeManuallyDatePicker'
import { AddTimeManuallyTimePicker } from './AddTimeManuallyTimePicker'
import { AddTimeManuallyActivitiesSelect } from './AddTimeManuallyActivitiesSelect'
import { ActivityChoice } from '../../entities/choices/activity'
import { AddTimeManuallyComment } from './AddTimeManuallyComment'
import { AddTimeManuallySection } from './AddTimeManuallySection'
import { AddTimeManuallyObject } from '../../tracking/types'
import { User } from '../../entities/user/model'
import { TimeEntry } from '../../entities/time-entry/model'
import { IFormErrors } from '../../project-page/state/store'
import { Alert, Button } from 'antd'
import {
  DATE_STORE_FORMAT,
  formatDate,
  TIME_FORMAT_ROUNDED,
} from '../../utils/date'

interface AddTimeManuallyModalProps {
  isOpen: boolean
  activities: ActivityChoice[]
  onSave: (data: AddTimeManuallyObject) => void
  onClose: () => void
  currentUser?: User
  timeEntries?: TimeEntry[]
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function AddTimeManuallyModal(props: AddTimeManuallyModalProps) {
  const { onSave, onClose, currentUser } = props

  const [startDate, setStartDate] = useState(new Date())
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [activity, setActivity] = useState('')
  const [comment, setCommentValue] = useState<string>('')
  const [disabled, setDisabled] = useState(true)
  const [calendarRef, setCalendarRef] = useState()

  const selectedUsersIDs = currentUser?.id ? [currentUser.id] : []
  const selectedProject = null
  const { workspaceId } = useParams()

  const eventSource: EventSourceFunc = useCallback(
    createEventSource(selectedUsersIDs, selectedProject, workspaceId),
    [selectedUsersIDs, selectedProject, workspaceId]
  )

  // Callback ref
  const onRefChange = useCallback((node) => {
    if (node !== null) setCalendarRef(node)
  }, [])

  useEffect(() => {
    if (!calendarRef) return

    const calendarApi = calendarRef.getApi()

    calendarApi.gotoDate(startDate)

    // Remove events if presents
    calendarApi.removeAllEventSources()

    setStartTime(null)
    setEndTime(null)

    calendarApi.addEventSource({ events: eventSource, editable: false })
  }, [startDate, currentUser, calendarRef]) // eslint-disable-line

  const onSaveHandler = useCallback(() => {
    const date = formatDate(startDate, DATE_STORE_FORMAT)
    const start = moment(`${date} ${startTime}`).format()
    const end = moment(`${date} ${endTime}`).format()

    onSave({ start, end, activity, comment })
  }, [onSave, startDate, startTime, endTime, activity, comment])

  const onCloseHandler = useCallback(() => {
    onClose()
  }, [onClose])

  const onKeyPressCloseHandler = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    setDisabled(
      !(
        !!startDate &&
        !!startTime &&
        !!endTime &&
        !!activity &&
        !!comment &&
        startTime !== endTime &&
        startTime < endTime &&
        comment.length <= 120
      )
    )
  }, [startDate, startTime, endTime, activity, comment])

  const addCustomEvent = useCallback(
    (start, end) => {
      if (!calendarRef) return

      const calendarApi = calendarRef.getApi()

      // Remove custom event if present
      const customEventSource = calendarApi.getEventSourceById('custom-event')
      customEventSource?.remove()

      // Add new custom event
      calendarApi.addEventSource({
        id: 'custom-event',
        events: [
          {
            start: moment(`${startDate.toDateString()} ${start}`).format(),
            end: moment(`${startDate.toDateString()} ${end}`).format(),
          },
        ],
      })
    },
    [startDate, calendarRef]
  )

  useEffect(() => {
    addCustomEvent(startTime, endTime)
  }, [startTime, endTime, addCustomEvent])

  useEffect(() => {
    window.addEventListener('keydown', onKeyPressCloseHandler)
    return () => {
      window.removeEventListener('keydown', onKeyPressCloseHandler)
    }
  }, [onKeyPressCloseHandler])

  const calendarSelectHandler = useCallback(
    (startStr: string, endStr: string) => {
      const start = formatDate(startStr, TIME_FORMAT_ROUNDED)
      const end = formatDate(endStr, TIME_FORMAT_ROUNDED)

      setStartTime(start)
      setEndTime(end)

      addCustomEvent(start, end)
    },
    [setStartTime, setEndTime, addCustomEvent]
  )

  return (
    <Dialog
      enforceFocus={false}
      className="add-time-manually-modal"
      isOpen={props.isOpen}
    >
      <AddTimeManuallySection title="Select date">
        <AddTimeManuallyDatePicker
          className="add-time-manually-modal__date-range"
          date={startDate}
          onChange={setStartDate}
        />
      </AddTimeManuallySection>
      <div className="add-time-manually-modal__calendar calendar-tracker">
        <FullCalendar
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
          ref={onRefChange}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView="timeGridDay"
          selectable={true}
          selectMirror={true}
          height={400}
          select={(info) => calendarSelectHandler(info.startStr, info.endStr)}
          editable={true}
          eventDrop={(info) =>
            calendarSelectHandler(info.event.startStr, info.event.endStr)
          }
          eventResize={(info) =>
            calendarSelectHandler(info.event.startStr, info.event.endStr)
          }
          scrollTime="14:00:00"
          locale="en-GB"
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
          eventContent={calendarEventContent}
        />
      </div>
      <div className="add-time-manually-modal__sections">
        <AddTimeManuallyTimePicker
          title="Start time"
          value={startTime}
          onChange={setStartTime}
        />
        <AddTimeManuallyTimePicker
          title="End time"
          value={endTime}
          onChange={setEndTime}
        />
      </div>
      {!!props.addTimeFormErrors?.non_field_errors && (
        <Alert
          message={props.addTimeFormErrors.non_field_errors}
          type="error"
          showIcon
        />
      )}
      <AddTimeManuallySection title="Activity">
        <AddTimeManuallyActivitiesSelect
          activities={props.activities}
          activity={activity}
          onSelect={setActivity}
        />
      </AddTimeManuallySection>
      <AddTimeManuallySection title="Comment">
        <AddTimeManuallyComment value={comment} onChange={setCommentValue} />
      </AddTimeManuallySection>
      <div className="add-time-manually-modal__buttons">
        <Button
          className="add-time-manually-modal__button"
          type="primary"
          htmlType="submit"
          disabled={disabled}
          onClick={onSaveHandler}
        >
          Save
        </Button>
        <Button
          className="add-time-manually-modal__button add-time-manually-modal__cancel-button"
          onClick={onCloseHandler}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  )
}
