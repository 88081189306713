import React from 'react'
import { Button } from 'antd'

export function FiltersListResetButton(props: { onClick: () => void }) {
  return (
    <div className="clear-filters">
      <Button className="clear-filters__button" onClick={props.onClick}>
        Reset filters
      </Button>
    </div>
  )
}
