import { Collapse } from '@blueprintjs/core'
import React, {useContext, useState} from 'react'
import { ChangeTypeAction } from '../../../entities/activities/model'
import { SubtaskTypeType } from '../../../entities/choices/subtaskType'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { HighlightSubtaskType } from '../text-formatters/HighlightSubtaskType'
import {User} from "../../../entities/user/model";
import {activityLogContext} from "../../../project-page/tasks/TaskPanel/TaskPanel";
import {Subtask} from "../../../entities/subtask/model";
import {HighlightedActivity} from "../text-formatters/HighlightedActivity";

interface ChangeSubtaskTypeActivityProps {
  action: ChangeTypeAction
  date: string
  user: User | string
}

export function ChangeSubtaskTypeActivity(
  props: ChangeSubtaskTypeActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }
  const getSubtasks = useContext(activityLogContext)
  const subtask = getSubtasks.find((x: Subtask) => x.id === action.target.id)

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> changed the type of subtask <HighlightedActivity>{subtask?.name}</HighlightedActivity>{' '}
      to{' '}
      <HighlightSubtaskType
        label={action.data.changed_to.label}
        value={action.data.changed_to.value as SubtaskTypeType}
      />
      .{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <HighlightSubtaskType
            label={action.data.changed_from.label}
            value={action.data.changed_from.value as SubtaskTypeType}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
