import React from 'react'
import {SprintEpicStatusType, getStatusColorMapping} from "../../../entities/choices/epicSprintChoices";

interface HighlightTaskStatusProps {
  label: string
  value: SprintEpicStatusType
}

export function HighlightSprintStatus(props: HighlightTaskStatusProps) {
  return (
    <span style={{ color: `${getStatusColorMapping[props.value]}` }}>
      {props.label}
    </span>
  )
}
