import React from 'react'
import { Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import { IconNames } from '@blueprintjs/icons'

interface SubtaskDetailsButtonProps {
  isOpen: boolean
  innerRef?: React.Ref<HTMLDivElement>
  hasDescription: boolean
  onClick: () => void
}

export function SubtaskDetailsButton(props: SubtaskDetailsButtonProps) {
  const { isOpen, innerRef, hasDescription, onClick } = props

  const iconPlus = (
    <Icon iconSize={12} icon={IconNames.PLUS} color="var(--color-text-link)" />
  )

  const iconChevronUp = (
    <Icon
      iconSize={12}
      icon={IconNames.CHEVRON_UP}
      color="var(--color-text-link)"
    />
  )
  const classes = classNames('subtasks-subtask__details-button', {
    'subtasks-subtask__details-button_is-visible': isOpen,
    'subtasks-subtask__details-button_no-description': !hasDescription,
    'subtasks-subtask__details-button_has-description': hasDescription,
  })

  const detailsText = (
    <span className={'subtasks-subtask__details-button-text'}>details</span>
  )

  const descriptionContent = (
    <>
      {detailsText}
      {isOpen ? iconChevronUp : iconPlus}
    </>
  )

  return (
    <span ref={innerRef} className={classes} onClick={onClick}>
      {descriptionContent}
    </span>
  )
}
