import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'

message.config({
  maxCount: 1,
})

interface IData {
  [key: string]: string
}

interface IMessage {
  message: string
  isError: boolean
}

class MessageDispatcher {
  private responsesQueue: AxiosResponse[]
  private errorsQueue: AxiosError[]
  private messages: IMessage[]
  private messagesKeys = [
    'detail',
    'non_field_errors',
    'target_id',
    'estimate',
    'error',
  ]
  private successCodes = [200, 201]
  private errorCodes = [400, 401, 403, 404]

  constructor() {
    this.responsesQueue = []
    this.errorsQueue = []
    this.messages = []
  }

  putError(error: AxiosError) {
    this.errorsQueue.push(error)
    this.processError()
  }

  putResponse(response: AxiosResponse) {
    this.responsesQueue.push(response)
    this.processResponse()
  }

  putSuccessMessage(message: string) {
    if (!this.messages.find((x) => x.message === message)) {
      this.messages.push({ message: message, isError: false })
      this.processMessage()
    }
  }

  putErrorMessage(message: string) {
    if (!this.messages.find((x) => x.message === message)) {
      this.messages.push({ message: message, isError: true })
      this.processMessage()
    }
  }

  parseMessage(message: AxiosError | AxiosResponse) {
    let messages: string[] = []

    const prepareMessage = (data: IData | string | string[]) => {
      if (typeof data === 'string') {
        messages.push(data)
      } else if (Array.isArray(data)) {
        if (!!data.length) {
          data.forEach((message) => {
            if (!!message) messages.push(message)
          })
        }
      } else {
        this.messagesKeys.forEach((key) => {
          if (data?.hasOwnProperty(key)) {
            messages.push(data[key])
          }
        })
      }
    }

    const isError = (message as AxiosError).isAxiosError !== undefined
    if (isError) {
      const message_ = message as AxiosError
      if (
        message_.hasOwnProperty('response') &&
        message_.response!.hasOwnProperty('data') &&
        !!message_.response &&
        this.errorCodes.includes(message_.response.status)
      ) {
        prepareMessage(message_.response?.data)
      }
    } else {
      const message_ = message as AxiosResponse
      if (
        message_.hasOwnProperty('data') &&
        this.successCodes.includes(message_.status)
      ) {
        prepareMessage(message_.data)
      }
    }
    return messages
  }

  processError() {
    while (this.errorsQueue.length > 0) {
      const nextError = this.errorsQueue.shift()
      if (!!nextError) {
        try {
          const messages = this.parseMessage(nextError)
          if (!!messages.length) {
            messages.forEach((errorMessage) => {
              if (!!errorMessage) message.error(errorMessage)
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  processResponse() {
    while (this.responsesQueue.length > 0) {
      const nextResponse = this.responsesQueue.shift()
      if (!!nextResponse) {
        try {
          const messages = this.parseMessage(nextResponse)
          if (!!messages.length) {
            messages.forEach((successMessage) => {
              if (!!successMessage) message.success(successMessage)
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  processMessage() {
    while (this.messages.length > 0) {
      const nextMessage = this.messages.shift()
      if (!!nextMessage && !!nextMessage.message) {
        if (nextMessage.isError) {
          message.error(nextMessage.message)
        } else {
          message.success(nextMessage.message)
        }
      }
    }
  }
}

export const messageDispatcher = new MessageDispatcher()
