import { Observable } from 'rxjs'
import { useEffect, useRef, useState } from 'react'
import { first } from 'rxjs/operators'

export function useQuery<T>(observable: Observable<T>): T {
  const initialized = useRef(false)
  let initialValue: T
  if (!initialized.current) {
    observable.pipe(first()).subscribe((first: T) => {
      initialValue = first
    })
    initialized.current = true
  }

  // @ts-ignore
  const [value, setValue] = useState<T>(initialValue)

  useEffect(() => {
    const sub = observable.subscribe((value) => {
      setValue(value)
    })

    return () => sub.unsubscribe()
  }, [observable])

  return value
}
