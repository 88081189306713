import React, {useState} from 'react'
import { SetProjectDeadlineAction } from '../../../entities/activities/model'
import { HighlightedActivity } from '../text-formatters/HighlightedActivity'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";
import {ActivitiesDetailsButton} from "../ActivitiesDetailsButton";
import {Collapse} from "@blueprintjs/core";
import {ActivityDetailsContent} from "../ActivitiyDetailsContent";

interface SetProjectDeadlineActivityProps {
  action: SetProjectDeadlineAction
  date: string
  user: User | string
}

export function SetProjectDeadlineActivity(props: SetProjectDeadlineActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }
  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> updated the project deadline date.{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      . <ActivitiesDate date={props.date} isActivityDate />
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <HighlightedActivity>
            {action.data.changed_to}
          </HighlightedActivity>
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
