import { interval } from 'rxjs'
import { WebSocketConnection, websocketConnection } from './connection'
import { WebSocketMessage } from './types'

export class UserPresenceManager {
  constructor(private connection: WebSocketConnection<WebSocketMessage>) {}

  init() {
    interval(60 * 1000).subscribe(() => {
      this.connection.send(['alive', null])
    })
  }
}

export const userPresenceManager = new UserPresenceManager(websocketConnection)
