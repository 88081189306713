import React from 'react'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import { UserProfileEmailInput } from './UserProfileEmailInput'

import '../../styles/user-field.scss'

interface UserProfileEmailInfoProps {
  label: string
  value: string
  editable: boolean
  onChange: (email: string) => void
}

export function UserProfileEmailInfo(props: UserProfileEmailInfoProps) {
  return (
    <div className="user-profile__info user-info user-profile__info_email">
      <UserProfileFieldTitle label={props.label} />
      <UserProfileEmailInput
        value={props.value}
        maxLength={256}
        editable={props.editable}
        onChange={props.onChange}
      />{' '}
    </div>
  )
}
