import classNames from 'classnames'
import { isEqual } from 'lodash'
import React, { useState } from 'react'
import { Extension, ProseMirrorEditor } from '../../../../editor'
import {
  PlaceHolderExtension,
  InternalLinkExtension,
  ExternalLinkExtension,
  MentionExtension,
  AttachmentExtension,
  KeyMapExtension,
  ToolbarExtension,
  FooterExtension,
  StrikethroughExtension,
  TableExtension,
  UnderlineExtension,
  HorizontalRuleExtension,
} from '../../../../editor/extentions'
import {
  InternalLinkProvider,
  MentionProvider,
  AttachmentProvider,
  FooterProvider,
} from '../../../../editor/providers'
import { Epic } from '../../../../entities/epic/model'
import { PanelSectionTitle } from '../../../panels/panel-section-title/PanelSectionTitle'
import '../../../tasks/styles/project-page-editor.scss'
import '../styles/epic-details-description.scss'

interface EpicDetailsDescriptionProps {
  epic: Epic
  className?: string
  disabled?: boolean
  onChange: (value: object | null) => void
}

export function EpicDetailsDescription(props: EpicDetailsDescriptionProps) {
  const extensions: Extension[] = [
    new PlaceHolderExtension(),
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider()),
    new AttachmentExtension(new AttachmentProvider(props.epic.id)),
    new ToolbarExtension(),
    new FooterExtension(new FooterProvider(props.epic.id)),
    new StrikethroughExtension(),
    new TableExtension(),
    new UnderlineExtension(),
    new HorizontalRuleExtension(),
    new KeyMapExtension(),
  ]

  const [value, setValue] = useState(() => props.epic.json_description)
  const { disabled } = props

  const onChangeEditor = (transaction: any, view: any) => {
    if (disabled) return
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
    }
  }

  const onBlur = () => {
    if (!isEqual(value, props.epic.json_description)) {
      props.onChange(value)
    }
  }
  const classes = classNames('epic-details-description', props.className, {
    'is-disabled': disabled,
  })

  return (
    <div className={classes}>
      <PanelSectionTitle title="description" />
      <ProseMirrorEditor
        value={value}
        extensions={extensions}
        hasFooter={true}
        onChange={onChangeEditor}
        onBlur={onBlur}
        editable={!disabled}
        className="project-page-editor epic-details-description__editor"
      />
    </div>
  )
}
