import { http } from '../../globals/http'
import { ActivityLog } from './model'
import { ID } from '@datorama/akita'

export class ActivityLogDataService {

  get(id: ID) {
    return http.get<ActivityLog>(`/api/activity_log/${id}/`)
  }

  list(query?: object) {
    return http.get<ActivityLog[]>('/api/activity_log/', query)
  }
}

export const activityLogDataService = new ActivityLogDataService()
