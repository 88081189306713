import { Schema } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'

import { enterInputRules } from "../../core/enter-input-rules"
import { Extension } from "../.."
import { UrlInputRule } from './inputrule'
import { UrlDialogPlugin } from './plugins'

export class ExternalLinkExtension extends Extension {
  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    enterInputRules({ rules: [UrlInputRule] }),
    UrlDialogPlugin(),
  ]
}
