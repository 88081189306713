import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import { ID } from '@datorama/akita'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { ReorderMenu, ReorderMenuItemType } from './ReorderMenu'
import { MoveItemCallback } from '../../drag-n-drop'
import {SortMenu} from "./TaskSortMenu";

const menuItemClasses = classNames(
  Classes.MENU_ITEM,
  Classes.TEXT_OVERFLOW_ELLIPSIS,
  Classes.FILL,
  Classes.POPOVER_DISMISS
)

const renderItem = (index: number, item: IItemInterfaceID) => {
  const { onSelectItem, ...selected } = item
  const onSelectItemHandler = () => onSelectItem(selected)

  return (
    <div key={index} className={menuItemClasses} onClick={onSelectItemHandler}>
      {item.label}
    </div>
  )
}

interface TaskGroupDropdownMenuSelectedItem {
  id: ID
  value?: string
  label: string
}

interface IItemInterface {
  label: string
  value?: string
  onSelectItem: (selectedItem: TaskGroupDropdownMenuSelectedItem) => void
}

interface TaskGroupDropdownMenuProps {
  objectID: ID
  objectType: 'sprint' | 'epic'
  items: IItemInterface[]
  isOpen?: boolean
  children: React.ReactNode
  hasReorderMenu?: boolean
  reorderMenuItems?: ReorderMenuItemType[]
  handleInteraction?: (state: boolean) => void
  onReorder?: MoveItemCallback
  onSort: (sprintID: ID) => void
  onSortTasksByAssignee: (sprintID: ID) => void
}

interface IItemInterfaceID extends IItemInterface {
  id: ID
}

export function TaskGroupDropdownMenu(props: TaskGroupDropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const onSortTasks = (sprintID: ID) => {
    props.onSort(sprintID)
    setIsOpen(false)
  }

  const onSortByAssigneeTasks = (sprintID: ID) => {
    props.onSortTasksByAssignee(sprintID)
    setIsOpen(false)
  }

  const handleInteraction = useCallback((nextOpenState: boolean) => {
    setIsOpen(() => nextOpenState)
  }, [])

  return (
    <Popover
      minimal={true}
      position={Position.BOTTOM_RIGHT}
      interactionKind={PopoverInteractionKind.CLICK}
      popoverClassName="popover"
      isOpen={isOpen}
      onInteraction={(state) => {
        handleInteraction(state)
      }}
    >
      {props.children}
      <div className={Classes.MENU}>
        {props.items.map((item, index) => {
          return renderItem(index, { ...item, id: props.objectID })
        })}
        {props.hasReorderMenu && props.reorderMenuItems?.length && (
          <ReorderMenu
            items={props.reorderMenuItems}
            objectId={props.objectID}
            onReorder={props.onReorder}
          />
        )}
        {props.objectType === 'sprint' && (
          <SortMenu
            items={props.reorderMenuItems}
            objectId={props.objectID}
            onSort={onSortTasks}
            onSortByAssignee={onSortByAssigneeTasks}
          />
        )}
      </div>
    </Popover>
  )
}
