import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
import { FormInstance } from 'antd/es/form'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Store } from 'rc-field-form/lib/interface'
import { Observable } from 'rxjs'
import { Project } from '../../../entities/project/model'
import { User, UserInvite, UserRole } from '../../../entities/user/model'
import { convertBackendErrors } from '../../../utils/backend-errors'
import { ProjectMultiSelect } from '../../../components/controls/ProjectMultiSelect'
import { messageDispatcher } from '../../../utils/message-dispatcher'

interface UsersListAddUserProps {
  projects: Project[]
  onUserInvite: (invite: UserInvite) => Observable<User>
  onSuccessInviteUser: (user: User) => void
}

export function UsersListAddUser(props: UsersListAddUserProps) {
  const [modelVisible, setModelVisible] = useState(false)

  const initialValues = useMemo(
    () => ({
      email: '',
      full_name: '',
      user_role: UserRole.USER,
      projects: [],
    }),
    []
  )
  const formRef = useRef<FormInstance>(null)

  const onOk = useCallback(() => {
    formRef.current?.submit()
  }, [formRef])

  const onClose = useCallback(() => {
    setModelVisible(false)
    formRef.current?.resetFields()
  }, [setModelVisible, formRef])

  const { onUserInvite, onSuccessInviteUser } = props
  const [inviteActionPending, setInviteActionPending] = useState<boolean>(false)

  const onFinish = useCallback(
    (values: Store) => {
      setInviteActionPending(true)

      values.projects = values.projects.map((project: Project) => project.id)
      onUserInvite(values as UserInvite).subscribe(
        (response) => {
          onSuccessInviteUser(response)
          setModelVisible(false)
          formRef.current?.resetFields()
          setInviteActionPending(false)
          messageDispatcher.putSuccessMessage(
            `Invitation has been sent to ${response.email}`
          )
        },
        (error) => {
          if (error.response.status === 400) {
            setInviteActionPending(false)
            formRef.current?.setFields(
              convertBackendErrors(error.response.data)
            )
          }
        }
      )
    },
    [onUserInvite, formRef, onSuccessInviteUser]
  )

  return (
    <AddUserForm
      visible={modelVisible}
      setModalVisible={setModelVisible}
      initialValues={initialValues}
      projects={props.projects}
      onOk={onOk}
      onCancel={onClose}
      onFinish={onFinish}
      formRef={formRef}
      isPendingRequest={inviteActionPending}
    />
  )
}

interface AddUserFormProps {
  visible: boolean
  setModalVisible: Dispatch<SetStateAction<boolean>>
  initialValues: object
  projects: Project[]
  onOk: () => void
  onCancel: () => void
  onFinish: (values: Store) => void
  formRef: RefObject<FormInstance>
  isPendingRequest: boolean
}

function AddUserForm(props: AddUserFormProps) {
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([])

  const { setModalVisible, isPendingRequest } = props

  const onAddUserClick = useCallback(() => setModalVisible(true), [
    setModalVisible,
  ])

  return (
    <>
      <button className="users-list__add" onClick={onAddUserClick}>
        <div className="users-list__add-label">Add User</div>
        <PlusCircleOutlined
          className="users-list__add-icon"
          style={{ fontSize: 19 }}
        />
      </button>
      <Modal
        visible={props.visible}
        title="Add new user"
        onOk={props.onOk}
        onCancel={props.onCancel}
        footer={[
          <Button
            key="back"
            onClick={props.onCancel}
            disabled={isPendingRequest}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={props.onOk}
            disabled={isPendingRequest}
          >
            Send invitation
          </Button>,
        ]}
      >
        <Form
          ref={props.formRef}
          labelCol={{ span: 5 }}
          layout="horizontal"
          initialValues={props.initialValues}
          onFinish={props.onFinish}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
              {
                type: 'email',
                message: 'Please enter valid email',
              },
            ]}
          >
            <Input type="email" placeholder="Enter email to sent invitation" />
          </Form.Item>
          <Form.Item
            name="full_name"
            label="Full Name"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Input placeholder="Enter new user name" />
          </Form.Item>
          <Form.Item name="user_role" label="Role">
            <Select>
              <Select.Option value={UserRole.USER}>User</Select.Option>
              <Select.Option value={UserRole.ADMIN}>Admin</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="projects"
            label="Projects"
            rules={[{ required: true, message: 'Field is required' }]}
            trigger="onProjectsChange"
            valuePropName="selectedProjects"
          >
            <ProjectMultiSelect
              projects={props.projects}
              selectedProjects={selectedProjects}
              onProjectsChange={setSelectedProjects}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
