import React, { useCallback, useMemo } from 'react'
import { ID } from '@datorama/akita'
import {
  HTMLInputProps,
  IInputGroupProps,
  ITagProps,
  Menu,
  PopoverPosition,
  Tag,
} from '@blueprintjs/core'
import {
  IItemRendererProps,
  ItemListRenderer,
  Suggest,
} from '@blueprintjs/select'
import { compact } from 'lodash'
import { Empty } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { sortAlphabetically, Sprint } from '../../entities/sprint/model'

import './styles/suggest.scss'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'

const TypedSprintsSuggest = Suggest.ofType<Sprint>()

interface SprintsSuggestProps {
  sprints: Sprint[]
  selectedSprintIDs: ID[]
  onChangeSelectedSprintIDs: (sprintsIDs: ID[]) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  noSprintsSelectedPlaceholder?: string
  popoverPosition?: PopoverPosition
  tilesClassName?: string
  wrapperClassName?: string
  targetClassName?: string
  popoverFill?: boolean
  noTilesWrapper?: boolean
  inputProps?: IInputGroupProps & HTMLInputProps
  escapeWithReference?: boolean
  noUnassignedSprint?: boolean
  inputIcon?: React.ReactNode
  closeOnSelect?: boolean
  portalClassName?: string
  maxSelectableItems?: number
}

export function SprintsSuggest(props: SprintsSuggestProps) {
  const {
    selectedSprintIDs,
    onChangeSelectedSprintIDs,
    escapeWithReference = true,
    closeOnSelect = true,
  } = props

  const sprints = useMemo(
    () => sortAlphabetically([...props.sprints]),
    [props.sprints]
  )

  const onChangeSprint = useCallback(
    (sprint: Sprint) => {
      const exists = selectedSprintIDs.includes(sprint.id)

      if (exists) {
        onChangeSelectedSprintIDs(
          selectedSprintIDs.filter((id) => id !== sprint.id)
        )
      } else {
        onChangeSelectedSprintIDs([sprint.id, ...selectedSprintIDs])
      }
    },
    [selectedSprintIDs, onChangeSelectedSprintIDs]
  )

  const handleTagRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, tagProps: ITagProps | any) => {
      const id = tagProps['data-id']
      const sprint = sprints.find((sprint) => sprint.id === id)!
      onChangeSprint(sprint)
    },
    [sprints, onChangeSprint]
  )

  const filterSprint = (query: string, sprint: Sprint) => {
    const matchString = sprint.name.toLowerCase()
    return matchString.indexOf(query.trim().toLowerCase()) >= 0
  }

  const disabled: boolean = props.maxSelectableItems
    ? selectedSprintIDs?.length >= props.maxSelectableItems
    : false

  // forcefully close suggestion box if we're over limit
  const _closeOnSelect = closeOnSelect || disabled

  const renderMenu: ItemListRenderer<Sprint> = ({
    items,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null)
    if (renderedItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          {(!sprints.length && props.emptyListLabel && (
            <li className="suggest__empty-list-label">
              {props.emptyListLabel}
            </li>
          )) || (
            <Empty
              imageStyle={{ height: 60 }}
              description={'Please pick a project'}
            />
          )}
        </Menu>
      )
    } else if (_closeOnSelect) {
      // ugly, but otherwise TypedSprintsSuggest won't close this window
      // until next render, despite its closeOnSelect property value
      return <></>
    }
    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={250}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  const renderSprint = useCallback(
    (sprint: Sprint, renderProps: IItemRendererProps) => {
      const { modifiers, handleClick } = renderProps

      if (!modifiers.matchesPredicate) {
        return null
      }

      const sprintTitle = `${sprint.name} (${sprint.status})`

      return (
        <li
          className={classNames('bp3-menu-item', {
            'bp3-multi': modifiers.active,
            'bp3-menu-item_selected': selectedSprintIDs.includes(sprint.id),
          })}
          onClick={handleClick}
          key={sprint.id}
        >
          <div className="sprint-select__list-item">
            <div
              className="sprint-select__list-full-name bp3-text-overflow-ellipsis"
              title={sprintTitle}
            >
              {sprintTitle}
            </div>
          </div>
        </li>
      )
    },
    [selectedSprintIDs]
  )

  const placeholder =
    (!selectedSprintIDs?.length && props.noSprintsSelectedPlaceholder) ||
    // eslint-disable-next-line no-useless-concat
    'Enter Sprint N' + '\u0430' + 'me'

  const selectedSprints = useMemo(() => {
    return sprints.filter((sprint) => selectedSprintIDs.includes(sprint.id))
  }, [selectedSprintIDs, sprints])

  const DEFAULT_INPUT_PROPS: IInputGroupProps & HTMLInputProps = {
    name: 'searchTerm',
    autoComplete: 'off',
    placeholder: placeholder,
    rightElement: (
      <SearchOutlined
        style={{
          color: 'var(--color-text-inactive)',
          fontSize: 13,
        }}
        className={'suggest__search-icon'}
      />
    ),
  }

  const tilesClasses = classNames('suggest__tiles', props.tilesClassName)
  const wrapperClasses = classNames('suggest', props.wrapperClassName)

  const tiles = selectedSprints.map((sprint: Sprint) => (
    <Tag
      className="project-tile suggest__tile"
      data-id={sprint.id}
      onRemove={handleTagRemove}
      key={sprint.id}
    >
      <div className="suggest__content">
        <div className="suggest__name text-trim-ellipsis">
          {sprint.name} ({sprint.status})
        </div>
      </div>
    </Tag>
  ))

  return (
    <div className={wrapperClasses}>
      <div className="suggest__input-wrapper">
        {props.inputIcon}
        <TypedSprintsSuggest
          items={sprints.filter((x) => !selectedSprints.includes(x))}
          disabled={disabled}
          resetOnClose={true}
          closeOnSelect={_closeOnSelect}
          itemRenderer={renderSprint}
          onItemSelect={onChangeSprint}
          itemPredicate={filterSprint}
          itemListRenderer={renderMenu}
          inputValueRenderer={useCallback(() => '', [])}
          noResults={
            (!sprints.length && props.emptyListLabel && (
              <div className="suggest__empty-list-label">
                {props.emptyListLabel}
              </div>
            )) || (
              <Empty imageStyle={{ height: 60 }} description={'No Results'} />
            )
          }
          inputProps={props.inputProps || DEFAULT_INPUT_PROPS}
          popoverProps={{
            className: props.targetClassName,
            fill: props.popoverFill || true,
            minimal: true,
            position: props.popoverPosition || 'top',
            popoverClassName: 'popover suggest-popover',
            portalClassName: classNames(
              'suggest-portal',
              props.portalClassName
            ),
            modifiers: {
              preventOverflow: {
                escapeWithReference: escapeWithReference,
              },
            },
          }}
        />
      </div>
      {selectedSprints.length > 0 && (
        <>
          {props.noTilesWrapper ? (
            tiles
          ) : (
            <CustomScrollbar translateContentSizeYToHolder={true}>
              <div className={tilesClasses}>{tiles}</div>
            </CustomScrollbar>
          )}
        </>
      )}
    </div>
  )
}

interface SingleSprintSuggestProps {
  sprints: Sprint[]
  selectedSprintID: ID | null
  onChangeSelectedSprintID: (sprintID: ID) => void
  allowSelectAll?: boolean
  emptyListLabel?: string
  noSprintsSelectedPlaceholder?: string
}

export function SingleSprintSuggest(props: SingleSprintSuggestProps) {
  const { selectedSprintID, onChangeSelectedSprintID } = props

  const selectedSprintIDs = useMemo(() => {
    return compact([selectedSprintID])
  }, [selectedSprintID])

  const onChangeSelectedSprintIDs = useCallback(
    (sprintsIDs: ID[]) => {
      if (sprintsIDs.length) {
        onChangeSelectedSprintID(sprintsIDs[0])
      }
    },
    [onChangeSelectedSprintID]
  )

  return (
    <SprintsSuggest
      sprints={props.sprints}
      selectedSprintIDs={selectedSprintIDs}
      onChangeSelectedSprintIDs={onChangeSelectedSprintIDs}
    />
  )
}
