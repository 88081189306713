import React from 'react'
import { Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import { IconNames } from '@blueprintjs/icons'

interface TaskActivitiesDetailsButtonProps {
  isActive: boolean
  type?: 'details' | 'show-original' | 'show-previous'
  noMargin?: boolean
  onClickHandler: () => void
  itemsCount?: number
}

export function ActivitiesDetailsButton(
  props: TaskActivitiesDetailsButtonProps
) {
  const {
    isActive,
    noMargin,
    type = 'details',
    onClickHandler,
    itemsCount,
  } = props
  const buttonClasses = classNames('task-activity__details-button', {
    'task-activity__details-button_is-rotated': isActive,
    'task-activity__details-button_no-margin': noMargin,
  })

  let text = ''
  let updateWord = itemsCount && itemsCount > 1 ? 'updates' : 'update'

  if (type === 'details') {
    text = isActive ? 'Hide Details' : 'Details'
  } else if (type === 'show-original') {
    text = isActive ? 'Hide Original' : 'Show Original'
  } else if (type === 'show-previous') {
    text = isActive
      ? 'Hide previous updates'
      : `Show ${itemsCount} previous ${updateWord}`
  }

  return (
    <span className={buttonClasses} onClick={onClickHandler}>
      {text}{' '}
      <Icon
        className="task-activity__details-button-icon"
        color="var(--color-text-link)"
        iconSize={10}
        icon={IconNames.CHEVRON_DOWN}
      />
    </span>
  )
}
