import {
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita'
import { TimeEntry } from './model'

export interface TimeEntryState extends EntityState<TimeEntry, ID> {}

@StoreConfig({ name: 'entity-time-entries' })
export class TimeEntryStore extends EntityStore<TimeEntryState> {}

export const timeEntryStore = new TimeEntryStore()
