import { Schema} from "prosemirror-model"

import {
  MenuItem, DropDown, dropDown, markItem, setBlockItem, wrapListItem, wrapItem, liftItem, linkItem
} from "./utils"

export const getMenuItems = (schema: Schema): (MenuItem | DropDown | false)[] => [
  markItem({
    mark: schema.marks.strong,
    label: "Bold (Ctrl + b)",
    macLabel: "Bold (⌘ + b)",
    iconName: "bold"
  }),
  markItem({
    mark: schema.marks.em,
    label: "Italic (Ctrl + i)",
    macLabel: "Italic (⌘ + i)",
    iconName: "italic"
  }),
  markItem({
    mark: schema.marks.code,
    label: "Code (Ctrl + `)",
    macLabel: "Code (⌘ + `)",
    iconName: "html"
  }),
  linkItem({
    mark: schema.marks.link,
    label: "Link",
    iconName: "link"
  }),
  setBlockItem({
    node: schema.nodes.paragraph,
    label: "Paragraph (Ctrl + Shift + 0)",
    iconName: "paragraph"
  }),
  dropDown({
    label: "Heading",
    iconName: "heading",
    options: [1, 2, 3, 4, 5, 6].map((level) => setBlockItem({
      node: schema.nodes.heading,
      label: `Heading ${level}`,
      attrs: {level: level}
    }))
  }),
  wrapListItem({
    node: schema.nodes.bullet_list,
    label: "Bullet List (Shift + Ctrl + 8)",
    iconName: "bullet-list"
  }),
  wrapListItem({
    node: schema.nodes.ordered_list,
    label: "Numerated List (Shift + Ctrl + 9)",
    iconName: "numerated-list"
  }),
  wrapItem({
    node: schema.nodes.blockquote,
    label: "Quote (Ctrl + >)",
    iconName: "quote"
  }),
  liftItem({
    label: "Lift out of enclosing block (Ctrl + [)",
    macLabel: "Lift out of enclosing block (⌘ + [)",
    iconName: "unquote"
  })
]
