import { http } from '../../globals/http'
import {TaskIntoSubtask, Task} from './model'
import { ModelUpdate } from '../../utils/types'
import { ID } from '@datorama/akita'

export class TaskDataService {
  create(task: Partial<Task>) {
    return http.post<Task>(`/api/task/`, task)
  }

  get(id: ID) {
    return http.get<Task>(`/api/task/${id}/`)
  }

  getExtraFields(taskCode: string) {
    return http.get<Task>(`/api/task/${taskCode}/extra-fields/`)
  }

  list(query?: object) {
    return http.get<Task[]>('/api/task/', query)
  }

  update(update: ModelUpdate<Task>) {
    return http.patch<Task>(`/api/task/${update.id}/`, update)
  }

  toSubtask(id: ID, data: TaskIntoSubtask) {
    return http.post<Task>(`/api/task/${id}/to-subtask/`, data)
  }

  uploadTaskFile(taskId: ID, file: File) {
    return http.postFile(`/api/task/${taskId}/upload-file/`, 'file', file)
  }

  sortTasks(query?: object) {
    return http.get <Partial<Task>[]>('/api/task/sort-sprint-tasks/', query)
  }

  sortTasksByAssigneeOnly(query?: object) {
    return http.get <Partial<Task>[]>('/api/task/sort-sprint-tasks-only/', query)
  }
}

export const taskDataService = new TaskDataService()
