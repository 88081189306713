import './styles/dashboard-project-card.scss'

import React from 'react'
import { Project } from '../entities/project/model'
import { Link, useRouteMatch } from 'react-router-dom'
import { ProjectLogo } from '../components/ProjectLogo'

interface DashboardProjectCardProps {
  project: Project
}

export function DashboardProjectCard(props: DashboardProjectCardProps) {
  const match = useRouteMatch()
  const numberOfMembers = props.project.users?.length || 0

  return (
    <Link
      to={`${match.url}/${props.project.id}`}
      className="dashboard-page__project-card dashboard-project-card"
    >
      <div className="dashboard-project-card__img">
        <ProjectLogo project={props.project} />
      </div>
      <div className="dashboard-project-card__content">
        <div className="dashboard-project-card__name">
          <span className="dashboard-project-card__abbr">
            {props.project.code}
            {' |'}
          </span>{' '}
          {props.project.name}
        </div>
        <div className="dashboard-project-card__participants">
          {numberOfMembers || 'No'} participant{numberOfMembers % 10 === 1 ? '' : 's'}
        </div>
      </div>
    </Link>
  )
}
