import { ID, QueryEntity } from '@datorama/akita'
import { SprintState, SprintStore, sprintStore } from './store'

export class SprintQuery extends QueryEntity<SprintState> {
  constructor(protected store: SprintStore) {
    super(store)
  }

  loading$ = this.select('loading')

  forProject(projectId: ID | null) {
    return this.selectAll({
      filterBy: (sprint) => sprint.project === projectId,
    })
  }

  getForProject(projectId: ID | null) {
    return this.getAll({
      filterBy: (sprint) => sprint.project === projectId,
    })
  }

  getLoadedHashes() {
    return this.getValue().loaded
  }
}

export const sprintQuery = new SprintQuery(sprintStore)
