import { Collapse } from '@blueprintjs/core'
import React, { useState } from 'react'
import { ChangeTypeAction } from '../../entities/activities/model'
import { SubtaskTypeType } from '../../entities/choices/subtaskType'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { HighlightSubtaskType } from './text-formatters/HighlightSubtaskType'
import {User} from "../../entities/user/model";

interface ChangeTypeActivityProps {
  action: ChangeTypeAction
  date: string
  user: User | string
}

export function ChangeTypeActivity(
  props: ChangeTypeActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> change the subtask
      type to{' '}
      <HighlightSubtaskType
        label={action.data.changed_to.label}
        value={action.data.changed_to.value as SubtaskTypeType}
      />
      .{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <HighlightSubtaskType
            label={action.data.changed_from.label}
            value={action.data.changed_from.value as SubtaskTypeType}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
