import { http } from '../../globals/http'
import { ID } from '@datorama/akita'
import { Comment } from './model'
import { ModelUpdate } from '../../utils/types'

export class CommentDataService {
  get(id: ID) {
    return http.get<Comment>(`/api/comment/${id}/`)
  }

  list(query?: object) {
    return http.get<Comment[]>('/api/comment/', query)
  }

  update(update: ModelUpdate<Comment>) {
    return http.patch<Comment>(`/api/comment/${update.id}/`, update)
  }

  create(comment: Partial<Comment>) {
    return http.post<Comment>('/api/comment/', comment)
  }

  destroy(id: ID) {
    return http.delete(`/api/comment/${id}/`)
  }
}

export const commentDataService = new CommentDataService()
