import React, {useState} from 'react'
import { Collapse } from '@blueprintjs/core'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivitiesDetailsButton } from '../ActivitiesDetailsButton'
import { ChangeSprintDatesAction } from '../../../entities/activities/model'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import { ActivityDetailsContent } from '../ActivitiyDetailsContent'
import {User} from "../../../entities/user/model";
import {DeletedText} from "../text-formatters/DeletedText";
import {InsertedText} from "../text-formatters/InsertedText";


interface ChangeSprintDatesActivityProps {
  action: ChangeSprintDatesAction
  date: string
  user: User | string
}

export function ChangeSprintDatesActivity(props: ChangeSprintDatesActivityProps) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }
  const dates = (oldVal:string, newVal: string) => {
    switch (oldVal) {
      case newVal:
        return newVal
      default:
        return <span><DeletedText>{oldVal}</DeletedText>{' '}<InsertedText>{newVal}</InsertedText></span>
    }
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText>{' '}
      updated the sprint dates.{' '}
      <ActivitiesDetailsButton
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          Start: {dates(action.data.changed_from.start_date, action.data.changed_to.start_date)}; <br/>
          Staging: {dates(action.data.changed_from.staging_date, action.data.changed_to.staging_date)}; <br/>
          Release: {dates(action.data.changed_from.release_date, action.data.changed_to.release_date)}. <br/>
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
