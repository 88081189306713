import '../styles/report-page.scss'

import React, { Ref, useCallback, useRef } from 'react'
import { ID } from '@datorama/akita'
import _ from 'lodash'
import { Layout, message } from 'antd'
import { DateRange } from '@blueprintjs/datetime'
import { User } from '../../entities/user/model'
import { Project } from '../../entities/project/model'
import { Sprint } from '../../entities/sprint/model'
import { sprintService } from '../../entities/sprint/service'
import {
  ReportTypeEnum,
  ReportType,
  ReportStatistics,
} from '../../entities/report-statistics/model'
import { useQuery } from '../../utils/queryhook'
import { reportStatisticsService } from './state/service'
import { reportStatisticsQuery } from './state/query'
import { ReportStatisticsFiltersList } from './ReportStatisticsFiltersList'
import { ReportStatisticsActivityComponent } from './ReportStatisticsActivityComponent'
import { ReportStatisticsTshirtComponent } from './ReportStatisticsTshirtComponent'
import { ReportStatisticsVelocityComponent } from './ReportStatisticsVelocityComponent'
import { projectQuery } from '../../entities/project/query'
import { ReportHeader } from '../ReportHeader'
import { ReportApplyFiltersText } from '../ReportApplyFiltersText'
import { copyElementContentsToClipboard } from '../utils'
import { Spinner } from '../../components/Spinner'
import { useInstantEffect } from '../../utils/instant-effect-hook'
import { Header } from '../../components/header/Header'
import { CustomScrollbar } from '../../components/custom-scrollbar/CustomScrollbar'

const statsComponents = {
  [ReportTypeEnum.ACTIVITY]: ReportStatisticsActivityComponent,
  [ReportTypeEnum.T_SHIRT]: ReportStatisticsTshirtComponent,
  [ReportTypeEnum.VELOCITY]: ReportStatisticsVelocityComponent,
  // FIXME: uncomment when more report types are implemented
  // [ReportTypeEnum.TASK_TYPE]: ReportStatisticsActivityComponent,
}

export function ReportStatisticsPageContainer() {
  const projects = useQuery(projectQuery.adminUserProjects$)
  const users = useQuery(reportStatisticsQuery.users$)
  const sprints = useQuery(reportStatisticsQuery.sprints$)

  const selectedProject = useQuery(reportStatisticsQuery.selectedProject$)
  const selectedUserID = useQuery(reportStatisticsQuery.selectedUserID$)
  const selectedSprints = useQuery(reportStatisticsQuery.selectedSprints$)

  const reportType =
    useQuery(reportStatisticsQuery.selectedReportType$) ||
    ReportTypeEnum.ACTIVITY

  const startDate = useQuery(reportStatisticsQuery.startDate$)
  const endDate = useQuery(reportStatisticsQuery.endDate$)
  const loading = useQuery(reportStatisticsQuery.loading$)
  const report = useQuery(reportStatisticsQuery.report$)

  const showFiltersPanel = useQuery(reportStatisticsQuery.showFiltersPanel$)

  useInstantEffect(() => {
    let needToFetch: Boolean = false
    if (reportType === ReportTypeEnum.VELOCITY) {
      // this can be optimized if you supply project id
      sprintService.loadSprints()
      needToFetch = Boolean(
        (selectedUserID || selectedProject) && !_.isEmpty(selectedSprints)
      )
    } else {
      needToFetch = Boolean(
        (selectedUserID || selectedProject) && startDate && endDate
      )
    }

    if (needToFetch) {
      reportStatisticsService.fetchReport(
        reportType,
        selectedProject,
        selectedUserID,
        selectedSprints,
        startDate,
        endDate
      )
    } else {
      reportStatisticsService.resetReport()
    }
  }, [
    reportType,
    selectedProject,
    selectedUserID,
    selectedSprints,
    startDate,
    endDate,
  ])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const onCopyReport = useCallback(() => {
    if (tableContainerRef.current) {
      const success = copyElementContentsToClipboard(tableContainerRef.current)
      if (success) {
        message.success('Report copied to clipboard.')
      } else {
        message.error('Error copying report to clipboard.')
      }
    }
  }, [tableContainerRef])

  return (
    <ReportStatisticsPage
      loading={loading}
      projects={projects}
      users={users}
      sprints={sprints}
      selectedProject={selectedProject}
      selectedUserID={selectedUserID}
      selectedSprints={selectedSprints}
      reportType={reportType}
      startDate={startDate}
      endDate={endDate}
      report={report}
      onChangeSelectedUserID={reportStatisticsService.setUser}
      onChangeProject={reportStatisticsService.setProject}
      onChangeSelectedSprintIDs={reportStatisticsService.setSprints}
      onChangeReportType={reportStatisticsService.setReportType}
      onChangeDates={reportStatisticsService.setDates}
      onResetFilters={reportStatisticsService.clearFilters}
      showFiltersPanel={showFiltersPanel}
      onToggleFiltersPanel={reportStatisticsService.setShowFiltersPanel}
      tableContainerRef={tableContainerRef}
      onCopyReport={onCopyReport}
    />
  )
}

interface ReportStatisticsPageProps {
  loading: boolean
  users: User[]
  projects: Project[]
  sprints: Sprint[]
  selectedUserID: ID | null
  selectedProject: Project | null
  selectedSprints: ID[]
  startDate: Date | null
  endDate: Date | null
  reportType: ReportType
  report: ReportStatistics | null
  customTitle?: string
  onChangeSelectedUserID: (userID: ID | null) => void
  onChangeProject: (project: Project | null) => void
  onChangeSelectedSprintIDs: (sprintIDs: ID[]) => void
  onChangeReportType: (reportType: ReportType | null) => void
  onChangeDates: (dates: DateRange) => void
  onResetFilters: () => void
  showFiltersPanel: boolean
  onToggleFiltersPanel: (value: boolean) => void
  tableContainerRef: Ref<HTMLDivElement>
  onCopyReport: () => void
}

function getCustomTitle(reportType: ReportType) {
  switch (reportType) {
    case ReportTypeEnum.T_SHIRT:
      return 'Average spent time by sizes'
    default:
      return ''
  }
}

export function ReportStatisticsPage(props: ReportStatisticsPageProps) {
  const { onToggleFiltersPanel } = props
  const onShowFiltersPanel = useCallback(
    () => onToggleFiltersPanel(true),
    [onToggleFiltersPanel]
  )

  const ReportComponent = statsComponents[props.reportType]

  return (
    <Layout className={'report-page'}>
      <Header title="Statistics" />
      <Layout.Content style={{ display: 'flex', flex: '1 1 0' }}>
        <ReportStatisticsFiltersList
          show={props.showFiltersPanel}
          users={props.users}
          projects={props.projects}
          sprints={props.sprints}
          selectedUserID={props.selectedUserID}
          selectedProject={props.selectedProject}
          selectedSprints={props.selectedSprints}
          reportType={props.reportType}
          startDate={props.startDate}
          endDate={props.endDate}
          onChangeProject={props.onChangeProject}
          onChangeSelectedUserID={props.onChangeSelectedUserID}
          onChangeSelectedSprintIDs={props.onChangeSelectedSprintIDs}
          onChangeReportType={props.onChangeReportType}
          onChangeDates={props.onChangeDates}
          onResetFilters={props.onResetFilters}
        />
        <CustomScrollbar
          className="report-page__main"
          theme="light"
          rendererHeight="auto"
          contentStyle={{
            height: 'auto',
            display: 'flex',
            minHeight: '100%',
          }}
        >
          <Layout.Content style={{ flex: '1 1 0' }}>
            <Layout.Content
              className={'report-page__content'}
              style={{ backgroundColor: 'var(--color-background)' }}
            >
              <ReportHeader
                project={props.selectedProject}
                startDate={props.startDate}
                endDate={props.endDate}
                showFiltersPanel={props.showFiltersPanel}
                onToggleFiltersPanel={props.onToggleFiltersPanel}
                copyReportButtonActive={!!props.selectedProject}
                customTitle={getCustomTitle(props.reportType)}
                onCopyReport={props.onCopyReport}
              />
              {props.loading ? (
                <Spinner />
              ) : props.report ? (
                <ReportComponent
                  report={props.report}
                  tableContainerRef={props.tableContainerRef}
                />
              ) : (
                <ReportApplyFiltersText onClick={onShowFiltersPanel} />
              )}
            </Layout.Content>
          </Layout.Content>
        </CustomScrollbar>
      </Layout.Content>
    </Layout>
  )
}
