import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ID } from '@datorama/akita'
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd'
import classNames from 'classnames'
import { SmallDashOutlined } from '@ant-design/icons'

import {
  Subtask,
  SubtaskEstimate,
  SubtaskType,
} from '../../../entities/subtask/model'
import { UserSelect } from '../controls/UserSelect'
import { SUBTASK_TYPES } from '../../../entities/choices/subtaskType'
import { ContentEditableComponent } from '../../../components/ContentEditableComponent'
import { SubtaskTypeSelect } from '../controls/SubtaskTypeSelect'
import { SubtaskEstimation } from './SubtaskEstimation'
import { SubtaskDescription } from './SubtaskDescription'
import { ModelUpdateCallback } from '../../../utils/types'
import { ShortUser, User } from '../../../entities/user/model'
import { SubtaskDetailsButton } from './SubtaskDetailsButton'

import '../styles/subtasks-subtask.scss'
import { StartTrackingCallback } from '../../../tracking/types'
import { SubtaskStatusSelect } from '../controls/SubtaskStatusSelect'
import {
  defaultStatusForType,
  statusesForType,
} from '../../../entities/choices/subtasksFilter'
import { TrackingState } from '../../../tracking/state/store'
import { IFormErrors } from '../../state/store'
import { isDocEmpty } from '../../../editor/core/utils'

export interface SubtaskListItemProps {
  users: User[] | ShortUser[]
  subtask: Subtask
  innerRef: React.Ref<HTMLDivElement>
  isDragging: boolean
  draggableProps?: DraggableProvidedDraggableProps
  dragHandleProps?: DraggableProvidedDragHandleProps
  onSubtaskUpdate: ModelUpdateCallback<Subtask>
  onSubtaskAddEstimate: (
    subtaskID: ID,
    estimate: Partial<SubtaskEstimate>
  ) => void
  trackingState?: TrackingState
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onSubtaskDelete: (id: ID) => void
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function SubtaskListItem(props: SubtaskListItemProps) {
  const [isUpd, setIsUpd] = useState(false)
  const [isChecking, setIsChecking] = useState(true)
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false)

  const {
    users,
    subtask,
    onSubtaskUpdate,
    onSubtaskAddEstimate,
    trackingState,
    onStartTracking,
    onStopTracking,
    onSubtaskDelete,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props

  const updateSubtask = useCallback(
    (update: Partial<Subtask>) => {
      onSubtaskUpdate({ id: subtask.id, ...update })
    },
    [onSubtaskUpdate, subtask.id]
  )

  const onSubtaskNameChange = useCallback(
    (value) => updateSubtask({ name: value }),
    [updateSubtask]
  )

  const onSubtaskDescriptionChange = useCallback(
    (value) => {
      updateSubtask({ json_description: value })
    },
    [updateSubtask]
  )

  const onDeveloperSelect = useCallback(
    (id) => updateSubtask({ assignee: id }),
    [updateSubtask]
  )

  const onSubtaskEstimateChange = useCallback(
    (estimate: Partial<SubtaskEstimate>) =>
      onSubtaskAddEstimate(subtask.id, { ...estimate }),
    [onSubtaskAddEstimate, subtask]
  )

  const subtaskClassNames = classNames(
    'subtasks__subtask',
    'subtasks-subtask',
    {
      'subtasks-subtask_is-done':
        subtask.status === 'done' ||
        subtask.is_done ||
        subtask.status === 'accepted',
      'subtasks-subtask_is-blocker': subtask.type === SubtaskType.BLOCKER,
      'subtasks-subtask_description-opened': isDescriptionOpen,
    }
  )

  const toggleDescriptionOpenState = () => {
    setIsDescriptionOpen(!isDescriptionOpen)
  }

  const subtaskWrapRef = useRef(null) as any
  const subtaskContainerRef = useRef(null) as any
  const subtaskInnerRef = useRef(null) as any
  const subtaskRightRef = useRef(null) as any
  const subtaskLeftRef = useRef(null) as any
  const detailsInnerRef = useRef(null) as any

  const subtaskContainerClasses = classNames('subtasks-subtask__container', {
    'is-upd': isUpd,
    'is-checking': isChecking,
  })

  const setChekingState = (state = true) => setIsChecking(state)

  const check = useCallback(() => {
    setChekingState(true)

    const subtaskInner = subtaskInnerRef.current
    const subtaskRight = subtaskRightRef.current
    const subtaskLeft = subtaskLeftRef.current
    const detailsButton = detailsInnerRef.current

    const { offsetLeft: left } = subtaskRight
    const { clientWidth: leftWidth, offsetLeft: leftOffset } = subtaskLeft
    const { offsetWidth: innerWidth } = subtaskInner
    const { scrollWidth: detailsButtonScrollWidth } = detailsButton

    const containerWidth =
      left - leftWidth - leftOffset - detailsButtonScrollWidth

    if (innerWidth < containerWidth) {
      setIsUpd(false)
    } else {
      setIsUpd(true)
    }

    setChekingState(false)
  }, [detailsInnerRef, subtaskInnerRef, subtaskLeftRef, subtaskRightRef])

  const checkSubtask = useCallback(() => {
    setTimeout(() => check(), 0)
  }, [check])

  useEffect(() => {
    check()
  }, [check])

  const subtaskStatusSelectItems = statusesForType(subtask.type)
  const defaultSubtaskSelectItem = defaultStatusForType(subtask.type)

  return (
    <div
      ref={props.innerRef}
      className={subtaskClassNames}
      {...props.draggableProps}
    >
      <div className={subtaskContainerClasses} ref={subtaskWrapRef}>
        <div
          ref={subtaskLeftRef}
          className="subtasks-subtask__col subtasks-subtask__left"
        >
          <div
            className="subtasks-subtask__drag-wrap"
            {...props.dragHandleProps}
          >
            <SmallDashOutlined className="subtasks-subtask__drag" />
          </div>
          <div className="subtasks-subtask__type-select">
            <SubtaskTypeSelect
              type={subtask.type}
              types={SUBTASK_TYPES}
              onSelect={(type) => updateSubtask({ type })}
            />
          </div>
          {subtask.code && (
            <span className="subtasks-subtask__bug-code">{subtask.code}</span>
          )}
        </div>
        <SubtaskListItemCenter
          subtask={subtask}
          detailsRef={detailsInnerRef}
          subtaskRef={subtaskInnerRef}
          containerRef={subtaskContainerRef}
          isDescriptionOpened={isDescriptionOpen}
          checkSubtask={checkSubtask}
          toggleDescription={toggleDescriptionOpenState}
          changeSubtaskNameHandler={onSubtaskNameChange}
        />
        <div
          ref={subtaskRightRef}
          className="subtasks-subtask__col subtasks-subtask__right"
        >
          {subtask.type !== SubtaskType.BLOCKER && (
            <SubtaskEstimation
              subtask={subtask}
              onSubtaskEstimateChange={onSubtaskEstimateChange}
              trackingState={trackingState}
              onStartTracking={onStartTracking}
              onStopTracking={onStopTracking}
              addTimeFormErrors={addTimeFormErrors}
              isAddTimeFormOpened={isAddTimeFormOpened}
              onOpenAddTimeModal={onOpenAddTimeModal}
              onCloseAddTimeModal={onCloseAddTimeModal}
            />
          )}
          <UserSelect
            users={users}
            userId={subtask.assignee}
            onlyAvatar={true}
            onSelect={onDeveloperSelect}
          />
          <SubtaskStatusSelect
            subtask={subtask}
            className="subtasks-subtask__status-select"
            status={subtask.status}
            statuses={subtaskStatusSelectItems}
            default={defaultSubtaskSelectItem}
            onSelect={(status) => updateSubtask({ status })}
            onSubtaskDelete={onSubtaskDelete}
          />
        </div>
      </div>
      <SubtaskDescription
        subtask={subtask}
        isOpen={isDescriptionOpen}
        placeholder="Add Description"
        transitionDuration={400}
        description={subtask.json_description}
        blurHandler={onSubtaskDescriptionChange}
      />
    </div>
  )
}

interface SubtaskListItemCenterProps {
  subtask: Subtask
  subtaskRef: React.Ref<HTMLDivElement>
  detailsRef: React.Ref<HTMLDivElement>
  containerRef: React.Ref<HTMLDivElement>
  isDescriptionOpened: boolean
  checkSubtask: () => void
  toggleDescription: () => void
  changeSubtaskNameHandler: (e: any) => void
}

export function SubtaskListItemCenter(props: SubtaskListItemCenterProps) {
  const {
    subtask,
    subtaskRef,
    detailsRef,
    containerRef,
    isDescriptionOpened,
    checkSubtask,
    toggleDescription,
    changeSubtaskNameHandler,
  } = props

  return (
    <div className="subtasks-subtask__center" ref={containerRef}>
      <div className="subtasks-subtask__inner" ref={subtaskRef}>
        <ContentEditableComponent
          className="subtasks-subtask__label"
          placeholder="Subtask Title"
          suppressEditableWarning={true}
          blurHandler={changeSubtaskNameHandler}
          changeHandler={checkSubtask}
        >
          {subtask.name}
        </ContentEditableComponent>
        <div className="subtasks-subtask__details-button-wrap">
          <SubtaskDetailsButton
            isOpen={isDescriptionOpened}
            innerRef={detailsRef}
            hasDescription={!isDocEmpty(subtask.json_description)}
            onClick={toggleDescription}
          />
        </div>
      </div>
    </div>
  )
}
