import { combineQueries, Query } from '@datorama/akita'
import { HistoryState, historyStore, HistoryStore } from './store'
import { map, switchMap } from 'rxjs/operators'
import {
  activityLogQuery,
  ActivityLogQuery,
} from '../../entities/activities/query'
import { commentQuery, CommentQuery } from '../../entities/comment/query'
import {
  TaskPanelQuery,
  taskPanelQuery,
} from '../../project-page/tasks/TaskPanel/state/query'
import { of } from 'rxjs'
import { sortBy } from 'lodash'
import {
  groupActivities,
  groupWorklog,
  HistoryActivityItemType,
  HistoryItem,
} from './model'
import { userQuery, UserQuery } from '../../entities/user/query'
import { subtaskQuery, SubtaskQuery } from '../../entities/subtask/query'
import { timeEntryQuery, TimeEntryQuery } from '../../entities/time-entry/query'
import { ActionType } from '../../entities/activities/model'

export class HistoryQuery extends Query<HistoryState> {
  /**
   * Activities, comments for task
   */
  taskHistory$ = this.taskPanelQuery.task$.pipe(
    switchMap((task) => {
      if (!task) {
        return of([])
      }

      const subtaskActivities$ = this.activityLogQuery.selectBySubTaskID(
        task.id
      )
      const taskActivities$ = this.activityLogQuery.selectByTaskID(task.id)

      const comments$ = this.commentQuery.forTask(task.id)

      const worklog$ = this.timeEntryQuery.forTask(task.id)
      const subtaskWorklog$ = this.timeEntryQuery.selectBySubTaskID(task.id)

      return combineQueries([
        taskActivities$,
        subtaskActivities$,
        comments$,
        worklog$,
        subtaskWorklog$,
      ]).pipe(
        map(
          ([
            taskActivities,
            subtaskActivities,
            comments,
            worklog,
            subtaskWorklogs,
          ]) => {
            const histories: HistoryItem[] = []
            const activities: ActionType[] = [
              ...taskActivities,
              ...subtaskActivities,
            ]
            const worklogs = [...worklog, ...subtaskWorklogs]

            groupActivities(activities).forEach((activity, index) => {
              const lastItem = activity[activity.length - 1]
              histories.push({
                id: `activity-${index}`,
                type: HistoryActivityItemType.ACTIVITY,
                date: lastItem.date,
                data: activity,
              })
            })

            comments.forEach((comment) => {
              histories.push({
                id: `${comment.id}-${comment.target.type}`,
                type: HistoryActivityItemType.COMMENT,
                date: comment.created_at,
                data: comment,
              })
            })

            const groupedWorklog = groupWorklog(worklogs)

            groupedWorklog.forEach((item, index) => {
              const lastItem = item.entries[item.entries.length - 1]
              histories.push({
                id: `${item.userId}-worklog-${index}`,
                type: HistoryActivityItemType.WORKLOG,
                date: lastItem.end,
                data: item,
              })
            })

            return sortBy(histories, 'date').reverse()
          }
        )
      )
    })
  )

  constructor(
    store: HistoryStore,
    private userQuery: UserQuery,
    private taskPanelQuery: TaskPanelQuery,
    private activityLogQuery: ActivityLogQuery,
    private commentQuery: CommentQuery,
    private subtaskQuery: SubtaskQuery,
    private timeEntryQuery: TimeEntryQuery
  ) {
    super(store)
  }
}

export const historyQuery = new HistoryQuery(
  historyStore,
  userQuery,
  taskPanelQuery,
  activityLogQuery,
  commentQuery,
  subtaskQuery,
  timeEntryQuery
)
