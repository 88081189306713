import './styles/project-select.scss'

import React, { useCallback } from 'react'
import { Icon, Menu } from '@blueprintjs/core'
import classNames from 'classnames'
import { ItemListRenderer, ItemRenderer, Suggest } from '@blueprintjs/select'
import { Empty } from 'antd'

import { Project, ProjectStatus } from '../../entities/project/model'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'

const ProjectsSuggest = Suggest.ofType<Project>()

interface ProjectsDropdownProps {
  projects: Project[]
  selectedProject: Project | null
  onChange: (project: Project | null) => void
}

export function ProjectSelect(props: ProjectsDropdownProps) {
  const { selectedProject, projects } = props

  const itemRenderer: ItemRenderer<Project> = useCallback(
    (project, { modifiers, handleClick }) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      const isSelectedProject = project.id === selectedProject?.id

      return (
        <li
          onClick={handleClick}
          key={project.name}
          className={classNames('bp3-menu-item', {
            'bp3-active': isSelectedProject,
          })}
        >
          <div className="project-select__list-content text-trim-ellipsis">
            <span className="project-select__list-abbr">{project.code}</span>
            {' | '}
            <span className="project-select__list-full">{project.name}</span>
          </div>
        </li>
      )
    },
    [selectedProject]
  )

  const itemListRenderer: ItemListRenderer<Project> = ({
    items,
    renderItem,
    itemsParentRef,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item !== null)
    if (renderedItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          <li key="no_results">
            <Empty
              imageStyle={{
                height: 60,
              }}
              description="No Results"
            />
          </li>
        </Menu>
      )
    }
    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={200}
          translateContentSizesToHolder={true}
        >
          <li
            key="clear"
            onClick={() => props.onChange(null)}
            className="bp3-menu-item"
          >
            <span className="project-select__list-abbr">
              <i>Clear filter</i>
            </span>
          </li>
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  const inputValueRenderer = (project: Project) => {
    return `${project.code} | ${project.name}`
  }

  const searchIcon = (
    <Icon
      className="project-select__icon-search"
      tagName="div"
      icon="chevron-down"
      iconSize={12}
      color={'var(--color-text-inactive)'}
      style={{ paddingRight: '9px', paddingTop: '9px' }}
    />
  )

  const itemPredicate = (query: string, project: Project) => {
    const queryLower = query.toLowerCase()
    if (project.status === ProjectStatus.CLOSED) {
      return project.name.toLowerCase() === queryLower
    }
    return (
      project.code.toLowerCase().indexOf(queryLower) >= 0 ||
      project.name.toLowerCase().indexOf(queryLower) >= 0
    )
  }

  return (
    <div className="project-select">
      <ProjectsSuggest
        itemListRenderer={itemListRenderer}
        inputValueRenderer={inputValueRenderer}
        items={projects}
        selectedItem={selectedProject}
        itemRenderer={itemRenderer}
        itemPredicate={itemPredicate}
        onItemSelect={props.onChange}
        popoverProps={{
          minimal: true,
          position: 'bottom',
          popoverClassName: 'popover project-select-popover',
          usePortal: true,
          wrapperTagName: 'div',
          targetTagName: 'div',
          fill: true,
          boundary: 'window',
        }}
        inputProps={{
          rightElement: searchIcon,
          fill: true,
          placeholder: 'Select Project...',
        }}
        resetOnQuery={true}
        resetOnSelect={true}
      />
    </div>
  )
}
