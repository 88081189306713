import { ID } from '@datorama/akita'
import { boundMethod } from 'autobind-decorator'

import { epicPanelStore, EpicPanelStore } from './store'
import { HistoryActivityItemType } from '../../../../../history/state/model'
import { arrayToggle } from '../../../../../utils/arrayToggle'

export class EpicPanelService {
  constructor(private store: EpicPanelStore) {}

  setActive(id: ID | null) {
    this.store.update({ epicId: id })
  }

  @boundMethod
  updateActivityFilter(choice: HistoryActivityItemType) {
    this.store.update(({ activitiesFilter }) => ({
      activitiesFilter: arrayToggle(activitiesFilter, choice),
    }))
  }

  @boundMethod
  setShowTasksInFinishedSprints(id: ID) {
    this.store.update(({ showTasksInFinishedSprints }) => {
      const toggled = arrayToggle(showTasksInFinishedSprints, id)
      localStorage.setItem(
        'showTasksInFinishedSprints',
        JSON.stringify({ toggled })
      )
      return { showTasksInFinishedSprints: toggled }
    })
  }
}

export const epicPanelService = new EpicPanelService(epicPanelStore)
