import { ID } from '@datorama/akita'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone/moment-timezone'
import { Task } from '../task/model'

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
}

export const UserRoles: UserRole[] = [UserRole.USER, UserRole.ADMIN]

export interface ShortUser {
  id: ID
  full_name: string
  email: string
  avatar: string
  color: string
  user_role: UserRole
  is_active: boolean
  is_invited: boolean
  is_external: boolean
  projects: ID[]
}

export const shortUserKeys: (keyof ShortUser)[] = [
  'id',
  'full_name',
  'email',
  'avatar',
  'color',
  'user_role',
  'is_active',
  'is_external',
  'is_invited',
  'projects',
]

export type UserTheme = 'light' | 'dark' | 'system'

export interface User extends ShortUser {
  private_email?: string
  private_contacts: {
    phone?: string
    skype?: string
    telegram?: string
    viber?: string
    facebook?: string
    linkedin?: string
    emergency?: string
    [key: string]: string | undefined
  }
  location: string | null
  birthday: string | null
  about_me: string | null
  user_permissions: string[]
  groups: number[]
  slack_id: string | null
  slack_name: string | null
  smartsuite_key?: string
  workspaces: number[]
  timezone: string
  invited_at: string | null
  start_date: string | null
  last_seen: string | null
  hours_worked: number
  theme: UserTheme
  [key: string]: any
}

export const ALL_USERS_ID = -1

export const UNASSIGNED_ID = -2

export const ALL_USERS = {
  id: ALL_USERS_ID,
  full_name: 'Show All',
  is_active: true,
} as User

export const UNASSIGNED = {
  id: UNASSIGNED_ID,
  full_name: 'Unassigned',
  color: 'var(--color-text-inactive)',
  is_active: true,
} as User

export interface UserInvite {
  email: User['email']
  full_name: User['full_name']
  user_role: User['user_role']
  projects: User['projects']
  workspaces: User['workspaces']
}

export interface ChangePasswordData {
  current_password: string
  new_password: string
}

export enum UserStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
  EXTERNAL = 'external',
  INACTIVE = 'inactive',
}

export const getUserStatuses = (user: User): UserStatus[] => {
  return _.compact([
    user.is_active && !user.is_external ? UserStatus.ACTIVE : null,
    !user.is_active ? UserStatus.INACTIVE : null,
    user.is_invited && user.is_active ? UserStatus.INVITED : null,
    user.is_external ? UserStatus.EXTERNAL : null,
  ])
}

export const getUserStatus = (user: User): UserStatus => {
  if (user.is_invited) {
    return UserStatus.INVITED
  }
  return user.is_active ? UserStatus.ACTIVE : UserStatus.INACTIVE
}

export const convertUserStatusToFlags = (status: UserStatus): Partial<User> => {
  if (status === UserStatus.INVITED) {
    return {
      is_active: true,
      is_invited: true,
    }
  }
  return {
    is_active: status === UserStatus.ACTIVE,
    is_invited: false,
  }
}

export const getLocalTime = (user: User): Moment => {
  return moment().tz(user.timezone)
}

export const nameSortFn = (a: User | ShortUser, b: User | ShortUser) => {
  const first_value = a.full_name || a.email
  const second_value = b.full_name || b.email
  if (first_value.toLowerCase() < second_value.toLowerCase()) {
    return -1
  }
  if (first_value.toLowerCase() > second_value.toLowerCase()) {
    return 1
  }
  return 0
}

export const booleanKeySortFn = (
  a: User | ShortUser,
  b: User | ShortUser,
  key: keyof (User | ShortUser)
) => {
  return a[key] === b[key] ? 0 : a[key] ? -1 : 1
}

export const booleanKeyReverseSortFn = (
  a: User | ShortUser,
  b: User | ShortUser,
  key: keyof (User | ShortUser)
) => {
  return a[key] === b[key] ? 0 : a[key] ? 1 : -1
}

export const sortAlphabetically = (users: User[] | ShortUser[]) => {
  return users.sort(function (a, b) {
    return nameSortFn(a, b)
  })
}

export const convertToShortUser = (user: User) => {
  return _.pick(user, shortUserKeys)
}

/**
 * Tells if user should be shown in task assignee dropdown
 */
export const isValidAssignee = (user: User | ShortUser, task: Task) => {
  return (
    (user.is_active && user.projects.includes(task.project)) ||
    user.id === task.assignee
  )
}
