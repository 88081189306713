import '../../styles/user-profile.scss'

import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Observable } from 'rxjs'
import { ID } from '@datorama/akita'
import { message } from 'antd'
import { ModelUpdateCallback } from '../../../utils/types'
import { ChangePasswordData, User } from '../../../entities/user/model'
import { Project } from '../../../entities/project/model'
import { UserProfileAboutMe } from './UserProfileAboutMe'
import { UserProfilePrivateContacts } from './UserProfilePrivateContacts'
import { UserProfilePersonalDetails } from './UserProfilePersonalDetails'
import { UserProfileMain } from './UserProfileMain'
import { InviteUserButtons } from './InviteUserButtons'
import { UserProfileIntegrations } from './UserProfileIntegrations'
import { UserProfilePreferences } from './UserProfilePreferences'

interface UserProfileProps {
  user: User
  allProjects: Project[]
  isCurrentUser: boolean
  adminView: boolean
  onInviteResend: (user: User) => Observable<object>
  onInviteCancel: (user: User) => Observable<object>
  onSuccessCancelInvite: () => void
  onUserUpdate: ModelUpdateCallback<User>
  onUploadAvatar: (userId: ID, file: File) => void
  onProjectsChange: (user: User, projects: Project[]) => void
  onChangePassword: (data: ChangePasswordData) => Observable<void>
}

export function UserProfile(props: UserProfileProps) {
  const { user, onInviteResend, onInviteCancel, onSuccessCancelInvite } = props
  const { is_active, is_invited } = user

  const profileClassNames = useMemo(
    () =>
      classNames('card', 'user-profile', {
        'user-profile_inactive': !is_active,
        'user-profile_active': is_active,
        'user-profile_invited': is_invited,
      }),
    [is_active, is_invited]
  )
  const displayDetailsAndContacts = props.adminView || !is_invited

  const [inviteActionPending, setInviteActionPending] = useState<boolean>(false)

  const onInviteResendClick = useCallback(() => {
    setInviteActionPending(true)
    onInviteResend(user).subscribe(
      () => {
        message.success('Invite has been successfully sent')
        setInviteActionPending(false)
      },
      () => {
        message.error('Error sending invite')
        setInviteActionPending(false)
      }
    )
  }, [user, onInviteResend])

  const onInviteCancelClick = useCallback(() => {
    setInviteActionPending(true)
    onInviteCancel(user).subscribe(
      () => {
        message.success('Invitation has been cancelled')
        setInviteActionPending(false)
        onSuccessCancelInvite()
      },
      () => {
        message.error('Error cancelling invite')
        setInviteActionPending(false)
      }
    )
  }, [user, onInviteCancel, onSuccessCancelInvite])

  return (
    <div className="card-wrap">
      <div className={profileClassNames}>
        <div className="user-profile__content">
          <UserProfileMain
            adminView={props.adminView}
            isCurrentUser={props.isCurrentUser}
            user={props.user}
            allProjects={props.allProjects}
            onUserUpdate={props.onUserUpdate}
            onUploadAvatar={props.onUploadAvatar}
            onProjectsChange={props.onProjectsChange}
            onChangePassword={props.onChangePassword}
          />
          {displayDetailsAndContacts && (
            <>
              <UserProfilePersonalDetails
                user={props.user}
                editable={props.isCurrentUser || props.adminView}
                onUserUpdate={props.onUserUpdate}
              />
              <UserProfilePrivateContacts
                user={props.user}
                editable={props.isCurrentUser || props.adminView}
                onUserUpdate={props.onUserUpdate}
              />
              <UserProfileIntegrations
                user={props.user}
                editable={props.isCurrentUser || props.adminView}
                onUserUpdate={props.onUserUpdate}
              />
              <UserProfileAboutMe
                about_me={props.user.about_me || ''}
                editable={props.isCurrentUser || props.adminView}
                onChange={(about_me) =>
                  props.onUserUpdate({ id: props.user.id, about_me })
                }
              />
              <UserProfilePreferences
                user={props.user}
                editable={props.isCurrentUser || props.adminView}
                onUserUpdate={props.onUserUpdate}
              />
            </>
          )}
        </div>
        {props.user.is_invited && props.adminView && (
          <InviteUserButtons
            showCancelButton={props.user.is_active}
            disabled={inviteActionPending}
            onInviteResend={onInviteResendClick}
            onInviteCancel={onInviteCancelClick}
          />
        )}
      </div>
    </div>
  )
}
