import { ID } from "@datorama/akita"

import { BaseAttachmentProvider } from "../extentions/attacment/provider"
import { uploadFile } from "./utils/upload-file"

export class AttachmentProvider extends BaseAttachmentProvider {
  constructor(private taskId: ID) { super() }

  uploadFile = (file: File, onSuccess: (url: string, preview_url?: string) => void, onError: () => void) => {
    uploadFile(this.taskId, file, onSuccess, onError)
  }
}
