import React, { useCallback } from 'react'
import { FilterOutlined } from '@ant-design/icons'
import classNames from 'classnames'

interface UsersListFilterButtonProps {
  showFiltersPanel: boolean
  setShowFiltersPanel: (show: boolean) => void
}

export function UsersListFilterButton(props: UsersListFilterButtonProps) {
  const { showFiltersPanel, setShowFiltersPanel } = props

  const toggleButton = useCallback(
    () => setShowFiltersPanel(!showFiltersPanel),
    [showFiltersPanel, setShowFiltersPanel]
  )

  const classes = classNames('users-list__filter', {
    'is-active': showFiltersPanel,
  })

  return (
    <div onClick={toggleButton} className={classes}>
      <FilterOutlined
        className="users-list__filter-icon"
        style={{ color: 'var(--color-text-link)' }}
      />
    </div>
  )
}
