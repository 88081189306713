import { arrayAdd, ID } from '@datorama/akita'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { boundMethod } from 'autobind-decorator'
import { UserStore, userStore } from './store'
import { UserDataService, userDataService } from './data-service'
import { ChangePasswordData, User } from './model'
import { ModelUpdate } from '../../utils/types'
import { messageDispatcher } from '../../utils/message-dispatcher'

export class UserService {
  constructor(private store: UserStore, private dataService: UserDataService) {}

  loadUser(id: ID) {
    this.dataService.get(id).subscribe((user) => {
      this.store.add(user)
    })
  }

  loadUsers(query?: object) {
    this.store.setLoading(true)
    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
          this.store.update({ loaded: true })
        })
      )
      .subscribe((users) => {
        this.store.add(users)
      })
  }

  @boundMethod
  updateUser(update: ModelUpdate<User>) {
    // is this optimistic update?
    this.store.update(update.id, (user) => {
      if (update.private_contacts) {
        update.private_contacts = {
          ...user.private_contacts,
          ...update.private_contacts,
        }
      }
      return {
        ...user,
        ...update,
      }
    })
    this.dataService.patch(update).subscribe(() => {
      if (!!this.store.getValue().entities) {
        const user = this.store.getValue().entities![update.id]
        if (!!user) {
          if (update.hasOwnProperty('is_active')) {
            const name = !!user.full_name ? user.full_name : user.email
            if (update.is_active) {
              messageDispatcher.putSuccessMessage(
                `The user ${name} has been activated`
              )
            } else {
              messageDispatcher.putSuccessMessage(
                `The user ${name} has been deactivated`
              )
            }
          } else if (update.hasOwnProperty('user_role')) {
            messageDispatcher.putSuccessMessage(
              `Member's access level has been changed to ${update.user_role}`
            )
          }
        }
      }
    })
  }

  @boundMethod
  cancelInvite(user: User): Observable<User> {
    const observable = this.dataService.cancelInvite(user)
    observable.subscribe({
      next: (updatedUser) => {
        this.store.update(user.id, (user: User) => {
          return {
            ...user,
            ...updatedUser,
          }
        })
      },
    })
    return observable
  }

  @boundMethod
  resendInvite(user: User): Observable<User> {
    const observable = this.dataService.resendInvite(user)
    observable.subscribe({
      next: (updatedUser) => {
        this.store.update(user.id, (user: User) => {
          return {
            ...user,
            ...updatedUser,
          }
        })
      },
    })
    return observable
  }

  @boundMethod
  changePassword(data: ChangePasswordData): Observable<void> {
    const observable = this.dataService.changePassword(data)
    observable.subscribe({
      next: () =>
        messageDispatcher.putSuccessMessage(
          'Password has been successfully changed'
        ),
    })
    return observable
  }

  appendUser(user: User) {
    this.store.upsert(user.id, user)
  }

  @boundMethod
  uploadAvatar(userId: ID, file: File) {
    this.dataService.uploadAvatar(userId, file).subscribe((value) => {
      this.store.update(value.id, value)
    })
  }

  updateProjects(userId: ID, projectId: ID) {
    this.store.update(userId, (user) => {
      return {
        ...user,
        projects: arrayAdd(user.projects, projectId),
      }
    })
  }
}

export const userService = new UserService(userStore, userDataService)
