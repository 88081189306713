import './styles/dashboard-header.scss'

import React from 'react'
import { Typography } from 'antd'
import { DashboardAddProject } from './DashboardAddProject'
import { Observable } from 'rxjs'
import { User } from '../entities/user/model'
import { Project } from '../entities/project/model'

const { Title } = Typography

interface DashboardHeaderProps {
  users: User[]
  onAddProject: (data: Partial<Project>) => Observable<Project>
  isAdminView: boolean
}

export function DashboardHeader(props: DashboardHeaderProps) {
  return (
    <div className="dashboard-page__header dashboard-header">
      <Title level={2} className={'dashboard-page__title'}>
        Select a Project to Get Started
      </Title>
      {props.isAdminView && (
        <DashboardAddProject
          classModifier={'dashboard-page__add-project-btn_small'}
          users={props.users}
          onAddProject={props.onAddProject}
        />
      )}
    </div>
  )
}
