import React from 'react'
import { DateRange } from '@blueprintjs/datetime'
import classNames from 'classnames'
import { ID } from '@datorama/akita'
import { User } from '../../entities/user/model'
import { FilterListUsers } from '../../filters-list/FilterListUsers'
import { FiltersListDateRange } from '../../filters-list/FiltersListDateRange'
import { FiltersListProjects } from '../../filters-list/FiltersListProjects'
import { Project } from '../../entities/project/model'
import { FiltersListResetButton } from '../../filters-list/FiltersListResetButton'
// import {
//   CheckboxChoice,
//   FilterListCheckboxes,
// } from '../../filters-list/FilterListCheckboxes'

// const DEFAULT_ROLES: CheckboxChoice[] = [
//   {
//     value: 'developer',
//     label: 'Developer',
//   },
//   {
//     value: 'manager',
//     label: 'Manager',
//   },
//   {
//     value: 'qa',
//     label: 'QA',
//   },
// ]

interface ReportProjectFiltersListProps {
  show: boolean
  users: User[]
  selectedUsersIDs: ID[]
  projects: Project[]
  selectedProject: Project | null
  startDate: Date | null
  endDate: Date | null
  onChangeProject: (project: Project | null) => void
  onChangeSelectedUsersIDs: (usersIDs: ID[]) => void
  onChangeDates: (dates: DateRange) => void
  onResetFilters: () => void
}

export function ReportProjectFiltersList(props: ReportProjectFiltersListProps) {
  const className = classNames(['filters-list-wrap'], {
    'is-expanded': props.show,
  })
  return (
    <div className={className}>
      <div className="filters-list">
        <FiltersListProjects
          projects={props.projects}
          selectedProject={props.selectedProject}
          onSelectProject={props.onChangeProject}
        />
        <FilterListUsers
          noUnassignedUser={true}
          users={props.users}
          selectedUsersIDs={props.selectedUsersIDs}
          onChangeSelectedUsersIDs={props.onChangeSelectedUsersIDs}
          emptyListLabel={'Please select a Project to see a list of Users'}
          noUsersSelectedPlaceholder={'Enter User Name...'}
        />

        {/* <FilterListCheckboxes items={DEFAULT_ROLES} onChange={() => {}} /> */}
        <FiltersListDateRange
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChangeDates}
        />
      </div>
      <FiltersListResetButton onClick={props.onResetFilters} />
    </div>
  )
}
