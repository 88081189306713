import { applyTransaction, ID } from '@datorama/akita'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { taskTypeStore, TaskTypeStore } from './store'
import { taskTypeDataService, TaskTypeDataService } from './data-service'
import { TaskType } from './model'
import { TaskTypeQuery, taskTypeQuery } from './query'

export class TaskTypeService {
  constructor(
    private store: TaskTypeStore,
    private query: TaskTypeQuery,
    private dataService: TaskTypeDataService
  ) {}

  setActive(id: ID) {
    this.store.setActive(id)
  }

  /**
   * Create new taskType
   * @param taskType
   */
  createTaskType(taskType: Partial<TaskType>) {
    const result = new Subject<TaskType>()

    this.dataService.create(taskType).subscribe(
      (taskType) => {
        this.store.add(taskType)
        result.next(taskType)
        result.complete()
      },
      (error) => {
        result.error(error)
      }
    )

    return result.asObservable()
  }

  loadTaskTypes(query?: object) {
    this.store.setLoading(true)
    this.dataService
      .list(query)
      .pipe(
        finalize(() => {
          this.store.setLoading(false)
        })
      )
      .subscribe((taskTypes) => {
        applyTransaction(() => {
          this.store.add(taskTypes)
        })
      })
  }
}

export const taskTypeService = new TaskTypeService(
  taskTypeStore,
  taskTypeQuery,
  taskTypeDataService
)
