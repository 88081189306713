import { Query } from '@datorama/akita'
import { WorkspacePageState, workspacePageStore, WorkspacePageStore } from './store'
import {combineLatest} from "rxjs";
import {map} from "rxjs/operators";
import {ProjectQuery, projectQuery} from "../../entities/project/query";

export class WorkspacePageQuery extends Query<WorkspacePageState> {

  statusFilter$ = this.select('statusFilters')

  filteredProjects$ = combineLatest([
    this.projectQuery.userProjects$,
    this.statusFilter$,
  ]).pipe(
    map(([projects, statusFilter]) => {
      return projects.filter((project) => statusFilter.includes(project.status))
    })
  )

  constructor(
    protected store: WorkspacePageStore,
    private projectQuery: ProjectQuery
  ) {
      super(store)
  }
}

export const workspacePageQuery = new WorkspacePageQuery(workspacePageStore, projectQuery)
