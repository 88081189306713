import classNames from 'classnames'
import React from 'react'
import './styles/project-page-filter-title.scss'

interface ProjectPageFilterTitleProps {
  className?: string
  children: React.ReactNode
}

export function ProjectPageFilterTitle(props: ProjectPageFilterTitleProps) {
  const classes = classNames('project-page-filter-title', props.className)
  return <div className={classes}>{props.children}</div>
}
