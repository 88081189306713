import {
  ActiveState,
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita'
import { ProjectPage } from '../model'
import { DefaultTaskStatuses, TaskStatus } from '../../entities/task/model'
import {
  DefaultSprintStatuses,
  SprintStatus,
} from '../../entities/sprint/model'
import { DefaultEpicStatuses, EpicStatus } from '../../entities/epic/model'
import { HistoryActivityItemType } from '../../history/state/model'
import { parseLocalStorage } from '../utils'

export interface IFormErrors {
  [name: string]: string
}

export interface ProjectPageState
  extends EntityState<ProjectPage, ID>,
    ActiveState {
  project: ID | null
  epicsVisible: boolean
  sprintsVisible: boolean
  backlogVisible: boolean
  tasksFilter: {
    statuses: TaskStatus[]
    assignee: ID[]
    task_type: ID[]
  }
  sprintsFilter: {
    statuses: SprintStatus[]
  }
  epicsFilter: {
    statuses: EpicStatus[]
  }
  activitiesFilter: HistoryActivityItemType[]
  createEpicFormErrors: IFormErrors | null
  createSprintFormErrors: IFormErrors | null
  createEpicFormIsOpened: boolean
  createSprintFormIsOpened: boolean
}

export const projectPageInitialState = {
  project: null,
  epicsVisible: true,
  sprintsVisible: true,
  backlogVisible: false,
  expandedEpics: [],
  collapsedSprints: [],
  epicsPaneCollapsed:
    parseLocalStorage('epicsPaneCollapsed', 'status') || false,
  sprintsPaneCollapsed:
    parseLocalStorage('sprintsPaneCollapsed', 'status') || false,
  myLastActivities: [],
  currentTeamActivities: [],
  tasksFilter: {
    statuses:
      parseLocalStorage('tasksFilter', 'statuses') || DefaultTaskStatuses,
    assignee: parseLocalStorage('tasksFilter', 'assignee') || [],
    task_type: parseLocalStorage('tasksFilter', 'task_type') || [],
  },
  sprintsFilter: {
    statuses:
      parseLocalStorage('sprintsFilter', 'statuses') || DefaultSprintStatuses,
  },
  epicsFilter: {
    statuses:
      parseLocalStorage('epicsFilter', 'statuses') || DefaultEpicStatuses,
  },
  activitiesFilter: parseLocalStorage('activitiesFilter', 'statuses') || [
    HistoryActivityItemType.ACTIVITY,
  ],
  createEpicFormErrors: null,
  createSprintFormErrors: null,
  createEpicFormIsOpened: false,
  createSprintFormIsOpened: false,
}

@StoreConfig({ name: 'ui-project-page', idKey: 'project', resettable: true })
export class ProjectPageStore extends EntityStore<ProjectPageState> {
  constructor() {
    super(projectPageInitialState)
  }
}

export const projectPageStore = new ProjectPageStore()
