import React from "react"

import { useReactNodeView } from "../../../core/react-node-view"
import { useProseMirrorContext } from "../../../core/provider"

export const Mention: React.FC = () => {
  const { node } = useReactNodeView()
  const { providers: { mentionProvider: provider } } = useProseMirrorContext()
  const { full_name } = provider.useMention({id: node?.attrs.id})
  const url = provider.useMentionUrl({id: node?.attrs.id})

  return <a href={url} target="_blank" rel="noopener noreferrer">{node?.attrs.prefix}{full_name}</a>
}
