import React from 'react'

export interface TaskPanelSectionTitleProps {
  children: React.ReactNode
}

export function TaskPanelSectionTitle(props: TaskPanelSectionTitleProps) {
  const { children } = props

  return <h2 className="task-panel__section-title">{children}</h2>
}
