import React from 'react'

import {Subtask, SubtaskStatus, SubtaskType} from '../../../entities/subtask/model'
import {
  SUBTASK_FILTER_TYPES, SUBTASK_FILTER_STATUSES
} from '../../../entities/choices/subtasksFilter'
import { SubtasksInitialEstimate } from './SubtasksInitialEstimate'
import { SubtasksHeaderEstimation } from './SubtasksHeaderEstimation'
import { SubtasksFilterPopover } from '../controls/SubtasksFilterPopover'

import '../styles/subtasks-header.scss'
interface SubtasksHeaderProps {
  subtasks: Subtask[]
  onFilterSubtasksByType: (value: SubtaskType) => void
  onFilterSubtasksByStatus: (value: SubtaskStatus) => void
  selectedSubtaskFilter?: {types: SubtaskType[], statuses: SubtaskStatus[]}
}

export function SubtasksHeader(props: SubtasksHeaderProps) {
  const {
    subtasks,
    onFilterSubtasksByType,
    onFilterSubtasksByStatus,
    selectedSubtaskFilter
  } = props

  const isInitialEstimateNotEqualsCurrentEstimate = subtasks.find(
    (subtask: Subtask) => subtask.initial_estimate !== subtask.current_estimate
  )

  let initialEstimatesSum = 0
  if (isInitialEstimateNotEqualsCurrentEstimate) {
    initialEstimatesSum = subtasks.reduce(
      (acc, curr) => acc + curr.initial_estimate,
      0
    )
  }

  return (
    <div className="subtasks-header">
      <SubtasksFilterPopover
        typeFilters={SUBTASK_FILTER_TYPES}
        statusFilters={SUBTASK_FILTER_STATUSES.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)}
        onFilterSubtasksByType={onFilterSubtasksByType}
        onFilterSubtasksByStatus={onFilterSubtasksByStatus}
        selectedSubtaskFilter={selectedSubtaskFilter}
      />
      <div className="subtasks-header__right">
        <SubtasksHeaderEstimation subtasks={subtasks} />
        {isInitialEstimateNotEqualsCurrentEstimate && (
          <SubtasksInitialEstimate initialEstimateSum={initialEstimatesSum} />
        )}
      </div>
    </div>
  )
}
