import { boundMethod } from 'autobind-decorator'
import { finalize } from 'rxjs/operators'
import { DateRange } from '@blueprintjs/datetime'
import moment from 'moment-timezone'
import { ID } from '@datorama/akita'
import { Project } from '../../../entities/project/model'
import { ReportTypeEnum, ReportType } from '../../../entities/report-statistics/model'
import {
  ReportStatisticsDataService,
  reportStatisticsPageDataService,
} from '../../../entities/report-statistics/data-service'
import { ReportStatisticsPageStore, reportStatisticsPageStore } from './store'

export class ReportStatisticsPageService {
  constructor(
    private store: ReportStatisticsPageStore,
    private dataService: ReportStatisticsDataService
  ) {}

  @boundMethod
  setReportType(reportType: ReportType | null) {
    this.store.update({ reportType: reportType })
  }

  @boundMethod
  setProject(project: Project | null) {
    this.store.update({
        selectedProject: project,
        selectedSprints: []
    })
  }

  @boundMethod
  setUser(userID: ID | null) {
    this.store.update({ selectedUserID: userID })
  }

  @boundMethod
  setSprints(sprintIDs: ID[]) {
    this.store.update({ selectedSprints: sprintIDs })
  }

  @boundMethod
  setDates(dates: DateRange) {
    this.store.update({ startDate: dates[0], endDate: dates[1] })
  }

  @boundMethod
  clearFilters() {
    this.store.reset()
  }

  resetReport() {
    this.store.update({ report: null })
  }

  fetchReport(
    reportType: ReportType | null,
    project: Project | null,
    userID: ID | null,
    sprintIDs: ID[],
    startDate: Date | null,
    endDate: Date | null,
  ) {
    this.store.setLoading(true)

    let extraParams = {}
    if (reportType === ReportTypeEnum.VELOCITY) {
      extraParams = {sprints: sprintIDs}
    } else {
      extraParams = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      }
    }

    this.dataService
      .get({
        report_type: reportType,
        project: project?.id,
        user: userID,
        ...extraParams,
      })
      .pipe(finalize(() => this.store.setLoading(false)))
      .subscribe((report) => {
        this.store.update({ report: report })
      })
  }

  @boundMethod
  setShowFiltersPanel(show: boolean) {
    this.store.update({ showFiltersPanel: show })
  }
}

export const reportStatisticsService = new ReportStatisticsPageService(
  reportStatisticsPageStore,
  reportStatisticsPageDataService
)
