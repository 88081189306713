import './styles/add-task-group-form.scss'

import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@blueprintjs/core'
import { Alert, Button } from 'antd'
import { IFormErrors } from '../state/store'

const MAX_LENGTH = 200

export interface AddTaskGroupFormProps {
  type: 'sprint' | 'epic'
  onClose: () => void
  onCreate: (value: string, order: number) => void
  formErrors: IFormErrors | null
  order?: number
}

export function AddTaskGroupForm(props: AddTaskGroupFormProps) {
  const [value, setValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [isTooltipShowing, setIsTooltipShowing] = useState(false)
  const timeout = useRef<any>(null)

  const clearTooltipTimeout = () => {
    clearTimeout(timeout.current)
    timeout.current = null
  }

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTooltipTimeout()
      }
    },
    []
  )
  useEffect(() => inputRef.current?.focus(), [])

  const onCreateHandler = (e: React.FormEvent) => {
    e.preventDefault()
    props.onCreate(value, props.order || 0)
    setValue('')
  }

  return (
    <>
      <form className="add-task-group-form" onSubmit={onCreateHandler}>
        <div className="add-task-group-form__input-wrap">
          <Tooltip
            targetTagName="div"
            wrapperTagName="div"
            isOpen={isTooltipShowing}
            content="You reached max length!"
          >
            <input
              value={value}
              onChange={(event) => {
                if (event.target.value.length >= MAX_LENGTH) {
                  setIsTooltipShowing(true)
                  timeout.current = setTimeout(
                    () => setIsTooltipShowing(false),
                    2000
                  )
                } else {
                  setIsTooltipShowing(false)
                  clearTooltipTimeout()
                  setValue(event.target.value)
                }
              }}
              placeholder={`${
                props.type === 'sprint' ? 'Sprint' : 'Epic'
              } name`}
              className="add-task-group-form__input"
              maxLength={MAX_LENGTH}
            />
          </Tooltip>
        </div>
        <div className="add-task-group-form__buttons">
          <Button
            className="add-task-group-form__button"
            type="primary"
            disabled={!value}
            htmlType="submit"
          >
            Add
          </Button>
          <Button
            className="add-task-group-form__button"
            onClick={props.onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
      {!!props.formErrors?.non_field_errors && (
        <Alert
          message={props.formErrors.non_field_errors}
          type="error"
          showIcon
        />
      )}
    </>
  )
}
