import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ID } from '@datorama/akita'
import { Button } from 'antd'
import { isEqual } from 'lodash'
import { MenuFoldOutlined } from '@ant-design/icons'
import { ShortUser, User } from '../../../entities/user/model'
import { Sprint } from '../../../entities/sprint/model'
import { Epic } from '../../../entities/epic/model'
import { Task } from '../../../entities/task/model'
import { ModelCreateCallback, ModelUpdateCallback } from '../../../utils/types'
import { useGroupBy } from '../../../utils/usegroupby'
import { SprintListItem } from './SprintListItem'
import {
  AddTimeManuallyCallback,
  StartTrackingCallback,
} from '../../../tracking/types'
import { AddTaskGroupForm } from '../AddTaskGroupForm'
import { VLIST_STYLES } from '../../virtual-scroll'
import { MoveItemCallback } from '../../drag-n-drop'
import { sprintService } from '../../../entities/sprint/service'
import { SORT_STEP } from '../../../utils/lists'
import { IFormErrors } from '../../state/store'
import { TaskType } from '../../../entities/task-type/model'
import { taskService } from '../../../entities/task/service'
import { SprintListActionsDropdown } from './SprintListActionsDropdown'

export interface SprintListProps {
  baseUrl?: string
  sprints: Sprint[]
  tasks: Task[]
  notFilteredTasks: Task[]
  epics: Epic[]
  taskTypes: TaskType[]
  collapsedSprints: ID[]
  currentTask?: Task | null
  currentSprint?: Sprint | null
  users: User[] | ShortUser[]
  onPaneToggle: () => void
  onToggleSprint: (id: ID) => void
  onCreateSprint: (sprint: Partial<Sprint>) => void
  onUpdateSprint: ModelUpdateCallback<Sprint>
  onReorderSprint: MoveItemCallback
  onTaskUpdate: ModelUpdateCallback<Task>
  onTaskCreate: ModelCreateCallback<Task>
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  onDestroySprint: (id: ID) => void
  formErrors: IFormErrors | null
  cleanupFormErrors: () => void
  createSprintFormIsOpened: boolean
  openCreateSprintFrom: () => void
  closeCreateSprintFrom: () => void
  collapseAllSprints: () => void
  collapseInactiveSprints: () => void
  currentUser?: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
  sprintsLoading?: boolean
  epicsLoading?: boolean
  tasksLoading?: boolean
  dragObject?: any
}

export function _SprintList(props: SprintListProps) {
  const {
    sprints,
    currentSprint,
    closeCreateSprintFrom,
    cleanupFormErrors,
    onCreateSprint,
    openCreateSprintFrom,
    collapseAllSprints,
    collapseInactiveSprints,
  } = props
  const epicTasks = useGroupBy(props.tasks, 'epic')
  const sprintTasks = useGroupBy(props.tasks, 'sprint')
  const notFilteredSprintTasks = useGroupBy(props.notFilteredTasks, 'sprint')

  const onCreateFormClose = useCallback(() => {
    closeCreateSprintFrom()
    cleanupFormErrors()
  }, [closeCreateSprintFrom, cleanupFormErrors])

  const onCreateFormOpen = useCallback(() => {
    openCreateSprintFrom()
    cleanupFormErrors()
  }, [openCreateSprintFrom, cleanupFormErrors])

  let lastSprintOrder = 0
  const lastSprint = sprints.slice(-1)[0]
  if (lastSprint) {
    lastSprintOrder = lastSprint['order'] + 1
  }

  const tasksWithoutEpic = epicTasks['null'] || []

  const createSprint = useCallback(
    (name: string, order: number) => {
      onCreateSprint({ name, order })
    },
    [onCreateSprint]
  )

  return (
    <div className="task-groups-list">
      <div className="task-groups-list__header">
        <h2>
          <MenuFoldOutlined
            className="task-groups-list__header__toggle"
            onClick={props.onPaneToggle}
          />{' '}
          Sprints
        </h2>

        <div className="task-groups-list__header-toolbar">
          <div className="task-groups-list__header-toolbar-button">
            <Button
              onClick={() => {
                const taskOrder = tasksWithoutEpic.length
                  ? tasksWithoutEpic.slice(-1)[0].sprint_order + SORT_STEP
                  : 0
                props.onTaskCreate({ name: '', sprint_order: taskOrder })
              }}
            >
              New Task
            </Button>
          </div>
          <div className="task-groups-list__header-toolbar-button">
            <Button onClick={onCreateFormOpen}>New Sprint</Button>
          </div>
          <SprintListActionsDropdown
            collapseAllSprints={collapseAllSprints}
            collapseInactiveSprints={collapseInactiveSprints}
          />
        </div>
      </div>

      <div className="task-groups-list__body">
        {props.createSprintFormIsOpened && (
          <AddTaskGroupForm
            type="sprint"
            onCreate={createSprint}
            onClose={onCreateFormClose}
            order={lastSprintOrder!}
            formErrors={props.formErrors}
          />
        )}

        <div>
          {sprints.map((item) => {
            return (
              <SprintListItem
                key={item.id}
                sprint={item}
                baseUrl={props.baseUrl}
                currentSprint={currentSprint}
                tasks={sprintTasks[item.id] || []}
                notFilteredTasks={notFilteredSprintTasks[item.id] || []}
                sprints={props.sprints}
                epics={props.epics || []}
                taskTypes={props.taskTypes}
                collapsed={props.collapsedSprints.includes(item.id)}
                currentTask={props.currentTask}
                users={props.users}
                dragObject={props.dragObject}
                onToggle={props.onToggleSprint}
                onTaskUpdate={props.onTaskUpdate}
                onTaskCreate={props.onTaskCreate}
                onStartTracking={props.onStartTracking}
                onStopTracking={props.onStopTracking}
                onAddTimeManually={props.onAddTimeManually}
                onDestroySprint={props.onDestroySprint}
                onUpdateSprint={props.onUpdateSprint}
                onDownloadSprint={sprintService.downloadSprint}
                onSortTasks={taskService.sortSprintTasks}
                onSortTasksByAssignee={taskService.sortSprintTasksByAssignee}
                currentUser={props.currentUser}
                onReorderSprint={props.onReorderSprint}
                style={VLIST_STYLES}
                addTimeFormErrors={props.addTimeFormErrors}
                isAddTimeFormOpened={props.isAddTimeFormOpened}
                onOpenAddTimeModal={props.onOpenAddTimeModal}
                onCloseAddTimeModal={props.onCloseAddTimeModal}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export function CreateSprintForm({
  onCreateSprint,
  onClose,
}: {
  onCreateSprint: (name: string) => void
  onClose: () => void
}) {
  const [name, setName] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => inputRef.current?.focus(), [])

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value)
  }

  return (
    <form onSubmit={() => onCreateSprint(name)}>
      <input required ref={inputRef} value={name} onChange={onChange} />
    </form>
  )
}

export const SprintList = React.memo(_SprintList, isEqual)
