import React, { useMemo } from 'react'
import { UserProfileSocialInput } from './UserProfileSocialInput'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import classNames from 'classnames'
import '../../styles/user-field.scss'
import { EditableTextValidator } from '../../../components/ValidatedEditableText'

interface UserProfileSocialInfoProps {
  label: string
  value: string
  editable: boolean
  onChange: (value: string) => void
  validator?: EditableTextValidator
}

export function UserProfileSocialInfo(props: UserProfileSocialInfoProps) {
  const { label } = props
  const classes = useMemo(
    () =>
      classNames(
        'user-profile__info',
        'user-info',
        `user-profile__info_${label}`
      ),
    [label]
  )
  if (!props.editable && !props.value) {
    return null
  }
  return (
    <div className={classes}>
      <UserProfileFieldTitle label={props.label} />
      <UserProfileSocialInput
        value={props.value}
        placeholder={'Enter account...'}
        editable={props.editable}
        onChange={props.onChange}
        validator={props.validator}
      />
    </div>
  )
}
