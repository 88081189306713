import React, { useCallback } from 'react'
import { Breadcrumb, Button, Empty } from 'antd'
import {
  Select,
  IItemRendererProps,
  ItemListRenderer,
  ItemRenderer,
} from '@blueprintjs/select'
import { Task } from '../../entities/task/model'
import { Project } from '../../entities/project/model'
import { Sprint } from '../../entities/sprint/model'
import { Epic } from '../../entities/epic/model'
import { ID } from '@datorama/akita'
import { selectChoicePredicate } from '../../utils/select'
import { IsBlockedBrick } from '../../components/IsBlockedBrick'
import classNames from 'classnames'
import '../../components/controls/styles/popover.scss'
import './styles/task-panel-breadcrumbs.scss'
import '../../assets/scss/select.scss'
import './styles/breadcrumb-select.scss'
import { EpicSelect } from '../../components/controls/EpicSelect'
import { SprintSelect } from '../../components/controls/SprintSelect'
import { CustomScrollbar } from '../../components/custom-scrollbar/CustomScrollbar'
import { Menu } from '@blueprintjs/core'
import { formatDate } from '../../utils/date'
import { useQuery } from '../../utils/queryhook'
import { taskPanelQuery } from './TaskPanel/state/query'

const TypedBreadcrumbProjectSelect = Select.ofType<Project>()

export function TaskBreadcrumbs({
  task,
  projects,
  sprints,
  filteredEpics,
  filteredSprints,
  isEpicsOrSprintsCollapsed,
  onProjectSelect,
  onSprintSelect,
  onEpicSelect,
}: {
  task: Task
  projects: Project[]
  sprints: Sprint[]
  filteredEpics: Epic[]
  filteredSprints: Sprint[]
  isEpicsOrSprintsCollapsed: boolean
  onProjectSelect: (project: ID) => void
  onSprintSelect: (sprint: ID) => void
  onEpicSelect: (epic: ID) => void
}) {
  const project = projects.find((p) => p.id === task.project)
  const sprint = sprints.find((s) => s.id === task.sprint)
  const epic = useQuery(taskPanelQuery.epic$)
  const disabled = task.is_readonly

  const renderProject: ItemRenderer<Project> = useCallback(
    (project: Project, { modifiers, handleClick }: IItemRendererProps) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      return (
        <li
          onClick={handleClick}
          key={project.name}
          className={classNames('bp3-menu-item', {
            'bp3-active': modifiers.active,
          })}
        >
          <div className="dropdown__list-content bp3-text-overflow-ellipsis bp3-fill">
            <span className="dropdown__list-abbr">{project.code}</span>
            {' | '}
            <span className="dropdown__list-full">{project.name}</span>
          </div>
        </li>
      )
    },
    []
  )

  const itemListRenderer: ItemListRenderer<Project> = ({
    items,
    renderItem,
    itemsParentRef,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item !== null)

    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          rendererMaxHeight={400}
          translateContentSizeYToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  const breadcrumbsSelectClasses = classNames('breadcrumb-select', {
    'breadcrumb-select_extended': isEpicsOrSprintsCollapsed,
  })

  const projectButtonClasses = classNames('breadcrumb-select__button', {
    'breadcrumb-select__button_disabled': disabled,
  })

  return (
    <div className="task-panel-breadcrumbs">
      <Breadcrumb className="task-panel-breadcrumbs__wrap">
        <Breadcrumb.Item>
          <TypedBreadcrumbProjectSelect
            className="breadcrumb-select"
            disabled={disabled}
            items={projects}
            itemListRenderer={itemListRenderer}
            itemRenderer={renderProject}
            itemPredicate={selectChoicePredicate('name')}
            onItemSelect={(project) => onProjectSelect(project.id)}
            noResults={
              <Empty imageStyle={{ height: 60 }} description={'No Results'} />
            }
            popoverProps={{
              minimal: true,
              position: 'bottom',
              portalClassName: 'breadcrumbs-portal',
              popoverClassName:
                'popover popover_with-search breadcrumbs-popover',
            }}
            activeItem={project}
          >
            <Button className={projectButtonClasses}>
              {project?.code || 'Projects'}
            </Button>
          </TypedBreadcrumbProjectSelect>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <EpicSelect
            disabled={disabled}
            className={breadcrumbsSelectClasses}
            portalClassName="breadcrumbs-portal"
            popoverClassName="breadcrumbs-popover"
            items={filteredEpics}
            currentItem={epic}
            onItemSelect={(epic) => onEpicSelect(epic.id)}
            canClear={true}
          />
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <SprintSelect
            disabled={disabled}
            className={breadcrumbsSelectClasses}
            portalClassName="breadcrumbs-portal"
            popoverClassName="breadcrumbs-popover"
            sprints={filteredSprints}
            selectedSprint={sprint}
            onSprintSelect={(sprint) => onSprintSelect(sprint.id)}
            canClear={true}
          />
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <div className="task-panel-breadcrumbs__task-code">{task.code}</div>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="task-panel-breadcrumbs__right">
        {task.is_blocked && (
          <div className="task-panel-breadcrumbs__bricks">
            {task.is_blocked && (
              <div className="task-panel-breadcrumbs__is-blocked">
                <IsBlockedBrick />
              </div>
            )}
          </div>
        )}
        {!task.is_blocked && (
          <div className="task-panel__created task-panel-breadcrumbs__created">
            <span className="task-panel__created-title">Created:</span>{' '}
            <span className="task-panel__created-value">
              {formatDate(task.created_at)}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
