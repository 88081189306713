import { Schema } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'

import { Extension } from "../.."
import { floatingMenuBar } from './plugins'
import { getMenuItems } from "./menu-items"

export class ToolbarExtension extends Extension {
  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    floatingMenuBar(getMenuItems(schema))
  ]
}
