import OrderedMap from "orderedmap";
import {NodeSpec, Schema} from "prosemirror-model"
import {Plugin} from "prosemirror-state"

import {NodeViewsSpec} from "../../core/types"
import {createReactNodeView} from "../../core/react-node-view"
import { Extension } from "../.."
import { attachmentPlugin } from "./plugins"
import { BaseAttachmentProvider } from "./provider"
import { AttachmentNodeSpec, AttachmentImageNodeSpec } from "./nodes"
import { AttachmentComponent, ImageComponent } from "./components";


export class AttachmentExtension extends Extension {
  constructor(
    private provider: BaseAttachmentProvider
  ) { super() }

  addNodes = (nodes: OrderedMap<NodeSpec>) => nodes.append({
    attachment: AttachmentNodeSpec,
    image: AttachmentImageNodeSpec,
  })

  addPlugins = (schema: Schema, plugins: Plugin[]): Plugin[] => [
    ...plugins,
    attachmentPlugin(this.provider)
  ]

  addNodeViews = (schema: Schema, nodeViews: NodeViewsSpec): NodeViewsSpec => ({
    ...nodeViews,
    attachment: (node, view, getPos, decorations) => {
      return createReactNodeView({
        node, view, getPos, decorations, component: AttachmentComponent
      })
    },
    image: (node, view, getPos, decorations) => {
      return createReactNodeView({
        node, view, getPos, decorations, component: ImageComponent
      })
    }
  })
}
