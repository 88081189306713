import React from 'react'
import { SetStatusAction } from '../../entities/activities/model'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import {
  HighlightTaskStatus,
} from './text-formatters/HighlightTaskStatus'
import {User} from "../../entities/user/model";
import {TaskStatus} from "../../entities/task/model";

interface SetStatusActivityProps {
  action: SetStatusAction
  date: string
  user: User | string
}

export function SetStatusActivity(props: SetStatusActivityProps) {
  const { action, user } = props
  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> changed this
      task status to{' '}
      <HighlightTaskStatus
        label={action.status.label}
        value={action.status.value as TaskStatus}
      />
      . <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
