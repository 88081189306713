import { useQuery } from '../../utils/queryhook'
import { taskQuery } from '../../entities/task/query'
import {
  BaseInternalLinkProvider,
  useProviderProps,
  useProviderResponse,
} from '../extentions/internal-link/provider'
import { taskService } from '../../entities/task/service'
import { useInstantEffect } from '../../utils/instant-effect-hook'
import { taskPanelService } from '../../project-page/tasks/TaskPanel/state/service'
import { ID } from '@datorama/akita'
import { useRouteMatch } from 'react-router-dom'

export class InternalLinkProvider extends BaseInternalLinkProvider {
  getTask = (code: string) => taskQuery.selectByCode(code)
  loadTask = (code: string) => taskService.loadTaskByCode(code)
  addLinkedTask = (idToAdd: ID) => taskPanelService.addLinkedTask(idToAdd)

  useProvider = ({
    taskCode,
    taskId,
  }: useProviderProps): useProviderResponse => {
    const code = `${taskCode}-${taskId}`
    const task = useQuery(this.getTask(code))
    let { taskCode: urlTaskCode } = useRouteMatch().params as any
    useInstantEffect(() => {
      if (!task && !!taskCode && !!taskId) {
        this.loadTask(code)
      }
      if (task && urlTaskCode) {
        this.addLinkedTask(task.id)
      }
    }, [])
    return { name: task?.name, status: task?.status }
  }
}
