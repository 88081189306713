import { ID } from '@datorama/akita'
import { ShortUser, User } from '../user/model'

export type EpicStatusType =
  | 'draft'
  | 'active'
  | 'closed'
  | 'cancelled'
  | 'on-hold'

export enum EpicStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  COMPLETE = 'closed',
  CANCELLED = 'cancelled',
  ONHOLD = 'on-hold',
}

export enum EpicStatusColor {
  DRAFT = 'rgb(7, 71, 166)',
  ACTIVE = 'rgb(54, 179, 126)',
  COMPLETE = 'rgb(151, 160, 175)',
  CANCELLED = '#f00',
  ONHOLD = 'rgb(192,192,192)',
}

export interface IEpicStatus {
  value: EpicStatus
  label: string
}

export const EPIC_STATUSES: IEpicStatus[] = [
  {
    value: EpicStatus.DRAFT,
    label: 'Draft',
  },
  {
    value: EpicStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: EpicStatus.ONHOLD,
    label: 'On Hold',
  },
  {
    value: EpicStatus.COMPLETE,
    label: 'Closed',
  },
  {
    value: EpicStatus.CANCELLED,
    label: 'Cancelled',
  },
]

export const DefaultEpicStatuses: EpicStatus[] = [
  EpicStatus.DRAFT,
  EpicStatus.ACTIVE,
]

type EpicStatusMapping = {
  [key in EpicStatusType]: string
}

export const getEpicStatusColorMapping: EpicStatusMapping = {
  draft: EpicStatusColor.DRAFT,
  active: EpicStatusColor.ACTIVE,
  'on-hold': EpicStatusColor.ONHOLD,
  closed: EpicStatusColor.COMPLETE,
  cancelled: EpicStatusColor.CANCELLED,
}

export interface Epic {
  id: ID
  project: ID
  name: string
  order: number
  created_by: ID
  updated_by: ID
  created_at: string
  updated_at: string
  time_logged: number
  status: EpicStatus
  json_description: object | null
  created_by_user?: User | ShortUser
  updated_by_user?: User | ShortUser
  ss_link?: string
  is_readonly: boolean
}

export function filterEpics(epics: Epic[], statuses: EpicStatus[]) {
  if (!statuses.length) return epics
  return epics.filter((epic) => statuses.includes(epic.status))
}
