import React from 'react'
import { LinkOutlined, FilterOutlined } from '@ant-design/icons'

import '../styles/filter-button.scss'
import classNames from 'classnames'

interface FilterButtonProps {
  color?: string
  iconName: 'link-outlined' | 'filter-outlined'
  isActive?: boolean
  noIconBackground?: boolean
}

interface Icon {
  value: string
  icon: React.ReactNode
}

const ICON_COLOR = 'var(--color-text-link)'

export function FilterButton(props: FilterButtonProps) {
  const { color = ICON_COLOR, iconName, isActive, noIconBackground } = props

  const icons: Icon[] = [
    {
      value: 'link-outlined',
      icon: <LinkOutlined style={{ color: color, fontSize: 12 }} />,
    },
    {
      value: 'filter-outlined',
      icon: <FilterOutlined style={{ color: color, fontSize: 11 }} />,
    },
  ]

  const iconToRender = icons.find((icon: Icon) => icon.value === iconName)

  const classes = classNames('small-filter-button', {
    'is-active': isActive && !noIconBackground,
    'no-background-active': isActive && noIconBackground,
  })

  return <button className={classes}>{iconToRender?.icon}</button>
}
