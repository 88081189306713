import React from 'react'
import { DateRange } from '@blueprintjs/datetime'
import { DatesRangeSelect } from '../components/controls/dates-range-select/DatesRangeSelect'
import { FiltersListTitle } from './FiltersListTitle'

interface FiltersListDateRangeProps {
  startDate: Date | null
  endDate: Date | null
  onChange: (dates: DateRange) => void
}

export function FiltersListDateRange(props: FiltersListDateRangeProps) {
  return (
    <div className="filters-list__item filters-list__item__date-range">
      <FiltersListTitle>Time Period</FiltersListTitle>
      <DatesRangeSelect
        startDate={props.startDate}
        endDate={props.endDate}
        onChange={props.onChange}
      />
    </div>
  )
}
