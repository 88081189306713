import React, { useCallback } from 'react'
import { Select } from '@blueprintjs/select'
import { ActivityChoice } from '../../entities/choices/activity'
import { selectItemRenderer } from '../../utils/select'
import './styles/add-time-manually-activities-select.scss'
import classNames from 'classnames'

const TypedActivitySelect = Select.ofType<ActivityChoice>()

interface AddTimeManuallyActivitiesSelectProps {
  activity: string
  activities: ActivityChoice[]
  selectClasses?: string
  onSelect: (value: string) => void
}

export function AddTimeManuallyActivitiesSelect(
  props: AddTimeManuallyActivitiesSelectProps
) {
  const { onSelect } = props
  const onItemSelect = useCallback(
    (activity: ActivityChoice) => onSelect(activity.value),
    [onSelect]
  )

  function filterActivity(query: string, activity: ActivityChoice) {
    return activity.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
  }

  const selectedActivityObj = props.activities.find(
    (activity) => activity.value === props.activity
  )

  const buttonClasses = classNames('add-time-manually-activities-button', {
    'is-active': selectedActivityObj,
  })

  return (
    <TypedActivitySelect
      itemPredicate={filterActivity}
      items={props.activities}
      className={props.selectClasses}
      itemRenderer={selectItemRenderer}
      onItemSelect={onItemSelect}
      filterable={false}
      popoverProps={{
        wrapperTagName: 'div',
        targetTagName: 'div',
        minimal: true,
        position: 'bottom-left',
        popoverClassName: 'popover',
      }}
    >
      <button className={buttonClasses}>
        {!selectedActivityObj
          ? 'Specify your activity'
          : selectedActivityObj.label}
      </button>
    </TypedActivitySelect>
  )
}
