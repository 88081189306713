import './styles/project-details-info.scss'

import React from 'react'
import { ProjectDetailsInfoSlack } from './ProjectDetailsInfoSlack'
import { ProjectDetailsInfoDeadline } from './ProjectDetailsInfoDeadline'
import {Project} from "../../../entities/project/model";

interface ProjectDetailsInfoProps {
  slackChannel: string
  dateDeadline: Date | null
  onUpdate: (update: Partial<Project>) => void
}

export function ProjectDetailsInfo(props: ProjectDetailsInfoProps) {
  return (
    <div className="project-details-info project-details__info">
      <div className="project-details-info__row">
        <div className="project-details-info__item">
          <ProjectDetailsInfoDeadline
            date={props.dateDeadline}
            onChange={(value) => props.onUpdate({deadline: value})}
          />
        </div>
        <div className="project-details-info__item">
          <ProjectDetailsInfoSlack
            slackChannel={props.slackChannel}
            onChange={(value) => props.onUpdate({slack_channel: value})}
          />
        </div>
      </div>
    </div>
  )
}
