import { ProjectPageStore, projectPageStore } from './store'
import { ProjectPageQuery, projectPageQuery } from './query'
import { combineLatest } from 'rxjs'
import { taskPanelQuery, TaskPanelQuery } from '../tasks/TaskPanel/state/query'
import {
  epicPanelQuery,
  EpicPanelQuery,
} from '../task-groups/details/EpicPanel/state/query'
import {
  sprintPanelQuery,
  SprintPanelQuery,
} from '../task-groups/details/SprintPanel/state/query'

export class FilterLocalStorageService {
  constructor(
    private store: ProjectPageStore,
    private query: ProjectPageQuery,
    private taskPanelQuery: TaskPanelQuery,
    private epicPanelQuery: EpicPanelQuery,
    private sprintPanelQuery: SprintPanelQuery
  ) {}

  subscribeOnProjectFilters() {
    combineLatest([
      this.query.projectId$,
      this.query.tasksFilter$,
      this.query.sprintsFilter$,
      this.query.epicsFilter$,
      this.query.activityFilter$,
      this.query.epicsPaneCollapsedState$,
      this.query.sprintsPaneCollapsedState$,
      this.query.expandedEpics$,
      this.query.collapsedSprints$,
    ]).subscribe(
      ([
        projectId,
        taskFilter,
        sprintsFilter,
        epicsFilter,
        activityFilter,
        epicsPaneCollapsed,
        sprintsPaneCollapsed,
        collapsedEpicsList,
        collapsedSprintsList,
      ]) => {
        localStorage.setItem(
          'tasksFilter',
          JSON.stringify({
            statuses: taskFilter.statuses,
            assignee: taskFilter.assignee,
            task_type: taskFilter.task_type,
          })
        )
        localStorage.setItem(
          'sprintsFilter',
          JSON.stringify({ statuses: sprintsFilter.statuses })
        )
        localStorage.setItem(
          'epicsFilter',
          JSON.stringify({ statuses: epicsFilter.statuses })
        )
        localStorage.setItem(
          'activityFilter',
          JSON.stringify({ statuses: activityFilter })
        )

        if (epicsPaneCollapsed !== undefined) {
          localStorage.setItem(
            'epicsPaneCollapsed',
            JSON.stringify({ status: epicsPaneCollapsed })
          )
        }

        if (sprintsPaneCollapsed !== undefined) {
          localStorage.setItem(
            'sprintsPaneCollapsed',
            JSON.stringify({ status: sprintsPaneCollapsed })
          )
        }

        localStorage.setItem(
          `${projectId}_epics`,
          JSON.stringify({ expandedEpics: collapsedEpicsList })
        )
        localStorage.setItem(
          `${projectId}_sprints`,
          JSON.stringify({ collapsedSprints: collapsedSprintsList })
        )
      }
    )
  }

  subscribeOnTaskPanelFilters() {
    combineLatest([
      this.taskPanelQuery.subtasksFilter$,
      this.taskPanelQuery.activityFilter$,
    ]).subscribe(([subtasksFilter, activityFilter]) => {
      localStorage.setItem(
        'subtasksFilter',
        JSON.stringify({
          statuses: subtasksFilter.statuses,
          types: subtasksFilter.types,
        })
      )
      localStorage.setItem(
        'taskActivityFilter',
        JSON.stringify({ statuses: activityFilter })
      )
    })
  }

  subscribeOnEpicPanelFilters() {
    this.epicPanelQuery.activityFilter$.subscribe((activityFilter) => {
      localStorage.setItem(
        'epicActivityFilter',
        JSON.stringify({ statuses: activityFilter })
      )
    })
  }

  subscribeOnSprintPanelFilters() {
    this.sprintPanelQuery.activityFilter$.subscribe((activityFilter) => {
      localStorage.setItem(
        'sprintActivityFilter',
        JSON.stringify({ statuses: activityFilter })
      )
    })
  }
}

export const filterLocalStorageService = new FilterLocalStorageService(
  projectPageStore,
  projectPageQuery,
  taskPanelQuery,
  epicPanelQuery,
  sprintPanelQuery
)
