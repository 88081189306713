import './styles/task-list.scss'

import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ID } from '@datorama/akita'
import { ShortUser, User } from '../../entities/user/model'
import { Task } from '../../entities/task/model'
import { Sprint } from '../../entities/sprint/model'
import { Epic } from '../../entities/epic/model'
import { ModelUpdate } from '../../utils/types'
import { DraggableTaskListItem } from './TaskListItem'
import {
  AddTimeManuallyCallback,
  StartTrackingCallback,
} from '../../tracking/types'
import { IFormErrors } from '../state/store'
import { TaskType } from '../../entities/task-type/model'
import {isEqual} from "lodash";

export interface TaskListProps {
  tasks: Task[]
  sprint?: Sprint
  epic?: Epic
  epics?: Epic[]
  sprints?: Sprint[]
  taskTypes: TaskType[]
  filteredSprints?: Sprint[]
  location: 'epic' | 'sprint'
  currentTask?: Task | null
  users: User[] | ShortUser[]
  onStartTracking: StartTrackingCallback
  onStopTracking: () => void
  onAddTimeManually: AddTimeManuallyCallback
  onTaskUpdate: (update: ModelUpdate<Task>) => void
  onRemove?: (idToRemove: ID) => void
  currentUser?: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

export function _TaskList(props: TaskListProps) {
  const match = useRouteMatch()
  return (
    <div className="task-list">
      {props.tasks.map((task, index) => (
        <DraggableTaskListItem
          key={task.id}
          index={index}
          task={task}
          location={props.location}
          onStartTracking={props.onStartTracking}
          onStopTracking={props.onStopTracking}
          onAddTimeManually={props.onAddTimeManually}
          onTaskUpdate={props.onTaskUpdate}
          isHighlighted={props.currentTask?.id === task.id}
          sprint={props.sprint}
          epic={props.epic}
          users={props.users}
          baseURL={match.url}
          onRemove={props.onRemove}
          epics={props.epics}
          sprints={props.sprints}
          taskTypes={props.taskTypes}
          filteredSprints={props.filteredSprints}
          currentUser={props.currentUser}
          addTimeFormErrors={props.addTimeFormErrors}
          isAddTimeFormOpened={props.isAddTimeFormOpened}
          onOpenAddTimeModal={props.onOpenAddTimeModal}
          onCloseAddTimeModal={props.onCloseAddTimeModal}
        />
      ))}
    </div>
  )
}

export const TaskList = React.memo(_TaskList, isEqual)
