import React, { createContext } from 'react'
import { User } from '../entities/user/model'
import { useQuery } from '../utils/queryhook'
import { userQuery } from '../entities/user/query'

export const UsersChoicesContext = createContext<User[]>([])

export function UsersContextGuard(props: { children: React.ReactNode }) {
  const users = useQuery(userQuery.users$)

  return (
    <UsersChoicesContext.Provider value={users}>
      {props.children}
    </UsersChoicesContext.Provider>
  )
}
