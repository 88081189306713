import './styles/multi-select.scss'
import './styles/project-select.scss'

import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import {
  IItemRendererProps,
  ItemListRenderer,
  MultiSelect,
} from '@blueprintjs/select'
import { Empty } from 'antd'
import { Project } from '../../entities/project/model'
import { CustomScrollbar } from '../custom-scrollbar/CustomScrollbar'
import { Menu } from '@blueprintjs/core'

const ProjectSelectComponent = MultiSelect.ofType<Project>()

interface ProjectSelectProps {
  projects: Project[]
  selectedProjects: Project[]
  onProjectsChange: (projects: Project[]) => void
}

export function ProjectMultiSelect(props: ProjectSelectProps) {
  const { selectedProjects, onProjectsChange } = props
  const [query, setQuery] = useState<string>('')

  const renderProject = useCallback(
    (project: Project, { modifiers, handleClick }: IItemRendererProps) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      return (
        <li
          onClick={handleClick}
          key={project.name}
          className={classNames('bp3-menu-item', {
            'bp3-multi': modifiers.active,
            'bp3-menu-item_selected': selectedProjects.includes(project),
          })}
        >
          <div className="project-select__list-content text-trim-ellipsis">
            <span className="project-select__list-abbr">{project.code}</span>
            {' | '}
            <span className="project-select__list-full">{project.name}</span>
            {' | '}
            <span className="project-select__list-abbr">{project.status}</span>
          </div>
        </li>
      )
    },
    [selectedProjects]
  )

  const renderMenu: ItemListRenderer<Project> = ({
    items,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null)
    if (renderedItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          <Empty imageStyle={{ height: 60 }} description={'No Results'} />
        </Menu>
      )
    }
    return (
      <Menu ulRef={itemsParentRef}>
        <CustomScrollbar
          theme="dark"
          rendererMaxHeight={400}
          translateContentSizesToHolder={true}
        >
          {renderedItems}
        </CustomScrollbar>
      </Menu>
    )
  }

  const renderTag = useCallback(
    (project: Project) => `${project.code} | ${project.name}`,
    []
  )

  const handleProjectSelect = useCallback(
    (project: Project) => {
      const index = selectedProjects.indexOf(project)
      if (index === -1) {
        onProjectsChange([...selectedProjects, project])
      } else {
        onProjectsChange(selectedProjects.filter((project, i) => i !== index))
      }
      setQuery('')
    },
    [selectedProjects, onProjectsChange]
  )

  const selectProjectPredicate = useCallback(
    (query: string, project: Project) => {
      return project.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
    },
    []
  )

  const handleTagRemove = useCallback(
    (_tag: React.ReactNode, index: number) => {
      onProjectsChange(selectedProjects.filter((project, i) => i !== index))
    },
    [selectedProjects, onProjectsChange]
  )

  return (
    <>
      <ProjectSelectComponent
        query={query}
        onQueryChange={setQuery}
        items={props.projects}
        itemRenderer={renderProject}
        itemPredicate={selectProjectPredicate}
        onItemSelect={handleProjectSelect}
        tagRenderer={renderTag}
        itemListRenderer={renderMenu}
        className="select"
        selectedItems={props.selectedProjects}
        placeholder={'Select Project'}
        fill={true}
        noResults={
          <Empty imageStyle={{ height: 60 }} description="No Results" />
        }
        tagInputProps={{
          onRemove: handleTagRemove,
          className: 'multi-select',
        }}
        popoverProps={{
          className: 'multi-select-popover-wrap',
          minimal: true,
          position: 'bottom-left',
          usePortal: false,
          fill: true,
          popoverClassName: 'popover popover_full-width multi-select-popover',
        }}
      />
    </>
  )
}
