import _ from 'lodash'
import { ID, QueryEntity } from '@datorama/akita'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { UserState, UserStore, userStore } from './store'
import { convertToShortUser, ShortUser } from './model'
import { userService, UserService } from './service'

export class UserQuery extends QueryEntity<UserState> {
  loaded$ = this.select('loaded')
  users$ = this.selectAll().pipe(
    map((users) =>
      _.orderBy(
        users,
        ['is_active', 'is_external', 'is_invited', 'full_name', 'email'],
        ['desc', 'asc', 'asc', 'asc', 'asc']
      )
    )
  )

  shortUsers$ = this.users$.pipe(
    map((users) => {
      return users.map(convertToShortUser)
    })
  )

  activeShortUsers$ = this.shortUsers$.pipe(
    map((users) => {
      return users.filter((user) => user.is_active && user.is_active)
    })
  )

  getOrLoadUser$(id: ID) {
    if (!this.hasEntity(id)) {
      this.userService.loadUser(id)
    }

    return this.selectEntity(id)
  }

  forProject(projectId: ID | null) {
    return this.users$.pipe(
      map((users) => {
        return users.filter(
          (user) =>
            user.projects?.includes(+(projectId || -1))
        )
      })
    )
  }

  forProjectShort(
    projectId: ID | null,
    shortUsers: Observable<ShortUser[]> = this.shortUsers$
  ) {
    return shortUsers.pipe(
      map((users) => {
        return users.filter(
          (user) =>
            user.projects?.includes(+(projectId || -1)) && !user.is_invited
        )
      })
    )
  }

  forWorkspace(workspaceId: ID | null) {
    return this.selectAll({
      filterBy: (user) => {
        return user.workspaces?.includes(+(workspaceId || -1))
      },
    })
  }

  constructor(protected store: UserStore, private userService: UserService) {
    super(store)
  }
}

export const userQuery = new UserQuery(userStore, userService)
