import { History } from 'history'

export class HistoryWrapper {
  private history: History | null = null

  init(history: History) {
    this.history = history
  }

  get instance(): History {
    return this.history!
  }
}

export const historyWrapper = new HistoryWrapper()
