import '../../styles/avatar-uploader.scss'

import React, { useState } from 'react'
import classNames from 'classnames'
import { ID } from '@datorama/akita'
import { Upload, message, Avatar } from 'antd'
import { LoadingOutlined, FileImageOutlined } from '@ant-design/icons'
import {
  RcCustomRequestOptions,
  UploadChangeParam,
} from 'antd/lib/upload/interface'
import { UserAvatar } from '../../../components/UserAvatar'
import { User } from '../../../entities/user/model'

const checkURL = (url: string) => {
  return url.match(/\.(jpeg|jpg|png)$/) != null
}

const AVATAR_SIZE = 100
const MAX_AVATAR_FILE_SIZE = 1024 * 1024 * 10

const validateAvatarFile = (file: File) => {
  const isOfProperType = file.type === 'image/jpeg' || file.type === 'image/png'
  const fitsMaxFileSize = file.size <= MAX_AVATAR_FILE_SIZE

  if (!isOfProperType) {
    message.error('You can only upload JPG/PNG file')
  }
  if (!fitsMaxFileSize) {
    message.error('Avatar file size should be less than 10MB')
  }
  return isOfProperType && fitsMaxFileSize
}

interface UserProfileUploadPhotoProps {
  user: User
  uploadClassName: string
  avatarClassName: string
  editable: boolean
  onUploadAvatar: (userId: ID, file: File) => void
}

export function UserProfileUploadPhoto(props: UserProfileUploadPhotoProps) {
  const [loading, setLoading] = useState(false)
  const imageURL = props.user.avatar

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="ant-upload-text">
          upload <br /> photo
        </div>
      )}
    </div>
  )
  const avatarClassNames = classNames(
    'avatar-uploader',
    { 'avatar-uploader_no-border': imageURL && checkURL(imageURL) },
    props.uploadClassName,
    props.avatarClassName
  )

  if (!props.editable) {
    return (
      <UserAvatar
        size={AVATAR_SIZE}
        shape={'square'}
        user={props.user}
        className={avatarClassNames}
      />
    )
  }

  const customRequest = ({ file, onProgress }: RcCustomRequestOptions) => {
    onProgress({ percent: 0 }, file)
    props.onUploadAvatar(props.user.id, file)
  }

  const onChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'error') {
      setLoading(false)
      message.error('Failed to load resource')
      return
    }
    if (info.file.status === 'done') {
      setLoading(false)
      message.success('Uploaded successfully')
      return
    }
  }

  return (
    <Upload
      name="avatar"
      style={{
        display: 'none',
      }}
      listType="picture-card"
      className={avatarClassNames}
      showUploadList={false}
      customRequest={customRequest}
      beforeUpload={validateAvatarFile}
      onChange={onChange}
    >
      {imageURL && checkURL(imageURL) ? (
        <Avatar
          src={imageURL}
          alt="avatar"
          icon={<FileImageOutlined />}
          shape={'square'}
          size={AVATAR_SIZE}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  )
}
