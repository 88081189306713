import { Collapse } from '@blueprintjs/core'
import React, { useState } from 'react'
import { ChangePriorityAction } from '../../entities/activities/model'
import { TaskPriorityType } from '../../entities/choices/priority'
import { ActivitiesDate } from './ActivitiesDate'
import { ActivitiesDetailsButton } from './ActivitiesDetailsButton'
import { ActivityDetailsContent } from './ActivitiyDetailsContent'
import { ActivityBoldText } from './text-formatters/ActivityBoldText'
import { HighlightTaskPriority } from './text-formatters/HighlightTaskPriority'
import {User} from "../../entities/user/model";

interface ChangePriorityActivityProps {
  action: ChangePriorityAction
  date: string
  user: User | string
}

export function ChangePriorityActivity(
  props: ChangePriorityActivityProps
) {
  const { action, user } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const setExpanedStateHandler = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> set the task
      priority to{' '}
      <HighlightTaskPriority
        label={action.data.changed_to.label}
        value={action.data.changed_to.value as TaskPriorityType}
      />
      .{' '}
      <ActivitiesDetailsButton
        type='show-original'
        isActive={isExpanded}
        onClickHandler={setExpanedStateHandler}
      />{' '}
      <ActivitiesDate date={props.date} isActivityDate />{' '}
      <Collapse isOpen={isExpanded}>
        <ActivityDetailsContent>
          <HighlightTaskPriority
            label={action.data.changed_from.label}
            value={action.data.changed_from.value as TaskPriorityType}
          />
        </ActivityDetailsContent>
      </Collapse>
    </>
  )
}
