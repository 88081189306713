import React from 'react'
import { MenuUnfoldOutlined } from '@ant-design/icons'

export function CollapsedPane(props: { title: string; onExpand: () => void }) {
  return (
    <div className="collapsed-pane">
      <MenuUnfoldOutlined
        className="collapsed-pane__icon"
        onClick={props.onExpand}
      />
      <h2>{props.title}</h2>
    </div>
  )
}
