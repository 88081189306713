import {
  ProjectPageStore,
  projectPageStore,
  projectPageInitialState,
} from './store'
import { applyTransaction, ID } from '@datorama/akita'
import { toggleArrayPresence } from '../../utils/togglearray'
import { boundMethod } from 'autobind-decorator'
import {
  DefaultTaskStatuses,
  filterTasks,
  Task,
  TaskStatus,
} from '../../entities/task/model'
import { TaskService, taskService } from '../../entities/task/service'
import {
  workspaceDataService,
  WorkspaceDataService,
} from '../../entities/workspace/data-service'
import { historyWrapper } from '../../globals/history'
import { workspaceQuery, WorkspaceQuery } from '../../entities/workspace/query'
import { SprintService, sprintService } from '../../entities/sprint/service'
import {
  DefaultSprintStatuses,
  filterSprints,
  Sprint,
  SprintStatus,
} from '../../entities/sprint/model'
import { ProjectPageQuery, projectPageQuery } from './query'
import {
  DefaultEpicStatuses,
  Epic,
  EpicStatus,
  filterEpics,
} from '../../entities/epic/model'
import { epicService, EpicService } from '../../entities/epic/service'
import { projectService, ProjectService } from '../../entities/project/service'
import { arrayToggle } from '../../utils/arrayToggle'
import {
  determineRealIndexInFilteredList,
  smartListReOrder,
} from '../../utils/lists'
import { sprintQuery, SprintQuery } from '../../entities/sprint/query'
import { epicQuery, EpicQuery } from '../../entities/epic/query'
import { TaskQuery, taskQuery } from '../../entities/task/query'
import { HistoryActivityItemType } from '../../history/state/model'
import { parseLocalStorage } from '../utils'
import {
  projectDataService,
  ProjectDataService,
} from '../../entities/project/data-service'
import {
  epicPanelQuery,
  EpicPanelQuery,
} from '../task-groups/details/EpicPanel/state/query'

export class ProjectPageService {
  constructor(
    private store: ProjectPageStore,
    private query: ProjectPageQuery,
    private taskService: TaskService,
    private taskQuery: TaskQuery,
    private sprintService: SprintService,
    private sprintQuery: SprintQuery,
    private epicService: EpicService,
    private epicQuery: EpicQuery,
    private workspaceQuery: WorkspaceQuery,
    private workspaceDataService: WorkspaceDataService,
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private epicPanelQuery: EpicPanelQuery
  ) {}

  loadMyLastActivities() {
    const workspaceID = this.workspaceQuery.getActive().id
    this.workspaceDataService
      .lastActivities(workspaceID)
      .subscribe((activities) => {
        this.store.update({ myLastActivities: activities })
      })
  }

  loadCurrentTeamActivities() {
    const projectID = this.query.getActiveId()
    if (projectID) {
      this.projectDataService
        .currentTeamActivities(projectID)
        .subscribe((activities) => {
          this.store.update({ currentTeamActivities: activities })
        })
    }
  }

  setProject(id: ID) {
    this.store.upsert(id, (_entity) => ({
      expandedEpics:
        parseLocalStorage(`${id}_epics`, 'expandedEpics') ||
        projectPageInitialState.expandedEpics,
      collapsedSprints:
        parseLocalStorage(`${id}_sprints`, 'collapsedSprints') ||
        projectPageInitialState.collapsedSprints,
    }))
    this.store.setActive(id)
  }

  @boundMethod
  sprintCreate(sprint: Partial<Sprint>) {
    const projectId = this.query.getActive().project
    if (!!sprint.name?.trim()) {
      this.sprintService
        .create({
          ...sprint,
          project: projectId!,
        })
        .subscribe(
          (sprint: Sprint) => {
            const workspace = this.workspaceQuery.getActiveId()
            historyWrapper.instance.push(
              `/${workspace}/${sprint.project}/sprint/${sprint.id}`
            )
            this.cleanupFormErrors()
            this.closeCreateSprintFrom()
          },
          (error) => {
            this.store.update(() => ({
              createSprintFormErrors: error.response.data,
            }))
          }
        )
    } else {
      this.store.update(() => ({
        createSprintFormErrors: {
          non_field_errors: 'Sprint name may not be blank',
        },
      }))
    }
  }

  @boundMethod
  epicCreate(epic: Partial<Epic>) {
    const projectId = this.query.getActive().project
    if (!!epic.name?.trim()) {
      this.epicService
        .create({
          ...epic,
          project: projectId!,
        })
        .subscribe(
          (epic) => {
            const workspace = this.workspaceQuery.getActiveId()
            historyWrapper.instance.push(
              `/${workspace}/${epic.project}/epic/${epic.id}`
            )
            this.cleanupFormErrors()
            this.closeCreateEpicFrom()
          },
          (error) => {
            this.store.update(() => ({
              createEpicFormErrors: error.response.data,
            }))
          }
        )
    } else {
      this.store.update(() => ({
        createEpicFormErrors: {
          non_field_errors: 'Epic name may not be blank',
        },
      }))
    }
  }

  @boundMethod
  toggleSprint(id: ID) {
    this.store.updateActive((state) => ({
      collapsedSprints: toggleArrayPresence(state.collapsedSprints, id),
    }))
  }

  @boundMethod
  collapseAllSprints() {
    this.store.updateActive((state) => {
      // extracting Sprint IDs for active project
      const sprintIds = this.sprintQuery
        .getAll({ filterBy: (sprint) => sprint.project === state.project })
        .map((sprint) => sprint.id)

      return { collapsedSprints: sprintIds }
    })
  }

  @boundMethod
  collapseInactiveSprints() {
    this.store.updateActive((state) => {
      // extracting inactive Sprint IDs for active project
      const sprintIds = this.sprintQuery
        .getAll({
          filterBy: (sprint) =>
            sprint.project === state.project &&
            sprint.status !== SprintStatus.STARTED,
        })
        .map((sprint) => sprint.id)

      return { collapsedSprints: sprintIds }
    })
  }

  @boundMethod
  toggleEpicsPane() {
    this.store.update((state) => ({
      epicsPaneCollapsed: !state.epicsPaneCollapsed,
    }))
  }

  @boundMethod
  toggleSprintsPane() {
    this.store.update((state) => ({
      sprintsPaneCollapsed: !state.sprintsPaneCollapsed,
    }))
  }

  @boundMethod
  toggleEpic(id: ID) {
    this.store.updateActive((state) => ({
      expandedEpics: toggleArrayPresence(state.expandedEpics, id),
    }))
  }

  @boundMethod
  toggleProjectBacklog() {
    this.store.updateActive((state) => ({
      backlogVisible: !state.backlogVisible,
    }))
  }

  @boundMethod
  taskCreate(task: Partial<Task>) {
    const projectId = this.query.getActive().project

    this.taskService
      .createTask({
        ...task,
        project: projectId!,
      })
      .subscribe((task) => {
        const workspace = this.workspaceQuery.getActiveId()
        historyWrapper.instance.push(
          `/${workspace}/${task.project}/task/${task.code}`
        )
      })
  }

  @boundMethod
  uploadLogo(file: File) {
    const projectId = this.query.getActive().project
    this.projectService.uploadLogo(projectId!, file)
  }

  @boundMethod
  updateTasksFilterStatuses(status: TaskStatus) {
    this.store.update(({ tasksFilter: { statuses, assignee, task_type } }) => ({
      tasksFilter: {
        statuses: arrayToggle(statuses, status),
        assignee,
        task_type,
      },
    }))
  }

  @boundMethod
  resetTasksFilterStatuses(resetStatuses: TaskStatus[]) {
    this.store.update(({ tasksFilter: { assignee } }) => ({
      tasksFilter: { statuses: resetStatuses, assignee },
    }))
  }

  @boundMethod
  updateTasksFilterAssignee(newAssigneeIds: ID[]) {
    this.store.update(({ tasksFilter: { statuses, task_type } }) => ({
      tasksFilter: { assignee: newAssigneeIds, statuses, task_type },
    }))
  }

  @boundMethod
  updateTasksFilterType(taskType: ID[]) {
    this.store.update(({ tasksFilter: { statuses, assignee, task_type } }) => ({
      tasksFilter: {
        assignee,
        statuses,
        task_type: taskType,
      },
    }))
  }

  @boundMethod
  updateSprintsFilterStatuses(status: SprintStatus) {
    this.store.update(({ sprintsFilter: { statuses } }) => ({
      sprintsFilter: { statuses: arrayToggle(statuses, status) },
    }))
  }

  @boundMethod
  resetSprintsFilterStatuses(sprintStatuses: SprintStatus[]) {
    this.store.update(({ sprintsFilter: { statuses } }) => ({
      sprintsFilter: { statuses: sprintStatuses },
    }))
  }

  @boundMethod
  updateEpicsFilterStatuses(status: EpicStatus) {
    this.store.update(({ epicsFilter: { statuses } }) => ({
      epicsFilter: { statuses: arrayToggle(statuses, status) },
    }))
  }

  @boundMethod
  resetEpicsFilterStatuses(epicsStatuses: EpicStatus[]) {
    this.store.update(({ epicsFilter: { statuses } }) => ({
      epicsFilter: { statuses: epicsStatuses },
    }))
  }

  @boundMethod
  updateActivityFilter(choice: HistoryActivityItemType) {
    this.store.update(({ activitiesFilter }) => ({
      activitiesFilter: arrayToggle(activitiesFilter, choice),
    }))
  }

  @boundMethod
  clearFilters() {
    applyTransaction(() => {
      this.updateTasksFilterAssignee(
        projectPageInitialState.tasksFilter.assignee
      )
      this.resetTasksFilterStatuses(DefaultTaskStatuses)
      this.resetSprintsFilterStatuses(DefaultSprintStatuses)
      this.resetEpicsFilterStatuses(DefaultEpicStatuses)
    })
  }

  @boundMethod
  moveSprint(id: ID, index: number) {
    const sprint: Sprint = { ...this.sprintQuery.getEntity(id) }
    const { statuses } = this.query.getValue().sprintsFilter
    const sprints = this.sprintQuery.getForProject(sprint.project)
    const filteredSprints = filterSprints(sprints, statuses)

    const realIndex = determineRealIndexInFilteredList(
      sprints,
      filteredSprints,
      'order',
      index
    )
    const updates = smartListReOrder(sprint, sprints, 'order', realIndex)

    applyTransaction(() => {
      updates.forEach((update) => this.sprintService.updateSprint(update))
    })
  }

  @boundMethod
  moveEpic(id: ID, index: number) {
    const epic: Epic = { ...this.epicQuery.getEntity(id) }
    const { statuses } = this.query.getValue().epicsFilter
    const epics: Epic[] = this.epicQuery.getForProject(epic.project)
    const filteredEpics = filterEpics(epics, statuses)

    const realIndex = determineRealIndexInFilteredList(
      epics,
      filteredEpics,
      'order',
      index
    )
    const updates = smartListReOrder(epic, epics, 'order', realIndex)

    applyTransaction(() => {
      updates.forEach((update) => this.epicService.updateEpic(update))
    })
  }

  @boundMethod
  moveTask(id: ID, targetType: 'sprint' | 'epic', targetId: ID, index: number) {
    const orderField = `${targetType}_order` as 'sprint_order' | 'epic_order'
    const { statuses, assignee, task_type } = this.query.getValue().tasksFilter
    const task: Task = { ...this.taskQuery.getEntity(id) }
    const sprints = this.sprintQuery.getAll({
      filterBy: (sprint) => sprint.status === 'closed',
    })
    const tasks: Task[] = this.taskQuery.getAll({
      filterBy: (task) => task[targetType] === targetId,
    })

    const { showTasksInFinishedSprints } = this.epicPanelQuery.getValue()

    const sprintIDs =
      targetType === 'epic' ? sprints.map((item) => item.id) : []

    const isShowTasksInFinishedSprints =
      targetType === 'epic'
        ? showTasksInFinishedSprints.includes(targetId)
        : true

    const filteredTasks = filterTasks(
      tasks,
      statuses,
      assignee,
      task_type,
      isShowTasksInFinishedSprints,
      sprintIDs
    )

    const realIndex = determineRealIndexInFilteredList(
      tasks,
      filteredTasks,
      orderField,
      index
    )
    const updates = smartListReOrder(task, tasks, orderField, realIndex)

    // need sentry to catch an error with tasks moved between sprints
    // Sentry.addBreadcrumb({
    //   type: 'debug',
    //   category: 'debug',
    //   message: `Task: ${id}, with targetType ${targetType} and targetId ${targetId} was moved`,
    //   level: Sentry.Severity.Warning,
    //   data: {
    //     info_updates: updates,
    //     task: task,
    //     tasks: tasks.map((task) => task.code),
    //   },
    // })
    //
    // Sentry.captureMessage(`Moved task ${id}`)

    applyTransaction(() => {
      updates.forEach((update) =>
        this.taskService.updateTask({
          [targetType]: targetId,
          ...update,
        })
      )
    })
  }

  @boundMethod
  cleanupFormErrors() {
    this.store.update(() => ({
      createEpicFormErrors: null,
      createSprintFormErrors: null,
    }))
  }

  @boundMethod
  openCreateEpicFrom() {
    this.store.update(() => ({
      createEpicFormIsOpened: true,
    }))
  }

  @boundMethod
  closeCreateEpicFrom() {
    this.store.update(() => ({
      createEpicFormIsOpened: false,
    }))
  }

  @boundMethod
  openCreateSprintFrom() {
    this.store.update(() => ({
      createSprintFormIsOpened: true,
    }))
  }

  @boundMethod
  closeCreateSprintFrom() {
    this.store.update(() => ({
      createSprintFormIsOpened: false,
    }))
  }
}

export const projectPageService = new ProjectPageService(
  projectPageStore,
  projectPageQuery,
  taskService,
  taskQuery,
  sprintService,
  sprintQuery,
  epicService,
  epicQuery,
  workspaceQuery,
  workspaceDataService,
  projectDataService,
  projectService,
  epicPanelQuery
)
