import { EntityStore } from '@datorama/akita'
import { WebSocketMessage, WebsocketMessageProcessor } from './types'
import { WorkspaceStore, workspaceStore } from '../entities/workspace/store'
import { ProjectStore, projectStore } from '../entities/project/store'
import { EpicStore, epicStore } from '../entities/epic/store'
import { SprintStore, sprintStore } from '../entities/sprint/store'
import { TaskStore, taskStore } from '../entities/task/store'
import { SubtaskStore, subtaskStore } from '../entities/subtask/store'
import { userStore, UserStore } from '../entities/user/store'
import { commentStore, CommentStore } from '../entities/comment/store'
import {
  activityLogStore,
  ActivityLogStore,
} from '../entities/activities/store'
import { timeEntryStore, TimeEntryStore } from '../entities/time-entry/store'

export class EntityUpdateProcessor implements WebsocketMessageProcessor {
  entityStoreMap: { [type: string]: EntityStore<any, any> } = {
    workspace: this.workspaceStore,
    project: this.projectStore,
    epic: this.epicStore,
    sprint: this.sprintStore,
    task: this.taskStore,
    subtask: this.subtaskStore,
    comment: this.commentStore,
    user: this.userStore,
    activity: this.activityLogStore,
    worklog: this.timeEntryStore,
  }

  constructor(
    private workspaceStore: WorkspaceStore,
    private projectStore: ProjectStore,
    private epicStore: EpicStore,
    private sprintStore: SprintStore,
    private taskStore: TaskStore,
    private subtaskStore: SubtaskStore,
    private commentStore: CommentStore,
    private userStore: UserStore,
    private activityLogStore: ActivityLogStore,
    private timeEntryStore: TimeEntryStore
  ) {}

  process(message: WebSocketMessage) {
    if (message[0] === 'entity_created') {
      const data = message[1]
      this.entityStoreMap[data.type].add(data.entity)
      return true
    }

    if (message[0] === 'entity_changed') {
      const data = message[1]
      if (data.update instanceof Array) {
        this.entityStoreMap[data.type].upsertMany(data.update)
      } else {
        this.entityStoreMap[data.type].update(data.update.id, (entity) => ({
          ...entity,
          ...data.update,
        }))
      }
      return true
    }

    if (message[0] === 'entity_deleted') {
      const data = message[1]
      this.entityStoreMap[data.type].remove(data.id)
      return true
    }

    return false
  }
}

export const entityUpdateProcessor = new EntityUpdateProcessor(
  workspaceStore,
  projectStore,
  epicStore,
  sprintStore,
  taskStore,
  subtaskStore,
  commentStore,
  userStore,
  activityLogStore,
  timeEntryStore
)
