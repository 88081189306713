import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import { websocketConnection } from './websockets/connection'
import { subscriptionManager } from './websockets/subscription-manager'
import { incommingMessageProcessor } from './websockets/incoming-message-processor'
import { userPresenceManager } from './websockets/user-presence-manager'
import { historyWrapper } from './globals/history'
import { ROUTES } from './routes'
import { persistState } from '@datorama/akita'
import { ConfigRouter } from './utils/ConfigRouter'
import { pushMessageManager } from './websockets/push-message-manager'
import { preferencesManager } from './preferences/manager'

function App() {
  const history = useHistory()

  useEffect(() => historyWrapper.init(history), [history])

  useEffect(() => {
    subscriptionManager.init()
    websocketConnection.init()
    incommingMessageProcessor.init()
    userPresenceManager.init()
    pushMessageManager.init()
    preferencesManager.init()
  }, [])

  return (
    <Layout className="app-layout" style={{ minHeight: '100%' }}>
      <ConfigRouter config={ROUTES} />
    </Layout>
  )
}

export default App
export const appPersistState = persistState({
  include: ['ui-calendar-page'],
})
