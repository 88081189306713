import React from 'react'
import {AddProjectMemberAction} from '../../../entities/activities/model'
import { ActivitiesDate } from '../ActivitiesDate'
import { ActivityBoldText } from '../text-formatters/ActivityBoldText'
import {User} from "../../../entities/user/model";

interface AddMemberActivityProps {
  action: AddProjectMemberAction
  date: string
  user: User | string
}

export function AddProjectMemberActivity(props: AddMemberActivityProps) {
  const { action, user } = props

  return (
    <>
      <ActivityBoldText>{user}</ActivityBoldText> added{' '}
      <ActivityBoldText>{action.data.changed_to.label}</ActivityBoldText>{' '} to this project.
      <ActivitiesDate date={props.date} isActivityDate />{' '}
    </>
  )
}
