import React from 'react'
import classNames from 'classnames'

interface TaskActivitiesDateProps {
  date: string
  isCommentDate?: boolean
  isActivityDate?: boolean
}

export function ActivitiesDate(props: TaskActivitiesDateProps) {
  const { date, isCommentDate, isActivityDate } = props
  const classes = classNames({
    'task-panel-comment__date': isCommentDate,
    'task-activity__date': isActivityDate,
  })
  return <span className={classes}>{date}</span>
}
