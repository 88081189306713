import React from 'react'

import '../../styles/titles.scss'

interface UserProfileTitleProps {
  label: string
}

export function UserProfileTitle(props: UserProfileTitleProps) {
  return <h2 className="user-section-title">{props.label}</h2>
}
