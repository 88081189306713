import { http } from '../../globals/http'
import { ID } from '@datorama/akita'
import {Subtask, SubtaskEstimate, SubtaskIntoTask} from './model'
import { ModelUpdate } from '../../utils/types'

export class SubtaskDataService {
  get(id: ID) {
    return http.get<Subtask>(`/api/subtask/${id}/`)
  }

  list(query?: object) {
    return http.get<Subtask[]>('/api/subtask/', query)
  }

  update(update: ModelUpdate<Subtask>) {
    return http.patch<Subtask>(`/api/subtask/${update.id}/`, update)
  }

  destroy(id: ID) {
    return http.delete(`/api/subtask/${id}/`)
  }

  create(task: Partial<Subtask>) {
    return http.post<Subtask>('/api/subtask/', task)
  }

  toTask(id: ID, data: SubtaskIntoTask) {
    return http.post<Subtask>(`/api/subtask/${id}/to-task/`, data)
  }

  addEstimate(subtaskID: ID, estimate: Partial<SubtaskEstimate>) {
    return http.post<Subtask>(`/api/subtask/${subtaskID}/estimate/`, estimate)
  }
}

export const subtaskDataService = new SubtaskDataService()
