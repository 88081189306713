import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { Observable } from 'rxjs'
import * as querystring from 'querystring'
import { ParsedUrlQueryInput } from 'querystring'

import { messageDispatcher } from './message-dispatcher'

type Options = {
  onlyServerErrMessages?: boolean
}

class rxios {
  private _httpClient: AxiosInstance

  get client() {
    return this._httpClient
  }

  constructor(private options: AxiosRequestConfig = {}) {
    this._httpClient = axios.create(options)
  }

  private _makeRequest<T>(
    method: string,
    url: string,
    queryParams?: object,
    body?: object,
    options?: Options
  ) {
    let request: AxiosPromise<T>
    const config = {
      params: queryParams,
      paramsSerializer: (params: ParsedUrlQueryInput) => {
        return querystring.stringify(params)
      },
    }
    switch (method) {
      case 'GET':
        request = this._httpClient.get<T>(url, config)
        break
      case 'POST':
        request = this._httpClient.post<T>(url, body, config)
        break
      case 'PUT':
        request = this._httpClient.put<T>(url, body, config)
        break
      case 'PATCH':
        request = this._httpClient.patch<T>(url, body, config)
        break
      case 'DELETE':
        request = this._httpClient.delete(url, config)
        break

      default:
        throw new Error('Method not supported')
    }
    return this.requestAsObservable<T>(request, options)
  }

  private requestAsObservable<T>(
    request: Promise<AxiosResponse<T>>,
    options?: Options
  ): Observable<T> {
    return new Observable<T>((subscriber) => {
      request
        .then((response) => {
          subscriber.next(response.data)
          subscriber.complete()
        })
        .catch((err: AxiosError) => {
          subscriber.error(err)
          subscriber.complete()

          if (window !== undefined) {
            if (!!err.response && err.response.status >= 500) {
              messageDispatcher.putErrorMessage(
                `${err.response.status}: Server error`
              )
            } else if (!options?.onlyServerErrMessages) {
              messageDispatcher.putError(err)
            }
          }
        })
    })
  }

  public get<T>(
    url: string,
    queryParams?: object,
    options?: Options
  ): Observable<T> {
    return this._makeRequest<T>('GET', url, queryParams, {}, options)
  }

  public post<T>(
    url: string,
    body: object,
    queryParams?: object,
    options?: Options
  ): Observable<T> {
    return this._makeRequest<T>('POST', url, queryParams, body, options)
  }

  public postFile<T>(
    url: string,
    fileName: string,
    file: File,
    options?: Options
  ): Observable<T> {
    const formData = new FormData()
    formData.append(fileName, file)
    const request = this._httpClient.post<T>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return this.requestAsObservable<T>(request, options)
  }

  public put<T>(
    url: string,
    body: object,
    queryParams?: object,
    options?: Options
  ): Observable<T> {
    return this._makeRequest<T>('PUT', url, queryParams, body, options)
  }

  public patch<T>(
    url: string,
    body: object,
    queryParams?: object,
    options?: Options
  ): Observable<T> {
    return this._makeRequest<T>('PATCH', url, queryParams, body, options)
  }

  public delete<T>(url: string, queryParams?: object): Observable<T> {
    return this._makeRequest('DELETE', url, queryParams)
  }
}

export { rxios, rxios as Rxios }
