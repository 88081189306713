import { Select } from 'antd'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { UserProfileFieldTitle } from './UserProfileFieldTitle'
import '../../styles/user-field.scss'
import { UserTheme } from '../../../entities/user/model'

interface UserProfileThemeProps {
  label: string
  value: UserTheme
  editable: boolean
  onChange: (value: UserTheme) => void
}

export function UserProfileTheme(props: UserProfileThemeProps) {
  const { label } = props
  const classes = useMemo(
    () =>
      classNames(
        'user-profile__info',
        'user-info',
        `user-profile__info_${label}`
      ),
    [label]
  )
  if (!props.editable && !props.value) {
    return null
  }
  return (
    <div className={classes}>
      <UserProfileFieldTitle label={props.label} />
      {props.editable ? (
        <Select
          value={props.value}
          options={[
            { label: 'Light', value: 'light' },
            { label: 'Dark', value: 'dark' },
            { label: 'System', value: 'system' },
          ]}
          onChange={props.onChange}
        />
      ) : (
        <span>{props.value}</span>
      )}
    </div>
  )
}
