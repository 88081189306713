import LinkifyIt from 'linkify-it'
import { InputRule } from 'prosemirror-inputrules'
import { Transaction, EditorState } from 'prosemirror-state'

const linkify = LinkifyIt()
// Url link href: /{workspaceId}/{projectId}/task/{taskCode}-{taskId}
const pathnameReg = /^\/(\d+)\/(\d+)\/task\/(\w+)-(\d+)$/

class LinkifyRegexMock {
  exec(str: string) {
    // Check that there is a space at the end of the line
    if (!(/(\s+)$/.test(str))) { return null }

    // We break the sentence into words and take the last one
    const chunks = str.trim().split(/(\s+)/)
    const lastChunk = chunks[chunks.length - 1]

    // Check that fragment is link
    if (!linkify.test(lastChunk)) { return null }

    // Check that this is an internal link
    try {
      const url = new URL(lastChunk)
      if (url.hostname !== window.location.hostname && !pathnameReg.test(url.pathname)) { return null }
      return lastChunk
    } catch { return null }
  }
}

const UrlInputHandler = (state: EditorState, match: string, start: number, end: number): Transaction | null => {
  const url = new URL(match)

  // Get url values
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, workspaceId, projectId, taskCode, taskId] = pathnameReg.exec(url.pathname) as any

  // Create new node for this link
  const node = state.schema.nodes.internal_link.create({workspaceId, projectId, taskId, taskCode});
  return state.tr.replaceRangeWith(end - match.length, end, node).insertText("\u00A0")
}

export const UrlInputRule = new InputRule(
  (new LinkifyRegexMock() as any) as RegExp,
  UrlInputHandler as any
);
