import {ID, QueryEntity} from '@datorama/akita'
import { CommentState, CommentStore, commentStore } from './store'

export class CommentQuery extends QueryEntity<CommentState> {
  constructor(protected store: CommentStore) {
    super(store)
  }

  forTask(taskID: ID) {
    return this.selectAll({
      filterBy: (comment) =>
        comment.target.type === 'task' && comment.target.id === taskID
    })
  }
}

export const commentQuery = new CommentQuery(commentStore)
