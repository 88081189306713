import React from 'react'

import {HistoryActivityItemType, HistoryItem} from "./state/model";
import {User} from "../entities/user/model";
import {humanizeDate} from "../utils/date";
import {ActivityLog} from "./ActivityLog";
import {CommentListItem} from "./CommentListItem";
import {ID} from "@datorama/akita";
import {ModelUpdateCallback} from "../utils/types";
import {Comment} from "../entities/comment/model";
import {WorkLog} from "./WorkLogListItem";

interface HistoryListItemProps {
  item: HistoryItem
  currentUser: User
  onCommentDelete: (id: ID) => void
  onWorkLogDelete: (id: ID) => void
  onCommentUpdate: ModelUpdateCallback<Comment>
}

export function HistoryListItem(props: HistoryListItemProps) {
  const { item, currentUser, onCommentDelete, onWorkLogDelete, onCommentUpdate } = props
  const humanDate = humanizeDate(item.date)

  switch (item.type) {
    case HistoryActivityItemType.ACTIVITY:
      return (
        <div className="task-activities-section__activity task-activity">
          <ActivityLog
            key={item.id}
            action={item.data}
            date={humanDate}
            currentUser={currentUser}
          />
        </div>
    )

    case HistoryActivityItemType.COMMENT:
      return (
        <div className="task-activities-section__activity task-activity">
          <CommentListItem
            onUpdate={onCommentUpdate}
            onDelete={onCommentDelete}
            key={item.data.id}
            comment={item.data}
            currentUser={currentUser}
            date={humanDate}
          />
        </div>
      )

    case HistoryActivityItemType.WORKLOG:
      return (
        <div className="task-activities-section__activity task-activity">
          <WorkLog
            key={item.id}
            worklog={item.data}
            date={humanDate}
            currentUser={currentUser}
            onDelete={onWorkLogDelete}
          />
        </div>
      )

    default:
      return <div className="task-activities-section__activity task-activity"/>
  }
}
