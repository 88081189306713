import { ID, Store, StoreConfig } from '@datorama/akita'
import { ReportDev } from '../../../entities/report-dev/model'
import { getLastMonthStartAndEnd } from '../../utils'

export interface ReportDevPageState {
  selectedUserID: ID | null
  startDate: Date | null
  endDate: Date | null
  report: ReportDev | null
  showFiltersPanel: boolean
}

const momentLastMonth = getLastMonthStartAndEnd()

const initialState = {
  selectedUserID: null,
  startDate: momentLastMonth.start,
  endDate: momentLastMonth.end,
  report: null,
  showFiltersPanel: true,
}

@StoreConfig({ name: 'ui-report-dev-page', resettable: true })
export class ReportDevPageStore extends Store<ReportDevPageState> {}

export const reportDevPageStore = new ReportDevPageStore(initialState)
