import React from 'react'
import { TASK_ACTIVITIES_FILTERS } from '../../../entities/choices/subtasksFilter'
import { TaskPanelFilterPopoverDefault } from '../controls/TaskPanelFilterPopoverDefault'
import '../styles/task-activities-section.scss'
import { User } from "../../../entities/user/model";
import {HistoryActivityItemType, HistoryItem} from "../../../history/state/model";
import {ID} from "@datorama/akita";
import {ModelUpdateCallback} from "../../../utils/types";
import {Comment} from "../../../entities/comment/model";
import {HistoryList} from "../../../history/HistoryList";

export interface TaskHistorySectionProps {
  items: HistoryItem[]
  currentUser: User
  onCommentDelete: (id: ID) => void
  onWorkLogDelete: (id: ID) => void
  onCommentUpdate: ModelUpdateCallback<Comment>
  onFilterTaskActivities: (value: HistoryActivityItemType) => void
  selectedActivityFilter?: HistoryActivityItemType[]
}

export function TaskHistorySection(props: TaskHistorySectionProps) {
  const { items, currentUser, onCommentDelete, onWorkLogDelete, onCommentUpdate } = props

  return (
    <div className="task-activities-section">
      <div className="task-activities-section__header">
        <TaskPanelFilterPopoverDefault
          filters={TASK_ACTIVITIES_FILTERS}
          onFilter={props.onFilterTaskActivities}
          selectedActivityFilter={props.selectedActivityFilter}
          sectionName={'Activities'}
        />
      </div>
      <div className="task-activities-section__content">
        <HistoryList
          items={items}
          onCommentDelete={onCommentDelete}
          onWorkLogDelete={onWorkLogDelete}
          onCommentUpdate={onCommentUpdate}
          currentUser={currentUser}
          selectedActivityFilter={props.selectedActivityFilter}
        />
      </div>
    </div>
  )
}
