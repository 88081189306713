import { Icon } from '@blueprintjs/core'
import React from 'react'
import { IconNames } from '@blueprintjs/icons'

export interface TaskGroupDropdownTargetProps {
  title?: string
  titleColor?: string
  className?: string
}

export function TaskGroupDropdownTarget(props: TaskGroupDropdownTargetProps) {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {!!props.title && (
        <div
          className="task-group-dropdown-title"
          style={{ color: props.titleColor, cursor: 'pointer' }}
        >
          {props.title}
        </div>
      )}
      <Icon
        className={props.className}
        icon={IconNames.MORE}
        style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
      />
    </span>
  )
}
