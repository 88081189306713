import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita'
import { Comment } from './model'

export interface CommentState extends EntityState<Comment, ID> {
  loaded: boolean
}

@StoreConfig({ name: 'entity-comments' })
export class CommentStore extends EntityStore<CommentState> {}

export const commentStore = new CommentStore()
