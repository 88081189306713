import React, { useState } from 'react'
import { DeletedText } from './text-formatters/DeletedText'
import { InsertedText } from './text-formatters/InsertedText'
import { Extension, ProseMirrorEditor } from '../../editor'
import { InternalLinkProvider, MentionProvider } from '../../editor/providers'
import {
  AttachmentExtension,
  ExternalLinkExtension,
  InternalLinkExtension,
  MentionExtension,
  StrikethroughExtension,
} from '../../editor/extentions'
import { BaseAttachmentProvider } from '../../editor/extentions/attacment/provider'
import { compareDocs } from '../../editor/core/utils'

const initialDoc = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
}

interface DescriptionCompareProps {
  newValue: object | null
  oldValue: object | null
}

export function DescriptionCompare(props: DescriptionCompareProps) {
  const { newValue, oldValue } = props

  const extensions: Extension[] = [
    new InternalLinkExtension(new InternalLinkProvider()),
    new ExternalLinkExtension(),
    new MentionExtension(new MentionProvider(false)),
    new AttachmentExtension(new BaseAttachmentProvider()),
    new StrikethroughExtension(),
  ]

  const { changedContent, content } = compareDocs(
    oldValue,
    newValue,
    extensions,
    initialDoc
  )

  const [value, setValue] = useState(content.toJSON())

  const onChangeEditor = (transaction: any, view: any) => {
    const newState = view.state.apply(transaction)
    view.updateState(newState)
    if (transaction.docChanged) {
      setValue(view.state.doc.toJSON())
    }
  }

  const onBlur = () => {}

  return (
    <>
      <DeletedText>
        <ProseMirrorEditor
          value={changedContent.toJSON()}
          extensions={extensions}
          editable={false}
          onChange={onChangeEditor}
          onBlur={onBlur}
          className="activity-page-editor"
        />
      </DeletedText>{' '}
      <InsertedText>
        <ProseMirrorEditor
          value={value}
          extensions={extensions}
          editable={false}
          onChange={onChangeEditor}
          onBlur={onBlur}
          className="activity-page-editor"
        />
      </InsertedText>
    </>
  )
}
