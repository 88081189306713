import React from 'react'
import { Layout } from 'antd'
import { Spinner } from '../components/Spinner'

export function Loader() {
  return (
    <Layout.Content
      style={{ height: '100%', backgroundColor: 'var(--color-background)' }}
    >
      <Spinner style={{ height: '100%', position: 'fixed' }} />
    </Layout.Content>
  )
}
