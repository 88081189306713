import { ID, Store, StoreConfig } from '@datorama/akita'
import { HistoryActivityItemType } from '../../../../../history/state/model'
import { parseLocalStorage } from '../../../../utils'

export interface EpicPanelState {
  epicId: ID | null
  activitiesFilter: HistoryActivityItemType[]
  showTasksInFinishedSprints: ID[]
}

const initialState = {
  epicId: null,
  activitiesFilter: parseLocalStorage('epicActivityFilter', 'statuses') || [
    HistoryActivityItemType.ACTIVITY,
    HistoryActivityItemType.WORKLOG,
  ],
  showTasksInFinishedSprints:
    parseLocalStorage('showTasksInFinishedSprints', 'toggled') || [],
}

@StoreConfig({ name: 'ui-epic-panel' })
export class EpicPanelStore extends Store<EpicPanelState> {}

export const epicPanelStore = new EpicPanelStore(initialState)
