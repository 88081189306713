import OrderedMap from 'orderedmap'
import applyDevTools from 'prosemirror-dev-tools'
import React, { useRef, useEffect, useCallback } from 'react'
import { EditorState, Plugin, Transaction } from 'prosemirror-state'
import { EditorView } from '../editor-view'
import {
  Schema,
  NodeSpec,
  MarkSpec,
  Node as ProseMirrorNode,
  DOMSerializer,
} from 'prosemirror-model'
import { keymap } from 'prosemirror-keymap'
import { schema as baseSchema } from 'prosemirror-schema-basic'
import { addListNodes } from 'prosemirror-schema-list'
import { suggest, Suggester } from 'prosemirror-suggest'
import isEqual from 'react-fast-compare'

import { reactProps, getReactProps, updateReactProps } from './react-props'
import { callbacks } from './callbacks'
import { useProseMirrorContext } from './provider'
import { Extension } from './extension'
import { NodeViewsSpec } from './types'
import { useDebouncedEffect } from './utils'

interface ProseMirrorOutput {
  ref: React.RefObject<any>
}

type ProseMirrorProps = {
  value: object | null
  extensions: Extension[]
  onChange: (transaction: Transaction, view: EditorView) => void
  onEnterPress?: (view: EditorView) => boolean
  editable?: boolean
  multiLine?: boolean
  debug?: boolean
}

export const initialDoc = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
}

class ClipboardSerializer<S extends Schema = any> extends DOMSerializer<S> {
  static fromSchema<S extends Schema = any>(schema: S) {
    // Avoid copy task type
    const result = DOMSerializer.fromSchema(schema)
    const originalSerializer = result.serializeNode
    result.serializeNode = (node: any, options?: { [p: string]: any }) => {
      if (node.type.name === 'tasktype') {
        return document.createElement('span')
      }
      return originalSerializer.bind(result)(node, options)
    }
    return result
  }
}

export const useProseMirror = ({
  value,
  editable = true,
  extensions,
  multiLine = true,
  debug,
  onChange,
  onEnterPress = (view: EditorView) => true,
}: ProseMirrorProps): ProseMirrorOutput => {
  const ref = useRef<HTMLDivElement>()
  const viewRef = useRef<EditorView>(null!)
  const { createPortal } = useProseMirrorContext()
  const handleCreatePortal = useCallback(createPortal, [])

  // We use debounced effect here for update editor from websocket
  // but we dont need updates if editor looses focus when we select
  // task type by mouse or drop image into editor
  useDebouncedEffect(
    () => {
      if (!viewRef.current || viewRef.current.hasFocus()) {
        return
      }
      const oldState = viewRef.current.state
      const doc = ProseMirrorNode.fromJSON(oldState.schema, value || initialDoc)
      const oldDoc = oldState.doc.toJSON()

      if (isEqual(value, oldDoc)) {
        return
      }

      const state = EditorState.create({
        schema: oldState.schema,
        doc,
        plugins: oldState.plugins,
      })
      viewRef.current.removePluginsDomElements()
      viewRef.current.updateState(state)
    },
    1000,
    [value]
  )

  // initiate component
  useEffect(() => {
    const itemContent = multiLine ? 'paragraph block*' : 'paragraph'
    const listGroup = multiLine ? 'block' : undefined
    const nodes = extensions.reduce<OrderedMap<NodeSpec>>(
      (acc, ext) => ext.addNodes(acc),
      addListNodes(
        baseSchema.spec.nodes as OrderedMap<NodeSpec>,
        itemContent,
        listGroup
      )
    )

    const marks = extensions.reduce<OrderedMap<MarkSpec>>(
      (acc, ext) => ext.addMarks(acc),
      baseSchema.spec.marks as OrderedMap<MarkSpec>
    )

    const schema = new Schema({ nodes, marks })

    const suggesters: Suggester[] = extensions.reduce<Suggester[]>(
      (acc, ext) => ext.addSuggesters(acc),
      []
    )

    const plugins: Plugin[] = [
      suggest(...suggesters),
      ...extensions.reduce<Plugin[]>(
        (acc, ext) => ext.addPlugins(schema, acc),
        []
      ),
      keymap({
        Enter: (_state, _dispatch, view) => onEnterPress(view as EditorView),
      }),
      callbacks({ createPortal: handleCreatePortal }),
      reactProps({ editable }),
    ]

    const doc = ProseMirrorNode.fromJSON(schema, value || initialDoc)
    const state = EditorState.create({ schema, doc, plugins })

    const nodeViews = extensions.reduce<NodeViewsSpec>(
      (acc, ext) => ext.addNodeViews(schema, acc),
      {}
    )

    viewRef.current = new EditorView(ref.current, {
      state,
      nodeViews,
      editable: (state: EditorState) => getReactProps(state)['editable'],
      dispatchTransaction: (transaction) => {
        onChange(transaction, viewRef.current)
        // console.log("--state", viewRef.current.state.doc.toJSON())
      },
      clipboardSerializer: ClipboardSerializer.fromSchema(schema),
    })

    if (debug) {
      applyDevTools(viewRef.current)
    }

    // destroy component
    return () => viewRef.current.destroy()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    viewRef.current.dispatch(
      updateReactProps(viewRef.current.state, { editable })
    )
  }, [editable])

  return { ref }
}
