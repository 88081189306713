import React, { useRef } from 'react'
import { Menu } from '@blueprintjs/core'

import { ElementPosition } from '../../../ui/element-position'

import './dialog.css'
import classNames from 'classnames'
import { CustomScrollbar } from '../../../../components/custom-scrollbar/CustomScrollbar'
import { TaskType } from '../../../../entities/task-type/model'

export interface DialogProps {
  activeIndex: number
  appendTo: HTMLElement
  targetElement: HTMLElement
  boundaryElement: HTMLElement
  offset: { top: number; left: number; right: number; bottom: number }
  taskTypes: any
  onClick: ({ id, title }: { id: number | string; title: string }) => void
}

export const Dialog = ({
  activeIndex,
  targetElement,
  boundaryElement,
  offset,
  taskTypes,
  appendTo,
  onClick,
}: DialogProps) => {
  const appendToRef = useRef(appendTo)
  const targetRef = useRef(targetElement)
  const boundaryRef = useRef(boundaryElement)
  targetRef.current = targetElement

  return (
    <ElementPosition
      targetElement={targetRef}
      boundaryElement={boundaryRef}
      appendTo={appendToRef}
      zIndex={10000}
      my="left top"
      at={`left+${offset.left} bottom+${offset.bottom}`}
    >
      <Menu className="mention-user-menu">
        <CustomScrollbar
          translateContentSizesToHolder={true}
          rendererMaxHeight={200}
        >
          {taskTypes.map((taskType: TaskType, index: number) => (
            <li
              className={classNames('bp3-menu-item', {
                'bp3-active': index === activeIndex,
              })}
              onClick={() => onClick({ id: taskType.id, title: taskType.name })}
              key={taskType.id}
            >
              {taskType.name}
            </li>
          ))}
        </CustomScrollbar>
      </Menu>
    </ElementPosition>
  )
}
